import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Modal, KeyboardAvoidingView, Dimensions, TextInput, Platform, useWindowDimensions } from 'react-native';
import { color } from 'react-native-reanimated';
import { GlobalStyles } from '../../constants/styles';
import { isFirstCharHebrew } from '../../functions/utils';
import { AntDesign } from '@expo/vector-icons';

import i18n from '../../hooks/useI18n';
import ButtonTypeButton from '../inputs/ButtonTypeButton';
import TextInputWithErrorComponent from '../forms/TextInputWithErrorComponent';
import { GenericAlert } from './GenericAlert';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
const InviteUserAdminAlert = ({
  UserName,
    visible = false,
    setVisible,
    buttonmessage1 = "יציאה",
    onPress,
    buttonmessage2 = "ביטול",
    navigation,
    onPressLoader = false,
    userSettingsState,
    inputs,
    inputChangedHandler,
 }) => {
  const hideVisibleAlert = () => {
    setVisible(false);
    inputChangedHandler("Email", '', true);
  };

  
const responsiveStyles = useResponsiveStyles(userSettingsState);

  const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);


  const [isLoading, setIsLoading] = useState(false);

  const windowWidth = responsiveStyles.screenWidth;
  const ButtonContainerWidth = responsiveStyles.isWeb ? windowWidth * 0.45 : windowWidth * 0.85;
  const styles = styling(responsiveStyles);
  const closeModal = () => {
    hideVisibleAlert();
  };
  const [buttonmessage1Width,setButtonmessage1Width] = useState(0);
  const [buttonmessage2Width,setButtonmessage2Width] = useState(0);
  // const isSetWidthButton = buttonmessage1Width + buttonmessage2Width > ButtonContainerWidth;
  const isSetWidthButton = buttonmessage1?.length + buttonmessage2?.length > 35;

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>

    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onDismiss={() => {console.log()}}      
    >
     <GenericAlert
     userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      <TouchableOpacity 
      style={[styles.modalContainer]} 
      onPress={closeModal}
      activeOpacity={1}
      >
        <TouchableOpacity 
        style={[
          styles.alertContainer, 
          { backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor }
        ,{ width: ButtonContainerWidth }
      ]} 
        activeOpacity={1}
        >
       <TouchableOpacity onPress={closeModal} style={{flexDirection: 'row-reverse', padding: 5, paddingBottom: 0 }}>
          <AntDesign name="closecircleo" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
        </TouchableOpacity>
        <View style={{ padding: 20, paddingTop: 0 }}>
          <Text style={[styles.headermessage, { color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor }]}>
          {i18n.t('account.MyAccount_InviteUserAdminAlert_Title')} {UserName}
            </Text>
          <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Email.value}
    setValue={(value) => {
        inputChangedHandler("Email", value, true);
    }}    label={i18n.t('account.MyAccount_InviteUserAdminAlert_Username')}
    errorMessage={i18n.t('account.MyAccount_InviteUserAdminAlert_Username_ErrorMessage')}
    isRequired={true}
    isValid={inputs.Email.isValid}
    />
          <View style={[styles.buttonContainer, 
          isSetWidthButton === true ?( 
            isFirstCharHebrew(buttonmessage1) === false ?  { flexDirection:'column' } : { flexDirection:'column-reverse' }
            )
          : isFirstCharHebrew(buttonmessage1) === false ? { flexDirection:'row' } : { flexDirection:'row-reverse' }
        ]}
        >
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.StyleButtonContainer}
          styleButton={styles.styleButton}
          // loading={onPressLoader}
          // onPress={onPress}
          loading={isLoading}
          onPress={async () => {
            setIsLoading(true);
            const response = await onPress();
            console.log(`response is ${JSON.stringify(response)}`);
            setIsLoading(false);
            if(response.isCompleted === true)
              {

              }
              else{
                setbodymessge(i18n.t('responseMessages.InvintationFailed'));
                setGenericAlertVisible(true);
              }
        }}
          >
          {i18n.t('account.MyAccount_InviteUserAdminAlert_SubmitButton')}
          </ButtonTypeButton>
          </View>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
    </KeyboardAvoidingView>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  buttonContainer:{
    //   flex:1,
    // flexDirection:'column-reverse',
    // flexDirection:'row-reverse',
    // backgroundColor:'brown',
    paddingTop: 50,
  },
  alertContainer: {
    borderRadius: 10,
    
    // width:'85%',
    // height:'25%',
  },
  headermessage:{
    color: GlobalStyles[responsiveStyles.ThemeValue]?.alertButtonColor,
    fontSize: responsiveStyles.fontSize_H4,
    paddingBottom: 15,
    // flex:1,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  StyleButtonContainer: {
     alignItems: "center",
     flex:1,
     paddingTop: 8,
  },
  styleButton:{
    // backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor,
    // backgroundColor: GlobalStyles.colors.AddButton,
    paddingHorizontal: 20
  },
});

export default InviteUserAdminAlert;
