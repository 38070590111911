import React, { useContext } from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { navigateToScreen } from "../../navigationResponsive";
import { useLocation } from "react-router-dom";
import { getNavigationParams, getNavigationRouteName } from "../../functions/navigationLogic";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import LanguageComponent from "../myAccountScreen/LanguageComponent";
import DarkModeComponent from "../myAccountScreen/DarkModeComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const HamburgerNavigationComponent = ({ 
    userSettingsState,
    navigation,
    cleanup,
    setIsMenuOpen,
    isHeader = false,
   }) => {

    const {signout } = useContext(AuthContext);
  const {setDefaultSettings, setUserSettingsDarkMode, setUserSettingsLanguage } = useContext(UserSettingsContext);

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles, isHeader);


    const location = responsiveStyles.isWeb ? useLocation() : null;
    let routeName = getNavigationRouteName(navigation, location);
    const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
    const IsProfileFromStore = (navigationParams?.UserId === "Mother" || navigationParams?.UserId === "Partner");
    const accountFlow = [
      "MyAccount",
      "CreateProfile",
      "EditProfile",
      "ManageMediaPost",
      "ManageTributePost",
      IsProfileFromStore ? null : "Profile",
      IsProfileFromStore ? null : "ProfileFromAccount",
      IsProfileFromStore ? null : "ImageMediaFromAccount",
      IsProfileFromStore ? null : "ImageMedia",
      "JewleryDetails",
      "JewleryDetailsFromOrder",
      "OrderDetails",
      "AssignJewleryToProfile",
      "ForgotPasswordCodeConfirmationFromAccountScreen",
      "ForgotPasswordConfirmCanChangePasswordFromAccountScreen",
    ];
    const loginFlow = [
      "Signin",
      "googlesignin",
      "SignUpWithEmail",
      "SignUpConfirmEmailCode",
      "SignUpConfirmUserNameAndPasswordByEmail",
      "SignUpConfirmation",
      "ForgotPassword",
      "ForgotPasswordConfirmCanChangePassword",
      "ForgotPasswordCodeConfirmation",
      "LoaderAuth",
    ];
    const discoverFlow = [
      // "ImageMedia",
  
    ];
  
    const storeFlow = [
      "Home",
      "ItemDetails",
      "ItemList",
      IsProfileFromStore ? "Profile" : null,
      IsProfileFromStore ? "ImageMedia" : null,
      "Cart",
      "CartInformation",
      "CartShippingInformation",
      "CartPayment",
      "PaymentSuccessful",
      "ItemDetailsFromCart",
      // "AboutUs",
      // "Ambassador",
      // "Help", // not using it cause i already have "Help" in hamburger
      // "Privacy",
      // "ContactUs",
      // "TermsAndConditions",
      // "Tutorial",
      // "TutorialExplanation",
    ];

    const closeMenu = () => {
      if(setIsMenuOpen){
        setIsMenuOpen(false);
      }
    }

  return (
<View style={styles.headerContainer}>
  <View style={styles.navigationContainer}>
  {/* <TouchableOpacity
        onPress={() => {
          closeMenu();
          navigateToScreen(navigation, 'StoreFlow', `Tutorial`, null, null);
          if(cleanup){
            cleanup();
          }
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, (routeName === "Tutorial" || routeName === "TutorialExplanation") ? styles.onFocusText : null]}>
            {i18n.t('menu.Tutorial')}
            </Text>
        </TouchableOpacity> */}

        {userSettingsState?.userSettings?.UserId ?
         userSettingsState?.userSettings?.IsAdmin ?
         <>
          <TouchableOpacity 
          onPress={async () => {
            closeMenu();
            navigateToScreen(navigation, 'AdminFlow', "AdminOrdersList", null, null);
            if(cleanup){
              cleanup();
            }
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, routeName === "AdminOrdersList" || routeName === "AdminOrderDetails" ? styles.onFocusText : null]}>
              {i18n.t('menu.Orders')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity 
          onPress={async () => {
            closeMenu();
            navigateToScreen(navigation, 'AdminFlow', "FinancialAnalysis", null, null);
            if(cleanup){
              cleanup();
            }
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, routeName === "FinancialAnalysis" ? styles.onFocusText : null]}>
              {i18n.t('menu.FinancialAnalysis')}
            </Text>
          </TouchableOpacity>
         </>
         :
        <TouchableOpacity
        onPress={() => {
          closeMenu();
          navigateToScreen(navigation, 'AccountFlow', `MyAccount`, null, null);
          if(cleanup){
            cleanup();
          }
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, accountFlow.includes(routeName) ? styles.onFocusText : null]}>
            {i18n.t('menu.MyAccount')}
            </Text>
        </TouchableOpacity>
        : null }
        <TouchableOpacity 
        onPress={() => {
          closeMenu();
          navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
          if(cleanup){
            cleanup();
          }
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, storeFlow.includes(routeName) ? styles.onFocusText : null]}>
            {i18n.t('menu.Store')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity 
        onPress={() => {
          closeMenu();
          navigateToScreen(navigation, 'StoreFlow', `Help`, null, null);
          if(cleanup){
            cleanup();
          }
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, routeName === "Help" ? styles.onFocusText : null]}>
            {i18n.t('menu.Help')}
          </Text>
        </TouchableOpacity>

        {userSettingsState?.userSettings?.UserId ? 
          <TouchableOpacity 
          onPress={async () => {
            await signout();
            console.log("setDefaultSettings");
            await setDefaultSettings();
            closeMenu();
            navigateToScreen(navigation, 'LoginFlow', "Signin", null, null);
            if(cleanup){
              cleanup();
            }
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, routeName === "SignOut" ? styles.onFocusText : null]}>
              {i18n.t('menu.SignOut')}
            </Text>
          </TouchableOpacity>
          :
          <TouchableOpacity
          onPress={() => {
            closeMenu();
            navigateToScreen(navigation, 'LoginFlow', "Signin", null, null);
            if(cleanup){
              cleanup();
            }
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, loginFlow.includes(routeName) ? styles.onFocusText : null]}>
              {i18n.t('menu.SignIn')}
            </Text>
          </TouchableOpacity>
        }
  </View>
<View style={styles.settingsContainer}>
        <LanguageComponent
        containerStyle={{ marginTop: 0 }}
        showText={!isHeader}
        showIcon={isHeader}
    userSettingsState={userSettingsState} 
    languageContainerStyle={{ paddingLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 20 }}
    setUserSettingsLanguage={(newLocale) => {
      i18n.locale = newLocale.slice(0, 2);
      setUserSettingsLanguage(newLocale);
    }}
    />
<DarkModeComponent
containerStyle={{ marginTop: 0 }}
showText={!isHeader}
    userSettingsState={userSettingsState} 
    setUserSettingsDarkMode={setUserSettingsDarkMode}
    showIcon={isHeader}
    />
    </View>
</View>
  );
}
const styling = (responsiveStyles, isHeader) => StyleSheet.create({
  headerContainer: {
    flexDirection: !isHeader ? null : responsiveStyles.isRTL ? 'row-reverse' : 'row', 
    justifyContent: !isHeader ? null : 'space-between', 
    flex:1,
    width: !isHeader ? null : responsiveStyles.screenWidth * 0.55
  },
  navigationContainer: {
    flex:1,
    flexDirection: !isHeader ? null : responsiveStyles.isRTL ? 'row-reverse' : 'row', 
    justifyContent: !isHeader ? null : 'flex-end', 
    alignItems: !isHeader ? null : 'center',
  },
  itemContainer: {
    paddingVertical: 10,
    borderBottomWidth: isHeader ? 0 : 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    marginHorizontal: isHeader ? 20 : null,
  },
  onFocusText: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles.colors.Gold,
  },
  text: {
      textAlign:'center',
      color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H4,
      fontFamily: GlobalStyles.fonts.application_font,
      
  },
  settingsContainer:{ 
    flexDirection: !isHeader ? null : responsiveStyles.isRTL ? 'row-reverse' : 'row', 
    flex: isHeader ? null : 1, 
    justifyContent: isHeader ? null :  'flex-end', 
    width: isHeader ? null :  responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8,  
    marginLeft: isHeader ? null :  -30,
   }
});

export default HamburgerNavigationComponent;