import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';

  const PlayCircle = ({ 
    userSettingsState,
    shouldPlay,
    isAudio,
    allowPlayVideo,
    isFullScreen,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: isAudio ? shouldPlay ? -30 : -20 : -24, // Adjust the button's position based on its width and height
      marginTop: isAudio ? -35 : -24,
    }}
    >
      {isAudio ? 
<View style={[{  }]}>
<FontAwesome name={shouldPlay ? "volume-up" :"volume-off"} 
size={60} 
// color={GlobalStyles[responsiveStyles.ThemeValue].iconColor}
color={"white"}

/>
</View>
: null }
    { allowPlayVideo && !shouldPlay && !isAudio ? 
    <AntDesign name={'playcircleo'} 
    size={48} 
    // color={GlobalStyles[responsiveStyles.ThemeValue].iconColor} 
    color={"white"}
    style={[isFullScreen ? styles.isFullScreenColor : null]}
     />
    : null }
  </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({

});

export default PlayCircle;