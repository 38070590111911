import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { isNumeric } from "../../functions/utils";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputCustom from "../inputs/TextInputCustom";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
  const ManagePetProfileForm_PetTypeComponent = ({ 
    userSettingsState,
    inputs,
    inputChangedHandler,
    editable,
    textInputNameStyle,
    containerStyle,
   }) => {

    const [isOtherVisible, setIsOtherVisible] = useState(false);
    const [lastValue, setLastValue] = useState("");
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
<View style={[styles.mainContainer, containerStyle]}>
  <View>
    <Text style={styles.subtitle}>{`${i18n.t('account.MyAccount_ManagePetProfileForm_PetType')} *`}</Text>
  </View>
  <View style={styles.container}>
  <View style={styles.secondaryContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.PetType.value === "Dog" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("PetType", "Dog", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_PetType_Dog')}
        </ButtonTypeButton>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.PetType.value === "Cat" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("PetType", "Cat", true);
    }}
    >
      {i18n.t('account.MyAccount_ManagePetProfileForm_PetType_Cat')}
    </ButtonTypeButton>
    </View>
    <View style={styles.secondaryContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, isOtherVisible ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(true);
      inputChangedHandler("PetType", lastValue, true);
    }}
    >
      {i18n.t('account.MyAccount_ManagePetProfileForm_PetType_Other')}
    </ButtonTypeButton>
    {isOtherVisible ? 
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.PetType.value}
    setValue={(value) => {
        if(editable){   
            inputChangedHandler("PetType", value, true);
            setLastValue(value);
        }
    }}
    editable={editable}
    label={`${i18n.t('account.MyAccount_ManagePetProfileForm_PetType')}`}
    styleTextInputContainer={textInputNameStyle}
    isPaddingBottom={false}
    />
    : null}
    </View>
    </View>
    {!inputs.PetType.isValid && (
      <ErrorComponentMessageError 
          userSettingsState={userSettingsState} 
          errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_PetType_ErrorMessage')}
      />
    )}
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  mainContainer: {
    // backgroundColor: 'blue',
  },
  container: {
    flexDirection: responsiveStyles.isMobile ? 'column' : responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  secondaryContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',
    paddingBottom: 15,
  },
  subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontWeight: '500',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
    paddingBottom: 10,
},
styleButton: {
  paddingHorizontal: 25,
},
chosenButton: {
  backgroundColor: Gold,
},
buttonStyle: {
  paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
},
});

export default ManagePetProfileForm_PetTypeComponent;