import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { apikey } from "./apikey";
import { token_Storage } from "../modules/StorageObjects";

const instance = axios.create({
  baseURL: `${apikey}/api`, // Ensuring the baseURL is a string
  responseType: "json",
  withCredentials: true, // Ensure this is set correctly
  maxContentLength: 100 * 1024 * 1024, // Max content length allowed (100 MB)
  maxBodyLength: 100 * 1024 * 1024, // Max body length allowed (100 MB)
  timeout: 300000, // Override timeout for this specific request (30 seconds)
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json' // Accepting JSON responses
  }
});

instance.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem(token_Storage);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
