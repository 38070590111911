import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { isNumeric } from "../../functions/utils";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputCustom from "../inputs/TextInputCustom";
  const TextInputWithErrorComponent = ({ 
    userSettingsState,
    value,
    minLetters = 0,
    minLettersShowcase = minLetters,
    maxLetters = 99999999,
    maxLettersShowcase = maxLetters,
    isStopInMax = false,
    excludeIsNumeric = null,
    setValue,
    label,
    errorMessage,
    isRequired = false,
    isValid = true,
    isChanged = false,
    styleContainer = null,
    styleTextInputContainer = null,
    keyboardType='default',
    multiline = false,
    heightTextInput = 0,
    iconType = "cancel",
    editable = true,
    isPaddingBottom = true,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  const [isAlreadyNotValid, setIsAlreadyNotValid] = useState(false);
  const [isValueChangedValid, setIsValueChangedValid] = useState(false);

  useEffect(() =>{
    if(!isValid){
      console.log(`if(!isValid){`);
      setIsAlreadyNotValid(true);
      setIsValueChangedValid(true);
    }
    if(isChanged){
      setIsValueChangedValid(true);
    }
  },[isValid, isChanged]);

  return (
    <View style={[styles.container, isPaddingBottom ? styles.paddingBottomcontainer : null, styleContainer,
      // responsiveStyles.isWeb ? { alignItems: 'center' } : null,
    ]}>
      <TextInputCustom
      userSettingsState={userSettingsState}
       editable={editable}
       value={value}
       keyboardType={keyboardType}
       multiline={multiline}
       onChangeText={(text) => {
        if(isStopInMax && text.length > maxLetters)
        {
          return;
        }
        setValue(text);
        setIsValueChangedValid(true);
      }}
      label={label}
      style={[
        // value.length != 0 ? { marginBottom: 15 } : null,
        styleTextInputContainer
      ]}
      iconType={iconType}
      heightTextInput={heightTextInput}
      />
      {
        keyboardType === "numeric" && isValueChangedValid && isNumeric(value,excludeIsNumeric) === false ? (
          <ErrorComponentMessageError 
          userSettingsState={userSettingsState} 
          errorMessage={i18n.t('textInput.numeric_onlyNumbers')} 
          styleTextInputContainer={styleTextInputContainer}
          />
        )
        : isValueChangedValid && minLetters && value.length < minLetters ? (
          <ErrorComponentMessageError 
          userSettingsState={userSettingsState} 
          errorMessage={`${i18n.t('textInput.numeric_atleastLetters1')} ${minLettersShowcase} ${i18n.t('textInput.numeric_atleastLetters2')}`} 
          styleTextInputContainer={styleTextInputContainer}
          />
        )
        : maxLetters && value.length > maxLetters ? (
          <ErrorComponentMessageError 
          userSettingsState={userSettingsState} 
          errorMessage={`${i18n.t('textInput.numeric_noMoreThan1')} ${maxLettersShowcase} ${i18n.t('textInput.numeric_noMoreThan2')}`} 
          styleTextInputContainer={styleTextInputContainer}
          />
        )
        : (isRequired && !isValid) ? (
        <ErrorComponentMessageError 
        userSettingsState={userSettingsState} 
        errorMessage={errorMessage}
        styleTextInputContainer={styleTextInputContainer}
        />
      ) : null}
      </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    container: {
    },
    paddingBottomcontainer: {
      paddingBottom: 20,
    },
text: {
  fontFamily: GlobalStyles.fonts.application_font,
}
});

export default TextInputWithErrorComponent;