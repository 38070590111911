export default {
    numeric_onlyNumbers: "Please enter a number",
    numeric_atleastLetters1: "Please enter at least",
    numeric_atleastLetters2: "characters",
    numeric_noMoreThan1: "Please do not enter more than",
    numeric_noMoreThan2: "characters",
    expirationDate_monthError: `Please enter a valid month (01-12).`,
    expirationDate_yearError: `Please enter a valid year greater than the current year.`,
    ImagePickerPopupWebComponent_SubmitButton: `Select image`,
    ImagePickerPopupWebComponent_CancelButton: `Cancel`,
    LanguageModal_Cancel: "Cancel",
    datePicker_modalTitle: "Select a Date",
    datePicker_cancelButton: "Close",
  };