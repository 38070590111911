import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const DisplayTypeComponent = ({ 
    userSettingsState, 
    displayType, 
    setDisplayType, 
    showArchive,
    admins = [],
    tributes = [],
   }) => {

    const PendingAdminInvitationsCount = admins.filter(s => s.IsInvitations === true).length;
    const PendingTributesInvitationsCount = tributes.filter(s => 
      s.IsActive === true && (s.IsInvitation === true && s.IsInvitationAccepted === false)
    ).length;
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.bottomCardContainer}>

<TouchableOpacity 
    style={[styles.cardContainer, displayType === 'Bio' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Bio')}
    >
    <Text style={[styles.text, displayType === 'Bio' ? styles.onfocusText : null]}>
      {i18n.t('account.MyAccount_EditProfile_DisplayTypeComponent_Bio')}
      </Text>
    </TouchableOpacity>

    
    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Media' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Media')}
    >
    <Text style={[styles.text, displayType === 'Media' ? styles.onfocusText : null]}>
    {i18n.t('account.MyAccount_EditProfile_DisplayTypeComponent_Media')}
      </Text>
    </TouchableOpacity>

    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Tributes' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Tributes')}
    >
    <Text style={[styles.text, displayType === 'Tributes' ? styles.onfocusText : null]}>
    {i18n.t('account.MyAccount_EditProfile_DisplayTypeComponent_Tributes')}
      </Text>
      { PendingTributesInvitationsCount > 0 ?
      <View style={styles.editButtonContainer}>
        <Text style={[styles.editButtonText]}>{PendingTributesInvitationsCount}</Text>
      </View>
    : null}
    </TouchableOpacity>

    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Admins' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Admins')}
    >
    <Text style={[styles.text, displayType === 'Admins' ? styles.onfocusText : null]}>
    {i18n.t('account.MyAccount_EditProfile_DisplayTypeComponent_Admins')}
      </Text>
      {PendingAdminInvitationsCount > 0 ?
      <View style={styles.editButtonContainer}>
        <Text style={[styles.editButtonText]}>{PendingAdminInvitationsCount}</Text>
      </View>
    : null}
    </TouchableOpacity>


  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  bottomCardContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    marginTop: 20,
    marginHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.135 : null,
    // backgroundColor:'blue'
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    alignSelf: 'center',
  },
  onfocus:{
    // borderBottomColor: GlobalStyles.colors.selectedItemColor,
    // borderBottomWidth: 1,
  },
  onfocusText: {
    color: GlobalStyles.colors.selectedItemColor,
    fontWeight: '700',
    // minWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 20 : responsiveStyles.screenWidth / 6,
    // maxWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 20 : responsiveStyles.screenWidth / 6,
    borderBottomColor: GlobalStyles.colors.selectedItemColor,
    borderBottomWidth: 2,
    paddingBottom: 10,
  },
  cardContainer: {
    flex:1,
  },
  cardBorderContainer: {
    borderRightWidth: responsiveStyles.isRTL ? 3 : null,
    borderLeftWidth: !responsiveStyles.isRTL ? 3 : null,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.selectedItemBackgroundColor,
  },
  editButtonContainer: {
    position: 'absolute',
    // left: !responsiveStyles.isRTL ? null : 
    // responsiveStyles.isRTL ? null :
    // responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.05
    // : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.02
    // : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.04
    // : 0,
    // right: responsiveStyles.isRTL ? null :
    // responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.05
    // : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.02
    // : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.04
    // : 0,
    left: !responsiveStyles.isRTL ? null : 
    responsiveStyles.isRTL ? null :
    responsiveStyles.isWeb ? 10 : 0,
    right: responsiveStyles.isRTL ? null :
    responsiveStyles.isWeb ? 10 : 0,
    top: -10,
    borderWidth:2, 
    borderRadius: 50,
    backgroundColor: GlobalStyles.colors.Lavender,
    width: !responsiveStyles.isMobile ? 25 : 20,
    height: !responsiveStyles.isMobile ? 25 : 20,
  },
  editButtonText: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: !responsiveStyles.isMobile ? responsiveStyles.fontSize_H6 : 10,
    fontFamily: GlobalStyles.fonts.application_font,
},
});

export default DisplayTypeComponent;