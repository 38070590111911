import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, Dimensions, useWindowDimensions, Platform } from 'react-native';
import { MainLogo, SecondaryLogo } from '../../constants/images';
import { GlobalStyles, Gold } from '../../constants/styles';
import i18n from '../../hooks/useI18n';
import { navigate } from '../../navigationRef';
import { navigateToScreen } from '../../navigationResponsive';
import CompanyFooter from '../CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { dialNumber, EmailLink } from '../../functions/shareApp';
import { headerHeight } from '../../constants/ScreenDimensions';
import { getAsset } from '../../functions/getAsset';
import FooterShareIconsComponent from './FooterShareIconsComponent';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}


const BuisnessFooter = ({
  userSettingsState,
  navigation,
}) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

    
  return (
    <View style={styles.box}>
      <View style={styles.wrapper}>
          <View style={styles.gridItem}>
            <View style={styles.footerLogo}>
              <FastImage
                // source={responsiveStyles.isMobile ? SecondaryLogo : MainLogo}
                source={{ uri:MainLogo }}
                // source={{ uri: getAsset(require('../../../assets/images/store/buisness/logo_main.png')) }}
                // resizeMode="stretch"
                style={styles.logo}
              />
            </View>
          </View>
        <View style={styles.grid}>
          <View style={styles.footerContainer}>

          
          <View style={[styles.gridItem, 
            { 
              flex: responsiveStyles.isDesktop || responsiveStyles.isLaptop && responsiveStyles.isRTL ? 2 : 1,
            paddingRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.1 : null : 0,
            paddingLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.1 : null : 0,
             }]}>
            <Text style={styles.footerTitle}>{i18n.t('footer.AboutUs')}</Text>
            <View style={styles.footerText}>
            <Text style={[styles.text, responsiveStyles.isDesktop || responsiveStyles.isLaptop ? { minWidth: responsiveStyles.isRTL ? null : responsiveStyles.screenWidth * 0.25 } : null]}>
            {i18n.t('footer.AboutUs_Description')}
              </Text>
            </View>
            <View style={styles.footerContact}>
              {/* <Text style={styles.text}>
              {i18n.t('footer.AboutUs_Address')}
                </Text> */}
              <TouchableOpacity onPress={async () => {
                const BuisnessNumber = i18n.t('footer.Phone');
                await dialNumber(BuisnessNumber);
              }}>
                <Text style={styles.text}>
                {i18n.t('footer.Phone')}
                </Text>
                </TouchableOpacity>
              <TouchableOpacity onPress={async () => {
                await EmailLink();
              }}>
                <Text style={styles.text}>
                {i18n.t('footer.Email')}
                </Text>
                </TouchableOpacity>
                <FooterShareIconsComponent 
                userSettingsState={userSettingsState}
                />
            </View>
          </View>

          <View style={[styles.gridItem, 
            { flex: 1, 
            paddingLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.25 : null : null,
            paddingRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.25 : null : null,
           }
            ]}>
            <Text style={styles.footerTitle}>{i18n.t('footer.FooterLinks_Title')}</Text>
            <View style={styles.footerLinks}>
              <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
                // navigate('StoreFlow', { screen: 'Home' });
                // navigate("Home");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_Home')}
                  </Text>
                  </TouchableOpacity>
              <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `AboutUs`, null, null);
                // navigate('StoreFlow', { screen: 'AboutUs' });
                // navigate("AboutUs");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_AboutUs')}
                  </Text>
                  </TouchableOpacity>

              {/* <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `Ambassador`, null, null);
                // navigate('StoreFlow', { screen: 'Ambassador' });
                // navigate("Ambassador");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_AMBASSADOR')}
                  </Text>
                  </TouchableOpacity> */}

              <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `Help`, null, null);
                // navigate('StoreFlow', { screen: 'Help' });
                // navigate("Help");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_Help')}
                  </Text>
                  </TouchableOpacity>
              <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `TermsAndConditions`, null, null);
                // navigate('StoreFlow', { screen: 'TermsAndConditions' });
                // navigate("TermsAndConditions");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_Terms')}
                  </Text>
                  </TouchableOpacity>

              <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `Privacy`, null, null);
                // navigate('StoreFlow', { screen: 'Privacy' });
                // navigate("Privacy");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_Privacy')}
                  </Text>
              </TouchableOpacity>

              <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `ContactUs`, null, null);
                // navigate('StoreFlow', { screen: 'ContactUs' });
                // navigate("ContactUs");
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_ContactUs')}
                  </Text>
                  </TouchableOpacity>

            <TouchableOpacity 
              onPress={() => {
                navigateToScreen(navigation, 'StoreFlow', `Tutorial`, null, null);
              }}
              >
                <Text style={styles.text}>
                  {i18n.t('footer.FooterLinks_Tutorial')}
                  </Text>
            </TouchableOpacity>
            </View>
          </View>
          </View>
        </View>
      </View>
      <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 35, paddingLeft: 17 }} />
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  box: {
    // backgroundColor: '#272727',
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].SecondrayBackgroundColor,

    // borderTopWidth: responsiveStyles.isWeb ? "0.1rem" : 0.5,
    borderTopColor: Gold,
  },
  wrapper: {
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : 20,
    paddingVertical: 30,
  },
  grid: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexWrap: 'wrap',
  },
  gridItem: {
    width: '100%', // adjust as needed
    marginBottom: 20,
    
  },
  footerLogo: {
    alignItems: 'center',

  },
  logo: {
    // width: responsiveStyles.screenWidth * 0.4,
    // // height: 40,
    // aspectRatio: 16 / 9,
    // // maxHeight: 120,
    // backgroundColor:'blue'

    flex:1,
    aspectRatio: 20 / 6,
    // aspectRatio: responsiveStyles.isMobile ? 20 / 5 : 20 / 6,
    height: headerHeight * 2,
    // transform: [ { scale: 2 } ],
    // marginVertical: headerHeight / 2,
  },
  footerTitle: {
    fontSize: responsiveStyles.fontSize_H4,
    fontWeight: 'bold',
    marginBottom: 10,
    // color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorOpposite,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    // textAlign: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  footerText: {
    marginBottom: 10,
  },
  footerContact: {

  },
  text: {
    marginBottom: 5,
    // color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorOpposite,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontSize: responsiveStyles.fontSize_H6,
    textAlign: responsiveStyles.isRTL ? 'right' : 'left',
    lineHeight: responsiveStyles.fontSize_H6 * 1.5,
  },
  footerLinks: {},
  footerContainer: {
    flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    flex:1,
  },
});

export default BuisnessFooter;
