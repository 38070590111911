import { defaultImage } from "../constants/images";

export const UserProfile_user = {
    UserImage: defaultImage.uri,
    UserName: "User name",
    Likes: 1000,
    Title: "Title",
    Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dignissim consectetur risus nec sagittis. Sed nec erat ligula. Integer ut metus id arcu tincidunt ultrices. Quisque tincidunt massa vitae velit convallis feugiat. Nam suscipit nisl vitae libero consectetur, ut auctor orci fermentum. Morbi commodo, ligula nec consequat faucibus, purus risus fermentum arcu, non fermentum urna sapien id leo.",
    CreatedDate: "21 במרץ",
  };

export const images = [
    { id: '1', type: 'image', uri: defaultImage.uri },
    { id: '2', type: 'video', uri: "https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4" },
    { id: '3', type: 'image', uri: "https://picsum.photos/200/300" },
    { id: '4', type: 'video', uri: "https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4" },
    { id: '5', type: 'image', uri: "https://picsum.photos/200/301" },
    { id: '6', type: 'image', uri: "https://picsum.photos/200/302" },
    // Add more images here
];

export const comments = [
    {
        Id: 1,
        UserId: "",
        UserName: "asfjio",
        Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dignissim consectetur risus nec sagittis. Sed nec erat ligula. Integer ut metus id arcu tincidunt ultrices. Quisque tincidunt massa vitae velit convallis feugiat. Nam suscipit nisl vitae libero consectetur, ut auctor orci fermentum. Morbi commodo, ligula nec consequat faucibus, purus risus fermentum arcu, non fermentum urna sapien id leo.",
        CreatedDate: "2d",
    },
    {
        Id: 2,
        UserId: "",
        UserName: "yrt67",
        Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dignissim consectetur risus nec sagittis. Sed nec erat ligula. Integer ut metus id arcu tincidunt ultrices. Quisque tincidunt massa vitae velit convallis feugiat. Nam suscipit nisl vitae libero consectetur, ut auctor orci fermentum. Morbi commodo, ligula nec consequat faucibus, purus risus fermentum arcu, non fermentum urna sapien id leo.",
        CreatedDate: "3d",
    },
];