import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image, Platform, ActivityIndicator } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { convertImageToBase64 } from "../../functions/imageLogic";
import ImageModal from "./ImageModal";
import { AntDesign } from '@expo/vector-icons';
import { GlobalStyles, Gold } from "../../constants/styles";
import { defaultImage } from "../../constants/images";
import { Feather } from '@expo/vector-icons'; 
import { askForPermissions, pickImage } from "../../functions/pickImage";
import ImageMediaModal from "./ImageMediaModal";
import { navigate } from "../../navigationRef";
import { FontAwesome } from '@expo/vector-icons';
import { getNavigationParams } from "../../functions/navigationLogic";
import { navigateToScreen } from "../../navigationResponsive";
import VideoImage from "./VideoImage";
import VideoMediaPrimary from "./VideoMediaPrimary";
import { Asset } from "expo-asset";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import PlayCircle from "./PlayCircle";
import { getThumbnailAsync } from "expo-video-thumbnails";
import { getAsset } from "../../functions/getAsset";

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const ImageMedia = ({ 
    fileURI = null,
    resizeMode = null,
    isUriWithSource = false,
    imageStyle,
    userName = null,
    showMultipleFiles = false,
    showVideoIcon = false,
    showAudioIcon = false,
    UserId = "",
    PostId = 0,
    JewelryId = null,
    isEditing = false,
    navigation,
    cleanup,
    userSettingsState,
}) => {
  const imagePic = fileURI === null || fileURI.length === 0  || fileURI?.uri?.length === 0 ?
  { uri: defaultImage }
  :isUriWithSource ? fileURI : { uri: fileURI };
  const errorURI = getAsset(require('../../../assets/images/error/image_url_not_valid.png'));
  // const errorURI = "";
  const [thumbnail, setThumbnail] = useState(null);
  // useEffect(() => {
  //   if(!thumbnail && (showVideoIcon || showAudioIcon)){
  //     const getThumbnail = async () => {
  //       const { uri } = await getThumbnailAsync(fileURI?.uri, {
  //         time: 500, // time to take the thumbnail from (in milliseconds)
  //       });
  //       console.log(`getThumbnail, uri is ${uri}`);
        
  //       setThumbnail(uri);
  //       return uri;
  //     }
  //     getThumbnail();
  //   }
  // }, []);
const responsiveStyles = useResponsiveStyles(userSettingsState);

    const styles = styling(responsiveStyles);
    const [modalVisible, setModalVisible] = useState(false);

    const [isReloading, setIsReloading] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);


    const onLoadError = () => {
      console.log(`error on image`);
      setIsErrorImage(true);
    };

    const showImageModal = () => {
      setModalVisible(true);
    };
  
    const closeModal = () => {
      setModalVisible(false);
    };
  
    const onButtonPress = () => {
      const navigationParams = getNavigationParams(navigation);
      const FromAccount = navigationParams?.FromScreen;
      console.log(`FromAccount is ${FromAccount}`);
      if(isEditing){
        navigateToScreen(navigation, 'AccountFlow', `ManageMediaPost`, { UserId: UserId, PostId : PostId, },
          `${UserId}/${PostId}`
          //  PostId
          );
        // navigation.navigate('AccountFlow', { screen: 'ManageMediaPost', params:  { UserId: UserId, PostId : PostId, } });
        // navigation.navigate("ManageMediaPost", { UserId: UserId, PostId : PostId, });
      }
      else{
        navigateToScreen(navigation, 'AccountFlow', `ImageMedia`, { UserId: UserId, PostId : PostId, JewelryId: JewelryId }, 
          JewelryId ? `${JewelryId}/${PostId}` : PostId
        );
      }
      // showImageModal();
      if(cleanup){
        cleanup();
      }
    };
  return (
    <>
    <TouchableOpacity onPress={onButtonPress}>
      {
            isErrorImage ? 
            <FastImage
            style={[imageStyle,{ borderWidth:1, borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor }]}
            source={{ uri: errorURI }}
            resizeMode={resizeMode}
            />
            // <View style={[imageStyle, styles.activityIndicator, styles.imageError]}>
            //     <Feather name="slash" size={35} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
            // </View>
            :
      isReloading ? 
    <View style={[imageStyle, styles.activityIndicator]}>
        <ActivityIndicator
            size={35}
            color={GlobalStyles[responsiveStyles.ThemeValue]?.ActivityIndicatorColor}
        />
    </View>
    : null
    }
    {
      (imagePic?.uri?.includes("mp4") || imagePic?.uri?.includes("mp3")) ? 
      (
        
          !responsiveStyles.isWeb ?
          <>
        <FastImage
        resizeMode={resizeMode} 
        style={[
          imageStyle,isReloading || isErrorImage ? styles.isReloadingImageStyle :  null,
          showAudioIcon ? { backgroundColor:'black' } : null
        ]} 
        source={imagePic} 
        // source={thumbnail ? thumbnail : imagePic} 
        onError={() => {
          if(!showAudioIcon)
          onLoadError();
        }}
         />
       {
       showAudioIcon ? <PlayCircle
        userSettingsState={userSettingsState}
        shouldPlay={false}
        isAudio={true}
        allowPlayVideo={false}
        isFullScreen={false}
        />
      : null}
         </>
        : 
        <VideoMediaPrimary
        userSettingsState={userSettingsState}
        resizeMode={resizeMode}
        videoUri={imagePic?.uri}
        videoStyle={imageStyle}
        editable={false}
        allowPlayVideo={false}
        onTouchableOpacityPress={onButtonPress}
        isAudio={imagePic?.uri?.includes("mp3")}
        // durationTime={albumItem.Duration}
        // videoContainerStyle={[{backgroundColor: null}, IsAudio ? { borderWidth: 1, borderColor: Gold } : null]}
        // isAudio={IsAudio}
      />
      )
      :
      (
        <FastImage
        resizeMode={resizeMode} 
        style={[
          imageStyle,
          isReloading || isErrorImage ? styles.isReloadingImageStyle :  null,
          { borderWidth:1, },
        ]} 
        source={imagePic} 
        onError={onLoadError}
        
         />
      )
    }

        <ImageMediaModal
        userSettingsState={userSettingsState}
        resizeMode={resizeMode} 
        imageUri={imagePic}
        visible={modalVisible}
        onClose={closeModal}
        chatType="user"
        userName={userName}
      />
            {
            isReloading ? null :
            showMultipleFiles ? (
          <TouchableOpacity style={styles.showMultipleFilesButtonContainer}  onPress={onButtonPress}>
          <FontAwesome name="files-o" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconMediaColor} />
          </TouchableOpacity>
      ) : showAudioIcon ? (
        <TouchableOpacity style={styles.showMultipleFilesButtonContainer}  onPress={onButtonPress}>
          <FontAwesome name="file-audio-o" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconMediaColor} />
          </TouchableOpacity>
      ) : showVideoIcon ? (
        <TouchableOpacity style={styles.showMultipleFilesButtonContainer}  onPress={onButtonPress}>
          <Feather name="video" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconMediaColor} />
          </TouchableOpacity>
      )
      : null
      }
      {/* {showEditButton ? (
          <TouchableOpacity style={styles.editButtonContainer}  onPress={onButtonPress}>
          <Feather iconName="edit-2" name="edit-2" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
      ) : null
      }
      {showOptionButton ? (
      <View style={styles.optionButton}>
        {showCloseButton ? 
        <AntDesign name="closecircle" size={24} color={"#b8b8b8"} />
        : <AntDesign name="checkcircle" size={24} color={"#00a984"} />
      }
      </View>
      )
      : null
    }
    <View style={styles.progressBarContainer}>
            <View style={styles.timestampContainer}>
            <View style={{ flexDirection:'row', alignItems:'center' }}>
              <Text style={[styles.groupText]}>{createdTime}</Text>
              </View>
            </View>
          </View> */}
  </TouchableOpacity>
  </>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  optionButton: {
    position: 'absolute',
    left: -15,
    bottom: -5,
    borderWidth:2, 
    borderRadius: 50,
  },
  progressBarContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 5,
  },
  timestampContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  editButtonContainer: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    borderWidth:2, 
    borderRadius: 50,
    padding: 5,
    backgroundColor: GlobalStyles.colors.Lavender,
  },
  showMultipleFilesButtonContainer: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  activityIndicator: {
    justifyContent: 'center',
     alignItems: 'center'
  },
  isReloadingImageStyle: {
    width: 0,
    height: 0,
  },
  imageError: {
    borderWidth:1,
    borderColor: Gold,
  },
});
export default ImageMedia;
