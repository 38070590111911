import { Asset } from "expo-asset";
import { Image, Platform } from "react-native";

export const getAsset = (url) => {
    if(Platform.OS === "web"){
        const res = Asset.fromModule(url).uri;
        return res;
    }
    else{
        const res = Image.resolveAssetSource(url).uri;
        return res;
    }
  };