import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
// import { withNavigation } from 'react-navigation';
import { GlobalStyles } from '../constants/styles';
import { navigateToScreen } from '../navigationResponsive';

const NavLink = ({ navigation, text, routeStack, routeName }) => {
  return (
    <TouchableOpacity onPress={() => {
      navigateToScreen(navigation, routeStack, routeName, null, null);
      // navigation.navigate(routeName)
    }} style={{margin:15}}>
        <Text style={styles.link}>{text}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  link: {
    color: '#5187cf',
    fontWeight:'800',
    textAlign:'center',
    fontFamily: GlobalStyles.fonts.application_font,
  }
});

export default NavLink;
