import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform } from 'react-native';
import { Context as UserSettingsContext } from "../context/UserSettingsContext";
import { Context as AuthContext } from "../context/AuthContext";
import { Context as ShopContext } from "../context/ShopContext";
import { initializeApp } from '../hooks/initializeApp';
const AppContainer = ({ children }) => {

    const userSettingsContext = useContext(UserSettingsContext);
    const authContext = useContext(AuthContext);
     const shopContext = useContext(ShopContext);
    useEffect(() =>{
        initializeApp(userSettingsContext, authContext, shopContext);
    },[]);

  return <>
  {children}
  </>

}

AppContainer.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
};

export default AppContainer;
