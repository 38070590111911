import Language from '../../objects/Language';

export const languages: Language[] = [{
    name: 'English (US)',
    language: 'en',
    userSettingsLanguage: 'en-US',
    id: 0,
}, {
    name: 'עברית',
    language: 'he',
    userSettingsLanguage: 'he-IL',
    id: 1,
}];