import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  BackHandler,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native'; // Import useFocusEffect from @react-navigation/native
import { Context as AuthContext } from '../../context/AuthContext';
import SignUpConfirmUserNameAndPasswordByEmailForm from '../../components/forms/SignUpConfirmUserNameAndPasswordByEmailForm';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import { SignUpConfirmUserNameAndPasswordByEmailAlert } from '../../components/alert/SignUpConfirmUserNameAndPasswordByEmailAlert';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const SignUpConfirmUserNameAndPasswordByEmailScreen = ({  }) => {
  
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [usernameRequired, setUsernameRequired] = useState(false);

  const location = responsiveStyles.isWeb ? useLocation() : null;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const email = navigationParams?.email;
  
  const { state, addErrorMessage, ConfirmUserNameAndPasswordByEmail, ValidateUserNameNotTaken } = useContext(AuthContext);
	const styles = styling(responsiveStyles);

  const [userName, setUserName] = useState('');

  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  // useEffect to handle isFirstTime state
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Sign Up Confirm UserName And Password By Email";
    }
    if (isFirstTime) {
      setIsFirstTime(false);
      addErrorMessage("signupConfirmUserNameAndPasswordByEmailAlertMessage", "");
    }
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
  return () => backHandler.remove();
    
  }, [state.signupConfirmUserNameAndPasswordByEmailAlertMessage?.length]);

  // useFocusEffect to handle focus-related effects
  if(responsiveStyles.isWeb){
  }
  else{
    useFocusEffect(
      React.useCallback(() => {
        // Function to run when screen gains focus
        setShowLoader(false); // Example: Resetting loader state
        // Perform other focus-related actions here
  
        // Clean-up function when screen loses focus (optional)
        return () => {
          // Perform any clean-up here if necessary
        };
      }, [])
    );
  }

  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
      <KeyboardAvoidingView
        behavior={'padding'}
        style={styles.container}
        >
    <ScrollView 
        contentContainerStyle={styles.scrollViewContent}
        keyboardShouldPersistTaps='handled'
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
          {/* <BackButtonComponent userSettingsState={userSettingsState} navigation={navigation} onPress={backAction} /> */}
          <SignUpConfirmUserNameAndPasswordByEmailAlert
          userSettingsState={userSettingsState}
            navigation={navigation}
            state={state}
            addErrorMessage={addErrorMessage}
            username={userName}
          />
          <SignUpConfirmUserNameAndPasswordByEmailForm
          userSettingsState={userSettingsState}
            errorMessage={state.errorMessage}
            onSubmit={ConfirmUserNameAndPasswordByEmail}
            ShowPassword={false}
            navigation={navigation}
            email={email}
            signupConfirmUserNameAndPasswordByEmailAlertMessage={state.signupConfirmUserNameAndPasswordByEmailAlertMessage}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            userName={userName}
            setUserName={setUserName}
            ValidateUserNameNotTaken={ValidateUserNameNotTaken}
            validateUserName={state.validateUserName}
            validateUserNameShowLoader={state.validateUserNameShowLoader}
            usernameRequired={usernameRequired}
            setUsernameRequired={setUsernameRequired}
          />
          
          <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
          
        </View>
      </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    </Screen>
  );
};

SignUpConfirmUserNameAndPasswordByEmailScreen.navigationOptions = {
  header: () => false,
};

const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      // minHeight: responsiveStyles.screenHeight,
    },
    scrollViewContent: {
      flexGrow: 1,
      // minHeight: 
      // responsiveStyles.isWeb ?
      //   responsiveStyles.screenHeight - (4 * 20)
      // : responsiveStyles.screenHeight + 60,
    },
    inner: {
      padding: 15,
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      // minHeight: responsiveStyles.screenHeight - (4 * 20),

    },
  });

export default SignUpConfirmUserNameAndPasswordByEmailScreen;
