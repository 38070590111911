import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import ImageProfile from "../images/ImageProfile";
import DateComponent from "../inputs/item/DateComponent";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { convertUTCtoLocal } from "../../functions/convertUTCtoLocal";
import { GenericAlert } from "../alert/GenericAlert";
import SelectDropdown from "react-native-select-dropdown";
import { Feather } from '@expo/vector-icons'; 
import { getPaddingScreen } from "../../functions/ScreenDimensionsLogic";
import BottomCardComponent from "../profileScreen/BottomCardComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { AntDesign } from '@expo/vector-icons';
import ManagePetProfileForm_PetTypeComponent from "./ManagePetProfileForm_PetTypeComponent";
import ManagePetProfileForm_GenderComponent from "./ManagePetProfileForm_GenderComponent";
import ManagePetProfileForm_EnergyLevelComponent from "./ManagePetProfileForm_EnergyLevelComponent";
import ManagePetProfileForm_Behavior_DogsComponent from "./ManagePetProfileForm_Behavior_DogsComponent";
import ManagePetProfileForm_Behavior_CatsComponent from "./ManagePetProfileForm_Behavior_CatsComponent";
import ManagePetProfileForm_Behavior_ChildrenComponent from "./ManagePetProfileForm_Behavior_ChildrenComponent";
import ManagePetProfileForm_Behavior_StragersComponent from "./ManagePetProfileForm_Behavior_StrangersComponent";
import ManagePetProfileForm_Behavior_OtherAnimalsComponent from "./ManagePetProfileForm_Behavior_OtherAnimalsComponent";

const ManagePetProfileForm_Header = ({ 
    userSettingsState,
    onPress,
    text,
    visible = false,
}) => {

    

    const responsiveStyles = useResponsiveStyles(userSettingsState);
const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
const styles = styling(responsiveStyles);  

    return (
        <TouchableOpacity
            style={styles.rowHeaderContainer}
            onPress={onPress}
            >
                <Text style={styles.headerText}>{text}</Text>
                <AntDesign size={!responsiveStyles.isMobile ? 24 : 18} 
    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
      name={visible ? "caretdown" : responsiveStyles.isRTL ? "caretleft" : "caretright" }
      />
            </TouchableOpacity>
    );
}

const styling = (responsiveStyles) => StyleSheet.create({
      rowHeaderContainer: {
          flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
          justifyContent: 'space-between', 
          alignItems: 'center',
          paddingBottom: 20,
      },
      headerText: {
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H3,
        fontFamily: GlobalStyles.fonts.application_font,
        fontWeight: 'bold',
    },
});

export default ManagePetProfileForm_Header;
