export default {
    Title: "Contact Us",
    description: "We’re here to help! If you have any questions, concerns, or feedback, please reach out to us using the information below or fill out the contact form.",
    ContactUs_Info: "Contact Information",
    ContactUs_Email: "Email:",
    ContactUs_Phone: "Phone:",
    ContactUs_Form: "Contact Form",
    ContactUs_Form_Description: "Please fill out the form below, and we will get back to you within 24-48 hours.",
    FinalWords: "Thank you for choosing TMJ. We look forward to assisting you!",  
    ContactForm_Name: "Name",
    ContactForm_Name_ErrorMessage: "Must enter Name",
    ContactForm_Email: "Email",
    ContactForm_Email_ErrorMessage: "Must enter Email",
    ContactForm_Subject: "Subject",
    ContactForm_Subject_ErrorMessage: "Must enter Subject",
    ContactForm_Message: "Message",
    ContactForm_Message_ErrorMessage: "Must enter Message",
    ContactForm_SendButton: "Send form",
  };