import React, { useState } from 'react';
import { StyleSheet,View,Text, Keyboard, TouchableOpacity, Dimensions, Platform, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton"
import { GlobalStyles } from '../../constants/styles';
import i18n from '../../hooks/useI18n';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';
// import { GoogleSignin, GoogleSigninButton } from '@react-native-google-signin/google-signin';
// import auth from '@react-native-firebase/auth';

const SignUpForm = ({ 
  // showLoader,
  // setShowLoader,
   onSubmit, 
   submitButtonText, 
   isEmail, 
   navigation, 
   signupAlertMessage,
   isKeyboardVisible,
   keyboardDidShowTxt,
   userSettingsState
   }) => {

    
const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

    const [showLoader, setShowLoader] = useState(false);
    const [googleShowLoader, setGoogleShowLoader] = useState(false);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  
  if(signupAlertMessage !== "" && showLoader === true)
  {
    setShowLoader(false); // brings an error
  }
  function ValidateEmail (text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      // Email is Not Correct
      return false;
    }
    else {
      // Email is Correct
      return true;
    }
  }

  const onGoogleButtonPress = async() => {
    return null;
  }
  // GoogleSignin.configure({
  //   webClientId: '619539221125-4cfdpm1njnkho3pfgejibaro3mp4o5ea.apps.googleusercontent.com',
  // });
  // const onGoogleButtonPress = async() => {
  //   if(Platform.OS !== "android" && Platform.OS !== "ios"){
  //     console.log('Google Sign-In is not supported on this platform.');
  //     return;
  //   }
  //   try{
  //     await GoogleSignin.revokeAccess();
  //     await auth().signOut();
  //   }
  //   catch(error)
  //   {
  //     console.log(error);
  //   }
  //   // Check if your device supports Google Play
  //   await GoogleSignin.hasPlayServices({ showPlayServicesUpdateDialog: true });
  //   // Get the users ID token
  //   const { idToken } = await GoogleSignin.signIn();
  //   // Create a Google credential with the token
  //   const googleCredential = auth.GoogleAuthProvider.credential(idToken);
  //   // Sign-in the user with the credential
  //   const user_sign_in = auth().signInWithCredential(googleCredential);
  //   user_sign_in.then(async (user) => {
  //     console.log(user);
  //     setGoogleShowLoader(true);
  //     Keyboard.dismiss();
  //     const response = await onSubmit({ email: user.user.email, idToken });
  //     setGoogleShowLoader(false);
  //     if(response.isCompleted === true)
  //     {
  //      navigateToScreen(navigation, 'LoginFlow', `SignUpConfirmEmailCode`, { email: user.user.email }, null);
  // //       navigation.navigate('LoginFlow', { screen: 'SignUpConfirmEmailCode', params:  { email: user.user.email } });
  // //       navigation.navigate('SignUpConfirmEmailCode', { email: user.user.email });
  //     }
      
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   })
  //   // return user_sign_in;
  // }
  return (
    <View style={styles.container}>
        <Text style={styles.header}>{isEmail === false ? i18n.t('auth.SignUp_headerTypeMobileNumber') : i18n.t('auth.SignUp_headerTypeEmail')}</Text>
        <View style={{ marginBottom:10, paddingBottom: 20 }}>
        <Text style={styles.headerDescription}>
        {isEmail === false ? i18n.t('auth.SignUp_headerDescriptionTypeMobileNumber_1') : i18n.t('auth.SignUp_headerDescriptionTypeEmail_1')}
        </Text>
        <Text style={styles.headerDescription}>
        {isEmail === false ? i18n.t('auth.SignUp_headerDescriptionTypeMobileNumber_2') : i18n.t('auth.SignUp_headerDescriptionTypeEmail_2')}
        </Text>
        </View>
        <View style={styles.textInputContainer}>
      <TextInputCustom
      userSettingsState={userSettingsState}
       editable={true}
       value={email}
       onChangeText={(text) => {
        setEmail(text);
        setIsEmailValid(true);
      }}
      label={isEmail === false ? i18n.t('auth.SignUp_headerMobileNumber') : i18n.t('auth.SignUp_headerEmail')}
      style={[styles.textInput, isEmailValid ? { marginBottom: 15 } : null]}
      iconType="cancel"
      isKeyboardVisible={isKeyboardVisible}
      keyboardDidShowTxt={keyboardDidShowTxt}
      keyboardType={isEmail === false ? "numeric" : 'default'}
      isValid={isEmailValid}
      />
      </View>
      {!isEmailValid ? <Text style={[[GlobalStyles.errorMessage,{ marginBottom: 15 }]]}>{i18n.t('auth.SignUp_emailErrorMessage')}</Text> : null}
      {isEmail === false ? <Text style={{color:GlobalStyles[responsiveStyles.ThemeValue].textColor}}>{i18n.t('auth.SignUp_smsInfo')}</Text> : null}


      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {
          if(isEmail === true && !ValidateEmail(email))
          {
            setIsEmailValid(false);
          }
          else{
            setShowLoader(true);
            Keyboard.dismiss();
            const response = await onSubmit({ email, idToken: null });
            setShowLoader(false);
            if(response.isCompleted === true)
            {
              navigateToScreen(navigation, 'LoginFlow', `SignUpConfirmEmailCode`, { email: email }, null);
              // navigation.navigate('LoginFlow', { screen: 'SignUpConfirmEmailCode', params:  { email: email } });
              // navigation.navigate('SignUpConfirmEmailCode', { email: email });
            }
          }
          
        }}
        styleButton={styles.buttonStyle}
        loading={showLoader}
        >
        {i18n.t('auth.SignUp_SubmitButton')}
          </ButtonTypeButton>
          {/* {googleShowLoader === true ? 
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          loading={googleShowLoader}
          styleButton={{backgroundColor: 'white',marginTop: 14, height: 40, width : responsiveStyles.screenWidth * 0.85,}}
          >
            
          </ButtonTypeButton> : 
            
              Platform.OS !== "android" && Platform.OS !== "ios" ? null :
          <TouchableOpacity style={{ marginHorizontal: -18, marginTop: 10 }} onPress={() => onGoogleButtonPress()} activeOpacity={0.75}>
      <GoogleSigninButton 
      style={{ width: '100%' }}
      onPress={() => onGoogleButtonPress()}
      />
        </TouchableOpacity>
    
   } */}
        </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  textInputContainer: {
    // flex: !responsiveStyles.isMobile ? null : 1,
    marginBottom: responsiveStyles.isWeb ? 15 : 50

  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    fontWeight:'bold',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    paddingBottom: 10,
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H5,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    marginBottom: 5,
    
  },
  btnContainer: {
    marginHorizontal: !responsiveStyles.isMobile ? null : 15,
    alignSelf:'center',
    
  },
  textInput:{
    height: 40,
    borderWidth: 1,
    padding: 10,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    // marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  // buttonStyle: {
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor, 
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
  //   alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  // },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default SignUpForm;
