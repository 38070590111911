import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Text, ScrollView, FlatList, BackHandler } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import LoadingScreen from '../../components/LoadingScreen';
import ErrorComponent from '../../components/ErrorComponent';
import { LineChart, BarChart, PieChart } from 'react-native-chart-kit';
import { Dimensions } from 'react-native';
import { GetFinancialData } from '../../api/apiCalls/AdminCalls';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('window');

const FinancialAnalysisScreen = ({ }) => {
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const styles = styling(responsiveStyles);
  const [bodymessge, setbodymessge] = useState('');
  const [GenericAlertVisible, setGenericAlertVisible] = useState(false);


  const [loading, setLoading] = useState(true);
  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const [financialData, setFinancialData] = useState(null);
  // const financialData = {
  //   TotalOrders: 1234,
  //   TotalRevenue: 567890.50,
  //   TotalItemsSold: 3456,
  //   AverageOrderValue: 460.30,
  //   StockLevels: [
  //     { Name: 'Product A', Stock: 150, Pending: 100, Remaining: 100 },
  //     { Name: 'Product B', Stock: 80, Pending: 100, Remaining: 100 },
  //     { Name: 'Product C', Stock: 120, Pending: 100, Remaining: 100 },
  //     { Name: 'Product D', Stock: 90, Pending: 100, Remaining: 100 },
  //   ],
  //   TopSellingProducts: [
  //     { Id: '1', Name: 'Product A', UnitsSold: 500 },
  //     { Id: '2', Name: 'Product B', UnitsSold: 300 },
  //     { Id: '3', Name: 'Product C', UnitsSold: 200 },
  //     { Id: '4', Name: 'Product D', UnitsSold: 150 },
  //   ],
  //   RevenueTrends: {
  //     Labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  //     Data: [45000, 47000, 43000, 49000, 51000, 52000, 55000, 58000, 60000, 62000, 63000, 65000],
  //   },
  //   OrderTrends: {
  //     Labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  //     Data: [100, 120, 90, 110, 130, 140, 150, 160, 170, 180, 190, 200],
  //   },
  // };
  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  useEffect(() => {
    // Fetch the financial data when the screen loads
    async function fetchData() {
      try {
        setErrorOnRequest(false);
        const req = await GetFinancialData();
        if (req.isCompleted) {
          const vm = JSON.parse(req.answer);
          setFinancialData(vm);
        }
        else{
          setLoading(true);
          setErrorOnRequest(true);
        }
      } catch (error) {
        setErrorOnRequest(true);
      }
      finally{
        setLoading(false);
      }
    }
      fetchData();
      if(responsiveStyles.isWeb){
        document.title = "Admin Orders List";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
  }, [reloadPage]);


  console.log();

  
  const { 
    TotalOrders = 0,
    TotalRevenue = 0,
    TotalItemsSold = 0,
    AverageOrderValue = 0,
    StockLevels = [],
    TopSellingProducts = [],
    RevenueTrends = { 
      Labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], 
      Data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] 
    },
    OrderTrends = { 
      Labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], 
      Data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] 
    },
  } = financialData || {};
  
   console.log(`financialData is`);
   console.log(financialData);
   
   

  return (
    <Screen 
    style={styles.container} 
    userSettingsState={userSettingsState}
    navigation={navigation}
    >
      {errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadPage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen
      userSettingsState={userSettingsState}
       />
    </View> 
    : ( 
      <ScrollView>
        <View style={styles.metricBox}>
          <Text style={styles.metricTitle}>Total Orders:</Text>
          <Text style={styles.metricValue}>{TotalOrders}</Text>
        </View>
        
        <View style={styles.metricBox}>
          <Text style={styles.metricTitle}>Total Revenue:</Text>
          <Text style={styles.metricValue}>₪{TotalRevenue}</Text>
        </View>

        <View style={styles.metricBox}>
          <Text style={styles.metricTitle}>Total Items Sold:</Text>
          <Text style={styles.metricValue}>{TotalItemsSold}</Text>
        </View>

        <View style={styles.metricBox}>
          <Text style={styles.metricTitle}>Average Order Value:</Text>
          <Text style={styles.metricValue}>₪{AverageOrderValue}</Text>
        </View>

        <View style={styles.chartContainer}>
          <Text style={styles.chartTitle}>Revenue Trends:</Text>
          <LineChart
            data={{
              labels: RevenueTrends?.Labels,
              datasets: [{ data: RevenueTrends?.Data }]
            }}
            width={width - 40}
            height={220}
            yAxisLabel="₪"
            chartConfig={chartConfig}
            style={styles.chart}
          />
        </View>

        {/* <View style={styles.chartContainer}>
          <Text style={styles.chartTitle}>Order Trends:</Text>
          <BarChart
            data={{
              labels: OrderTrends?.Labels,
              datasets: [{ data: OrderTrends?.Data }]
            }}
            width={width - 40}
            height={220}
            yAxisLabel=""
            chartConfig={chartConfig}
            style={styles.chart}
          />
        </View> */}

        {/* <View style={styles.chartContainer}>
          <Text style={styles.chartTitle}>Stock Levels:</Text>
          <PieChart
            data={StockLevels?.map((item, index) => ({
              name: item.Name,
              percentage: (item.Stock / TotalItemsSold) * 100,
              color: chartColors[index % chartColors.length], // Cycle through colors
              legendFontColor: "#7F7F7F",
              legendFontSize: 15
            }))}
            width={width - 40}
            height={220}
            chartConfig={chartConfig}
            accessor="percentage"
            backgroundColor="transparent"
            paddingLeft="15"
            style={styles.chart}
          />
        </View> */}

        <View style={styles.tableContainer}>
          <ScrollView horizontal>
            <View style={styles.tableBody}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderText}>Item</Text>
            <Text style={styles.tableHeaderText}>Total Stock</Text>
            <Text style={styles.tableHeaderText}>Pending Deliveries</Text>
            <Text style={styles.tableHeaderText}>Remaining Stock</Text>
          </View>
              {StockLevels?.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{item.Name}</Text>
                  <Text style={styles.tableCell}>{item.Stock}</Text>
                  <Text style={styles.tableCell}>{item.Pending}</Text>
                  <Text style={styles.tableCell}>{item.Remaining}</Text>
                </View>
              ))}
            </View>
          </ScrollView>
        </View>


        <View style={styles.metricBox}>
          <Text style={styles.metricTitle}>Top Selling Products:</Text>
          <FlatList 
            data={TopSellingProducts}
            keyExtractor={(item) => item.Id}
            renderItem={({ item }) => (
              <View style={styles.productItem}>
                <Text style={styles.productName}>{item.Name}:</Text>
                <Text style={styles.productQuantity}>{item.UnitsSold} units sold</Text>
              </View>
            )}
          />
        </View>

      </ScrollView>
    )}
    </Screen>
  );
};

const chartConfig = {
  backgroundGradientFrom: '#ffffff',
  backgroundGradientTo: '#ffffff',
  color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  strokeWidth: 2,
  barPercentage: 0.5,
  useShadowColorFromDataset: false,
};

const chartColors = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
];

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles.primaryBackgroundColor,
    padding: 20,
  },
  metricBox: {
    marginBottom: 20,
    backgroundColor: GlobalStyles.boxBackgroundColor,
    padding: 15,
    borderRadius: 10,
  },
  metricTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: GlobalStyles.primaryTextColor,
  },
  metricValue: {
    fontSize: 22,
    color: GlobalStyles.secondaryTextColor,
  },
  chartContainer: {
    // marginBottom: 20,
    padding: 15,
    // paddingHorizontal: 20,
  },
  chartTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: GlobalStyles.primaryTextColor,
  },
  chart: {
    marginVertical: 8,
    borderRadius: 16,
  },
  productItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
  productName: {
    fontSize: 16,
    color: GlobalStyles.primaryTextColor,
  },
  productQuantity: {
    fontSize: 16,
    color: GlobalStyles.secondaryTextColor,
  },
 tableContainer: {
    marginVertical: 20,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f4f4f4',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  tableHeaderText: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
    color: GlobalStyles.textColor,
    width: 100,
    textAlign: 'center',
  },
  tableBody: {
    backgroundColor: '#fff',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  tableCell: {
    flex: 1,
    fontSize: 16,
    color: GlobalStyles.textColor,
    width: 100,
    textAlign: 'center',
  },
});

export default FinancialAnalysisScreen;
