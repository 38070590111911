import { Dimensions } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

    const windowWidth = Dimensions.get('window').width;
    const windowHeight = Dimensions.get('window').height;
    export const statusBarHeight = getStatusBarHeight();
    export const statusBarHeightFixed = statusBarHeight > 24 ? statusBarHeight - 24 : 0;

    console.log(`statusBarHeight ${statusBarHeight}, statusBarHeightFixed: ${statusBarHeightFixed}`);

    export const headerHeight = windowHeight * 0.09;
    export const footerHeight = windowHeight * 0.08;
    // export const bodyHeight = windowHeight * 0.83;
    const bodyHeightWithoutHeaderAndFotterHeight = windowHeight - (headerHeight + footerHeight);
    export const bodyHeight = statusBarHeight > 24 ? 
    bodyHeightWithoutHeaderAndFotterHeight : 
    bodyHeightWithoutHeaderAndFotterHeight - (statusBarHeight);
    export const fixedScreenHeight = '92%';

    // console.log(`
    // windowHeight ${windowHeight}
    // everything together ${headerHeight + bodyHeight + footerHeight + statusBarHeight}
    // headerHeight ${headerHeight} 
    // bodyHeight ${bodyHeight} 
    // footerHeight ${footerHeight} 
    // statusBarHeight ${statusBarHeight}
    // `);
