import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { isNumeric } from "../../functions/utils";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputCustom from "../inputs/TextInputCustom";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
  const ManagePetProfileForm_TrainedComponent = ({ 
    userSettingsState,
    inputs,
    inputChangedHandler,
    editable,
    textInputNameStyle,
    containerStyle,
   }) => {

    const [isOtherVisible, setIsOtherVisible] = useState(false);
    const [lastValue, setLastValue] = useState("");
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
<View style={[styles.mainContainer, containerStyle]}>
  <View>
    <Text style={styles.subtitle}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_TrainedLevel')}</Text>
  </View>
  <View style={styles.container}>
  <View style={styles.secondaryContainer}>
  <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_TrainedLevel.value === "Untrained" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_TrainedLevel", "Untrained", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Untrained')}
        </ButtonTypeButton>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_TrainedLevel.value === "Basic" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_TrainedLevel", "Basic", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Basic')}
        </ButtonTypeButton>
        </View>
        <View style={styles.secondaryContainer}>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_TrainedLevel.value === "Trained" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_TrainedLevel", "Trained", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Trained')}
        </ButtonTypeButton>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_TrainedLevel.value === "InTraining" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_TrainedLevel", "InTraining", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_InTraining')}
        </ButtonTypeButton>
    </View>
    </View>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  mainContainer: {
    // backgroundColor: 'blue',
  },
  container: {
    flexDirection: responsiveStyles.isMobile || responsiveStyles.isTablet || responsiveStyles.isLaptop
    ? 'column' : responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  secondaryContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',
    paddingBottom: 15,
  },
  subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontWeight: '500',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
    paddingBottom: 10,
},
styleButton: {
  paddingHorizontal: 25,
},
chosenButton: {
  backgroundColor: Gold,
},
buttonStyle: {
  paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
},
});

export default ManagePetProfileForm_TrainedComponent;