    export const rearrangeDataForRTL = (data, numColumns) => {
        try{
            const rearrangedData = [];
            for (let i = 0; i < data.length; i += numColumns) {
                const rowItems = data.slice(i, i + numColumns);
                rearrangedData.push(...rowItems.reverse());
            }
            return rearrangedData;
        }
        catch(err){
            console.log(`func rearrangeDataForRTL failed, err: ${err}`);
        }
    };