import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, FlatList, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";
import { GenericAlert } from "../alert/GenericAlert";
import CartEmptyListComponent from "../displayComponent/CartEmptyListComponent";
import QuantityStoreItemComponent from "./QuantityStoreItemComponent";
import { Feather } from '@expo/vector-icons';
import { Context as ShopContext, shippingAmount, shippingInternational } from '../../context/ShopContext';
import ShoppingCartComponent from "./ShoppingCartComponent";
import DiscountComponent from "./_DiscountComponent";
import TotalItemsComponent from "./TotalItemsComponent";
import CheckoutButtonComponent from "./CheckoutButtonComponent";
import FloatingCartHeaderComponent from "./FloatingCartHeaderComponent";
import ShoppingCartFullInfoComponent from "./ShoppingCartFullInfoComponent";
import { getTotalPrice } from "../../functions/getShopInfo";
import useResponsiveStyles from "../../functions/ResponsiveStyles";


  const OrderShippingContainer = ({ 
    userSettingsState,
    onChangePress,
    showShippingMeathod = false,
    containerStyle,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);


    const [showOrderSummery, setShowOrderSummery] = useState(false);
    const {state: shopState } = useContext(ShopContext);
    let totalPrice = getTotalPrice(shopState, false, false);

  return (
    <View style={[styles.container, containerStyle]}>
      
    <View style={styles.boxContainer}>
      <View style={styles.titleContainer}>
        <Text style={styles.text}>{i18n.t('store.OrderShippingContainer_ShipTo')}</Text> 
        <TouchableOpacity
        onPress={onChangePress}
        style={{ flexDirection: 'row' }}
        >
          <Feather 
          iconName="edit-2" 
          name="edit-2" 
          size={24} 
          color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
          style={{ paddingHorizontal: 10 }}
          />
        <Text style={[styles.text, styles.changeButton]}>{i18n.t('store.OrderShippingContainer_ChangeButton')}</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.descriptionContainer}>
        <Text style={styles.text}>
          {shopState.shippingDetails.Address}, {shopState.shippingDetails.City}, {shopState.shippingDetails.Country}
          </Text> 
      </View>
    </View>

    {showShippingMeathod ? 
    <>
    <View style={styles.boxFirstStyle}>

    </View>

    <View style={styles.boxContainer}>
      <View style={styles.titleContainer}>
        <Text style={styles.text}>{i18n.t('store.OrderShippingContainer_ShippingMeathod_Title')}</Text> 
      </View>
      <View style={styles.descriptionContainer}>
      <View style={styles.shippingTypeContainer}>
        <Text style={styles.text}>
        {shippingInternational}
          </Text> 
      <Text style={[styles.text, styles.shippingAmount,]}>
        { !responsiveStyles.isRTL ? 
        `· ${shippingAmount} ₪`
        : `₪ ${shippingAmount} ·`
        }
        </Text>
      </View>
      </View>
    </View>
    </>
    : null }

    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    borderRadius: 15,
    borderWidth: 1,
    margin: 20,
    borderColor: GlobalStyles.colors.Lavender,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.35 : responsiveStyles.screenWidth * 0.85,
    alignSelf: 'center',
    marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 10,
  },
  boxContainer: {
    padding: 10,
    paddingHorizontal: 20,
    // backgroundColor:'yellow'
  },
  boxFirstStyle: {
    marginHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  titleContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent:'space-between',
    paddingBottom: 10,
  },
  descriptionContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent:'space-between',
    paddingBottom: 0,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  shippingAmount: {
    paddingHorizontal: 10,
    fontWeight: '800',
  },
  changeButton: {
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  shoppingCartIcon: {
    paddingRight: 10,
  },
  shippingTypeContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  
});

export default OrderShippingContainer;