// initializeApp.js
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useContext } from 'react';
import { DarkMode_Storage, Language_Storage, UserId_Storage, token_Storage } from '../modules/StorageObjects';
import { getItem } from '../modules/Storage';

export const initializeApp = async (UserSettingsContext, AuthContext, ShopContext) => {

    console.log(`initializeApp started`);
    const { setDefaultSettings } = UserSettingsContext;
    const { saveTokenToContext } = AuthContext;
    const { initContext } = ShopContext;
  try {
    setDefaultSettings();
    const token = await AsyncStorage.getItem(token_Storage);
    if (token) {
        // const Language_Storage_Res = await getItem(Language_Storage);
        // const DarkMode_Storage_Res = await getItem(DarkMode_Storage);
        // const UserId_Storage_Res = await getItem(UserId_Storage);

        
        // console.log('func initializeApp found the token!!');
        // console.log(`func initializeApp 
        //     Language_Storage_Res: ${Language_Storage_Res}
        //     DarkMode_Storage_Res: ${DarkMode_Storage_Res}
        //     UserId_Storage_Res: ${UserId_Storage_Res}
        //     `);

        saveTokenToContext();
        initContext();
    } else {
      
      console.log('func initializeApp did not have token');
    }
  } catch (error) {
    console.error('Error initializing app:', error);
  }
};
