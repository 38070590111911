import React from "react";
import { StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const ShareIconsComponent = ({ 
    userSettingsState,
    ProductName,
    ProductPrice,
    URL,
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  // Functions to handle share links for each platform
  const openWhatsApp = () => {
    const message = `${ProductName}, ${ProductPrice}₪
    ${URL}`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
    Linking.openURL(url);
  };

//   const openInstagram = () => {
//     const url = "https://www.instagram.com";
//     Linking.openURL(url);
//   };

  const openFacebook = () => {
    const message = `${ProductName}, ${ProductPrice}₪`;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(URL)}&quote=${encodeURIComponent(message)}`;
    Linking.openURL(facebookUrl);
  };

  const openTwitter = () => {
    const text = `${ProductName}, ${ProductPrice}₪
    ${URL}`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
    Linking.openURL(url);
  };

  const openTumblr = () => {
    const message = `${ProductName}, ${ProductPrice}₪`;
    const tumblrUrl = `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(URL)}&caption=${encodeURIComponent(message)}`;
    Linking.openURL(tumblrUrl);
  };

  const openPinterest = () => {
    const message = `${ProductName}, ${ProductPrice}₪`;
    const pinterestUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(URL)}&description=${encodeURIComponent(message)}`;
    Linking.openURL(pinterestUrl);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.ButtonContaier} onPress={openWhatsApp}>
        <FontAwesome5 name="whatsapp-square" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity>
      {/* <TouchableOpacity style={styles.ButtonContaier} onPress={openInstagram}>
        <FontAwesome5 name="instagram-square" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity> */}
      <TouchableOpacity style={styles.ButtonContaier} onPress={openFacebook}>
        <FontAwesome5 name="facebook-square" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.ButtonContaier} onPress={openTwitter}>
        <FontAwesome5 name="twitter-square" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.ButtonContaier} onPress={openTumblr}>
        <FontAwesome5 name="tumblr-square" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.ButtonContaier} onPress={openPinterest}>
        <FontAwesome5 name="pinterest-square" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1, 
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  ButtonContaier: {
    paddingHorizontal: 5,
  },
});

export default ShareIconsComponent;
