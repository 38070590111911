import React,{ useRef, useEffect, useState } from 'react';
import { StyleSheet,View,Text, SafeAreaView, StatusBar, KeyboardAvoidingView, Platform, Keyboard, Animated, Dimensions } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import Header from './Header';
import { headerHeight, bodyHeight, footerHeight, fixedScreenHeight } from '../constants/ScreenDimensions';
import { getUserSettingsStateProperties } from '../functions/getUserSettingsStateProperties';
import { GlobalStyles } from '../constants/styles';
import HamburgerMenuOpen from './HamburgerMenuOpen';
import StoreMenuOpen from './StoreMenuOpen';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const Screen = ({ 
  children, 
  userSettingsState,
  headerLeftComponent, 
  headerLeftComponentStyle,
  headerRightComponent, 
  headerRightComponentStyle,
  headerTitle, 
  navigation, 
  backAction = null,
  Fullscreen = false,
  isMaxheight = false,
  onlyBodyHeight = false,
  showFooter = true,
  showHeader = true,
  hideStatusBar = false,
  searchVisible = false,
  showBackButton = false,
  showCart = false,
   openCart = false,
   setOpenCart = null,
   cleanup,
 }) => {
  
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const [footerAnimation] = useState(new Animated.Value(1));
  const footerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isStoreMenuOpen, setIsStoreMenuOpen] = useState(false);

  
  useEffect(() => {
    
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      Animated.timing(footerAnimation, {
        toValue: 0,
        duration: 50,
        useNativeDriver: true,
      }).start();
    });

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      Animated.timing(footerAnimation, {
        toValue: 1,
        duration: 25,
        useNativeDriver: true,
      }).start();
    });
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, [footerAnimation]);

  return (
    <KeyboardAvoidingView
    // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    style={{ flex: 1 }}
  >
    <SafeAreaView style={{flex:1}}>
      <StatusBar
        animated={true}
        backgroundColor={responsiveStyles.ThemeValue === 'dark' ? 'black' : 'white'}
        barStyle={responsiveStyles.ThemeValue === 'dark' ? 'light-content' : 'dark-content'}
        hidden={hideStatusBar}
      />

        <HamburgerMenuOpen
        userSettingsState={userSettingsState}
        setIsMenuOpen={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
        navigation={navigation}
        cleanup={cleanup}
        />

        <StoreMenuOpen
        userSettingsState={userSettingsState}
        setIsStoreMenuOpen={setIsStoreMenuOpen}
        isStoreMenuOpen={isStoreMenuOpen}
        navigation={navigation}
        />

      {Fullscreen === true ? null :(
        showHeader === false ? null : 
        <>
        <Header 
        userSettingsState={userSettingsState}
        headerLeftComponent={headerLeftComponent}
        headerLeftComponentStyle={headerLeftComponentStyle}
        headerRightComponent={headerRightComponent}
        headerRightComponentStyle={headerRightComponentStyle}
        headerTitle={headerTitle}
        navigation={navigation}
        backAction={backAction}
        searchVisible={searchVisible}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isStoreMenuOpen={isStoreMenuOpen}
        setIsStoreMenuOpen={setIsStoreMenuOpen}
        showBackButton={showBackButton}
        showCart={showCart}
        openCart={openCart}
        setOpenCart={setOpenCart}
         />
         {/* <View style={{ marginBottom: headerHeight }}> */}
         <View style={ responsiveStyles.isWeb ? { marginBottom: headerHeight } : null}>

         </View>
         </>
      )}
        <View style={
          isMaxheight === true ? 
          onlyBodyHeight === true ? { minHeight:bodyHeight, maxHeight:bodyHeight } : { minHeight:bodyHeight, maxHeight:bodyHeight, flex:1 }
          : onlyBodyHeight === true ? {height:bodyHeight} : {height:bodyHeight, flex:1}
          }>
        
      {children}

        </View>
    </SafeAreaView>
    </KeyboardAvoidingView>
  );
};
Screen.navigationOptions = {
    header: () => false,
  };
const styles = StyleSheet.create({
  header: {
    flex: 0.09,
    backgroundColor: 'white',
  },
  body: {
    flex: 0.83,
  },
  footer: {
    flex: 0.08,
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default Screen;
