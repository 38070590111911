export const token_Storage = "token";
export const FCMToken_Storage = "FCMToken";
export const OfflineDate_Storage = "OfflineDate";
export const DraggableListState_Storage = "DraggableListState";
export const Language_Storage = "Language";
export const DarkMode_Storage = "DarkMode";
export const UserId_Storage = "UserId";
export const IsAdmin_Storage = "IsAdmin";
export const HomeScreenDefault_Storage = "HomeScreenDefault";
export const MainCategoryListScreen_displayType_Storage = "MainCategoryListScreen_displayType";
export const DraggableListStateIsCalled_Storage = "DraggableListStateIsCalled";
export const Brightness_Storage = "Brightness_Storage";
export const BackgroundColor_Storage = "BackgroundColor";
export const BackgroundImage_Storage = "BackgroundImage";
export const FinishInitSync_Storage = "FinishInitSync";
export const notes_Storage = "notes";
export const AllUnreadMessages_Storage = "AllUnreadMessages";
export const canSetInitialReRendering_Storage = "canSetInitialReRendering";
export const ExpoToken_Storage = "ExpoToken";
export const Cart_Storage = "Cart";
export const ShippingDetails_Storage = "ShippingDetails";
export const ShippingMeathod_Storage = "ShippingMeathod";
export const Discount_Storage = "Discount";

export const MyAccountScreen_displayType = "MyAccountScreen_displayType";
export const MyAccountScreen_ProfilesList_displayType = "MyAccountScreen_ProfilesList_displayType";
export const MyAccountScreen_Profiles_displayType = "MyAccountScreen_Profiles_displayType";
export const MyAccountScreen_Jewelry_displayType = "MyAccountScreen_Jewelry_displayType";

export const EditProfileScreen_displayType = "EditProfileScreen_displayType";


export const ProfileScreen_displayType = "ProfileScreen_displayType";
export const ProfileScreen_mediaDisplayTypeTribute = "ProfileScreen_mediaDisplayTypeTribute";
