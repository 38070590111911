import React, { useState } from 'react';
import { View, Text, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import i18n from '../../hooks/useI18n';
import useResponsiveStyles from '../../functions/ResponsiveStyles';


const ringsData = [
  { size: '3', circumference: `44.2 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '3.5', circumference: `45.5 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '4', circumference: `46.8 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '4.5', circumference: `48.0 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '5', circumference: `49.3 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '5.5', circumference: `50.6 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '6', circumference: `51.9 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '6.5', circumference: `53.1 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '7', circumference: `54.4 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '7.5', circumference: `55.7 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '8', circumference: `57.0 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '8.5', circumference: `58.3 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '9', circumference: `59.5 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '9.5', circumference: `60.8 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '10', circumference: `62.1 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '10.5', circumference: `63.4 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '11', circumference: `64.6 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '11.5', circumference: `65.9 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '12', circumference: `67.2 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '12.5', circumference: `68.5 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: '13', circumference: `69.7 ${i18n.t('store.BraceletSizeGuide_mm')}` },
];

const braceletsData = [
  { size: 'X-Small', inches: `4.76-5.25 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `121-133 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: 'Small', inches: `5.26-5.75 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `134-146 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: 'Medium', inches: `5.76-6.25 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `146-159 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: 'Large', inches: `6.26-6.75 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `159-171 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: 'X-Large', inches: `6.76-7.25 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `172-184 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: 'XX-Large', inches: `7.26-7.75 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `184-197 ${i18n.t('store.BraceletSizeGuide_mm')}` },
  { size: 'XXX-Large', inches: `7.76-8.25 ${i18n.t('store.BraceletSizeGuide_in')}`, mm: `197-210 ${i18n.t('store.BraceletSizeGuide_mm')}` },
];

const necklacesData = [
  { lengthIn: `16 ${i18n.t('store.BraceletSizeGuide_in')}`, lengthCm: `40.50 ${i18n.t('store.BraceletSizeGuide_cm')}` },
  { lengthIn: `18 ${i18n.t('store.BraceletSizeGuide_in')}`, lengthCm: `45.75 ${i18n.t('store.BraceletSizeGuide_cm')}` },
  { lengthIn: `20 ${i18n.t('store.BraceletSizeGuide_in')}`, lengthCm: `50.75 ${i18n.t('store.BraceletSizeGuide_cm')}` },
  { lengthIn: `24 ${i18n.t('store.BraceletSizeGuide_in')}`, lengthCm: `61.00 ${i18n.t('store.BraceletSizeGuide_cm')}` },
  { lengthIn: `30 ${i18n.t('store.BraceletSizeGuide_in')}`, lengthCm: `76.25 ${i18n.t('store.BraceletSizeGuide_cm')}` },
  { lengthIn: `36 ${i18n.t('store.BraceletSizeGuide_in')}`, lengthCm: `91.50 ${i18n.t('store.BraceletSizeGuide_cm')}` },
];

const BraceletSizeGuide = ({
    userSettingsState,
}) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);


  const [selectedTab, setSelectedTab] = useState(`necklacesAndPendants`);

  const tabs = [
      `necklacesAndPendants`,
      `bracelets`,
      `rings`,
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case `rings`:
        return (
          <View>
            <View style={styles.headerRow}>
              <Text style={[styles.cell, styles.headerText]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_usSize')}</Text>
              <Text style={[styles.cell, styles.headerText]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_insideCircumference')}</Text>
            </View>
            <FlatList
              data={ringsData}
              keyExtractor={(item) => item.size}
              renderItem={({ item }) => (
                <View style={styles.row}>
                  <Text style={styles.cell}>{item.size}</Text>
                  <Text style={styles.cell}>{item.circumference}</Text>
                </View>
              )}
            />
          </View>
        );
      case `bracelets`:
        return (
          <View>
            <View style={styles.headerRow}>
              <Text style={[styles.cell, styles.headerText]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_braceletsHeader')}</Text>
              <Text style={[styles.cell, styles.headerText]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_wristMeasurements')}</Text>
            </View>
            <FlatList
              data={braceletsData}
              keyExtractor={(item) => item.size}
              renderItem={({ item }) => (
                <View style={styles.row}>
                  <Text style={styles.cell}>{item.size}</Text>
                  <Text style={styles.cell}>{item.inches}</Text>
                  <Text style={styles.cell}>{item.mm}</Text>
                  </View>
              )}
            />
          </View>
        );
      case `necklacesAndPendants`:
        return (
          <View>
            <View style={styles.headerRow}>
              <Text style={[styles.cell, styles.headerText]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_lengthIn')}</Text>
              <Text style={[styles.cell, styles.headerText]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_lengthCm')}</Text>
            </View>
            <FlatList
              data={necklacesData}
              keyExtractor={(item) => item.lengthIn}
              renderItem={({ item }) => (
                <View style={styles.row}>
                  <Text style={styles.cell}>{item.lengthIn}</Text>
                  <Text style={styles.cell}>{item.lengthCm}</Text>
                </View>
              )}
            />
          </View>
        );
      default:
        return null;
    }
  };

  return (
    <View>
      <View style={styles.tabContainer}>
        {tabs.map((tab, index) => (
          <TouchableOpacity key={index} onPress={() => setSelectedTab(tab)}>
            <Text style={[styles.tab, selectedTab === tab && styles.activeTab]}>{i18n.t('store.BraceletSizeGuide_sizeGuide_' + tab)}</Text>
          </TouchableOpacity>
        ))}
      </View>
      {renderContent()}
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  tabContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    justifyContent: 'space-around',
    marginBottom: 10,
  },
  tab: {
    padding: 10,
    fontSize: 16,
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
  },
  headerRow: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  row: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  cell: {
    flex: 1,
    textAlign: 'center',
  },
  headerText: {
    fontWeight: 'bold',
  },
});

export default BraceletSizeGuide;
