import React, { useState, useEffect } from 'react';
import { StyleSheet,View,Text, Keyboard, Dimensions, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton"
import { GlobalStyles } from '../../constants/styles';
import { FontAwesome } from '@expo/vector-icons';
import { navigate } from '../../navigationRef';
import i18n from '../../hooks/useI18n';
import { postAsync } from '../../api/categoryApiCalls';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';
const SignUpConfirmUserNameAndPasswordByEmailForm = ({ 
  errorMessage, 
  onSubmit, 
  navigation, 
  email,
  ThemeValue = 'light',
  isRTL,
  signupConfirmUserNameAndPasswordByEmailAlertMessage,
  // showLoader,
  // setShowLoader,
  userName,
  setUserName,
  ValidateUserNameNotTaken,
  // validateUserName,
  // validateUserNameShowLoader,
  usernameRequired,
  setUsernameRequired,
  userSettingsState,
 }) => {

  
const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);


  const [showLoader, setShowLoader] = useState(false);
  const [abortController, setAbortController] = useState(null); // add new state variable
  const [validateUserName, setValidateUserName] = useState(true); // add new state variable
  const [validateUserNameShowLoader, setValidateUserNameShowLoader] = useState(null); // add new state variable
  
  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort(); // cancel any ongoing API request when component unmounts
      }
    };
  }, [abortController]);
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [passwordTooEasy, setPasswordTooEasy] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [resetLoader, setResetLoader] = useState(true);
    
    function ValidatePassword (text) {
      let reg = /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/;
      if (reg.test(text) === false || text.length < 6) {
        // atleast one number and one charecter
        return false;
      }
      else {
        // Email is Correct
        return true;
      }
    }
    function hasSameLetter(str) {
      const firstChar = str.charAt(0).toLowerCase();
      for (let i = 1; i < str.length; i++) {
        if (str.charAt(i).toLowerCase() !== firstChar) {
          return false;
        }
      }
      return true;
    }
    
  if(signupConfirmUserNameAndPasswordByEmailAlertMessage !== "" && showLoader === true)
  {
    setShowLoader(false); // brings an error
  }

  return (
    <View style={styles.container}>
        <Text style={styles.header}>{i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_header')}</Text>
        <View style={{ paddingBottom: 20 }}>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_headerDescription_1')}
        </Text>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_headerDescription_2')}
        </Text>
        </View>
        <View style={styles.textInputContainer}>
        <TextInputCustom
        userSettingsState={userSettingsState}
      editable={true}
       value={firstName}
       onChangeText={(text) => {
        setFirstName(text);
        setIsNameValid(true);
      }}
      maxLength={50}
      label={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_firstName')}
      style={[styles.textInput,{ marginVertical: 15  }]}
      />
      </View>
      <View style={styles.textInputContainer}>

<TextInputCustom
userSettingsState={userSettingsState}
      editable={true}
       value={lastName}
       onChangeText={(text) => {
        setLastName(text);
        setIsNameValid(true);
      }}
      maxLength={50}
      label={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_lastName')}
      style={[styles.textInput,{ marginVertical: 15  }]}
      />
      </View>

      <View style={styles.textInputContainer}>
        <TextInputCustom
        userSettingsState={userSettingsState}
       editable={true}
       value={userName}
       onChangeText={async (text) => {
        setUserName(text);
        console.log("text: " + text);
        setResetLoader(!resetLoader);
        text.length > 0  ?setUsernameRequired(false) :  setUsernameRequired(true);

        if (abortController) {
          abortController.abort(); // cancel any ongoing API request
        }
        try {
          const newAbortController = new AbortController(); // create new AbortController
    setAbortController(newAbortController); // update state with the new AbortController instance
    setValidateUserNameShowLoader(true);
          const req = await postAsync(
            "/Auth/ValidateUserNameNotTaken",
            text
          );
            const response = req.data;
            if(response.isCompleted)
            {
              const answer = JSON.parse(response.answer);
              setValidateUserName(answer);
          }
          else{
           
          }
        } catch (err) {
          setValidateUserName(false);
        }
        finally
        {
          setValidateUserNameShowLoader(false);
      }
        // ValidateUserNameNotTaken({username: text});
        
      }}
      label={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_userName')}
      style={[styles.textInput,{ marginVertical: 15  }]}
      iconType="loader"
      isLoaderValid={validateUserName}
      loader={validateUserNameShowLoader}
      resetLoader={resetLoader}
      />

      { usernameRequired ?  <Text style={[GlobalStyles.errorMessage]}>
      {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UsernameRequiredErrorMessge')}
      </Text> :
      !validateUserName  ? validateUserNameShowLoader ? null : (
        <Text style={[GlobalStyles.errorMessage]}>
          {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UsernameNotAvailableErrorMessge_part1')}
          {userName}
          {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UsernameNotAvailableErrorMessge_part2')}
          </Text>
      ) : null}
      </View>
      <View style={styles.textInputContainer}>
      <TextInputCustom
      userSettingsState={userSettingsState}
      secureTextEntry={true}
      editable={true}
       value={password}
       onChangeText={(text) => {
        setPassword(text);
        setIsPasswordValid(true);
        
        // setIsEmailValid(true);
      }}
      label={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_password')}
      style={[styles.textInput,{ marginVertical: 15  }]}
      iconType="password"
      />
      {!isPasswordValid  ? (
        password.length === 0 ? <Text style={[GlobalStyles.errorMessage]}>
          {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_PasswordRequiredErrorMessage')}
          </Text> 
        : !passwordTooEasy ? <Text style={[GlobalStyles.errorMessage]}>
          {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_PasswordLengthErrorMessge')}
          </Text>
          : <Text style={[GlobalStyles.errorMessage]}>
          {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_PasswordTooEasyErrorMessge')}
          </Text>
      ) : null}

      {!isNameValid  ? (
       <Text style={[GlobalStyles.errorMessage]}>
          {i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_NameRequiredErrorMessage')}
          </Text> 
      ) : null}
      </View>
      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {
          if(userName.length === 0)
          {
            setValidateUserName(false);
          }
          else if(validateUserName)
          {
            setPasswordTooEasy(false);
            if(userName.length === 0)
            {
              setUsernameRequired(true);
            }
            else{

            
            if(isPasswordValid === false || password.length < 6)
            {
              console.log("password.length " + password.length);
              setIsPasswordValid(false);
            }
            else if(firstName.length < 2 || lastName.length < 2)
            {
                setIsNameValid(false);
            }
            else if(!hasSameLetter(password) === false)
            {
              console.log("hasSameLetter");
              setIsPasswordValid(false);
              setPasswordTooEasy(true);
            }
            else{
              setShowLoader(true);
              Keyboard.dismiss();
              const response = await onSubmit({ email, userName, password, firstName, lastName });
              setShowLoader(false);
              if(response.isCompleted === true)
              {
                navigateToScreen(navigation, 'LoginFlow', `SignUpConfirmation`, { email, userName }, null);
                // navigation.navigate('LoginFlow', { screen: 'SignUpConfirmation', params:  { email, userName } });
                // navigation.navigate('SignUpConfirmation', { email, userName });
              }
            }
          }
          }
        }}
        styleButton={styles.buttonStyle}
        loading={showLoader}
        >
        {i18n.t('auth.Next')}
          </ButtonTypeButton>
        </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    fontWeight:'bold',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    paddingBottom: 10,
    
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H5,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    marginBottom: 5,
  },
  btnContainer: {
    marginHorizontal: !responsiveStyles.isMobile ? null : 15,
    marginVertical: 15,
    alignSelf:'center',
    
  },
  textInput:{
    height: 40,
    borderWidth: 1,
    padding: 10,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    // marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  // buttonStyle: {
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor, 
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
  //   alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  // },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default SignUpConfirmUserNameAndPasswordByEmailForm;
