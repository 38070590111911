import { defaultImage } from "../constants/images";

export const user = {
    UserName: "Itay Dalmeida",
    BirthDate: "01/04/2000",
    DeathDate: "01/04/2170",
    Description: "You only live once, but if you do it right, once is enough.",
    BackgroundImage: defaultImage.uri,
    UserImage: defaultImage.uri,
  };

  export const media = [
    {
        Id: 1,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 2,
        FileCount: 2,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "video",
    },
    {
        Id: 3,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 4,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "video",
    },
    {
        Id: 5,
        FileCount: 5,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 6,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 7,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 8,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 9,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 10,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
    {
        Id: 11,
        FileCount: 1,
        FirstFileURI: defaultImage.uri,
        FirstFileType: "image",
    },
  ];