import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, View, Platform, Dimensions, Image } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from "react-native-reanimated"; // For mobile animation
import { getAsset } from "../../functions/getAsset";
import { transitionTime } from "../../functions/ScreenDimensionsLogic";

let FastImage = null;
if (Platform.OS === "web") {
  FastImage = Image;
} else {
  FastImage = require("react-native-fast-image");
}

const SecondaryContainer = ({ userSettingsState, navigation, scrollY }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Save the Y position of the component
  const windowHeight = Dimensions.get("window").height;

  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // For web (useState-based animation control)
  const [isVisible, setIsVisible] = useState(false);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    contentOpacity.value = 1;
    contentTranslateY.value = 0;
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== "web") {
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when it's within the viewport
      }
    }
  }, [scrollY, layoutY]);

  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); // Save the Y position
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // Trigger animation if visible on initial load
    }
  };

  // Intersection Observer for Web
  useEffect(() => {
    if (Platform.OS === "web") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect(); // Disconnect observer after triggering
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the section is visible
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  return (
    <Animated.View
      ref={sectionRef}
      style={[
        styles.fullImageContainer,
        Platform.OS === "web" && isVisible
          ? { opacity: 1, transform: "translateY(0px)", transition: "opacity 1s ease-out, transform 1s ease-out" }
          : { opacity: 0, transform: "translateY(50px)" }, // Hidden until visible
        Platform.OS !== "web" ? animatedContentStyle : null,
      ]}
      onLayout={handleLayout} // Capture layout for mobile
    >
      <FastImage
        source={{ uri: getAsset(require('../../../assets/images/store/images/secondary_image.jpg')) }}
        resizeMode={responsiveStyles.isMobile ? null : "contain"}
        style={styles.image}
      />
    </Animated.View>
  );
};

const styling = (responsiveStyles) =>
  StyleSheet.create({
    fullImageContainer: {
      // Customize based on your design needs
      paddingVertical: responsiveStyles.isMobile ? 50 : null,
    },
    image: {
      width: responsiveStyles.screenWidth,
      aspectRatio: responsiveStyles.isMobile ? (5 / 6) : (16 / 9),
      // aspectRatio: 80 / 29,
      borderBottomEndRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
      borderBottomStartRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
    },
  });

export default SecondaryContainer;
