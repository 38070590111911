export default {
  numeric_onlyNumbers: "אנא הזן מספר",
  numeric_atleastLetters1: "אנא הזן לפחות",
  numeric_atleastLetters2: "תווים",
  numeric_noMoreThan1: "אנא אל תזין יותר מ",
  numeric_noMoreThan2: "תווים",
  expirationDate_monthError: "אנא הזן חודש תקין (01-12).",
  expirationDate_yearError: "אנא הזן שנה תקינה הגדולה משנה הנוכחית.",
  ImagePickerPopupWebComponent_SubmitButton: `בחרו תמונה`,
  ImagePickerPopupWebComponent_CancelButton: `ביטול`,
  LanguageModal_Cancel: "ביטול",
  datePicker_modalTitle: "בחירת תאריך",
  datePicker_cancelButton: "סגור",
};
