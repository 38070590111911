import { getAsset } from "../../functions/getAsset";
import { imagePaths } from "./imagePaths";
import { Asset } from 'expo-asset';
// export const Necklaces = [
//   {
//     Id: 1001,
//     Images: [imagePaths['necklaces/lotus_flower.png'],imagePaths['necklaces/lotus_flower_2.png'],imagePaths['necklaces/lotus_flower_3.png']],
//     Name: "Lotus flower",
//     Price: 399.99,
//     Discount: 25,
//     Rating: 4.8,
//   },
//   {
//     Id: 1002,
//     Images: [imagePaths['necklaces/lion.png'],imagePaths['necklaces/lion_2.png'],imagePaths['necklaces/lion_3.png']],
//     Name: "Lion",
//     Price: 450,
//     Discount: 25,
//     Rating: 4.8,
//   },
//   {
//     Id: 1003,
//     Images: [imagePaths['necklaces/initials_and_monograms.png']],
//     Name: "Heart monograms",
//     Price: 399.99,
//     Discount: 25,
//     Rating: 4.8,
//   },
//   {
//     Id: 1004,
//     Images: [imagePaths['necklaces/eye_of_horus_hamsa.png'],imagePaths['necklaces/eye_of_horus_hamsa_2.png'],imagePaths['necklaces/eye_of_horus_hamsa_3.png']],
//     Name: "Eye of Horus",
//     Price: 499.99,
//     Discount: 25,
//     Rating: 4.8,
//   },
// ];
export const Necklaces = [
  {
      Id: 1001,
      Images: [imagePaths['necklaces/lotus_flower.png'], imagePaths['necklaces/lotus_flower_2.png'], imagePaths['necklaces/lotus_flower_3.png']],
      Name: "Lotus flower",
      Name_en: "Lotus flower",
      Name_he: "פרח לוטוס",
      Price: 399.99,
      Discount: 25,
      Rating: 4.8,
      Material_en: "Sterling silver 925.",
      Material_he: "כסף סטרלינג 925.",
      // Description_en: "A delicate, hand-crafted lotus flower necklace in sterling silver, symbolizing purity and new beginnings.",
      // Description_he: "שרשרת פרחי לוטוס עדינה בעבודת יד מכסף סטרלינג, המסמלת טוהר והתחלות חדשות.",
      Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
      Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
      Dimensions_ChainLength: 18,
      Dimensions_PendantSize: 20,
      Weight: 10,
      IsActive: false,
      IsFrontSideText: false,
      IsBackSideText: false,
      IsChain: true,
      IsBracelet: false,
      IsRing: false,
      
      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,

  },
  {
      Id: 1002,
      Images: [imagePaths['necklaces/lion.png'], imagePaths['necklaces/lion_2.png'], imagePaths['necklaces/lion_3.png']],
      Name: "Lion",
      Name_en: "Lion",
      Name_he: "אריה",
      Price: 450,
      Discount: 25,
      Rating: 4.8,
      Material_en: "Sterling silver 925.",
      Material_he: "כסף סטרלינג 925.",
      // Description_en: "A delicate, hand-crafted lotus flower necklace in sterling silver, symbolizing purity and new beginnings.",
      // Description_he: "שרשרת פרחי לוטוס עדינה בעבודת יד מכסף סטרלינג, המסמלת טוהר והתחלות חדשות.",
      Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
      Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
      Dimensions_ChainLength: 18,
      Dimensions_PendantSize: 20,
      Weight: 10,
      IsActive: false,
      IsFrontSideText: false,
      IsBackSideText: false,
      IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
      Id: 1003,
      Images: [imagePaths['necklaces/initials_and_monograms.png']],
      Name: "Heart monograms",
      Name_en: "Heart monograms",
      Name_he: "מונוגרמות לב",
      Price: 399.99,
      Discount: 25,
      Rating: 4.8,
      Material_en: "Sterling silver 925.",
      Material_he: "כסף סטרלינג 925.",
      // Description_en: "A delicate, hand-crafted lotus flower necklace in sterling silver, symbolizing purity and new beginnings.",
      // Description_he: "שרשרת פרחי לוטוס עדינה בעבודת יד מכסף סטרלינג, המסמלת טוהר והתחלות חדשות.",
      Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
      Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
      Dimensions_ChainLength: 18,
      Dimensions_PendantSize: 20,
      Weight: 10,
      IsActive: false,
      IsFrontSideText: false,
      IsBackSideText: false,
      IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
      Id: 1004,
      Images: [imagePaths['necklaces/eye_of_horus_hamsa.png'], imagePaths['necklaces/eye_of_horus_hamsa_2.png'], imagePaths['necklaces/eye_of_horus_hamsa_3.png']],
      Name: "Eye of Horus",
      Name_en: "Eye of Horus",
      Name_he: "העין של הורוס",
      Price: 499.99,
      Discount: 25,
      Rating: 4.8,
      Material_en: "Sterling silver 925.",
      Material_he: "כסף סטרלינג 925.",
      // Description_en: "A delicate, hand-crafted lotus flower necklace in sterling silver, symbolizing purity and new beginnings.",
      // Description_he: "שרשרת פרחי לוטוס עדינה בעבודת יד מכסף סטרלינג, המסמלת טוהר והתחלות חדשות.",
      Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
      Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
      Dimensions_ChainLength: 18,
      Dimensions_PendantSize: 20,
      Weight: 10,
      IsActive: false,
      IsFrontSideText: false,
      IsBackSideText: false,
      IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
    Id: 1005,
    Images: [imagePaths['necklaces/lotus_flower_circle_1.png']],
    Name: "Lotus flower",
    Name_en: "Lotus flower",
    Name_he: "פרח לוטוס",
    Price: 499.99,
    Discount: 25,
    Rating: 4.8,
    Material_en: "Sterling silver 925.",
    Material_he: "כסף סטרלינג 925.",
    Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
    Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
    Dimensions_ChainLength: 18,
    Dimensions_PendantSize: 20,
    Weight: 10,
    IsActive: true,
    IsFrontSideText: false,
    IsBackSideText: false,
    IsChain: true,
    IsBracelet: false,
    IsRing: false,

    IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
    Id: 1006,
    Images: [imagePaths['necklaces/heart_circle_1.png']],
    Name: "Heart",
    Name_en: "Heart",
    Name_he: "לב",
    Price: 499.99,
    Discount: 25,
    Rating: 4.8,
    Material_en: "Sterling silver 925.",
    Material_he: "כסף סטרלינג 925.",
    Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
    Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
    Dimensions_ChainLength: 18,
    Dimensions_PendantSize: 20,
    Weight: 10,
    IsActive: true,
    IsFrontSideText: false,
    IsBackSideText: false,
    IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
    Id: 1007,
    Images: [imagePaths['necklaces/cannabis_flower_circle_1.png']],
    Name: "Cannabis flower",
    Name_en: "Cannabis flower",
    Name_he: "פרח קנאביס",
    Price: 499.99,
    Discount: 25,
    Rating: 4.8,
    Material_en: "Sterling silver 925.",
    Material_he: "כסף סטרלינג 925.",
    Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
    Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
    Dimensions_ChainLength: 18,
    Dimensions_PendantSize: 20,
    Weight: 10,
    IsActive: true,
    IsFrontSideText: false,
    IsBackSideText: false,
    IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
    Id: 1008,
    Images: [imagePaths['necklaces/hamsa_circle_1.png']],
    Name: "Hamsa",
    Name_en: "Hamsa",
    Name_he: "חמסה",
    Price: 499.99,
    Discount: 25,
    Rating: 4.8,
    Material_en: "Sterling silver 925.",
    Material_he: "כסף סטרלינג 925.",
    Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
    Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
    Dimensions_ChainLength: 18,
    Dimensions_PendantSize: 20,
    Weight: 10,
    IsActive: true,
    IsFrontSideText: false,
    IsBackSideText: false,
    IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  {
    Id: 1009,
    Images: [imagePaths['necklaces/phonix_circle_1.png']],
    Name: "Phonix",
    Name_en: "Phonix",
    Name_he: "עוף החול",
    Price: 499.99,
    Discount: 25,
    Rating: 4.8,
    Material_en: "Sterling silver 925.",
    Material_he: "כסף סטרלינג 925.",
    Description_en: "A timeless, handcrafted necklace made from premium sterling silver 925. This elegant piece is designed to capture cherished moments, featuring an integrated NFC chip that connects to a personalized profile showcasing memories, photos, and heartfelt messages. Perfect for celebrating love, friendship, or family, this necklace is more than just jewelry—it’s a meaningful keepsake of unforgettable moments. Delicate yet durable, it blends beauty with advanced technology, creating an emotional connection that keeps loved ones close, no matter where you are.",
    Description_he: "שרשרת על-זמנית בעבודת יד עשויה מכסף סטרלינג 925 איכותי. תכשיט אלגנטי זה נועד לשמר רגעים יקרים, ומשלב שבב NFC המוביל לפרופיל אישי הכולל זיכרונות, תמונות והודעות מרגשות. מושלם לחגיגת אהבה, חברות או משפחה, השרשרת הופכת ליותר מתכשיט – היא מזכרת ייחודית לרגעים בלתי נשכחים. עדינה אך עמידה, היא משלבת יופי עם טכנולוגיה מתקדמת, ויוצרת קשר רגשי שמקרב את היקרים לך בכל מקום.",
    Dimensions_ChainLength: 18,
    Dimensions_PendantSize: 20,
    Weight: 10,
    IsActive: true,
    IsFrontSideText: false,
    IsBackSideText: false,
    IsChain: true,
      IsBracelet: false,
      IsRing: false,

      IsSilver: true,
      IsGold: true,

      IsMotherOfPearl: true,
      IsMalachite: true,
      IsBlackAgate: true,
      IsRedAgate: true,
      IsLapisLazuli: true,
      IsTigersEye: true,
  },
  ];
  
  
  export const Bracelets = [
  
  ];
  
  
  export const PetsTag = [
    {
      Id: 3001,
      Images: [imagePaths['pets/dog_identification_tag.png']],
      Name: "Dog identification tag",
      Name_en: "Dog identification tag",
      Name_he: "תג זיהוי לכלב",
      Price: 79.99,
      Discount: 15,
      Rating: 4.8,
      Material_en: "aluminium",
      Material_he: "מתכת אלומיניום.",
      Description_en: "A durable, lightweight aluminum pet tag, custom-engraved with your pet's name and essential information. Designed to withstand everyday adventures, this sleek tag not only ensures your pet’s safety but also features a scannable QR code, giving access to their personalized profile. Easily update medical details, behavior notes, and vet info, all accessible with a quick scan. Stylish, secure, and practical—because your pet deserves the best.",
      Description_he: "תג לחיות מחמד עשוי מאלומיניום קל משקל ועמיד, עם חריטה אישית של שם חיית המחמד ומידע חיוני. התג המעוצב לעמידה בכל אתגרי היומיום כולל גם קוד QR הניתן לסריקה, המוביל לפרופיל אישי של חיית המחמד, בו ניתן לעדכן פרטים רפואיים, התנהגותיים ומידע על הווטרינר. עיצוב אופנתי ושימושי המשלב אבטחה ואסתטיקה – כי חיית המחמד שלך ראויה לטוב ביותר.",
      Dimensions_ChainLength: 18,
      Dimensions_PendantSize: 20,
      Weight: 10,
      IsActive: true,
      IsFrontSideText: true,
      IsBackSideText: true,
      IsChain: false,
      IsBracelet: false,
      IsRing: false,

      IsSilver: false,
      IsGold: false,

      IsMotherOfPearl: false,
      IsMalachite: false,
      IsBlackAgate: false,
      IsRedAgate: false,
      IsLapisLazuli: false,
      IsTigersEye: false,

  },
  ];


// export const Bracelets = [
//     {
//       Id: 2001,
//       Image: "",
//       Name: "שרשרת חריטה לגבר - קאי כסף",
//       Price: 99.99,
//       Discount: 50,
//       Rating: 4.8,
//     },
//   ];

  export const StoreItems = [
    {
        Id: 1,
        Items: Necklaces,
        Title: "Necklaces",
        Title_en: "Necklaces",
        Title_he: "שרשראות",
        Uri:  getAsset(require('../../../assets/images/store/store_main_topics/necklaces.png')),
        // Uri: "",
    },
    {
        Id: 2,
        Items: Bracelets,
        Title: "Bracelets",
        Title_en: "Bracelets",
        Title_he: "שרשראות",
        Uri:  getAsset(require('../../../assets/images/store/store_main_topics/bracelet.png')),
        // Uri: "",
    },
    {
      Id: 3,
      Items: PetsTag,
      Title: "PetsTag",
      Title_en: "Pets Tag",
      Title_he: "בעלי חיים",
      Uri:  getAsset(require('../../../assets/images/store/store_main_topics/bracelet.png')),
    // Uri: "",
  },
];