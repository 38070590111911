import React, { useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Platform, Keyboard, KeyboardAvoidingView } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import ManageHumanProfileForm from '../../components/forms/ManageHumanProfileForm';
import { CreateUserProfileInfo } from '../../api/apiCalls/ProfileCalls';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { TouchableWithoutFeedback } from 'react-native';
import ManagePetProfileForm from '../../components/forms/ManagePetProfileForm';
import { isNumeric } from '../../functions/utils';
import { getNavigationParams } from '../../functions/navigationLogic';


const CreateProfileScreen = ({  }) => {

  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IsPetFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const isPet = navigationParams?.IsPet === "true" || IsPetFromURL === "true" ? true : false;
  // const isPet = true;
  console.log(`navigation is`);
  console.log(navigation);
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const scrollViewRef = useRef(null);


    const [inputs, setInputs] = useState({
      FirstName: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      MiddleName: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      LastName: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      BirthDate: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      DeathDate: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Relationship: {
        value: -1,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      State: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      City: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Description: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Image:{
          value: "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
      },
      BackgroundImage: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
    },
    });

    const [petInputs_Info, setPetInputs_Info] = useState({
      PetName: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      PetType: {
        value: "", // Dog, Cat, Other
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Breed: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Gender: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      BirthDate: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      DeathDate: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Weight: {
        value: "", // should i go with KG of LB
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Bio: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      ContactName1: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      ContactPhone1: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      ContactName2: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      ContactPhone2: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Address: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      AdditionalInfo: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Image:{
          value: "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
      },
      BackgroundImage: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
    },
    });
    const [petInputs_AdditionalInfo, setPetInputs_AdditionalInfo] = useState({
      Behavior_Dogs: {
        value: null, // true or false or other
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_Cats: {
        value: null, // Dog, Cat, Other
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_Children: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_Strangers: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_OtherAnimals: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_EnergyLevel: {
        value: null, // mid, hight/ relaxed, needs a lot, other
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_TrainedLevel: {
        value: null, // basic, trained, untrained, in the middle of training
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_Nature: {
        value: null, // Shy, friendly, aggresive, knows close places, protected, dominant, other
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Behavior_AdditionalInfo: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Health_Allergies: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Health_Medication: {
        value: "", // should i go with KG of LB
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Health_Vaccine: {
        value: "", // should i go with KG of LB
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Health_MedicalIndications: {
        value: "", // should i go with KG of LB
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Health_Spayed: {
        value: null,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Health_AdditionalInfo: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Vet_Name: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Vet_PhoneNumber: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      // Vet_LicenseID: {
      //   value: "",
      //   isValid: true,
      //   isChanged: false,
      //   layoutY: 0,
      // },
      Vet_MicrochipNumber: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Vet_RabiesNumber: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Vet_AdditionalInfo: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
    });
    const onLayoutChange = (inputName, y) => {
      if(isPet){
        setPetInputs_Info(curInputs => ({
          ...curInputs,
          [inputName]: { ...curInputs[inputName], layoutY: y },
        }));
        setPetInputs_AdditionalInfo(curInputs => ({
          ...curInputs,
          [inputName]: { ...curInputs[inputName], layoutY: y },
        }));
      }
      else{
        setInputs(curInputs => ({
          ...curInputs,
          [inputName]: { ...curInputs[inputName], layoutY: y },
        }));
      }
    };
    const SavePetPersonalDetails = async () => {
      const PetNameIsValid = petInputs_Info.PetName.value?.toString().trim().length > 0;
      const BirthDateIsValid = petInputs_Info.BirthDate.value?.toString()?.trim()?.length > 0;
      const GenderIsValid = petInputs_Info.Gender.value?.toString().trim().length > 0;
      const PetTypeIsValid = petInputs_Info.PetType.value?.toString().trim().length > 0;
      const WeightIsValid = isNumeric(petInputs_Info.Weight.value) && petInputs_Info.Weight.value?.toString().trim().length > 0;
      const ContactPhone1IsValid = isNumeric(petInputs_Info.ContactPhone1.value) && petInputs_Info.ContactPhone1.value?.toString().trim().length > 0;
      const ContactName1IsValid = petInputs_Info.ContactName1.value?.toString().trim().length > 0;
      if (!PetNameIsValid || !BirthDateIsValid || !GenderIsValid || !PetTypeIsValid || !WeightIsValid
        || !ContactName1IsValid || !ContactPhone1IsValid
      ) {
        setPetInputs_Info((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.PetName.isValid = PetNameIsValid;
          updatedInputs.BirthDate.isValid = BirthDateIsValid;
          updatedInputs.Gender.isValid = GenderIsValid;
          updatedInputs.PetType.isValid = PetTypeIsValid;
          updatedInputs.Weight.isValid = WeightIsValid;
          updatedInputs.ContactName1.isValid = ContactName1IsValid;
          updatedInputs.ContactPhone1.isValid = ContactPhone1IsValid;
          return updatedInputs;
        });

        if (!PetNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.PetName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.PetName.layoutY, x: petInputs_Info.PetName.layoutY, animated: true });
          }
        } 
        else if (!BirthDateIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.BirthDate.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.BirthDate.layoutY, x: petInputs_Info.BirthDate.layoutY, animated: true });
          }
        } 
        else if (!GenderIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.Gender.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.Gender.layoutY, x: petInputs_Info.Gender.layoutY, animated: true });
          }
        } 
        else if (!PetTypeIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.PetType.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.PetType.layoutY, x: petInputs_Info.PetType.layoutY, animated: true });
          }
        } 
        else if (!WeightIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.Weight.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.Weight.layoutY, x: petInputs_Info.Weight.layoutY, animated: true });
          }
        } 
        else if (!ContactName1IsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.ContactName1.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.ContactName1.layoutY, x: petInputs_Info.ContactName1.layoutY, animated: true });
          }
        } 
        else if (!ContactPhone1IsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.ContactPhone1.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.ContactPhone1.layoutY, x: petInputs_Info.ContactPhone1.layoutY, animated: true });
          }
        } 
        
        return;
      }

      console.log(`SavePetPersonalDetails passed`);
      const formData = new FormData();
      let userData = {
        IsPet: true,
        PetName: petInputs_Info.PetName.value,
        PetType: petInputs_Info.PetType.value,
        Breed: petInputs_Info.Breed.value,
        Gender: petInputs_Info.Gender.value,
        BirthDate: petInputs_Info.BirthDate.value,
        DeathDate: petInputs_Info.DeathDate.value,
        Weight: petInputs_Info.Weight.value,
        Bio: petInputs_Info.Bio.value,
        ContactName1: petInputs_Info.ContactName1.value,
        ContactPhone1: petInputs_Info.ContactPhone1.value,
        ContactName2: petInputs_Info.ContactName2.value,
        ContactPhone2: petInputs_Info.ContactPhone2.value,
        Address: petInputs_Info.Address.value,
        AdditionalInfo: petInputs_Info.AdditionalInfo.value,
        Behavior_Dogs: petInputs_AdditionalInfo.Behavior_Dogs.value,
        Behavior_Cats: petInputs_AdditionalInfo.Behavior_Cats.value,
        Behavior_Children: petInputs_AdditionalInfo.Behavior_Children.value,
        Behavior_Strangers: petInputs_AdditionalInfo.Behavior_Strangers.value,
        Behavior_OtherAnimals: petInputs_AdditionalInfo.Behavior_OtherAnimals.value,
        Behavior_EnergyLevel: petInputs_AdditionalInfo.Behavior_EnergyLevel.value,
        Behavior_TrainedLevel: petInputs_AdditionalInfo.Behavior_TrainedLevel.value,
        Behavior_Nature: petInputs_AdditionalInfo.Behavior_Nature.value,
        Behavior_AdditionalInfo: petInputs_AdditionalInfo.Behavior_AdditionalInfo.value,
        Health_Allergies: petInputs_AdditionalInfo.Health_Allergies.value,
        Health_Medication: petInputs_AdditionalInfo.Health_Medication.value,
        Health_Vaccine: petInputs_AdditionalInfo.Health_Vaccine.value,
        Health_MedicalIndications: petInputs_AdditionalInfo.Health_MedicalIndications.value,
        Health_Spayed: petInputs_AdditionalInfo.Health_Spayed.value,
        Health_AdditionalInfo: petInputs_AdditionalInfo.Health_AdditionalInfo.value,
        Vet_Name: petInputs_AdditionalInfo.Vet_Name.value,
        Vet_PhoneNumber: petInputs_AdditionalInfo.Vet_PhoneNumber.value,
        Vet_MicrochipNumber: petInputs_AdditionalInfo.Vet_MicrochipNumber.value,
        Vet_RabiesNumber: petInputs_AdditionalInfo.Vet_RabiesNumber.value,
        Vet_AdditionalInfo: petInputs_AdditionalInfo.Vet_AdditionalInfo.value,
    }

    if(petInputs_Info.Image.isChanged)
    {
      const ImageFileName = 'image.jpg';
      if(responsiveStyles.isWeb){
        console.log(`
          if(petInputs_Info.Image.isChanged) 
          if(responsiveStyles.isWeb){
          `);
        const blob = await fetch(petInputs_Info.Image.value).then((res) => res.blob());
        formData.append('userImage', blob, ImageFileName);
      }
      else{
        formData.append('userImage', {
          uri: petInputs_Info.Image.value,
          name: ImageFileName,
          type: 'image/jpeg', // Modify the content type based on your image type
        });
      }
    }
  if(petInputs_Info.BackgroundImage.isChanged)
    {
      const BackgroundImageFileName = 'image.jpg';
      if(responsiveStyles.isWeb){
        const blob = await fetch(petInputs_Info.BackgroundImage.value).then((res) => res.blob());
        formData.append('backgroundImage', blob, BackgroundImageFileName);
      }
      else{
        formData.append('backgroundImage', {
          uri: petInputs_Info.BackgroundImage.value,
          name: BackgroundImageFileName,
          type: 'image/jpeg', // Modify the content type based on your image type
        });
      }
    }
    formData.append('json', JSON.stringify(userData));
    console.log(`petInputs_Info.Image.value is ${petInputs_Info.Image.value}`);
      const response = await CreateUserProfileInfo(formData);
        if(response.isCompleted === true)
        {
        const answer = JSON.parse(response.answer);
        navigateToScreen(navigation, 'AccountFlow', `EditProfile`, { UserId: answer.Id, NavigateFromScreen: "CreateProfile" }, answer.Id);
        // navigation.navigate('AccountFlow', { screen: 'EditProfile', params: { UserId: answer.Id, NavigateFromScreen: "CreateProfile" } });
          // navigation.navigate("EditProfile", { UserId: answer.Id, NavigateFromScreen: "CreateProfile" });
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    const SaveHumanPersonalDetails = async () => {
      const FirstNameIsValid = inputs.FirstName.value.toString().trim().length > 0;
      const LastNameIsValid = inputs.LastName.value.toString().trim().length > 0;
      const RelationshipIsValid = inputs.Relationship.value > 0;
      const BirthDateIsValid = inputs.BirthDate.value?.toString()?.trim()?.length > 0;
      if (!FirstNameIsValid || !LastNameIsValid || !RelationshipIsValid || !BirthDateIsValid) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.FirstName.isValid = FirstNameIsValid;
          updatedInputs.LastName.isValid = LastNameIsValid;
          updatedInputs.Relationship.isValid = RelationshipIsValid;
          updatedInputs.BirthDate.isValid = BirthDateIsValid;
          return updatedInputs;
        });


        if (!FirstNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.FirstName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.FirstName.layoutY, x: inputs.FirstName.layoutY, animated: true });
          }
        } 
        else if (!LastNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.LastName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.LastName.layoutY, x: inputs.LastName.layoutY, animated: true });
          }
        } 
        else if (!RelationshipIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.Relationship.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.Relationship.layoutY, x: inputs.Relationship.layoutY, animated: true });
          }
        } 
        else if (!BirthDateIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.BirthDate.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.BirthDate.layoutY, x: inputs.BirthDate.layoutY, animated: true });
          }
        } 
        
        return;
      }

      console.log(`SaveHumanPersonalDetails passed`);
      const formData = new FormData();
      let userData = {
        IsPet: false,
        FirstName: inputs.FirstName.value,
        MiddleName: inputs.MiddleName.value,
        LastName: inputs.LastName.value,
        Relationship: inputs.Relationship.value,
        BirthDate: inputs.BirthDate.value,
        DeathDate: inputs.DeathDate.value,
        State: inputs.State.value,
        City: inputs.City.value,
        Description: inputs.Description.value,
    }

    if(inputs.Image.isChanged)
    {
      const ImageFileName = 'image.jpg';
      if(responsiveStyles.isWeb){
        console.log(`
          if(inputs.Image.isChanged) 
          if(responsiveStyles.isWeb){
          `);
        const blob = await fetch(inputs.Image.value).then((res) => res.blob());
        formData.append('userImage', blob, ImageFileName);
      }
      else{
        formData.append('userImage', {
          uri: inputs.Image.value,
          name: ImageFileName,
          type: 'image/jpeg', // Modify the content type based on your image type
        });
      }
    }
  if(inputs.BackgroundImage.isChanged)
    {
      const BackgroundImageFileName = 'image.jpg';
      if(responsiveStyles.isWeb){
        const blob = await fetch(inputs.BackgroundImage.value).then((res) => res.blob());
        formData.append('backgroundImage', blob, BackgroundImageFileName);
      }
      else{
        formData.append('backgroundImage', {
          uri: inputs.BackgroundImage.value,
          name: BackgroundImageFileName,
          type: 'image/jpeg', // Modify the content type based on your image type
        });
      }
    }
    formData.append('json', JSON.stringify(userData));
    console.log(`inputs.Image.value is ${inputs.Image.value}`);
      const response = await CreateUserProfileInfo(formData);
        if(response.isCompleted === true)
        {
        const answer = JSON.parse(response.answer);
        navigateToScreen(navigation, 'AccountFlow', `EditProfile`, { UserId: answer.Id, NavigateFromScreen: "CreateProfile" }, answer.Id);
        // navigation.navigate('AccountFlow', { screen: 'EditProfile', params: { UserId: answer.Id, NavigateFromScreen: "CreateProfile" } });
          // navigation.navigate("EditProfile", { UserId: answer.Id, NavigateFromScreen: "CreateProfile" });
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Create Profile";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen  style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
          <KeyboardAvoidingView
      behavior={'padding'}
      style={styles.container}
      >
  <ScrollView 
  ref={scrollViewRef}
      contentContainerStyle={styles.scrollViewContent}
      keyboardShouldPersistTaps='handled'
  >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      {
      isPet ? 
      <ManagePetProfileForm
    inputs={petInputs_Info} 
    setInputs={setPetInputs_Info} 
    additionalInfoInputs={petInputs_AdditionalInfo}
    setAdditionalInfoInputs={setPetInputs_AdditionalInfo}
    submitHandler={SavePetPersonalDetails} 
    userSettingsState={userSettingsState}
    navigation={navigation}
    onLayoutChange={onLayoutChange}
     />
     : <ManageHumanProfileForm
     inputs={inputs} 
     setInputs={setInputs} 
     submitHandler={SaveHumanPersonalDetails} 
     userSettingsState={userSettingsState}
     navigation={navigation}
     onLayoutChange={onLayoutChange}
      />
      }
 
 </>
  </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    </Screen>

}


CreateProfileScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // padding: 20,
    // height: fixedScreenHeight,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
});

export default CreateProfileScreen;
