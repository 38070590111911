import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const DisplayTypeComponent = ({ 
    userSettingsState, 
    displayType, 
    setDisplayType, 
    showArchive,
    isAdmin,
    PendingInvitationsCount = 0,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.bottomCardContainer}>

<TouchableOpacity 
    style={[displayType === 'ProfileAdmins' ? styles.onfocus : null]}
    onPress={() => setDisplayType('ProfileAdmins')}
    >
    <Text style={[styles.text, displayType === 'ProfileAdmins' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_AdminsComponent_DisplayTypeComponent_ProfileAdmins')}</Text>
    </TouchableOpacity>

    
    {isAdmin ? <TouchableOpacity 
    style={[displayType === 'PendingInvitations' ? styles.onfocus : null]}
    onPress={() => setDisplayType('PendingInvitations')}
    >
    <Text style={[styles.text, displayType === 'PendingInvitations' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_AdminsComponent_DisplayTypeComponent_PendingInvitations')}</Text>
    {PendingInvitationsCount > 0 ?
      <View style={styles.editButtonContainer}>
        <Text style={[styles.editButtonText]}>{PendingInvitationsCount}</Text>
      </View>
    : null}
    </TouchableOpacity>
    : null }

  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({



  bottomCardContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    marginTop: !responsiveStyles.isMobile || responsiveStyles.isWeb ? 50 : 20,
    paddingBottom: 10,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  onfocus:{
    borderBottomColor: GlobalStyles.colors.selectedItemColor,
    borderBottomWidth: 2,
  },
  onfocusText: {
    color: GlobalStyles.colors.selectedItemColor,
    fontWeight: '700',
  },
  editButtonContainer: {
    position: 'absolute',
    left: !responsiveStyles.isRTL ? null : -25,
    right: responsiveStyles.isRTL ? null : -25,
    top: -10,
    borderWidth:2, 
    borderRadius: 50,
    backgroundColor: GlobalStyles.colors.Lavender,
    width: !responsiveStyles.isMobile ? 25 : 20,
    height: !responsiveStyles.isMobile ? 25 : 20,
  },
  editButtonText: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: !responsiveStyles.isMobile ? responsiveStyles.fontSize_H6 : 10,
    fontFamily: GlobalStyles.fonts.application_font,
},
});

export default DisplayTypeComponent;