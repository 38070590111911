export default {
  Title: "מי אנחנו",
  Section1_1: "ברוכים הבאים ל",
  Section1_2: "Timeless Memories jewelry",
  Section1_3: ", היכן שהחדשנות פוגשת את האלגנטיות. אנו מציגים קונספט חדש ויפהפה שמשלב בין הקסם הנצחי של התכשיטים לטכנולוגיה המתקדמת של קודי QR. ב-TMJ, אנו מאמינים שהתכשיטים שלך צריכים לעשות יותר מאשר רק לקשט; הם צריכים לספר סיפור.",
  Section2_1: "היצירות הייחודיות שלנו מאפשרות ",
  Section2_2: "לך",
  Section2_3: " לשתף את הזכרונות הגדולים ביותר שלך עם האנשים האהובים עליך בצורה מודרנית ואינטראקטיבית. כל יצירת תכשיטים של TMJ מוטבעת בקוד QR מותאם אישית שכאשר נסרק, פותח גלריה דיגיטלית אישית. גלריה זו יכולה להציג אוסף של התמונות, הסרטונים וההודעות האהובים עליך, ויוצרת חוויה אישית ומשמעותית עמוקה.",
  OurMission: "המשימה שלנו",
  Section3_1: "ב-TMJ, המשימה שלנו היא לחולל מהפכה באופן שבו אתה מתחבר לרגעים היקרים שלך. אנו שואפים ליצור תכשיטים שהם לא רק יפים ואופנתיים אלא גם עשירים במשמעות אישית. על ידי שילוב טכנולוגיה עם אומנות מסורתית, אנו מכוונים להציע מוצר ייחודי שמלכד ומשתף את מהות הזיכרונות המוערכים ביותר שלך.",
  OurStory: "הסיפור שלנו",
  Section4_1: "החברה TMJ נולדה מהרעיון שכל תכשיט צריך להיות ייחודי כמו הזיכרונות שהוא מייצג. המייסד שלנו, [שמך], דמיין קו של תכשיטים שיכול לגשר על הפער בין העולם הפיזי והדיגיטלי, ולגרום לכך שיהיה קל מאי פעם לשתף רגעים מיוחדים עם חברים ומשפחה. בהשראת הפוטנציאל של טכנולוגיית QR, יצאנו ליצור אוסף שיגשים את החזון הזה.",
  OurCraftsmanship: "האומנות שלנו",
  Section5_1: "כל יצירת TMJ נוצרת בקפידה עם החומרים האיכותיים ביותר על מנת להבטיח גם יופי וגם עמידות. אנו עובדים עם אומנים מיומנים שמביאים את העיצובים שלנו לחיים, תוך תשומת לב קפדנית לכל פרט. התוצאה היא תכשיט מדהים שגם מתפקד כפורטל לגלריה האישית שלך.",
  WhyChooseUs: "למה לבחור ב-TMJ?",
  WhyChooseUs_1_Title: "עיצוב חדשני: ",
  WhyChooseUs_1_Description: "התכשיטים שלנו משלבים אלגנטיות קלאסית עם טכנולוגיה מודרנית.",
  WhyChooseUs_2_Title: "חיבור אישי: ",
  WhyChooseUs_2_Description: "שתף בקלות את הרגעים האהובים עליך בסריקה פשוטה.",
  WhyChooseUs_3_Title: "איכות גבוהה: ",
  WhyChooseUs_3_Description: "אנו משתמשים רק בחומרים ובאומנות מהאיכות הגבוהה ביותר.",
  WhyChooseUs_4_Title: "מתנה ייחודית: ",
  WhyChooseUs_4_Description: "מושלם לכל אירוע, מימי הולדת ועד ימי נישואין.",
  Section6_1: "הצטרף אלינו למסע זה של שילוב מסורת עם חדשנות. תן ל-TMJ להיות הבד של הזיכרונות שלך, מהפך כל רגע לנצחי ובר שיתוף."
};
