import { Asset } from "expo-asset";
import { getAsset } from "../../../functions/getAsset";
export const Mother_Media = [
    {
        Id: -10001,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Beach",
        Description: "A fun day at the beach",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/beach.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100001,
                PostId: -10001,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/beach.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10002,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Kayaking",
        Description: "Kayaking together",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/kayaking.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100002,
                PostId: -10002,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/kayaking.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10003,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Relaxing at home",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/relaxing_at_home.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100003,
                PostId: -10003,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/relaxing_at_home.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10004,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Gardening",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/gardening.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100004,
                PostId: -10004,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/gardening.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10005,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Spa",
        Description: "Relaxing at the spa after a streesed day",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/spa.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100005,
                PostId: -10005,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/spa.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10006,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Happy 42 Birthday",
        Description: `To my beautiful mom, happy birthday.
        I LOVE YOU
        `,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/birthday.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100006,
                PostId: -10006,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/birthday.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10007,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Picnic with the kids",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/picnic.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100007,
                PostId: -10007,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/picnic.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10008,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Baking cookies",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/baking_cookies.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100008,
                PostId: -10008,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/baking_cookies.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10009,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "A day at work",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/working.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100009,
                PostId: -10009,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/working.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10010,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Yoga",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/yoga.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100010,
                PostId: -10010,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/yoga.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10011,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Bubble bath",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/bubble_bath.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100011,
                PostId: -10011,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/bubble_bath.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10012,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "Making breakfast",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/making_breakfast.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100012,
                PostId: -10012,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/making_breakfast.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
    {
        Id: -10013,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
        // UserImage: "",
        UserName: "Mother",
        UserId: null,
        UserProfileId: null,

        Title: "A snowy day",
        Description: ``,

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/mother/mother_and_child.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -100013,
                PostId: -10013,
                MediaType: 'image',
                URL:getAsset(require('../../../../assets/images/store/profiles/mother/mother_and_child.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-10",
    },
  
  ];