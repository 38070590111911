import { useEffect, useContext, useState } from 'react';
import { StyleSheet,View,StatusBar, Button, TouchableOpacity, Text, ActivityIndicator, Appearance } from "react-native";
import LoadingScreen from '../../components/LoadingScreen';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';

const LoaderAuthScreen = ({  }) => {
  const navigation = Platform.OS === "web" ? useNavigate() : useNavigation();
  const {setDefaultSettings } = useContext(UserSettingsContext);
  const colorScheme = Appearance.getColorScheme();
  const ThemeValue = colorScheme === "dark" ? 'dark' : 'light';
  console.log(ThemeValue);
  const styles = styling(ThemeValue);
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Loader Auth";
    }
    setDefaultSettings();
    const timer = setTimeout(() => {
      console.log(`after timer`);
      navigateToScreen(navigation, 'LoginFlow', `Signin`, null, null);
      // navigation.navigate('LoginFlow', { screen: 'Signin' });
      // navigation.navigate('Signin');
    }, 2000);
    return () => clearTimeout(timer);
  }, [navigation]);

  return <View style={[styles.bodyContainer]}>
    <LoadingScreen 
    userSettingsState={{
      userSettings: {
        ThemeValue: ThemeValue
      }
    }}
    // ThemeValue={ThemeValue}
     />
    </View>
    };
    LoaderAuthScreen.navigationOptions = (navData) => {
      return {
        header: () => false,
      }
    };
    const styling = ThemeValue => StyleSheet.create({
      bodyContainer:{
    backgroundColor:GlobalStyles[ThemeValue]?.primaryBackgroundColor,
    height:fixedScreenHeight,
    flex:1,
  },
});
export default LoaderAuthScreen;
