import React, { useEffect, useState } from 'react';
import { StyleSheet,View,Text, TextInput, Dimensions,Image, useWindowDimensions, Platform, TouchableOpacity} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { GlobalStyles } from '../constants/styles';
import { headerHeight } from '../constants/ScreenDimensions';
import ImageProfile from './images/ImageProfile';
import HamburgerMenu from './HamburgerMenu';
import BackButtonComponent from './BackButtonComponent';
import { MainLogo, SecondaryLogo } from '../constants/images';
import StoreMenu from './StoreMenu';
import { isDesktopResponsive, isLaptopResponsive } from '../functions/ScreenDimensionsLogic';
import HamburgerNavigationComponent from './store/HamburgerNavigationComponent';
import { navigateToScreen } from '../navigationResponsive';
import useResponsiveStyles from '../functions/ResponsiveStyles';
import { getAsset } from '../functions/getAsset';
import ImageHeaderComponent from './ImageHeaderComponent';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}


const Header = ({ 
    children, 
    headerLeftComponent, 
    headerLeftComponentStyle,
    headerRightComponent, 
    headerRightComponentStyle,
    headerTitle, 
    navigation, 
    backAction = null,
    userSettingsState,
    Fullscreen,
    searchVisible = false,
    isMenuOpen,
    setIsMenuOpen,
    isStoreMenuOpen,
    setIsStoreMenuOpen,
    showBackButton,
    showCart,
    openCart = false,
   setOpenCart = null,
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const isLaptopOrBigger = isLaptopResponsive(responsiveStyles.screenWidth) || isDesktopResponsive(responsiveStyles.screenWidth);
  const styles = styling(responsiveStyles, searchVisible);
  console.log(`responsiveStyles.screenHeight is ${responsiveStyles.screenHeight}`);

    useEffect(() => {
      if(openCart){
        if(setOpenCart !== null){

          setOpenCart(false);
          toggleStoreMenu();
        }
      }
    }, [openCart]);


    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    const toggleStoreMenu = () => {
      setIsStoreMenuOpen(!isMenuOpen);
    };

  return (
    <View style={[styles.header,
        {height:headerHeight},
        //  responsiveStyles.isRTL ? { flexDirection:'row-reverse' } : {flexDirection:'row'}
        { flexDirection:'row' },
        ]}
         >
        <View style={[styles.textView,
        headerLeftComponentStyle,
        { alignItems: 'flex-start' },
        responsiveStyles.isRTL && isLaptopOrBigger ? { flex: 2 } : null,
        ]}>
        {!responsiveStyles.isRTL ?
        isLaptopOrBigger ? <ImageHeaderComponent userSettingsState={userSettingsState} navigation={navigation} /> :
         <HamburgerMenu userSettingsState={userSettingsState} onPress={toggleMenu} /> 
        : 
        <View style={{ flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row'}}>
        { isLaptopOrBigger ? 
      <HamburgerNavigationComponent
      setIsMenuOpen={setIsMenuOpen}
      isHeader={true}
      userSettingsState={userSettingsState}
      navigation={navigation}
      />  
      : null }
      <View style={{ justifyContent:'center' }}>
        {showCart ? 
          <StoreMenu 
          userSettingsState={userSettingsState} 
          navigation={navigation} 
          onPress={toggleStoreMenu} 
          />
          :
          <BackButtonComponent 
          userSettingsState={userSettingsState} 
          navigation={navigation} 
          backAction={backAction}
          showBackButton={showBackButton}
           /> 
          }

          </View>
          </View> 
        }
        </View>


        { isLaptopOrBigger ? null : <View style={[[styles.titleView,
        responsiveStyles.isRTL ? { marginRight: 10 } : { marginLeft: 10 },
        
        ]]}>
            {/* {headerTitle} */}
              <ImageHeaderComponent userSettingsState={userSettingsState} navigation={navigation} />
        </View>}


        <View style={[styles.textView,
        headerRightComponentStyle,
        { alignItems: 'flex-end' },
        !responsiveStyles.isRTL && isLaptopOrBigger ? { flex: 2 } : null,
        ]}>
        {responsiveStyles.isRTL ?
        isLaptopOrBigger ? <ImageHeaderComponent userSettingsState={userSettingsState} navigation={navigation} /> :
        <HamburgerMenu userSettingsState={userSettingsState} onPress={toggleMenu} />
        :
        <View style={{ flexDirection:'row'}}>
        { isLaptopOrBigger ? 
      <HamburgerNavigationComponent
      setIsMenuOpen={setIsMenuOpen}
      isHeader={true}
      userSettingsState={userSettingsState}
      navigation={navigation}
      />  
      : null }
      <View style={{ justifyContent:'center' }}>
        {showCart ? 
          <StoreMenu 
          userSettingsState={userSettingsState} 
          navigation={navigation} 
          onPress={toggleStoreMenu} 
          />
          :
          <BackButtonComponent 
          userSettingsState={userSettingsState} 
          navigation={navigation} 
          backAction={backAction}
          showBackButton={showBackButton}
           /> 
           }

          </View>
          </View>
        }
        </View>
        
    </View>
  );
};

const styling = (responsiveStyles, searchVisible) => StyleSheet.create({
    header:{
        // height:50,
        borderBottomWidth: responsiveStyles.ThemeValue === 'light' ? 1 : 0,
        borderBottomColor: GlobalStyles[responsiveStyles.ThemeValue]?.headerborderBottomColor,
        backgroundColor: searchVisible ? GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor : GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor,       
        position: responsiveStyles.isWeb ? 'fixed' : null,
        top: responsiveStyles.isWeb ? 0 : null,
        left: responsiveStyles.isWeb ? 0 : null,
        right: responsiveStyles.isWeb ? 0 : null,
        zIndex: responsiveStyles.isWeb ? 900 : null,
        height: responsiveStyles.isWeb ? headerHeight : null,
        // position: 'fixed',
        // top: 0,
        // left: 0,
        // right: 0,
        // zIndex: 900,
        // height: headerHeight,
    },
    textView:{
        flex:1, 
        flexDirection:'column',
        height:'100%',
        justifyContent:'center',
        

    },
    titleView:{
        // backgroundColor:'blue',
        justifyContent:'center',
        flex:3,
        
    },
    text: {
        textAlign:'center',
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H4,
        fontFamily: GlobalStyles.fonts.application_font,
        
    },
    backgroundImage: {
        height: headerHeight,
        width:'100%',
        // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      },

});

export default Header;
