export default {

   readMoreButton: "Read more",
   ViewAllTutorials: `View all tutorials`,
   step1_title1: "Step 1",
   step1_title2: " - Buy a gift",
   step1_text1: `Welcome to our tutorial on creating your Account.`,
   step1_text2: `In just a few simple steps, you'll be able to set up your account and start exploring all the features our website has to offer.`,
   step1_text3: `Let's get started!`,
   step1_text4: `At the top right/left corner, click on the "Sign In" button. This will take you to the Sign-In Screen.`,
   step1_text5: `If you don’t have an account yet, don’t worry, you can Click on the "Create new account" button to begin creating your profile.`,
   step1_text6: `Enter you email address.`,
   step1_text7: `Make sure to use an email that you have access to, as you’ll need to verify it later.`,
   step1_text8: `Once you entered the code, click "Next" to move forward.`,
   step1_text9: `now you will need to fill Your Personal details`,
   step1_text10: `for the password you will need to Create a strong password that includes a mix of letters, numbers, and special characters.`,
   step1_text11: `Once all the fields are filled out correctly, click the "Next" button to proceed.`,
   step1_text12: `click the submit button to create you account.`,
   step1_text13: `Congratulations! Your profile is now created. You can start exploring your account, customize your profile, and make the most of all the features available to you.`,

   step2_title1: "Step 2",
   step2_title2: " - Create your acount",
   step2_text1: `scroll down and click on one of the options under the "Products" tab.`,
   step2_text2: "This will take you to our product catalog, where you can view all the available jewelry items.",
   step2_text3: "Click on any product that catches your eye to view its details.",
   step2_text4: `Once you’ve made your selections, click the "Add to Cart" button`,
   step2_text5: "and the cart pop up will be revealed and you will see the items you have added.",
   step2_text6: `once you have added all your items to the cart you can click on the "Check out" button to complete your order.`,
   step2_text7: `click on the "Continue to shipping" button to proceed with your order.`,
   step2_text8: "On the checkout page, choose your preferred payment method.",
   step2_text9: `When you’re ready, click the "Place Order" button.`,
   step2_text10: "You’ll receive a confirmation email with your order details and a receipt.",
   step2_text11: "Congratulations: You’ve successfully purchased your item! We’ll take care of the rest and ensure your jewelry is delivered to your doorstep as quickly as possible.",

   Step4_title1: `Step 4`,
   Step4_title2: ` - Linking Jewelry to Your Profile`,
   Step4_text1: `In your account dashboard, you’ll see several tabs`,
   Step4_text2: `Click on the "Jewelry" tab to view all the jewelry items you’ve purchased`,
   Step4_text3: `In the Jewelry section, you’ll see a list of all the items you’ve purchased.`,
   Step4_text4: `Select the piece of jewelry you want to link to your profile by clicking on the "VIew jewelry" button`,
   Step4_text5: `in this page you will see the jewelry image you have purchased`,
   Step4_text6: `you’ll notice a unique QR code associated with your jewelry`,
   Step4_text7: `This QR code can be scanned by anyone to view the profile linked to your jewelry`,
   Step4_text8: `if you haven't linked your jewelry to a profile and you will click the "show profile" button, you will see a message that you have not linked your jewerly yet.`,
   Step4_text9: `to link your jewelry to your preferred profile you will need to click the "Assign profile" button.`,
   Step4_text10: `now, select your profile you want to link and click the "Assign to profile" button.`,
   Step4_text11: `After linking your jewelry to your profile, you can click on "Show Profile" button to see the your profile.`,
   Step4_text12: `Your jewelry is now linked to your profile, and you’ve successfully personalized it! Whether it’s for yourself or a gift, this added touch makes your jewelry truly unique.`,


   Step3_title1: `Step 3`,
   Step3_title2: ` - Set-up a profile for your loved one`,
   Step3_text1: `Begin by signing in to your account on our website. Click on the "Sign In" button at the top right/left corner of the homepage and enter your username and password.`,
   Step3_text2: `Once signed in, you will be navigated to the "My Account" page, or you can click on "My Account" button. This will take you to your account dashboard.`,
   Step3_text3: `In your account dashboard, you’ll see several tabs. Click on the "Profiles" tab to view all the profiles you’ve created.`,
   Step3_text4: `Click on the "Create new profile" button to start.`,
   Step3_text5: `Fill in the required fields such as First Name, Last Name, Relationship, and Birth date.`,
   Step3_text6: `You can also upload a profile picture and a background image by clicking the profile or background picture and selecting an image from your device.`,
   Step3_text7: `Once you’ve entered all the necessary information, click the "Save changes" button to create your profile.`,
   Step3_text8: `once you will create your profile You will automatically be redirected to the edit profile page, where you can upload images, videos, and documents that will be associated with your profile.`,
   Step3_text9: `Congratulations you have successfully created you profile!`,
   Step3_text10: `There are 4 tabs to your profile: Bio, Media, Tributes and Admins.`,
   Step3_text11: `In the Bio tab, you can edit your profile details.`,
   Step3_text12: `In the Media tab, you can add posts to your profile.`,
   Step3_text13: `In the Tributes tab, you can add gestures and memories for the profile, and you can also send a link to your friends and family where they can also add tributes to your profile.`,
   Step3_text14: `In the admin tab you can have full controll to your profile.`,
   Step3_text15: `lets start add a media post!`,
   Step3_text16: `Click on the "Post a media" button to begin.`,
   Step3_text17: `enter a title and a description for your post and click the "Upload" button to add the files you want.`,
   Step3_text18: `You can upload the following file types:
Images: JPEG, PNG, GIF
Videos: MP4, MOV
Audio: MP3`,
   Step3_text19: `Each post you make can include files up to a total of 25 MB. This means you can upload one or multiple files, but the combined size must not exceed 25 MB per post.`,
   Step3_text20: `If your files exceed the 25 MB limit, you’ll receive a notification to either reduce the size of your files or upload them in separate posts.`,
   Step3_text21: `After selecting your files, click "Save changes. button" You’ll see a progress bar indicating the upload status.`,
   Step3_text22: `this action my take a while, depending on the amount of files and size of the files you have chosen.`,
   Step3_text23: `Once the upload is complete, your files will be displayed in the Media section.`,
   Step3_text24: `you can also do the same proccess in the Tributes tab.`,
   Step3_text25: `in the Tributes tab you have a button "Tributes Link", which will open a url to send to friends and family so they can also upload tributes to your profile.`,
   Step3_text26: `under the Tributes tab you will have an "Invitations" tab, which in there you will see the tributes that have been submitted by your friends.`,
   Step3_text27: `you can decide if you want to accept or decline the tributes.`,
   Step3_text28: `In the Admin tab you will see the users which can edit your profile.`,
   Step3_text29: `know that the users you have gived access to your profile only can add Media and Tribute posts to your profile, only you, the admin, can edit the Bio, accept Tribute invitations and accept any pending invitations for editors.`,
   Step3_text30: `if you decide you want to add an editor for you profile you can click the "Invite user" button and enter the username.`,
   Step3_text31: `every signed in user who will scan the QR code of a jewelry you have linked to your profile will have a "Request editting permissions" button which will request you, the admin, to invite them to edit your profile.`,
   Step3_text32: `you will see the users who have requested to edit you profile under the "Pending Invitations" tab under "Admins" tab.`,
   Step3_text33: `congratulations! you now know how to create a profile, add posts and invite users to edit your profile!`,
   Step3_text34: `to link your profile to a jewelry you will need first to purchase a jewelry from the "Store" tab and place your order.`,
  };