import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { GlobalStyles } from '../../constants/styles';
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import i18n from '../../hooks/useI18n';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const CartBreadcrumbs = ({
    navigation,
    showInformation = false,
    showShipping = false,
    showPayment = false,
    itemDetailName = null,
    userSettingsState,
}) => {
  
  const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.scrollViewContainer}>
      <View style={[styles.grid,{ padding: 10 }]}>
      <TouchableOpacity style={styles.gridItem}
        onPress={() => {
          if(showPayment){
            if(responsiveStyles.isWeb){
              // navigation(-3);
              navigateToScreen(navigation, 'StoreFlow', `Cart`, null, null);
            }
            else{
              navigation.pop();
              // navigation.pop();
              navigation.pop();
            }
          }
          else if(showShipping){
            if(responsiveStyles.isWeb){
              // navigation(-2);
              navigateToScreen(navigation, 'StoreFlow', `Cart`, null, null);
            }
            else{
              // navigation.pop();
              navigation.pop();
            }
          }
          else if(showInformation){
            if(responsiveStyles.isWeb){
              // navigation(-1);
              navigateToScreen(navigation, 'StoreFlow', `Cart`, null, null);
            }
            else{
              navigation.pop();
            }
          }
        }}
      >
          <Text style={styles.text}>{i18n.t('store.CartBreadcrumbs_Cart')}</Text>
          {showInformation  ?
      <AntDesign name={responsiveStyles.isRTL ? "left" : "right"}
      size={12} 
      color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
      style={styles.ArrowIcon}
       />
          : null }
      </TouchableOpacity>

      {showInformation  ?
      <TouchableOpacity 
      style={styles.gridItem} 
      activeOpacity={showShipping  ? 0.25 : 1}
      onPress={() => {
        if(showPayment){
          if(responsiveStyles.isWeb){
            // navigation(-2);
            navigateToScreen(navigation, 'StoreFlow', `CartInformation`, null, null);
          }
          else{
            navigation.pop();
            // navigation.pop();
          }
        }
        else if(showShipping){
          if(responsiveStyles.isWeb){
            // navigation(-1);
            navigateToScreen(navigation, 'StoreFlow', `CartInformation`, null, null);
          }
          else{
            navigation.pop();
          }
        }

    }}
      >
          <Text style={[styles.text, showShipping  ? null : styles.onFocusText]}>{i18n.t('store.CartBreadcrumbs_Information')}</Text>
          {showShipping  ?
                <AntDesign name={responsiveStyles.isRTL ? "left" : "right"}
                size={12} 
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
                style={styles.ArrowIcon}
                 />
          : null }
      </TouchableOpacity>
      : null }

    {/* {showShipping  ?
      <TouchableOpacity 
      style={styles.gridItem} 
      activeOpacity={showPayment  ? 0.25 : 1}
      onPress={() => {
        if(showPayment){
          if(responsiveStyles.isWeb){
            // navigation(-1);
            navigateToScreen(navigation, 'StoreFlow', `CartShippingInformation`, null, null);
          }
          else{
            navigation.pop();
          }
        }
    }}
      >
          <Text style={[styles.text, showPayment  ? null : styles.onFocusText]}>{i18n.t('store.CartBreadcrumbs_Shipping')}</Text>
          {showPayment  ?
                <AntDesign name="right" 
                size={12} 
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
                style={{paddingTop: 5, paddingHorizontal: 5}}
                 />
          : null }
      </TouchableOpacity>
      : null } */}


    {showPayment  ?
      <TouchableOpacity 
      style={styles.gridItem} 
      activeOpacity={itemDetailName  ? 0.25 : 1}
      onPress={() => {
        // if(itemDetailName ){
        //   responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
        // }
    }}
      >
          <Text style={[styles.text, itemDetailName  ? null : styles.onFocusText]}>{i18n.t('store.CartBreadcrumbs_Payment')}</Text>
      </TouchableOpacity>
      : null }
      </View>
    </ScrollView>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  scrollViewContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  grid: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    ? responsiveStyles.screenWidth * 0.03
    :  responsiveStyles.screenWidth * 0.075,
  },
  gridItem: {
    // marginHorizontal: 5,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  ArrowIcon: {
    paddingTop: 5, 
    paddingHorizontal: !responsiveStyles.isMobile ? 10 : 5,
  },
  text: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
    onFocusText: {
    fontWeight: 'bold',
  },
});

export default CartBreadcrumbs;
