import React, { useState } from 'react';
import { Button, View, Alert, Linking, Platform, StyleSheet } from 'react-native';
import * as Location from 'expo-location';
import ButtonTypeButton from '../inputs/ButtonTypeButton';
import i18n from '../../hooks/useI18n';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const ShareLocation = ({userSettingsState}) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  const [location, setLocation] = useState(null);

  // Function to get the current location
  const getLocation = async () => {
    try {
      // Request permission to access location
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        Alert.alert('Permission denied', 'Allow location access to share location.');
        return;
      }

      // Get the current location
      let currentLocation = await Location.getCurrentPositionAsync({});
      const { latitude, longitude } = currentLocation.coords;
      setLocation({ latitude, longitude });

      // Share the location to WhatsApp
      shareLocationOnWhatsApp(latitude, longitude);
    } catch (error) {
      Alert.alert('Error', 'Unable to get location.');
      console.error(error);
    }
  };

  // Function to share location via WhatsApp
  const shareLocationOnWhatsApp = (latitude, longitude) => {
    const message = `Hi, I found your pet! Here is my current location, Check it out on Google Maps: https://maps.google.com/?q=${latitude},${longitude}`;
    // const message = `Here's my location: https://www.google.com/maps?q=${latitude},${longitude}`;
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(message)}`;

    Linking.canOpenURL(whatsappUrl)
      .then((supported) => {
        if (supported) {
          Linking.openURL(whatsappUrl);
        } else {
          Alert.alert('Error', 'WhatsApp is not installed or not supported on this device.');
        }
      })
      .catch((error) => {
        Alert.alert('Error', 'Unable to share location.');
        console.error(error);
      });
  };

  return (
    <ButtonTypeButton
        userSettingsState={userSettingsState}
        style={styles.button}
        onPress={getLocation}
        >
      {i18n.t(`account.BirthDateComponent_ShareLocation`)}
    </ButtonTypeButton>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  button: {
    width: responsiveStyles.screenWidth * 0.1,
    minWidth: responsiveStyles.isMobile ? 150 : responsiveStyles.isTablet ? 150 : 250,
  },
});
export default ShareLocation;
