import { imagePaths } from '../constants/store/imagePaths';

export const getImageUrl = (imageFileName) => {
    try {
        imageFileName = imageFileName.toLowerCase();
        if (!imageFileName) {
            return null;
        }
        const imagePath = imagePaths[imageFileName];
        
        if (!imagePath) {
            console.log(`func getImageUrl did not found for file name: ${imageFileName}`);
            return null;
        }
        return imagePath;
    } catch (error) {
        console.log(`func getImageUrl failed, error: ${error}`);
        return null;
    }
}