export default {
  MyAccount: "החשבון שלי",
  Discover: "גלה",
  Store: "חנות",
  Help: "עזרה",
  SignIn: "התחבר",
  SignOut: "התנתק",
  Orders: "הזמנות",
  FinancialAnalysis: "ניתוח פיננסי",
  Tutorial: "הדרכה",
};