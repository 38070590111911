import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import ButtonTypeText from '../../components/inputs/ButtonTypeText';
import { navigateToScreen } from '../../navigationResponsive';


const PaymentFailedScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Screen";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  >
    <View style={[styles.container]}>
      <View style={styles.header}>
        <GenericAlert
        userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      <View style={styles.centerContainer}>
    <Text style={styles.title}>{i18n.t('paymentFailed.header')}</Text>
    <View style={{ marginBottom: 20 }}>
    <Text style={[styles.text, { textAlign:'center' }]}>
      {i18n.t('paymentFailed.MainMessage1')}
      </Text>
      <Text style={[styles.text, { textAlign:'center' }]}>
      {i18n.t('paymentFailed.MainMessage2')}
      </Text>
      <Text style={[styles.text, { textAlign:'center' }]}>
      {i18n.t('paymentFailed.MainMessage3')}
      </Text>
      </View>

      <View style={{ marginBottom: 20 }}>
      <Text style={[styles.text, { textAlign:'center' }]}>
      {i18n.t('paymentFailed.SecondaryMessage1')}
      </Text>
      <Text style={[styles.text, { textAlign:'center' }]}>
      {i18n.t('paymentFailed.SecondaryMessage2')}
      </Text>
      </View>

    <View style={{ flexDirection:'row' }}>
    <ButtonTypeText
    userSettingsState={userSettingsState}
    styleText={{ fontSize: responsiveStyles.fontSize_H5, }}
    onPress={() => {
      navigateToScreen(navigation, 'StoreFlow', `CartInformation`, null, null);
    }}
    >
    <Text style={styles.text}>{i18n.t('paymentFailed.ReturnToCartMessage')}</Text>
    </ButtonTypeText>
    </View>
    </View>
      </View>
    </View>
    </Screen>

}


PaymentFailedScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      padding: 20,
      height: fixedScreenHeight,
    },
    centerContainer: {
      flex: 1,
      alignItems: 'center',
      // justifyContent: 'center',
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    title: {
      color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H4,
      fontFamily: GlobalStyles.fonts.application_font,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      paddingVertical: responsiveStyles.screenWidth * 0.05,
  },
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,  
    lineHeight: responsiveStyles.fontSize_H5 * 1.5,
  },
});

export default PaymentFailedScreen;
