import AsyncStorage from "@react-native-async-storage/async-storage";
import { jewlery, orders, profiles, user_PersonalDetails } from "../../dummyData/AccountData";
import { tributes } from "../../dummyData/Profile_TributesData";
import { getResponseByErr, getResponseSucceed } from "../../functions/getResponseByErr";
import { getAsync, post, postAsync } from "../categoryApiCalls";
import { token_Storage } from "../../modules/StorageObjects";
const GetUserPersonalDetails = async () => {
  // const token = await AsyncStorage.getItem(token_Storage);
  // if(!token){
  //   let vm = {
  //     AccountInformation: user_PersonalDetails,
  //     Profiles: profiles,
  //     Tributes: tributes,
  //     Jewlery: jewlery,
  //     Orders: orders,
  //   };
  //   return getResponseSucceed(vm);
  // }
    try {
        const req = await getAsync(
          "/Account/GetUserPersonalDetails"
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetUserPersonalDetails ${err}`);
      return getResponseByErr(err);
    }
  };
  const UpdateUserPersonalDetails = async (data) => {
    try {
        const req = await postAsync(
          "/Account/UpdateUserPersonalDetails",
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from UpdateUserPersonalDetails ${err}`);
      return getResponseByErr(err);
    }
  };
  const RestoreUserProfile = async (Id) => {
    try {
        const req = await getAsync(
          "/UserProfile/RestoreUserProfile/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from RestoreUserProfile ${err}`);
      return getResponseByErr(err);
    }
  };


  const SendContactUsMessage = async (data) => {
    try {
        const req = await postAsync(
          "/ContactUs/SendContactUsMessage",
          data
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from SendContactUsMessage ${err}`);
      return getResponseByErr(err);
    }
  };
  const SetUserSettings = async (data) => {
    try {
        const req = await postAsync(
          "/Auth/SetUserSettings",
          data
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from SetUserSettings ${err}`);
      return getResponseByErr(err);
    }
  };

  export { UpdateUserPersonalDetails, GetUserPersonalDetails, RestoreUserProfile, SendContactUsMessage, SetUserSettings };