export default {
    Email: "timelessmemoriesjewelryshop@gmail.com",
    Phone: "0587257993",
    AboutUs: "אודותינו",
    AboutUs_Description: "התחברו לזיכרונות ולאהובים דרך תכשיט NFC מבית TMJ. פשוט געו בתכשיט כדי לגלות רגעים משותפים וליצור חדשים, הכל במזכרת יפהפייה אחת.",
    AboutUs_Address: "כתובת 70, עיר, מדינה, מיקוד",
    FooterLinks_Title: "קישורים מהירים",
    FooterLinks_Home: "דף הבית",
    FooterLinks_AboutUs: "אודותינו",
    FooterLinks_AMBASSADOR: "הפוך לשגריר",
    FooterLinks_Help: "מרכז עזרה",
    FooterLinks_Terms: "תנאים והתניות",
    FooterLinks_Privacy: "פרטיות",
    FooterLinks_ContactUs: "צור קשר",
    FooterLinks_Tutorial: "הדרכה",
};
