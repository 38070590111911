import { Asset } from 'expo-asset';
import { getAsset } from '../../../functions/getAsset';



  export const Pet_Tributes = [{
    Id: -3000002,
    
    UserId: null,
    UserProfileId: null,
    UserProfileFullName: "Pet",
    UserName: "Pet",
    UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
    // UserImage: "",

    CreatedDate: "10/07/2024",
    Title: "our funny sunny",
    Description: "a colection of photos",

    album: [
      {
          Id: -300001,
          PostId: -30001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo1.png')),
          // URL: "",
          Duration: null,
      },
      {
          Id: -300002,
          PostId: -30001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo2.png')),
          // URL: "",
          Duration: null,
      },
      {
          Id: -300003,
          PostId: -30001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo3.png')),
          // URL: "",
          Duration: null,
      },
      {
          Id: -300004,
          PostId: -30001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo4.png')),
          // URL: "",
          Duration: null,
      },
  ],
    IsUserLiked: false,
    Comments: [],
    Likes: 0,
    IsActive: true,
    CreatedDate: "2024-07-10",
  }];
