import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TutorialContainer from "./TutorialContainer";
import { getAsset } from "../../functions/getAsset";
import { navigateToScreen } from "../../navigationResponsive";
import ImageSlider from "../media/ImageSlider";

  const Step1TextComponent = ({ 
    userSettingsState,
    showOnlyFirst = false,
    navigation,
    isFromHome = false,
    handleVideoLayout,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View>
    <TutorialContainer 
    isSetVideoSlider={true}
    userSettingsState={userSettingsState}
    isFromHome={isFromHome}
    videoUri={getAsset(require('../../../assets/tutorial/step1.mp4'))}
    durationTime={"00:01:26.0000000"}
    title1={i18n.t('tutorial.step1_title1')}
    title2={i18n.t('tutorial.step1_title2')}
    handleVideoLayout={handleVideoLayout}
    // titleStyle={
    //     { 
    //         marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    //         ? -responsiveStyles.screenWidth * 0.02 
    //         : null,
    //         marginRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    //         ? -responsiveStyles.screenWidth * 0.02 
    //         : null,
    //     }}
    showButton={showOnlyFirst}
    onPress={() => {
      navigateToScreen(navigation, 'StoreFlow', `TutorialExplanation`, { Type: "Step1" }, "Step1");
    }}
    text={
        <View style={styles.container}>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text1')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text2')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text3')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text4')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text5')}</Text>
    {
        showOnlyFirst ? 
        <Text style={styles.text}>...</Text>
        : <>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text6')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text7')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text8')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text9')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text10')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text11')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text12')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step1_text13')}</Text>
        </>
    }
    </View>
    }
    />
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    container: {
        // backgroundColor:'blue', 
        width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : responsiveStyles.screenWidth,
    },
    text: {
        fontSize: responsiveStyles.fontSize_H5,
        lineHeight: responsiveStyles.fontSize_H5 * 1.5,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontFamily: GlobalStyles.fonts.application_font,
        maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.3 : null,
        marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
        backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      },
});

export default Step1TextComponent;