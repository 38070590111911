import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { formatDate } from "../../functions/formatDate";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const JoinedComponent = ({ userSettingsState, user }) => {

    const formattedDate = formatDate(user?.JoinedDate);

    
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  return (
    <View style={styles.nameContainer}>
            <Text style={styles.title}>{i18n.t('account.MyAccount_Joined')}</Text>
            <Text style={[styles.nameValue]}>{ formattedDate === null || formattedDate === "NaN/NaN/NaN" ? user?.JoinedDate : formattedDate}</Text>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    nameContainer: {
      flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
      paddingLeft: !responsiveStyles.isRTL ? !responsiveStyles.isMobile ? 
        responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.17 :  
        responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.26 - 10
        : responsiveStyles.screenWidth * 0.26 
        : responsiveStyles.screenWidth * 0.1 
        : null,
        paddingRight: responsiveStyles.isRTL ? !responsiveStyles.isMobile ? 
        responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.17 :  
        responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.26 - 10
        : responsiveStyles.screenWidth * 0.26 
        : responsiveStyles.screenWidth * 0.1 
        : null,
      },
      title: {
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        textAlign: 'center',
        fontSize: responsiveStyles.fontSize_H4,
        fontFamily: GlobalStyles.fonts.application_font,
      },
      nameValue: {
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textValueColor,
        textAlign: 'center',
        fontSize: responsiveStyles.fontSize_H4,
        fontFamily: GlobalStyles.fonts.application_font,
        paddingLeft: !responsiveStyles.isRTL ? 10 : null,
        paddingRight: responsiveStyles.isRTL ? 10 : null,
      },
});

export default JoinedComponent;