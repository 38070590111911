import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, FlatList, Dimensions, Image, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { formatDate } from "../../functions/formatDate";
import { navigateToScreen } from "../../navigationResponsive";
import { rearrangeDataForRTL } from "../../functions/rearrangeDataForRTL";
import ProfileDisplayTypeComponent from "./ProfileDisplayTypeComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import AssignJewleryToProfileComponent from "../displayComponent/ProfileEmptyListComponent";
import ProfileListDisplayTypeComponent from "./ProfileListDisplayTypeComponent";
  const ProfileListComponent = ({ 
    userSettingsState,
    profileListDisplayType,
    setProfileListDisplayType,
    displayType,
    setDisplayType,
    navigation, 
    cleanup,
    data = [], 
    jewlery = [],
    RestoreUserProfile,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const [numColumns, setNumColumns] = useState(responsiveStyles.screenWidth > 1024 ? 3 : 1);
    
    const IsPet = profileListDisplayType === "Pets" ? true  : false;

    const IsActive = displayType === 'Profiles' || displayType === 'AuthorizedProfiles';
    const rerenderedData = 
    data.filter(s => s.IsActive === IsActive 
      && s.IsNotAdmin === (displayType === "AuthorizedProfiles" ? true : false)
      && s.IsPet === IsPet
    )
    ;

    const styles = styling(responsiveStyles, numColumns);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const onChange = ({ window }) => {
      const newWidth = window.width;
      setNumColumns(newWidth > 1024 ? 3 : 1);
      // setNumColumns(!responsiveStyles.isMobile ? 3 : 1);
    };


    if(responsiveStyles.isWeb){
    Dimensions.addEventListener('change', onChange);
    }
    return () =>{
      if(responsiveStyles.isWeb){
        Dimensions.removeEventListener('change', onChange);
      }
    } 
  }, []);

  return (
    <View style={styles.bottomCardContainer}>
  <ProfileListDisplayTypeComponent
    userSettingsState={userSettingsState}
    displayType={profileListDisplayType} 
    setDisplayType={setProfileListDisplayType}
  />
  <View style={styles.noteView}>
    {profileListDisplayType === "Pets" ? 
    <Text style={[styles.text,styles.noteText]}>{i18n.t('account.MyAccount_JewleryListComponent_NotePets')}</Text>
    : <Text style={[styles.text,styles.noteText]}>{i18n.t('account.MyAccount_JewleryListComponent_NoteHuman')}</Text>
    }
    </View>
<ButtonTypeButton
userSettingsState={userSettingsState}
        style={styles.createButton}
         onPress={() => {
          navigateToScreen(navigation, 'AccountFlow', `CreateProfile`, { IsPet: IsPet.toString() }, IsPet.toString());
          // navigation.navigate('AccountFlow', { screen: 'CreateProfile' });
          // navigation.navigate("CreateProfile");
          if(cleanup){
            cleanup();
          }
        }}
        >
          {i18n.t('account.MyAccount_ProfileListComponent_Create')}
    </ButtonTypeButton>
  
    <ProfileDisplayTypeComponent
userSettingsState={userSettingsState}
displayType={displayType} 
setDisplayType={setDisplayType} 
showArchive={data.filter(s => !s.IsActive).length > 0}
showAuthorizedProfiles={data.filter(s => s.IsActive && s.IsNotAdmin).length > 0}
/>
      <FlatList
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={responsiveStyles.isWeb ? styles.flatListContentContainer : null}
          data={responsiveStyles.isRTL ? rearrangeDataForRTL(rerenderedData, numColumns) : rerenderedData}
          key={numColumns} 
          // key={(item) => item.Id.toString()} 
          numColumns={numColumns}
          renderItem={({ item, index }) =>
          {

      const formattedCreatedDate = formatDate(item.CreatedDate);
      const formattedArchivedDate = formatDate(item.ArchivedDate);

      const jewleryOfUserProfile = jewlery.filter(s => s.UserProfileId === item.Id);

      return <View style={styles.itemContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={item.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
    />
              <Text style={styles.username}>{IsPet ? item.PetName : item.FullName}</Text>
              <Text style={styles.text}>{i18n.t('account.MyAccount_ProfileListComponent_Bonding')} {item.FullName}</Text>
              {!item.IsActive ? <View>
              <Text style={styles.text}>• {i18n.t('account.MyAccount_ProfileListComponent_Archived')}</Text>
              <Text style={styles.text}>{i18n.t('account.MyAccount_ProfileListComponent_ArchivedDate')} {formattedArchivedDate == null || formattedArchivedDate === "NaN/NaN/NaN" ? item.ArchivedDate : formattedArchivedDate}</Text>
              <ButtonTypeButton
              userSettingsState={userSettingsState}
                  style={styles.activeButton}
                  styleButton={styles.styleButton}
                  loading={isLoading}
                  onPress={async () => {
                    setIsLoading(true);
                    await RestoreUserProfile(item.Id);
                    setIsLoading(false);
                  }}
                  >
                    {i18n.t('account.MyAccount_ProfileListComponent_Restore')}
              </ButtonTypeButton>
                </View>
                : <View>
                <Text style={styles.text}>{i18n.t('account.MyAccount_JewleryComponent_Created')} {formattedCreatedDate == null || formattedCreatedDate === "NaN/NaN/NaN" ? item.CreatedDate : formattedCreatedDate}
                </Text>



                {
                  displayType === "Profiles" ?
                  <Text style={[styles.text, 
                  jewleryOfUserProfile.length === 0 ? styles.noAssignedJewelry : null,
                  { marginHorizontal: !responsiveStyles.isMobile ? 10 : null, paddingBottom: jewleryOfUserProfile.length > 0 ? 25 : null }
                  ]}>
                  {
                    jewleryOfUserProfile.length > 0 ? 
                    <>
                    {i18n.t('account.MyAccount_JewleryComponent_JewleryAssigned1')} {jewleryOfUserProfile.length} {i18n.t('account.MyAccount_JewleryComponent_JewleryAssigned2')}
                    </>
                    : <>
                    • {i18n.t('account.UNVERIFIED_UserProfile')}
                    </>
                  }
                  
                  </Text>
                  : null}

                <View style={styles.buttonsContainer}>
                <ButtonTypeButton
                userSettingsState={userSettingsState}
                    style={styles.archiveButton}
                    styleButton={styles.styleButton}
                    onPress={() => {
                      navigateToScreen(navigation, 'AccountFlow', `Profile`, { UserId: item.Id, FromScreen: "FromAccount" }, item.Id);
                      // navigation.navigate('AccountFlow', { screen: 'ProfileFromAccount', params:  { UserId: item.Id, FromScreen: "FromAccount" } });
                      // navigation.navigate("ProfileFromAccount", { UserId: item.Id, FromScreen: "FromAccount" });
                      if(cleanup){
                        cleanup();
                      }
                    }}
                    >
                      {i18n.t('account.MyAccount_ProfileListComponent_View')}
                </ButtonTypeButton>
                <ButtonTypeButton
                userSettingsState={userSettingsState}
                    style={styles.archiveButton}
                    styleButton={styles.styleButton}
                    onPress={() => {
                      navigateToScreen(navigation, 'AccountFlow', `EditProfile`, { UserId: item.Id }, item.Id);
                      // navigation.navigate('AccountFlow', { screen: 'EditProfile', params:  { UserId: item.Id } });
                      // navigation.navigate("EditProfile", { UserId: item.Id });
                      if(cleanup){
                        cleanup();
                      }
                    }}
                    >
                      {i18n.t('account.MyAccount_ProfileListComponent_Edit')}
                </ButtonTypeButton>
                    </View> 
                    </View> 
                }
              </View>
          }}
          ListEmptyComponent={
            <AssignJewleryToProfileComponent
            userSettingsState={userSettingsState}
            navigation={navigation}
            IsPet={IsPet}
            />
              }
          />
  </View>

  );
}
const styling = (responsiveStyles, numColumns) => StyleSheet.create({
  bottomCardContainer: {
    flex:1,

    // flexDirection:'row',
    // alignItems: 'center',
    // alignItems:'flex-start',
    // marginTop: 20,
    // backgroundColor: 'blue',
    
  },
  flatListContentContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexShrink: 1,
    alignSelf:'center',
  },
  itemContainer: {
    flex:1,
    padding: 25,
    justifyContent:'flex-start',
    alignItems:'center',
    alignSelf:'center',
    marginVertical: 10,

    borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth:  1,
    borderRadius: 10,

    
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : responsiveStyles.screenWidth * 0.8,
    minWidth: numColumns === 3 ? (responsiveStyles.screenWidth * 0.7 / 3 - (25 * 3)) : null,
    maxWidth: numColumns === 3 ? (responsiveStyles.screenWidth * 0.7 / 3 - (25 * 3)) : null,
    marginHorizontal: !responsiveStyles.isMobile ? 20 : 15,
  },
  userPhoto: {
    // width: 150,
    // height: 150,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.08 : 150,
    aspectRatio: 1 / 1,
    borderRadius: 100,
    // borderWidth: 4,
    // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    marginBottom: 10,
  },
  username: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 10,
    fontWeight: '700',
  },
  noteView: {
    marginHorizontal: responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.08
    : responsiveStyles.isLaptop || responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.05
    : null,
    marginBottom: !responsiveStyles.isMobile ? 20 : null,
    
  },
  noteText: {
    textAlign: responsiveStyles.isRTL ? 'right' : 'left'
  },
  noAssignedJewelry: {
    color: GlobalStyles.errorMessage.color,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    marginVertical: 5,
    
  },
  archiveButton: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.07 : responsiveStyles.screenWidth * 0.25,
    paddingTop:10,
    alignSelf:'center',
},
activeButton: {
  // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.3,
  paddingTop:10,
  // backgroundColor:'blue',
  alignSelf:'center',
  alignItems:'center',
},
buttonsContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent: !responsiveStyles.isMobile ? 'space-evenly' : 'space-evenly',
  // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : null,
  width: numColumns === 3 ? (responsiveStyles.screenWidth * 0.7 / 3 - (25 * 3)) : responsiveStyles.screenWidth * 0.8,
},
styleButton: {
  // backgroundColor: GlobalStyles.colors.Gold,
  // width: !responsiveStyles.isMobile ? 100 : null,
  width: numColumns === 3 ? ((responsiveStyles.screenWidth * 0.7 / 3 - (25 * 3)) / 2) - 20 :
  responsiveStyles.isTablet ? 100 : null,
  maxWidth: !responsiveStyles.isMobile ? 100 : null,
  alignSelf:'center',
},
createButton: {
  width: 
  responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.1 
  : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.15
  : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
  : responsiveStyles.screenWidth * 0.7,
  // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.7, // Use wp function here
  paddingVertical: 10,
  alignSelf:'center'
},
});

export default ProfileListComponent;