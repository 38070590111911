import { Asset } from 'expo-asset';
import { getAsset } from '../functions/getAsset';

// export const defaultImage = {uri: "https://bootdey.com/img/Content/avatar/avatar1.png"};
// export const BuisnessImage = { uri: "https://app.turninghearts.com/assets/TurningHearts-logo-df086be0f775c0e4f03e0e31c1eff102f70547ba94e699f513b644df5240c80d.png" };

// export const defaultImage = Asset.fromModule(require('../../assets/images/error/image_not_found.png')).uri;


export const defaultImage = getAsset(require('../../assets/images/default_image.png'));
export const defaultBackgroundImage = getAsset(require('../../assets/images/default_background_image.png'));
// export const BuisnessImage = getAsset(require('../../assets/images/store/buisness/logo.png'));
export const MainLogo = getAsset(require('../../assets/images/store/buisness/logo_main.png'));
export const SecondaryLogo = getAsset(require('../../assets/images/store/buisness/logo_secondary.png'));


// export const defaultImage = "";
// export const defaultBackgroundImage = "";
// export const BuisnessImage = "";