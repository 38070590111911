import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { GlobalStyles } from '../../constants/styles';
import i18n from '../../hooks/useI18n';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { transitionTime } from '../../functions/ScreenDimensionsLogic';

const GuaranteesGrid = ({
  userSettingsState,
  scrollY, // Accept scrollY to track the scroll position for mobile
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const windowHeight = Dimensions.get('window').height;

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Save the Y position of the component
  const [isVisible, setIsVisible] = useState(false); // For web visibility

  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    contentOpacity.value = 1;
    contentTranslateY.value = 0;
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when it's within the viewport
      }
    }
  }, [scrollY, layoutY]);

  // Capture layout information on mobile
  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); // Save the Y position
    // If the view is visible right after layout calculation, trigger the animation
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // First view is already in the viewport on initial load
    }
  };

  // Intersection Observer for Web
  useEffect(() => {
    if (Platform.OS === 'web') {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect(); // Disconnect observer after triggering
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the section is visible
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  return (
    <Animated.View
      ref={sectionRef}
      style={[
        styles.grid,
        Platform.OS === 'web' && isVisible
          ? { opacity: 1, transform: 'translateY(0px)', transition: 'opacity 1s ease-out, transform 1s ease-out' }
          : { opacity: 0, transform: 'translateY(50px)' }, // Hidden until visible
        Platform.OS !== 'web' ? animatedContentStyle : null,
      ]}
      onLayout={handleLayout} // Capture layout information for mobile
    >
      <View style={styles.gridItem}>
        <View style={styles.guaranteeItem}>
          <Feather name="thumbs-up" size={45} color={GlobalStyles.store.iconColor} />
          <Text style={styles.guaranteeTitle}>
            {i18n.t('store.HomeScreen_GuaranteesGrid_ThumbsUp')}
          </Text>
        </View>
      </View>
      <View style={styles.gridItem}>
        <View style={styles.guaranteeItem}>
          <Feather name="calendar" size={45} color={GlobalStyles.store.iconColor} />
          <Text style={styles.guaranteeTitle}>
            {i18n.t('store.HomeScreen_GuaranteesGrid_Calendar')}
          </Text>
        </View>
      </View>
      <View style={styles.gridItem}>
        <View style={styles.guaranteeItem}>
          <MaterialIcons name="done-outline" size={45} color={GlobalStyles.store.iconColor} />
          <Text style={styles.guaranteeTitle}>
            {i18n.t('store.HomeScreen_GuaranteesGrid_DoneOutline')}
          </Text>
        </View>
      </View>
      <View style={styles.gridItem}>
        <View style={styles.guaranteeItem}>
          <MaterialIcons name="star-border" size={60} color={GlobalStyles.store.iconColor} />
          <Text style={styles.guaranteeTitle}>
            {i18n.t('store.HomeScreen_GuaranteesGrid_StarBorder')}
          </Text>
        </View>
      </View>
    </Animated.View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  grid: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexWrap: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'nowrap' : 'wrap',
    transform: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? [{ scale: 1 }] : [{ scale: 1 }],
    marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.05 : null,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.25 : 0,
  },
  gridItem: {
    width: '45%', // Adjust as needed
    margin: '2.5%', // Adjust as needed
  },
  guaranteeItem: {
    alignItems: 'center',
  },
  guaranteeIcon: {
    fontSize: responsiveStyles.fontSize_H1,
    fontFamily: GlobalStyles.fonts.application_font,
    color: '#555', // Adjust color as needed
    marginBottom: 10,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  guaranteeTitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
});

export default GuaranteesGrid;
