import React, { useState, useEffect ,useRef} from "react";
import { View, 
  StyleSheet, 
  Text, 
  TextInput , 
  Button, 
  FlatList, 
  Dimensions, 
  TouchableWithoutFeedback, 
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
  Platform,
  useWindowDimensions,
} from "react-native";
import { GlobalStyles } from "../../constants/styles";
import { MaterialIcons } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { isFirstCharHebrew } from "../../functions/utils";
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import useResponsiveStyles from "../../functions/ResponsiveStyles";


const TextInputCustom = ({ 
  userSettingsState,
  label,
  value, 
  onChangeText, 
  fieldId, 
  editable, 
  secureTextEntry, 
  style, 
  iconType = null, // types: 'cancel' || 'password' || 'loader'
  multiline = false , 
  isKeyboardVisible,
  keyboardType='default',
  isValid = true,
  loader = "",
  isLoaderValid = false,
  resetLoader = false,
  refreshComponent = false,
  onFocus = undefined,
  onBlur = undefined,
  maxLength = 100000,
  heightTextInput = 0,
  showLabel = true,
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const refInput = useRef();
  const styles = styling(responsiveStyles);
    const [containerBorderColor, setContainerBorderColor] = useState();
    const [textInputPlaceholder, setTextInputPlaceholder] = useState();
    const [showInputHeader, setShowInputHeader] = useState();
    const [textInputPlaceholderCenter, setTextInputPlaceholderCenter] = useState();
    const [textColor, setTextColor] = useState();
    const [isFocus, setIsFocus] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    
    const [isResetLoader, setIsResetLoader] = useState(false);
    const IconHeight = responsiveStyles.screenHeight / 20; 

    const setDefaultProps = () => {
        setContainerBorderColor(GlobalStyles[responsiveStyles.ThemeValue].inputBorderColor);
        setTextInputPlaceholder(label);
        setShowInputHeader(value?.length === 0 ? false : true);
        setTextInputPlaceholderCenter( value?.length === 0 ? 
        {
            marginTop:10,
            marginBottom: 10,
        } : {});
        setTextColor(GlobalStyles[responsiveStyles.ThemeValue].inputHeaderTextColor);
        setIsFocus(false);
            
    }
    const removeFocusBorder = () => {
      if(responsiveStyles.isWeb){
        const inputs = document.querySelectorAll('input, textarea');
        inputs.forEach(input => {
          input.style.border = 'none'; // Remove border
          input.style.outline = 'none'; // Remove focus outline
        });
      }
    };

    useEffect(() => {
      setDefaultProps();
      }, [refreshComponent,responsiveStyles.ThemeValue, responsiveStyles.isRTL]);
    const onTextInputFocus = () => {
      if(onFocus !== undefined)
      {
        onFocus();
      }
        setContainerBorderColor(GlobalStyles[responsiveStyles.ThemeValue].inputBorderColorOnFocus);
        setTextInputPlaceholder('');
        setShowInputHeader(true);
        setTextInputPlaceholderCenter({});
        setTextColor(GlobalStyles[responsiveStyles.ThemeValue].inputHeaderTextColorFocus);
        setIsFocus(true);
        
        // Keyboard.setKeyboardAppearance('default'); // optional: set keyboard appearance
        // Keyboard.setTranslucent(false); // optional: set keyboard translucency
        // Keyboard.setAnimationDuration(50); // set keyboard animation duration
        

    }
    const onTextInputBlur = () => {
      if(onBlur !== undefined  && isKeyboardVisible === false)
      {
        onBlur();
      }
        setDefaultProps();
    }


  return (
    <TouchableWithoutFeedback 
    onPress={() => {
      refInput.current.focus();
    }}
    >
      <View style={
        [styles.container, 
          multiline === false ? styles.inputHeight : null,
        !isValid ? { borderColor: 'red' } : { borderColor: containerBorderColor },
        style,
        label !== undefined && isFirstCharHebrew(label) === false ? {flexDirection:'row'} : {flexDirection:'row-reverse'},
        
        ]}>
        {
          showLabel ?
          <View style={[{ flexWrap: 'wrap' },
              label !== undefined && isFirstCharHebrew(label) === false ? { alignSelf: "flex-start" } : { alignSelf: "flex-end" },
              styles.legend,
              responsiveStyles.isRTL ?  { right: 20 } : { left: 20 },
              { alignSelf: "flex-end" },
      ]}>
            {showInputHeader ? <Text style={[styles.text,!isValid ? { color: 'red' } :{color: textColor}, { fontSize: responsiveStyles.fontSize_H6, }]}>{label}</Text> : null }
            
        </View>
        : null}

        <View style={[textInputPlaceholderCenter,{ flex:1 }
      ]}>
        <View>
        <TextInput
          ref={refInput}
                label={label}
                value={value}
                  onChangeText={onChangeText}
                  placeholder={textInputPlaceholder}
                  // secureTextEntry={secureTextEntry === true && isShowPassword === false && value?.length > 0 ? true : false}
                  secureTextEntry={secureTextEntry === true && isShowPassword === false ? true : false}
                autoCapitalize="none"
                autoCorrect={false}
                style={[
                  multiline === true ? styles.inputMultiline : null,
                  responsiveStyles.isWeb && isFocus ? styles.webTextInput : null,
                  styles.textInput,
                  secureTextEntry === true 
                  ? value?.length > 0 && isFirstCharHebrew(value) === true 
                  ? { textAlign:'right' } 
                  : value?.length === 0 && isFirstCharHebrew(label) === true 
                  ? { textAlign:'right' } 
                  : null
                  : 
                  (
                  // value?.length > 0 && 
                  isFirstCharHebrew(value?.length > 0 ? value : textInputPlaceholder) === true
                  ) ? { textAlign:'right' } : null,
                  heightTextInput > 0 ? { minHeight : heightTextInput } : null,
                ]}
                multiline={multiline}
              //  numberOfLines={3}
                editable={editable}
                selectTextOnFocus={editable}
                onFocus={() => {
                  editable ? onTextInputFocus() : null;
                  removeFocusBorder();
                }}
                onBlur={() => editable ? onTextInputBlur() : null}
                // placeholderTextColor={GlobalStyles[responsiveStyles.ThemeValue].placeholderTextColor}
                placeholderTextColor={"gray"}
              keyboardType={keyboardType}
              inputMode={keyboardType} // Suggest numeric keypad for better UX
              pattern={keyboardType === "numeric" ? "[0-9]*" : null} // Ensure only numeric input
              maxLength={maxLength}
              ThemeValue={responsiveStyles.ThemeValue}
              isRTL={responsiveStyles.isRTL}
              />
              </View>
        </View>
        {iconType === null || (iconType === 'cancel' && value?.length === 0) ? null : (
        <TouchableOpacity style={[styles.CancelIcon, {width:30,height:IconHeight}, 
      ]}
      activeOpacity={iconType === 'loader' && isLoaderValid === true ? 1 : 0.2}
      onPress={() => {
        if(iconType === 'cancel' && value?.length > 0)
        {
          onChangeText('');
        }
        else if(iconType === 'password' && value?.length > 0)
        {
          setIsShowPassword(!isShowPassword);
        }
        else if(iconType === 'loader' && value?.length > 0)
        {
          if(isLoaderValid === false)
          {
            onChangeText('');
          }
          else{
            
          }
        }
      }}
      >
              { 
              // (responsiveStyles.isWeb ? true : isFocus === true && isKeyboardVisible === true) && 
              iconType === 'cancel' && value?.length > 0 ? (
          <Feather name="x" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
              ) : null}
              {iconType === 'password' && value?.length > 0 ? (
                  <Feather name={isShowPassword === true ? "eye" : "eye-off"} size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
              ) : null} 
              {iconType === 'loader' ? (
                value?.length === 0 ? <Feather name="alert-circle" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} /> :
                loader === true ?  <ActivityIndicator key={resetLoader ? Date.now() : 'activityIndicator'} /> : 
                isLoaderValid === false ? 
                (
                    <Feather name="x" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
                ) : 
              (
                <AntDesign name="checkcircleo" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
              )
              ) : null}                            
        </TouchableOpacity>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};


const styling = (responsiveStyles) => StyleSheet.create({
    container:{
        flex:1,
        borderWidth: 1,
        borderRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 25 : 15,
        padding:10,
        // backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].inputBackgroundColor,
        
        alignItems:'center',
        justifyContent:'space-evenly',
        
        // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 
        // // : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4
        // : null,
        width: responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2 
        : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
        // : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4
        : null,
        alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
        
    },
    fieldSet:{
      margin: 10,
  },
  legend:{
      position: 'absolute',
      top: -10,
      // fontWeight: 'bold',
      paddingHorizontal: 5,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
    text:{
      fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
        
    },
    textInput: { 
      fontSize: responsiveStyles.fontSize_H5,
       color: GlobalStyles[responsiveStyles.ThemeValue]?.inputColor,
       paddingHorizontal:5,
       fontFamily: GlobalStyles.fonts.application_font,
      //  minHeight:  responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 30,
      //  maxHeight:  responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 30,
      minHeight: 30,
      maxHeight: 30,
     },
     CancelIcon:{
      
      alignSelf:'center',
      justifyContent:'center'
    },
    inputMultiline: {
      minHeight: 100,
      textAlignVertical: 'top'
    },
    inputHeight:{
      minHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
      maxHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
    },
    webTextInput: {
      // outline: 'none', // Remove the focus outline
      // borderWidth: 0,  // Remove default border
      // borderColor: 'blue',
      // borderWidth: 1,
    },
    
});

export default TextInputCustom;
