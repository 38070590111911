import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../context/UserSettingsContext";
import { GlobalStyles } from '../constants/styles';
import Screen from "../components/Screen";
import i18n from '../hooks/useI18n';
import { GenericAlert } from '../components/alert/GenericAlert';
import { fixedScreenHeight } from '../constants/ScreenDimensions';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import ButtonTypeText from '../components/inputs/ButtonTypeText';
import NotFoundComponent from '../components/NotFoundComponent';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const PlainScreen = ({  }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const {state: userSettingsState } = useContext(UserSettingsContext);


    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Not Found";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  >
    <View style={[styles.container]}>
      <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
    </View>
    </Screen>

}

PlainScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  
});

export default PlainScreen;
