import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, Platform, useWindowDimensions, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Context as UserSettingsContext } from "../../../context/UserSettingsContext";
import { GlobalStyles } from '../../../constants/styles';
import Screen from "../../../components/Screen";
import i18n from '../../../hooks/useI18n';
import { GenericAlert } from '../../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../../constants/ScreenDimensions';
import ImageProfile from '../../../components/images/ImageProfile';
import BuisnessFooter from '../../../components/store/BuisnessFooter';
import TextInputWithErrorComponent from '../../../components/forms/TextInputWithErrorComponent';
import ButtonTypeButton from '../../../components/inputs/ButtonTypeButton';
import { SendContactUsMessage, UpdateUserPersonalDetails } from '../../../api/apiCalls/AccountCalls';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import BulletText from '../../../components/inputs/BulletText';
import useResponsiveStyles from '../../../functions/ResponsiveStyles';

const ContactUsScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);



    const [inputs, setInputs] = useState({
      Name: {
        value: "",
        isValid: true,
        isChanged: false,
      },
      Email: {
        value: "",
        isValid: true,
        isChanged: false,
      },
      Subject: {
        value: "",
        isValid: true,
        isChanged: false,
      },
      Message: {
        value: "",
        isValid: true,
        isChanged: false,
      },
    });
    const [isLoading, setIsLoading] = useState(false);

    function inputChangedHandler(inputIdentifier, enteredValue,isChanged) {
      setInputs((curInputs) => {
        const newInputs = {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        };
        return newInputs;
      });
      return;
    }
    function ValidateEmail(text)  {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(text) === false) {
        // Email is Not Correct
        return false;
      }
      else {
        // Email is Correct
        return true;
      }
    }
  const [isEmailValid, setIsEmailValid] = useState(true);

    const SendFormHandler = async () => {
      const NameIsValid = inputs.Name.value.toString().trim().length > 0;
      const EmailIsValid = inputs.Email.value.toString().trim().length > 0;
      const SubjectIsValid = inputs.Subject.value.toString().trim().length > 0;
      const MessageIsValid = inputs.Message.value.toString().trim().length > 0;
      if (!NameIsValid || !EmailIsValid || !SubjectIsValid) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.Name.isValid = NameIsValid;
          updatedInputs.Email.isValid = EmailIsValid;
          updatedInputs.Subject.isValid = SubjectIsValid;
          updatedInputs.Message.isValid = MessageIsValid;
          return updatedInputs;
        });
        return;
      }
      if(!ValidateEmail(inputs.Email.value)){
        setIsEmailValid(false);
        return;
      }
      else{
        setIsEmailValid(true);
      }
      let userData = {
        Name: inputs.Name.value,
        Email: inputs.Email.value,
        Subject: inputs.Subject.value,
        Message: inputs.Message.value,
    }

      const response = await SendContactUsMessage(userData);
        if(response.isCompleted === true)
        {
          setbodymessge(i18n.t('responseMessages.success'));
          setGenericAlertVisible(true);
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Contact Us";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.container}
        >
    <ScrollView 
    contentContainerStyle={styles.scrollViewContent}
        keyboardShouldPersistTaps='handled'
    >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <>
    <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={{padding: 10}}>
      
      <View style={styles.TitleContainer}>
        <Text style={[styles.title,styles.mainTitle]}>
        {i18n.t('contactUs.Title')}
          </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('contactUs.description')}</Text>
      </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('contactUs.ContactUs_Info')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('contactUs.ContactUs_Email')} </Text>
        <Text style={styles.text}>{i18n.t('footer.Email')} </Text>
        </>} />

        <BulletText userSettingsState={userSettingsState} text={<>
          <Text style={[styles.text,styles.textBold]}>{i18n.t('contactUs.ContactUs_Phone')} </Text>
          <Text style={styles.text}>{i18n.t('footer.Phone')} </Text>
        </>} />

      <View style={[styles.DescriptionContainer, { paddingTop: 20 }]}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('contactUs.ContactUs_Form')}</Text>
        </Text>
      </View>


      <View style={[styles.DescriptionContainer, { marginBottom: 10 }]}>
      <Text>
        <Text style={styles.text}>{i18n.t('contactUs.ContactUs_Form_Description')}</Text>
      </Text>
      </View>


      <View style={styles.textInputRowContainer}>
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Name.value}
    setValue={(value) => {
        inputChangedHandler("Name", value, true);
    }}
    label={i18n.t('contactUs.ContactForm_Name')}
    errorMessage={i18n.t('contactUs.ContactForm_Name_ErrorMessage')}
    isRequired={true}
    isValid={inputs.Name.isValid}
    styleContainer={styles.textInputWithErrorComponentstyleContainer}
    styleTextInputContainer={styles.inputStyle}
    />

    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Email.value}
    setValue={(value) => {
      setIsEmailValid(true);
        inputChangedHandler("Email", value, true);
    }}
    label={i18n.t('contactUs.ContactForm_Email')}
    errorMessage={i18n.t('contactUs.ContactForm_Email_ErrorMessage')}
    isRequired={true}
    isValid={inputs.Email.isValid}
    styleContainer={[styles.textInputWithErrorComponentstyleContainer, !responsiveStyles.isMobile ? {marginLeft:  responsiveStyles.screenWidth * 0.015} : null]}
    styleTextInputContainer={styles.inputStyle}
    />
    {!isEmailValid ? <Text style={[[GlobalStyles.errorMessage,{ marginBottom: 15 }]]}>{i18n.t('auth.SignUp_emailErrorMessage')}</Text> : null}

    </View>
    <View style={styles.textInputRowContainer}>
<TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Subject.value}
    setValue={(value) => {
        inputChangedHandler("Subject", value, true);
    }}
    label={i18n.t('contactUs.ContactForm_Subject')}
    errorMessage={i18n.t('contactUs.ContactForm_Subject_ErrorMessage')}
    isRequired={true}
    isValid={inputs.Subject.isValid}
    styleContainer={styles.textInputWithErrorComponentstyleContainer}
    styleTextInputContainer={styles.inputStyleOneRow}
    />
    </View>
    <View style={styles.textInputRowContainer}>
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Message.value}
    setValue={(value) => {
        inputChangedHandler("Message", value, true);
    }}
    label={i18n.t('contactUs.ContactForm_Message')}
    errorMessage={i18n.t('contactUs.ContactForm_Message_ErrorMessage')}
    isRequired={true}
    isValid={inputs.Message.isValid}
    // styleContainer={[styles.textInputWithErrorComponentstyleContainer, !responsiveStyles.isMobile ? {marginLeft:  responsiveStyles.screenWidth * 0.015} : null]}
    styleTextInputContainer={[styles.inputStyleOneRow]}
    multiline={true}
    heightTextInput={responsiveStyles.screenHeight * 0.3 }
    />
    </View>
<View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        loading={isLoading}
         onPress={async () => {
            setIsLoading(true);
            await SendFormHandler();
            setIsLoading(false);
        }}
        >
        {i18n.t('contactUs.ContactForm_SendButton')}
    </ButtonTypeButton>
    </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('contactUs.FinalWords')} </Text>
        </Text>
      </View>

      </View>
      </View>
      <BuisnessFooter userSettingsState={userSettingsState} navigation={navigation} />
      </>
      </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    </Screen>

}

ContactUsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  subContainer: {
    flex: 1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.5 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  TitleContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
  },
  mainTitle: {
    fontSize: responsiveStyles.fontSize_H1,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '900',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  DescriptionContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    paddingVertical: 5,
    paddingBottom: 20,
  },
  secondPharagraph: {
    paddingTop: 20,
  },
  text: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    lineHeight: responsiveStyles.fontSize_H5 * 1.5,
    
},
  textBold: {
    fontWeight: '900',
  },
  textSubBold: {
    fontWeight: '700',
  },


  buttonContainer: {
    marginBottom: 20,
    alignItems:'center',
},
button: {
  width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85, // Use wp function here
},
textInputRowContainer: {
  flexDirection: !responsiveStyles.isMobile ? 'row' : null,
  justifyContent: !responsiveStyles.isMobile ?  'space-between' : null,
},
inputStyle: {
  width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth) * 0.25 : null,
},
inputStyleOneRow: {
  width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth) * 0.515 : null,
},
});

export default ContactUsScreen;
