import { Asset } from 'expo-asset';
import { getAsset } from '../../functions/getAsset';

export const imagePaths = {
    "necklaces/lotus_flower.png": getAsset(require('../../../assets/images/necklaces/lotus_flower.png')),
    "necklaces/lotus_flower_2.png": getAsset(require('../../../assets/images/necklaces/lotus_flower_2.png')),
    "necklaces/lotus_flower_3.png": getAsset(require('../../../assets/images/necklaces/lotus_flower_3.png')),
    "necklaces/lion.png": getAsset(require('../../../assets/images/necklaces/lion.png')),
    "necklaces/lion_2.png": getAsset(require('../../../assets/images/necklaces/lion_2.png')),
    "necklaces/lion_3.png": getAsset(require('../../../assets/images/necklaces/lion_3.png')),
    "necklaces/initials_and_monograms.png": getAsset(require('../../../assets/images/necklaces/initials_and_monograms.png')),
    "necklaces/eye_of_horus_hamsa.png": getAsset(require('../../../assets/images/necklaces/eye_of_horus_hamsa.png')),
    "necklaces/eye_of_horus_hamsa_2.png": getAsset(require('../../../assets/images/necklaces/eye_of_horus_hamsa_2.png')),
    "necklaces/eye_of_horus_hamsa_3.png": getAsset(require('../../../assets/images/necklaces/eye_of_horus_hamsa_3.png')),
    "pets/dog_identification_tag.png": getAsset(require('../../../assets/images/pets/dog_identification_tag.png')),


    "necklaces/lotus_flower_circle_1.png": getAsset(require('../../../assets/images/necklaces/lotus_flower_circle_1.png')),
    "necklaces/heart_circle_1.png": getAsset(require('../../../assets/images/necklaces/heart_circle_1.png')),
    "necklaces/cannabis_flower_circle_1.png": getAsset(require('../../../assets/images/necklaces/cannabis_flower_circle_1.png')),
    "necklaces/hamsa_circle_1.png": getAsset(require('../../../assets/images/necklaces/hamsa_circle_1.png')),
    "necklaces/phonix_circle_1.png": getAsset(require('../../../assets/images/necklaces/phonix_circle_1.png')),
  };

// export const imagePaths = {
//   "Necklaces/lotus_flower.png": "",
//   "Necklaces/lotus_flower_2.png": "",
//   "Necklaces/lotus_flower_3.png": "",
//   "Necklaces/lion.png": "",
//   "Necklaces/lion_2.png": "",
//   "Necklaces/lion_3.png": "",
//   "Necklaces/initials_and_monograms.png": "",
//   "Necklaces/Eye_of_Horus_hamsa.png": "",
//   "Necklaces/Eye_of_Horus_hamsa_2.png": "",
//   "Necklaces/Eye_of_Horus_hamsa_3.png": "",
// };
  