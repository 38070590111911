import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { GlobalStyles } from '../../constants/styles';
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import i18n from '../../hooks/useI18n';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const StoreBreadcrumbs = ({
    navigation,
    itemListName = null,
    itemDetailName = null,
    userSettingsState,
    itemListId = 0,
}) => {
  
  const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.scrollViewContainer}>
      <View style={[styles.grid,{ padding: 10 }]}>
      <TouchableOpacity style={[styles.gridItem]}
        onPress={() => {
          if(itemDetailName != null){
            if(responsiveStyles.isWeb){
              // navigation(-2);
              navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
            } else{
              navigation.pop(); 
              navigation.pop();
            } 
          }
          else if(itemListName != null){
            if(responsiveStyles.isWeb){
              // navigation(-1);
              navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
            }
             else{
              navigation.pop();
             }
          }
        }}
      >
          <Text style={styles.text}>{i18n.t('store.StoreBreadcrumbs_Home')}</Text>
          {itemListName != null ?
                <AntDesign name={responsiveStyles.isRTL ? "left" : "right"}
                size={12} 
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
                style={styles.ArrowIcon}
                 />
          : null }
      </TouchableOpacity>

      {itemListName != null ?
      <TouchableOpacity style={styles.gridItem} 
      activeOpacity={itemDetailName != null ? 0.25 : 1}
      onPress={() => {
        if(itemDetailName != null){
          if(responsiveStyles.isWeb){
            // navigation(-1);
            navigateToScreen(navigation, 'StoreFlow', `ItemList`, { Id: itemListId }, itemListId);
          }
           else{
            navigation.pop();
           }
        }
    }}
      >
          <Text style={[styles.text, itemDetailName !== null ? null : styles.onFocusText]}>{itemListName}</Text>
          {itemDetailName != null ?
                <AntDesign name={responsiveStyles.isRTL ? "left" : "right"}
                size={12} 
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
                style={[styles.ArrowIcon]}
                 />
          : null }
      </TouchableOpacity>
      : null }

        {itemDetailName != null ?
      <TouchableOpacity style={styles.gridItem}
      activeOpacity={1}
      >
          <Text style={[styles.text, styles.onFocusText]}>{itemDetailName}</Text>
      </TouchableOpacity>
      : null }
      </View>
    </ScrollView>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  scrollViewContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  grid: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginHorizontal: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.045
    : responsiveStyles.screenWidth * 0.02,
  },
  gridItem: {
    // marginHorizontal: 5,
    
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  ArrowIcon: {
    paddingTop: 5, 
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 10 : 5,
  },
  text: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
    onFocusText: {
    fontWeight: 'bold',
  },
});

export default StoreBreadcrumbs;
