export const formatDate = (dateString) => {
  // Parse the date string
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const year = date.getFullYear();

  // Format as dd/mm/yyyy
  const res = `${day}/${month}/${year}`;
  if(res == null || res === "NaN/NaN/NaN"){
    return null;
  }
  return res;
};

export const formatDateTime = (dateString) => {
  // Parse the date string
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return null;
  }

  // Extract day, month, year, hours, and minutes
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Format as dd/mm/yyyy hh:mm
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedDate;
};
