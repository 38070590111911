import { Pressable, StyleSheet, Text, View, Image, ActivityIndicator } from 'react-native';
import { GlobalStyles } from '../../constants/styles';
import { useState } from 'react';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
function ButtonTypeText({ 
  children, 
  onPress, 
  mode, 
  style,
  styleButton, 
  styleText,
  loading = false, 
  userSettingsState,
 }) {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  return (
    <View style={[styles.container, {backgroundColor:GlobalStyles[responsiveStyles.ThemeValue].primaryBackgroundColor }, style,
    ]}>
      <Pressable
        onPress={onPress}
        style={({ pressed }) => pressed && styles.pressed }
        disabled={loading}
      >
        <View style={[styleButton, mode === 'flat' && styles.flat, loading ? styles.requestPressed : null
        
      ]}>
          <Text style={[styles.buttonText, mode === 'flat' && styles.flatText, styleText]}>
            {children}
            {/* {!loading ? children : <ActivityIndicator />} */}
            
          </Text>
        </View>
      </Pressable>
    </View>
  );
}

export default ButtonTypeText;

const styling = (responsiveStyles) => StyleSheet.create({
  container:{
    flexWrap: 'wrap', 
    alignSelf: 'center'
  },
  flat: {
    backgroundColor: 'transparent',
  },
  buttonText: {
    color: GlobalStyles[responsiveStyles.ThemeValue].inputColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H4,
    fontWeight: '700',
    fontFamily: GlobalStyles.fonts.application_font,
  },
  flatText: {
    color: "#a281f0",
  },
  pressed: {
    opacity: 0.65,
    borderRadius: 4,
  },
  requestPressed: {
    opacity: 0.4,
    borderRadius: 4,
  },
});