import AsyncStorage from "@react-native-async-storage/async-storage";
import createDataContext from "./createDataContext";
import { navigate } from "../navigationRef";
import { getAsync, postAsync } from "../api/categoryApiCalls";
import i18n from "../hooks/useI18n";
import { getResponseByErr } from "../functions/getResponseByErr";
import { getItem, getItemAsync, saveAsync } from "../modules/Storage";
import { deviceId } from "../constants/StaticData";
import { navigateToHomeScreen } from "../functions/navigateToHomeScreen";
import { DarkMode_Storage, DraggableListState_Storage, HomeScreenDefault_Storage, IsAdmin_Storage, Language_Storage, MainCategoryListScreen_displayType_Storage, UserId_Storage, token_Storage } from "../modules/StorageObjects";
// import { GoogleSignin } from "@react-native-google-signin/google-signin";
// import auth from '@react-native-firebase/auth';
import { Platform } from "react-native";
import { navigateToScreen } from "../navigationResponsive";

const authReducer = (state, action) => {
  switch (action.type) {
    case "add_error":
      return { ...state, errorMessage: action.payload };
    case "signin":
      return { ...state, errorMessage: "", token: action.payload };
      case "add_error_signinAlertMessage":
      return { ...state, signinAlertMessage: action.payload };
      case "add_error_signupAlertMessage":
        return { ...state, signupAlertMessage: action.payload };
        case "add_error_signupConfirmUserNameAndPasswordByEmailAlertMessage":
          return { ...state, signupConfirmUserNameAndPasswordByEmailAlertMessage: action.payload };
      case "add_error_forgotPasswordAlertMessage":
        return { ...state, forgotPasswordAlertMessage: action.payload };
        case "add_error_forgotPasswordCodeConfirmationAlertMessage":
          return { ...state, forgotPasswordCodeConfirmationAlertMessage: action.payload };
          case "add_error_forgotPasswordConfirmCanChangePasswordAlertMessage":
            return { ...state, forgotPasswordConfirmCanChangePasswordAlertMessage: action.payload };
      case "add_error_emailConfirmation":
      return { ...state, emailConfirmationErrorMessage: action.payload };
        case "setSignUpConfirmEmailCodeModalVisible":
        return { ...state, signUpConfirmEmailCodeModalVisible: action.payload };
        case "setForgotPasswordCodeConfirmationModalVisible":
          return { ...state, forgotPasswordCodeConfirmationModalVisible: action.payload };
        case "setForgotPasswordConfirmCanChangePassword":
          return { ...state, forgotPasswordConfirmCanChangePasswordModalVisible: action.payload };
        case "setResetPasswordshowLoader":
        return { ...state, resetPasswordshowLoader: action.payload };
        case "setResentEmailCodeshowLoader":
          return { ...state, resentEmailCodeshowLoader: action.payload };   
          case "setValidateUserNameShowLoader":
            return { ...state, validateUserNameShowLoader: action.payload };   
    case "clear_error_message":
      return { ...state, errorMessage: "" };
      case "validateUserName":
        return { ...state, validateUserName: action.payload };
    case "signout":
      return { ...state, token: null, errorMessage: "" };
    default:
      return state;
  }
};

const saveTokenToContext = (dispatch) => async () => {
  let token = await AsyncStorage.getItem(token_Storage);
  if (token) {
    dispatch({ type: "signin", payload: token });
  } else {
    console.log("token was not found in func saveTokenToContext");
  }
};

const tryLocalSignin = (dispatch) => async (navigation) => {
  let token = await AsyncStorage.getItem(token_Storage);
  // token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzNlYzdiMGFmYjIwMjIwYThlYTM5NmIiLCJpYXQiOjE2NjUwNTg3MzZ9.QmSwCTqM_XuaQRcQJqyt1KSXZmhRAGXYG8LI0AR4yO8";
  if (token) {
    console.log(`token from tryLocalSignin: ${token}`);
    dispatch({ type: "signin", payload: token });
      await navigateToHomeScreen(navigation, false);
  } else {
    console.log("token was not found in func tryLocalSignin");
    navigateToScreen(navigation, 'LoginFlow', `Signin`, null, null);
    // navigate('LoginFlow', { screen: 'Signin' });
    // navigate("Signin");
  }
};

const addErrorMessage = (dispatch) => (errorMessage, message) => {
  if(message != undefined && message !== null && message.length > 0)
  {
    console.log(`addErrorMessage, errorMessage ${errorMessage} + message ${message}`);
  }
  dispatch({ 
    type: "add_error_" + errorMessage,
    payload: message
});
};
const setForgotPasswordCodeConfirmationModalVisible = (dispatch) => async ({ message }) => {
  {
    dispatch({ 
      type: "setForgotPasswordCodeConfirmationModalVisible",
      payload: message
  });
  }
};
const setSignUpConfirmEmailCodeModalVisible = (dispatch) => async ({ message }) => {
  {
    console.log(`setSignUpConfirmEmailCodeModalVisible message ${message}`);
    dispatch({ 
      type: "setSignUpConfirmEmailCodeModalVisible",
      payload: message
  });
  }
};

const setForgotPasswordConfirmCanChangePasswordModalVisible = (dispatch) => async ({ message }) => {
  {
    dispatch({ 
      type: "setForgotPasswordConfirmCanChangePasswordModalVisible",
      payload: message
  });
  }
};

const signin = (dispatch) =>
  async ({ email, password }) => {
    try {
      const user = {
        UserName: email,
        Password: password
      };
      const vm = {
        user,
        deviceId
      }
      console.log("before signin");
      const req = await postAsync(
        "/Auth/SignIn",
        vm
      );
      console.log("after signin");

      const response = req.data;
      console.log(response);
      if (response.isCompleted) {
        const answer = JSON.parse(response.answer);
        const UserSettings = answer.UserSettings;
        await saveAsync(Language_Storage, UserSettings.Language.slice(0, 2));
        await saveAsync(DarkMode_Storage, UserSettings.DarkMode);
        await saveAsync(UserId_Storage, UserSettings.UserId);
        const token = answer.Token.Token;
        await AsyncStorage.setItem(token_Storage, token);
        await saveAsync(IsAdmin_Storage, answer.IsAdmin);
        dispatch({ type: "signin", payload: token });
        
      }
      else {
        dispatch({
          type: "add_error_signinAlertMessage",
          payload: response.message,
        });
        }
      return response;
    } catch (err) {
      console.log("err from signin " + err);
      dispatch({
        type: "add_error_signinAlertMessage",
        payload: err.message,
      });
      return getResponseByErr(err);
    }
  };
  const signInGoogle = (dispatch) => async ({ user, idToken }) => {
    try {
      const userAuth = {
        email: user.user.email,
        googleUID: idToken,
      };
      const vm = {
        user: userAuth,
        deviceId
      }
      console.log(`before signInGoogle user.email ${user.email} idToken ${idToken}`);
      const req = await postAsync(
        "/Auth/SignIn",
        vm
      );
      console.log("after signInGoogle");

      const response = req.data;
      if (response.isCompleted) {
        const answer = JSON.parse(response.answer);
        const UserSettings = answer.UserSettings;
        await saveAsync(Language_Storage, UserSettings.Language.slice(0, 2));
        await saveAsync(DarkMode_Storage, UserSettings.DarkMode);
        await saveAsync(UserId_Storage, UserSettings.UserId);
        const token = answer.Token.Token;
        await AsyncStorage.setItem(token_Storage, token);
        await saveAsync(IsAdmin_Storage, answer.IsAdmin);

        dispatch({ type: "signin", payload: token });
        
      }
      else {
        dispatch({
          type: "add_error_signinAlertMessage",
          payload: response.message,
        });
        }
      return response;
    } catch (err) {
      console.log("err from signin " + err);
      dispatch({
        type: "add_error_signinAlertMessage",
        payload: err.message,
      });
      return getResponseByErr(err);
    }
  };
const signout = (dispatch) => async () => {
  dispatch({ type: "signout" });
  
  try{
  const vm = {
    deviceId
  }
  // not await because we dont want the user will wait for the server
  const req = postAsync(
    "/Auth/SignOut",
    vm
  );
}
catch(error)
{
  console.log(error);
}
try{
  console.log("casoufio");
  await AsyncStorage.clear();
  // if(Platform.OS !== "android" && Platform.OS !== "ios"){
  //   console.log('Google Sign-In is not supported on this platform.');
  // }
  // else{
  //   await GoogleSignin.revokeAccess();
  //   await auth().signOut();
  // }

}
catch(error)
{
  console.log(error);
}

console.log("signout");
  // await AsyncStorage.removeItem(token_Storage);
  // await saveAsync(DraggableListState_Storage,null);
  // await saveAsync(Language_Storage,null);
  // await saveAsync(DarkMode_Storage,null);
  // await saveAsync(HomeScreenDefault_Storage,1);
  // await saveAsync(MainCategoryListScreen_displayType_Storage,null);
  // send to server that the user signed out
};
const SignUpWithEmail = (dispatch) => async ({ email, idToken }) => {
  try {
    const vm = {
      email: email,
      googleUID: idToken,
    }
      const req = await postAsync(
        "/Auth/SignUpWithEmail",
        vm
      );
      const response = req.data;
      if (response.isCompleted) {
     
      }
      else {
        dispatch({
          type: "add_error_signupAlertMessage",
          payload: response.message,
        });
        }
        return response;

  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "add_error_signupAlertMessage",
      payload: err.message,
    });
    return getResponseByErr(err);
  }
}
const ResentEmailCode = (dispatch) => async ({ email }) => {
  try {
    
    dispatch({ 
      type: "setResentEmailCodeshowLoader",
      payload: true
  });
      const req = await postAsync(
        "/Auth/ResentEmailCode",
        email
      );
      const response = req.data;
      if (response.isCompleted) {
      dispatch({ 
        type: "setSignUpConfirmEmailCodeModalVisible",
        payload: true
    });
      }
      else {
        }
        return response;

  } catch (err) {
    console.log(err.message);
  return getResponseByErr(err);
  }
  finally{
    dispatch({ 
      type: "setResentEmailCodeshowLoader",
      payload: false
  });
  }
}
const SignUpConfirmEmailCode = (dispatch) => async ({ email, code }) => {
  try {
      const req = await postAsync(
        "/Auth/ConfirmEmailCode",
        { email, code }
      );
      const response = req.data;
      if (response.isCompleted) {
      }
      else {
        throw new Error('BadRequest');
        }
        return response;

  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "add_error_emailConfirmation",
      payload: i18n.t('auth.SignUpConfirmEmailCodeAlert_IncorrectCode'),
    });
    return getResponseByErr(err);
  }
}
const ValidateUserNameNotTaken = (dispatch) => async ({ username }) => {
  try {
    dispatch({ 
      type: "setValidateUserNameShowLoader",
      payload: true
  });
  console.log("username " + username);
    const req = await postAsync(
      "/Auth/ValidateUserNameNotTaken",
       username 
    );

      const response = req.data;
      if(response.isCompleted)
      {
        const answer = JSON.parse(response.answer);

      dispatch({
        type: "validateUserName",
        payload: answer,
      })
    }
    else{
      throw new Error('BadRequest');
    }
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "validateUserName",
      payload: false,
    });
  }
  finally
  {
    dispatch({ 
      type: "setValidateUserNameShowLoader",
      payload: false
  });
  }
}
const ConfirmUserNameAndPasswordByEmail = (dispatch) => async ({ email, userName, password, firstName, lastName }) => {
  try {
    dispatch({
      type: "add_error_signupConfirmUserNameAndPasswordByEmailAlertMessage",
      payload: "",
    });
    const req = await postAsync(
      "/Auth/ConfirmUserNameAndPasswordByEmail",
      { email, userName, password, firstName, lastName }
    );

      const response = req.data;
      if (response.isCompleted) {
      }
      else {
        dispatch({
          type: "add_error_signupConfirmUserNameAndPasswordByEmailAlertMessage",
          payload: response.message,
        });

        }
        return response;
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "add_error_signupConfirmUserNameAndPasswordByEmailAlertMessage",
      payload: err.message,
    });
    return response;
  }
}
const AddUserByEmail = (dispatch) => async ({ email, navigation }) => {
  try {
    const vm = {
      email,
      deviceId
    }
      const req = await postAsync(
        "/Auth/AddUserByEmail",
        vm
      );
      const response = req.data;
        if (response.isCompleted) {
        const answer = JSON.parse(response.answer);
        const UserSettings = answer.UserSettings;
        await saveAsync(Language_Storage, UserSettings.Language.slice(0, 2));
        await saveAsync(DarkMode_Storage, UserSettings.DarkMode);
        await saveAsync(UserId_Storage, UserSettings.UserId);
        const token = answer.Token.Token;
        await AsyncStorage.setItem(token_Storage, token);
        await saveAsync(IsAdmin_Storage, answer.IsAdmin);
        dispatch({ type: "signin", payload: token });
        navigateToScreen(navigation, 'ResolveAuth', `ResolveAuth`, null, null);
        // navigate("ResolveAuth");
        }
        else {
          throw new Error('BadRequest');
          }
          return response;

  } catch (err) {
    console.log(err.message);
          dispatch({
      type: "add_error",
      payload: "Something went wrong with sign up",
    });
    return getResponseByErr(err);
  }
}
const ForgotPasswordCodeConfirmationResetPassword = (dispatch) => async ({ email }) => {
  try {


    // setResetPasswordshowLoader
    dispatch({ 
      type: "setResetPasswordshowLoader",
      payload: true
  });
      const req = await postAsync(
        "/Auth/ResetPassword",
        email
      );
      const response = req.data;
      if (response.isCompleted) {
        dispatch({ 
          type: "setForgotPasswordCodeConfirmationModalVisible",
          payload: true
      });
      }
      else {
            
          }
      return response;

  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "setForgotPasswordConfirmCanChangePassword",
      payload: true,
    });
    return getResponseByErr(err);
  }
  finally{
    dispatch({ 
      type: "setResetPasswordshowLoader",
      payload: false
  });
  }
}

const ResetPassword = (dispatch) => async ({ email }) => {
  try {
      const req = await postAsync(
        "/Auth/ResetPassword",
        email
      );
      const response = req.data;
      if (response.isCompleted) {
        const answer = JSON.parse(response.answer);
      }
      else {
        throw new Error('BadRequest');

        }
        return response;
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "add_error_forgotPasswordAlertMessage",
      payload: true,
    });
    return getResponseByErr(err);
  }
}
const ConfirmResetPasswordCode = (dispatch) => async ({ email, code, navigation }) => {
  try {
      const req = await postAsync(
        "/Auth/ConfirmResetPasswordCode",
        { email, code }
      );
      const response = req.data;
      if (response.isCompleted) {
        navigateToScreen(navigation, 'LoginFlow', `ForgotPasswordConfirmCanChangePassword`, { email, code }, null);
        // navigate('LoginFlow', { screen: 'ForgotPasswordConfirmCanChangePassword', params: { email, code } });
      // navigate('ForgotPasswordConfirmCanChangePassword', { email, code });
      }
      else {
        throw new Error('BadRequest');

        }
        return response;
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "add_error_forgotPasswordCodeConfirmationAlertMessage",
      payload: i18n.t('auth.SignUpConfirmEmailCodeAlert_IncorrectCode'),
    });
    return getResponseByErr(err);
  }
}
const ConfirmResetPassword = (dispatch) => async ({ email, code, password, navigation }) => {
  try {
    dispatch({
      type: "setForgotPasswordConfirmCanChangePassword",
      payload: false,
    });
    const req = await postAsync(
      "/Auth/ConfirmResetPassword",
      { email, code, password, deviceId }
    );
      const response = req.data;      
      if (response.isCompleted) {
        const answer = JSON.parse(response.answer);
        const UserSettings = answer.UserSettings;
        await saveAsync(Language_Storage, UserSettings.Language.slice(0, 2));
        await saveAsync(DarkMode_Storage, UserSettings.DarkMode);
        await saveAsync(UserId_Storage, UserSettings.UserId);
        const token = answer.Token.Token;
        await AsyncStorage.setItem(token_Storage, token);
        await saveAsync(IsAdmin_Storage, answer.IsAdmin);
        dispatch({ type: "signin", payload: token });
        navigateToScreen(navigation, 'ResolveAuth', `ResolveAuth`, null, null);
        // navigate("ResolveAuth");
      }
      else {
        dispatch({
          type: "add_error_forgotPasswordConfirmCanChangePasswordAlertMessage",
          payload: response.message,
        });
        }
        return response;

  } catch (err) {
    console.log(err.message);
    dispatch({
      type: "add_error_forgotPasswordConfirmCanChangePasswordAlertMessage",
      payload: err.message,
    });
    return getResponseByErr(err);
  }
  finally{
    dispatch({
      type: "setForgotPasswordConfirmCanChangePassword",
      payload: true,
    });
  }
}
export const { Provider, Context } = createDataContext(
  authReducer,
  { signin,
    saveTokenToContext,
    signInGoogle,
    signout, 
    addErrorMessage, 
    tryLocalSignin,
    SignUpWithEmail, 
    SignUpConfirmEmailCode, 
    ConfirmUserNameAndPasswordByEmail, 
    ResentEmailCode,
    AddUserByEmail,
    ResetPassword, 
    ConfirmResetPasswordCode, 
    ConfirmResetPassword, 
    ForgotPasswordCodeConfirmationResetPassword,
    setForgotPasswordCodeConfirmationModalVisible, 
    setForgotPasswordConfirmCanChangePasswordModalVisible, 
    setSignUpConfirmEmailCodeModalVisible,
    ValidateUserNameNotTaken,
   },
  { token: null, 
    errorMessage: "",
   signinAlertMessage: "",
   signupAlertMessage: "",
   signupConfirmUserNameAndPasswordByEmailAlertMessage: "",
   emailConfirmationErrorMessage: "",
   forgotPasswordAlertMessage: false,
   ForgotPasswordCodeConfirmationAlertMessage: "",
   forgotPasswordCodeConfirmationModalVisible: false,
   resetPasswordshowLoader: false,
   resentEmailCodeshowLoader: false,
   forgotPasswordConfirmCanChangePasswordAlertMessage: "",
   forgotPasswordConfirmCanChangePasswordModalVisible: false,
   validateUserName: "",
   validateUserNameShowLoader: false,
   }
);
