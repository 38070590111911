import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { MaterialIcons } from '@expo/vector-icons';

  const FullScreenIcon = ({ 
    userSettingsState,
    showFullScreen,
    isAudio,
    onOpen,
    bottom = null,
    fullScreenZindex = null,
    style,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles, bottom);
  return (
    
      showFullScreen ? (
    <TouchableOpacity
    style={[{
      position: 'absolute',
      bottom: bottom ? bottom : 0,
      // bottom: 18,
      right: 0,
      // bottom: '5%',
      // right: '2%',
      marginLeft: isAudio ? -20 : -24, // Adjust the button's position based on its width and height
      marginTop: isAudio ? -35 : -24,
      // backgroundColor:'brown',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: 8,
      paddingTop: 14,
      paddingLeft: 100,
      zIndex: fullScreenZindex ? fullScreenZindex : null,
    },
    style,
  ]}
    onPress={onOpen}
    >
            {!isAudio ? 
            <MaterialIcons
            name="open-in-full" 
            size={!responsiveStyles.isMobile ? 26 : 20} 
            // color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
            color={"white"}
            />
            : null }
    </TouchableOpacity>
    ) : null
  );
}
const styling = (responsiveStyles, bottom) => StyleSheet.create({

});

export default FullScreenIcon;