import { Buffer } from 'buffer';
import { apikey } from '../api/apikey';

export const getTranzilaURL = (amount, OrderId, userSettingsState, JsonToSend) => {
    const terminalName = "tmj123";
    const currency = 1; // סוג המטבע (1 = שקל)
  
    const successUrl = `https://timelessmemories-jewelry.com/PaymentSuccessful/${OrderId}`; // URL להצלחה
    // const successUrl = "https://timelessmemories-jewelry.com/PaymentSuccessful/LastOrder"; // URL להצלחה
    const failUrl = "https://timelessmemories-jewelry.com/PaymentFailed"; // URL לכישלון
    const notifyUrl = `${apikey}/api/Order/NotifyOrderAfterPayment`; // URL להודעות
    // const notifyUrl = `http://5734622882b8.ngrok.app/api/Order/NotifyOrderAfterPayment`; // URL להודעות

    // const notifyUrl = "https://timelessmemories-jewelry.com/Notify"; // URL להודעות
    const buttonLabel = "Pay Now!"; // כפתור התשלום
    const bitPay = 0; // אפשרות לתשלום בביט
    const googlePay = 0; // אפשרות לתשלום ב-Google Pay
    const json = JSON.stringify(JsonToSend);
    const base64EncodedJson = Buffer.from(json, 'utf8').toString('base64');
    const urlSafeBase64 = encodeURIComponent(base64EncodedJson); // Ensure it's safe for URLs
    
    const iframeUrl = `https://direct.tranzila.com/${terminalName}/iframenew.php?
    sum=${amount}
    &currency=${currency}
    &success_url_address=${encodeURIComponent(
        successUrl
      )}&fail_url_address=${encodeURIComponent(
        failUrl
      )}&notify_url_address=${encodeURIComponent(
        notifyUrl
      )}&buttonLabel=${encodeURIComponent(buttonLabel)}
      &bit_pay=${bitPay}
      &google_pay=${googlePay}
      &UserId=${userSettingsState?.userSettings?.UserId}
      &OrderId=${OrderId}
      &Json=${urlSafeBase64}
      `;

      return iframeUrl;
    };