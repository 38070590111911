import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions, KeyboardAvoidingView, Platform, Keyboard } from "react-native";
import { TextInput } from "react-native";
import { Feather } from '@expo/vector-icons'; 
import i18n from "../../hooks/useI18n";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { TouchableWithoutFeedback } from "react-native";
import TextInputCustom from "../inputs/TextInputCustom";

  const ChatTextInput = ({ 
    userSettingsState,
    message,
    setMessage,
    onSendButtonPress,
    isForDisplayMessage = false,
    userName = '',
 }) => {

  const [isFocused, setIsFocused] = useState(false);

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  return (
    <KeyboardAvoidingView
    // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    behavior="padding"
    style={styles.container}
  >
    {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
<View style={[
  styles.SendMessageContainer,
  isForDisplayMessage ? { justifyContent:'center', flex:1, borderRadius: 25 } : null,
  ]}
  onLayout={(event) => {
    let {height} = event.nativeEvent.layout;
    console.log(`onLayout height is ${height}`);
  }}
  >

      <TextInputCustom
       userSettingsState={userSettingsState}
       editable={true}
       multiline={true}
       value={message}
       onChangeText={(text) => {
        setMessage(text);
      }}
      label={`${i18n.t(`media.CommentSectionPopup_TextInput`)} ${userName}`}
      showLabel={false}
      style={[styles.TextInput]}
      iconType="cancel"
      // heightTextInput={responsiveStyles.screenHeight * 0.3 }
      // isKeyboardVisible={isKeyboardVisible}
      // keyboardDidShowTxt={keyboardDidShowTxt}
      // refreshComponent={refreshPage}
      // onFocus={onFocus}
      // onBlur={onBlur}
      />
        {/* <View style={[
          styles.TextInputContainer,
        isForDisplayMessage ? { width: responsiveStyles.screenWidth * 0.35, } : null,
        { 
          // marginBottom: !responsiveStyles.isMobile ? 5 : null
         }
        ]}>
        <TextInput
        placeholder={`${i18n.t(`media.CommentSectionPopup_TextInput`)} ${userName}`}
        style={styles.TextInput}
          placeholderTextColor={GlobalStyles[responsiveStyles.ThemeValue]?.placeholderColor}
          color={GlobalStyles[responsiveStyles.ThemeValue]?.textColor}
          value={message}
          onChangeText={text => setMessage(text)}
          editable={!isForDisplayMessage}
          isRTL={responsiveStyles.isRTL}
          multiline={true}
          numberOfLines={10}
        />
          
          </View> */}
        <TouchableOpacity 
        style={[
          styles.sendButtonContainer,
          {
        borderColor: message.length === 0 ? GlobalStyles[responsiveStyles.ThemeValue]?.MessageButtonSendDisabled : GlobalStyles[responsiveStyles.ThemeValue]?.Message_SameUserMessageContainerBackgroundColor,
        backgroundColor: message.length === 0 ? GlobalStyles[responsiveStyles.ThemeValue]?.MessageButtonSendDisabled : GlobalStyles[responsiveStyles.ThemeValue]?.Message_SameUserMessageContainerBackgroundColor,
        },
        isForDisplayMessage ? { width: 40, height: 40,} : null,
      ]}
        activeOpacity={message.length === 0 ? 1 : 0.25}
        onPress={onSendButtonPress}
        >
        <Feather name="send" size={20} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
      </View>
      {/* </TouchableWithoutFeedback> */}
      </KeyboardAvoidingView>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    // backgroundColor: 'red',
    minHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 80 : 80,
    maxHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 80 : 80,
  },
text: {
  fontFamily: GlobalStyles.fonts.application_font,
},
SendMessageContainer: { 
    alignItems: 'center',
    justifyContent: 'space-around',
    // backgroundColor:GlobalStyles[responsiveStyles.ThemeValue]?.Message_bodyContainer, // to display the ImageBackground i need to comment THIS line
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    
    flex: 1, 
    alignItems:'flex-end',
   //  marginBottom: !responsiveStyles.isMobile ? 10 : null
    // paddingBottom: 5,
    // marginHorizontal: 10,
    
    },
    // TextInputContainer: { 
    //   alignItems: 'center',
    //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor, 
    //   flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    //   borderRadius: 50,
    //   borderWidth:1,
    //   // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.Message_bodyContainer,
    //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.53 : responsiveStyles.screenWidth * 0.85,
    //   // height: 75,
    // //   minHeight: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65) - 10,
    // // maxHeight: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65) - 10,
    // // marginBottom: 5,
    //   },
      sendButtonContainer: {
        // width: 
        // !responsiveStyles.isMobile ? 
        // responsiveStyles.screenWidth * 0.035
        // : Math.max(responsiveStyles.screenHeight * 0.055, responsiveStyles.screenWidth * 0.10),
        // height: 
        // !responsiveStyles.isMobile ? 
        // responsiveStyles.screenWidth * 0.035
        // : Math.max(responsiveStyles.screenHeight * 0.055, responsiveStyles.screenWidth * 0.10),

        minWidth: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 50),
        maxWidth: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 50),
        minHeight: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 50),
        maxHeight: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 50),
        borderWidth: 1,
        borderRadius: 50,
        alignItems:'center',
        justifyContent:'center',
        transform: responsiveStyles.isRTL ? [{ scaleX: -1 }] : [],
        marginHorizontal: 5,
        // backgroundColor:'brown',
        marginBottom: (responsiveStyles.isDesktop || responsiveStyles.isLaptop) ? 15 : 15,

      },
      sendButton: {
        borderRadius: 50,
      },
      TextInput: { 
        // flex: 1, 
        // height: responsiveStyles.screenHeight * 0.055, 
        // paddingHorizontal: 10,
        // color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        // fontFamily: GlobalStyles.fonts.application_font,
        // textAlign: responsiveStyles.isRTL ? 'right' : 'left',
        marginBottom: (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 10)
       },
});

export default ChatTextInput;