import React, { useState, useEffect } from 'react';

import { StyleSheet, Text, View, Image, Button } from 'react-native';
import 'expo-dev-client';
// import { GoogleSignin, GoogleSigninButton } from '@react-native-google-signin/google-signin';
// import auth from '@react-native-firebase/auth';

const googlesignin = ( {  } ) => {
  return (
    <View>
      <Text>googlesignin</Text>
    </View>
  );
  //    // Set an initializing state whilst Firebase connects
  // const [initializing, setInitializing] = useState(true);
  // const [user, setUser] = useState();

  // GoogleSignin.configure({
  //   webClientId: '619539221125-4cfdpm1njnkho3pfgejibaro3mp4o5ea.apps.googleusercontent.com',
  // });
  // useEffect(() => {
  //   const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
  //   return subscriber; // unsubscribe on unmount
  // }, []);
  // // Handle user state changes
  // function onAuthStateChanged(user) {
  //   setUser(user);
  //   if (initializing) setInitializing(false);
  // }
  // const onGoogleButtonPress = async() => {
  //   console.log("onGoogleButtonPress");
  //   // Check if your device supports Google Play
  //   await GoogleSignin.hasPlayServices({ showPlayServicesUpdateDialog: true });
  //   // Get the users ID token
  //   console.log(`before const { idToken } = await GoogleSignin.signIn();`);
  //   const { idToken } = await GoogleSignin.signIn();
  //   console.log(`after const { idToken } = await GoogleSignin.signIn();`);
  
  //   // Create a Google credential with the token
  //   const googleCredential = auth.GoogleAuthProvider.credential(idToken);
  // console.log(`googleCredential ${googleCredential}`);
  //   // Sign-in the user with the credential
  //   const user_sign_in = auth().signInWithCredential(googleCredential);
  //   user_sign_in.then((user) => {
  //     console.log(user);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   })
  //   // return user_sign_in;
  // }


  // const signOut = async () => {
  //   try{
  //     await GoogleSignin.revokeAccess();
  //     await auth().signOut();
  //   }
  //   catch(error)
  //   {
  //     console.log(error);
  //   }
  // }
  // if (initializing) return null;

  // if (!user) {
  //   return (
  //     <View style={styles.container}>
  //       <GoogleSigninButton 
  //       style={{ window:300, height: 65, marginTop: 300 }}
  //       onPress={onGoogleButtonPress}
  //       />
  //     </View>
  //   );
  // }

  // return (
  //   <View style={styles.container}>
  //     <View style={{ marginTop:100, alignItems:'center' }}>
  //       <Text tyle={{fontSize:23, fontWeight:'bold', fontFamily: GlobalStyles.fonts.application_font,}}>{user.uid}</Text>
  //     <Text style={{fontSize:23, fontWeight:'bold', fontFamily: GlobalStyles.fonts.application_font,}} >Welcome {user.email}</Text>
  //     <Image
  //     source={{ uri: user.photoURL }}
  //     style={{height:300, width:300, borderRadius: 150, marginTop:50}}
  //     />
  //     <Button  title='Sign Out' onPress={signOut}/>
  //     </View>
  //   </View>
  // );
};

googlesignin.navigationOptions = {
  header: () => false,
};

const styles = StyleSheet.create({
 container: {
    flex:1,
    justifyContent: 'center',
    alignItems: 'center',
 }
});

export default googlesignin;
