import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions, FlatList, TouchableOpacity, ScrollView, Button, TextInput, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import SelectDropdown from 'react-native-select-dropdown';
import { Feather } from '@expo/vector-icons'; 
import TextInputCustom from '../../components/inputs/TextInputCustom';
import DateComponent from '../../components/inputs/item/DateComponent';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { navigateToScreen } from '../../navigationResponsive';
import LoadingScreen from '../../components/LoadingScreen';
import ErrorComponent from '../../components/ErrorComponent';
import { GetAllOrdersByPagination } from '../../api/apiCalls/AdminCalls';
import { formatDate } from '../../functions/formatDate';

const AdminOrdersListScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    
    const [orders, setOrders] = useState([]);
    // const orders = [
    //   { OrderId: '001', CustomerName: 'John Doe', OrderDate: '2024-09-10', OrderStatus: 'Pending', Total: 150, PaymentStatus: 'Paid' },
    //   { OrderId: '002', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '003', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '004', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '005', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '006', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '007', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '008', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '009', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '010', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '011', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '012', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    //   { OrderId: '013', CustomerName: 'Jane Smith', OrderDate: '2024-09-11', OrderStatus: 'Shipped', Total: 200, PaymentStatus: 'Pending Payment' },
    // ];
    const statusOptions = ['Select Status', 'Pending', 'Shipped', 'Delivered'];
    const paymentOptions = ['Select Payment', 'Paid', 'Pending Payment'];

    const defaultFilter = {
      OrderDate: null,
      CustomerName: '',
      OrderStatus: '',
      PaymentStatus: '',
    };
    const [filters, setFilters] = useState(defaultFilter);
    const applyFilters = () => {
      const newData = orders.filter(item => {
        return (
          (filters.OrderDate === '' || item.OrderDate.includes(filters.OrderDate)) &&
          (filters.CustomerName === '' || item.CustomerName.toLowerCase().includes(filters.CustomerName.toLowerCase())) &&
          (filters.OrderStatus === '' || item.OrderStatus === filters.OrderStatus) &&
          (filters.PaymentStatus === '' || item.PaymentStatus === filters.PaymentStatus)
        );
      });
      return newData;
    };

    const renderTableHeader = () => (
      <View style={styles.tableHeader}>
        <Text style={styles.headerText}>Order ID</Text>
        <Text style={styles.headerText}>Customer</Text>
        <Text style={styles.headerText}>Date</Text>
        <Text style={styles.headerText}>Status</Text>
        <Text style={styles.headerText}>Payment</Text>
        <Text style={styles.headerText}>Total</Text>
        <Text style={styles.headerText}></Text>
      </View>
    );
  
    // Table Row Component
    const renderItem = ({ item }) => {
      const formattedOrderDate = formatDate(item.OrderDate);
      return (
        <View style={styles.tableRow}>
          <Text style={styles.cellText}>{item.OrderId}</Text>
          <Text style={styles.cellText}>{item.CustomerName}</Text>
          <Text style={styles.cellText}>{formattedOrderDate}</Text>
          <Text style={styles.cellText}>{item.OrderStatus}</Text>
          <Text style={styles.cellText}>{item.PaymentStatus}</Text>
          <Text style={styles.cellText}>${item.Total}</Text>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => {
              navigateToScreen(navigation, 'AdminFlow', `AdminOrderDetails`, { OrderId: item.OrderId }, item.OrderId);
            }}
          >
            <Text style={styles.buttonText}>Details</Text>
          </TouchableOpacity>
        </View>
      );
    };

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };

    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);

    const fetchData = async () => {
      try {
        setLoading(true);
        const req = await GetAllOrdersByPagination(filters);
        if (req.isCompleted) {
          const vm = JSON.parse(req.answer);
          setOrders(vm);
        }
        else{
          setLoading(true);
          setErrorOnRequest(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(true);
        setErrorOnRequest(true);
      }
      finally{
        setLoading(false);
      }
    };
    useEffect(() =>{
      fetchData();
      if(responsiveStyles.isWeb){
        document.title = "Admin Orders List";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[reloadpage]);

    console.log(`filters ${JSON.stringify(filters)}`);
    
  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  >
    {errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen
      userSettingsState={userSettingsState}
       />
    </View> 
    : ( 
    <ScrollView horizontal={true} style={styles.horizontalScrollView}>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
    <View style={[styles.container]}>
      <View style={styles.header}>
        <GenericAlert
        userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>

            {/* Filters */}
            <View style={styles.filtersContainer}>
      <TextInputCustom
      userSettingsState={userSettingsState}
       editable={true}
       value={filters.CustomerName}
       onChangeText={(text) => setFilters((prev) => ({ ...prev, CustomerName: text }))}
      label={"Customer Name"}
      style={styles.textInputName}
      iconType="cancel"
      />
      <View style={{paddingHorizontal: 10}}>
        <DateComponent
        onChangeFieldValue={(itemId, value) => {
          setFilters((prev) => ({ ...prev, OrderDate: value }));
        }}
        editable={true}
        initValue={filters.OrderDate}
        item={{
            fieldName: `Order date`
        }}
        userSettingsState={userSettingsState}
        styleContainer={[styles.textInputName, { margin: 0 }]}
          />
          </View>

        <SelectDropdown
          data={statusOptions}
          defaultButtonText="Select Status"
          selectedItem={filters.OrderStatus}
          onSelect={(selectedItem) => {
            let value = selectedItem === "Select Status" ? "" : selectedItem;
            setFilters((prev) => ({ ...prev, OrderStatus: value }))
          }}
          buttonStyle={styles.dropdown}
          renderDropdownIcon={(isOpened) => {
            return (
                <Feather
                    name={isOpened ? "chevron-up" : "chevron-down"}
                    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
                    size={24}
                    style={{ marginHorizontal: 10 }}
                />
            );
        }}
        />
        <View style={{ paddingHorizontal: 10 }}>
        <SelectDropdown
          data={paymentOptions}
          defaultButtonText="Select Payment"
          selectedItem={filters.PaymentStatus}
          onSelect={(selectedItem) => {
            let value = selectedItem === "Select Payment" ? "" : selectedItem;
            setFilters((prev) => ({ ...prev, PaymentStatus: value }));
          }}
          buttonStyle={styles.dropdown}
          renderDropdownIcon={(isOpened) => {
            return (
                <Feather
                    name={isOpened ? "chevron-up" : "chevron-down"}
                    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
                    size={24}
                    style={{ marginHorizontal: 10 }}
                />
            );
        }}
        />
        </View>

      </View>
      <View style={styles.buttonContainer}>
      <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.StyleButtonContainer}
          styleButton={styles.styleButton}
          onPress={async () => {
            setFilters(defaultFilter);
            fetchData();
        }}
          >
          Clear
          </ButtonTypeButton>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={[styles.StyleButtonContainer, { paddingHorizontal: 20 }]}
          styleButton={styles.styleButton}
          onPress={async () => {
            fetchData();
        }}
          >
          Search
          </ButtonTypeButton>
      </View>



        {/* Render Table Header */}
        {renderTableHeader()}

      <FlatList
      style={styles.flatListContainer}
      data={orders}
      // data={applyFilters(orders)}
      renderItem={renderItem}
      keyExtractor={(item) => item.OrderId}
      />
    </View>
    </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </ScrollView>
    )}
    </Screen>

}


AdminOrdersListScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    padding: 20,
  },
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems:'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems:'flex-start',
    paddingBottom: 10,
  },
  flatListContainer: {
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBorderColor,
    paddingBottom: 10,
    marginBottom: 10,
  },
  headerText: {
    flex: 1,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
    textAlign: 'center',
    width: 100,
  },
  tableRow: {
    flex:1,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBorderColor,
    paddingVertical: 10,
  },
  cellText: {
    flex: 1,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
    textAlign: 'center',
    width: 100,
    alignSelf:'center',
  },
  actionButton: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.buttonBackgroundColor,
    borderRadius: 5,
    width: 100,
    alignItems: 'center',
    alignSelf:'center',
  },
  buttonText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.buttonTextColor,
  },
  textInputName: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.22 : responsiveStyles.screenWidth * 0.5,
    // width: responsiveStyles.screenWidth * 0.5,
},
StyleButtonContainer: {
  // alignItems: "baseline",
  // flex:1,
  // paddingBottom: 10,
},
styleButton:{
//  paddingHorizontal: 20
},
});

export default AdminOrdersListScreen;
