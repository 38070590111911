import React, { useRef, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, PanResponder, Animated, Image, Dimensions, FlatList, TextInput, useWindowDimensions, ActivityIndicator } from 'react-native';
import Modal from 'react-native-modal';
import { GlobalStyles } from '../constants/styles';
import { AntDesign } from '@expo/vector-icons';
import ImageProfile from './images/ImageProfile';
import i18n from '../hooks/useI18n';
import ButtonTypeButton from './inputs/ButtonTypeButton';
import { bodyHeight, fixedScreenHeight } from '../constants/ScreenDimensions';
import MediaEmptyListComponent from './displayComponent/MediaEmptyListComponent';
import CommentSectionListComponent from './displayComponent/CommentSectionListComponent';
import { Feather } from '@expo/vector-icons'; 
import { Menu, MenuItem } from 'react-native-material-menu';
import ChatTextInput from './chat/ChatTextInput';
import { formatDate } from '../functions/formatDate';
import useResponsiveStyles from '../functions/ResponsiveStyles';
import { GenericAlert } from './alert/GenericAlert';

const CommentSectionPopup = ({ 
  isVisible, 
  onClose,
  userSettingsState,
  data = [],
  UserName,
  sendComment = null,
  DeleteComment = null,
  IsDemo = false,
  isAdmin = false,
}) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const panY = useRef(new Animated.Value(0)).current;
  const [popUpHeight, setPopUpHeight] = useState(100);
  const [numberOfLines, setNumberOfLines] = useState(4);
const [showMoreRead, setShowMoreRead] = useState(false);
const isSentRequestToJoin = false;
const [isDeleteLoading, setIsDeleteLoading] = useState(false);
const [message, setMessage] = useState('');

const [isHideMenu, setIsHideMenu] = useState(false);
const [visible, setVisible] = useState(null);
const hideMenu = () => {
  if(setIsHideMenu !== null && setIsHideMenu !== undefined)
  {
    setIsHideMenu(false);
  }
  setVisible(null)
};

const showMenu = (Id) => {
  if(setIsHideMenu !== null && setIsHideMenu !== undefined)
  {
    setIsHideMenu(true);
  }
  setVisible(Id)
};
  // console.log(`popUpHeight ${popUpHeight} showMoreRead ${showMoreRead} numberOfLines ${numberOfLines}
  // numberOfLines === null || showMoreRead === false ${numberOfLines === null || showMoreRead === false}
  // `);
  useEffect(() => {
    if (isVisible) {
      Animated.spring(panY, {
        toValue: 0,
        useNativeDriver: false,
      }).start();
    }
    else{
      setShowMoreRead(false);
      setNumberOfLines(4);
    }
  }, [isVisible,showMoreRead]);

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy > 0) {
          panY.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        if (gestureState.dy > 0 && gestureState.dy < popUpHeight / 2) {
          Animated.spring(panY, {
            toValue: 0,
            useNativeDriver: false,
          }).start();
        } else if (gestureState.dy >= popUpHeight / 2) {
          // Add a delay before calling onClose to wait for the animation to finish
          setTimeout(() => {
            onClose();
          }, 750); // 750 milliseconds (0.75 seconds) delay
        }
      },
    })
  ).current;

  const animatedStyle = {
    transform: [{ translateY: panY }],
  };
  const [bodymessge, setBodyMessage] = useState('');
  const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
      style={styles.modalContainer}
      backdropOpacity={0.5}
    >
      {/* <Animated.View  */}
      <View 
      style={[styles.popupAnimatedContainer
        // , animatedStyle
      ]} 
      // {...panResponder.panHandlers} 
            // onLayout={(event) => {
            //     let {height} = event.nativeEvent.layout;
            //     setPopUpHeight(height);
            //   }}
      >
      <GenericAlert
     userSettingsState={userSettingsState}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
        <TouchableOpacity 
        style={styles.headerContainer} 
        activeOpacity={0.75}
        >
        <View style={styles.upperSlider}>
            <Text style={styles.upperSliderText}>
                uuuuuuuuuuuuu
                </Text>
        </View>
        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
        <AntDesign name="close" size={24} color= {GlobalStyles.colors.TitleBorderBottomColor} />
        </TouchableOpacity>
        
        <View style={styles.popupContainer}>
        <View style={styles.TitleContainer}>
        <Text style={styles.title}>{i18n.t('media.CommentSectionPopup_Title')}</Text>
        </View>
        </View>
        </TouchableOpacity>

        <FlatList
      // scrollEnabled={false}
      // nestedScrollEnabled
      style={styles.flatListContainer}
      data={data}
      renderItem={({ item, index }) =>
      {
        
        // console.log(item);
        const formattedDate = formatDate(item.CreatedDate);
     return <View style={[styles.commentContainer, index === data.length - 1 ? { paddingBottom: 15 } : null]}>
      <View style={styles.comment_userInfoContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={item.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
    />
            <Text style={styles.userNameText}>{item.UserName}</Text>
            <View style={styles.commentOption}>
            <Text style={[styles.userNameText,styles.commentCreatedDateText]}>{formattedDate == null || formattedDate === "NaN/NaN/NaN" ? item.CreatedDate : formattedDate}</Text>
          { item.UserId === userSettingsState?.userSettings?.UserId || isAdmin ?
           <Menu
          visible={visible === item.Id}
          anchor={
        <TouchableOpacity 
         onPress={() => showMenu(item.Id)}
        >
        <Feather name="more-vertical"  size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
        </TouchableOpacity>
          
        }
        style={{borderRadius:10}}
          onRequestClose={hideMenu}
        >
              <View style={styles.menuContainer}>
      <MenuItem textStyle={styles.menuItemText} onPress={async () => {
        setIsHideMenu(false);
        setIsDeleteLoading(true);
        if(DeleteComment){
          const response = await DeleteComment(item.Id);
          if(!response.isCompleted){
            setBodyMessage(response.message);
            setGenericAlertVisible(true);
          }
          // hideMenu();
        }
        setIsDeleteLoading(false);
      }}>
                {isDeleteLoading ? <View style={{ display:'flex'}}>
          
          <ActivityIndicator
          size={35}
          color={GlobalStyles[responsiveStyles.ThemeValue]?.ActivityIndicatorColor}
          />
          </View>
        : i18n.t(`media.CommentSectionPopup_DeleteButton`)
        }
      </MenuItem>
      
      </View>
      </Menu> : null}
              </View>
            </View>
    
            <View style={styles.comment_commentContainer}>
              <Text style={[styles.userNameText,styles.commentText]}>
              {item.Description}
              </Text>
            </View>
    </View>
      }
      }
      // numColumns={3}
      ListEmptyComponent={
        <CommentSectionListComponent userSettingsState={userSettingsState} />
          }
      />
        
        

      {IsDemo ? null :<ChatTextInput
      userSettingsState={userSettingsState}
      message={message}
      setMessage={setMessage}
      userName={UserName}
      // pickImage={pickImage}
      onSendButtonPress={async() => {
        if(message?.length === 0){
          return;
        }
        if(sendComment){
          const response = await sendComment(message);
          if(response.isCompleted){
            setMessage('');
          }
        }
        }}
      />}

      {/* <View style={styles.textInputContainer}>
        <TextInput
        placeholder={i18n.t(`chat.GroupChatScreen_TextInputPlaceholder`)}
        style={styles.TextInput}
          placeholderTextColor={GlobalStyles[responsiveStyles.ThemeValue]?.placeholderColor}
          color={GlobalStyles[responsiveStyles.ThemeValue]?.textColor}
          // value={message}
          // onChangeText={text => setMessage(text)}
          // editable={!isForDisplayMessage}
        />
        </View> */}
      </View>
    </Modal>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  modalContainer: {
    justifyContent: 'flex-end',
    margin: 0,

  },
  popupAnimatedContainer: {
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: '100%',
    // height: fixedScreenHeight,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  popupContainer: {
    // padding: 50,
    alignItems: 'center',
  },
  TitleContainer: {
    // backgroundColor: 'blue',
    paddingBottom: 20,
    borderBottomWidth: 1,
    width: '100%',
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    justifyContent:'center',
    alignItems:'center',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  upperSlider: {
    alignItems:'center',
     marginTop: 15,

  },
  upperSliderText: {
    borderWidth:1, 
    borderRadius:20, 
    fontSize:2,
    backgroundColor: GlobalStyles.colors.TitleBorderBottomColor, 
    color: GlobalStyles.colors.TitleBorderBottomColor, 
    borderColor: GlobalStyles.colors.TitleBorderBottomColor,
    fontFamily: GlobalStyles.fonts.application_font,
    width: responsiveStyles.screenWidth * 0.2,
},
  groupName: {
    fontSize: responsiveStyles.fontSize_H3,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  groupCreated: {
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  groupDescription: {
    marginVertical: 10,
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  joinButton: {
    backgroundColor: 'blue',
    padding: 10,
    borderRadius: 5,
    width:'100%',
    alignItems:'center',
    borderRadius: 50,
  },
  joinButtonText: {
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  closeButton: {
    marginTop: 10,
    alignSelf: responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
    paddingHorizontal: 15,
    borderRadius: 15,
  },
  requestJoinDescription: {
    paddingVertical: 10,
    alignSelf: 'center',
  },
  Description: {
    fontSize: responsiveStyles.fontSize_H5,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingHorizontal: 5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  TextTouchableOpacity:{
    color:'#0d8984'
  },
  groupPhoto: {
    width: 100,
    height: 100,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,

  },
  userPhoto: {
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  numberOfMembers: {
    width: 50,
    height: 50,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    textAlign:'center',
    paddingTop:15,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.borderColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  commentContainer: {
    width: '100%',
    // padding: 10,
    // alignItems:'center',
    // paddingHorizontal: 20,
  },
  comment_userInfoContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    padding: 10,
    alignItems:'center',
    // paddingHorizontal: 20,
  },
  comment_commentContainer: {
    paddingLeft: !responsiveStyles.isRTL ? 40 + 5 : null,
    paddingRight: responsiveStyles.isRTL ? 40 + 5 : null,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  userNameText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    marginHorizontal: 20,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  commentCreatedDateText: {
    fontWeight: '500',
  },
  commentText: {
    fontWeight: '500',
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  commentOption: {
    flex:1, 
    alignItems:'center',
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'flex-end',

  },
  menuContainer:{
    
    backgroundColor:GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor, 
    borderWidth:1,
     borderColor:GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor, 
     borderRadius: 10,

    
},
menuItemText:{
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
textInputContainer: {
  backgroundColor: 'blue',
  
},
flatListContainer: {
height: responsiveStyles.screenHeight * 0.7,
// marginBottom: 100,
// backgroundColor:'blue',
// paddingBottom: 20,
},
TextInput: { 
  // flex: 1, 
  // height: responsiveStyles.screenHeight * 0.055, 
  // paddingHorizontal: 10,
  // color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  // fontFamily: GlobalStyles.fonts.application_font,
 },
 headerContainer: {
  // backgroundColor:'blue',
},
});

export default CommentSectionPopup;
