import { defaultImage } from "../constants/images";
import { comments } from "./UserPofile_ImageMedia";

  export const tributes = [{
    Id: 1,
    UserImage: defaultImage.uri,
    UserName: "UserNamesa uhihui gyu gyu wrts ter evhu huiohui  hui gyogy",
    CreatedDate: "26/04/2024",
    Title: "Mom",
    Description: `Description`,
    Comments: comments,
    likes: 104,
    album: [
      {
        MediaType: 'image',
        URL: defaultImage.uri,
      }
    ],
  }];
