import React, { useContext } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import i18n from "../../hooks/useI18n";

import ImageProfile from "../images/ImageProfile";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { getPaddingScreen } from "../../functions/ScreenDimensionsLogic";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { navigateToScreen } from "../../navigationResponsive";

  const MyAccountUserInfoComponent = ({ 
    userSettingsState, 
    user,
    navigation,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
    const styles = styling(responsiveStyles, paddingScreen);
    console.log(`user image is`);
    console.log(user?.UserImage);
    const {signout } = useContext(AuthContext);
    const {setDefaultSettings } = useContext(UserSettingsContext);
    
  return (
    <View style={{
      backgroundColor: GlobalStyles.colors.Lavender,
       padding: 20, marginVertical: 20, 
       flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
        }}>
      <View style={styles.userPhotoContainer}>
        <ImageProfile
        userSettingsState={userSettingsState}
      uri={user?.UserImage}
      imageStyle={styles.userPhoto}
      editable={false}
      isGroup={false}
      userName={user?.UserName}
        />
      </View>
      <View style={styles.userInfoContainer}>
         <Text style={styles.text}>{i18n.t('account.MyAccountUserInfoComponent_Hello')},</Text>
         <Text style={styles.text}>{user?.FirstName} {user?.LastName}</Text>
      <TouchableOpacity
          onPress={async () => {
            await signout();
            await setDefaultSettings();
            navigateToScreen(navigation, 'LoginFlow', "Signin", null, null);
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, styles.signOutText]}>
              {i18n.t('menu.SignOut')}
            </Text>
          </TouchableOpacity>
      </View>
  </View>
  );
}
const styling = (responsiveStyles, paddingScreen) => StyleSheet.create({
  userPhotoContainer: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? '15%' : '40%',
    // alignItems: responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
    alignItems: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : !responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
    justifyContent: 'center',
  },
  userInfoContainer: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? '85%' : '60%',
    justifyContent: 'center',
  },
  userPhoto: {
    // width: 150,
    // height: 150,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 75 : 100,
    height: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 75 : 100,
    borderRadius: 100,
    borderWidth: 4,
    // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    marginBottom: 10,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: !responsiveStyles.isMobile ? responsiveStyles.fontSize_H4 : responsiveStyles.fontSize_H5,
    textAlign: responsiveStyles.isRTL ? 'right' : 'left',
    fontFamily: GlobalStyles.fonts.application_font,
  },
  itemContainer: {
    paddingTop: 20,
  },
  signOutText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorOpposite,
    fontWeight: 'bold',
  },
});

export default MyAccountUserInfoComponent;