import { Alert, View } from "react-native";
import { useEffect, useState } from "react";
import i18n from "../../hooks/useI18n";
import CustomAlert from "../modals/CustomAlert";


  export const GenericAlert = ({ 
    userSettingsState, 
    navigation,
    headermessage,
    bodymessge,
    buttonmessage1 = null,
    buttonmessage1onPress,
    buttonmessage2 = null,
    buttonmessage2onPress = undefined,
    visible,
    setVisible,
    children,
    onClosePress = null,

}) => {

  const hideVisibleAlert = () => {
      setVisible(false);
      if(onClosePress){
        onClosePress();
      }
    };
    return (
      <View>
      <CustomAlert
        visible={visible}
        onClosePress={hideVisibleAlert}
        userSettingsState={userSettingsState}
        headermessage={headermessage}
        bodymessge={bodymessge}
        buttonmessage1={buttonmessage1 === null ? i18n.t('error.ok') : buttonmessage1}
        buttonmessage1onPress={buttonmessage1onPress}
        buttonmessage2={buttonmessage2}
        buttonmessage2onPress={buttonmessage2onPress}
        children={children}
      />
    </View>
    );
  };
