import React from "react";
import { Alert, Modal, StyleSheet, Text, Pressable, View } from "react-native";
import { MaterialIcons } from '@expo/vector-icons'; 
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const PopUp = ({
  text,
  modalVisible,
  setModalVisible,
  showCancelButton,
  cancelModalTimer = false,
  userSettingsState,
}) => {


  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

    const cancelModal = () => {
        setTimeout(() => {
          console.log("cancelModal");
            setModalVisible(false);
           }, 3000); // 3 seconds
    }
  return (
<View style={styles.bottomView}>
      {modalVisible === true ? 
        (
          <View style={[styles.modalView, showCancelButton === true ? {justifyContent:'space-between'} : {justifyContent:'flex-end'}]}>
            { setModalVisible === undefined || cancelModalTimer === false ? null :  cancelModal()}
            {showCancelButton === true ? <MaterialIcons 
          name="cancel" 
          size={22} 
          color="white"
          onPress={() => {
            setModalVisible(!modalVisible);
          }}
           />
          : null}
          
            <Text style={styles.text}>{text}</Text>
          </View>
        )
        : null}
        </View>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  bottomView: {
        position: 'absolute', //Here is the trick
        bottom: 0, //Here is the trick
        width: '100%',
        backgroundColor:'rgb(10, 10, 10)',
        marginBottom:20,
        alignSelf:'center',

      },
    modalView: {
      flex:1,
      
      flexDirection: 'row-reverse',
      margin: 20,
    },
    text:{
      color:'white',
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
    },

  });
export default PopUp;
