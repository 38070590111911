import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import ImageProfile from "../images/ImageProfile";
import LanguageComponent from "../myAccountScreen/LanguageComponent";
import DarkModeComponent from "../myAccountScreen/DarkModeComponent";
import { navigateToScreen } from "../../navigationResponsive";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { formatDate } from "../../functions/formatDate";
  const AccountForm = ({ 
    userSettingsState,
    inputs,
    setInputs,
    submitHandler,
    setUserSettingsLanguage,
    setUserSettingsDarkMode,
    navigation,
    user = null,
    ResetPassword,
}) => {
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

    const [isLoading, setIsLoading] = useState(false);
    function inputChangedHandler(inputIdentifier, enteredValue,isChanged) {
        setInputs((curInputs) => {
          const newInputs = {
            ...curInputs,
            [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
          };
          return newInputs;
        });
        return;
      }

      const formattedJoinedDate = formatDate(user?.JoinedDate);

  return (
    <View style={styles.container}>
    <View style={styles.titleContainer}>
        <Text style={styles.title}>{i18n.t('account.MyAccount_AccountForm_Title')}</Text>
    </View>
    <View style={styles.settingsRow}>
    <LanguageComponent
    userSettingsState={userSettingsState} 
    setUserSettingsLanguage={(newLocale) => {
        i18n.locale = newLocale.slice(0, 2);
        setUserSettingsLanguage(newLocale);
      }}
      containerStyle={{ flex: 1 }}
    />
    <DarkModeComponent
    userSettingsState={userSettingsState} 
    setUserSettingsDarkMode={setUserSettingsDarkMode}
    containerStyle={{ flex: 1 }}
    />
    </View>

    <View style={styles.settingsRow}>

    <View style={styles.nameContainer}>
            <Text style={styles.subtitle}>{i18n.t('account.MyAccount_Email')}</Text>
            <Text style={[styles.nameValue]}>{user?.Email}</Text>
    </View>

    <View style={styles.nameContainer}>
            <Text style={styles.subtitle}>{i18n.t('account.MyAccount_Joined')}</Text>
            <Text style={[styles.nameValue]}>{ formattedJoinedDate === null || formattedJoinedDate === "NaN/NaN/NaN" ? user?.JoinedDate : formattedJoinedDate}</Text>
    </View>

    </View>

<View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
         onPress={async () => {

            // navigateToScreen(navigation, 'DiscoverFlow', `ImagePicker`, null, null);
            // return;
            navigateToScreen(navigation, 'AccountFlow', `ForgotPasswordCodeConfirmation`, { email: user?.Email, FromScreen: "MyAccount" }, null);
            // navigation.navigate('AccountFlow', { screen: 'ForgotPasswordCodeConfirmationFromAccountScreen', params:  { email: user?.Email, FromScreen: "MyAccount" } });
            //  navigation.navigate('ForgotPasswordCodeConfirmationFromAccountScreen', { email: user?.Email, FromScreen: "MyAccount" });
             await ResetPassword({ email: user?.Email });
        }}
        >
          {i18n.t('account.MyAccount_AccountForm_ChangePassword')}
    </ButtonTypeButton>
    </View>


    <TouchableOpacity style={styles.userPhotoContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={inputs.Image.value}
  imageStyle={styles.userPhoto}
  editable={true}
  showEditButton={true}
  isGroup={false}
//   userName={user?.UserName}
onImagePick={(data) => {
    inputChangedHandler("Image", data, true);
  }}
  isWithCrop={true}
  isCircle={true}
    />
    </TouchableOpacity>
    <View style={styles.subtitleContainer}>
        <Text style={styles.subtitle}>{i18n.t('account.MyAccount_AccountForm_PersonalDetails')}</Text>
    </View>
    <View style={styles.TextInputContainer}>
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.FirstName.value}
    setValue={(value) => {
        inputChangedHandler("FirstName", value, true);
    }}
    label={i18n.t('account.MyAccount_AccountForm_FirstName')}
    errorMessage={i18n.t('account.MyAccount_AccountForm_FirstName_ErrorMessage')}
    isRequired={true}
    isValid={inputs.FirstName.isValid}
    styleTextInputContainer={styles.textInputStyle}
    />

<TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.LastName.value}
    setValue={(value) => {
        inputChangedHandler("LastName", value, true);
    }}
    label={i18n.t('account.MyAccount_AccountForm_LastName')}
    errorMessage={i18n.t('account.MyAccount_AccountForm_LastName_ErrorMessage')}
    isRequired={true}
    isValid={inputs.LastName.isValid}
    styleTextInputContainer={styles.textInputStyle}
    />
    </View>

<View style={[styles.subtitleContainer, { paddingTop: !responsiveStyles.isMobile ? 20 : 20 }]}>
        <Text style={styles.subtitle}>{i18n.t('account.MyAccount_AccountForm_LocationDetails')}</Text>
    </View>

    <View style={styles.TextInputContainer}>
<TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.State.value}
    setValue={(value) => {
        inputChangedHandler("State", value, true);
    }}
    label={i18n.t('account.MyAccount_AccountForm_State')}
    errorMessage={i18n.t('account.MyAccount_AccountForm_State_ErrorMessage')}
    styleTextInputContainer={styles.textInputStyle}
    />
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.City.value}
    setValue={(value) => {
        inputChangedHandler("City", value, true);
    }}
    label={i18n.t('account.MyAccount_AccountForm_City')}
    errorMessage={i18n.t('account.MyAccount_AccountForm_City_ErrorMessage')}
    styleTextInputContainer={styles.textInputStyle}
    />
    </View>

<View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        loading={isLoading}
         onPress={async () => {
            setIsLoading(true);
            await submitHandler();
            setIsLoading(false);
        }}
        >
        {i18n.t('account.MyAccount_AccountForm_Save')}
    </ButtonTypeButton>
    </View>

    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    container: {
        flex:1,
        // backgroundColor:'blue',
        margin: 20,
        padding:10,
        width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 : responsiveStyles.screenWidth * 0.9,
        alignSelf:'center',
        borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth: 1,
    borderRadius: 10,
    },
text: {
  fontFamily: GlobalStyles.fonts.application_font,
},
TextInputContainer: {
    flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    justifyContent: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  'space-between' : null,
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.07 : null,
},
textInputStyle: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.2 : null,
},
titleContainer: {
    // paddingBottom: 20,
    // borderBottomWidth: 2,
    marginBottom: 20,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.4 : responsiveStyles.screenWidth * 0.6,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    paddingVertical: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 10,
    alignSelf:'center',
},
title: {
    fontSize: responsiveStyles.fontSize_H3,
    fontWeight: '700',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign:'center'
},
subtitleContainer: {
    paddingBottom: 20,
    // borderBottomWidth: 1,
    // marginBottom: 20,
    borderColor: GlobalStyles.colors.TitleBorderBottomColor, 
    marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.08 : responsiveStyles.screenWidth * 0.15,
    // marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.15,
    
},
subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontWeight: '500',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: responsiveStyles.isRTL ? 'right' : 'left',
},
buttonContainer: {
    marginVertical: 20,
    alignItems:'center',
},
button: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.15 : responsiveStyles.screenWidth * 0.8,
},
userPhotoContainer: {
    justifyContent:'center',
    alignItems:'center',
    marginBottom: 30,
  },
userPhoto: {
    width: 150,
    height: 150,
    borderRadius: 100,
    borderWidth: 4,
    // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    marginBottom: 10,
  },
  settingsRow: {
    flex:1,
    flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    justifyContent:'space-between',
  },
  nameContainer: {
    flex:1,
    flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    justifyContent:'space-between',
    alignItems: 'center',
    paddingHorizontal: !responsiveStyles.isMobile ? 40 : null,
    paddingTop: 20,
    paddingBottom: 10,
  },
  nameValue: {
    // color: GlobalStyles[responsiveStyles.ThemeValue]?.textValueColor,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    // paddingLeft: !responsiveStyles.isRTL ? 10 : null,
    // paddingRight: responsiveStyles.isRTL ? 10 : null,
  },
});

export default AccountForm;