import { Asset } from 'expo-asset';
import { getAsset } from '../../../functions/getAsset';

// export const Pet_User = {
//     Id: "Pet",
//     IsPet: true,
//     PetName: "Sunny",
//     BirthDate: "2017-03-28",
//     DeathDate: null,
//     Relationship: 1,
//     State: "Israel",
//     City: "Tel Aviv",
//     Description: "For the most important woman in my world ♡",
//     UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
//     BackgroundImage: getAsset(require('../../../../assets/images/store/profiles/pet/background_image.png')),
//     // UserImage: "",
//     // BackgroundImage: "",
//   };
  export const Pet_User = {
    Id :"Pet",
    IsPet:true,
    UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
    BackgroundImage: getAsset(require('../../../../assets/images/store/profiles/pet/background_image.png')),
    BirthDate:"2017-02-14",
    DeathDate:null,
    PetName:"Sunny",
    PetType:"Dog",
    Breed:"Golden retriever",
    Gender:"Boy",
    Weight:25,
    Bio:"Our sweet boy, sunny",
    ContactName1:"Dad",
    ContactPhone1:"0555555555",
    ContactName2:"Mom",
    ContactPhone2:"0555555555",
    Address:"",
    AdditionalInfo:"We love you sunny!",
    Behavior_Dogs:"Yes",
    Behavior_Cats:"Yes",
    Behavior_Children:"Yes",
    Behavior_Strangers:"Yes",
    Behavior_OtherAnimals:"Yes",
    Behavior_EnergyLevel:"High",
    Behavior_TrainedLevel:"Trained",
    Behavior_Nature:"Social",
    Behavior_AdditionalInfo:"He is a very sweet boy and he is very social",
    Health_Allergies:"Peanuts",
    Health_Medication:"No medication",
    Health_Vaccine:"Rabies, DHPP, Bordetella, Leptospirosis, Canine Influenza, Lyme Disease",
    Health_MedicalIndications:"No Medical Indications",
    Health_Spayed:"Yes",
    Health_AdditionalInfo:"Nothing out of the ordinary",
    Vet_Name:"Samuel",
    Vet_PhoneNumber:"0500000000",
    Vet_MicrochipNumber:"11111111",
    Vet_RabiesNumber:"2222222",
    Vet_AdditionalInfo:"No other information",
  }

  
export const Pet_PlainUser = {
    UserName: "Pet",
    Like: 0,
    IsUserLiked: false,
    UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
    // UserImage: "",
  };