import React from 'react';
import { StyleSheet,View,Text, TextInput } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { GlobalStyles, Lavender } from '../constants/styles';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const AuthFormHeader = ({ 
  userSettingsState
 }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  return (
    <View style={styles.container}>
    <FontAwesome name="user-circle-o" size={70} color={Lavender} style={{ textAlign:'center',}} />
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  header: {
    fontSize: responsiveStyles.fontSize_H3,
    // marginBottom: 48,
    // marginTop: 30,
    textAlign:'center',
    fontWeight:'bold',
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default AuthFormHeader;
