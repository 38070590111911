import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { isNumeric } from "../../functions/utils";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputCustom from "../inputs/TextInputCustom";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
  const ManagePetProfileForm_NatureComponent = ({ 
    userSettingsState,
    inputs,
    inputChangedHandler,
    editable,
    textInputNameStyle,
    containerStyle,
   }) => {

    const [isOtherVisible, setIsOtherVisible] = useState(false);
    const [lastValue, setLastValue] = useState("");
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
<View style={[styles.mainContainer, containerStyle]}>
  <View>
    <Text style={styles.subtitle}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature')}</Text>
  </View>
  <View style={styles.container2}>
  <View style={styles.secondaryContainer}>
  <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Shy" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Shy", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Shy')}
        </ButtonTypeButton>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Social" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Social", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Social')}
        </ButtonTypeButton>
        </View>
        <View style={styles.secondaryContainer}>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Aggressive" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Aggressive", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Aggressive')}
        </ButtonTypeButton>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Curious" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Curious", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Curious')}
        </ButtonTypeButton>
        </View>
        </View>
        <View style={styles.container2}>
        <View style={styles.secondaryContainer}>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Protective" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Protective", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Protective')}
        </ButtonTypeButton>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Energetic" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Energetic", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Energetic')}
        </ButtonTypeButton>
        </View>
        <View style={styles.secondaryContainer}>
        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Dominant" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Dominant", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Dominant')}
        </ButtonTypeButton>

        <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Sensitive" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Sensitive", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Sensitive')}
        </ButtonTypeButton>
    </View>
        </View>
        <View style={styles.container1}>

    <View style={styles.secondaryContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    styleButton={[styles.styleButton, inputs.Behavior_Nature.value === "Familiar" ? styles.chosenButton : null]}
    style={styles.buttonStyle}
    onPress={() => {
      setIsOtherVisible(false);
      inputChangedHandler("Behavior_Nature", "Familiar", true);
    }}
        >
          {i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature_Familiar')}
        </ButtonTypeButton>
    
    </View>
    </View>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  mainContainer: {
    // backgroundColor: 'blue',
  },
  container1: {
    // flexDirection: responsiveStyles.isMobile ? 'column' : responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexDirection: responsiveStyles.isMobile || responsiveStyles.isTablet || responsiveStyles.isLaptop
    ? 'column' : responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  container2: {
    // flexDirection: responsiveStyles.isMobile ? 'column' : responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexDirection: responsiveStyles.isMobile || responsiveStyles.isTablet
    ? 'column' : responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  secondaryContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',
    paddingBottom: 15,
  },
  subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontWeight: '500',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
    paddingBottom: 10,
},
styleButton: {
  paddingHorizontal: 25,
},
chosenButton: {
  backgroundColor: Gold,
},
buttonStyle: {
  paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
},
});

export default ManagePetProfileForm_NatureComponent;