import React, { useCallback, useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Platform, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Alert } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import ManageTributesComponent from '../../components/displayComponent/ManageTributesComponent';
import ManageMediaComponent from '../../components/displayComponent/ManageMediaComponent';
import ManageHumanProfileForm from '../../components/forms/ManageHumanProfileForm';
import AdminsComponent from '../../components/displayComponent/AdminsComponent';
import DisplayTypeComponent from '../../components/editProfileScreen/DisplayTypeComponent';
import { DeleteUserProfile, GetUserProfileFullInfoById, UpdateUserProfileInfo } from '../../api/apiCalls/ProfileCalls';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { ApproveUserAsAdmin, DisapproveUserAsAdmin, InviteUserAsAdmin, RemoveUserAsAdmin } from '../../api/apiCalls/ProfileAdminCalls';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useFocusEffect, useNavigation, useRoute  } from '@react-navigation/native';
import { useNavigate, useLocation,  } from 'react-router-dom';
import { isNumeric, isValidGuid } from '../../functions/utils';
import NotFoundComponent from '../../components/NotFoundComponent';
import { EditProfileScreen_displayType } from '../../modules/StorageObjects';
import { getItem, saveAsync } from '../../modules/Storage';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import ManagePetProfileForm from '../../components/forms/ManagePetProfileForm';
const EditProfileScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const UserIdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);

  const UserId = navigationParams?.UserId || UserIdFromURL;
  const NavigateFromScreen = navigationParams?.NavigateFromScreen;
console.log(`NavigateFromScreen is ${NavigateFromScreen}`);
  const IsIdValid = isValidGuid(UserId);

  const isEditing = !!UserId;

    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const [user, setUser] = useState();
    const IsPet = user?.IsPet ? user.IsPet : false;
    const [tributes, setTributes] = useState([]);
    const [media, setMedia] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [isAdmin, setIsAdmin] = useState(true);
    const [displayType, setDisplayType] = useState('Bio');

    const setDisplayTypeWithLocalStorage = async (newDisplayType) => {
      await saveAsync(EditProfileScreen_displayType, newDisplayType);
      setDisplayType(newDisplayType);
    }


    const [emailinputs, setEmailInputs] = useState({
      Email: {
        value: "",
        isValid: true,
        isChanged: false,
      },
    });
    function emailInputChangedHandler(inputIdentifier, enteredValue,isChanged) {
      setEmailInputs((curInputs) => {
        const newInputs = {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        };
        return newInputs;
      });
      return;
    }

    const scrollViewRef = useRef(null);

    const [inputs, setInputs] = useState();
    const [petInputs_Info, setPetInputs_Info] = useState();
    const [petInputs_AdditionalInfo, setPetInputs_AdditionalInfo] = useState();
    const setInputsByUser = (FuncUser) => {
      console.log(`setInputsByUser is ${JSON.stringify(FuncUser)}`);
      setInputs({
        FirstName: {
          value: FuncUser?.FirstName ? FuncUser.FirstName :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        MiddleName: {
          value: FuncUser?.MiddleName ? FuncUser.MiddleName :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        LastName: {
          value: FuncUser?.LastName ? FuncUser.LastName :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        BirthDate: {
          value: FuncUser?.BirthDate ? FuncUser.BirthDate : null,
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        DeathDate: {
          value: FuncUser?.DeathDate ? FuncUser.DeathDate : null,
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Relationship: {
          value: FuncUser?.Relationship ? FuncUser.Relationship : -1,
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        State: {
          value: FuncUser?.State ? FuncUser.State : "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        City: {
          value: FuncUser?.City ? FuncUser.City : "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Description: {
          value: FuncUser?.Description ? FuncUser.Description : "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Image:{
            value: FuncUser?.UserImage ? FuncUser.UserImage : "",
            isValid: true,
            isChanged: false,
            layoutY: 0,
        },
        BackgroundImage: {
          value: FuncUser?.BackgroundImage ? FuncUser.BackgroundImage : "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
      },
      });
    }
    const setPetInputs_InfoByUser = (FuncUser) => {
      setPetInputs_Info({
        PetName: {
          value: FuncUser?.PetName ? FuncUser.PetName :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        PetType: {
          value: FuncUser?.PetType ? FuncUser.PetType :"", // Dog, Cat, Other
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Breed: {
          value: FuncUser?.Breed ? FuncUser.Breed :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Gender: {
          value: FuncUser?.Gender ? FuncUser.Gender :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        BirthDate: {
          value: FuncUser?.BirthDate ? FuncUser.BirthDate : null,
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        DeathDate: {
          value: FuncUser?.DeathDate ? FuncUser.DeathDate : null,
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Weight: {
          value: FuncUser?.Weight ? FuncUser.Weight :"", // should i go with KG of LB
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Bio: {
          value: FuncUser?.Bio ? FuncUser.Bio :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        ContactName1: {
          value: FuncUser?.ContactName1 ? FuncUser.ContactName1 :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        ContactPhone1: {
          value: FuncUser?.ContactPhone1 ? FuncUser.ContactPhone1 :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        ContactName2: {
          value: FuncUser?.ContactName2 ? FuncUser.ContactName2 :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        ContactPhone2: {
          value: FuncUser?.ContactPhone2 ? FuncUser.ContactPhone2 :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Address: {
          value: FuncUser?.Address ? FuncUser.Address :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        AdditionalInfo: {
          value: FuncUser?.AdditionalInfo ? FuncUser.AdditionalInfo :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Image:{
            value: FuncUser?.UserImage ? FuncUser.UserImage : "",
            isValid: true,
            isChanged: false,
            layoutY: 0,
        },
        BackgroundImage: {
          value: FuncUser?.BackgroundImage ? FuncUser.BackgroundImage : "",
          isValid: true,
          isChanged: false,
          layoutY: 0,
      },
      });
    }
    const setPetInputs_AdditionalInfoByUser = (FuncUser) => {
      setPetInputs_AdditionalInfo({
        Behavior_Dogs: {
          value: FuncUser?.Behavior_Dogs ? FuncUser.Behavior_Dogs :"", // true or false or other
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_Cats: {
          value: FuncUser?.Behavior_Cats ? FuncUser.Behavior_Cats :"", // Dog, Cat, Other
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_Children: {
          value: FuncUser?.Behavior_Children ? FuncUser.Behavior_Children :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_Strangers: {
          value: FuncUser?.Behavior_Strangers ? FuncUser.Behavior_Strangers :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_OtherAnimals: {
          value: FuncUser?.Behavior_OtherAnimals ? FuncUser.Behavior_OtherAnimals :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_EnergyLevel: {
          value: FuncUser?.Behavior_EnergyLevel ? FuncUser.Behavior_EnergyLevel :"", // mid, hight/ relaxed, needs a lot, other
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_TrainedLevel: {
          value: FuncUser?.Behavior_TrainedLevel ? FuncUser.Behavior_TrainedLevel :"", // basic, trained, untrained, in the middle of training
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_Nature: {
          value: FuncUser?.Behavior_Nature ? FuncUser.Behavior_Nature :"", // Shy, friendly, aggresive, knows close places, protected, dominant, other
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Behavior_AdditionalInfo: {
          value: FuncUser?.Behavior_AdditionalInfo ? FuncUser.Behavior_AdditionalInfo :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Health_Allergies: {
          value: FuncUser?.Health_Allergies ? FuncUser.Health_Allergies :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Health_Medication: {
          value: FuncUser?.Health_Medication ? FuncUser.Health_Medication :"", // should i go with KG of LB
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Health_Vaccine: {
          value: FuncUser?.Health_Vaccine ? FuncUser.Health_Vaccine :"", // should i go with KG of LB
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Health_MedicalIndications: {
          value: FuncUser?.Health_MedicalIndications ? FuncUser.Health_MedicalIndications :"", // should i go with KG of LB
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Health_Spayed: {
          value: FuncUser?.Health_Spayed ? FuncUser.Health_Spayed :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Health_AdditionalInfo: {
          value: FuncUser?.Health_AdditionalInfo ? FuncUser.Health_AdditionalInfo :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Vet_Name: {
          value: FuncUser?.Vet_Name ? FuncUser.Vet_Name :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Vet_PhoneNumber: {
          value: FuncUser?.Vet_PhoneNumber ? FuncUser.Vet_PhoneNumber :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        // Vet_LicenseID: {
        //   value: FuncUser?.Vet_LicenseID ? FuncUser.Vet_LicenseID :"",
        //   isValid: true,
        //   isChanged: false,
        //   layoutY: 0,
        // },
        Vet_MicrochipNumber: {
          value: FuncUser?.Vet_MicrochipNumber ? FuncUser.Vet_MicrochipNumber :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Vet_RabiesNumber: {
          value: FuncUser?.Vet_RabiesNumber ? FuncUser.Vet_RabiesNumber :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
        Vet_AdditionalInfo: {
          value: FuncUser?.Vet_AdditionalInfo ? FuncUser.Vet_AdditionalInfo :"",
          isValid: true,
          isChanged: false,
          layoutY: 0,
        },
      });
    }

    const onLayoutChange = (inputName, y) => {

      if(IsPet){
        setPetInputs_Info(curInputs => ({
          ...curInputs,
          [inputName]: { ...curInputs[inputName], layoutY: y },
        }));
        setPetInputs_AdditionalInfo(curInputs => ({
          ...curInputs,
          [inputName]: { ...curInputs[inputName], layoutY: y },
        }));
      }
      else{
        setInputs(curInputs => ({
          ...curInputs,
          [inputName]: { ...curInputs[inputName], layoutY: y },
        }));
      }
    };

    const DeactivateUserHandler = async (userId) => {
      const response = await RemoveUserAsAdmin({ UserProfileId: UserId, UserId: userId});
      if(response.isCompleted === true)
      {
        setAdmins((prevAdmins) =>
          prevAdmins.filter((admin) => admin.UserId !== userId)
        );
        if(!isAdmin && userId === userSettingsState?.userSettings?.UserId){
              backAction();
        }
        // setbodymessge(i18n.t('responseMessages.success'));
        // setGenericAlertVisible(true);
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    const ApproveUserAsAdminHandler = async (userId) => {
      const response = await ApproveUserAsAdmin({ UserProfileId: UserId, UserId: userId});
      if(response.isCompleted === true)
      {
        // setbodymessge(i18n.t('responseMessages.success'));
        // setGenericAlertVisible(true);
        setAdmins((prevAdmins) =>
          prevAdmins.map((admin) => {
            if (admin.UserId === userId) {
              return {
                ...admin,
                Role: 2,
                IsInvitations: false,
              };
            }
            return admin;
          })
        );
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    const DisapproveUserAsAdminHandler = async (userId) => {
      const response = await DisapproveUserAsAdmin({ UserProfileId: UserId, UserId: userId});
      if(response.isCompleted === true)
      {
        // setbodymessge(i18n.t('responseMessages.success'));
        // setGenericAlertVisible(true);
        setAdmins((prevAdmins) =>
          prevAdmins.filter((admin) => admin.UserId !== userId)
        );
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    const InviteUserAsAdminHandler = async () => {
      const UserNameIsValid = emailinputs.Email.value.toString().trim().length > 0;
      if (!UserNameIsValid) {
        setEmailInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.Email.isValid = UserNameIsValid;
          return updatedInputs;
        });
        return;
      }

      let userData = {
        UserName: emailinputs.Email.value,
        UserProfileId: UserId
    }
    

    const response = await InviteUserAsAdmin(userData);

    if(response.isCompleted === true)
        {
          // setbodymessge(i18n.t('responseMessages.InvintationSuccess'));
          // setGenericAlertVisible(true);
          setEmailInputs((curInputs) => {
            const updatedInputs = { ...curInputs };
            updatedInputs.Email.value = "";
            return updatedInputs;
          });
          setReloadPage(!reloadpage);
        }
        else{
          // setbodymessge(i18n.t('responseMessages.InvintationFailed'));
          // setGenericAlertVisible(true);
        }
        return response;
    };
    const DeleteUserProfileHandler = async () => {
    const response = await DeleteUserProfile(UserId);
    return response;
    }
    const SavePetPersonalDetails = async () => {
      const PetNameIsValid = petInputs_Info.PetName.value?.toString().trim().length > 0;
      const BirthDateIsValid = petInputs_Info.BirthDate.value?.toString()?.trim()?.length > 0;
      const GenderIsValid = petInputs_Info.Gender.value?.toString().trim().length > 0;
      const PetTypeIsValid = petInputs_Info.PetType.value?.toString().trim().length > 0;
      const WeightIsValid = isNumeric(petInputs_Info.Weight.value) && petInputs_Info.Weight.value?.toString().trim().length > 0;
      const ContactPhone1IsValid = isNumeric(petInputs_Info.ContactPhone1.value) && petInputs_Info.ContactPhone1.value?.toString().trim().length > 0;
      const ContactName1IsValid = petInputs_Info.ContactName1.value?.toString().trim().length > 0;
      if (!PetNameIsValid || !BirthDateIsValid || !GenderIsValid || !PetTypeIsValid || !WeightIsValid
        || !ContactName1IsValid || !ContactPhone1IsValid
      ) {
        setPetInputs_Info((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.PetName.isValid = PetNameIsValid;
          updatedInputs.BirthDate.isValid = BirthDateIsValid;
          updatedInputs.Gender.isValid = GenderIsValid;
          updatedInputs.PetType.isValid = PetTypeIsValid;
          updatedInputs.Weight.isValid = WeightIsValid;
          updatedInputs.ContactName1.isValid = ContactName1IsValid;
          updatedInputs.ContactPhone1.isValid = ContactPhone1IsValid;
          return updatedInputs;
        });

        if (!PetNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.PetName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.PetName.layoutY, x: petInputs_Info.PetName.layoutY, animated: true });
          }
        } 
        else if (!BirthDateIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.BirthDate.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.BirthDate.layoutY, x: petInputs_Info.BirthDate.layoutY, animated: true });
          }
        } 
        else if (!GenderIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.Gender.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.Gender.layoutY, x: petInputs_Info.Gender.layoutY, animated: true });
          }
        } 
        else if (!PetTypeIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.PetType.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.PetType.layoutY, x: petInputs_Info.PetType.layoutY, animated: true });
          }
        } 
        else if (!WeightIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.Weight.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.Weight.layoutY, x: petInputs_Info.Weight.layoutY, animated: true });
          }
        } 
        else if (!ContactName1IsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.ContactName1.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.ContactName1.layoutY, x: petInputs_Info.ContactName1.layoutY, animated: true });
          }
        } 
        else if (!ContactPhone1IsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, petInputs_Info.ContactPhone1.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: petInputs_Info.ContactPhone1.layoutY, x: petInputs_Info.ContactPhone1.layoutY, animated: true });
          }
        } 
        
        return;
      }

      console.log(`SavePetPersonalDetails passed`);
      const formData = new FormData();
      let userData = {
        Id: UserId,
        IsPet: true,
        PetName: petInputs_Info.PetName.value,
        PetType: petInputs_Info.PetType.value,
        Breed: petInputs_Info.Breed.value,
        Gender: petInputs_Info.Gender.value,
        BirthDate: petInputs_Info.BirthDate.value,
        DeathDate: petInputs_Info.DeathDate.value,
        Weight: petInputs_Info.Weight.value,
        Bio: petInputs_Info.Bio.value,
        ContactName1: petInputs_Info.ContactName1.value,
        ContactPhone1: petInputs_Info.ContactPhone1.value,
        ContactName2: petInputs_Info.ContactName2.value,
        ContactPhone2: petInputs_Info.ContactPhone2.value,
        Address: petInputs_Info.Address.value,
        AdditionalInfo: petInputs_Info.AdditionalInfo.value,
        Behavior_Dogs: petInputs_AdditionalInfo.Behavior_Dogs.value,
        Behavior_Cats: petInputs_AdditionalInfo.Behavior_Cats.value,
        Behavior_Children: petInputs_AdditionalInfo.Behavior_Children.value,
        Behavior_Strangers: petInputs_AdditionalInfo.Behavior_Strangers.value,
        Behavior_OtherAnimals: petInputs_AdditionalInfo.Behavior_OtherAnimals.value,
        Behavior_EnergyLevel: petInputs_AdditionalInfo.Behavior_EnergyLevel.value,
        Behavior_TrainedLevel: petInputs_AdditionalInfo.Behavior_TrainedLevel.value,
        Behavior_Nature: petInputs_AdditionalInfo.Behavior_Nature.value,
        Behavior_AdditionalInfo: petInputs_AdditionalInfo.Behavior_AdditionalInfo.value,
        Health_Allergies: petInputs_AdditionalInfo.Health_Allergies.value,
        Health_Medication: petInputs_AdditionalInfo.Health_Medication.value,
        Health_Vaccine: petInputs_AdditionalInfo.Health_Vaccine.value,
        Health_MedicalIndications: petInputs_AdditionalInfo.Health_MedicalIndications.value,
        Health_Spayed: petInputs_AdditionalInfo.Health_Spayed.value,
        Health_AdditionalInfo: petInputs_AdditionalInfo.Health_AdditionalInfo.value,
        Vet_Name: petInputs_AdditionalInfo.Vet_Name.value,
        Vet_PhoneNumber: petInputs_AdditionalInfo.Vet_PhoneNumber.value,
        Vet_MicrochipNumber: petInputs_AdditionalInfo.Vet_MicrochipNumber.value,
        Vet_RabiesNumber: petInputs_AdditionalInfo.Vet_RabiesNumber.value,
        Vet_AdditionalInfo: petInputs_AdditionalInfo.Vet_AdditionalInfo.value,
        IsActive: true,
    }

    if(petInputs_Info.Image.isChanged)
    {
      const ImageFileName = 'image.jpg';
      if(responsiveStyles.isWeb){
        console.log(`
          if(petInputs_Info.Image.isChanged) 
          if(responsiveStyles.isWeb){
          `);
        const blob = await fetch(petInputs_Info.Image.value).then((res) => res.blob());
        formData.append('userImage', blob, ImageFileName);
      }
      else{
        formData.append('userImage', {
          uri: petInputs_Info.Image.value,
          name: ImageFileName,
          type: 'image/jpeg', // Modify the content type based on your image type
        });
      }
    }
  if(petInputs_Info.BackgroundImage.isChanged)
    {
      const BackgroundImageFileName = 'image.jpg';
      if(responsiveStyles.isWeb){
        const blob = await fetch(petInputs_Info.BackgroundImage.value).then((res) => res.blob());
        formData.append('backgroundImage', blob, BackgroundImageFileName);
      }
      else{
        formData.append('backgroundImage', {
          uri: petInputs_Info.BackgroundImage.value,
          name: BackgroundImageFileName,
          type: 'image/jpeg', // Modify the content type based on your image type
        });
      }
    }
    formData.append('json', JSON.stringify(userData));
    console.log(`petInputs_Info.Image.value is ${petInputs_Info.Image.value}`);
    const response = await UpdateUserProfileInfo(formData);
        if(response.isCompleted === true)
        {
          setbodymessge(i18n.t('responseMessages.success'));
          setGenericAlertVisible(true);
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    const SaveHumanPersonalDetails = async () => {
      const FirstNameIsValid = inputs.FirstName.value.toString().trim().length > 0;
      const LastNameIsValid = inputs.LastName.value.toString().trim().length > 0;
      const RelationshipIsValid = inputs.Relationship.value > 0;
      const BirthDateIsValid = inputs.BirthDate.value?.toString()?.trim()?.length > 0;
      if (!FirstNameIsValid || !LastNameIsValid || !RelationshipIsValid || !BirthDateIsValid) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.FirstName.isValid = FirstNameIsValid;
          updatedInputs.LastName.isValid = LastNameIsValid;
          updatedInputs.Relationship.isValid = RelationshipIsValid;
          updatedInputs.BirthDate.isValid = BirthDateIsValid;
          return updatedInputs;
        });

        if (!FirstNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.FirstName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.FirstName.layoutY, x: inputs.FirstName.layoutY, animated: true });
          }
        } 
        if (!LastNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.LastName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.LastName.layoutY, x: inputs.LastName.layoutY, animated: true });
          }
        } 
        else if (!RelationshipIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.Relationship.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.Relationship.layoutY, x: inputs.Relationship.layoutY, animated: true });
          }
        } 
        else if (!BirthDateIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.BirthDate.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.BirthDate.layoutY, x: inputs.BirthDate.layoutY, animated: true });
          }
        } 


        return;
      }

      console.log(`SavePersonalDetails passed`);
      const formData = new FormData();
      let userData = {
        Id: UserId,
        FirstName: inputs.FirstName.value,
        MiddleName: inputs.MiddleName.value,
        LastName: inputs.LastName.value,
        Relationship: inputs.Relationship.value,
        BirthDate: inputs.BirthDate.value,
        DeathDate: inputs.DeathDate.value,
        State: inputs.State.value,
        City: inputs.City.value,
        Description: inputs.Description.value,
        IsActive: true,
    }
    
    if(inputs.Image.isChanged)
      {
        const ImageFileName = 'image.jpg';
        if(responsiveStyles.isWeb){
          console.log(`
            if(inputs.Image.isChanged) 
            if(responsiveStyles.isWeb){
            `);
          const blob = await fetch(inputs.Image.value).then((res) => res.blob());
          formData.append('userImage', blob, ImageFileName);
        }
        else{
          formData.append('userImage', {
            uri: inputs.Image.value,
            name: ImageFileName,
            type: 'image/jpeg', // Modify the content type based on your image type
          });
        }
      }
    if(inputs.BackgroundImage.isChanged)
      {
        const BackgroundImageFileName = 'image.jpg';
        if(responsiveStyles.isWeb){
          const blob = await fetch(inputs.BackgroundImage.value).then((res) => res.blob());
          formData.append('backgroundImage', blob, BackgroundImageFileName);
        }
        else{
          formData.append('backgroundImage', {
            uri: inputs.BackgroundImage.value,
            name: BackgroundImageFileName,
            type: 'image/jpeg', // Modify the content type based on your image type
          });
        }
      }
    formData.append('json', JSON.stringify(userData));
    console.log(`formData is ${JSON.stringify(formData)}`);
    const response = await UpdateUserProfileInfo(formData);
        if(response.isCompleted === true)
        {
          setbodymessge(i18n.t('responseMessages.success'));
          setGenericAlertVisible(true);
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    const backAction = () => {
      if(NavigateFromScreen === "CreateProfile"){
        if(responsiveStyles.isWeb){
          navigation(-2);
        }
        else{
          navigation.pop();
          navigation.pop();
        } 
      }
      else{
        if(responsiveStyles.isWeb){
          navigation(-1);
        }
        else{
          navigation.pop();
        }
      }
      return true;
    };
    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);



    const fetchData = async () => {
      try {
        setLoading(true);
        const req = await GetUserProfileFullInfoById(UserId);
        if (req.isCompleted) {
          const vm = JSON.parse(req.answer);
          setUser(vm.User);
          setTributes(vm.Tributes);
          setMedia(vm.Media);
          setAdmins(vm.Admins);
          setIsAdmin(vm.IsAdmin);
          if(vm.User.IsPet){
            setPetInputs_InfoByUser(vm.User);
            setPetInputs_AdditionalInfoByUser(vm.User);
          }
          else{
            setInputsByUser(vm.User);
          }
        }
        else{
          setLoading(true);
          setErrorOnRequest(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(true);
        setErrorOnRequest(true);
      }
      finally{
        setLoading(false);
      }
    };

    const setDisplayType_ForAll = async () => {
      const newDisplayType = await getItem(EditProfileScreen_displayType);
      if(newDisplayType){
        setDisplayType(newDisplayType);
      }
    }
    const InitFunction = () => {
      if(isFirstTime)
        {
          setDisplayType_ForAll();
          fetchData();
          setIsFirstTime(false);
        }
        else{
          // setLoading(true);
          setErrorOnRequest(false);
          if(errorOnRequest === true)
          {
            fetchData();
          }
          else{
            fetchData();
          }
        }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    }
    if(responsiveStyles.isWeb){
      useEffect(() =>{
        if(responsiveStyles.isWeb){
          document.title = "Edit Profile";
        }
        InitFunction();
    },[reloadpage])
    }
    else{
      useFocusEffect(
        useCallback(() =>{
          InitFunction();
      },[reloadpage])
    );
    }
  

    const UserName = inputs ? `${inputs.FirstName.value} ${inputs.MiddleName.value} ${inputs.LastName.value}` : null;
    console.log(`UserName is ${UserName}`);

    const [childFunction, setChildFunction] = useState(null);
    const onScrollEndChildFunction = (fn) => {
      setChildFunction(() => fn); // Store the child's function in state
    };
    const handleScroll = (event) => {
      const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
      if (layoutMeasurement.height + contentOffset.y >= contentSize.height - responsiveStyles.screenHeight) {
          if (childFunction) {
            childFunction(); // Call the child's function from the parent
          } else {
            // Alert.alert('No function provided by the child yet');
          }
      } else {

      }
    };


  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  backAction={backAction}
  >
    {
    !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
    :errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
      <KeyboardAvoidingView
      behavior={'padding'}
      style={styles.container}
      >
  <ScrollView 
    ref={scrollViewRef}
    contentContainerStyle={styles.scrollViewContent}
    keyboardShouldPersistTaps='handled'
    onScroll={handleScroll}
    scrollEventThrottle={16}
  >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
<DisplayTypeComponent 
userSettingsState={userSettingsState}
displayType={displayType} 
setDisplayType={setDisplayTypeWithLocalStorage}
admins={admins}
tributes={tributes}
 />

<View style={styles.displayTypeContainer}>
{displayType === "Bio" ? 
      IsPet ? 
      <ManagePetProfileForm
    inputs={petInputs_Info} 
    setInputs={setPetInputs_Info} 
    additionalInfoInputs={petInputs_AdditionalInfo}
    setAdditionalInfoInputs={setPetInputs_AdditionalInfo}
    submitHandler={SavePetPersonalDetails} 
    userSettingsState={userSettingsState}
    navigation={navigation}
    onLayoutChange={onLayoutChange}
     />
    : <ManageHumanProfileForm 
    inputs={inputs} setInputs={setInputs} 
    submitHandler={SaveHumanPersonalDetails} 
    userSettingsState={userSettingsState}
    Id={UserId}
    DeleteUserProfile={DeleteUserProfileHandler}
    navigation={navigation}
    onLayoutChange={onLayoutChange}
    isAdmin={isAdmin}
     />
  : 
  displayType === 'Media' ? 
  <ManageMediaComponent 
  isEditing={true} 
  UserName={UserName} 
  userSettingsState={userSettingsState} 
  UserId={UserId} 
  data={media}
  navigation={navigation}
  />
  : displayType === 'Tributes' ? 
  <ManageTributesComponent 
  isEditing={true} 
  userSettingsState={userSettingsState} 
  data={tributes} 
  UserId={UserId}
  UserName={`${user.FirstName} ${user.LastName}`}
  navigation={navigation}
  isAdmin={isAdmin}
  onScrollEndParentFunction={onScrollEndChildFunction}
   />
  :  <AdminsComponent
  userSettingsState={userSettingsState}
  data={admins}
  isAdmin={isAdmin}
   navigation={navigation} 
   UserName={UserName}
   inputs={emailinputs}
   inputChangedHandler={emailInputChangedHandler}
   InviteUserAsAdminHandler={InviteUserAsAdminHandler}
   DeactivateUserHandler={DeactivateUserHandler}
   ApproveUserAsAdminHandler={ApproveUserAsAdminHandler}
   DisapproveUserAsAdminHandler={DisapproveUserAsAdminHandler}
    />}
  </View>
  </>
  </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    )}
    </Screen>

}


EditProfileScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // padding: 20,
    // height: fixedScreenHeight,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  displayTypeContainer: {
    paddingTop: 5,
  },
});

export default EditProfileScreen;
