import { Asset } from "expo-asset";
import { getAsset } from "../../../functions/getAsset";
export const Partner_Media = [
    {
        Id: -20001,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "A day workshopping",
        Description: "It was a wonderful day with you my love ♡",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_1.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200001,
                PostId: -20001,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_2.png')),
                // URL: "",
                Duration: null,
            },
            {
                Id: -200002,
                PostId: -20001,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_3.png')),
                // URL: "",
                Duration: null,
            },
            {
                Id: -200003,
                PostId: -20001,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_4.png')),
                // URL: "",
                Duration: null,
            },
            {
                Id: -200004,
                PostId: -20001,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_1.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
    {
        Id: -20002,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "A weeknd with you",
        Description: "some memories from our fun weeknd",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/weeknd/weeknd_1.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200005,
                PostId: -20002,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/weeknd/weeknd_1.png')),
                // URL: "",
                Duration: null,
            },
            {
                Id: -200006,
                PostId: -20002,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/weeknd/weeknd_2.png')),
                // URL: "",
                Duration: null,
            },
            {
                Id: -200007,
                PostId: -20002,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/weeknd/weeknd_3.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
    {
        Id: -20003,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "Cycling",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/cycling.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200008,
                PostId: -20003,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/cycling.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
    {
        Id: -20004,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "Dinner",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/dinner.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200009,
                PostId: -20004,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/dinner.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
    {
        Id: -20005,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "Mountains",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/mountains.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200010,
                PostId: -20005,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/mountains.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
    {
        Id: -20006,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "Movie_night",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/movie_night.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200011,
                PostId: -20006,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/movie_night.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
    {
        Id: -20007,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
        // UserImage: "",
        UserName: "Partner",
        UserId: null,
        UserProfileId: null,

        Title: "Volunteering",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/partner/volunteering.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -200012,
                PostId: -20007,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/partner/volunteering.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-20",
    },
  
  ];