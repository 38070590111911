export const RelationshipList = 
[
    {
        Id: 1,
        Value: "אמא",
    },
    {
        Id: 2,
        Value: "אבא",
    },
    {
        Id: 3,
        Value: "אישה",
    },
    {
        Id: 4,
        Value: "בעל",
    },
    {
        Id: 5,
        Value: "אחות",
    },
    {
        Id: 6,
        Value: "אח",
    },
    {
        Id: 7,
        Value: "דודה",
    },
    {
        Id: 8,
        Value: "דוד",
    },
    {
        Id: 9,
        Value: "בן דוד/ה",
    },
    {
        Id: 10,
        Value: "בת",
    },
    {
        Id: 11,
        Value: "בן",
    },
    {
        Id: 12,
        Value: "אחיין",
    },
    {
        Id: 13,
        Value: "בן זוג",
    },
    {
        Id: 14,
        Value: "בת זוג",
    },
    {
        Id: 15,
        Value: "חבר/ה",
    },
    {
        Id: 16,
        Value: "נכדה",
    },
    {
        Id: 17,
        Value: "נכד",
    },
    {
        Id: 18,
        Value: "סבתה",
    },
    {
        Id: 19,
        Value: "סבא",
    },
    {
        Id: 20,
        Value: "סבא רבא",
    },
    {
        Id: 21,
        Value: "סבתא רבתא",
    },
    {
        Id: 22,
        Value: "אחר",
    },
];