import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, Platform, Dimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from "react-native-reanimated"; // For mobile animation
import ReviewContainer from "./ReviewContainer";
import { transitionTime } from "../../functions/ScreenDimensionsLogic";

const ReviewsContainer = ({ 
  userSettingsState,
  navigation,
  scrollY, // Accept scrollY from parent ScrollView for mobile scroll tracking
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const windowHeight = Dimensions.get("window").height; // Get window height for viewport calculation

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Store the Y position of the section
  const [isVisible, setIsVisible] = useState(false); // For web visibility

  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    contentOpacity.value = 1;
    contentTranslateY.value = 0;
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== "web") {
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when the component comes into the viewport
      }
    }
  }, [scrollY, layoutY]);

  // Capture the layout position on mobile
  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); // Save Y position
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // Trigger animation if the view is initially visible
    }
  };

  // Intersection Observer for Web
  useEffect(() => {
    if (Platform.OS === "web") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect(); // Disconnect after the animation triggers
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the section is visible
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  return (
    <Animated.View // Use Animated.View for mobile animations
      ref={sectionRef}
      style={[
        styles.reviewContainer,
        Platform.OS === "web" && isVisible
          ? { opacity: 1, transform: "translateY(0px)", transition: "opacity 1s ease-out, transform 1s ease-out" }
          : { opacity: 0, transform: "translateY(50px)" }, // Hidden until visible
        Platform.OS !== "web" ? animatedContentStyle : null,
      ]}
      onLayout={handleLayout} // Capture layout for mobile
    >
      <ReviewContainer
        userSettingsState={userSettingsState}
        name={i18n.t("store.HomeScreen_Review1_Name")}
        subtitle={i18n.t("store.HomeScreen_Review1_Subtitle")}
        content={i18n.t("store.HomeScreen_Review1_Description")}
      />

      <ReviewContainer
        userSettingsState={userSettingsState}
        name={i18n.t("store.HomeScreen_Review2_Name")}
        subtitle={i18n.t("store.HomeScreen_Review2_Subtitle")}
        content={i18n.t("store.HomeScreen_Review2_Description")}
      />

      <ReviewContainer
        userSettingsState={userSettingsState}
        name={i18n.t("store.HomeScreen_Review3_Name")}
        subtitle={i18n.t("store.HomeScreen_Review3_Subtitle")}
        content={i18n.t("store.HomeScreen_Review3_Description")}
      />
    </Animated.View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  reviewContainer: {
    flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop
      ? responsiveStyles.isRTL
        ? 'row-reverse'
        : 'row'
      : 'column', // Stack reviews in a column on mobile
    justifyContent: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'space-around' : 'center',
    marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop
      ? responsiveStyles.screenWidth * 0.05
      : 10,
  },
});

export default ReviewsContainer;
