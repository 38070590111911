export default {
  ManageTributesComponent_Create: "פרסם מחווה",
  ManageTributesComponent_Accept: "אישור",
  ManageTributesComponent_Decline: "מחיקה",
  ManageTributesComponent_Likes: "לייקים",
  ManageTributesComponent_Comments: "תגובות",
  CommendComponent_Edit: "ערוך",
  ManageTributesComponent_DeleteButton: "מחק פוסט",
  CommentSectionPopup_Title: "תגובות",
  DescriptionExtention: "המשך קריאה",
  CommentSectionPopup_DeleteButton: "מחק תגובה",
  CommentSectionPopup_TextInput: "הוסף תגובה ל",
  Comments: "תגובות",
  Likes: "לייקים",

  ShareProfile: "שתף פרופיל",
  ShareProfile_message1: "צפו בכל הזכרונו של",
  ShareProfile_message2: "עם Timeless Memories Jewelry.",
  TributesLink: "שתף מחוות",
  TributesLink_message1: "שתפו את הזכרונות שלכם עם",
  TributesLink_message2: "ותצרו מחווה נצחית ב Timeless Memories Jewelry.",
};