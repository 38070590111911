import { useState, useContext } from "react";
import { I18n } from "i18n-js";
import en from '../constants/translations/en';
import he from '../constants/translations/he';
import { Language_Storage } from "../modules/StorageObjects";

const translations = {
    en,
    he
  }
  const i18n = new I18n(translations);
  i18n.defaultLocale = 'en';
  i18n.fallbacks = true;
  i18n.enableFallback = true;
  
  export default i18n;