export default {
  CompanyName: "Timeless Memories jewelry ©",
  AlreadyHaveAccountButton: "כבר יש לך חשבון?",
    EmailInput: "שם משתמש, מייל או מספר טלפון",
    CodeSuccess: "הקוד נשלח",
    Next: "הבא",
    SignIn_PasswordInput: "סיסמה",
    SignIn_LogInButton: "התחברות",
    SignIn_ForgotPasswordButton: "שכחת את הסיסמה?",
    SignIn_CreateNewAccountButton: "יצירת חשבון חדש",
    SignIn_EmailRequired_subject: `נדרש דוא"ל או מספר טלפון נייד`,
    SignIn_EmailRequired_message: `יש להזין רת הדוא"ל או את מספר הטלפון הנייד שלך כדי להמשיך`,
    SignIn_EmailRequired_signUpText: 'אישור',
    SignIn_PasswordRequired_subject: `נדרשת סיסמה`,
    SignIn_PasswordRequired_message: `יש להזין סיסמה כדי להמשיך`,
    SignIn_PasswordRequired_signUpText: `אישור`,
    SignIn_CantFindAccountAlert_subject: 'החשבון לא נמצא',
    SignIn_CantFindAccountAlert_message1: `לא ניתן למצוא חשבון עם `,
    SignIn_CantFindAccountAlert_message2: `. יש לנסות מספר טלפון או כתובת דוא"ל אחרים, או אם אין לך חשבון TMJ אפשר להירשם.`,
    SignIn_CantFindAccountAlert_signUpText: 'הרשמה',
    SignIn_CantFindAccountAlert_tryAgainText: 'נסה/נסי שוב',
    SignIn_IncorrectPasswordAlert_subject: `סיסמה שגויה`,
    SignIn_IncorrectPasswordAlert_message: `הסיסמה שהזנת שגויה. נסה/נסי שוב.`,
    SignIn_IncorrectPasswordAlert_signUpText: 'אישור',
    SignUp_headerEmail: `דוא"ל`,
    SignUp_headeremail: `כתובת דוא"ל`,
    SignUp_headerTypeEmail: `מהו הדוא"ל שלך?`,
    SignUp_headerTypeMobileNumber: "מהו מספר הטלפון הנייד שלך",
    SignUp_headerMobileNumber: "מספר טלפון נייד",
    SignUp_headermobileNumber: "מספר סלולרי",
    SignUp_headerDescriptionTypeEmail_1: `יש להזין את הדוא"ל שבו אפשר ליצור איתך קשר.`,
    SignUp_headerDescriptionTypeEmail_2: `אף אחד לא יראה את הכתובת הזו בפרופיל שלך`,
    SignUp_headerDescriptionTypeMobileNumber_1: `יש להזין את מספר הטלפון הנייד שבו אפשר ליצור איתך קשר.`,
    SignUp_headerDescriptionTypeMobileNumber_2: `אף אחד לא יראה את הכתובת הזו בפרופיל שלך`,
    SignUp_emailErrorMessage: "הזן כתובת דואר אלקטרוני חוקית.",
    SignUp_smsInfo: "ייתכן שישלחו לך מאיתנו התראות בהודעות טקסט למטרות אבטחה והתחברות.",
    SignUp_SubmitButton: "הבא",
    SignUp_signUpWith: "הירשם עם ",
    SignUp_AccountExistAlert_subject: "מנסה להתחבר?",
    SignUp_AccountExistAlert_message: `הדוא"ל הזה משויך לחשבון קיים. יש לך אפשרות להתחבר אליו או לאפס את סיסמתך.`,
    SignUp_AccountExistAlert_logIn: "התחברות לחשבון קיים",
    SignUp_AccountExistAlert_ForgotPassword: "איפוס סיסמה",
    SignUpConfirmEmailCode_header: "יש להזין את קוד האישור",
    SignUpConfirmEmailCode_headerDescription_1: "כדי לאשר את חשבונך, יש להזין את הקוד בן 6 הספרות ששלחנו ",
    SignUpConfirmEmailCode_headerDescription_2: "אל",
    SignUpConfirmEmailCode_confirmationCode: "קוד אישור",
    SignUpConfirmEmailCode_didntGetCode: "לא קיבלתי את הקוד",
    SignUpConfirmEmailCode_submitButton: 'הבא',
    // SignUpConfirmEmailCodeAlert_CodeRequired_subject: "Enter a code",
    // SignUpConfirmEmailCodeAlert_CodeRequired_message: `You'll need to enter a code to continue.`,
    // SignUpConfirmEmailCodeAlert_CodeRequired_TryAgain: `Try again`,
    // SignUpConfirmEmailCodeAlert_IncorrectCode_subject: "That code didn't work",
    // SignUpConfirmEmailCodeAlert_IncorrectCode_message: `Please re-enter you code or request a new one.`,
    // SignUpConfirmEmailCodeAlert_IncorrectCode_TryAgain: `Try again`,
    SignUpConfirmEmailCodeAlert_CodeRequired: `נדרש קוד. נא לבדוק את הדוא"ל שלך או הודעות בטקסט שלך כדי למצוא את הקוד`,
    SignUpConfirmEmailCodeAlert_CodeTooShort: `הקוד קצר מדי. נא לבדוק שהזנת אותו במדויק ולנסות שוב.`,
    SignUpConfirmEmailCodeAlert_CodeTooLong: `הקוד ארוך מדי. נא לבדוק שהזנת אותו במדויק ולנסות שוב.`,
    SignUpConfirmEmailCodeAlert_IncorrectCode: `קוד זה אינו חוקי. אתה יכול לבקש אחד חדש.`,
    SignUpConfirmUserNameAndPasswordByEmail_header: "יצירת שם משתמש וסיסמה",
    SignUpConfirmUserNameAndPasswordByEmail_headerDescription_1: "צור סיסמה עם לפחות 6 אותיות או מספרים.",
    SignUpConfirmUserNameAndPasswordByEmail_headerDescription_2: "זה צריך להיות משהו שאחרים לא יכולים לנחש.",
    SignUpConfirmUserNameAndPasswordByEmail_userName: "שם משתמש",
    SignUpConfirmUserNameAndPasswordByEmail_firstName: "שם פרטי",
    SignUpConfirmUserNameAndPasswordByEmail_lastName: "שם משפחה",
    SignUpConfirmUserNameAndPasswordByEmail_NameRequiredErrorMessage: `יש להכניס שם פרטי ושם משפחה כדי להמשיך`,
    SignUpConfirmUserNameAndPasswordByEmail_password: "סיסמה",
    SignUpConfirmUserNameAndPasswordByEmail_UsernameRequiredErrorMessge: "יש לבחור משתמש כדי להמשיך.",
    SignUpConfirmUserNameAndPasswordByEmail_UsernameNotAvailableErrorMessge_part1: "שם המשתמש ",
    SignUpConfirmUserNameAndPasswordByEmail_UsernameNotAvailableErrorMessge_part2: " אינו זמין.",
    SignUpConfirmUserNameAndPasswordByEmail_PasswordRequiredErrorMessage: "יש להזין סיסמה",
    SignUpConfirmUserNameAndPasswordByEmail_PasswordLengthErrorMessge: "צור סיסמה שאורכה 6 תווים לפחות",
    SignUpConfirmUserNameAndPasswordByEmail_PasswordTooEasyErrorMessge: "קל מדי לנחש סיסמה זו. צור סיסמה חדשה.",
    SignUpConfirmation_header: "הירשם כ ",
    SignUpConfirmation_headerDescription: "תמיד תוכל לשנות את שם המשתמש שלך מאוחר יותר",
    SignUpConfirmation_footerDescription: "הקשה על אני מסכים/ה מהווה את הסכמתך ליצירת חשבון ולתנאים, למדיניות הפרטיות ולמדיניות קובצי ה-Cookie של TMJ",
    SignUpConfirmation_submitButton: "אני מסכים/ה",
    ForgotPassword_header: "מציאת החשבון שלך",
    ForgotPassword_headerDescription: `יש להזין שם משתמש, דוא"ל או מספר טלפון נייד`,
    ForgotPassword_submitButton: "מציאת החשבון שלי",
    ForgotPassword_emailError: `הזן את כתובת הדוא"ל או את מספר הטלפון הנייד שלך כדי להמשיך.`,
    ForgotPassword_AccountNotFoundAlert_subject: 'לא נמצא חשבון',
    ForgotPassword_AccountNotFoundAlert_message:  `יש לבדוק את שם המשתמש, את מספר הטלפון הנייד או את הדוא"ל ולנסות שוב.`,
    ForgotPassword_AccountNotFoundAlert_okButton: 'אישור',
    ForgotPasswordCodeConfirmation_header: "אישור החשבון שלך",
    ForgotPasswordCodeConfirmation_headerDescription_1: `שלחנו קוד לדוא"ל שלך.`,
    ForgotPasswordCodeConfirmation_headerDescription_2: `יש להזין את הקוד כדי לאשר את חשבונך.`,
    ForgotPasswordCodeConfirmation_codeInput: "יש להזין את הקוד",
    ForgotPasswordCodeConfirmation_submitButton: "המשך",
    ForgotPasswordCodeConfirmation_sendCodeAgain: "שלחו לי שוב את הקוד",
    ForgotPasswordCodeConfirmation_CodeRequired: `נדרש קוד. נא לבדוק את הדוא"ל שלך או הודעות בטקסט שלך כדי למצוא את הקוד`,
    ForgotPasswordCodeConfirmation_CodeTooShort: `הקוד קצר מדי. נא לבדוק שהזנת אותו במדויק ולנסות שוב.`,
    ForgotPasswordCodeConfirmation_CodeTooLong: `הקוד ארוך מדי. נא לבדוק שהזנת אותו במדויק ולנסות שוב.`,
    ForgotPasswordCodeConfirmation_IncorrectCode: `קוד זה אינו חוקי. אתה יכול לבקש אחד חדש.`,
    ForgotPasswordConfirmCanChangePassword_header: "יצירת סיסמה חדשה", // בסדק
    ForgotPasswordConfirmCanChangePassword_headerDescription_1: "יש ליצור סיסמה עם לפחות 6 אותיות וספרות.",
    ForgotPasswordConfirmCanChangePassword_headerDescription_2: "הסיסמה דרושה לכניסה לחשבון שלך.",
    ForgotPasswordConfirmCanChangePassword_passwordInput: "המשך",
    ForgotPasswordConfirmCanChangePassword_sumbitButton: "המשך",
    ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_subject: "יש להזין סיסמה",
    ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_message: "יהיה עלך להזין סיסמה כדי להמשיך.",
    ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_okButton: "נסיון חוזר",
    ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_message: "צור סיסמה שאורכה 6 תווים לפחות",
    ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton: "אישור",
    ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_message: "קל מדי לנחש סיסמה זו. צור סיסמה חדשה.",
    ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_okButton: "אישור",
    ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_message: "קוד זה אינו חוקי. אתה יכול לבקש אחד חדש.",
    ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_okButton: "אישור",
    InitMessages_loadingMessages: `מסנכרן הודעות, נא להמתין`,
    ResolveAuth_loadingData: `מביא נתונים`,
  };