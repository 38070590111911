import React, { useState, useEffect } from "react";
import {
  Button,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  useWindowDimensions,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import Moment from "moment";
import { Feather } from '@expo/vector-icons'; 
import { GlobalStyles, Lavender } from "../../../constants/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import useResponsiveStyles from "../../../functions/ResponsiveStyles";
import i18n from "../../../hooks/useI18n";


Modal.setAppElement('#root');  // Set the root element for accessibility

const DateComponent = ({ 
  item, 
  onChangeFieldValue, 
  itemId, 
  editable,
  initValue, 
  userSettingsState, 
  styleContainer,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  const IconHeight = responsiveStyles.screenHeight / 20; 
  console.log(initValue);
  const [datePicker, setDatePicker] = useState(false);
  const [date, setDate] = useState(initValue === null ? null : new Date(initValue));
  console.log(`DateComponent initValue: ${initValue}, date: ${date}`);

  function showDatePicker() {
    if(editable){
      console.log("showDatePicker");
      setDatePicker(true);
      onFocus();
    }
  }

  function onDateSelected(value) {
    setDate(value);
    setDatePicker(false);
    onChangeFieldValue(itemId, value);
    onBlur();
  }

  Moment.locale("en");

  const [containerBorderColor, setContainerBorderColor] = useState();
  const [textColor, setTextColor] = useState();

  const setDefaultProps = () => {
    setContainerBorderColor(GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor);
    setTextColor(GlobalStyles[responsiveStyles.ThemeValue]?.inputHeaderTextColor);
  }
  useEffect(() => {
    if (responsiveStyles.ThemeValue === 'dark') {
      import("./dark-mode-datepicker.css");
    }
    else{
      import("./light-mode-datepicker.css");
    }
    setDefaultProps();
  }, [responsiveStyles.ThemeValue, responsiveStyles]);
  const onFocus = () => {
    setContainerBorderColor(GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColorOnFocus);
    setTextColor(GlobalStyles[responsiveStyles.ThemeValue]?.inputHeaderTextColorFocus);
  }
  const onBlur = () => {
    setDefaultProps();
  }

  const DatePickerComponent = () => {
    return (
      <View style={[styles.container]}>
        <View style={[
          styles.DateContainer, 
          styles.fieldSet, 
          { borderColor: containerBorderColor }, 
          styles.inputHeight,
          styleContainer
          ]}>
          <View style={[styles.legend, responsiveStyles.isRTL ? { right: 20 } : { left: 20 }]}>
            <Text style={[styles.inputHeaderText, { color: textColor }]}>{item.fieldName}</Text>
          </View>
          <TouchableOpacity
            style={[styles.DatePick, responsiveStyles.isRTL ? { flexDirection: "row" } : { flexDirection: "row-reverse" }]}
            onPress={showDatePicker}
            activeOpacity={editable ? 0.25 : 1}
          >
            <View style={[
              styles.DatePickerIcon, 
              // { 
              //   height: IconHeight, 
              // },
              ]}>
              <Feather
                name="calendar"
                size={24}
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
              />
            </View>
            <Text style={styles.dateText}>
              {date === null ? null : Moment(date).format("DD/MM/yy")}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View>
        <DatePickerComponent />
        {responsiveStyles.isWeb && (
          <Modal
            animationType="fade"
            transparent={true}
            isOpen={datePicker}
            onRequestClose={() => setDatePicker(false)}
            style={customStyles(responsiveStyles.ThemeValue)}
            contentLabel="Date Picker Modal"
          >
            <View style={styles.modalContainer}>
              <h2 style={styles.modalTitle}>{i18n.t('textInput.datePicker_modalTitle')}</h2>
              <DatePicker
                selected={date}
                onChange={(value) => onDateSelected(value)}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                inline
              />
              <button
                onClick={() => setDatePicker(false)}
                style={styles.closeButton}
              >
                {i18n.t('textInput.datePicker_cancelButton')}
              </button>
            </View>
          </Modal>
        )}
        {!responsiveStyles.isWeb && datePicker && (
          <DateTimePicker
            value={date === null ? new Date() : date}
            mode={"date"}
            display={Platform.OS === "ios" ? "spinner" : "default"}
            is24Hour={true}
            onChange={(event, value) => onDateSelected(value)}
            style={styles.datePicker}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

const customStyles = (responsiveStyles) => ({
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '400px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
});

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  inputHeight:{
    minHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
    maxHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
  },
  fieldSet: {
    margin: 10,
  },
  legend: {
    position: 'absolute',
    top: -10,
    fontWeight: 'bold',
    paddingHorizontal: 5,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  DateContainer: {
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor,
    borderWidth: 1,
    borderRadius: 25,
    padding: 10,
    width: '100%',
  },
  DatePick: {
    flex: 2,
    alignItems: "center",
  },
  DatePickerIcon: {
    marginHorizontal: 10,
    // padding: !responsiveStyles.isMobile ? 10 : 5,
    // height: 35,
    height: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputHeaderText: {
    fontSize: responsiveStyles.fontSize_H6,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  dateText: {
    fontSize: responsiveStyles.fontSize_H4,
    flex: 3,
    textAlign: 'left',
    paddingHorizontal: 15,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  datePicker: {
    justifyContent: "center",
    alignItems: "flex-start",
    width: 320,
    height: 260,
    display: "flex",
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalTitle: {
    marginBottom: '20px',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: Lavender,
    color: '#fff',
    cursor: 'pointer',
  },
});

export default DateComponent;
