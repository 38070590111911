import { media } from "../../dummyData/ProfileData";
import { tributes } from "../../dummyData/Profile_TributesData";
import { admins, user } from "../../dummyData/UserProfile_FullInfoData";
import { getResponseByErr, getResponseSucceed } from "../../functions/getResponseByErr";
import { getAsync, post, postAsync } from "../categoryApiCalls";

const InviteUserAsAdmin = async (data) => {
  try {
      const req = await postAsync(
        "/UserProfileAdmin/InviteUserAsAdmin/", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from InviteUserAsAdmin ${err}`);
    return getResponseByErr(err);
  }
};
const RemoveUserAsAdmin = async (data) => {
  try {
      const req = await postAsync(
        "/UserProfileAdmin/RemoveUserAsAdmin/", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from RemoveUserAsAdmin ${err}`);
    return getResponseByErr(err);
  }
};
const ApproveUserAsAdmin = async (data) => {
  try {
      const req = await postAsync(
        "/UserProfileAdminRequest/RequestUserAdminApproved/", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from ApproveUserAsAdmin ${err}`);
    return getResponseByErr(err);
  }
};
const DisapproveUserAsAdmin = async (data) => {
  try {
      const req = await postAsync(
        "/UserProfileAdminRequest/RequestUserAdminNotApproved/", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from DisapproveUserAsAdmin ${err}`);
    return getResponseByErr(err);
  }
};
    export { InviteUserAsAdmin,RemoveUserAsAdmin,ApproveUserAsAdmin, DisapproveUserAsAdmin  };