import React, { useRef, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, PanResponder, Animated, Image, Dimensions, FlatList, Platform } from 'react-native';
import Modal from 'react-native-modal';
import { GlobalStyles } from '../constants/styles';
import { AntDesign } from '@expo/vector-icons';
import ImageProfile from './images/ImageProfile';
import i18n from '../hooks/useI18n';
import ButtonTypeButton from './inputs/ButtonTypeButton';
import { bodyHeight, fixedScreenHeight } from '../constants/ScreenDimensions';
import MediaEmptyListComponent from './displayComponent/MediaEmptyListComponent';
import CommentSectionListComponent from './displayComponent/CommentSectionListComponent';
import { Feather } from '@expo/vector-icons'; 
import { Menu, MenuItem } from 'react-native-material-menu';
import { rearrangeDataForRTL } from '../functions/rearrangeDataForRTL';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const RatingSectionPopup = ({ 
  isVisible, 
  onClose,
  userSettingsState,
  data = [],
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  const panY = useRef(new Animated.Value(0)).current;
  const [popUpHeight, setPopUpHeight] = useState(100);
  const [numberOfLines, setNumberOfLines] = useState(4);
const [showMoreRead, setShowMoreRead] = useState(false);
const isSentRequestToJoin = false;

const handleLayout = (event) => {
  console.log("handleLayout");
  const { height } = event.nativeEvent.layout;
  const fontSize = 16;
  const lineHeight = fontSize + fontSize * 0.2; // Calculating line height based on font size
  const maxAllowedHeight = lineHeight * 4;
  if(height >= maxAllowedHeight)
  {
    console.log(`height > maxAllowedHeight ${height} > ${maxAllowedHeight}`);
    setShowMoreRead(true);
  }
};
const [isHideMenu, setIsHideMenu] = useState(false);
const [visible, setVisible] = useState(null);
const hideMenu = () => {
  if(setIsHideMenu !== null && setIsHideMenu !== undefined)
  {
    setIsHideMenu(false);
  }
  setVisible(null)
};

const showMenu = (Id) => {
  if(setIsHideMenu !== null && setIsHideMenu !== undefined)
  {
    setIsHideMenu(true);
  }
  setVisible(Id)
};
  // console.log(`popUpHeight ${popUpHeight} showMoreRead ${showMoreRead} numberOfLines ${numberOfLines}
  // numberOfLines === null || showMoreRead === false ${numberOfLines === null || showMoreRead === false}
  // `);
  useEffect(() => {
    if (isVisible) {
      Animated.spring(panY, {
        toValue: 0,
        useNativeDriver: false,
      }).start();
    }
    else{
      setShowMoreRead(false);
      setNumberOfLines(4);
    }
  }, [isVisible,showMoreRead]);

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy > 0) {
          panY.setValue(gestureState.dy);
        }
      },
      onPanResponderRelease: (_, gestureState) => {
        if (gestureState.dy > 0 && gestureState.dy < popUpHeight / 2) {
          Animated.spring(panY, {
            toValue: 0,
            useNativeDriver: false,
          }).start();
        } else if (gestureState.dy >= popUpHeight / 2) {
          // Add a delay before calling onClose to wait for the animation to finish
          setTimeout(() => {
            onClose();
          }, 750); // 750 milliseconds (0.75 seconds) delay
        }
      },
    })
  ).current;

  const animatedStyle = {
    transform: [{ translateY: panY }],
  };

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
      style={styles.modalContainer}
      backdropOpacity={0.5}
    >
      <Animated.View 
      style={[styles.popupAnimatedContainer, animatedStyle]} {...panResponder.panHandlers} 
            onLayout={(event) => {
                let {height} = event.nativeEvent.layout;
                setPopUpHeight(height);
              }}
      >
        <View style={styles.upperSlider}>
            <Text style={styles.upperSliderText}>
                uuuuuuuuuuuuu
                </Text>
        </View>
        <TouchableOpacity onPress={onClose} style={styles.closeButton}>
        <AntDesign name="close" size={24} color= {GlobalStyles.colors.TitleBorderBottomColor} />
        </TouchableOpacity>
        <View style={styles.popupContainer}>
        <View style={styles.TitleContainer}>
        <Text style={styles.title}>{i18n.t('media.CommentSectionPopup_Title')}</Text>
        </View>

        <FlatList
      scrollEnabled={false}
      style={styles.flatListContainer}
      contentContainerStyle={responsiveStyles.isWeb ? styles.flatListContentContainer : null}
      // data={responsiveStyles.isRTL ? rearrangeDataForRTL(data, 3) : data}
      data={data}
      renderItem={({ item, index }) =>
      {
      
     return <View style={styles.commentContainer}>
      <View style={styles.comment_userInfoContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={null}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
    />
            <Text style={styles.userNameText}>{item.UserName}</Text>
            <View style={styles.commentOption}>
            <Text style={[styles.userNameText,styles.commentCreatedDateText]}>{item.CreatedDate}</Text>
            {/* <Feather name="more-vertical" size={24}
           color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
             /> */}
          <Menu
          visible={visible === item.Id}
          anchor={
        <TouchableOpacity 
         onPress={() => showMenu(item.Id)}
        >
        <Feather name="more-vertical"  size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
        </TouchableOpacity>
          
        }
        style={{borderRadius:10}}
          onRequestClose={hideMenu}
        >
              <View style={styles.menuContainer}>
      <MenuItem textStyle={styles.menuItemText} onPress={() => {
        setIsHideMenu(false);
      }}>{i18n.t(`media.CommentSectionPopup_DeleteButton`)}
      </MenuItem>
      
      </View>
      </Menu>
              </View>
            </View>
    
            <View style={styles.comment_commentContainer}>
              <Text style={[styles.userNameText,styles.commentText]}>
              {item.Description}
              </Text>
            </View>
    </View>
      }
      }
      // numColumns={3}
      ListEmptyComponent={
        <CommentSectionListComponent userSettingsState={userSettingsState} />
          }
      />
        
        </View>
      </Animated.View>
    </Modal>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  modalContainer: {
    justifyContent: 'flex-end',
    margin: 0,
    
  },
  flatListContentContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexShrink: 1,
  },
  popupAnimatedContainer: {
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: '100%',
    // height: fixedScreenHeight,
  },
  popupContainer: {
    // padding: 50,
    alignItems: 'center',
  },
  TitleContainer: {
    // backgroundColor: 'blue',
    paddingBottom: 20,
    borderBottomWidth: 1,
    width: '100%',
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    justifyContent:'center',
    alignItems:'center',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  upperSlider: {
    alignItems:'center',
     marginTop: 15,

  },
  upperSliderText: {
    borderWidth:1, 
    borderRadius:20, 
    fontSize:2, 
    backgroundColor: GlobalStyles.colors.TitleBorderBottomColor, 
    color: GlobalStyles.colors.TitleBorderBottomColor, 
    borderColor: GlobalStyles.colors.TitleBorderBottomColor,
    fontFamily: GlobalStyles.fonts.application_font,
    width: responsiveStyles.screenWidth * 0.2,
    fontFamily: GlobalStyles.fonts.application_font,
},
  closeButton: {
    marginTop: 10,
    alignSelf: 'flex-end',
    paddingHorizontal: 15,
    borderRadius: 15,
  },
  requestJoinDescription: {
    paddingVertical: 10,
    alignSelf: 'center',
  },
  TextTouchableOpacity:{
    color:'#0d8984'
  },
  groupPhoto: {
    width: 100,
    height: 100,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,

  },
  userPhoto: {
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  numberOfMembers: {
    width: 50,
    height: 50,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    textAlign:'center',
    paddingTop:15,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.borderColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  commentContainer: {
    // flexDirection: 'row',
    width: '100%',
    // padding: 10,
    // alignItems:'center',
    // paddingHorizontal: 20,
  },
  comment_userInfoContainer: {
    flexDirection: 'row',
    padding: 10,
    alignItems:'center',
    // paddingHorizontal: 20,
  },
  comment_commentContainer: {
    paddingLeft: 40 + 5,
  },
  userNameText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    marginHorizontal: 20,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  commentCreatedDateText: {
    fontWeight: '500',
  },
  commentText: {
    fontWeight: '500',
    fontSize: responsiveStyles.fontSize_H6,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  commentOption: {
    flex:1, 
    alignItems:'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',

  },
  menuContainer:{
    
    backgroundColor:GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor, 
    borderWidth:1,
     borderColor:GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor, 
     borderRadius: 10,

    
},
menuItemText:{
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
}
});

export default RatingSectionPopup;
