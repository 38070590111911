import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { navigateToScreen } from "../../navigationResponsive";
import ButtonTypeText from "../inputs/ButtonTypeText";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const OrderEmptyListComponent = ({ 
    userSettingsState, 
    navigation
   }) => {
 
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

    
  return (
    <View>
    <View style={styles.OrderContainer}>
      <AntDesign name="frowno" size={24 * 3} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      <Text style={styles.title}>{i18n.t('account.MyAccount_OrderEmptyListComponent_Title')}</Text>
      <View style={{flexDirection:'row', alignItems:'center'}}>
      <Text style={styles.text}>{i18n.t('account.MyAccount_OrderEmptyListComponent_SubTitle1')} </Text>
      <ButtonTypeText
      userSettingsState={userSettingsState}
      styleText={{fontSize: responsiveStyles.fontSize_H5,}}
      onPress={() => {
        navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
      }}
      >
    {i18n.t('account.MyAccount_OrderEmptyListComponent_SubTitle2')}
      </ButtonTypeText>
      <Text style={styles.text}> {i18n.t('account.MyAccount_OrderEmptyListComponent_SubTitle3')}</Text>
      </View>
  </View>
    </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  OrderContainer: {
    flex:1,
    marginHorizontal: responsiveStyles.screenWidth * 0.05,
    padding: 15,
    width: responsiveStyles.screenWidth * 0.9,
    alignItems:'center',
    justifyContent:'space-around',
    marginTop: responsiveStyles.screenHeight * 0.15,
    alignSelf: 'center',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
  },
  title: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    paddingVertical: 20,
  },
  buttonContainer: {
    alignItems:'center'
  },
  button: {
    width: !responsiveStyles.isMobile || responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
    minWidth: !responsiveStyles.isMobile || responsiveStyles.isWeb ? 150 : null,
  },
});

export default OrderEmptyListComponent;