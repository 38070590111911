
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getResponseByErr } from "../functions/getResponseByErr";
import { postAsync } from "./categoryApiCalls";
import { FCMToken_Storage } from "../modules/StorageObjects";
const SendNotificationToken = async (token, deviceId) => {
    try {
      const vm = {
        deviceId,
        token
      }
      console.log("SendNotificationToken from UserApiCalls");

        const req = await postAsync(
          "/User/SendNotificationToken",
          vm
        );
        const response = req.data;
        if (response.isCompleted) {
          const token = JSON.parse(response.answer);
        console.log("token from SendNotificationToken " + token);
        await AsyncStorage.setItem("ExpoToken", token);
        }
        else {
          throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from SendNotificationToken ${err}`);
      return getResponseByErr(err);
    }
  };
  const SendFCMNotificationToken = async (token, deviceId) => {
    try {
      const currentFCMToken = await AsyncStorage.getItem(FCMToken_Storage);
      // if(currentFCMToken === token){
      //   console.log("SendFCMNotificationToken will not be called because the FCMToken is the same");
      //   return;
      // }
      await AsyncStorage.setItem("FCMToken", token);
      const vm = {
        deviceId,
        token
      }
      console.log("SendFCMNotificationToken from UserApiCalls");

        const req = await postAsync(
          "/User/SendFCMNotificationToken",
          vm
        );
        const response = req.data;
        if (response.isCompleted) {
          const token = JSON.parse(response.answer);
        console.log("token from SendFCMNotificationToken " + token);
        await AsyncStorage.setItem("FCMToken", token);
        }
        else {
          throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from SendFCMNotificationToken ${err}`);
      return getResponseByErr(err);
    }
  };

  export { SendNotificationToken, SendFCMNotificationToken };