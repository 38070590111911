import { Alert, View } from "react-native";
import { useEffect, useState } from "react";
import i18n from "../../hooks/useI18n";
import CustomAlert from "../modals/CustomAlert";
import { navigateToScreen } from "../../navigationResponsive";

// function AccountExist(navigation) {
//     Alert.alert(i18n.t('auth.SignUp_AccountExistAlert_subject'),
//     i18n.t('auth.SignUp_AccountExistAlert_message'), [
//         {
//           text: i18n.t('auth.SignUp_AccountExistAlert_logIn'),
//           onPress: () => {
//             navigation.navigate('Signin',{ isEmail: true });
//         },
//           style: 'cancel',
//         },
//         {
//             text: i18n.t('auth.SignUp_AccountExistAlert_ForgotPassword'),
//             onPress: () => {
//               navigation.navigate('ForgotPassword');
//           },
//             style: 'cancel',
//           },
//       ],
//       {cancelable:true}
//       );
//   }

// export {AccountExist};

export const SignUpAlert = ({ 
  navigation, 
  state, 
  addErrorMessage,
  userSettingsState,
 }) => {
  const [AccountExistVisible, setAccountExistVisible] = useState(false);
  const [ServerMessageVisible, setServerMessageVisible] = useState(false);
  const [MessageFromServer, setMessageFromServer] = useState("");
useEffect(() => {
  // state.signupAlertMessage === "EmailExist" ? AccountExist(navigation) : null;
  // addErrorMessage("signupAlertMessage","");
    if(state.signupAlertMessage === "EmailExist")
    {
      setAccountExistVisible(true);
    }
    else if(state.signupAlertMessage?.length > 0)
    {
      const message = state.signupAlertMessage;
      setServerMessageVisible(true);
      setMessageFromServer(message);
    }
    addErrorMessage("signupAlertMessage","");
  }, [state.signupAlertMessage]);

  const hideAccountExistAlert = () => {
    setAccountExistVisible(false);
  };
  const hideServerMessageVisibleAlert = () => {
    setServerMessageVisible(false);
};
  return (
    <View>
    <CustomAlert
      visible={AccountExistVisible}
      onClosePress={hideAccountExistAlert}
      userSettingsState={userSettingsState}
      headermessage={i18n.t('auth.SignUp_AccountExistAlert_subject')}
      bodymessge={i18n.t('auth.SignUp_AccountExistAlert_message')}
      buttonmessage1={i18n.t('auth.SignUp_AccountExistAlert_logIn')}
      buttonmessage1onPress={() => {
        navigateToScreen(navigation, 'LoginFlow', `Signin`, { isEmail: true }, null);
        // navigation.navigate('LoginFlow', { screen: 'Signin', params: { isEmail: true } });
        // navigation.navigate('Signin',{ isEmail: true });
              }}
      buttonmessage2={i18n.t('auth.SignUp_AccountExistAlert_ForgotPassword')}
      buttonmessage2onPress={() => {
        navigateToScreen(navigation, 'LoginFlow', `ForgotPassword`, null, null);
        // navigation.navigate('LoginFlow', { screen: 'ForgotPassword'});
        // navigation.navigate('ForgotPassword');
              }}
    />
    <CustomAlert
        visible={ServerMessageVisible}
        onClosePress={hideServerMessageVisibleAlert}
        userSettingsState={userSettingsState}
        bodymessge={MessageFromServer}
        buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton')}
      />    
  </View>
  );
};
