import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const MaterialColorPickerContainer = ({ 
  userSettingsState, 
  foundItem,
  materialColor,
  setMaterialColor,
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [hoveredColor, setHoveredColor] = useState(null);

  const isHasMaterial = foundItem.IsSilver || foundItem.IsGold;

  useEffect(() =>{
    if(materialColor === null){
      if(foundItem.IsSilver){
        setMaterialColor('Silver');
      }
      else if(foundItem.IsGold){
        setMaterialColor('Gold');
      }
    }
    },[]);

  if(!isHasMaterial)
  {
    return null;
  }

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.text}>{i18n.t('store.MaterialColorPickerContainer_title')}:</Text>
      </View>

      {
        foundItem.IsSilver ?
        <View style={[styles.colorMainContainer, materialColor === 'Silver' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Silver')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, { backgroundColor: 'silver' }, 
        ]}
        activeOpacity={1}
        onPress={() => setMaterialColor('Silver')}
      >
        {hoveredColor === 'Silver' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.MaterialColorPickerContainer_Silver')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }

      {
        foundItem.IsGold ?
        <View style={[styles.colorMainContainer, materialColor === 'Gold' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Gold')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: Gold },
           // { backgroundColor: 'gold' },
        ]}
        activeOpacity={1}
        onPress={() => setMaterialColor('Gold')}
      >
        {hoveredColor === 'Gold' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.MaterialColorPickerContainer_Gold')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginBottom: 20,
  },
  colorWrapper: {
    padding: 2, // Add small padding between border and color
    borderRadius: 50, // Ensure borderRadius matches the border
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.iconColor,
    borderWidth: 1,
    marginTop: -2,
  },
  colorMainContainer: {
    marginLeft: !responsiveStyles.isRTL ? 20 : null,
    marginRight: responsiveStyles.isRTL ? 20 : null,
  },
  colorContainer: {
    width: 25,
    height: 25,
    borderRadius: 50, // Matches the wrapper's borderRadius
    backgroundColor: 'transparent', // Will be overridden dynamically
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  tooltip: {
    position: 'absolute',
    top: -40, // Position above the color container
    left: '50%',
    transform: [{ translateX: '-50%' }], // Corrected syntax
    padding: 5,
    backgroundColor: 'black',
    borderRadius: 5,
    zIndex: 1, // Ensure tooltip is above other content
  },
  tooltipText: {
    color: 'white',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default MaterialColorPickerContainer;
