import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles, Lavender } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { MaterialIcons } from '@expo/vector-icons';
import SelectDropdown from "react-native-select-dropdown";
import { languages } from "../../modules/StackNavigator/languages";
import { Feather } from '@expo/vector-icons';
import { headerHeight } from "../../constants/ScreenDimensions";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { SetUserSettings } from "../../api/apiCalls/AccountCalls";


  const LanguageComponent = ({ 
    userSettingsState, 
    // setUserSettings,
    setUserSettingsLanguage,
    languageContainerStyle = null,
    showText=true,
    showIcon = false,
    containerStyle,
 }) => {


  const responsiveStyles = useResponsiveStyles(userSettingsState);

    const LanguagesList = languages.map(language => language.userSettingsLanguage );

    const [locale, setLocale] = useState(responsiveStyles.Language.slice(0, 2));
    i18n.locale = locale;
    
    const dropdownRef = useRef(null);

    const getScrollPosition = () => {
      return {
        scrollTop: window.pageYOffset || document.documentElement.scrollTop,
        scrollLeft: window.pageXOffset || document.documentElement.scrollLeft
      };
    };
    const setTopOfDropDown = (newTop) => {
      try {
        

        // Select elements based on the class names
        let elements = document.querySelectorAll(".css-175oi2r.r-150rngu.r-eqz5dr.r-16y2uox.r-1wbh5a2.r-11yh6sk.r-1rnoaur.r-agouwx");
        
        if(!elements || elements.length === 0){
          elements = document.querySelectorAll(".css-view-175oi2r.r-WebkitOverflowScrolling-150rngu.r-flexDirection-eqz5dr.r-flexGrow-16y2uox.r-flexShrink-1wbh5a2.r-overflowX-11yh6sk.r-overflowY-1rnoaur.r-transform-agouwx");
        }
        if (elements.length > 0) {
          console.log(`elements.length is ${elements.length}`);
          
          // Get the last element
          const element = elements[elements.length - 1];
          const parent = element.parentElement;
    
          if (parent) {
            // Check if "WebkitOverflowScrolling" is present in the HTML content
            const position = getScrollPosition();
            const isScrolled = position.scrollTop > 0;
    
            console.log(`isScrolled is ${isScrolled}`);
            console.log(`parent is ${parent}`);
    
            const originalTop = getComputedStyle(parent).top;
            const originalTopValue = parseFloat(originalTop); // Convert to positive number
    
            const ParentTop = Math.abs(originalTopValue) + (responsiveStyles.screenHeight - newTop) - 110 - (isScrolled ? 155 : 0);
            console.log(`originalTop: ${originalTop}, 
              newTop is ${newTop}, 
              responsiveStyles.screenHeight: ${responsiveStyles.screenHeight},
              ParentTop: ${ParentTop}
              `);
    
            parent.style.top = 
            showIcon ? `${headerHeight}px` :
            ParentTop > responsiveStyles.screenHeight ? `${responsiveStyles.screenHeight - 215}px` : `${ParentTop}px`; // Ensure unit is included
          }
        }
      } catch (err) {
        console.log(`setTopOfDropDown err is ${err}`);
      }
    }
    

    const measureDropdown = () => {
      if(responsiveStyles.isWeb){
        console.log(`measureDropdown`);
        if (dropdownRef.current) {
          dropdownRef.current.measure((fx, fy, width, height, px, py) => {
            console.log(`Position on screen: X: ${px}, Y: ${py}, Width: ${width}, Height: ${height}`);
            setTopOfDropDown(py);
  
          });
        }
      }
    };

  const styles = styling(responsiveStyles, showText, showIcon);
  return <View style={[styles.settingsRow,
    responsiveStyles.isRTL ? { flexDirection:'row-reverse'} : { flexDirection:'row'},
    containerStyle,
  ]}>
    {showText ?
  <View style={[styles.settingsRowTextAndIcon,
  responsiveStyles.isRTL ? { flexDirection:'row-reverse'} : { flexDirection:'row'}
  ]}>
  <View style={[styles.settingsRowIcon, { backgroundColor:Lavender }]}>
<MaterialIcons name="language" size={20} color={GlobalStyles[responsiveStyles.ThemeValue].iconColorOpposite} style={{ padding:3 }} />
</View>
<Text style={[styles.settingsRowText,
  responsiveStyles.isRTL ? { paddingRight:7} : { paddingLeft:7}
  ]}>
    {i18n.t('account.MyAccount_LanguageText')}
  </Text>
  </View>
   : null}
  <View style={[{ flexDirection:'row'}, languageContainerStyle]} 
  ref={dropdownRef}
  // onLayout={(event) => {
  //   console.log(`event is `);
  //   console.log(event);
  //   if (dropdownRef.current) {
  //     measureDropdown();
  //   }
  // }}
  >
  <SelectDropdown
  data={LanguagesList}
  
  defaultValue={responsiveStyles.Language}
  value={responsiveStyles.Language}
  onFocus={() => {
    console.log(`onFocus`);
    measureDropdown();
  }}
  onSelect={async (selectedItem, index) => {
    console.log(`selectedItem ${selectedItem.slice(0, 2)}`);
    const localeLanguage = selectedItem.slice(0, 2);
    const newLocale = localeLanguage === 'he' ? 'he' : localeLanguage === "en" ? "en" : "en";
    console.log(`newLocale ${newLocale}`);

    await setUserSettingsLanguage(selectedItem);    
    await SetUserSettings({ 
      ...userSettingsState.userSettings
      , Language: selectedItem, 
      TextAlign: selectedItem === "he-IL" ? 'right' : 'left' 
    });
    setLocale(newLocale);
  }}
  buttonTextAfterSelection={(selectedItem, index) => {
    return selectedItem;
  }}
  rowTextForSelection={(item, index) => {
    // text represented for each item in dropdown
    // if data array is an array of objects then return item.property to represent item in dropdown

    return item;
  }}
  renderDropdownIcon={(isOpened) => {
    // if(showIcon){
    //   return null;
    // }
    return (
      <Feather
        name={isOpened ? "chevron-up" : "chevron-down"}
        color={"#000000"}
        size={24}
      />
    );
  }}
  buttonStyle={
    {
    flex: 1,
    height: 50,
    backgroundColor: 
    // showIcon ? 
    GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor 
    // : GlobalStyles[responsiveStyles.ThemeValue]?.inputBackgroundColor
    ,
    maxWidth:120,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#444',
    flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
  }
}
  buttonTextStyle={
    responsiveStyles.isRTL
    ? {color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor, textAlign: 'right'}
    : {color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor, textAlign: 'left'}
  }
  dropdownIconPosition={"right"}
  dropdownStyle={styles.dropdown2DropdownStyle}
  rowStyle={styles.dropdown2RowStyle}
  rowTextStyle={
    responsiveStyles.isRTL
    ? {color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor, textAlign: 'right'}
    : {color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor, textAlign: 'left'}
  }
/>
</View>
</View>
}
const styling = (responsiveStyles, showText, showIcon) => StyleSheet.create({
    settingsRow: {
        alignItems: 'center',
        marginHorizontal: 10,
        marginTop: 10,
        justifyContent:'space-between',
        // backgroundColor:'blue',
        paddingHorizontal: !showText ? null : 20,        
      },
      settingsRowTextAndIcon:{
      },
      settingsRowIcon:{
        borderRadius: 50,
        borderWidth: 2, 
        borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        
      },
      settingsRowText: {
        fontSize: responsiveStyles.fontSize_H4,
        fontWeight: '400',
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontFamily: GlobalStyles.fonts.application_font,
        
      },
      dropdown2DropdownStyle: {
        backgroundColor: 
        // showIcon ?
         GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor
        // : GlobalStyles[responsiveStyles.ThemeValue]?.inputBackgroundColor
        ,
      },
    dropdown2RowStyle: {
      backgroundColor: 
      // showIcon ?
      GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor
    //  : GlobalStyles[responsiveStyles.ThemeValue]?.inputBackgroundColor
     ,
      borderBottomColor: '#C5C5C5',
      paddingVertical: 10,
    },
});

export default LanguageComponent;