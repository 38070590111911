import { Asset } from 'expo-asset';
import { getAsset } from '../../../functions/getAsset';

export const Partner_User = {
    Id: "Partner",
    FirstName: "Partner",
    MiddleName: "",
    LastName: "",
    BirthDate: "1977-03-28",
    DeathDate: null,
    Relationship: 1,
    State: "Israel",
    City: "Tel Aviv",
    Description: "For the most important woman in my world ♡",
    UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
    BackgroundImage: getAsset(require('../../../../assets/images/store/profiles/partner/background_image.png')),
    // UserImage: "",
    // BackgroundImage: "",
  };

  
export const Partner_PlainUser = {
    UserName: "Partner",
    Like: 0,
    IsUserLiked: false,
    UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
    // UserImage: "",
  };