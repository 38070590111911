import categoryApi from './categoryApi';

const POST_TIMEOUT = 20000; // 20 seconds
const GET_TIMEOUT = 10000;  // 10 seconds

export const postAsync = async (url, obj, config = null, shouldReject = true) => {
  const request = categoryApi.post(url, obj, config);

  if (!shouldReject) {
    return request;
  }

  return await Promise.race([
    request,
    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), POST_TIMEOUT))
  ]);
};

export const post = (url, obj) => {
  return Promise.race([
    categoryApi.post(url, obj),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), POST_TIMEOUT))
  ]);
};

export const getAsync = async (url) => {
  return await Promise.race([
    categoryApi.get(url),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), GET_TIMEOUT))
  ]);
};
