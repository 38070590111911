import { defaultImage } from "../constants/images";

export const user = {
  FirstName: "Joe",
  MiddleName: "Middle",
  LastName: " Biden",
  BirthDate: "2000-04-01",
  DeathDate: "2170-04-01",
  Relationship: 2,
  State: "USA",
  City: "Ohio",
  Description: "You only live once, but if you do it right, once is enough.",
  BackgroundImage: defaultImage.uri,
  Image: defaultImage.uri,
};


  export const  admins = [
    {
      UserId: "",
      UserImage: "https://picsum.photos/200/300",
      UserName: "Admin",
      Joined: '11/04/2024',
      Role: 1, // 1 === Admin, 2 === Moderator, 3 === PendingUser
      IsInvitations: false,
    },
    {
      UserId: "",
      UserImage: "https://picsum.photos/200/300",
      UserName: "Moderator",
      Joined: '11/04/2024',
      Role: 2, // 1 === Admin, 2 === Moderator, 3 === PendingUser
      IsInvitations: false,
    },
    {
      UserImage: "https://picsum.photos/200/300",
      UserName: "Pending User",
      Joined: '11/04/2024',
      Role: 3, // 1 === Admin, 2 === Moderator, 3 === PendingUser
      IsInvitations: true,
    }
  ];
