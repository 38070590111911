import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import ImageProfile from "../images/ImageProfile";
import DateComponent from "../inputs/item/DateComponent";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { convertUTCtoLocal } from "../../functions/convertUTCtoLocal";
import { GenericAlert } from "../alert/GenericAlert";
import SelectDropdown from "react-native-select-dropdown";
import { Feather } from '@expo/vector-icons'; 
import { getPaddingScreen } from "../../functions/ScreenDimensionsLogic";
import BottomCardComponent from "../profileScreen/BottomCardComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const ManageHumanProfileForm = ({ 
    userSettingsState,
    inputs,
    setInputs,
    submitHandler,
    Id = null,
    isAdmin = false,
    DeleteUserProfile = null,
    navigation,
    onLayoutChange,
}) => {

    

    const responsiveStyles = useResponsiveStyles(userSettingsState);
const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);

const widthOfInput = 
!responsiveStyles.isMobile ? responsiveStyles.screenWidth - (paddingScreen * 2) 
: responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.6 :  responsiveStyles.screenWidth * 0.90;

const styles = styling(responsiveStyles, paddingScreen, isAdmin);

    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);

    const DeleteUserProfileHandler = async () => {
        if(DeleteUserProfile){
            const response = await DeleteUserProfile();
            if(response.isCompleted === true) {
                responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
            } else {
                setbodymessge(response.message);
                setGenericAlertVisible(true);
            }
            setDeleteAlertVisible(false);
        }
    }

    const RelationshipList = i18n.t('RelationshipList');
    console.log(`convertUTCtoLocal(inputs.DeathDate.value) ${new Date(inputs.DeathDate.value)}`);
    
    const [isLoading, setIsLoading] = useState(false);
    const isEditing = !!Id;
    const editable = isAdmin || !isEditing;
    function inputChangedHandler(inputIdentifier, enteredValue, isChanged) {
        setInputs((curInputs) => ({
            ...curInputs,
            [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        }));
    }

    const RelationshipListData = RelationshipList.map(item => item.Value);

    

    return (
        <View>
            
            <View style={styles.titleContainer}>
                <Text style={styles.title}>{isEditing ? i18n.t('account.MyAccount_ManageProfileForm_Edit') : i18n.t('account.MyAccount_ManageProfileForm_Create')} {i18n.t('account.MyAccount_ManageProfileForm_NewProfile')}</Text>
            </View>

            
        <View style={styles.container}>
            <GenericAlert
            userSettingsState={userSettingsState}
                navigation={navigation}
                bodymessge={bodymessge}
                visible={GenericAlertVisible}
                setVisible={setGenericAlertVisible}
            />
            <GenericAlert
            userSettingsState={userSettingsState}
                bodymessge={i18n.t('account.buttonmessage1DeleteAlert')}
                buttonmessage1={i18n.t('account.buttonmessage1Yes')}
                buttonmessage2={i18n.t('account.buttonmessage2No')}
                visible={deleteAlertVisible}
                setVisible={setDeleteAlertVisible}
                buttonmessage1onPress={DeleteUserProfileHandler}
            />
<TouchableOpacity style={styles.backgroundImageContainer}
            activeOpacity={editable ? 0.25 : 1}
            >
                <ImageProfile
                userSettingsState={userSettingsState}
                    uri={inputs.BackgroundImage.value}
                    imageStyle={styles.backgroundImage}
                    isBackgroundImage={true}
                    editable={editable}
                    isGroup={false}
                    showEditButton={true}
                    onImagePick={(data) => {
                        if(editable){
                            inputChangedHandler("BackgroundImage", data, true);
                        }
                    }}

                    isWithCrop={true}
                    // resizeMode={"contain"}
                />
            </TouchableOpacity>

            <View style={styles.imageContainer}
            activeOpacity={editable ? 0.25 : 1}
            >
                <ImageProfile
                userSettingsState={userSettingsState}
                    uri={inputs.Image.value}
                    imageStyle={styles.userPhoto}
                    editable={editable}
                    showEditButton={true}
                    onImagePick={(data) => {
                        if(editable){
                        inputChangedHandler("Image", data, true);
                        }
                    }}
                    isWithCrop={true}
                    isCircle={true}
                />
            </View>
            <View>
            {
                !isEditing ? null :
                isAdmin ?
                <Text style={styles.noteText}>{`${i18n.t('account.MyAccount_ManageProfileForm_NoteText')}`}</Text>
                : <Text style={styles.noteText}>{`${i18n.t('account.MyAccount_ManageProfileForm_NoteForNonAdmin')}`}</Text>
            }
            </View>
            
            <View style={styles.NameContainer}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('FirstName', event.nativeEvent.layout.y);
                          onLayoutChange('LastName', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.FirstName.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("FirstName", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManageProfileForm_FirstName')} *`}
                errorMessage={i18n.t('account.MyAccount_ManageProfileForm_FirstName_ErrorMessage')}
                isRequired={true}
                isValid={inputs.FirstName.isValid}
                styleTextInputContainer={styles.textInputName}
                
            />

            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.MiddleName.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("MiddleName", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManageProfileForm_MiddleName')}`}
                errorMessage={i18n.t('account.MyAccount_ManageProfileForm_MiddleName_ErrorMessage')}
                styleTextInputContainer={styles.textInputName}
            />

            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.LastName.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("LastName", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManageProfileForm_LastName')} *`}
                errorMessage={i18n.t('account.MyAccount_ManageProfileForm_LastName_ErrorMessage')}
                isRequired={true}
                isValid={inputs.LastName.isValid}
                styleTextInputContainer={styles.textInputName}
            />
            </View>
            <View style={[styles.subtitleContainer, styles.firstSubtitleContainer]}>
                <Text style={styles.subtitle}>{i18n.t('account.MyAccount_ManageProfileForm_Relationship')}</Text>
            </View>
            <View style={styles.selectDropdown}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Relationship', event.nativeEvent.layout.y);
                        }
                      }}
            >
                {/* <Text style={styles.relationshipText}>{`${i18n.t('account.MyAccount_ManageProfileForm_Relationship')}`}</Text> */}
                <SelectDropdown
                disabled={!editable}
                    data={RelationshipListData}
                    selectedItem={RelationshipListData[inputs.Relationship.value - 1]}
                    defaultValue={RelationshipListData[inputs.Relationship.value - 1]}
                    onSelect={(selectedItem, index) => {
                        if(editable){   
                            const RelationshipItem = RelationshipList[index];
                            inputChangedHandler("Relationship", RelationshipItem.Id, true);
                        }
                    }}
                    defaultButtonText={i18n.t('account.MyAccount_ManageProfileForm_RelationshipOptions')}
                    buttonTextAfterSelection={(selectedItem, index) => {
                        return selectedItem;
                    }}
                    renderDropdownIcon={(isOpened) => {
                        return (
                            <Feather
                                name={isOpened ? "chevron-up" : "chevron-down"}
                                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
                                size={24}
                                style={{ marginHorizontal: 10 }}
                            />
                        );
                    }}
                    buttonStyle={{
                        flex: 1,
                        // height: 60,
                        minHeight:responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
                        maxHeight:responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65,
                        // backgroundColor: isAdmin ? GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor : "gray",
                        backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
                        borderRadius: 25,
                        borderWidth: 1,
                        borderColor: GlobalStyles[responsiveStyles.ThemeValue].inputBorderColor,
                        flexDirection: responsiveStyles.Language === 'he-IL' ? 'row' : 'row-reverse',
                        width: widthOfInput
                    }}
                    buttonTextStyle={{
                        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
                        textAlign: responsiveStyles.Language === 'he-IL' ? 'right' : 'left',
                        height: !responsiveStyles.isMobile ? 60 : null,
                        paddingTop: !responsiveStyles.isMobile ? 17.5 : null,

                    }}
                    dropdownIconPosition={"right"}
                    dropdownStyle={styles.dropdown2DropdownStyle}
                    rowStyle={styles.dropdown2RowStyle}
                    rowTextStyle={{
                        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
                        textAlign: responsiveStyles.Language === 'he-IL' ? 'right' : 'left'
                    }}
                />
                {!inputs.Relationship.isValid && (
                    <ErrorComponentMessageError 
                        userSettingsState={userSettingsState} 
                        errorMessage={i18n.t('account.MyAccount_ManageProfileForm_Relationship_ErrorMessage')}
                    />
                )}
            </View>

            <View style={styles.subtitleContainer}>
                <Text style={styles.subtitle}>{i18n.t('account.MyAccount_ManageProfileForm_Lifetime')}</Text>
            </View>

            <View style={styles.dateContainer}
            onLayout={(event) => {
                if(onLayoutChange){
                  console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                  console.log(event.nativeEvent);
                  onLayoutChange('BirthDate', event.nativeEvent.layout.y);
                }
              }}
            >

            <View>
            <DateComponent
                onChangeFieldValue={(itemId, value) => {
                    if(editable){   
                        inputChangedHandler("BirthDate", value, true);
                    }
                }}
                editable={editable}
                itemId={0}
                initValue={inputs.BirthDate.value}
                item={{
                    fieldName: `${i18n.t('account.MyAccount_ManageProfileForm_BirthDate')} *`
                }}
                userSettingsState={userSettingsState}
                styleContainer={[styles.dateStyle, !responsiveStyles.isMobile ? { alignSelf: 'flex-start' } : null]}

            />

            {!inputs.BirthDate.isValid && (
                <ErrorComponentMessageError 
                    userSettingsState={userSettingsState} 
                    errorMessage={i18n.t('account.MyAccount_ManageProfileForm_BirthDate_ErrorMessage')}
                />
            )}

            </View>

            <DateComponent
                onChangeFieldValue={(itemId, value) => {
                    if(editable){   
                        inputChangedHandler("DeathDate", value, true);
                    }
                }}
                editable={editable}
                itemId={0}
                initValue={inputs.DeathDate.value}
                item={{
                    fieldName: `${i18n.t('account.MyAccount_ManageProfileForm_DeathDate')}`
                }}
                userSettingsState={userSettingsState}
                styleContainer={[styles.dateStyle, !responsiveStyles.isMobile ? { alignSelf: responsiveStyles.isRTL ? 'flex-start' : 'flex-end' } : null]}
            />
            </View>

            <View style={styles.subtitleContainer}>
                <Text style={styles.subtitle}>{i18n.t('account.MyAccount_ManageProfileForm_LocationDetails')}</Text>
            </View>

            <View style={styles.LocationContainer}>
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.State.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("State", value, true);
                    }
                }}
                editable={editable}
                label={i18n.t('account.MyAccount_ManageProfileForm_State')}
                styleTextInputContainer={styles.textInputLocation}
            />

            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.City.value}
                setValue={(value) => {
                    if(editable){
                        inputChangedHandler("City", value, true);
                    }
                }}
                editable={editable}
                label={i18n.t('account.MyAccount_ManageProfileForm_City')}
                styleTextInputContainer={styles.textInputLocation}
            />
            </View>
            <View style={styles.subtitleContainer}>
                <Text style={styles.subtitle}>{i18n.t('account.MyAccount_ManageProfileForm_Quote')}</Text>
            </View>
            <View style={styles.QuoteContainer}>
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.Description.value}
                setValue={(value) => {
                    if(editable){
                        inputChangedHandler("Description", value, true);
                    }
                }}
                editable={editable}
                label={i18n.t('account.MyAccount_ManageProfileForm_QuoteText')}
                styleTextInputContainer={styles.textInputLocation}
            />
            </View>

            <Text style={styles.text}>{i18n.t('account.MyAccount_ManageProfileForm_Example1')} {i18n.t('account.MyAccount_ManageProfileForm_Example2')}</Text>
            <Text style={[styles.text, { marginBottom: 20 }]}>{i18n.t('account.MyAccount_ManageProfileForm_Example3')}</Text>

            {editable ? 
        <>
            <View style={styles.buttonContainer}>
                <ButtonTypeButton
                userSettingsState={userSettingsState}
                    style={styles.button}
                    loading={isLoading}
                    onPress={async () => {
                        setIsLoading(true);
                        await submitHandler();
                        setIsLoading(false);
                    }}
                >
                    {i18n.t('account.MyAccount_ManageProfileForm_Save')}
                </ButtonTypeButton>
            </View>
            { Id && 
                <TouchableOpacity 
                    style={styles.deleteContainer}
                    onPress={() => setDeleteAlertVisible(true)}
                >
                    <Feather name="trash-2" size={36} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
                </TouchableOpacity>
            }
        </>
         : null }

        </View>
        </View>
    );
}

const styling = (responsiveStyles, paddingScreen, isAdmin) => StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal: paddingScreen,
        // padding: 10,
      },
    text: {
        fontFamily: GlobalStyles.fonts.application_font,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
    },
    titleContainer: {
        paddingBottom: 20,
        marginVertical: !responsiveStyles.isMobile ? 20 : 10,
        borderBottomWidth: 1,
        borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
        marginHorizontal: paddingScreen,
    },
    title: {
        fontSize: responsiveStyles.fontSize_H1,
        fontWeight: '700',
        fontFamily: GlobalStyles.fonts.application_font,
        textAlign: 'center',
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
    selectDropdown: {
        paddingTop: 10,
        // paddingBottom: 20,
        // marginBottom: 20,

    },
    firstSubtitleContainer: {
        paddingTop: !responsiveStyles.isMobile ? 20 : 10,
    },
    subtitleContainer: {
        paddingTop: !responsiveStyles.isMobile ? 40 : 20,
        paddingBottom: 20,
        // marginBottom: 20,
        // borderBottomWidth: 2,
        borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
    subtitle: {
        fontSize: responsiveStyles.fontSize_H4,
        fontWeight: '500',
        fontFamily: GlobalStyles.fonts.application_font,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
    NameContainer: {
        flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
        justifyContent: !responsiveStyles.isMobile ?  'space-between' : null,
    },
    textInputName: {
        width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth - paddingScreen) * 0.22 : null,
        // backgroundColor: isAdmin ? null : 'gray',
    },
    dateContainer: {
        flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
        // justifyContent: !responsiveStyles.isMobile ?  'space-evenly' : null,
        marginHorizontal: !responsiveStyles.isMobile ? -10 : null,
    },
    dateStyle: {
        width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth - paddingScreen) * 0.35 : '100%',
        // backgroundColor: isAdmin ? null : 'gray',
    },
    LocationContainer: {
        flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
        justifyContent: !responsiveStyles.isMobile ?  'space-between' : null,
    },
    QuoteContainer: {
        alignItems: !responsiveStyles.isMobile ? !responsiveStyles.isRTL ? 'flex-start' : 'flex-end' : null,
    },
    textInputLocation: {
        width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth - paddingScreen) * 0.35 : null,
        // backgroundColor: isAdmin ? null : 'gray',
    },
    buttonContainer: {
        marginBottom: 20,
        marginTop: 20,
        paddingTop: 20,
        alignItems: 'center',
    },
    button: {
        width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.8, // Use wp function here
        // width: responsiveStyles.screenWidth * 0.8,
    },
    userPhoto: {
        // width: !responsiveStyles.isMobile ? 200 : 150,
        // height: !responsiveStyles.isMobile ? 200 : 150,
        width: responsiveStyles.screenWidth > 1200 ? 200 : 150,
        height: responsiveStyles.screenWidth > 1200 ? 200 : 150,
        borderRadius: 300,
        // borderWidth: 4,
        // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        marginBottom: 10,
    },
    imageContainer: {
        alignItems: 'center',
        // paddingTop: !responsiveStyles.isMobile ? 130 :  90,  // old

        // marginTop: responsiveStyles.screenWidth > 1200 ? 400 - 100 : !responsiveStyles.isWeb ?  150 - 70 : (responsiveStyles.screenWidth / 3) - 70,  // if backgroundImageContainer is outside styles.container
        marginTop: responsiveStyles.screenWidth > 1200 ? -100 : !responsiveStyles.isWeb ?  -50 : -70,
        marginHorizontal: paddingScreen,
    },
    backgroundImageContainer: {

        // position: 'absolute',  // if backgroundImageContainer is outside styles.container
        // top: !responsiveStyles.isMobile ? 120 : 150 - 60,  // if backgroundImageContainer is outside styles.container

        // top: !responsiveStyles.isMobile ? 120 : 70, // old
        // right: !responsiveStyles.isMobile ? 0 : null,
        
    },
    backgroundImage: {
        // height: responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth > 768 ? 350 : 150,
        // aspectRatio: !responsiveStyles.isMobile ? 288 / 100 : null,
        // width: !responsiveStyles.isMobile ? null : responsiveStyles.screenWidth,

        height: responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth / 3,
        minHeight: 150,
        width: responsiveStyles.screenWidth,
        maxWidth: 1200,
        // marginLeft: responsiveStyles.screenWidth > 1200 ? (responsiveStyles.screenWidth - 1200) / 2 : null, // if backgroundImageContainer is outside styles.container
        alignSelf: 'center',
        // backgroundColor:'rgba(0, 0, 0, 0.3)',

         // old
        // width: responsiveStyles.screenWidth,
        // height: !responsiveStyles.isMobile ? 200 : 150,
        // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        // // marginLeft: !responsiveStyles.isRTL ? -paddingScreen : null,
        // // marginRight: responsiveStyles.isRTL ? -paddingScreen : null,
        // marginLeft: -paddingScreen,
    },
    noteText: {
        paddingTop: !responsiveStyles.isMobile ? 20 : 20,
        paddingBottom: !responsiveStyles.isMobile ? 40 : 30,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
    },
    relationshipText: {
        paddingBottom: 10,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
    },
    dropdown2DropdownStyle: {
        // backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.inputBackgroundColor,
        backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    dropdown2RowStyle: {
        // backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.inputBackgroundColor,
        backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        borderBottomColor: '#C5C5C5',

    },

    deleteContainer: {
        alignItems: 'center',
        paddingBottom: responsiveStyles.isWeb ? 20 : 0,
    },
});

export default ManageHumanProfileForm;
