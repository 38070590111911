import { useEffect, useContext, useState, useCallback } from 'react';
import { StyleSheet,View,StatusBar, Button, TouchableOpacity, Text, Appearance, Platform } from "react-native";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as UserSettingsContext } from "../context/UserSettingsContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from '../hooks/useI18n';
import { AntDesign } from '@expo/vector-icons';
import LoadingScreen from '../components/LoadingScreen';
import {  getItem } from '../modules/Storage';
import { GlobalStyles } from '../constants/styles';
// import * as Notifications from 'expo-notifications';
import { SendNotificationToken } from '../api/UserApiCalls';
import { FinishInitSync, deviceId } from '../constants/StaticData';
import { DarkMode_Storage, ExpoToken_Storage, token_Storage } from '../modules/StorageObjects';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useNavigate } from 'react-router-dom';

const ResolveAuthScreen = ({}) => {
  const navigation = Platform.OS === "web" ? useNavigate() : useNavigation();
  const { tryLocalSignin } = useContext(AuthContext);
  const { state: userSettingsState, setUserSettingsDarkMode, setDefaultSettings } = useContext(UserSettingsContext);

  const [reloadpage, setReloadPage] = useState(false);
  const [DarkMode, setDarkMode] = useState(null);
  const [showLoadingDataMessage, setShowLoadingDataMessage] = useState(false);
  const [errorOnInit, setErrorOnInit] = useState(false);

  const styles = styling(DarkMode);
  async function prepare() {
    await setDefaultSettings();
    await tryLocalSignin(navigation);
  }
  if(Platform.OS === "web"){
  }
  else{
    useFocusEffect(
      useCallback(() => {
        console.log(`useFocusEffect in ResolveAuthScreen`);
        prepare();
        return () => {
        };
      }, [])
    );
  }
  useEffect(() => {
    console.log(`useEffect in ResolveAuthScreen`);

    async function getThemeValue() {
        const isDarkMode = await getItem(DarkMode_Storage);
        console.log(`getThemeValue ${isDarkMode}`);
        if(isDarkMode === null || isDarkMode === undefined)
        {
          const colorScheme = Appearance.getColorScheme();
          const isDeviceDarkMode = colorScheme === "dark" ? true : false;
          setDarkMode(isDeviceDarkMode === true ? 'dark' : 'light');
        }
        else{
          setUserSettingsDarkMode(isDarkMode);
          setDarkMode(isDarkMode === true ? 'dark' : 'light');
        }
    }
    if(DarkMode === null)
    {
      getThemeValue();
    }
    else{
      console.log(`entering prepare, DarkMode ${DarkMode}`);
      prepare();
    }
    registerForPushNotifications();
  }, [reloadpage,DarkMode]);


  const registerForPushNotifications = async () => {
    return;
    // try {
    //   const { status } = await Notifications.getPermissionsAsync();

    //   if (status !== 'granted') {
    //     const { granted } = await Notifications.requestPermissionsAsync();

    //     if (!granted) {
    //       console.log('Permission to receive push notifications denied');
    //       return;
    //     }
    //   }
    //   else{
    //     console.log(`Permission to receive push notifications granted, status: ${status}`);

    //   }
    //   const tokenData = await Notifications.getExpoPushTokenAsync();
    //   const ExpoToken = tokenData.data;
    //   // Now you can send the token to your server
    //   console.log('Expo Push Token:', ExpoToken);

    // let ExpoTokenFromAsyncStorage = await AsyncStorage.getItem(ExpoToken_Storage);
    // if(!ExpoTokenFromAsyncStorage || ExpoTokenFromAsyncStorage !== ExpoToken){
    //   console.log('Device ID:', deviceId);
    //   await SendNotificationToken(ExpoToken, deviceId);
    // }
    // } catch (error) {
    //   console.error('Error registering for push notifications:', error);
    // }
  };


  return <View style={styles.container}>
          <StatusBar
        animated={true}
        backgroundColor={DarkMode === 'dark' ? 'black' : 'white'}
        barStyle={DarkMode === 'dark' ? 'light-content' : 'dark-content'}
      />
    {errorOnInit === false ?
     <LoadingScreen 
    userSettingsState={{
      userSettings: {
        ThemeValue: DarkMode
      }
    }}
    // ThemeValue={DarkMode} 
    message={!showLoadingDataMessage ? null : i18n.t('auth.ResolveAuth_loadingData')}
    /> : 
    <View style={styles.errorContainer}>
      <TouchableOpacity style={styles.loaderView} onPress={() => {
        setErrorOnInit(false);
        setReloadPage(!reloadpage);
      }}>
      <AntDesign name="reload1" size={32} color={GlobalStyles[DarkMode]?.iconColor} style={styles.loader} />
      </TouchableOpacity>
    <Text style={styles.loaderText}>{i18n.t('error.error_loader1')}</Text>
    <Text style={styles.loaderText}>{i18n.t('error.error_loader2')}</Text>
    </View>
    }
  </View>;
};
const styling = ThemeValue => StyleSheet.create({
  container:{
    backgroundColor:GlobalStyles[ThemeValue]?.primaryBackgroundColor,
    flex:1, 
    justifyContent:'center', 
    alignItems:'center', 
  },
  errorContainer: {
    flex: 1,
   justifyContent: 'center',
  },
  loaderView: {
    alignItems:'center',
    marginVertical: 10,
  },
  loader: {
    borderWidth:1,
    borderRadius: 25,
    padding: 8,
  },
  loaderText: {
    textAlign: 'center',
    color: GlobalStyles[ThemeValue]?.inputColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});
export default ResolveAuthScreen;