import React, { useState } from 'react';
import { StyleSheet,View,Text, Keyboard, Alert, Dimensions, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import ButtonTypeText from '../inputs/ButtonTypeText';
import { GlobalStyles } from '../../constants/styles';
import i18n from '../../hooks/useI18n';
import PopUp from '../modals/PopUp';
import { GenericAlert } from '../alert/GenericAlert';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';

const SignUpConfirmEmailCodeForm = ({ 
  // showLoader,
  // setShowLoader,
  emailConfirmationErrorMessage,
  SignUpConfirmEmailCode, 
  ResentEmailCode, 
  resentEmailCodeshowLoader,
  email, 
  addErrorMessage,
  ThemeValue = 'light',
  isRTL,
  signUpConfirmEmailCodeModalVisible,
  setSignUpConfirmEmailCodeModalVisible,
  isKeyboardVisible,
  keyboardDidShowTxt,
  navigation,
  userSettingsState,
}) => {

  
  const responsiveStyles = useResponsiveStyles(userSettingsState);

    const styles = styling(responsiveStyles);

  const [showLoader, setShowLoader] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');
    if(emailConfirmationErrorMessage !== "" && showLoader === true)
    {
      setShowLoader(false); // brings an error
    }
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
  return (
    <View style={styles.container}>
            <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
        <Text style={styles.header}>{i18n.t('auth.SignUpConfirmEmailCode_header')}</Text>
        <View style={{ paddingBottom: 20 }}>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.SignUpConfirmEmailCode_headerDescription_1')}
        </Text>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.SignUpConfirmEmailCode_headerDescription_2')} {email}
        </Text>
        </View>
        <View style={styles.textInputContainer}>
        <TextInputCustom
        userSettingsState={userSettingsState}
       editable={true}
       value={confirmationCode}
       onChangeText={(text) => {
        if(emailConfirmationErrorMessage?.length > 0)
        {
          addErrorMessage("emailConfirmation","");
        }
        setConfirmationCode(text);
      }}
      label={i18n.t('auth.SignUpConfirmEmailCode_confirmationCode')}
      style={[styles.textInput, !emailConfirmationErrorMessage ? { marginBottom: 15 } : null]}
      iconType="cancel"
      isKeyboardVisible={isKeyboardVisible}
      keyboardDidShowTxt={keyboardDidShowTxt}
      keyboardType='numeric'
      isValid={!emailConfirmationErrorMessage}
      />
      {emailConfirmationErrorMessage ? (
        <Text style={[GlobalStyles.errorMessage,{ marginBottom: 15 }]}>{emailConfirmationErrorMessage}</Text>
      ) : null}
      </View>
      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {
          console.log("confirmationCode " + confirmationCode);
          if(confirmationCode?.length === 0)
          {
            addErrorMessage("emailConfirmation",i18n.t('auth.SignUpConfirmEmailCodeAlert_CodeRequired'));
          }
          else if(confirmationCode?.length > 6)
          {
            addErrorMessage("emailConfirmation",i18n.t('auth.SignUpConfirmEmailCodeAlert_CodeTooLong'));
          }
          else if(confirmationCode?.length < 6)
          {
            addErrorMessage("emailConfirmation",i18n.t('auth.SignUpConfirmEmailCodeAlert_CodeTooShort'));
          }
          else{
            setShowLoader(true);
            Keyboard.dismiss();
            const response = await SignUpConfirmEmailCode({ email, code: confirmationCode });
            setShowLoader(false);
            if(response.isCompleted === true)
            {
              navigateToScreen(navigation, 'LoginFlow', `SignUpConfirmUserNameAndPasswordByEmail`, { email }, null);
              // navigation.navigate('LoginFlow', { screen: 'SignUpConfirmUserNameAndPasswordByEmail', params:  { email: email } });
              // navigation.navigate('SignUpConfirmUserNameAndPasswordByEmail', { email: email });
            }
          }
        }}
        styleButton={[styles.buttonStyle, { marginBottom: 20 }]}
        loading={showLoader}
        >
        {i18n.t('auth.SignUpConfirmEmailCode_submitButton')}
          </ButtonTypeButton>
          <ButtonTypeText
        onPress={async () =>{
          const response = await ResentEmailCode({ email });
          if(response.isCompleted === true)
          {
          console.log(`response.isCompleted is true ${response.isCompleted}`);
          await setSignUpConfirmEmailCodeModalVisible({ message: true });
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
          // Alert.alert(null,response.message, [
          //   {
          //     text: i18n.t('error.ok'),
          //   },
          // ],
          // {cancelable:true}
          // );
        }
         } }
        // styleButton={{backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor}}
        loading={resentEmailCodeshowLoader}
        style={{marginTop: 10}}
        userSettingsState={userSettingsState}
        >
        {i18n.t('auth.SignUpConfirmEmailCode_didntGetCode')}
          </ButtonTypeText>
        </View>
          <PopUp
          text={i18n.t('auth.CodeSuccess')}
          modalVisible={signUpConfirmEmailCodeModalVisible}
          setModalVisible={setSignUpConfirmEmailCodeModalVisible}
          showCancelButton={false}
          cancelModalTimer={true}
          userSettingsState={userSettingsState}

          />
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  textInputContainer: {
    // flex: !responsiveStyles.isMobile ? null : 1,
    marginBottom: responsiveStyles.isWeb ? 15 : 50

  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    fontWeight:'bold',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:20,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    paddingBottom: 10,
    
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H5,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    marginBottom: 5,
  },
  btnContainer: {
    // marginHorizontal:15
    alignSelf:'center',
  },
  textInput:{
    height: 40,
    borderWidth: 1,
    padding: 10,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    // marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  // buttonStyle: {
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor, 
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
  //   alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  //   marginBottom: !responsiveStyles.isMobile ? 10 : null,
  // },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default SignUpConfirmEmailCodeForm;
