import React from "react";
import { StyleSheet, View, Text, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import BulletText from "../inputs/BulletText";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import RatingAndPurchaseContainer from "./RatingAndPurchaseContainer";

const DescriptionContainer = ({ 
  userSettingsState, 
  foundItem,
  quantity,
  setQuantity,
  openDialog,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  return (
    <View style={styles.container}>
      <View style={[styles.itemNameContainer, styles.borderBottomStyle]}>
      <Text style={[styles.descriptionText]}>{i18n.t('store.ItemDetailsScreen_Description_text1')}</Text>
      <RatingAndPurchaseContainer
        foundItem={foundItem} 
        userSettingsState={userSettingsState}
        openDialog={openDialog}
        setQuantity={setQuantity}
        quantity={quantity}
         />
      <Text style={[styles.text]}>{i18n.t('store.ItemDetailsScreen_Description_text2')}</Text>
    </View>
    <View style={[styles.borderBottomStyle]}>
      <BulletText userSettingsState={userSettingsState} text={i18n.t('store.ItemDetailsScreen_DescriptionContainer_text1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('store.ItemDetailsScreen_DescriptionContainer_text3')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('store.ItemDetailsScreen_DescriptionContainer_text2')} />
      </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // marginBottom: 20,
  },
  bulletContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  bullet: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  bulletText: {
    flex: 1,
    lineHeight: responsiveStyles.fontSize_H5,
    paddingLeft: 5, // adjust padding as needed to align with the bullet point
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  itemNameContainer: {
  },
  borderBottomStyle: {
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  descriptionText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingBottom: 10,
    fontWeight: '600',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default DescriptionContainer;
