export default {
    Title: "Who We Are",
    Section1_1: "Welcome to ",
    Section1_2: "Timeless Memories jewelry",
    Section1_3: ", where innovation meets elegance. We are pioneering a brand new concept that beautifully combines the timeless allure of jewelry with the cutting-edge technology of QR codes. At TMJ, we believe that your jewelry should do more than just adorn; it should tell a story.",
    Section2_1: "Our unique pieces allow ",
    Section2_2: "you",
    Section2_3: " to share your greatest memories with your loved ones in a modern and interactive way. Each piece of TMJ jewelry is embedded with a custom QR code that, when scanned, opens a personalized digital gallery. This gallery can showcase a collection of your favorite photos, videos, and messages, creating a deeply personal and meaningful experience.",
    OurMission: "Our Mission",
    Section3_1: "At TMJ, our mission is to revolutionize the way you connect with your cherished moments. We strive to create jewelry that is not only beautiful and fashionable but also rich with personal significance. By merging technology with traditional craftsmanship, we aim to offer a unique product that captures and shares the essence of your most treasured memories.",
    OurStory: "Out Story",
    Section4_1: "TMJ was born from the idea that every piece of jewelry should be as unique as the memories it represents. Our founder, [Your Name], envisioned a line of jewelry that could bridge the gap between the physical and digital worlds, making it easier than ever to share special moments with friends and family. Inspired by the potential of QR technology, we set out to create a collection that would bring this vision to life.",
    OurCraftsmanship: "Our Craftsmanship",
    Section5_1: "Each TMJ piece is meticulously crafted with the highest quality materials to ensure both beauty and durability. We work with skilled artisans who bring our designs to life, paying close attention to every detail. The result is a stunning piece of jewelry that also functions as a portal to your personal gallery.",
    WhyChooseUs: "Why Choose TMJ?",
    WhyChooseUs_1_Title: "Innovative Design: ",
    WhyChooseUs_1_Description: "Our jewelry combines classic elegance with modern technology.",
    WhyChooseUs_2_Title: "Personal Connection: ",
    WhyChooseUs_2_Description: "Easily share your favorite moments with a simple scan.",
    WhyChooseUs_3_Title: "High Quality: ",
    WhyChooseUs_3_Description: "We use only the finest materials and craftsmanship.",
    WhyChooseUs_4_Title: "Unique Gift: ",
    WhyChooseUs_4_Description: "Perfect for any occasion, from birthdays to anniversaries.",
    Section6_1: "Join us on this journey of blending tradition with innovation. Let TMJ be the canvas for your memories, making each moment timeless and shareable.",
  };