import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import QuantityStoreItemComponent from "../store/QuantityStoreItemComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const PriceComponent = ({ 
    userSettingsState, 
    foundItem,
    quantity,
    setQuantity,
  }) => {

    const isWithDiscount = foundItem.Discount > 0;
    const realPrice = foundItem.Price;
    const afterDiscount = isWithDiscount ? (foundItem.Price * (100 - foundItem.Discount) / 100).toFixed(2) : realPrice;
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.itemDetailsContainer}>

    <View style={[styles.priceContainer]}>
    <Text style={[styles.text, styles.itemDetails,isWithDiscount ? styles.itemBeforeDiscount : null]}>{realPrice} ₪</Text>
    { isWithDiscount ? 
    <Text style={[styles.text, styles.itemDetails, styles.itemAfterDiscount]}>{afterDiscount} ₪</Text>
    : null }

    </View>

    <QuantityStoreItemComponent 
    userSettingsState={userSettingsState} 
    quantity={quantity} 
    setQuantity={setQuantity}
    styleContainer={{ justifyContent:'flex-start' }}
    />

    </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    itemDetailsContainer: {
        flexDirection: responsiveStyles.isRTL ? "row-reverse" : 'row',        
        marginTop: 10,
        marginBottom: 20,
      },
      priceContainer: {
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
        justifyContent:'flex-start',
      },
      text: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
        },
      itemDetails: {
        fontSize: responsiveStyles.fontSize_H5,
        fontWeight: 'bold',
        fontFamily: GlobalStyles.fonts.application_font,
    },
    itemBeforeDiscount: {
      textDecorationLine: 'line-through',
      fontSize: responsiveStyles.fontSize_H4,
      fontFamily: GlobalStyles.fonts.application_font,

    },
    itemAfterDiscount: {
      paddingHorizontal: 5,
      color: GlobalStyles.colors.priceAfterDiscountColor,
      fontSize: responsiveStyles.fontSize_H4,
      fontFamily: GlobalStyles.fonts.application_font,
    }
});

export default PriceComponent;