import { Asset } from "expo-asset";
import { getAsset } from "../../../functions/getAsset";
export const Pet_Media = [
    {
        Id: -30001,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "Just another day with our beloved sunny",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo1.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300001,
                PostId: -30001,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo1.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30002,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "A day at the beach",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo2.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300002,
                PostId: -30002,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo2.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30003,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "Running",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo3.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300003,
                PostId: -30003,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo3.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30004,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "A ride to the park",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo4.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300004,
                PostId: -30004,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo4.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30005,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "sunny has a birthday!",
        Description: "our beloved sunny celebrated his 5th birthday!!",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo5.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300005,
                PostId: -30005,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo5.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30006,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "Mountains",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo6.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300006,
                PostId: -30006,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo6.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30007,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "Lazy sunny",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo7.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300007,
                PostId: -30007,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo7.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
    {
        Id: -30008,

        UserImage: getAsset(require('../../../../assets/images/store/profiles/pet/main_image.png')),
        // UserImage: "",
        UserName: "Pet",
        UserId: null,
        UserProfileId: null,

        Title: "Just having fun",
        Description: "",

        FileCount: 1,
        FirstFileURI: getAsset(require('../../../../assets/images/store/profiles/pet/photo8.png')),
        // FirstFileURI: "",
        FirstFileType: "image",
        album: [
            {
                Id: -300008,
                PostId: -30008,
                MediaType: 'image',
                URL: getAsset(require('../../../../assets/images/store/profiles/pet/photo8.png')),
                // URL: "",
                Duration: null,
            },
        ],

        Comments: [],
        Likes: 0,
        IsActive: true,
        CreatedDate: "2024-07-30",
    },
  
  ];