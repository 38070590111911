import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image, FlatList, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { imageWidth } from "../../constants/imageWidth";
import MediaEmptyListComponent from "./MediaEmptyListComponent";
import ImageProfile from "../images/ImageProfile";
import ImageMedia from "../images/ImageMedia";
import { navigateToScreen } from "../../navigationResponsive";
import { getPaddingScreen,  } from "../../functions/ScreenDimensionsLogic";
import { getImageWidth, getwidthOfImage } from "../../functions/getImageWidth";
import { rearrangeDataForRTL } from "../../functions/rearrangeDataForRTL";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { ScrollView } from "react-native";
import { Feather } from '@expo/vector-icons'; 
import { dialNumber, sendSms } from "../../functions/shareApp";



  const PhoneNumberComponent = ({ 
    userSettingsState, 
    user,
    ContactName,
    ContactPhone,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);    
  const styles = styling(responsiveStyles);

  

  return (
    <View style={styles.phoneRowContainer}>

    <View style={[styles.rowContainer, { justifyContent:  'flex-end' }]}>
    <TouchableOpacity 
    style={styles.smsIconContainer}
    onPress={async () => {
      await sendSms(ContactPhone);
    }}
    >
    <Feather name="message-circle"  size={responsiveStyles.isWeb ? 32 : 24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
    </TouchableOpacity>
    <TouchableOpacity 
    style={styles.phoneIconContainer}
    onPress={async () => {
      await dialNumber(ContactPhone);
    }}
    >
    <Feather name="phone"  size={responsiveStyles.isWeb ? 32 : 24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
    </TouchableOpacity>
    </View>
    
    <View style={[styles.rowContainer, { justifyContent: 'flex-start' }]}>
    <View style={styles.userPhoto}>
      <Text style={styles.text}>{ContactName.charAt(0)}</Text>
      </View>
      <View style={styles.phoneNumberContainer}>
      <Text style={[styles.text, styles.boldText]}>{ContactName}</Text>
      <Text style={styles.text}>{ContactPhone}</Text>
      </View>
      </View>

    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  boldText: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: responsiveStyles.isRTL ? 'right' : 'left',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 10,
  },
  phoneRowContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
    paddingLeft: responsiveStyles.isRTL ? responsiveStyles.isMobile ? 10 : null : 20,
    paddingRight: !responsiveStyles.isRTL ? responsiveStyles.isMobile ? 10 : null : 20,
    paddingBottom: 10,
    // backgroundColor:'blue'
  },
  rowContainer: {
    // flex: responsiveStyles.isMobile ? null : 1,
    flex: 1,
    flexDirection: !responsiveStyles.isRTL ? 'row' : 'row-reverse',
  },
  phoneNumberContainer: {
    flex: 1,
    // flexDirection: !responsiveStyles.isRTL ? 'row' : 'row-reverse',
  },
  smsIconContainer: {
    backgroundColor: GlobalStyles.colors.Lavender,
    padding: 10,
    marginHorizontal: 10,
    alignItems: 'center',
    borderRadius: 15,
  },
  phoneIconContainer: {
    backgroundColor: GlobalStyles.colors.Gold,
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 15,
  },
  userPhoto: {
    width: responsiveStyles.screenWidth > 1200 ? 55 : 45,
    height: responsiveStyles.screenWidth > 1200 ? 55 : 45,
    borderRadius: 100,
    borderWidth: 4,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    justifyContent:'center',
    alignItems:'center',
    marginRight: responsiveStyles.isRTL ? null : 15,
    marginLeft: !responsiveStyles.isRTL ? null : 15,
    backgroundColor: GlobalStyles.colors.Gold,
  },
});

export default PhoneNumberComponent;