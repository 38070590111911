import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  BackHandler,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';  // Import useFocusEffect from react-navigation/native
import { Context as AuthContext } from '../../context/AuthContext';
import ForgotPasswordConfirmCanChangePasswordForm from '../../components/forms/ForgotPasswordConfirmCanChangePasswordForm';
import { ForgotPasswordConfirmCanChangePasswordAlert } from '../../components/alert/ForgotPasswordConfirmCanChangePasswordAlert';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const ForgotPasswordConfirmCanChangePasswordScreen = ({  }) => {
  
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const email = navigationParams?.email;
  const code = navigationParams?.code;
  
  const [showLoader, setShowLoader] = useState(false);
  const { state, addErrorMessage, ConfirmResetPassword, setForgotPasswordConfirmCanChangePasswordModalVisible } = useContext(AuthContext);

  const styles = styling(responsiveStyles);

  // useEffect to manage showLoader state and keyboard listeners
  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Forgot Password Confirm Can Change Password";
    }
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      // Handle keyboard show logic if needed
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      // Handle keyboard hide logic if needed
    });
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
      backHandler.remove();
    };
  }, []);

  // useFocusEffect to handle focus-related effects
  if(responsiveStyles.isWeb){
  }
  else{
    useFocusEffect(
      React.useCallback(() => {
        // Function to run when screen gains focus
        setShowLoader(false); // Example: Resetting loader state
  
        // Clean-up function when screen loses focus (optional)
        return () => {
          // Perform any clean-up here if necessary
        };
      }, [])
    );
  }

  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
          {/* <BackButtonComponent
          userSettingsState={userSettingsState} 
          navigation={navigation} 
          onPress={backAction}
           /> */}
          <ForgotPasswordConfirmCanChangePasswordAlert
          userSettingsState={userSettingsState}
            navigation={navigation}
            state={state}
            addErrorMessage={addErrorMessage}
          />
          <ForgotPasswordConfirmCanChangePasswordForm
          userSettingsState={userSettingsState}
            errorMessage={state.errorMessage}
            onSubmit={ConfirmResetPassword}
            email={email}
            code={code}
            forgotPasswordConfirmCanChangePasswordAlertMessage={state.forgotPasswordConfirmCanChangePasswordAlertMessage}
            forgotPasswordConfirmCanChangePasswordModalVisible={state.forgotPasswordConfirmCanChangePasswordModalVisible}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            setForgotPasswordConfirmCanChangePasswordModalVisible={setForgotPasswordConfirmCanChangePasswordModalVisible}
            addErrorMessage={addErrorMessage}
            navigation={navigation}
          />
          <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </Screen>
  );
};

ForgotPasswordConfirmCanChangePasswordScreen.navigationOptions = {
  header: () => false,
};

const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    inner: {
      padding: 15,
      flex: 1,
    },
  });

export default ForgotPasswordConfirmCanChangePasswordScreen;
