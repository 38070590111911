import { Dimensions, useWindowDimensions } from 'react-native';
import { useContext, useEffect, useState } from 'react';
import { Context as UserSettingsContext } from '../context/UserSettingsContext';
import { getFontSize_H1, getFontSize_H2, getFontSize_H3, getFontSize_H4, getFontSize_H5, getFontSize_H6, isDesktopResponsive, isLaptopResponsive, isMobileResponsive, isTabletResponsive, isWebResponsive } from '../functions/ScreenDimensionsLogic';
import { GlobalStyles } from '../constants/styles';
import { getUserSettingsStateProperties } from './getUserSettingsStateProperties';

const useResponsiveStyles = (
    userSettingsState
    // jdio
 ) => {
    // const { state: userSettingsState } = useContext(UserSettingsContext);
    // const { ThemeValue, isRTL, Language } = getUserSettingsStateProperties(userSettingsState);
    let userSettingsStateTemp = null;
    if(!userSettingsState){
        const { state: userSettingsState1 } = useContext(UserSettingsContext);
        userSettingsState = userSettingsState1;
        console.log(`if(!userSettingsState){
        const { state: userSettingsState1 } = useContext(UserSettingsContext);
        userSettingsState = userSettingsState1;
    }`);
    }
  const { ThemeValue, isRTL, Language } = getUserSettingsStateProperties(userSettingsState ? userSettingsState : userSettingsStateTemp);
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  
  const isWeb = isWebResponsive();
  const isDesktop = isDesktopResponsive(screenWidth);
  const isLaptop = isLaptopResponsive(screenWidth);
  const isTablet = isTabletResponsive(screenWidth);
  const isMobile = isMobileResponsive(screenWidth);

  // const [reload, setReload] = useState(false);


  // useEffect(() => {
  //   const onChange = ({ window }) => {
  //     console.log(`setReload(!reload);`);
      
  //     setReload(!reload);
  //   };


  //   if(isWeb){
  //   Dimensions.addEventListener('change', onChange);
  //   }
  //   return () =>{
  //     if(isWeb){
  //       Dimensions.removeEventListener('change', onChange);
  //     }
  //   } 
  // }, [reload]);

  return {
    ThemeValue,
    isRTL,
    Language,
    screenWidth,
    screenHeight,
    isWeb,
    isDesktop,
    isLaptop,
    isTablet,
    isMobile,
    primaryBackgroundColor: GlobalStyles[ThemeValue]?.primaryBackgroundColor,
    SecondrayBackgroundColor: GlobalStyles[ThemeValue]?.SecondrayBackgroundColor,
    textColor: GlobalStyles[ThemeValue]?.textColor,
    fontSize_H6: getFontSize_H6(screenWidth),
    fontSize_H5: getFontSize_H5(screenWidth),
    fontSize_H4: getFontSize_H4(screenWidth),
    fontSize_H3: getFontSize_H3(screenWidth),
    fontSize_H2: getFontSize_H2(screenWidth),
    fontSize_H1: getFontSize_H1(screenWidth),
  };
};

export default useResponsiveStyles;
