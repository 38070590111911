import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions, ScrollView } from 'react-native';
import { Context as UserSettingsContext } from "../../../context/UserSettingsContext";
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import { fixedScreenHeight } from '../../../constants/ScreenDimensions';
import useResponsiveStyles from '../../../functions/ResponsiveStyles';
import { GenericAlert } from '../../../components/alert/GenericAlert';
import Screen from '../../../components/Screen';
import { GlobalStyles } from '../../../constants/styles';
import TutorialContainer from '../../../components/tutorial/TutorialContainer';
import { getAsset } from '../../../functions/getAsset';
import VideoMediaPrimary from '../../../components/images/VideoMediaPrimary';
import { getwidthOfImage } from '../../../functions/getImageWidth';
import i18n from '../../../hooks/useI18n';
import ButtonTypeButton from '../../../components/inputs/ButtonTypeButton';
import Step1TextComponent from '../../../components/tutorial/Step1TextComponent';
import Step2TextComponent from '../../../components/tutorial/Step2TextComponent';
import Step4TextComponent from '../../../components/tutorial/Step4TextComponent';
import Step3TextComponent from '../../../components/tutorial/Step3TextComponent';
import { navigateToScreen } from '../../../navigationResponsive';
import BuisnessFooter from '../../../components/store/BuisnessFooter';

const TutorialScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Tutorial";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
    return () => backHandler.remove();
    },[]);

  return <Screen
  userSettingsState={userSettingsState}
  navigation={navigation}
  >
      <ScrollView 
      contentContainerStyle={styles.scrollViewContent}
      keyboardShouldPersistTaps='handled'
  >
    <View style={[styles.container]}>
      <View style={styles.header}>
        <GenericAlert
        userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={styles.subContainer}>
      <Step1TextComponent
        userSettingsState={userSettingsState}
        showOnlyFirst={true}
        navigation={navigation}
        />

        <Step2TextComponent
        userSettingsState={userSettingsState}
        showOnlyFirst={true}
        navigation={navigation}
        />

        <Step4TextComponent
        userSettingsState={userSettingsState}
        showOnlyFirst={true}
        navigation={navigation}
        />
      
        <Step3TextComponent
        userSettingsState={userSettingsState}
        showOnlyFirst={true}
        navigation={navigation}
        />
        
      </View>
    </View>
    <BuisnessFooter userSettingsState={userSettingsState} navigation={navigation} />
    </ScrollView>
    </Screen>

}


TutorialScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    ? (responsiveStyles.screenWidth * 0.6 + 20) : responsiveStyles.screenWidth * 0.8,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  text: {
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.4,
    marginHorizontal: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },

});

export default TutorialScreen;
