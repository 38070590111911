import React, { useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Dimensions, Platform, useWindowDimensions, TouchableWithoutFeedback, Keyboard, KeyboardAvoidingView } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { Context as ShopContext } from "../../context/ShopContext";
import { GlobalStyles, Gold } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import CartBreadcrumbs from '../../components/store/CartBreadcrumbs';
import OrderSummeryComponent from '../../components/store/OrderSummeryComponent';
import OrderShippingContainer from '../../components/store/OrderShippingContainer';
import TextInputWithErrorComponent from '../../components/forms/TextInputWithErrorComponent';
import { getDiscountPrice, getTotalPrice, getTotalQuantity } from '../../functions/getShopInfo';
import { OrderCheckout } from '../../api/apiCalls/OrderCalls';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import { isNumeric } from '../../functions/utils';
import ErrorComponentMessageError from '../../components/error/ErrorComponentMessageError';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { encryptData } from '../../functions/encryptData';
import { secretKey } from '../../api/apikey';
import PaymentIframe from '../../components/store/TranzilaIframe';
import TranzilaIframe from '../../components/store/TranzilaIframe';
import { getFileName, getOrderCheckoutJson } from '../../functions/getOrderCheckoutJson';
const CartPaymentScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const {state: shopState, updatePaymentDetails, finishedOrder } = useContext(ShopContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const validateExpirationDate = (expirationDate, checkMonth) => {
      if (!expirationDate || expirationDate.length < 4) {
        return false;
      }
    
      if (checkMonth) {
        const monthPart = expirationDate.substring(0, 2);
        const month = parseInt(monthPart, 10);
    
        if (isNaN(month) || month < 1 || month > 12) {
          return false;
        }
      } else {
        const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
        const yearPart = expirationDate.substring(2, 4);
        const year = parseInt(yearPart, 10);
    
        if (isNaN(year) || year <= (currentYear - 1) || year > 99) {
          return false;
        }
      }
    
      return true;
    };
    const scrollViewRef = useRef(null);
    const [inputs, setInputs] = useState({
      CardNumber: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      NameOnCard: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      ExpirationDate: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      SecurityCode: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
    });
    const onLayoutChange = (inputName, y) => {

      setInputs(curInputs => ({
        ...curInputs,
        [inputName]: { ...curInputs[inputName], layoutY: y },
      }));
    };

    function inputChangedHandler(inputIdentifier, enteredValue,isChanged) {
      setInputs((curInputs) => {
        const newInputs = {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        };
        return newInputs;
      });
      return;
    }

    const getDataToSend = () => {
      let DataToSend = {
        CardNumber: inputs.CardNumber.value,
        NameOnCard: inputs.NameOnCard.value,
        ExpirationDate: inputs.ExpirationDate.value,
        SecurityCode: inputs.SecurityCode.value,
    }
    return DataToSend;
    }

    const saveInfoHandler = async () => {
      try{

      const CardNumberIsValidLimit = isNumeric(inputs.CardNumber.value) && 
      (inputs.CardNumber.value.length >= 15 &&
        inputs.CardNumber.value.length <= 16);
      const CardNumberIsValid = inputs.CardNumber.value.toString().trim().length > 0 && CardNumberIsValidLimit;

      const NameOnCardIsValid = inputs.NameOnCard.value.toString().trim().length > 0;


      const ExpirationDateIsValidLimit = isNumeric(inputs.ExpirationDate.value) &&
      inputs.ExpirationDate.value.length === 4
      && validateExpirationDate(inputs.ExpirationDate.value, true)
      && validateExpirationDate(inputs.ExpirationDate.value, false)
      ;
      const ExpirationDateIsValid = inputs.ExpirationDate.value.toString().trim().length > 0 && ExpirationDateIsValidLimit;

      const SecurityCodeIsValidLimit = isNumeric(inputs.SecurityCode.value) &&
      inputs.SecurityCode.value.length === 3;
      const SecurityCodeIsValid = inputs.SecurityCode.value.toString().trim().length > 0 && SecurityCodeIsValidLimit;

      if (!CardNumberIsValid
         || !NameOnCardIsValid 
         || !ExpirationDateIsValid
         || !SecurityCodeIsValid
        ) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          
          
          updatedInputs.CardNumber.isValid = CardNumberIsValid;
          updatedInputs.CardNumber.isChanged = updatedInputs.CardNumber.isChanged === true ? true :CardNumberIsValidLimit;
          
          updatedInputs.NameOnCard.isValid = NameOnCardIsValid;
          
          updatedInputs.ExpirationDate.isValid = ExpirationDateIsValid;
          updatedInputs.ExpirationDate.isChanged = updatedInputs.ExpirationDate.isChanged === true ? true : ExpirationDateIsValidLimit;

          
          updatedInputs.SecurityCode.isValid = SecurityCodeIsValid;
          updatedInputs.SecurityCode.isChanged = updatedInputs.SecurityCode.isChanged === true ? true : SecurityCodeIsValidLimit;
          
          console.log(`updatedInputs: ${JSON.stringify(updatedInputs)}`);
          return updatedInputs;
        });
        if (!CardNumberIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.CardNumber.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.CardNumber.layoutY, x: inputs.CardNumber.layoutY, animated: true });
          }
        } 

        else if (!NameOnCardIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.NameOnCard.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.NameOnCard.layoutY, x: inputs.NameOnCard.layoutY, animated: true });
          }
        } 

        else if (!ExpirationDateIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.ExpirationDate.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.ExpirationDate.layoutY, x: inputs.ExpirationDate.layoutY, animated: true });
          }
        } 

        else if (!SecurityCodeIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.SecurityCode.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.SecurityCode.layoutY, x: inputs.SecurityCode.layoutY, animated: true });
          }
        } 
        return;
      }

      let DataToSend = getDataToSend();
    await updatePaymentDetails(DataToSend);

    let OrderCheckoutVM = getOrderCheckoutJson(userSettingsState, shopState, DataToSend);
    console.log(`OrderCheckoutVM is`);
    console.log(JSON.stringify(OrderCheckoutVM));

    // const encryptedData = encryptData(OrderCheckoutVM, secretKey);
    // const response = await OrderCheckout(encryptedData);

    
    const response = await OrderCheckout(OrderCheckoutVM);
    if(response.isCompleted === true)
      {
        // remove all parts from cart + user details
        const OrderId = JSON.parse(response.answer);
        finishedOrder();
        navigateToScreen(navigation, 'StoreFlow', `PaymentSuccessful`, { OrderId: OrderId }, OrderId)
        // navigation.navigate('StoreFlow', { screen: 'PaymentSuccessful' });
        // navigation.navigate("PaymentSuccessful");
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    catch(err){
      console.log(`func saveInfoHandler failed, err: ${err}`);
    }
    };
    
    const formatExpirationDate = (value) => {
      // Remove any non-numeric characters
      const numericValue = value.replace(/\D/g, '');
    
      // Format the value as MM/YY
      if (numericValue.length <= 2) {
        return numericValue;
      } else if (numericValue.length <= 4) {
        return `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}`;
      } else {
        return `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}`;
      }
    };
    const getFirstLetters = (str, number) => {
      if (typeof str !== 'string') {
        return str;
      }
      
      return str.substring(0, number);
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Cart Payment";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);


    
    
  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
        <TranzilaIframe
  // amount={getTotalPrice(shopState, true, true)}
  userSettingsState={userSettingsState}
  JsonToSend={JSON.stringify(getOrderCheckoutJson(userSettingsState, shopState, null))}
/>
    </Screen>

}

CartPaymentScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    scrollViewContent: {
      flexGrow: 1,
    },
    subContainer: {
      flex: 1,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.8 : null,
      alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
    },
    title: {
      fontSize: responsiveStyles.fontSize_H3,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontFamily: GlobalStyles.fonts.application_font,
      fontWeight: '700',
      alignSelf:'center',
      paddingBottom: 20,
    },
    text: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      textAlign: 'center',
    },
    mainContainer: {
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    },
    orderSummeryContainer:
    {flex:1, 
      marginBottom: 20, 
      paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
     },
    textInputContainer: {
  
    },
    buttonContainer: {
      alignItems:'center',
      // flex:1
    },
    button: {
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
    },
    textInputRowContainer: {
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'row' : null,
      justifyContent: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  'space-between' : null,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.35 : responsiveStyles.screenWidth * 0.85,
      marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
      alignSelf: responsiveStyles.isTablet || responsiveStyles.isMobile ? 'center' : null,
  },
  inputStyle: {
      // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.2 : null,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.15 : null,
  },
});

export default CartPaymentScreen;
