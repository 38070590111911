import React from 'react';
import { StyleSheet,View,Text, TextInput, useWindowDimensions } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { GlobalStyles, Lavender } from '../constants/styles';
import i18n from '../hooks/useI18n';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const CompanyFooter = ({ userSettingsState, containerStyle }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  return (
    <View style={[styles.container, containerStyle]}>
    <Text style={styles.text}>{i18n.t('auth.CompanyName')}</Text>
  </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
alignItems:'center'
  },
text: {
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor, 
  fontSize: responsiveStyles.fontSize_H4,
  fontWeight:'bold', 
  fontFamily: GlobalStyles.fonts.application_font,
}
});

export default CompanyFooter;
