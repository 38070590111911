import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, FlatList, Dimensions, Image, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import QRCodeGenerator from "../QRCodeGenerator";
import { navigateToScreen } from "../../navigationResponsive";
import JewelryEmptyListComponent from "../displayComponent/JewleryEmptyListComponent";
import { getImageUrl } from "../../functions/getImageUrl";
import { Asset } from "expo-asset";
import { rearrangeDataForRTL } from "../../functions/rearrangeDataForRTL";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { StoreItems } from "../../constants/store/Store_Items";

  const JewleryListComponent = React.memo(({ 
    userSettingsState, 
    data = [],
    navigation,
    cleanup,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const [numColumns, setNumColumns] = useState(responsiveStyles.screenWidth > 1024 ? 3 : 1);
    const styles = styling(responsiveStyles, numColumns);
    useEffect(() => {
      const onChange = ({ window }) => {
        const newWidth = window.width;
      setNumColumns(newWidth > 1024 ? 3 : 1);
        // setNumColumns(!responsiveStyles.isMobile ? 3 : 1);
      };


      if(responsiveStyles.isWeb){
      Dimensions.addEventListener('change', onChange);
      }
      return () =>{
        if(responsiveStyles.isWeb){
          Dimensions.removeEventListener('change', onChange);
        }
      } 
    }, []);

  return (
    <View style={styles.bottomCardContainer}>
      <FlatList
         removeClippedSubviews={true} // Unmount components when outside of window 
         initialNumToRender={2} // Reduce initial render amount
         maxToRenderPerBatch={1} // Reduce number in each render batch
         updateCellsBatchingPeriod={100} // Increase time between renders
         windowSize={7} // Reduce the window size
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          data={responsiveStyles.isRTL ? rearrangeDataForRTL(data, numColumns) : data}
          contentContainerStyle={responsiveStyles.isWeb ? styles.flatListContentContainer : null}
    // key={(item) => item.Id.toString()} 
    key={numColumns}
    numColumns={numColumns}
          renderItem={useCallback(({ item, index }) =>
          {
            const foundStoreItem = StoreItems.find(storeItem => 
                                      storeItem.Items.find(item1 => item1.Id === item.JewleryId)
                                    );
          let foundItem = foundStoreItem ? foundStoreItem.Items.find(item1 => item1.Id === item.JewleryId) : null;
          
          
            return <View style={styles.itemContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={getImageUrl(item.ImagePath)}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
    />
              <Text style={styles.JewleryName}>{foundItem ? responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_he : item.JewleryName}</Text>
              {
                item.UserProfileName ? 
                <Text style={[styles.text, { paddingBottom: 30 }]}>{i18n.t('account.MyAccount_JewleryListComponent_Profile')} {item.UserProfileName}</Text>
                : 
                <Text style={[styles.text, { color: GlobalStyles.errorMessage.color }]}>{i18n.t('account.UNVERIFIED_UserProfile')}</Text>
              }
              {/* <QRCodeGenerator 
              userSettingsState={userSettingsState}
              Id={item.Id} 
              barcodeSize={
                responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.06 
                : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
                : responsiveStyles.screenWidth * 0.4} 
              containerStyle={{ paddingVertical: 10 }}
               /> */}
              <ButtonTypeButton
              userSettingsState={userSettingsState}
                    style={styles.button}
                    onPress={() => {
                      navigateToScreen(navigation, 'AccountFlow', `JewleryDetails`, { Id: item.Id }, item.Id);
                      // navigate('AccountFlow', { screen: 'JewleryDetails', params:  { Id: item.Id } });
                      // navigate("JewleryDetails", { Id: item.Id })
                      if(cleanup){
                        cleanup();
                      }
                    }}
                    >
                      {i18n.t('account.MyAccount_JewleryListComponent_View')}
                </ButtonTypeButton>
              </View>
          }, [userSettingsState, responsiveStyles])}
          ListEmptyComponent={
            <JewelryEmptyListComponent
            userSettingsState={userSettingsState}
            navigation={navigation}
            />
              }
          />
  </View>

  );
})
const styling = (responsiveStyles, numColumns) => StyleSheet.create({
  bottomCardContainer: {
    flex:1,
    flexDirection:'row',
    // paddingHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.18 : null,
  },
  flatListContentContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexShrink: 1,
    alignSelf:'center',
  },
  itemContainer: {
    flex:1,
    padding: 25,
    // justifyContent:'flex-start',
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'center',
    marginVertical: 10,
    
    borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth:  1,
    borderRadius: 10,

    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : responsiveStyles.screenWidth * 0.8,
    minWidth: numColumns === 3 ? (responsiveStyles.screenWidth * 0.7 / 3 - (25 * 3)) : null,
    maxWidth: numColumns === 3 ? (responsiveStyles.screenWidth * 0.7 / 3 - (25 * 3)) : null,
    marginHorizontal: !responsiveStyles.isMobile ? 20 : 15,

  },
  
  userPhoto: {
    // width: 50,
    // height: 50,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.08 
    : responsiveStyles.isTablet ? 125
    : 150,
    aspectRatio: 1 / 1,
    borderRadius: 25,
    // borderWidth: 4,
    // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // marginBottom: 10,
  },
  JewleryName: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 10,
    fontWeight: '700',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingVertical: 10,
  },
  button: {
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 - (10 * 3)
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.screenWidth * 0.4,
    minWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 80 : null,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.06 - (10 * 3) : responsiveStyles.screenWidth * 0.4,
    paddingTop:10,
},
});

export default JewleryListComponent;