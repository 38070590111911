import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const JewleryDisplayTypeComponent = ({ userSettingsState, displayType, setDisplayType, showArchive }) => {
    
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);
  return (
    <View style={styles.bottomCardContainer}>

<TouchableOpacity 
    style={[styles.cardContainer, displayType === 'Jewlery' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Jewlery')}
    >
    <Text style={[styles.text, displayType === 'Jewlery' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_JewleryDisplayTypeComponent_Jewlery')}</Text>
    </TouchableOpacity>


<TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Orders' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Orders')}
    >
    <Text style={[styles.text, displayType === 'Orders' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_JewleryDisplayTypeComponent_Orders')}</Text>
    </TouchableOpacity>

  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  bottomCardContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    // marginTop: 20,
    // paddingHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.15 : 0,
    marginBottom: !responsiveStyles.isMobile ? 20 : 10,
    paddingTop: !responsiveStyles.isMobile ? 20 : 10,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    alignSelf: 'center',
  },
  onfocus:{
    // borderBottomColor: GlobalStyles.colors.selectedItemColor,
    // borderBottomWidth: 1,
  },
  onfocusText: {
    color: GlobalStyles.colors.selectedItemColor,
    fontWeight: '700',
    // minWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 12 : responsiveStyles.screenWidth / 6,
    // maxWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 12 : responsiveStyles.screenWidth / 6,
    borderBottomColor: GlobalStyles.colors.selectedItemColor,
    borderBottomWidth: 2,
    paddingBottom: 10,
  },
  cardContainer: {
    flex:1,
  },
  cardBorderContainer: {
    borderRightWidth: responsiveStyles.isRTL ? 3 : null,
    borderLeftWidth: !responsiveStyles.isRTL ? 3 : null,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.selectedItemBackgroundColor,
  },
});

export default JewleryDisplayTypeComponent;