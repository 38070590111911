import { Pressable, StyleSheet, Text, View, Image, ActivityIndicator, Animated, Dimensions, useWindowDimensions } from 'react-native';
import { GlobalStyles } from '../../constants/styles';
import React,{ useRef, useEffect, useState, useContext } from 'react';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

function ButtonTypeButton({ 
  children, 
  onPress, 
  mode, 
  style,
  styleButton, 
  loading = false, 
  styleText,
  TextOpposite = false,
  userSettingsState,
 }) {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles, style);
  // console.log(`style?.width is ${style?.width}`);

  const [scaleValue] = useState(new Animated.Value(1));
  const footerRef = useRef(null);
  const handlePressIn = () => {
    Animated.timing(scaleValue, {
      toValue: 0.95,
      duration: 150,
      useNativeDriver: true,
    }).start();
  };

  const handlePressOut = () => {
    Animated.timing(scaleValue, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  return (
    // <View style={style}>
    <Animated.View
        ref={footerRef}
        style={[
          {
            transform: [
              {
                scale: scaleValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.5, 1],
                }),
              },
            ],
            // width: style && style?.width !== undefined && style?.width ? style?.width : 
            // responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.4,
          },
          style
        ]}
      >
      <Pressable
        onPress={onPress}
        style={({ pressed }) => pressed && styles.pressed}
        disabled={loading}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
      >
        {!loading ? (
        <View style={[styles.button, styleButton, mode === 'flat' && styles.flat]}>
            <Text style={[styles.buttonTextOpposite , TextOpposite ? styles.buttonText : null, mode === 'flat' && styles.flatText, styleText]}>
            {children}            
          </Text>
        </View>
        ) : <ActivityIndicator style={[styles.button, styleButton, mode === 'flat' && styles.flat
        // ,{ minHeight:50 }
        ]} />}
      </Pressable>
    {/* </View> */}
      </Animated.View>
  );
}

export default ButtonTypeButton;

const styling = (responsiveStyles, style) => StyleSheet.create({
  button: {
    // borderRadius: 20,
    borderRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 25 : 15,
    padding: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 10 : 5,
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 5 : 20,
    // backgroundColor: GlobalStyles.colors.Lavender,
    borderWidth: 1,
    borderColor: GlobalStyles.colors.Gold,
    marginHorizontal: style && style?.width && style?.width < responsiveStyles.screenWidth * 0.4 ? -15 : 0,
    // marginHorizontal: -15
    // paddingVertical: 10,
    
  },
  flat: {
    backgroundColor: 'transparent',
  },
  buttonTextOpposite: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 18 : responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  buttonText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorOpposite,
  },
  pressed: {
    opacity: 0.75,
    borderRadius: 4,
    // transform: [ { scale: 0.98 } ]
  },
});