import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../constants/styles";
import i18n from "../hooks/useI18n";
import ButtonTypeText from "./inputs/ButtonTypeText";
import useResponsiveStyles from "../functions/ResponsiveStyles";
  const JewelryNotAssignedComponent = ({ userSettingsState, navigation }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.centerContainer}>
    <Text style={styles.title}>{i18n.t('account.JewelryNotAssignedComponent_title')}</Text>
    <View style={{  }}>
    <Text style={styles.text}>{i18n.t('account.JewelryNotAssignedComponent_subtitle1')}</Text>
    <Text style={styles.text}>{i18n.t('account.JewelryNotAssignedComponent_subtitle2')}</Text>
    <Text style={styles.text}>{i18n.t('account.JewelryNotAssignedComponent_subtitle3')}</Text>
    </View>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    centerContainer: {
        flex: 1,
        alignItems: 'center',
        // justifyContent: 'center',
        backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      },
      title: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H4,
        fontWeight: 'bold',
        fontFamily: GlobalStyles.fonts.application_font,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        paddingVertical: responsiveStyles.screenWidth * 0.05,
    },
    text: {
      color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,  
      textAlign:'center',
      paddingBottom: 5,
    },
});

export default JewelryNotAssignedComponent;