import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../../constants/styles";
import i18n from "../../../hooks/useI18n";

import ImageProfile from "../../images/ImageProfile";
import { formatDate } from "../../../functions/formatDate";
import CommendComponent from "../../../CommendComponent";
import useResponsiveStyles from "../../../functions/ResponsiveStyles";
  const BottomRow = ({ 
    userSettingsState,
    user,
    LikePostHandler,
    openDialog,
    handleLayout,
    showMoreRead,
    comments,
    IsDemo = false,
 }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

    const formattedDate = formatDate(user?.CreatedDate);
    const [numberOfLines, setNumberOfLines] = useState(3);
  return (
    <View style={styles.bottomContainer}>

    <CommendComponent
    userSettingsState={userSettingsState}
    isLike={user?.IsUserLiked}
    onCommentPress={openDialog}
    onLikePress={() => LikePostHandler()}
    IsDemo={IsDemo}
    />

      <Text style={styles.likes}>{user?.Like} {i18n.t(`media.Likes`)}</Text>
      <Text style={styles.title}>{user?.Title}</Text>
      <Text style={styles.description} 
      numberOfLines={numberOfLines}
    onLayout={handleLayout}
      
      >
      {user?.Description}
      </Text>
      {numberOfLines === null || showMoreRead === false ? null : 
      <TouchableOpacity 
      onPress={() => setNumberOfLines(null)}
      >
      <Text 
      style={[styles.description, styles.TextTouchableOpacity, responsiveStyles.isRTL ? {textAlign: 'right'} : {textAlign: 'left', fontWeight: '800'}]} 
      >
        {i18n.t(`media.DescriptionExtention`)}
        </Text>
      </TouchableOpacity>
      }
      <TouchableOpacity style={styles.commentContainer} onPress={openDialog}>
      <Text style={[styles.comment]}>{i18n.t(`media.Comments`)}</Text>
      <View style={styles.commentsNumberContainer}>
      <Text style={[styles.commentsNumber]}>{comments.length}</Text>
      </View>
      </TouchableOpacity>
      <View style={[styles.commentContainer, styles.uploadDateContainer]}>
      <Text style={[styles.comment, { paddingVertical: !responsiveStyles.isMobile ? 20 : 10 }]}>{formattedDate == null || formattedDate === "NaN/NaN/NaN" ? user?.CreatedDate : formattedDate}</Text>
      </View>
      
  </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  
  imageContainer: {
    flex:1,
  },
  closeContainer: {
    marginHorizontal: 10,
  },
  shareContainer: {
    marginHorizontal: 10,
  },
  bottomContainer: {
    // flex:1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // alignSelf: 'center',
    // bottom: 90,
    // position: 'absolute',
    width:'100%',
  },
  likes: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingVertical: 5,
    paddingHorizontal: 20,
    fontWeight: '700',
    textAlign : responsiveStyles.isRTL ? 'right' : 'left',
  },
  title: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingVertical: 5,
    paddingHorizontal: 20,
    fontWeight: '700',
    textAlign : responsiveStyles.isRTL ? 'right' : 'left',
  },
  description: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  commentContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    paddingHorizontal: 20,
  },
  uploadDateContainer: {
    paddingBottom: 20,
  },
  comment: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingVertical: 5,
    paddingHorizontal: 5,
    },
    commentsNumberContainer: {
      backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
      // padding: 10,
      borderRadius: 100,
      width: 30,
      height: 30,
      justifyContent:'center',
      alignItems:'center'
    },
    commentsNumber: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H6,
      fontFamily: GlobalStyles.fonts.application_font,
    },
    showcaseNextPreviousButtonContainer: {
      position: 'absolute',
      top: '50%',
      transform: [{ translateY: -12 }], // Adjust based on icon size
      backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
      borderRadius: 100,
      padding: 5,
      justifyContent:'center',
      alignItems:'center',
    },
    PreviousContainer: {
      right: 10,
    },
    NextContainer: {
      left: 10,
    },

    swipeableView: {
      flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
    image: {
      flex: 1,
      width: '100%',
      height: responsiveStyles.screenWidth,
  },
});

export default BottomRow;