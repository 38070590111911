export default {
    Title: "Help",
    Section1_1: "Welcome to the TMJ Help Center! We’re here to assist you with any questions or issues you may have. Below, you'll find answers to common questions and troubleshooting tips to ensure you have the best experience with your TMJ jewelry.",
    FrequentlyAskedQuestions: "Frequently Asked Questions",
    Section2_Title: "How do I scan the QR code on my TMJ jewelry?",
    Section2_1: "To scan the QR code, simply use your smartphone's camera or a QR code scanning app. Point the camera at the QR code, and a link will appear on your screen. Tap the link to access your personalized digital gallery.",
    Section3_Title: "How do I set up a profile and personalize my digital gallery?",
    Section3_1: "After purchasing your TMJ jewelry, you will receive an email with instructions on how to set up your gallery. Follow the link provided in the email to create an account.",
    Section3_2: "Once logged in, you can set up a profile for the person you want to make the profile for. This includes uploading photos, videos, and any other files you wish to share.",
    Section3_3: "Customize the profile to make it unique and personal, reflecting the memories and moments you want to showcase.",
    Section4_Title: "How do I assign a profile to my jewelry?",
    Section4_1: `In your TMJ account, navigate to the "Jewelry" section. Here, you will see all the jewelry you have ordered. Click on the piece of jewelry you want to assign. Then, click the "Assign Jewelry" button and select the profile you want to link to this QR code. You can create and manage multiple profiles as needed.`,
    Section5_Title: "What if the QR code doesn't scan?",
    Section5_1: "Ensure your camera lens is clean and there is enough light to see the QR code clearly. If the problem persists, try using a different QR code scanning app. If you're still having trouble, contact our customer support for further assistance.",
    Section6_Title: "Can I update the content in my digital gallery?",
    Section6_1: "Yes, you can update your gallery at any time by logging into your account on our website. Simply upload new photos or videos, and they will be instantly available through the QR code on your jewelry.",
    Section7_Title: "Is my personal information secure?",
    Section7_1: "At TMJ, your privacy and security are our top priorities. We use advanced encryption technologies to protect your data and ensure that your personal information is safe.",
    Troubleshooting: "Troubleshooting",
    Problem: "Problem:",
    Solution: "Solution:",
    Troubleshooting_Problem1: "The QR code is not scanning.",
    Troubleshooting_Solution1_1: `Make sure your phone’s camera is focused on the QR code.`,
    Troubleshooting_Solution1_2: `Try cleaning the QR code surface if it’s dirty or scratched.`,
    Troubleshooting_Solution1_3: "Use a different QR code scanner app.",
    Troubleshooting_Problem2: " I didn’t receive the setup email.",
    Troubleshooting_Solution2_1: "Check your spam or junk folder.",
    Troubleshooting_Solution2_2: "Ensure you entered the correct email address during purchase.",
    Troubleshooting_Solution2_3: "Contact our customer support for help.",
    Troubleshooting_Problem3: "I forgot my account password.",
    Troubleshooting_Solution3_1: `Click on the "Forgot Password" link on the login page to reset your password. Follow the instructions sent to your email to regain access to your account.`,
    ManageJewelryAndProfiles: "Managing Your Jewelry and Profiles",
    ManageJewelryAndProfiles1_Title: "How to View Your Jewelry:",
    ManageJewelryAndProfiles1_1: "Log in to your TMJ account.",
    ManageJewelryAndProfiles1_2: `Navigate to the "Jewelry" section to see all the pieces you have ordered.`,
    ManageJewelryAndProfiles2_Title: "How to Assign a Profile to Your Jewelry:",
    ManageJewelryAndProfiles2_1: `In the "Jewelry" section, click on the piece of jewelry you want to manage.`,
    ManageJewelryAndProfiles2_2: `Click the "Assign Jewelry" button.`,
    ManageJewelryAndProfiles2_3: "Select the profile you want to link to this QR code. You can create and manage multiple profiles within your account.",
    ContactUs: "Contact Us",
    ContactUs_1: "If you need further assistance, please don’t hesitate to contact our customer support team. We’re here to help!",
    ContactUs_Email: "Email:",
    ContactUs_Phone: "Phone:",
    FinalWords: `Thank you for choosing TMJ. We’re committed to providing you with the best experience possible!`,
  };