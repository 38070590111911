import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions, FlatList, TouchableOpacity, ScrollView, Button, TextInput } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import SelectDropdown from 'react-native-select-dropdown';
import { Feather } from '@expo/vector-icons'; 
import TextInputCustom from '../../components/inputs/TextInputCustom';
import DateComponent from '../../components/inputs/item/DateComponent';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { navigateToScreen } from '../../navigationResponsive';
import { getNavigationParams } from '../../functions/navigationLogic';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { GetOrderById, SaveOrderStatus } from '../../api/apiCalls/AdminCalls';
import { formatDateTime } from '../../functions/formatDate';
import { getImageUrl } from '../../functions/getImageUrl';
import ImageProfile from '../../components/images/ImageProfile';

const AdminOrderDetailsScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const OrderIdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);

  const OrderId = navigationParams?.OrderId || OrderIdFromURL;
  const [order, setOrder] = useState(null);
  const statusOptions = ['Pending', 'Shipped', 'Delivered'];
  const [OrderStatus, setOrderStatus] = useState("Pending");
  const [isLoading, setIsLoading] = useState(false);

  

    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    
    const SaveOrderStatusLogic = async () => {
      const response = await SaveOrderStatus({
        OrderId,
        OrderStatus,
      });
      if(response.isCompleted === true)
      {
        // setReloadPage(!reloadpage);
        setbodymessge(i18n.t('responseMessages.success'));
        setGenericAlertVisible(true);
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };

    const renderOrderItem = ({ item }) => {
      console.log(`item.ImagePath is ${item.ImagePath}`);
      return (
      <View style={styles.itemRow}>
      <ImageProfile
    userSettingsState={userSettingsState}
   uri={getImageUrl(item.ImagePath)}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
    />
        <Text style={styles.itemName}>{item.ProductName}</Text>
        <Text style={styles.itemQuantity}>Quantity: {item.Quantity}</Text>
        <Text style={styles.itemPrice}>Price: ${item.TotalPrice}</Text>
      </View>
      )
    };

    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);
    useEffect(() =>{
      const fetchData = async () => {
        try {
          setLoading(true);
          const req = await GetOrderById(OrderId);
          if (req.isCompleted) {
            const vm = JSON.parse(req.answer);
            setOrder(vm);
            setOrderStatus(vm.OrderStatus);
          }
          else{
            setLoading(true);
            setErrorOnRequest(true);
          }
        } catch (err) {
          console.log(err);
          setLoading(true);
          setErrorOnRequest(true);
        }
        finally{
          setLoading(false);
        }
      };
      fetchData();
      if(responsiveStyles.isWeb){
        document.title = "Admin Order Details";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[reloadpage]);

    const formattedOrderDate = formatDateTime(order?.OrderDate);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  >
    {errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen
      userSettingsState={userSettingsState}
       />
    </View> 
    : ( 
    <View style={[styles.container]}>
      <View style={styles.header}>
        <GenericAlert
        userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      {/* Order Info */}
      <View style={styles.orderInfoContainer}>
        <Text style={styles.title}>Order ID: {order?.OrderId}</Text>
        <Text style={styles.subtitle}>Customer: {order?.CustomerName}</Text>
        <Text style={styles.subtitle}>Phone Number: {order?.PhoneNumber}</Text>
        <Text style={styles.subtitle}>Order Date: {formattedOrderDate}</Text>
        <View style={{ flexDirection:'row', alignItems:'center' }}>
        <Text style={styles.subtitle}>Order Status:</Text>
        <SelectDropdown
          data={statusOptions}
          defaultButtonText="Select Status"
          selectedItem={OrderStatus}
          defaultValue={order?.OrderStatus}
          onSelect={(selectedItem) => {
            setOrderStatus(selectedItem);
          }}
          buttonStyle={styles.dropdown}
          renderDropdownIcon={(isOpened) => {
            return (
                <Feather
                    name={isOpened ? "chevron-up" : "chevron-down"}
                    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
                    size={24}
                    style={{ marginHorizontal: 10 }}
                />
            );
        }}
        />
        </View>
        <Text style={styles.subtitle}>Payment Status: {order?.PaymentStatus}</Text>
      </View>

      <View style={styles.orderAddressContainer}>
        <Text style={styles.title}>Order Address</Text>
        <Text style={styles.subtitle}>Country: {order?.Country}</Text>
        <Text style={styles.subtitle}>Address: {order?.AddressLine}</Text>
        <Text style={styles.subtitle}>Apartment: {order?.Apartment}</Text>
        <Text style={styles.subtitle}>PostalCode: {order?.PostalCode}</Text>
      </View>

      {/* Order Items */}
      <Text style={styles.sectionTitle}>Order Items:</Text>
      <FlatList
        data={order?.Items}
        keyExtractor={(item) => item.OrderId}
        renderItem={renderOrderItem}
        contentContainerStyle={styles.itemsList}
      />

      {/* Total Amount */}
      <View style={styles.totalContainer}>
        <Text style={styles.totalText}>Total Amount: ${order?.Total}</Text>
      </View>

      {/* Back Button */}
      <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        loading={isLoading}
         onPress={async () => {
            setIsLoading(true);
            await SaveOrderStatusLogic();
            setIsLoading(false);
        }}
        >
        Save Changes
    </ButtonTypeButton>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
         onPress={async () => {
            backAction();
        }}
        >
        Go Back
    </ButtonTypeButton>
    </View>
      </View>
    )}
  </Screen>

}


AdminOrderDetailsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    padding: 20,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  orderInfoContainer: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 8,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.secondaryBackgroundColor,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  orderAddressContainer: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 8,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.secondaryBackgroundColor,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
  },
  subtitle: {
    fontSize: 16,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.secondaryTextColor,
    marginTop: 5,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
    marginBottom: 10,
  },
  itemsList: {
    marginBottom: 20,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBorderColor,
    alignItems: 'center',
  },
  itemName: {
    flex: 1,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
    paddingLeft: responsiveStyles.isRTL ? null : 10,
    paddingRight: !responsiveStyles.isRTL ? null : 10,
  },
  itemQuantity: {
    flex: 1,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.secondaryTextColor,
  },
  itemPrice: {
    flex: 1,
    textAlign: 'right',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
  },
  totalContainer: {
    paddingVertical: 10,
    borderTopWidth: 1,
    borderTopColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBorderColor,
  },
  totalText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'right',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.primaryTextColor,
  },
  backButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.buttonBackgroundColor,
    borderRadius: 5,
    alignItems: 'center',
  },
  backButtonText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.buttonTextColor,
    fontSize: 16,
  },
  userPhoto: {
    // width: 50,
    // height: 50,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.08 
    : responsiveStyles.isTablet ? 100
    : 50,
    aspectRatio: 1 / 1,
    borderRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 25 : 15,
    // borderWidth: 4,
    // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // marginBottom: 10,
  },
  dropdown: {
    marginHorizontal: 10,
  },
  button: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.15 : responsiveStyles.screenWidth * 0.4,
},
buttonContainer: {
  marginVertical: 20,
  alignItems:'center',
  flexDirection: 'row',
  justifyContent: 'space-between'
},
});

export default AdminOrderDetailsScreen;
