import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { isNumeric } from "../../functions/utils";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputCustom from "../inputs/TextInputCustom";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
  const ManagePetProfileForm_GenderComponent = ({ 
    userSettingsState,
    inputs,
    inputChangedHandler,
    editable,
    textInputNameStyle,
    containerStyle,
   }) => {

    const [isOtherVisible, setIsOtherVisible] = useState(false);
    const [lastValue, setLastValue] = useState("");
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
<View style={[styles.mainContainer, containerStyle]}>
<View>
  <Text style={styles.subtitle}>{`${i18n.t('account.MyAccount_ManagePetProfileForm_Gender')} *`}</Text>
  </View>
  <View style={styles.secondaryContainer}>
  <ButtonTypeButton
  userSettingsState={userSettingsState}
  styleButton={[styles.styleButton, inputs.Gender.value === "Boy" ? styles.chosenButton : null]}
  style={styles.buttonStyle}
  onPress={() => {
    setIsOtherVisible(false);
    inputChangedHandler("Gender", "Boy", true);
  }}
      >
        {i18n.t('account.MyAccount_ManagePetProfileForm_Gender_Boy')}
      </ButtonTypeButton>
  <ButtonTypeButton
  userSettingsState={userSettingsState}
  styleButton={[styles.styleButton, inputs.Gender.value === "Girl" ? styles.chosenButton : null]}
  style={styles.buttonStyle}
  onPress={() => {
    setIsOtherVisible(false);
    inputChangedHandler("Gender", "Girl", true);
  }}
  >
    {i18n.t('account.MyAccount_ManagePetProfileForm_Gender_Girl')}
  </ButtonTypeButton>
  </View>
  {!inputs.Gender.isValid && (
      <ErrorComponentMessageError 
          userSettingsState={userSettingsState} 
          errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_Gender_ErrorMessage')}
      />
    )}
  </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  mainContainer: {
    // backgroundColor: 'blue',
    paddingBottom: 15,
    // alignItems:'center',
  },
  secondaryContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',
  },
  subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontWeight: '500',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
    paddingBottom: 10,
},
styleButton: {
  paddingHorizontal: 25,
},
chosenButton: {
  backgroundColor: Gold,
},
buttonStyle: {
  paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
},
});

export default ManagePetProfileForm_GenderComponent;