import { MaterialIcons } from '@expo/vector-icons';
import React from 'react';
import { View, Text, StyleSheet, Image, useWindowDimensions } from 'react-native';
import { GlobalStyles } from '../../constants/styles';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const ReviewContainer = ({
  userSettingsState,
  name = "",
  subtitle = "",
  content = "",

}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
      <MaterialIcons name="account-circle" size={responsiveStyles.isMobile || responsiveStyles.isTablet ? 75 : 125} color={"#d9d9d9"} />

        <Text style={styles.name}>{name}</Text>
        <View style={styles.starCount}>
          {[...Array(5)].map((_, index) => (
   <MaterialIcons name="star" size={26} color={GlobalStyles.store.iconColor} key={index} />
          ))}
        </View>
      </View>
      <View style={styles.info}>
        <Text style={styles.subtitle}>{subtitle}</Text>
        <Text style={styles.content}>{content}</Text>
      </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].SecondrayBackgroundColor,
    // borderWidth: 1,
    // borderColor: GlobalStyles.colors.buttonBorderColor,
    // borderRadius: 10,

    padding: 20,
    marginVertical: 20,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    // elevation: 5,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'flex-start' : "center",
    minHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.22 : null,
    // justifyContent:'center',
    transform: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? [{ scale: 0.9 }] : [{ scale: 1 }],
  },
  header: {
    // flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  name: {
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  starCount: {
    flexDirection: 'row',
  },
  star: {
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  info: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  content: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  buttonContainer: {
    alignItems: 'center',
  },
});

export default ReviewContainer;
