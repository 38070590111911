import React from 'react';
import { View, Text, StyleSheet, useWindowDimensions } from 'react-native';
import { GlobalStyles, Gold } from '../../constants/styles';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const DownArrow = ({ 
  userSettingsState,
  minHeight,
 }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles, minHeight);

  return (
    <View style={styles.container}>
      {
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 
        <Text style={styles.arrow}>{responsiveStyles.isRTL ?  `←` : `→`}</Text>
        : <Text style={styles.arrow}>↓</Text>
      }
    </View>
  );
};

const styling = (responsiveStyles, minHeight) => StyleSheet.create({
  container: {
    alignItems: 'center', // Center the arrow horizontally
    justifyContent:'center',
    // padding: 10,
    paddingBottom: 20,
    marginTop: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  100 : null,
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.02 : null,
    // backgroundColor:'blue',
    minHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  minHeight : null,
  },
  arrow: {
    fontSize: 
    responsiveStyles.isMobile
    ? responsiveStyles.screenWidth * 0.12
    : responsiveStyles.isTablet
    ? responsiveStyles.screenWidth * 0.1
    : responsiveStyles.isLaptop
    ? responsiveStyles.screenWidth * 0.05
    : responsiveStyles.screenWidth * 0.04, // Adjust the size of the arrow
    // color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    color: Gold,
    // width: responsiveStyles.screenWidth * 0.1,
    alignSelf: 'center',
  },
});

export default DownArrow;
