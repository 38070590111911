import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { Context as ShopContext, shippingAmount, shippingInternational } from "../../context/ShopContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import CartBreadcrumbs from '../../components/store/CartBreadcrumbs';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import OrderSummeryComponent from '../../components/store/OrderSummeryComponent';
import OrderShippingContainer from '../../components/store/OrderShippingContainer';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
const CartShippingInformationScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const { state: shopState, updateShippingMeathod } = useContext(ShopContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    
    const saveInfoHandler = async () => {
      // await updateShippingMeathod(shippingAmount);
      navigateToScreen(navigation, 'StoreFlow', `CartPayment`, null, null)
      // navigation.navigate('StoreFlow', { screen: 'CartPayment' });
      // navigation.navigate("CartPayment");
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Cart Shipping Information";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <ScrollView style={[styles.container]}>
    <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      <OrderSummeryComponent
  userSettingsState={userSettingsState}
  navigation={navigation}
      />
      <CartBreadcrumbs 
      userSettingsState={userSettingsState}
      navigation={navigation}
      showInformation={true}
      showShipping={true}
      />
      <OrderShippingContainer
      userSettingsState={userSettingsState}
      showShippingMeathod={false}
      onChangePress={() => {
        responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      }}
      />
      </View>
      <View style={{padding: 20}}>
      <Text style={styles.title}>{i18n.t('store.CartShippingInformationScreen_Title')}</Text>

      <View style={styles.shippingTypeContainer}>
      <Text style={styles.text}>{shippingInternational}</Text>
      <Text style={styles.text}>{shippingAmount} ₪</Text>
      </View>

      <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    style={styles.button}
     onPress={saveInfoHandler}
    >
      {i18n.t('store.CartShippingInformationScreen_SubmitButton')}
</ButtonTypeButton>
</View>
</View>
</View>
    </ScrollView>
    </Screen>

}

CartShippingInformationScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.8 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
    textAlign: 'center'
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
  },
  shippingTypeContainer: {
    flexDirection: 'row',
    padding: 20,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.85,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: GlobalStyles.colors.Lavender,
    justifyContent: 'space-between',
    marginVertical: 20,
    alignSelf:'center'
  },
  buttonContainer: {
    alignItems:'center',
    // flex:1
  },
  button: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
  },
});

export default CartShippingInformationScreen;
