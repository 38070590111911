import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const QRCodeGenerator = ({ 
  Id, 
  barcodeSize = null,
  containerStyle = null,
  userSettingsState,
  isFromHomeScreen = false,
}) => {
  const qrcodeValue = isFromHomeScreen ? "https://timelessmemories-jewelry.com/Profile/Mother" : "https://timelessmemories-jewelry.com/myjewelry/" + Id;
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

    const size = 200 
  return(
  <View style={[styles.container, containerStyle]}>
    <QRCode
      value={qrcodeValue}
      size={barcodeSize ? barcodeSize : size}
      bgColor="black"
      fgColor="white"
    />
  </View>
)};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: '#F5FCFF',
  paddingVertical: 5,

  },
});

export default QRCodeGenerator;
