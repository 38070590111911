import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, View, Image, TouchableOpacity, StyleSheet, Dimensions, Text, ScrollView, useWindowDimensions, Platform } from 'react-native';
import { Video } from "expo-av";
import Slider from '@react-native-community/slider';
import { GlobalStyles, Gold } from '../../constants/styles';
import * as MediaLibrary from 'expo-media-library';
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Asset } from 'expo-asset';
import useLazyLoad from '../../functions/useLazyLoad';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import PlayCircle from './PlayCircle';
import { getAsset } from '../../functions/getAsset';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const VideoMedia = ({ 
  userSettingsState,
  uri,
  isAudio = false,
  isDisplaying = false,
  videoStyle = null,
  videoContainerStyle = null,
  isShowBorder = false,
  videoContainerStyleTouchableOpacity = null,
  videoRef,
  refresh,
  isFullScreen = false,
  allowPlayVideo = true,
  onTouchableOpacityPress = null,
  onPlayShowFullScreen = false,
  setVisible,
  visible,
  resizeMode = "cover",
  isThumbnail = false,
  thumbnail,
  refreshVideoRef,
  onVideoStatusUpdate,
  isBackgroundColorBlack = false,
  shouldPlay,
  setShouldPlay,
  onVideoReadyForDisplay,
}) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const [isVisible, elementRef] = useLazyLoad(); // Use the lazy load hook

  const [localFilePath, setLocalFilePath] = useState(null);
  
  const [showThumbnail, setShowThumbnail] = useState(true);

  const [videoSliderValue, setVideoSliderValue] = useState(0);
    const styles = styling(responsiveStyles, isAudio);
    
    const [isErrorImage, setIsErrorImage] = useState(false);
    const errorURI = isAudio ? 
    getAsset(require('../../../assets/images/error/audio_url_not_valid.png'))
    : getAsset(require('../../../assets/images/error/video_url_not_valid.png'));
    // const errorURI = "";

    const onLoadError = useCallback(() => {
      console.log(`error on image`);
      setIsErrorImage(true);
    }, []);

    useEffect(() => {
      console.log(`useEffect in VideoImage`);
        if(isDisplaying){
          setShouldPlay(true);
        }
        else{
          setShouldPlay(false);
        }
      setLocalFilePath(uri);
      if(!responsiveStyles.isWeb){
      refreshVideoRef();
      }
    }, [isDisplaying, refresh]);




  return (
      <View style={[
        styles.container, 
        isShowBorder ? { borderWidth: 1, borderColor: Gold } : null,
        videoContainerStyle,
      ]} ref={elementRef}>
        {isVisible && (
        <>
        <TouchableOpacity style={[{ 
          flex: 1, 
          backgroundColor:  'black', 
          // backgroundColor:  GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
          justifyContent:'center', 
          alignItems:'center'
         },
         videoContainerStyle,
         videoContainerStyleTouchableOpacity,
        ]} 
        activeOpacity={1}
        onPress={async () => {
          if(onTouchableOpacityPress !== null && onTouchableOpacityPress !== undefined){
            onTouchableOpacityPress();
          }
          else if(allowPlayVideo){
            if(onPlayShowFullScreen && !visible){
              setVisible(true);
              await refreshVideoRef();
            }
            else{
              if(responsiveStyles.isWeb
                //  && onPlayShowFullScreen
                ){
                if(!shouldPlay){
                  // setRefresh(!refresh);
                  // await refreshVideoRef();
                  if(videoRef.current){
                    videoRef.current.playAsync();
                  }
                  else{
                    console.log(`
                      if(videoRef.current){
                      need to check
                      `);
                    
                  }
                }
                else{
                  console.log(`else of videoRef.current.pauseAsync();`);
                  if(videoRef.current){
                    console.log(`videoRef.current.pauseAsync();`);
                    videoRef.current.pauseAsync();
                  }
                }
              }
              setShowThumbnail(false);
              setShouldPlay(!shouldPlay);
            }
          }
          }}
        >
          <>
          {isErrorImage ? 
            <FastImage
            style={[styles.video,
              (onPlayShowFullScreen && visible) ? { width: '99%' } : videoStyle,
              visible ? { height: '100%' } :  null,
              {     borderWidth:1, borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor },
              {  }
              ]}
            source={{ uri: errorURI }}
            // resizeMode={resizeMode}
            />
          : 
          isThumbnail && (thumbnail || isAudio) && showThumbnail ?
          <FastImage
            //  key={index}
             style={[styles.video,videoStyle]}
             source={{
                 uri: thumbnail,
                //  priority: FastImage.priority.normal,
             }}
             resizeMode={resizeMode}
          />
          : <Video
        ref={videoRef}
          source={{ uri: localFilePath }}
          rate={1.0}
          onError={onLoadError}
          volume={1.0}
          isMuted={false}
          resizeMode={resizeMode}
          shouldPlay={shouldPlay}
          isLooping={true}
          style={[
            styles.video,
            visible ? { height: '100%' } :  null,
            (onPlayShowFullScreen && visible) ? { width: '99%' } : videoStyle,
            isBackgroundColorBlack ? { backgroundColor: 'black' } : null,
            {  }
            ]}
          onPlaybackStatusUpdate={onVideoStatusUpdate}
          onReadyForDisplay={onVideoReadyForDisplay}
          videoStyle={styles.videoNativeStyle}
         />
         }
        <PlayCircle 
        userSettingsState={userSettingsState}
        shouldPlay={shouldPlay}
        isAudio={isAudio}
        allowPlayVideo={allowPlayVideo}
        isFullScreen={isFullScreen}
        />
          </>
          
        </TouchableOpacity>
          </>
        )}
      </View>
  );
};
const styling = (responsiveStyles, isAudio) => StyleSheet.create({
  container: { 
    flex: 1, 
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
  },
  video: {
    width: responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.5 : responsiveStyles.screenWidth,
    // height: responsiveStyles.screenHeight * 1,
    objectFit: 'contain', // Ensure objectFit is part of the main style
  },
  isFullScreenColor: {
    color: "white"
  },
  videoNativeStyle: 
  { 
    objectFit:'contain', 
    width: '100%', 
    height: '100%',
  }

});
export default VideoMedia;
