import { Asset } from 'expo-asset';
import { getAsset } from '../../../functions/getAsset';



  export const Partner_Tributes = [{
    Id: -2000002,
    
    UserId: null,
    UserProfileId: null,
    UserProfileFullName: "Partner",
    UserName: "Partner",
    UserImage: getAsset(require('../../../../assets/images/store/profiles/partner/main_image.png')),
    // UserImage: "",

    CreatedDate: "10/07/2024",
    Title: "Beach",
    Description: "A fun day at the beach",

    album: [
      {
          Id: -200001,
          PostId: -20001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_2.png')),
          // URL: "",
          Duration: null,
      },
      {
          Id: -200002,
          PostId: -20001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_3.png')),
          // URL: "",
          Duration: null,
      },
      {
          Id: -200003,
          PostId: -20001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_4.png')),
          // URL: "",
          Duration: null,
      },
      {
          Id: -200004,
          PostId: -20001,
          MediaType: 'image',
          URL: getAsset(require('../../../../assets/images/store/profiles/partner/creative_workshop/creative_workshop_1.png')),
          // URL: "",
          Duration: null,
      },
  ],
    IsUserLiked: false,
    Comments: [],
    Likes: 0,
    IsActive: true,
    CreatedDate: "2024-07-10",
  }];
