import React, { useState } from 'react';
import { StyleSheet,View,Text, Alert, TouchableOpacity, Dimensions, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton"
import { GlobalStyles } from '../../constants/styles';
import { FontAwesome } from '@expo/vector-icons';
import ButtonTypeText from '../inputs/ButtonTypeText';
import { navigate } from '../../navigationRef';
import i18n from '../../hooks/useI18n';
// import { GoogleSignin, GoogleSigninButton } from '@react-native-google-signin/google-signin';
// import auth from '@react-native-firebase/auth';
import { navigateToHomeScreen } from '../../functions/navigateToHomeScreen';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';
import CompanyFooter from '../CompanyFooter';

const AuthForm = ({ onSubmit,
  signInGoogle,
    ShowPassword, 
    signinAlertMessage, 
    email, 
    setEmail,
    Keyboard, 
    ShowForgotPassword,
    isKeyboardVisible,
    keyboardDidShowTxt,
    refreshPage,
    addErrorMessage,
    onFocus,
    onBlur,
    navigation,
    userSettingsState,
    showThirdView,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [googleShowLoader, setGoogleShowLoader] = useState(false);


  if(signinAlertMessage !== "" && showLoader === true)
  {
    setShowLoader(false);
  }
  const onGoogleButtonPress = async() => {
    return null;
  }
  // if(Platform.OS !== "android" && Platform.OS !== "ios"){
    
  // }
  // else{
  //   GoogleSignin.configure({
  //     webClientId: '619539221125-4cfdpm1njnkho3pfgejibaro3mp4o5ea.apps.googleusercontent.com',
  //   });
  // }
  // const onGoogleButtonPress = async() => {
  //   if(Platform.OS !== "android" && Platform.OS !== "ios"){
  //     console.log('Google Sign-In is not supported on this platform.');
  //     return;
  //   }
  //   try{
  //     await GoogleSignin.revokeAccess();
  //     await auth().signOut();
  //   }
  //   catch(error)
  //   {
  //     console.log(error);
  //   }
  //   // Check if your device supports Google Play
  //   await GoogleSignin.hasPlayServices({ showPlayServicesUpdateDialog: true });
  //   // Get the users ID token
  //   const { idToken } = await GoogleSignin.signIn();
  //   // Create a Google credential with the token
  //   const googleCredential = auth.GoogleAuthProvider.credential(idToken);
  //   // Sign-in the user with the credential
  //   const user_sign_in = auth().signInWithCredential(googleCredential);
  //   user_sign_in.then(async (user) => {
  //     console.log(user);
  //     setGoogleShowLoader(true);
  //     Keyboard.dismiss();
  //     const response = await signInGoogle({ user, idToken});
  //     setGoogleShowLoader(false);
  //     if(response.isCompleted === true)
  //     {
  //       navigation.navigate("ResolveAuth");
  //     }
      
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   })
  //   // return user_sign_in;
  // }


  return (
    <View style={styles.container}>
      <View style={styles.textInputContainers}>
       <TextInputCustom
       userSettingsState={userSettingsState}
       editable={true}
       value={email}
       onChangeText={(text) => {
        setEmail(text);
      }}
      label={i18n.t('auth.EmailInput')}
      style={[styles.textInputStyle
        // , { backgroundColor:'yellow',  }
      ]}
      iconType="cancel"
      // isKeyboardVisible={isKeyboardVisible}
      // keyboardDidShowTxt={keyboardDidShowTxt}
      refreshComponent={refreshPage}
      onFocus={onFocus}
      onBlur={onBlur}
      />
      <TextInputCustom
      userSettingsState={userSettingsState}
      secureTextEntry={true}
      editable={true}
      value={password}
      onChangeText={(text) => {
        setPassword(text);
      }}
      label={i18n.t('auth.SignIn_PasswordInput')}
      style={[styles.textInputStyle
        // , { backgroundColor:'blue', }
      ]}
      iconType="password"
      // isKeyboardVisible={isKeyboardVisible}
      // keyboardDidShowTxt={keyboardDidShowTxt}
      refreshComponent={refreshPage}
      onFocus={onFocus}
      onBlur={onBlur}
      />
        <View style={styles.submitButtonContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {
          if(email.length === 0)
          {
            // EmailRequired();
            addErrorMessage("signinAlertMessage","EmailRequired");
          }
          else if(ShowPassword && password.length === 0)
          {
            // PasswordRequired();
            addErrorMessage("signinAlertMessage","PasswordRequired");

          }
          else{
            setShowLoader(true);
            Keyboard.dismiss();
            const response = await onSubmit({ email, password });  
            setShowLoader(false);
            if(response.isCompleted === true)
            {
              navigateToScreen(navigation, 'ResolveAuth', `ResolveAuth`, null, null);
              // navigation.navigate("ResolveAuth");
              // navigateToHomeScreen(navigation, false);
            }
          } 
        }}
        styleButton={styles.buttonStyle}
        loading={showLoader}
        >
        {i18n.t('auth.SignIn_LogInButton')}
          </ButtonTypeButton>
          </View>
      
          {ShowForgotPassword === true ? (
            <View style={styles.forgotPasswordContainer}>
            <ButtonTypeText onPress={() => {
              navigateToScreen(navigation, 'LoginFlow', `ForgotPassword`, null, null);
              // navigate('ForgotPassword');
            }} 
            userSettingsState={userSettingsState}
            >{i18n.t('auth.SignIn_ForgotPasswordButton')}
            </ButtonTypeText>
            </View>
          ) : null}

          {responsiveStyles.isMobile && showThirdView === false ? null : (

  <View style={styles.createNewAccountContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={() => {
          navigateToScreen(navigation, 'LoginFlow', `SignUpWithEmail`, { isEmail: true }, null);
          // navigate('LoginFlow', { screen: 'SignUpWithEmail', params: { isEmail: true } });
          // navigate('SignUpWithEmail',{ isEmail: true });
        }}
        styleButton={[styles.buttonStyle,{ minWidth: 250 }]}
        style={{alignItems: 'center'}}
        >
        {i18n.t('auth.SignIn_CreateNewAccountButton')}
          </ButtonTypeButton>
          </View>
    )}
          {/* {googleShowLoader === true ? 
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          loading={googleShowLoader}
          styleButton={{backgroundColor: 'white',marginTop: 14, height: 40, width : responsiveStyles.screenWidth * 0.85,}}
          >
            
          </ButtonTypeButton> : 
          Platform.OS !== "android" && Platform.OS !== "ios" ? null :
          <TouchableOpacity style={{ marginHorizontal: -18, marginTop: 10 }} onPress={() => onGoogleButtonPress()} activeOpacity={0.75}>
      <GoogleSigninButton 
        style={{ width: '100%' }}
        onPress={() => onGoogleButtonPress()}
        />
        </TouchableOpacity>
   } */}
        </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  // buttonStyle: {
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.secondaryButtonBackgroundColor,
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
  //   alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  // },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  textInputContainers: {
    flex: 1,
    // justifyContent: !responsiveStyles.isMobile ? null : 'space-around',
    // justifyContent: !responsiveStyles.isMobile ? null : 'space-evenly',
    // backgroundColor:'orange',
    marginTop: 20,

  },
  textInputContainer: {
    flex:1,
    marginBottom: responsiveStyles.isWeb ? 15 : null
  },
  textInputStyle: {
    fontFamily: GlobalStyles.fonts.application_font,
    marginBottom: 20,
    backgroundColor:'transparent'
  },
  submitButtonContainer: 
  {
    marginBottom: !responsiveStyles.isMobile ? 20 : 20, 
    alignSelf:'center',
  },
  forgotPasswordContainer: {
    marginBottom: !responsiveStyles.isMobile ? 20 : 20, 
  },
  createNewAccountContainer: {
    // alignItems:'center',
    flex:1,
    justifyContent:'flex-end',
    // backgroundColor: 'red',
    paddingBottom: 20,
  },
});

export default AuthForm;
