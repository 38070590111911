// Assuming createDataContext is a custom function that creates context and provider
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "../hooks/useI18n";
import createDataContext from "./createDataContext";
import { getItem, saveAsync } from "../modules/Storage";
import { Cart_Storage, Discount_Storage, ShippingDetails_Storage, ShippingMeathod_Storage } from "../modules/StorageObjects";

const ADD_OR_UPDATE_ITEM_TO_CART = 'ADD_OR_UPDATE_ITEM_TO_CART';
const INIT_CONTEXT = 'INIT_CONTEXT';
const DELETE_ITEM_FROM_CART = 'DELETE_ITEM_FROM_CART';
const UPDATE_SHIPPING_DETAILS = 'UPDATE_SHIPPING_DETAILS';
const UPDATE_SHIPPING_MEATHOD = 'UPDATE_SHIPPING_MEATHOD';
const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';
const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
export const shippingAmount = 30;
export const shippingInternational = i18n.t('store.shippingInternational');
// export const shippingInternational = 'International Flat Rate';


const cartInit = [];
// const shippingDetailsInit = {
//   Country: 'Israel',
//   FirstName: 'Joe',
//   LastName: 'Doe',
//   Address: 'Savyon Hadekel 12',
//   Apartment: '',
//   PostalCode: '',
//   City: 'Savyon',
//   Phone: '0500000000',
// };
const shippingDetailsInit = {
  Country: '',
  FirstName: '',
  LastName: '',
  Address: '',
  Apartment: '',
  PostalCode: '',
  City: '',
  Phone: '',
};
const discountInit = {
  discountCode: '',
  discountPercentage: 0,
};
const shippingMethodInit = {
  Id: 1,
  type: 'international shipping',
  price: shippingAmount,
  name: shippingInternational,
};
const paymentDetailsInit =  {
  CreditCardType: 'visa',
  CardNumber: '',
  NameOnCard: '',
  ExpirationDate: '',
  SecurityCode: '',
}


const reducer = (state, action) => {
  switch (action.type) {
    case INIT_CONTEXT:
    return { ...state, 
      cart: action.payload.cart ? action.payload.cart : state.cart,
      shippingDetails: action.payload.shippingDetails ? action.payload.shippingDetails : state.shippingDetails,
      discount: action.payload.discount ? action.payload.discount : state.discount,
      shippingMethod: action.payload.shippingMethod ? action.payload.shippingMethod : state.shippingMethod,
     };
case ADD_OR_UPDATE_ITEM_TO_CART:
  // const existingItemIndex = state.cart.findIndex(item => item.itemId === action.payload.Id);
  const existingItemIndex = state.cart.findIndex(item => 
    item.itemId === action.payload.Id &&
    item.item.materialColor === action.payload.Item.materialColor &&
    item.item.stoneColor === action.payload.Item.stoneColor &&
    item.item.frontSideText === action.payload.Item.frontSideText &&
    item.item.backSideText === action.payload.Item.backSideText &&
    item.item.chainSize === action.payload.Item.chainSize &&
    item.item.braceletSize === action.payload.Item.braceletSize &&
    item.item.ringSize === action.payload.Item.ringSize
  );
  console.log(`
    existingItemIndex: ${existingItemIndex}

    state.cart: ${JSON.stringify(state.cart)}
    
    action.payload: ${JSON.stringify(action.payload)}
    `);
  
  if (existingItemIndex !== -1) {
    let updatedCart = [...state.cart];
    updatedCart[existingItemIndex].quantity += action.payload.Amount;
    updatedCart = updatedCart.filter(item => item.quantity > 0);
    saveAsync(Cart_Storage, updatedCart);

    return { ...state, cart: updatedCart };
  } else {
    let updatedCart = [
      ...state.cart,
      { item: action.payload.Item , itemId: action.payload.Id, quantity: action.payload.Amount }
    ];
    saveAsync(Cart_Storage, updatedCart);
    return {
      ...state,
      cart: updatedCart
    };
  }
case DELETE_ITEM_FROM_CART:
  const { selectedItem } = action.payload;
  // const Id = selectedItem.Id;
  // const indexToDelete = state.cart.findIndex(item => item.itemId === Id);
  const indexToDelete = state.cart.findIndex(item => 
    item.itemId === selectedItem.itemId &&
    item.item.materialColor === selectedItem.item.materialColor &&
    item.item.stoneColor === selectedItem.item.stoneColor &&
    item.item.frontSideText === selectedItem.item.frontSideText &&
    item.item.backSideText === selectedItem.item.backSideText &&
    item.item.chainSize === selectedItem.item.chainSize &&
    item.item.braceletSize === selectedItem.item.braceletSize &&
    item.item.ringSize === selectedItem.item.ringSize
  );
  if (indexToDelete !== -1) {
    // If item exists and its quantity is less than or equal to the specified Amount, remove it from cart

    // const updatedCart = state.cart.filter(item => item.itemId !== Id);
    const updatedCart = state.cart.filter(item => 
    !(item.itemId === selectedItem.itemId &&
    item.item.materialColor === selectedItem.item.materialColor &&
    item.item.stoneColor === selectedItem.item.stoneColor &&
    item.item.frontSideText === selectedItem.item.frontSideText &&
    item.item.backSideText === selectedItem.item.backSideText &&
    item.item.chainSize === selectedItem.item.chainSize &&
    item.item.braceletSize === selectedItem.item.braceletSize &&
    item.item.ringSize === selectedItem.item.ringSize
    ));
    saveAsync(Cart_Storage, updatedCart);
    return { ...state, cart: updatedCart };
  } else {
    return state;
  }
  case UPDATE_SHIPPING_DETAILS:
    saveAsync(ShippingDetails_Storage, action.payload);
    return { ...state, shippingDetails: action.payload };
  case UPDATE_SHIPPING_MEATHOD:
    saveAsync(ShippingMeathod_Storage, action.payload);
    return { ...state, shippingMeathod: action.payload };
  case UPDATE_PAYMENT_DETAILS:
    return { ...state, paymentDetails: action.payload };
  case APPLY_DISCOUNT:
    const discountOBJ = {
      discountCode: action.payload.discountCode,
      discountPercentage: action.payload.discountPercentage,
    };
    saveAsync(Discount_Storage, discountOBJ);
    return { ...state, discount: discountOBJ };
    default:
      return state;
  }
};

const finishedOrder = dispatch => async () => {
  await saveAsync(Cart_Storage, null)
  await saveAsync(ShippingDetails_Storage, null);
  await saveAsync(ShippingMeathod_Storage, null);
  await saveAsync(Discount_Storage, null);
  dispatch({ type: INIT_CONTEXT, payload: { 
    cart: cartInit,
    shippingDetails: shippingDetailsInit,
    discount: discountInit,
    shippingMethod: shippingMethodInit,
   } });
};
const initContext = dispatch => async () => {
  const Cart = await getItem(Cart_Storage)
  const ShippingDetails = await getItem(ShippingDetails_Storage)
  const ShippingMeathod = await getItem(ShippingMeathod_Storage)
  const Discount = await getItem(Discount_Storage)
  dispatch({ type: INIT_CONTEXT, payload: { 
    cart: Cart ? Cart : cartInit,
    shippingDetails:  ShippingDetails ? ShippingDetails : shippingDetailsInit,
    discount: Discount ? Discount : discountInit,
    shippingMethod: ShippingMeathod ? ShippingMeathod : shippingMethodInit,
   } });
};
const addOrUpdateItemToCart = dispatch => (Item, Id, Amount) => {
  console.log(`func addOrUpdateItemToCart got Item: ${JSON.stringify(Item)}, Id: ${Id} Amount: ${Amount}`);
  dispatch({ type: ADD_OR_UPDATE_ITEM_TO_CART, payload: { Item, Id, Amount } });
};

const deleteItemFromCart = dispatch => (selectedItem) => {
  dispatch({ type: DELETE_ITEM_FROM_CART, payload: { selectedItem } });
};

const updateShippingDetails = dispatch => (shippingDetails) => {
  dispatch({ type: UPDATE_SHIPPING_DETAILS, payload: shippingDetails });
};

const updateShippingMeathod = dispatch => (shippingMethod) => {
  // dispatch({ type: UPDATE_SHIPPING_MEATHOD, payload: shippingMethod });
};
const updatePaymentDetails = dispatch => (paymentDetails) => {
  dispatch({ type: UPDATE_PAYMENT_DETAILS, payload: paymentDetails });
};

const applyDiscount = dispatch => (discountCode, discountPercentage) => {
  dispatch({ type: APPLY_DISCOUNT, payload: { discountCode, discountPercentage } });
};

export const { Provider, Context } = createDataContext(
  reducer,
  { 
    initContext,
    finishedOrder,
    addOrUpdateItemToCart, 
    deleteItemFromCart,
    updateShippingDetails,
    updateShippingMeathod,
    updatePaymentDetails,
    applyDiscount,
   },
  { 
    cart: cartInit,
    shippingDetails: shippingDetailsInit,
    discount: discountInit,
    shippingMethod: shippingMethodInit,
    paymentDetails: paymentDetailsInit,
   }
);
