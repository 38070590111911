import { Alert, Linking, Share } from "react-native";
import i18n from "../hooks/useI18n";

export const shareApp = async (message) => {
    try {
        // const message = "bruh check out this app";
      const result = await Share.share({
        message: message,
      });
      if(result.action === Share.sharedAction)
      {
        if(result.activityType){
          console.log(`shaed with activity type of: ${result.activityType}`);
        }
        else{
          console.log(`shared`);
        }
      } else if(result.action === Share.dismissedAction)
      {
        console.log(`dismissed`);
      }
    }
    catch(error){
      console.log(error.message);
    }
  };

  export const shareTributesLink = async (UserName, UserProfileId) => {
    let message = `${i18n.t(`media.TributesLink_message1`)} '${UserName}' ${i18n.t(`media.TributesLink_message2`)}
    https://timelessmemories-jewelry.com/ManageTributePost/${UserProfileId}/CreatePostTributeInvitation
    `;
    await shareApp(message);
  }
  export const shareProfileLink = async (UserName, JewelryId) => {
    let message = `${i18n.t(`media.ShareProfile_message1`)} '${UserName}' ${i18n.t(`media.ShareProfile_message2`)}
    https://timelessmemories-jewelry.com/myjewelry/${JewelryId}
    `;
    await shareApp(message);
  }
  export const sendSms = async (number) => {
    if(number.startsWith('05')){
      number = number.replace(/^05/, '+9725');
    }
    let url = `sms:${number}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          Alert.alert("Error", "Your device doesn't support this feature.");
        }
      })
      .catch((err) => Alert.alert("Error", "Failed to open dialer."));
  }
  export const dialNumber = async (number) => {
    const url = `tel:${number}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          Alert.alert("Error", "Your device doesn't support this feature.");
        }
      })
      .catch((err) => Alert.alert("Error", "Failed to open dialer."));
  }
  export const EmailLink = async () => {
    const url = `mailto:${i18n.t('footer.Email')}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(url);
        } else {
          Alert.alert("Error", "Your device doesn't support this feature.");
        }
      })
      .catch((err) => Alert.alert("Error", "Failed to open email client."));
  }