export default {
    Email: `timelessmemoriesjewelryshop@gmail.com`,
    Phone: `0587257993`,
    AboutUs: "About Us",
    AboutUs_Description: "Connect with memories and loved ones through a TMJ NFC jewelry piece. Simply tap to discover shared moments and create new ones, all in one beautiful keepsake.",
    AboutUs_Address: `70 Address, City, Country PostCode`,
    FooterLinks_Title: "Quick Links",
    FooterLinks_Home: "HOME",
    FooterLinks_AboutUs: "ABOUT US",
    FooterLinks_AMBASSADOR: "BECOME AN AMBASSADOR",
    FooterLinks_Help: "HELP CENTER",
    FooterLinks_Terms: "TERMS & CONDITIONS",
    FooterLinks_Privacy: "PRIVACY",
    FooterLinks_ContactUs: "CONTACT US",
    FooterLinks_Tutorial: "TUTORIALS",
   };