import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, FlatList, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";
import { GenericAlert } from "../alert/GenericAlert";
import CartEmptyListComponent from "../displayComponent/CartEmptyListComponent";
import QuantityStoreItemComponent from "./QuantityStoreItemComponent";
import { Feather } from '@expo/vector-icons';
import { Context as ShopContext } from '../../context/ShopContext';
import ShoppingCartComponent from "./ShoppingCartComponent";
import DiscountComponent from "./_DiscountComponent";
import TotalItemsComponent from "./TotalItemsComponent";
import CheckoutButtonComponent from "./CheckoutButtonComponent";
import FloatingCartHeaderComponent from "./FloatingCartHeaderComponent";
import ShoppingCartFullInfoComponent from "./ShoppingCartFullInfoComponent";
import { getTotalPrice } from "../../functions/getShopInfo";
import useResponsiveStyles from "../../functions/ResponsiveStyles";


  const OrderSummeryComponent = ({ 
    userSettingsState, 
    showShippingFee = false,
    navigation,
    displayAsFinalProduct = false,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const [showOrderSummery, setShowOrderSummery] = useState(displayAsFinalProduct);
    const {state: shopState } = useContext(ShopContext);
    let totalPrice = getTotalPrice(shopState, showShippingFee, true);
  const styles = styling(responsiveStyles, displayAsFinalProduct);
  return (
    <View style={styles.container}>
      <TouchableOpacity 
      style={styles.orderSummeryContainer}
      onPress={() => {
        if(!displayAsFinalProduct){
          setShowOrderSummery(!showOrderSummery);
        }
      }}
      >
          <View style={{flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', alignItems:'center', justifyContent:'center'}}>
  <AntDesign 
  name="shoppingcart" 
  size={24} 
  color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
  style={styles.shoppingCartIcon}
   />
      {displayAsFinalProduct ? <>
        <Text style={[styles.text, styles.title]}>
        {i18n.t('store.OrderSummeryComponent_SummeryText')}
        </Text>
        </> :
      <>
      <Text style={[styles.text, styles.title]}>
        {!showOrderSummery ? i18n.t('store.OrderSummeryComponent_Show') : i18n.t('store.OrderSummeryComponent_Hide')} {i18n.t('store.OrderSummeryComponent_SummeryText')}
        </Text>
      <AntDesign name="down" size={14} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} style={{paddingTop: 5, paddingHorizontal: 5}} />
      </>
          }
        </View>

      <Text style={[styles.text, styles.price]}>{totalPrice} ₪</Text>
      </TouchableOpacity>
      {showOrderSummery ? 
    <ShoppingCartFullInfoComponent
     userSettingsState={userSettingsState} 
     isOrderSummery={true}
     showShippingFee={showShippingFee}
     navigation={navigation}
     displayAsFinalProduct={displayAsFinalProduct}
     />
     : null }
    </View>
  );
}
const styling = (responsiveStyles, displayAsFinalProduct) => StyleSheet.create({
  container: {
  },
  orderSummeryContainer: {
    backgroundColor: displayAsFinalProduct ? null : GlobalStyles.colors.Gold,
    paddingHorizontal: !responsiveStyles.isMobile ? 40 : 20,
    padding: 20,
    paddingTop: displayAsFinalProduct ? 0 : 20,
    paddingBottom: 10,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent:'space-between',
    // alignItems:'center',
  },
  title: {
    fontSize: !responsiveStyles.isMobile ? responsiveStyles.fontSize_H3 : responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '600',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
  },
  price: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles.colors.Lavender,
  },
  shoppingCartIcon: {
    paddingRight: responsiveStyles.isRTL ? null : 10,
    paddingLeft: !responsiveStyles.isRTL ? null : 10,
  },
  
});

export default OrderSummeryComponent;