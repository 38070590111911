import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../../context/UserSettingsContext";
import { GlobalStyles } from '../../../constants/styles';
import Screen from "../../../components/Screen";
import i18n from '../../../hooks/useI18n';
import { GenericAlert } from '../../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../../constants/ScreenDimensions';
import ImageProfile from '../../../components/images/ImageProfile';
import BuisnessFooter from '../../../components/store/BuisnessFooter';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import BulletText from '../../../components/inputs/BulletText';
import useResponsiveStyles from '../../../functions/ResponsiveStyles';

const HelpScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Help";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <ScrollView style={[styles.container]}>
    <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={{padding: 10}}>
      
      <View style={styles.TitleContainer}>
        <Text style={[styles.title,styles.mainTitle]}>
        {i18n.t('help.Title')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('help.Section1_1')}</Text>
      </Text>
      </View>

      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('help.FrequentlyAskedQuestions')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}>1. {i18n.t('help.Section2_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section2_1')} />

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}>2. {i18n.t('help.Section3_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section3_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section3_2')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section3_3')} />


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}>3. {i18n.t('help.Section4_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section4_1')} />


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}>4. {i18n.t('help.Section5_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section5_1')} />


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}>5. {i18n.t('help.Section6_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section6_1')} />


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}>6. {i18n.t('help.Section7_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Section7_1')} />



      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('help.Troubleshooting')}
          </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.Problem')}</Text>
        <Text style={[styles.text]}> {i18n.t('help.Troubleshooting_Problem1')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.Solution')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution1_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution1_2')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution1_3')} />

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.Problem')}</Text>
        <Text style={[styles.text]}> {i18n.t('help.Troubleshooting_Problem2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.Solution')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution2_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution2_2')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution2_3')} />



      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.Problem')}</Text>
        <Text style={[styles.text]}> {i18n.t('help.Troubleshooting_Problem3')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.Solution')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.Troubleshooting_Solution3_1')} />


      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('help.ManageJewelryAndProfiles')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.ManageJewelryAndProfiles1_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.ManageJewelryAndProfiles1_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.ManageJewelryAndProfiles1_2')} />


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold]}> {i18n.t('help.ManageJewelryAndProfiles2_Title')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.ManageJewelryAndProfiles2_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.ManageJewelryAndProfiles2_2')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('help.ManageJewelryAndProfiles2_3')} />


      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('help.ContactUs')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('help.ContactUs_1')}</Text>
        </Text>
      </View>
      
      <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('help.ContactUs_Email')} </Text>
        <Text style={styles.text}>{i18n.t('footer.Email')} </Text>
        </>} />

        <BulletText userSettingsState={userSettingsState} text={<>
          <Text style={[styles.text,styles.textBold]}>{i18n.t('help.ContactUs_Phone')} </Text>
          <Text style={styles.text}>{i18n.t('footer.Phone')} </Text>
        </>} />


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('help.FinalWords')} </Text>
        </Text>
      </View>

      </View>
      </View>
      <BuisnessFooter userSettingsState={userSettingsState} navigation={navigation} />
    </ScrollView>
    </Screen>

}

HelpScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.5 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  TitleContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
  },
  mainTitle: {
    fontSize: responsiveStyles.fontSize_H1,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '900',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  DescriptionContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    paddingVertical: 5,
    paddingBottom: 20,
  },
  secondPharagraph: {
    paddingTop: 20,
  },
  text: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    lineHeight: responsiveStyles.fontSize_H5 * 1.5,
    
},
  textBold: {
    fontWeight: 'bold',
  },
});

export default HelpScreen;
