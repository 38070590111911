import { Alert, View } from "react-native";
import { useEffect, useState } from "react";
import i18n from "../../hooks/useI18n";
import CustomAlert from "../modals/CustomAlert";

// function AccountNotFoundAlert() {
//     Alert.alert(i18n.t('auth.ForgotPassword_AccountNotFoundAlert_subject'),
//     i18n.t('auth.ForgotPassword_AccountNotFoundAlert_message'), [
//         {
//           text: i18n.t('auth.ForgotPassword_AccountNotFoundAlert_okButton'),
//         },
//       ],
//       {cancelable:true}
//       );
//   }
  
 
// export {AccountNotFoundAlert};

export const ForgotPasswordAlert = ({ 
  navigation, 
  state, 
  addErrorMessage,
  userSettingsState,
 }) => {
  const [AccountNotFoundVisible, setAccountNotFoundVisible] = useState(false);
  const [ServerMessageVisible, setServerMessageVisible] = useState(false);
  const [MessageFromServer, setMessageFromServer] = useState("");
useEffect(() => {
  
    if(state.forgotPasswordAlertMessage === true)
    {
      // AccountNotFoundAlert();
      setAccountNotFoundVisible(true);
    }
    else if(state.forgotPasswordAlertMessage?.length > 0)
    {
      const message = state.forgotPasswordAlertMessage;
      setServerMessageVisible(true);
      setMessageFromServer(message);
    }
    addErrorMessage("forgotPasswordAlertMessage",false);
  }, [state.forgotPasswordAlertMessage]);

  const hideAccountNotFoundAlert = () => {
    setAccountNotFoundVisible(false);
  };
  const hideServerMessageVisibleAlert = () => {
    setServerMessageVisible(false);
};
  return (
    <View>
    <CustomAlert
      visible={AccountNotFoundVisible}
      onClosePress={hideAccountNotFoundAlert}
      userSettingsState={userSettingsState}
      headermessage={i18n.t('auth.ForgotPassword_AccountNotFoundAlert_subject')}
      bodymessge={i18n.t('auth.ForgotPassword_AccountNotFoundAlert_message')}
      buttonmessage1={i18n.t('auth.ForgotPassword_AccountNotFoundAlert_okButton')}
    />
    <CustomAlert
        visible={ServerMessageVisible}
        onClosePress={hideServerMessageVisibleAlert}
        userSettingsState={userSettingsState}
        bodymessge={MessageFromServer}
        buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton')}
      />
  </View>
  );
};