import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import ImageProfile from "./components/images/ImageProfile";
import { GlobalStyles, Lavender } from "./constants/styles";
import { Feather } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import i18n from "./hooks/useI18n";
import { Ionicons } from '@expo/vector-icons'; 
import ButtonTypeText from "./components/inputs/ButtonTypeText";
import ButtonTypeButton from "./components/inputs/ButtonTypeButton";
import useResponsiveStyles from "./functions/ResponsiveStyles";

  const CommendComponent = ({ 
    userSettingsState,
    isLike = false,
    onLikePress = null,
    onCommentPress = null,
    showEditButton = false,
    onEditPress = null,
    IsDemo = false,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  
  return (
    <View style={styles.commendContainer}>
    <TouchableOpacity 
    onPress={onLikePress}
    activeOpacity={!IsDemo ? 0.25 : 1}
    >
    <Ionicons name={isLike ? "heart" : 'heart-outline'} size={28} color={isLike ? Lavender : GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} style={{ paddingHorizontal: 20 }} />
    </TouchableOpacity>

    <TouchableOpacity onPress={onCommentPress}>
    <FontAwesome5 name="comment" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
    </TouchableOpacity>

    {showEditButton ? 
    // <TouchableOpacity onPress={onEditPress} style={{ paddingHorizontal: 20 }}>
    <ButtonTypeButton
    onPress={onEditPress}
    style={styles.StyleButtonContainer}
    styleButton={styles.styleButton}
    userSettingsState={userSettingsState}
    >
    <Text style={styles.text}>{i18n.t('media.CommendComponent_Edit')}</Text>
    </ButtonTypeButton>
    // </TouchableOpacity>
    : null }
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    commendContainer: {
        flex:1,
        alignItems:responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
        alignItems:'center',
        paddingTop: 10,
      },
      text: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H6,
        fontFamily: GlobalStyles.fonts.application_font,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        
    },
    StyleButtonContainer: {
      alignItems: responsiveStyles.isRTL ? 'flex-end' : "flex-start",
      flex:1,
      paddingHorizontal: 10,
    },
    styleButton:{
      //  backgroundColor: GlobalStyles.colors.AddButton,
      borderRadius: 50,
      padding: 5,
      paddingHorizontal: 15,
    
   },
});

export default CommendComponent;