import React, { useCallback, useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { GetJewleryById, GetOrderById } from '../../api/apiCalls/ProfileCalls';
import OrderItemsListComponent from '../../components/myAccountScreen/OrderItemsListComponent';
import OrderItemsListDetailsComponent from '../../components/myAccountScreen/OrderItemsListDetailsComponent';
import ImageProfile from '../../components/images/ImageProfile';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import QRCodeGenerator from '../../components/QRCodeGenerator';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { navigateToScreen } from '../../navigationResponsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../functions/getImageUrl';
import NotFoundComponent from '../../components/NotFoundComponent';
import { isValidGuid } from '../../functions/utils';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { StoreItems } from '../../constants/store/Store_Items';
const JewleryDetailsScreen = ({  }) => {

  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const Id = navigationParams?.Id || IdFromURL;
  const IsIdValid = isValidGuid(Id);

    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const [jewlery, setJewlery] = useState(null);
    const [profile, setProfile] = useState(null);

    const foundStoreItem = StoreItems.find(storeItem => 
      storeItem.Items.find(item1 => item1.Id === jewlery?.JewleryId)
    );
let foundItem = foundStoreItem ? foundStoreItem.Items.find(item1 => item1.Id === jewlery?.JewleryId) : null;

    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);


    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };

    const InitFunction = () => {
      if(isFirstTime)
        {
          fetchData();
          setIsFirstTime(false);
        }
        else{
          // setLoading(true);
          setErrorOnRequest(false);
          if(errorOnRequest === true)
          {
        }
        else{
            
            fetchData();
          }
        }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    }
    const fetchData = async () => {
      try {
          console.log("fetchData");
        setLoading(true);
        const req = await GetJewleryById(Id);
        if (req.isCompleted) {
          const vm = JSON.parse(req.answer);
          setJewlery(vm.Jewlery);
          setProfile(vm.Profile);
        }
        else{
          setLoading(true);
          setErrorOnRequest(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(true);
        setErrorOnRequest(true);
      }
      finally{
        setLoading(false);
      }
    };
    if(responsiveStyles.isWeb){
      useEffect(() =>{
        if(responsiveStyles.isWeb){
          document.title = "Jewlery Details";
        }
        InitFunction();
    },[reloadpage])
    }
    else{
      useFocusEffect(
        useCallback(() =>{
          InitFunction();
      },[reloadpage])
    );
    }

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    {
      !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
    : errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
    <ScrollView style={[styles.container]}>
      <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={{padding: 20}}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{i18n.t('account.JewleryDetailsScreen_Title')}</Text>
    </View>
    <View style={styles.imageContainer}>
      <ImageProfile
      userSettingsState={userSettingsState}
    uri={getImageUrl(jewlery.ImagePath)}
  //  uri={jewlery.Image}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
  isTouchable={false}
    />
</View>
    
      <Text style={styles.JewleryName}>{foundItem ? responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_he : jewlery.JewleryName}</Text>
    

    <View style={styles.rowContainer}>
    <QRCodeGenerator 
    userSettingsState={userSettingsState}
    Id={Id} 
    barcodeSize={
      responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 
      : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
      : responsiveStyles.screenWidth * 0.4} 
    // barcodeSize={!responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.4}
     />
    </View>

    
    <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
         onPress={() => {
          
          // // navigate to Profile of the user who is assigned
          // navigateToScreen(navigation, 'AccountFlow', `Profile`, { UserId: jewlery.UserProfileId, FromScreen: "FromAccount" }, jewlery.UserProfileId);

          // navigate to Jewelry screen
          navigateToScreen(navigation, 'AccountFlow', `myjewelry`, { UserId: jewlery.Id, FromScreen: "FromAccount" }, jewlery.Id);


          // navigation.navigate('AccountFlow', { screen: 'ProfileFromAccount', params: { UserId: jewlery.UserProfileId, FromScreen: "FromAccount" } });
            // navigation.navigate("ProfileFromAccount", { UserId: jewlery.UserProfileId, FromScreen: "FromAccount" });
        }}
        >
          {i18n.t('account.JewleryDetailsScreen_ShowProfileButton')}
    </ButtonTypeButton>
    </View>
    {profile ? 
    <View style={styles.rowContainer}>
      <Text style={styles.text}>{i18n.t('account.JewleryDetailsScreen_Profile')}:</Text>
      <Text style={styles.text}>{profile?.FullName || profile?.PetName}</Text>
    </View>
    : 
    <View style={{ alignItems:'center', marginBottom: 20 }}>
    <Text style={[styles.text, { color: GlobalStyles.errorMessage.color }]}>{i18n.t('account.UNVERIFIED_UserProfile')}</Text>
    </View> 
    }

    <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
         onPress={() => {
          navigateToScreen(navigation, 'AccountFlow', `AssignJewleryToProfile`, { Id: Id }, Id);
          // navigation.navigate('AccountFlow', { screen: 'AssignJewleryToProfile', params: { Id: Id } });
            // navigation.navigate("AssignJewleryToProfile", { Id: Id });
        }}
        >
          {i18n.t('account.JewleryDetailsScreen_AssignProfileButton')}
    </ButtonTypeButton>
    </View>
    </View>
    </View>
    </ScrollView>
    )}
    </Screen>

}


JewleryDetailsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // padding: 20,
    // height: fixedScreenHeight,
  },
  subContainer: {
    flex: 1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  header: {
    flex: 1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  titleContainer: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
},
title: {
  fontSize: responsiveStyles.fontSize_H3,
    fontWeight: '700',
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
text: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
rowContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent: 'space-around',
  paddingBottom: 20,
  // flex:1,
  paddingHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : null,
},
imageContainer: {
    marginHorizontal: -20,
    width: responsiveStyles.screenWidth,
    alignSelf:'center',
    marginBottom: 20,
},
userPhoto: {
  // width: responsiveStyles.screenWidth,
  // height: responsiveStyles.screenWidth,
  //   maxHeight: 300,
  width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth,
    aspectRatio: 1 / 1,
    alignSelf:'center',
    borderRadius: !responsiveStyles.isMobile ? 25 : null,
  },
  JewleryName: {
    fontSize: responsiveStyles.fontSize_H4,
  fontFamily: GlobalStyles.fonts.application_font,
  textAlign: 'center',
  fontWeight: '700',
  // paddingVertical: 10,
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  marginBottom: 20,
  },
  buttonContainer: {
    marginBottom: 20,
    alignItems:'center',
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent:'space-around'
  },
  button: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.4,
    minWidth: !responsiveStyles.isMobile ? 100 : null,
  },
});

export default JewleryDetailsScreen;
