import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Dimensions,
  BackHandler,
  useWindowDimensions,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native'; // Import useFocusEffect from @react-navigation/native
import { Context as AuthContext } from '../../context/AuthContext';
import SignUpForm from '../../components/forms/SignUpForm';
import NavLink from '../../components/NavLink';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import i18n from '../../hooks/useI18n';
import { SignUpAlert } from '../../components/alert/SignUpAlert';
import { getNavigationParams } from '../../functions/navigationLogic';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigate } from 'react-router-dom';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const SignUpWithEmailScreen = ({  }) => {

  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  console.log("SignUpWithEmailScreen");

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const windowHeight = responsiveStyles.screenHeight;
  const navigationParams = getNavigationParams(navigation);
  // const isEmail = navigationParams?.isEmail;
  const isEmail = true;

  const { state, SignUpWithEmail, addErrorMessage } = useContext(AuthContext);
  const styles = styling(responsiveStyles);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardDidShowTxt, setKeyboardDidShowTxt] = useState('');

  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  // useEffect to handle isFirstTime state and keyboard listeners
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Sign Up With Email";
    }
    if (isFirstTime) {
      setIsFirstTime(false);
    }
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true);
      setKeyboardDidShowTxt("keyboardDidShow");
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false);
      setKeyboardDidShowTxt("keyboardDidHide");
    });
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
      backHandler.remove();
    };
  }, [state.signupAlertMessage]); // Only re-subscribe if state.signupAlertMessage changes

  // useFocusEffect to handle focus-related effects
  if(responsiveStyles.isWeb){
  }
  else{
    useFocusEffect(
      React.useCallback(() => {
        // Function to run when screen gains focus
        setShowLoader(false); // Example: Resetting loader state
        // Perform other focus-related actions here
  
        // Clean-up function when screen loses focus (optional)
        return () => {
          // Perform any clean-up here if necessary
        };
      }, [])
    );
  }

  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
          <SignUpAlert
          userSettingsState={userSettingsState}
            navigation={navigation}
            state={state}
            addErrorMessage={addErrorMessage}
          />
          <View style={{ flex: 1 }}>
            {/* <BackButtonComponent
              userSettingsState={userSettingsState}
              navigation={navigation}
              onPress={() => {
                isEmail === false
                  ? backAction()
                  : navigateToScreen(navigation, 'LoginFlow', `SignUpWithEmail`, { isEmail: !isEmail }, null)
                  // : navigation.navigate('LoginFlow', { screen: 'SignUpWithEmail', params: { isEmail: !isEmail } });
              }}
            /> */}
            <SignUpForm
            userSettingsState={userSettingsState}
              errorMessage={state.errorMessage}
              onSubmit={SignUpWithEmail}
              isEmail={isEmail}
              navigation={navigation}
              signupAlertMessage={state.signupAlertMessage}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              isKeyboardVisible={isKeyboardVisible}
              keyboardDidShowTxt={keyboardDidShowTxt}
            />
          </View>
          <View style={{ flex: 1, justifyContent: 'flex-end', paddingBottom: !responsiveStyles.isMobile ? 20 : 10 }}>
            <NavLink
            routeStack="LoginFlow"
              routeName="Signin"
              text={i18n.t('auth.AlreadyHaveAccountButton')}
              navigation={navigation}
            />
          </View>
          <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </Screen>
  );
};

SignUpWithEmailScreen.navigationOptions = {
  header: () => false,
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  inner: {
    padding: 15,
    flex: 1,
  },
});

export default SignUpWithEmailScreen;
