import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import LengthSelector from './LengthSelector';
import BulletText from '../inputs/BulletText';
import i18n from '../../hooks/useI18n';

const JewelryTypeLengthSelector = ({ 
    userSettingsState,
    isChain = false,
    chainSize,
    setChainSize,
    isBracelet = false,
    braceletSize,
    setBraceletSize,
    isRing = false,
    ringSize,
    setRingSize,
    onSelect,
 }) => {

    const chainLengths = [40, 45, 50];
    const chainDefaultValue = 45;
    const braceletLengths = [16, 17, 18, 19, 20];
    const braceletDefaultValue = 18;
    const ringLengths = [5, 6, 7, 8, 9];
    const ringDefaultValue = 7;

    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
    useEffect(() =>{
      if(isChain === true && chainSize === null){
        setChainSize(chainDefaultValue);
      }
      if(isBracelet === true && braceletSize === null){
        setBraceletSize(braceletDefaultValue);
      }
      if(isRing === true && ringSize === null){
        setRingSize(ringDefaultValue);
      }
      },[]);
  return (
    <View style={styles.container}>
        {
        isChain ?
        <View>
        <BulletText 
      userSettingsState={userSettingsState} 
      text={i18n.t('store.JewelryLengthContainer_Chain_text1')}
      withBullet={false}
      isBold={true}
       />
        <LengthSelector 
        userSettingsState={userSettingsState} 
        lengths={chainLengths}
        onSelect={onSelect}
        value={chainSize}
        setValue={setChainSize}
        />
        </View>
        : null
        }
        {
        isBracelet ?
        <View>
        <BulletText 
      userSettingsState={userSettingsState} 
      text={i18n.t('store.JewelryLengthContainer_Bracelet_text1')}
      withBullet={false}
      isBold={true}
       />
        <LengthSelector 
        userSettingsState={userSettingsState} 
        lengths={braceletLengths}
        onSelect={onSelect}
        value={braceletSize}
        setValue={setBraceletSize}
        />
        </View>
        : null
        }
        {
        isRing ?
        <View>
        <BulletText
      userSettingsState={userSettingsState} 
      text={i18n.t('store.JewelryLengthContainer_Ring_text1')}
      withBullet={false}
      isBold={true}
       />
        <LengthSelector 
        userSettingsState={userSettingsState} 
        lengths={ringLengths}
        onSelect={onSelect}
        value={ringSize}
        setValue={setRingSize}
        />
        </View>
        : null
        }
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    marginVertical: 10,
  },
  option: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginHorizontal: 5,
    backgroundColor: '#f9f9f9',
  },
  selectedOption: {
    borderColor: '#000',
    backgroundColor: '#e0e0e0',
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
});

export default JewelryTypeLengthSelector;
