import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Button, Image, useWindowDimensions } from 'react-native';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { GlobalStyles, Gold } from '../../constants/styles';
import ButtonTypeButton from '../inputs/ButtonTypeButton';
import i18n from '../../hooks/useI18n';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const ImagePickerPopupWebComponent = ({ 
  userSettingsState,
  src, 
  onClose, 
  isCircle,
  setIsModalPopupVisible,
 }) => {
  
const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const hasAdjustedWidthRef = useRef(null);
  useEffect(() => {
    if (isCircle) {
      
      const intervalId = setInterval(() => {
        const elements = document.querySelectorAll('.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid');
        elements.forEach(element => {
          // const originalWidth = element.offsetWidth;
          // const newWidth = originalWidth - 225;
          // if(!hasAdjustedWidthRef.current){
          //   element.style.width = `${newWidth}px`;
          // }
          element.style.borderRadius = '200000px';
          // element.style.aspectRatio = '1';
          // element.style.height = 'unset';
          hasAdjustedWidthRef.current = true;
        });
      }, 500);

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [isCircle]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSaveAndClose = async () => {
    if (croppedAreaPixels) {
      try {
        const croppedImageUrl = await getCroppedImg(src, croppedAreaPixels);
        await onClose(croppedImageUrl);
      } catch (err) {
        console.log(`handleSaveAndClose error, err: ${err}`);
      }
    }
  };

  const showCroppedImage = async () => {
    try {
      const croppedImageUrl = await getCroppedImg(src, croppedAreaPixels);
      setCroppedImageUrl(croppedImageUrl);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <View style={styles.modalContent}>
      <View style={styles.cropContainer}>
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          aspect={isCircle ? 1 / 1 : 3 / 1}
          // aspect={285 / 100}
          // aspect={16 / 9}
          onCropChange={(location) => {
            setCrop(location);
            showCroppedImage();
          }}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </View>
      {/* {croppedImageUrl && (
        <View style={isCircle ? styles.croppedImageCircleContainer : styles.croppedImageContainer}>
          <Image style={styles.croppedImage} source={{ uri: croppedImageUrl }} />
        </View>
      )} */}
    <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    style={styles.button}
    // styleButton={{ backgroundColor: Gold }}
    onPress={handleSaveAndClose}
    >
      {i18n.t('textInput.ImagePickerPopupWebComponent_SubmitButton')}
</ButtonTypeButton>
<ButtonTypeButton
userSettingsState={userSettingsState}
    style={styles.button}
    onPress={() => {
      setIsModalPopupVisible(false);
    }}
    >
      {i18n.t('textInput.ImagePickerPopupWebComponent_CancelButton')}
</ButtonTypeButton>
</View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  modalContent: {
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    width: '70%',
    height: responsiveStyles.screenHeight * 0.8,
    padding: 20,
    borderRadius: 10,
    alignItems: 'center', // Center the content
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    background: '#333',
  },
  croppedImageContainer: {
    marginTop: 20,
    width: 200,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  croppedImageCircleContainer: {
    marginTop: 20,
    width: 200,
    height: 200,
    borderRadius: 100, // Make the container circular
    overflow: 'hidden', // Ensure the image is contained within the circle
    justifyContent: 'center',
    alignItems: 'center',
  },
  croppedImage: {
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    alignItems:'center',
    marginVertical: 20,
    width: '60%',
  },
  button: {
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.85,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.4,
    width: responsiveStyles.screenWidth * 0.1,
    minWidth: 70,
  },
});

export default ImagePickerPopupWebComponent;
