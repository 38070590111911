import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const CartEmptyListComponent = ({ userSettingsState, displayType, setDisplayType, data = [], isEditing = false }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  
  return (
    <View>
    <View style={styles.TributeContainer}>
      <Text style={styles.title}>{i18n.t('store.CartEmptyListComponent_Title')}</Text>
  </View>
    </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  TributeContainer: {
    flex:1,    
    marginTop: responsiveStyles.screenHeight * 0.35,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
  },
  title: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    paddingVertical: 20,
  },
  buttonContainer: {
    alignItems:'center'
  },
  button: {
    width: responsiveStyles.screenWidth * 0.9,
  },
});

export default CartEmptyListComponent;