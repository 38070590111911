import { RelationshipList } from "./RelationshipList";
import aboutUs from "./aboutUs";
import account from "./account";
import auth from "./auth";
import contactUs from "./contactUs";
import error from "./error";
import footer from "./footer";
import help from "./help";
import media from "./media";
import menu from "./menu";
import notFound from "./notFound.js";
import paymentFailed from "./paymentFailed";
import paymentSuccessful from "./paymentSuccessful";
import privacy from "./privacy";
import responseMessages from "./responseMessages";
import store from "./store";
import terms from "./terms";
import textInput from "./textInput";
import tutorial from "./tutorial";


export default {
    responseMessages,
    error,
    auth,
    account,
    media,
    menu,
    RelationshipList,
    store,
    
    footer,
    aboutUs,
    help,
    terms,
    privacy,
    contactUs,
    textInput,
    paymentSuccessful,
    paymentFailed,
    notFound,

    tutorial,
};