import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const TitleComponent = ({ userSettingsState, title }) => {
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
  </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    justifyContent:'center',
    alignItems:'center',
    // marginTop: 85,
    marginTop: responsiveStyles.screenHeight * 0.5 - 40,

    // position:'absolute',
    // top: responsiveStyles.screenHeight * 0.5 - 40,
    // left: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.37 : responsiveStyles.screenWidth * 0.4,
  },
  title: {
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
});

export default TitleComponent;