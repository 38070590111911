export default {
  Title: "צור קשר",
  description: "אנחנו כאן לעזור! אם יש לך שאלות, דאגות או משוב, אנא צור איתנו קשר באמצעות המידע למטה או מלא את טופס הקשר.",
  ContactUs_Info: "פרטי קשר",
  ContactUs_Email: "אימייל:",
  ContactUs_Phone: "טלפון:",
  ContactUs_Form: "טופס קשר",
  ContactUs_Form_Description: "אנא מלא את הטופס למטה, ואנחנו נחזור אליך תוך 24-48 שעות.",
  FinalWords: "תודה שבחרת ב-TMJ. אנו מצפים לעזור לך!",
  ContactForm_Name: "שם",
  ContactForm_Name_ErrorMessage: "חובה להזין שם",
  ContactForm_Email: "אימייל",
  ContactForm_Email_ErrorMessage: "חובה להזין אימייל",
  ContactForm_Subject: "נושא",
  ContactForm_Subject_ErrorMessage: "חובה להזין נושא",
  ContactForm_Message: "הודעה",
  ContactForm_Message_ErrorMessage: "חובה להזין הודעה",
  ContactForm_SendButton: "שלח טופס",
};