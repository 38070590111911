import { Alert, View } from "react-native";
import { useEffect, useState } from "react";
import i18n from "../../hooks/useI18n";
import CustomAlert from "../modals/CustomAlert";

export const SignUpConfirmUserNameAndPasswordByEmailAlert = ({ 
  navigation, 
  state, 
  addErrorMessage, 
  username,
  userSettingsState,
 }) => {
    const [UserExistVisible, setUserExistVisible] = useState(false);
    const [ServerMessageVisible, setServerMessageVisible] = useState(false);
    const [MessageFromServer, setMessageFromServer] = useState("");
    
  useEffect(() => {

        // const message = state.signupConfirmUserNameAndPasswordByEmailAlertMessage;
        // message === "UserExist" ? UserExist(userName): ServerMessage(message);
      if(state.signupConfirmUserNameAndPasswordByEmailAlertMessage === "UserExist")
      {
        setUserExistVisible(true);
      }
      else if(state.signupConfirmUserNameAndPasswordByEmailAlertMessage?.length > 0)
      {
        const message = state.signupConfirmUserNameAndPasswordByEmailAlertMessage;
        setServerMessageVisible(true);
        setMessageFromServer(message);
      }
      addErrorMessage("signupConfirmUserNameAndPasswordByEmailAlertMessage","");
    
    }, [state.signupConfirmUserNameAndPasswordByEmailAlertMessage]);
  
    const hideUserExistAlert = () => {
      setUserExistVisible(false);
    };
    const hideServerMessageVisibleAlert = () => {
        setServerMessageVisible(false);
    };
    return (
      <View>
      <CustomAlert
        visible={UserExistVisible}
        onClosePress={hideUserExistAlert}
        userSettingsState={userSettingsState}
        headermessage={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UserExist_subject')}
        bodymessge={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UserExist_message1') + username + 
        i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UserExist_message2')}
        buttonmessage1={i18n.t('auth.SignUpConfirmUserNameAndPasswordByEmail_UserExist_TryAgain')}
      />
          <CustomAlert
        visible={ServerMessageVisible}
        onClosePress={hideServerMessageVisibleAlert}
        userSettingsState={userSettingsState}
        bodymessge={MessageFromServer}
        buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton')}
      />
    </View>
    );
  };
  