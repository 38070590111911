import React, { useContext, useEffect, useState } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Text,
  Dimensions,
  useWindowDimensions,
  BackHandler
} from 'react-native';
import { Context as AuthContext } from '../../context/AuthContext';
import SignUpConfirmEmailCodeForm from '../../components/forms/SignUpConfirmEmailCodeForm';
import { I18nManager } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import i18n from '../../hooks/useI18n';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GenericAlert } from '../../components/alert/GenericAlert';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const SignUpConfirmationScreen = ({  }) => {
  
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const UserIdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const userName = navigationParams?.userName;
  const email = navigationParams?.email;

  const [showLoader, setShowLoader] = useState(false);
  const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
  const { state, addErrorMessage, AddUserByEmail } = useContext(AuthContext);


  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };

  useEffect(() =>{
    if(responsiveStyles.isWeb){
      document.title = "Sign Up Confirmation";
    }
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
  return () => backHandler.remove();
  },[]);
	const styles = styling(responsiveStyles);
  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
    <KeyboardAvoidingView
      // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
        {/* <BackButtonComponent userSettingsState={userSettingsState} navigation={navigation} onPress={backAction} /> */}
      <Text style={styles.header}>{i18n.t('auth.SignUpConfirmation_header')}{userName}?</Text>
      <Text style={styles.headerDescription}>{i18n.t('auth.SignUpConfirmation_headerDescription')}</Text>
      <Text style={styles.footerDescription}>{i18n.t('auth.SignUpConfirmation_footerDescription')}</Text>
      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        loading={showLoader}
        onPress={async () =>
          {
            setShowLoader(true);
             const response = await AddUserByEmail({email, navigation});
             if(response.isCompleted === true)
              {
              }
             else{
              setbodymessge(response.message);
              setGenericAlertVisible(true);
            }
             setShowLoader(false);
            }}
        styleButton={[styles.buttonStyle, { marginBottom: 20 }]}
         >
        {i18n.t('auth.SignUpConfirmation_submitButton')}
          </ButtonTypeButton>
        </View>
        <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
    </View>
    </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </Screen>
  );
};

SignUpConfirmationScreen.navigationOptions = (navData) => {
    return {
        header: () => false,
      };
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  inner: {
    padding: 15,
    flex: 1,
    justifyContent: 'space-around',
  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    fontWeight:'bold',
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    marginBottom: 15,
    
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H6,
    textAlign:'center',
    flex:1,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    marginBottom: 15,
  },
  footerDescription:{
    fontSize: responsiveStyles.fontSize_H6,
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom: !responsiveStyles.isMobile ? 20 : 15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  btnContainer: {
    // marginHorizontal: !responsiveStyles.isMobile ? 0 : 15,
    // // paddingLeft: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : null,
    // paddingRight: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : null,
    // alignItems:!responsiveStyles.isMobile ? 'center' : null,
    // marginBottom: !responsiveStyles.isMobile ? responsiveStyles.screenHeight * 0.05 : null,
    alignSelf:'center',
    paddingTop: 10,
  },
  textInput:{
    height: 40,
    marginHorizontal: 12,
    borderWidth: 1,
    padding: 10,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default SignUpConfirmationScreen;
