import React from 'react';
import { StyleSheet,View,Text, TextInput, TouchableOpacity, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { GlobalStyles } from '../constants/styles';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const BackButtonComponent = ({ 
  userSettingsState, 
  navigation,
  backAction = null,
  showBackButton = true,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  return (
      <TouchableOpacity style={[styles.container, !showBackButton ? { pointerEvents: 'none' } : null]}
      onPress={() => {
        if(!showBackButton){
          return;
        }
        if(backAction){
          backAction();
        }
        else{
          if (responsiveStyles.isWeb) {
            navigation(-1); // This is the equivalent of navigation.pop() in React Navigation
          } else {
            navigation.pop();
          }
        }
      }}
      >
    <AntDesign size={!responsiveStyles.isMobile ? 32 : 24} 
    color={!showBackButton ? GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor : GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
    //   style={ responsiveStyles.isRTL ? { marginRight:10 } : { marginLeft:10 } }
      name={responsiveStyles.isRTL ? "arrowleft" : "arrowright" }
      />
      </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection:'row',
    justifyContent: responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
  },
});

export default BackButtonComponent;
