import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  useWindowDimensions,
  BackHandler,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Context as AuthContext } from '../../context/AuthContext';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import { ForgotPasswordAlert } from '../../components/alert/ForgotPasswordAlert';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const ForgotPasswordScreen = ({  }) => {
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const [showLoader, setShowLoader] = useState(false);
  const { state, addErrorMessage, ResetPassword } = useContext(AuthContext);

const styles = styling(responsiveStyles);

  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardDidShowTxt, setKeyboardDidShowTxt] = useState('');

  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Forgot Password";
    }
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true);
      setKeyboardDidShowTxt("keyboardDidShow");
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false);
      setKeyboardDidShowTxt("keyboardDidHide");
    });
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
      backHandler.remove();
    };
  }, []);

  if(responsiveStyles.isWeb){
  }
  else{
    useFocusEffect(
      useCallback(() => {
        setShowLoader(false);
        return () => {
          // Clean up if needed
        };
      }, [])
    );
  }

  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
          {/* <BackButtonComponent userSettingsState={userSettingsState} navigation={navigation} onPress={backAction} /> */}
          <ForgotPasswordAlert
          userSettingsState={userSettingsState}
            navigation={navigation}
            state={state}
            addErrorMessage={addErrorMessage}
          />
          <ForgotPasswordForm
          userSettingsState={userSettingsState}
            errorMessage={state.errorMessage}
            ResetPassword={ResetPassword}
            ShowPassword={false}
            navigation={navigation}
            forgotPasswordAlertMessage={state.forgotPasswordAlertMessage}
            Keyboard={Keyboard}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            isKeyboardVisible={isKeyboardVisible}
            keyboardDidShowTxt={keyboardDidShowTxt}
          />
          <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </Screen>
  );
};

ForgotPasswordScreen.navigationOptions = {
  header: () => null,
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  inner: {
    flex: 1,
    padding: 15,
  },
});

export default ForgotPasswordScreen;
