import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";

const EngravingTextContainer = ({ 
  userSettingsState, 
  foundItem,
  frontSideText,
  setFrontSideText,
  backSideText,
  setBackSideText,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [hoveredColor, setHoveredColor] = useState(null);
  if(!foundItem?.IsFrontSideText && !foundItem?.IsBackSideText)
  {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={[styles.text, styles.textBold]}>* {i18n.t('store.ItemDetailsScreen_EngravingTextContainer_note')}</Text>

      {foundItem?.IsFrontSideText ? 
      <TextInputWithErrorComponent
      userSettingsState={userSettingsState}
      value={frontSideText}
      setValue={(value) => {
            setFrontSideText(value);
      }}
      editable={true}
      label={`${i18n.t('store.ItemDetailsScreen_EngravingTextContainer_frontSideText_label')}`}
      isRequired={true}
      // isValid={inputs.PetName.isValid}
      styleTextInputContainer={styles.textInputName}
      maxLetters={20}
      isStopInMax={true}
      />
      : null }

      {foundItem?.IsBackSideText ? 
      <TextInputWithErrorComponent
      userSettingsState={userSettingsState}
      value={backSideText}
      setValue={(value) => {
            setBackSideText(value);
      }}
      editable={true}
      label={`${i18n.t('store.ItemDetailsScreen_EngravingTextContainer_backSideText_label')}`}
      isRequired={true}
      // isValid={inputs.PetName.isValid}
      styleTextInputContainer={styles.textInputName}
      maxLetters={20}
      isStopInMax={true}
      />
      : null }
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    // flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    // marginBottom: 20,
    alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
  },
  colorContainer: {
    width: 25,
    height: 25,
    borderRadius: 100,
    marginLeft: !responsiveStyles.isRTL ? 20 : null,
    marginRight: responsiveStyles.isRTL ? 20 : null,
    position: 'relative', // Needed for the tooltip
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  textBold: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  tooltip: {
    position: 'absolute',
    top: -40, // Position above the color container
    left: '50%',
    transform: [{ translateX: '-50%' }], // Corrected syntax
    padding: 5,
    backgroundColor: 'black',
    borderRadius: 5,
    zIndex: 1, // Ensure tooltip is above other content
  },
  tooltipText: {
    color: 'white',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
//   textInputName: {
//     width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth - paddingScreen) * 0.22 : null,
//     // backgroundColor: isAdmin ? null : 'gray',
// },
});

export default EngravingTextContainer;
