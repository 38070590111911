import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import { GlobalStyles } from "../constants/styles";
import i18n from "../hooks/useI18n";
import useResponsiveStyles from "../functions/ResponsiveStyles";
const ErrorComponent = ({ 
  onPress, 
  userSettingsState,
 }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

    return (
        <View style={styles.errorContainer}>
        <TouchableOpacity style={styles.loaderView} onPress={onPress}>
        <AntDesign name="reload1" size={32} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} style={styles.loader} />
        </TouchableOpacity>
      <Text style={styles.loaderText}>{i18n.t('error.error_loader1')}</Text>
      <Text style={styles.loaderText}>{i18n.t('error.error_loader2')}</Text>
      </View>
    );
  };

  const styling = (responsiveStyles) => StyleSheet.create({
    errorContainer: {
      flex: 1,
     justifyContent: 'center',
     backgroundColor:GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    loaderView: {
      alignItems:'center',
      marginVertical: 10,
    },
    loader: {
      borderWidth:1,
      borderRadius: 25,
      padding: 8,
    },
    loaderText: {
      textAlign: 'center',
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontFamily: GlobalStyles.fonts.application_font,
    },
  });

  export default ErrorComponent;