import React, { useState, useEffect } from 'react';
import { Modal, View, Image, TouchableOpacity, StatusBar, StyleSheet, Dimensions, Text, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { GlobalStyles } from '../../constants/styles';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const ImageMediaModal = ({ 
  resizeMode = null,
  imageUri,
  visible,
  onClose, 
  userName = null,
  chatType = "user",
  userSettingsState,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  if(visible){
    console.log(imageUri);
  }

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <TouchableOpacity 
        style={styles.imageContainer}
         activeOpacity={1}
         >
          <FastImage
            source={imageUri}
            resizeMode="contain"
            style={{ flex: 1, width: '100%', height: 300 }}
          />
          <View style={styles.topRowContainer}>
          <TouchableOpacity
          style={styles.closeContainer}
          onPress={onClose}
        >
          <AntDesign name="closecircle" size={24} color="white" />
        </TouchableOpacity>
        <View style={styles.userNameContainer}>
            <Text style={styles.userNameText}>{userName}</Text>
            </View>
            {chatType === "group" ? 
            <TouchableOpacity
          style={styles.shareContainer}
          // onPress={onClose}
        >
          <AntDesign name="sharealt" size={24} color="white" />
        </TouchableOpacity>
        : null }
          </View>




        </TouchableOpacity>
          <TouchableOpacity 
          style={[styles.showcaseNextPreviousButtonContainer, styles.PreviousContainer]}
          
          >
            <AntDesign name="right" size={16} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
          
          <TouchableOpacity 
          style={[styles.showcaseNextPreviousButtonContainer, styles.NextContainer]}
          
          >
          <AntDesign name="left" size={16} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
        <View style={styles.bottomContainer}>
            <Text style={styles.title}>Title</Text>
            <Text style={styles.description}>Description fdb fdb fdb fdb f sdvd svd vsd v sdv sdv sd vsd vsd vsd vsd vsdv f sdvd svd vsd v sdv sdv sd vsd vsd vsd vsd vsdv f sdvd svd vsd v sdv sdv sd vsd vsd vsd vsd vsdv f sdvd svd vsd v sdv sdv sd vsd vsd vsd vsd vsdv f sdvd svd vsd v sdv sdv sd vsd vsd vsd vsd vsdv dd vsdb sdb sfb sbs d vfdb fb dbdf bfd bfd bfd b</Text>
            <View style={styles.commentContainer}>
            <Text style={[styles.comment]}>Comments</Text>
            <View style={styles.commentsNumberContainer}>
            <Text style={[styles.commentsNumber]}>100</Text>
            </View>
            </View>
            <Text style={[styles.comment]}>21 במרץ</Text>
        </View>
      </View>
    </Modal>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  topRowContainer: {
    position: 'absolute',
    alignSelf: 'center',
    width:'100%',
    paddingVertical: 15,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundColor: GlobalStyles.colors.Lavender,
    alignItems:'center'

  },
  userNameContainer:{
    flex:1,
    alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
  },
  userNameText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorModal,
    fontSize: responsiveStyles.fontSize_H5,
    marginHorizontal: 20,
    fontFamily: GlobalStyles.fonts.application_font,
    

  },
  imageContainer: {
    backgroundColor:'black',
    flex:1,
    paddingBottom: 100
  },
  closeContainer: {
    marginHorizontal: 10,
  },
  shareContainer: {
    marginHorizontal: 10,
  },
  bottomContainer: {
    // flex:1,
    // backgroundColor: 'orange',
    // alignSelf: 'center',
    // bottom: 90,
    // position: 'absolute',
    width:'100%',
  },
  title: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorModal,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 5,
    paddingHorizontal: 20,
    fontWeight: '700',
  },
  description: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorModal,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 5,
    paddingHorizontal: 20,
  },
  commentContainer: {
    flexDirection: 'row-reverse',
    paddingHorizontal: 20,
  },
  comment: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorModal,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 5,
    paddingHorizontal: 5,
    },
    commentsNumberContainer: {
      backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
      // padding: 10,
      borderRadius: 100,
      width: 30,
      height: 30,
      justifyContent:'center',
      alignItems:'center'
    },
    commentsNumber: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColorModal,
      fontSize: responsiveStyles.fontSize_H6,
      fontFamily: GlobalStyles.fonts.application_font,
    },
    showcaseNextPreviousButtonContainer: {
      position: 'absolute',
      top: '50%',
      transform: [{ translateY: -12 }], // Adjust based on icon size
      backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
      borderRadius: 100,
      padding: 5,
      justifyContent:'center',
      alignItems:'center',
    },
    PreviousContainer: {
      right: 10,
    },
    NextContainer: {
      left: 10,
    },
});
export default ImageMediaModal;
