import React from "react";
import { StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import i18n from "../../hooks/useI18n";

const FooterShareIconsComponent = ({ 
    userSettingsState,
    ProductName,
    ProductPrice,
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const WebsiteURL = "https://timelessmemories-jewelry.com";

  // Functions to handle share links for each platform
  const openWhatsApp = () => {
    const phoneNumber = i18n.t('footer.Phone');
    const url = `https://wa.me/${phoneNumber}`;
    Linking.openURL(url).catch(() => {
      Alert.alert("Error", "WhatsApp not installed or cannot open link.");
    });
  };

  const openEmail = () => {
    const email = i18n.t('footer.Email');
    const subject = "Inquiry about TMJ Jewelry";
    const url = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    Linking.openURL(url).catch(() => {
      Alert.alert("Error", "No email client installed.");
    });
  };

  const openInstagram = () => {
    const url = "https://www.instagram.com/TMJ";
    Linking.openURL(url).catch(() => {
      Alert.alert("Error", "Cannot open Instagram.");
    });
  };

  const openFacebook = () => {
    const url = "https://www.facebook.com/TMJ";
    Linking.openURL(url).catch(() => {
      Alert.alert("Error", "Cannot open Facebook.");
    });
  };



  return (
    <View style={styles.container}>
      <TouchableOpacity style={[styles.ButtonContaier, { backgroundColor: '#1EBEA5' }]} onPress={openWhatsApp}>
        <FontAwesome5 name="whatsapp" size={20} color={"white"} />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.ButtonContaier, { backgroundColor: '#774430' }]} onPress={openInstagram}>
        <FontAwesome5 name="instagram" size={20} color={"white"} />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.ButtonContaier, { backgroundColor: '#F89A1E' }]} onPress={openEmail}>
        <FontAwesome5 name="envelope" size={20} color={"white"} />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.ButtonContaier, { backgroundColor: '#365493' }]} onPress={openFacebook}>
        <FontAwesome5 name="facebook" size={20} color={"white"} />
      </TouchableOpacity>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1, 
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  ButtonContaier: {
    marginHorizontal: 5,
    padding: 10,
    borderRadius: 100,
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FooterShareIconsComponent;
