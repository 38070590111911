import React, { useState } from 'react';
import { StyleSheet,View,Text, Dimensions, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton"
import { GlobalStyles } from '../../constants/styles';
import { FontAwesome } from '@expo/vector-icons';
import { navigate } from '../../navigationRef';
import i18n from '../../hooks/useI18n';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';

const ForgotPasswordForm = ({ ResetPassword, forgotPasswordAlertMessage, Keyboard,
  ThemeValue = 'light',
  isRTL,
  isKeyboardVisible,
  keyboardDidShowTxt,
  navigation,
  userSettingsState,
 }) => {
  
const responsiveStyles = useResponsiveStyles(userSettingsState);

    const [email, setEmail] = useState('');
    const [emailError,setEmailError] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    if(forgotPasswordAlertMessage === true && showLoader === true)
    {
      setShowLoader(false);
    }
    
    const styles = styling(responsiveStyles);

  return (
    <View style={styles.container}>
        <Text style={styles.header}>{i18n.t('auth.ForgotPassword_header')}</Text>
        <Text style={styles.headerDescription}>{i18n.t('auth.ForgotPassword_headerDescription')}</Text>
        <View style={styles.textInputContainer}>
        <TextInputCustom
        userSettingsState={userSettingsState}
        editable={true}
        label={i18n.t('auth.EmailInput')}
        value={email}
        onChangeText={(text) => {
          setEmailError();
          setEmail(text);
        }}
        style={[styles.textInput, !emailError ? { marginBottom: 15 } : null]}
        iconType="cancel"
        isKeyboardVisible={isKeyboardVisible}
        keyboardDidShowTxt={keyboardDidShowTxt}
      />
     {emailError ? (
      <Text style={[GlobalStyles.errorMessage,{ marginBottom: 15 }]}>{emailError}</Text>
      ) : null}
      </View>
      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {
          if(email.length === 0)
          {
            setEmailError(i18n.t('auth.ForgotPassword_emailError'));
          }
          else{
            setShowLoader(true);
            Keyboard.dismiss();
            const response = await ResetPassword({ email });
            setShowLoader(false);
            if(response.isCompleted === true)
            {
              const answer = JSON.parse(response.answer);
              navigateToScreen(navigation, 'LoginFlow', `ForgotPasswordCodeConfirmation`, { email: answer }, null);
              // navigation.navigate('LoginFlow', { screen: 'ForgotPasswordCodeConfirmation', params:  { email: answer } });
              // navigation.navigate('ForgotPasswordCodeConfirmation', { email: answer });
            }
          }
        }}
        styleButton={styles.buttonStyle}
        loading={showLoader}
        >
       {i18n.t('auth.ForgotPassword_submitButton')}
          </ButtonTypeButton>
        </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    textAlign:'center',
    fontWeight:'bold',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
    
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H5,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    paddingBottom: 20,
    marginBottom: 5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign:'center',
  },
  textInputContainer: {
    // flex: !responsiveStyles.isMobile ? null : 1,
    marginBottom: responsiveStyles.isWeb ? 15 : 50

  },
  btnContainer: {
    alignSelf:'center',
    
  },
  textInput:{
    height: 40,
    borderWidth: 1,
    padding: 10,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    // marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default ForgotPasswordForm;
