import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { navigateToScreen } from "../../navigationResponsive";
import { getAsset } from "../../functions/getAsset";
import TutorialContainer from "./TutorialContainer";

  const Step4TextComponent = ({ 
    userSettingsState,
    showOnlyFirst = false,
    navigation,
    isFromHome = false,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles, isFromHome);
  return (
    <TutorialContainer
    isFromHome={isFromHome}
    shouldPaddingBottom={false}
    userSettingsState={userSettingsState}
    videoUri={getAsset(require('../../../assets/tutorial/step4.mp4'))}
    durationTime={"00:00:49.0000000"}
    title1={isFromHome ? null : i18n.t('tutorial.Step4_title1')}
    title2={isFromHome ? null : i18n.t('tutorial.Step4_title2')}
    showButton={showOnlyFirst}
    onPress={() => {
      navigateToScreen(navigation, 'StoreFlow', `TutorialExplanation`, { Type: "Step4" }, "Step4");
    }}
    text={
    <View>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text1')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text2')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text3')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text4')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text5')}</Text>
    {
        showOnlyFirst ? 
        <Text style={styles.text}>...</Text>
        : <>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text6')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text7')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text8')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text9')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text10')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text11')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step4_text12')}</Text>
        </>
    }
    </View>
    }
    />
  );
}
const styling = (responsiveStyles, isFromHome) => StyleSheet.create({
  text: {
    fontSize: responsiveStyles.fontSize_H5,
    lineHeight: responsiveStyles.fontSize_H5 * 1.5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    ? isFromHome ? responsiveStyles.screenWidth * 0.7 : responsiveStyles.screenWidth * 0.3 
    : responsiveStyles.screenWidth * 0.48,
    marginHorizontal: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
});

export default Step4TextComponent;