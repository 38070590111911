import createDataContext from "./createDataContext";
import {Appearance} from 'react-native';
import * as Localization from 'expo-localization';
import { getAsync, postAsync } from "../api/categoryApiCalls";
import { getResponseByErr, getResponseSucceed } from "../functions/getResponseByErr";
import { getItem, saveAsync } from "../modules/Storage";
import { BackgroundColor_Storage, Brightness_Storage, DarkMode_Storage, HomeScreenDefault_Storage, IsAdmin_Storage, Language_Storage, UserId_Storage, token_Storage } from "../modules/StorageObjects";
import { deviceId } from "../constants/StaticData";
import AsyncStorage from "@react-native-async-storage/async-storage";

const itemReducer = (state, action) => {
  switch (action.type) {
    case "set_updateState":
      return { ...state, updateState: action.payload };
    case "set_user_settings":
      return { ...state, userSettings: action.payload };
      case "set_user_logic":
      return { ...state, userSettings: action.payload.userSettings, userDetails: action.payload.userDetails };
      case "set_tutorial":
      return { ...state, tutorial: action.payload };
      case "set_user_settings_Language": // cant use it, beacuse i have to set 'userSettings' in storage - dont use it anymore
        return { ...state, userSettings:{
          ... state.userSettings,
          //   Language: action.payload.userSettingsLanguage, 
          // TextAlign: action.payload.userSettingsLanguage === "he-IL" ? 'right' : 'left'
          Language: action.payload, 
          TextAlign: action.payload === "he-IL" ? 'right' : 'left'
        } };
        case "set_user_settings_DarkMode": 
        return { ...state, userSettings:{
          ... state.userSettings,
          DarkMode: action.payload, 
          ThemeValue: action.payload === true ? 'dark' : 'light'
        } };
        case "set_user_settings_Brightness": 
        return { ...state, userSettings:{
          ... state.userSettings,
          Brightness: action.payload, 
        }};
        case "set_user_settings_BackgroundColor": 
        return { ...state, userSettings:{
          ... state.userSettings,
          BackgroundColor: action.payload,
          BackgroundImage: "",
          Width: 0,
          Height: 0,
        }};
        case "set_user_settings_BackgroundImage": 
        return { ...state, userSettings:{
          ... state.userSettings,
          BackgroundColor: "",
          BackgroundImage: action.payload.BackgroundImage,
          Width: action.payload.Width,
          Height: action.payload.Height,

        }};
        case "set_user_settings_HomeScreenDefault": 
        return { ...state, userSettings:{
          ... state.userSettings,
          HomeScreenDefault: action.payload, 
        } };
        case "set_user_settings_TextAlign": 
        return { ...state, userSettings:{
          ... state.userSettings,
          TextAlign: action.payload,
        } };

  

        // case "add_error":
        //   return { ...state, errorMessage: action.payload };
        // case "signin":
        //   return { ...state, errorMessage: "", token: action.payload };
        //   case "add_error_signinAlertMessage":
        //   return { ...state, signinAlertMessage: action.payload };
        //   case "add_error_signupAlertMessage":
        //     return { ...state, signupAlertMessage: action.payload };
        //     case "add_error_signupConfirmUserNameAndPasswordByEmailAlertMessage":
        //       return { ...state, signupConfirmUserNameAndPasswordByEmailAlertMessage: action.payload };
        //   case "add_error_forgotPasswordAlertMessage":
        //     return { ...state, forgotPasswordAlertMessage: action.payload };
        //     case "add_error_forgotPasswordCodeConfirmationAlertMessage":
        //       return { ...state, forgotPasswordCodeConfirmationAlertMessage: action.payload };
        //       case "add_error_forgotPasswordConfirmCanChangePasswordAlertMessage":
        //         return { ...state, forgotPasswordConfirmCanChangePasswordAlertMessage: action.payload };
        //   case "add_error_emailConfirmation":
        //   return { ...state, emailConfirmationErrorMessage: action.payload };
        //     case "setSignUpConfirmEmailCodeModalVisible":
        //     return { ...state, signUpConfirmEmailCodeModalVisible: action.payload };
        //     case "setForgotPasswordCodeConfirmationModalVisible":
        //       return { ...state, forgotPasswordCodeConfirmationModalVisible: action.payload };
        //     case "setForgotPasswordConfirmCanChangePassword":
        //       return { ...state, forgotPasswordConfirmCanChangePasswordModalVisible: action.payload };
        //     case "setResetPasswordshowLoader":
        //     return { ...state, resetPasswordshowLoader: action.payload };
        //     case "setResentEmailCodeshowLoader":
        //       return { ...state, resentEmailCodeshowLoader: action.payload };   
        //       case "setValidateUserNameShowLoader":
        //         return { ...state, validateUserNameShowLoader: action.payload };   
        // case "clear_error_message":
        //   return { ...state, errorMessage: "" };
        //   case "validateUserName":
        //     return { ...state, validateUserName: action.payload };
        // case "signout":
        //   return { ...state, token: null, errorMessage: "" };
    default:
      return state;
  }
};

const setUserSettingsLanguage = (dispatch) => async (language) => {
  console.log(`setUserSettingsLanguage language is ${language}`);
  await saveAsync(Language_Storage, language.slice(0, 2));
  dispatch({ type: "set_user_settings_Language", payload: language });
}
const setUserSettingsDarkMode = (dispatch) => async (darkMode) => {
  await saveAsync(DarkMode_Storage, darkMode);
  dispatch({ type: "set_user_settings_DarkMode", payload: darkMode });
}
const setUserSettingsBrightness = (dispatch) => async (brightness) => {
  await saveAsync(Brightness_Storage, brightness);
  dispatch({ type: "set_user_settings_Brightness", payload: brightness });
}
const setUserSettingsBackgroundColor = (dispatch) => async (backgroundColor) => {
  await saveAsync(BackgroundColor_Storage, backgroundColor);
  dispatch({ type: "set_user_settings_BackgroundColor", payload: backgroundColor });
}

const setUserSettingsHomeScreenDefault = (dispatch) => async (homeScreenDefault) => {
  await saveAsync(HomeScreenDefault_Storage, homeScreenDefault);
  dispatch({ type: "set_user_settings_HomeScreenDefault", payload: homeScreenDefault });
}
const setUserSettingsTextAlign = (dispatch) => async (textAlign) => {
  dispatch({ type: "set_user_settings_TextAlign", payload: textAlign });
}

const setDefaultSettings = (dispatch) => async () => {
  const colorScheme = Appearance.getColorScheme();
let isDarkMode = colorScheme === "dark" ? true : false;
// console.log(`setDefaultSettings isDarkMode ${isDarkMode}`);
let language = Localization.locale;

const Language_Storage_Res = await getItem(Language_Storage);
console.log(`setDefaultSettings, Language_Storage is ${Language_Storage_Res}`);
if(Language_Storage_Res !== null){
  console.log(`language = Language_Storage_Res;`);
  language = Language_Storage_Res;
}
const DarkMode_Storage_Res = await getItem(DarkMode_Storage);
console.log(`setDefaultSettings, DarkMode_Storage is ${DarkMode_Storage_Res}`);

if(DarkMode_Storage_Res  !== null){
  console.log(`isDarkMode = DarkMode_Storage_Res;
    isDarkMode: ${isDarkMode}
    DarkMode_Storage_Res: ${DarkMode_Storage_Res}
    `);
  isDarkMode = DarkMode_Storage_Res;
}
const UserId_Storage_Res = await getItem(UserId_Storage);
console.log(`setDefaultSettings, UserId_Storage_Res is ${UserId_Storage_Res}`);

const IsAdmin_Storage_Res = await getItem(IsAdmin_Storage);
console.log(`setDefaultSettings, isDarkMode is ${isDarkMode}`);

    let userSettingsRes = {
      UserId: UserId_Storage_Res ? UserId_Storage_Res : '',
      UserName: '',
      TextAlign: language === "he-IL" || language == "he" ? 'right' : 'left',
      Language: language,
      DarkMode: isDarkMode,
      ThemeValue: isDarkMode === true ? 'dark' : 'light',
      HomeScreenDefault: 1,
      photo: null,
      IsAdmin: IsAdmin_Storage_Res ? IsAdmin_Storage_Res : false,
    }
    console.log(`setDefaultSettings, userSettingsRes is ${JSON.stringify(userSettingsRes)}`);

    const newLanguage = userSettingsRes.Language.slice(0, 2);
    await saveAsync(Language_Storage, newLanguage);
    await saveAsync(DarkMode_Storage, isDarkMode);
    console.log();
    dispatch({ type: "set_user_settings", payload: userSettingsRes });
}

const setFinishInit = (dispatch) => async () => {
  dispatch({ type: "setFinishInit", payload: true });
};





export const { Provider, Context } = createDataContext(
    itemReducer,
    { 
    //   signin,
    // signInGoogle,
    // signout, 
    // addErrorMessage, 
    // tryLocalSignin,
    // SignUpWithEmail, 
    // SignUpConfirmEmailCode, 
    // ConfirmUserNameAndPasswordByEmail, 
    // ResentEmailCode,
    // AddUserByEmail,
    // ResetPassword, 
    // ConfirmResetPasswordCode, 
    // ConfirmResetPassword, 
    // ForgotPasswordCodeConfirmationResetPassword,
    // setForgotPasswordCodeConfirmationModalVisible, 
    // setForgotPasswordConfirmCanChangePasswordModalVisible, 
    // setSignUpConfirmEmailCodeModalVisible,
    // ValidateUserNameNotTaken,






      setDefaultSettings, 
      setFinishInit,
      setUserSettingsLanguage, 
      setUserSettingsDarkMode,
      setUserSettingsBrightness,
      setUserSettingsBackgroundColor,
      setUserSettingsHomeScreenDefault,
      setUserSettingsTextAlign, 
    },
    { 
  //     token: null, 
  //   errorMessage: "",
  //  signinAlertMessage: "",
  //  signupAlertMessage: "",
  //  signupConfirmUserNameAndPasswordByEmailAlertMessage: "",
  //  emailConfirmationErrorMessage: "",
  //  forgotPasswordAlertMessage: false,
  //  ForgotPasswordCodeConfirmationAlertMessage: "",
  //  forgotPasswordCodeConfirmationModalVisible: false,
  //  resetPasswordshowLoader: false,
  //  resentEmailCodeshowLoader: false,
  //  forgotPasswordConfirmCanChangePasswordAlertMessage: "",
  //  forgotPasswordConfirmCanChangePasswordModalVisible: false,
  //  validateUserName: "",
  //  validateUserNameShowLoader: false,



  
      isFinishInit: false,
      isFinishFirstRequest: false,
      InitError: false,
       userSettings: {
      TextAlign: 'left',
      ThemeValue: 'light',
      Language: 'en-US',
      DarkMode: false,
      HomeScreenDefault: 1,
      photo: null,
      UserName: '',
      UserId: '',
      IsAdmin: false,
      chatSettings: [],
      Brightness: 100,
      BackgroundImage: '',
      BackgroundColor: '',
      },
      userDetails: {
        FullName: '',
        Description: '',
        ImagePath: '',
        BirthDate: '',
      },
      userBonding: [],
      usersChat: [],
      userBlocks: [],
      chatLastSeenMessages: [],
      updateState: false,
      tutorial: {
        isFinishedShowMainCategories: false,
        isFinishedShowMainCategoryTemplate: false,
        isFinishedShowCategories: false,
        isFinishedShowCategoryDetails: false,
        isFinishedShowGroup: false,
        isFinishedShowGroupDetailsAdmin: false,
        isFinishedShowUserTable: false,
        isFinishedShowGroupTable: false,
        isFinishedShowMergedGroupTable: false,
      }
    }
  );
