import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const ItemNameComponent = ({ userSettingsState, foundItem }) => {
    const isWithDiscount = foundItem.Discount > 0;
    const realPrice = foundItem.Price;
    const afterDiscount = isWithDiscount ? (foundItem.Price * (100 - foundItem.Discount) / 100).toFixed(2) : realPrice;
    

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.itemDetailsContainer}>
    <View style={styles.itemNameContainer}>
    <Text style={[styles.text, styles.itemDetails]}>{responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_en}</Text>
    </View>
    </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    itemDetailsContainer: {
        flexDirection: responsiveStyles.isRTL ? "row-reverse" : 'row',
        // justifyContent: 'space-around',
        
        marginTop: 20,
        marginBottom: 20,
        paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 15 : null,
      },
      priceContainer: {
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
      },
      itemNameContainer: {
        flex: 1, 
      },
      text: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
        textAlign: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 'center',
        },
      itemDetails: {
        fontSize: responsiveStyles.fontSize_H5,
        fontWeight: 'bold',
        fontSize: responsiveStyles.fontSize_H3,
        fontFamily: GlobalStyles.fonts.application_font,
    },
});

export default ItemNameComponent;