import React, { useState } from 'react';
import { StyleSheet,View,Text, Alert, Dimensions, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton"
import { GlobalStyles } from '../../constants/styles';
import PopUp from '../modals/PopUp';
import ButtonTypeText from '../inputs/ButtonTypeText';
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../alert/GenericAlert';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';
const ForgotPasswordCodeConfirmationForm = ({ 
  ConfirmResetPasswordCode, 
  ResetPassword, 
  email,
   forgotPasswordCodeConfirmationAlertMessage, 
   Keyboard, 
   addErrorMessage,
   resetPasswordshowLoader,
   forgotPasswordCodeConfirmationModalVisible,
   setForgotPasswordCodeConfirmationModalVisible,
   isKeyboardVisible,
   keyboardDidShowTxt,
   navigation,
   userSettingsState,
   FromScreen,
  }) => {

    
    const responsiveStyles = useResponsiveStyles(userSettingsState);

    const [showLoader, setShowLoader] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    let emailFirstPart = email.substring(0, email.indexOf("@"));
    const emailLastPart = email.substring(email.indexOf("@"), email.length);
    let replaceEmail = emailFirstPart[0];
    if(emailFirstPart.length > 3)
    {
      replaceEmail = replaceEmail + emailFirstPart[1];
      for (let i = 0; i < emailFirstPart.length - 3; i++) {
        replaceEmail = replaceEmail + "*";
      }
      replaceEmail = replaceEmail + emailFirstPart[emailFirstPart.length - 1] + emailLastPart;
    }
    else{
      replaceEmail = emailFirstPart;
    }
    if(forgotPasswordCodeConfirmationAlertMessage !== "" && forgotPasswordCodeConfirmationAlertMessage?.length > 0 && showLoader === true)
    {
      setShowLoader(false);
    }
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
  return (
    <View style={styles.container}>
        <Text style={styles.header}>{i18n.t('auth.ForgotPasswordCodeConfirmation_header')}</Text>
        <View style={{ paddingBottom: 20 }}>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.ForgotPasswordCodeConfirmation_headerDescription_1')}
        </Text>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.ForgotPasswordCodeConfirmation_headerDescription_2')}
        </Text>
        </View>
        <View style={styles.textInputContainer}>
        <TextInputCustom
        userSettingsState={userSettingsState}
      editable={true}
        label={i18n.t('auth.ForgotPasswordCodeConfirmation_codeInput')}
        value={confirmationCode}
        onChangeText={(text) => {
          if(forgotPasswordCodeConfirmationAlertMessage?.length > 0)
          {
            addErrorMessage("forgotPasswordCodeConfirmation","");
            addErrorMessage("forgotPasswordCodeConfirmationAlertMessage","");
          }
          setConfirmationCode(text);
        }}
        style={[styles.textInput, !forgotPasswordCodeConfirmationAlertMessage ? { marginBottom: 15 } : null]}
        keyboardType={'numeric'}
        iconType="cancel"
        isKeyboardVisible={isKeyboardVisible}
        keyboardDidShowTxt={keyboardDidShowTxt}
      />
      {forgotPasswordCodeConfirmationAlertMessage ? (
      <Text style={[[GlobalStyles.errorMessage,{ marginBottom: 15 }]]}>{forgotPasswordCodeConfirmationAlertMessage}</Text>
      ) : null}
      </View>
      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {

            console.log("confirmationCode " + confirmationCode);
            if(confirmationCode.length === 0)
            {
              addErrorMessage("forgotPasswordCodeConfirmationAlertMessage",i18n.t('auth.ForgotPasswordCodeConfirmation_CodeRequired'));
            }
            else if(confirmationCode.length > 6)
            {
              addErrorMessage("forgotPasswordCodeConfirmationAlertMessage",i18n.t('auth.ForgotPasswordCodeConfirmation_CodeTooLong'));
            }
            else if(confirmationCode.length < 6)
            {
              addErrorMessage("forgotPasswordCodeConfirmationAlertMessage",i18n.t('auth.ForgotPasswordCodeConfirmation_CodeTooShort'));
            }
            else {
              setShowLoader(true);
            Keyboard.dismiss();
            const response = await ConfirmResetPasswordCode({ email, code: confirmationCode, navigation });
            setShowLoader(false);
            if(response.isCompleted === true)
            {
              navigateToScreen(navigation, 'LoginFlow', `ForgotPasswordConfirmCanChangePassword`, { email, code: confirmationCode, FromScreen: FromScreen }, null);
            }
            }
        }}
        styleButton={styles.buttonStyle}
        loading={showLoader}
        >
        {i18n.t('auth.ForgotPasswordCodeConfirmation_submitButton')}
          </ButtonTypeButton>

          <ButtonTypeText
        onPress={async () =>{
          const response = await ResetPassword({ email });
          if(response.isCompleted === true)
          {
          console.log(`response.isCompleted is true ${response.isCompleted}`);
          await setForgotPasswordCodeConfirmationModalVisible({ message: true });
        }
        else{
          console.log(`response.isCompleted is false ${response.isCompleted}`);
          setbodymessge(response.message);
          setGenericAlertVisible(true);
          // Alert.alert(null,response.message, [
          //   {
          //     text: i18n.t('error.ok'),
          //   },
          // ],
          // {cancelable:true}
          // );
        }
        }}
        // styleButton={{backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor}}
        loading={resetPasswordshowLoader}
        style={{marginTop: 20, marginLeft: !responsiveStyles.isMobile ? 15 : null}}
        userSettingsState={userSettingsState}
        >
        {i18n.t('auth.ForgotPasswordCodeConfirmation_sendCodeAgain')}
          </ButtonTypeText>
        </View>
        <GenericAlert
        userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
          <PopUp
          text={i18n.t('auth.CodeSuccess')}
          modalVisible={forgotPasswordCodeConfirmationModalVisible}
          setModalVisible={setForgotPasswordCodeConfirmationModalVisible}
          showCancelButton={false}
          cancelModalTimer={true}
          userSettingsState={userSettingsState}
          />
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    fontWeight:'bold',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H5,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    marginBottom: 5,
  },
  btnContainer: {
    marginHorizontal: !responsiveStyles.isMobile ? null : 15,  
    alignSelf:'center',  
  },
  textInputContainer: {
    // flex: !responsiveStyles.isMobile ? null : 1,
    marginBottom: responsiveStyles.isWeb ? 15 : 50

  },
  textInput:{
    height: 40,
    borderWidth: 1,
    padding: 10,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    // marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  // buttonStyle: {
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor, 
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.8,
  //   alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  //   marginBottom: !responsiveStyles.isMobile ? 15 : null,
  // },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default ForgotPasswordCodeConfirmationForm;
