import { Asset } from 'expo-asset';
import { getAsset } from '../../../functions/getAsset';



  export const Mother_Tributes = [{
    Id: -1000002,
    
    UserId: null,
    UserProfileId: null,
    UserProfileFullName: "Mother",
    UserName: "Mother",
    UserImage: getAsset(require('../../../../assets/images/store/profiles/mother/main_image.png')),
    // UserImage: "",

    CreatedDate: "10/07/2024",
    Title: "Beach",
    Description: "A fun day at the beach",

    album: [
        {
            Id: -100001,
            PostId: -10001,
            MediaType: 'image',
            URL: getAsset(require('../../../../assets/images/store/profiles/mother/beach.png')),
            // URL: "",
            Duration: null,
        },
    ],
    IsUserLiked: false,
    Comments: [],
    Likes: 0,
    IsActive: true,
    CreatedDate: "2024-07-10",
  }];
