import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../constants/styles";
import i18n from "../hooks/useI18n";
import ButtonTypeText from "./inputs/ButtonTypeText";
import useResponsiveStyles from "../functions/ResponsiveStyles";
  const NotFoundComponent = ({ userSettingsState, navigation }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.centerContainer}>
    <Text style={styles.title}>{i18n.t('notFound.title')}</Text>
    <View style={{ flexDirection:'row' }}>
    <Text style={[styles.text, { textAlign:'center' }]}>
      {i18n.t('notFound.subtitle1')}

    <ButtonTypeText
    userSettingsState={userSettingsState}
    styleText={{ fontSize: responsiveStyles.fontSize_H5, }}
    onPress={() => {
      if(responsiveStyles.isWeb){
        navigation(-1);
      }
      else{
        navigation.pop();
      }
    }}
    >
    <Text style={styles.text}>{i18n.t('notFound.subtitle2')}</Text>
    </ButtonTypeText>
    </Text>
    </View>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    centerContainer: {
        flex: 1,
        alignItems: 'center',
        // justifyContent: 'center',
        backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      },
      title: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H4,
        fontFamily: GlobalStyles.fonts.application_font,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        paddingVertical: responsiveStyles.screenWidth * 0.05,
    },
    text: {
      color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,  
    },
});

export default NotFoundComponent;