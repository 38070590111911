import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import TributeEmptyListComponent from "./TributeEmptyListComponent";
import DisplayTypeComponent from "../adminsComponent/DisplayTypeComponent";
import { AntDesign } from '@expo/vector-icons';
import InviteUserAdminAlert from "../alert/InviteUserAdminAlert";
import { formatDate } from "../../functions/formatDate";
import AdminEmptyListComponent from "./AdminEmptyListComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";


  const AdminsComponent = ({ 
    userSettingsState, 
    data = [], 
    isAdmin = false,
    isEditing = false, 
    navigation, 
    UserName,
    inputs,
    inputChangedHandler,
    InviteUserAsAdminHandler,
    DeactivateUserHandler,
    ApproveUserAsAdminHandler,
    DisapproveUserAsAdminHandler
  
  }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
    const [displayType, setDisplayType] = useState('ProfileAdmins');
    const [modalVisible, setModalVisible] = useState(false);
  const [isLoading_DeactivateUser, setIsLoading_DeactivateUser] = useState(null);
  const [isLoading_Approve, setIsLoading_Approve] = useState(null);
  const [isLoading_DisapproveUser, setIsLoading_DisapproveUser] = useState(null);
    
    const PendingInvitations = data.filter(s => s.IsInvitations === true);
    const ProfileAdmins = data.filter(s => s.IsInvitations !== true);
    console.log(`ProfileAdmins is`);
    console.log(ProfileAdmins);
  return (
    <View style={{flex:1}}>
        <InviteUserAdminAlert
      navigation={navigation}
      visible={modalVisible}
      setVisible={setModalVisible}
      // onPressLoader={isReportLoaderVisible} // loader for sumbit
      // onPress={ReportGroupHandler} // submit
      UserName={UserName}
      userSettingsState={userSettingsState}
      inputs={inputs}
      inputChangedHandler={inputChangedHandler}
      onPress={InviteUserAsAdminHandler}
      />
      {isAdmin ? <View style={styles.buttonContainer}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.button}
          styleButton={styles.inviteStyleButton}
           onPress={() => {
            setModalVisible(true);
          }}
          >
            {i18n.t('account.MyAccount_AdminsComponent_InviteUser')}
      </ButtonTypeButton>
      </View>
    : null }
<DisplayTypeComponent 
userSettingsState={userSettingsState}
displayType={displayType} 
setDisplayType={setDisplayType} 
isAdmin={isAdmin}
PendingInvitationsCount={PendingInvitations.length}
/>
      <FlatList
      scrollEnabled={false}
      data={displayType === 'PendingInvitations' ? PendingInvitations: ProfileAdmins}
      renderItem={({ item, index }) =>
      {
    const formattedDate = formatDate(item.Joined);
      
     return <View style={styles.TributeContainer}>
      <View style={styles.userRowContainer}>
      <ImageProfile 
      userSettingsState={userSettingsState}
   uri={item.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
    />
    <View style={styles.userNameContainer}>
        <Text style={styles.userName}>{item.UserName}</Text>
          <Text style={styles.createdDate}>{i18n.t('account.MyAccount_AdminsComponent_Joined')} {formattedDate === null || formattedDate === "NaN/NaN/NaN" ? item.Joined : formattedDate}</Text>
        </View>
        
        </View>
        {
        item.Role === 1 ?
        <View style={[{
          justifyContent:'center', 
          paddingRight: responsiveStyles.isRTL ? null : !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.02 : 25,
          paddingLeft: !responsiveStyles.isRTL ? null : !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.02 : 25,
          },
          ]}>
          <Text style={styles.admin}>{i18n.t('account.MyAccount_AdminsComponent_Admin')}</Text>
      </View>
        : item.Role === 2 && isAdmin || (!isAdmin && item.UserId === userSettingsState?.userSettings?.UserId) ?
        <View style={{justifyContent:'center'}}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.StyleButtonContainer}
          styleButton={styles.styleButton}
          loading={isLoading_DeactivateUser === item.UserId}
          onPress={async () => {
            setIsLoading_DeactivateUser(item.UserId);
            await DeactivateUserHandler(item.UserId);
            setIsLoading_DeactivateUser(null);
        }}
          >
          {i18n.t('account.MyAccount_AdminsComponent_Deactivate')}
          </ButtonTypeButton>
      </View>
      : item.Role === 3 ? 
      <View style={{justifyContent:  'space-around', flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', flex: !responsiveStyles.isMobile ? null : 1}}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.StyleButtonContainer_arrpoveIconButtons}
          styleButton={styles.styleButton_arrpoveIconButtons}
          loading={isLoading_DisapproveUser  === item.UserId}
          onPress={async () => {
            setIsLoading_DisapproveUser(item.UserId);
            await DisapproveUserAsAdminHandler(item.UserId);
            setIsLoading_DisapproveUser(null);
        }}
          >
          <AntDesign name="closecircleo" size={28} color={GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor} />
          </ButtonTypeButton>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={[styles.StyleButtonContainer_arrpoveIconButtons]}
          styleButton={styles.styleButton_arrpoveIconButtons}
          loading={isLoading_Approve === item.UserId}
          onPress={async () => {
            setIsLoading_Approve(item.UserId);
            await ApproveUserAsAdminHandler(item.UserId);
            setIsLoading_Approve(null);
        }}
          >
          <AntDesign name="checkcircleo" size={28} color={GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor} />
          </ButtonTypeButton>
      </View>
      : null}
      </View>
      }}
      // there cant be an empty list cause there will ALWAYS be atleast one Admin
      ListEmptyComponent={
        <AdminEmptyListComponent UserName={UserName} isEditing={isEditing} userSettingsState={userSettingsState} />
          }
      />
  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({



  TributeContainer: {
    flex:1,
    // marginHorizontal: responsiveStyles.screenWidth * 0.05,
    padding: 15,
    borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth: 1,
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 
    : responsiveStyles.Tablet ? responsiveStyles.screenWidth * 0.7
    : responsiveStyles.screenWidth * 0.9,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginVertical: 10,
    borderRadius: 10,
    alignSelf:'center',
  },
  bottomCard: {

  },
  userRowContainer: {
    flex: 3,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',
  },
  InvintationContainer: {
    alignItems:'center',
    justifyContent: 'center',
  },
  userPhoto: {
    width: 50,
    height: 50,
    borderRadius: 100,

    marginRight: !responsiveStyles.isRTL ? 10 : null,
    marginLeft: responsiveStyles.isRTL ? 10 : null,
  },
  userNameContainer: {
    width: responsiveStyles.screenWidth * 0.7,
    alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
    paddingLeft: !responsiveStyles.isRTL ? 10 : null,
    paddingRight: responsiveStyles.isRTL ? 10 : null,
  },
  admin: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
  },
  userName: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  createdDate: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  title: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  description: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  buttonContainer: {
    alignItems:'center',
    flex:1,
    paddingTop: 10,
  },
  button: {
    width: !responsiveStyles.isMobile || responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
    minWidth: !responsiveStyles.isMobile || responsiveStyles.isWeb ? 150 : null,
  },
  inviteStyleButton: {
    // backgroundColor: GlobalStyles.colors.Lavender,
  },
  StyleButtonContainer: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingRight: !responsiveStyles.isMobile ? !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.005 : null : null,
    paddingLeft: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.005 : null : null,
   alignItems: responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
 },
 StyleButtonContainer_arrpoveIconButtons: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  paddingRight: !responsiveStyles.isMobile ? !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.005 : null : null,
  paddingLeft: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.005 : null : null,
 alignItems: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'flex-start' : 'flex-end' : null,
},
 styleButton:{
  //  backgroundColor: GlobalStyles.colors.CancelButton,
   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : null,
   minWidth: !responsiveStyles.isMobile ? 125 : null,
 },
 styleButton_arrpoveIconButtons:{
  backgroundColor: null,
  // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.05 : null,
  borderWidth: 0,
},
});

export default AdminsComponent;