import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions, Platform, ImageBackground } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import { Asset } from 'expo-asset';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigateToScreen } from "../../navigationResponsive";

  const BackgroundImageComponent = ({ 
    userSettingsState, 
    navigation,
    user,
    AddRequestUserAdmin,
    CancelRequestUserAdmin,
    IsMyJewelryScreen = false,
    isAdmin = false,
    UserId,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
    const [loading, setLoading] = useState(false);
  return (
    <View>
        {/* <ImageBackground
        source={user.BackgroundImage}
        style={styles.backgroundBlur}
        blurRadius={15} // This adds the blur effect (React Native)
      > */}
    <TouchableOpacity style={styles.backgroundImageContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
         uri={user.BackgroundImage}
        imageStyle={styles.backgroundImage}
        isBackgroundImage={true}
        editable={false}
        isGroup={false}
        // userName={user?.UserName}
        // resizeMode={"contain"}
          />
      </TouchableOpacity>
      {/* </ImageBackground> */}


      {/* {isAdmin && !IsMyJewelryScreen ? 
      <View style={styles.buttonContainer}>
      <ButtonTypeButton
      userSettingsState={userSettingsState}
      style={styles.button}
      loading={loading}
      // styleButton={{ backgroundColor: Gold }}
      onPress={() => {
        navigateToScreen(navigation, 'AccountFlow', `EditProfile`, { UserId: UserId }, UserId);
      }}
      >
    {i18n.t(`account.BirthDateComponent_EditProfile`)}
  </ButtonTypeButton>
  </View>
  : null
      }
      {!user.IsUserHaveRole && userSettingsState?.userSettings?.UserId && IsMyJewelryScreen ? 
      <View style={styles.buttonContainer}>
      <ButtonTypeButton
      userSettingsState={userSettingsState}
      style={styles.button}
      loading={loading}
      // styleButton={{ backgroundColor: Gold }}
      onPress={async () => {
        let response;
        setLoading(true);
        if(!user.IsSendAdminRequest){
          response = await AddRequestUserAdmin();
        }
        else{
          response = await CancelRequestUserAdmin();
        }
        setLoading(false);
        if(!response.isCompleted){

        }
      }}
      >
    {!user.IsSendAdminRequest ? i18n.t(`account.BirthDateComponent_RequestAdmin`) : i18n.t(`account.BirthDateComponent_CancelRequestAdmin`)}
  </ButtonTypeButton>
  </View>
  : null } */}
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  backgroundImageContainer: {
    position:'absolute',
    top: 0,
    // left: responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : null,
  },
  backgroundBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth / 3,
    minHeight: 150,
    width: responsiveStyles.screenWidth,
    maxWidth: 1200,
  },
  backgroundImage: {
    height: responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth / 3,
    minHeight: 150,
    width: responsiveStyles.screenWidth,
    maxWidth: 1200,
    marginLeft: responsiveStyles.screenWidth > 1200 ? (responsiveStyles.screenWidth - 1200) / 2 : null,


    // old

    // width: responsiveStyles.screenWidth,
    // height: 150,
    // maxWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : null,
    // // height: !responsiveStyles.isMobile ? screenHeight > 350 ? 350 : screenHeight : 150,
    // borderColor: GlobalStyles[ThemeValue]?.primaryBackgroundColor,

    //   //   // aspectRatio: 16 / 9,
  },
  buttonContainer: {
    position: 'absolute',
    top: (responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth / 3) + 10,
    right: (responsiveStyles.screenWidth > 1200 ? (responsiveStyles.screenWidth - 1200) / 2 : 20) + 10,
  },
  button: {
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.85,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.4,
    width: responsiveStyles.screenWidth * 0.1,
    minWidth: !responsiveStyles.isMobile ? 250 : 100,
  },
});

export default BackgroundImageComponent;