export default {
    Title: "Terms & Conditions",
    description: "Welcome to TMJ! These terms and conditions outline the rules and regulations for the use of our website and services. By accessing or using our website and services, you agree to be bound by these terms and conditions. Please read them carefully.",
    Section1_Title: "1. Introduction",
    Section1_1: `These Terms & Conditions apply to the TMJ website and all associated services provided by TMJ. By using our website and services, you agree to comply with and be bound by these Terms.`,
    Section2_Title: "2. User Accounts",
    Section2_1_1: `2.1 Account Creation:`,
    Section2_1_2: ` To access certain features of our website, you may need to create an account. You agree to provide accurate and complete information during the registration process and to update this information as necessary.`,
    Section2_2_1: `2.2 Account Security:`,
    Section2_2_2: ` You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account.`,
    Section3_Title: "3. Use of Services",
    Section3_1_1: `3.1 Eligibility:`,
    Section3_1_2: ` You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this eligibility requirement.`,
    Section3_2_1: `3.2 License:`,
    Section3_2_2: ` TMJ grants you a limited, non-exclusive, non-transferable, and revocable license to use our services for personal, non-commercial use only.`,
    Section3_3_1: `3.3 Prohibited Activities:`,
    Section3_3_2: ` You agree not to:`,
    Section3_3_reason_1: `Use our services for any unlawful purpose.`,
    Section3_3_reason_2: `Upload any content that is offensive, harmful, or violates any third-party rights.`,
    Section3_3_reason_3: `Attempt to interfere with the proper functioning of our website or services.`,
    Section4_Title: "4. Orders and Payments",
    Section4_1_1: `4.1 Orders:`,
    Section4_1_2: ` By placing an order, you agree to purchase the jewelry subject to these Terms. All orders are subject to availability and acceptance by TMJ.`,
    Section4_2_1: `4.2 Pricing:`,
    Section4_2_2: ` All prices are listed in [Currency] and are subject to change without notice. Prices do not include applicable taxes or shipping charges, which will be calculated at checkout.`,
    Section4_3_1: `4.3 Payment:`,
    Section4_3_2: ` Payments must be made through the payment methods provided on our website. By providing payment information, you represent and warrant that you have the legal right to use the payment method selected.`,
    Section5_Title: "5. Shipping and Delivery",
    Section5_1_1: `5.1 Shipping:`,
    Section5_1_2: ` We will ship the jewelry to the address provided during checkout. Shipping times may vary depending on your location and other factors.`,
    Section5_2_1: `5.2 Delivery:`,
    Section5_2_2: ` We are not responsible for any delays in delivery caused by events outside our control. In case of significant delays, we will inform you and provide an estimated delivery date.`,
    Section6_Title: "6. Returns and Refunds",
    Section6_1_1: `6.1 Returns:`,
    Section6_1_2: ` If you are not satisfied with your purchase, you may return the jewelry within 30 days of receipt for a refund or exchange, subject to our return policy.`,
    Section6_2_1: `6.2 Refunds:`,
    Section6_2_2: ` Refunds will be processed within [number] days of receiving the returned item. The refund will be issued to the original payment method used during the purchase.`,
    Section7_Title: "7. Intellectual Property",
    Section7_1_1: `7.1 Ownership:`,
    Section7_1_2: ` All content on our website, including text, graphics, logos, and images, is the property of TMJ or its licensors and is protected by intellectual property laws.`,
    Section7_2_1: `7.2 License to Content:`,
    Section7_2_2: ` By uploading content to our website, you grant TMJ a worldwide, non-exclusive, royalty-free license to use, reproduce, and display the content in connection with our services.`,
    Section8_Title: "8. Privacy",
    Section8_1: `Your use of our services is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices.`,
    Section9_Title: "9. Limitation of Liability",
    Section9_1: `To the fullest extent permitted by law, TMJ shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:`,
    Section9_1_reason_1: `Your use of or inability to use our services.`,
    Section9_1_reason_2: `Any unauthorized access to or use of our servers and/or any personal information stored therein.`,
    Section9_1_reason_3: `Any interruption or cessation of transmission to or from our services.`,
    Section10_Title: "10. Governing Law",
    Section10_1: `These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.`,
    Section11_Title: "11. Changes to Terms",
    Section11_1: `TMJ reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. You are advised to review these Terms periodically for any changes. Your continued use of the services after any changes to the Terms constitutes acceptance of those changes.`,
    Section12_Title: "12. Contact Us",
    Section12_1: `If you have any questions about these Terms, please contact us at:`,
    ContactUs_Email: "Email:",
    ContactUs_Phone: "Phone:",
    FinalWords: `Thank you for choosing TMJ. We appreciate your business and look forward to helping you share your greatest memories with your loved ones.`,
  };