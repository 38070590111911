export default {
    CompanyName: "Timeless Memories jewelry ©",
    AlreadyHaveAccountButton: "Already have an account?",
    EmailInput: "Username, email or mobile number",
    CodeSuccess: "Code was sent",
    Next: "Next",
    SignIn_PasswordInput: "Password",
    SignIn_LogInButton: "Log In",
    SignIn_ForgotPasswordButton: "Forgot password?",
    SignIn_CreateNewAccountButton: "Create new account",
    SignIn_EmailRequired_subject: `Email address or mobile number required`,
    SignIn_EmailRequired_message: `Enter you email address or mobile number to continue.`,
    SignIn_EmailRequired_signUpText: 'OK',
    SignIn_PasswordRequired_subject: `Password Required`,
    SignIn_PasswordRequired_message: `Enter your password to continue.`,
    SignIn_PasswordRequired_signUpText: `OK`,
    SignIn_CantFindAccountAlert_subject: `Can't Find Account`,
    SignIn_CantFindAccountAlert_message1: `We can't find an account with `,
    SignIn_CantFindAccountAlert_message2: `. Try another phone number or email, or if you don't have a TMJ account, you can sign up.`,
    SignIn_CantFindAccountAlert_signUpText: 'Sign up',
    SignIn_CantFindAccountAlert_tryAgainText: "Try again",
    SignIn_IncorrectPasswordAlert_subject: `Incorrect Password`,
    SignIn_IncorrectPasswordAlert_message: `The password you entered is incorrect. Please try again.`,
    SignIn_IncorrectPasswordAlert_signUpText: 'OK',
    SignUp_headerTypeEmail: "What's your email?",
    SignUp_headerTypeMobileNumber: "What's your mobile number?",
    SignUp_headerEmail: "Email",
    SignUp_headeremail: "email",
    SignUp_headerMobileNumber: "Mobile number",
    SignUp_headermobileNumber: "mobile number",
    SignUp_headerDescriptionTypeEmail_1: "Enter the email where you can be contacted.",
    SignUp_headerDescriptionTypeEmail_2: "No one will see this on your profile.",
    SignUp_headerDescriptionTypeMobileNumber_1: "Enter the mobile number where you can be contacted.",
    SignUp_headerDescriptionTypeMobileNumber_2: "No one will see this on your profile.",
    SignUp_emailErrorMessage: "Enter a valid email address.",
    SignUp_smsInfo: "You may recieve SMS notifications from us for security and login purposes.",
    SignUp_SubmitButton: "Next",
    SignUp_signUpWith: "Sign up with ",
    SignUp_AccountExistAlert_subject: "Are you trying to log in?",
    SignUp_AccountExistAlert_message: "This email is associated with an existing account. You can log into it or reset your password",
    SignUp_AccountExistAlert_logIn: "Log into an existing account",
    SignUp_AccountExistAlert_ForgotPassword: "Reset password",
    SignUpConfirmEmailCode_header: "Enter the confirmation code",
    SignUpConfirmEmailCode_headerDescription_1: "To confirm your account, enter the 6-dight code we sent",
    SignUpConfirmEmailCode_headerDescription_2: "to ",
    SignUpConfirmEmailCode_confirmationCode: "Confirmation Code",
    SignUpConfirmEmailCode_didntGetCode: "I didn't get the code",
    SignUpConfirmEmailCode_submitButton: 'Next',


    // SignUpConfirmEmailCodeAlert_CodeRequired_subject: "Enter a code",
    // SignUpConfirmEmailCodeAlert_CodeRequired_message: `You'll need to enter a code to continue.`,
    // SignUpConfirmEmailCodeAlert_CodeRequired_TryAgain: `Try again`,
    // SignUpConfirmEmailCodeAlert_IncorrectCode_subject: "That code didn't work",
    // SignUpConfirmEmailCodeAlert_IncorrectCode_message: `Please re-enter you code or request a new one.`,
    // SignUpConfirmEmailCodeAlert_IncorrectCode_TryAgain: `Try again`,
    SignUpConfirmEmailCodeAlert_CodeRequired: `Code is required. Check your email or text messages to find the code.`,
    SignUpConfirmEmailCodeAlert_CodeTooShort: `Code is too short. Check that you entered it correctly and try again.`,
    SignUpConfirmEmailCodeAlert_CodeTooLong: `Code is too long. Check that you entered it correctly and try again.`,
    SignUpConfirmEmailCodeAlert_IncorrectCode: `That code isn't valid. You can request a new one.`,
    SignUpConfirmUserNameAndPasswordByEmail_header: "Create a Username and password",
    SignUpConfirmUserNameAndPasswordByEmail_headerDescription_1: "Create a password with at least 6 letters or numbers.",
    SignUpConfirmUserNameAndPasswordByEmail_headerDescription_2: "It should be something others can't guess.",
    SignUpConfirmUserNameAndPasswordByEmail_userName: "User Name",
    SignUpConfirmUserNameAndPasswordByEmail_firstName: "First Name",
    SignUpConfirmUserNameAndPasswordByEmail_lastName: "Last Name",
    SignUpConfirmUserNameAndPasswordByEmail_NameRequiredErrorMessage: `You'll need to enter a first name and last name to continue.`,
    SignUpConfirmUserNameAndPasswordByEmail_password: "Password",
    SignUpConfirmUserNameAndPasswordByEmail_UsernameRequiredErrorMessge: "Choose a username to continue.",
    SignUpConfirmUserNameAndPasswordByEmail_UsernameNotAvailableErrorMessge_part1: "The username ",
    SignUpConfirmUserNameAndPasswordByEmail_UsernameNotAvailableErrorMessge_part2: " is not available",
    SignUpConfirmUserNameAndPasswordByEmail_PasswordRequiredErrorMessage: "You'll need to enter a password to continue.",
    SignUpConfirmUserNameAndPasswordByEmail_PasswordLengthErrorMessge: "Create a password at least 6 characters long.",
    SignUpConfirmUserNameAndPasswordByEmail_PasswordTooEasyErrorMessge: "This password is too easy to guess. Please create a new one.",
    SignUpConfirmUserNameAndPasswordByEmail_UserExist_subject: 'Username is not available',
    SignUpConfirmUserNameAndPasswordByEmail_UserExist_message1: 'The username ',
    SignUpConfirmUserNameAndPasswordByEmail_UserExist_message2: ' is not available. try another username',
    SignUpConfirmUserNameAndPasswordByEmail_UserExist_TryAgain: 'Try again',
    SignUpConfirmation_header: "Sign up as ",
    SignUpConfirmation_headerDescription: "You can always change your username later.",
    SignUpConfirmation_footerDescription: "By tapping 'Sign Up', you agree to our Terms, Privacy Policy and Coockies Policy",
    SignUpConfirmation_submitButton: "Sign up",
    ForgotPassword_header: "Finding you account",
    ForgotPassword_headerDescription: "Enter your Username, email or mobile number",
    ForgotPassword_submitButton: "Find account",
    ForgotPassword_emailError: "Enter your email or mobile number to continue.",
    ForgotPassword_AccountNotFoundAlert_subject: 'No account found',
    ForgotPassword_AccountNotFoundAlert_message: 'Check you username, mobile number or email and try again.',
    ForgotPassword_AccountNotFoundAlert_okButton: 'OK',
    ForgotPasswordCodeConfirmation_header: "Confirm your account",
    ForgotPasswordCodeConfirmation_headerDescription_1: "We sent a code to your email.",
    ForgotPasswordCodeConfirmation_headerDescription_2: "Enter that code to confirm your account.",
    ForgotPasswordCodeConfirmation_codeInput: "Enter code",
    ForgotPasswordCodeConfirmation_submitButton: "Continue",
    ForgotPasswordCodeConfirmation_sendCodeAgain: "Send code again",
    ForgotPasswordCodeConfirmation_CodeRequired: `Code is required. Check your email or text messages to find the code.`,
    ForgotPasswordCodeConfirmation_CodeTooShort: `Code is too short. Check that you entered it correctly and try again.`,
    ForgotPasswordCodeConfirmation_CodeTooLong: `Code is too long. Check that you entered it correctly and try again.`,
    ForgotPasswordCodeConfirmation_IncorrectCode: `That code isn't valid. You can request a new one.`,
    ForgotPasswordConfirmCanChangePassword_header: "Create new password",
    ForgotPasswordConfirmCanChangePassword_headerDescription_1: "Create a password with at least 6 letters and numbers.",
    ForgotPasswordConfirmCanChangePassword_headerDescription_2: "You'll need this password to log into your account.",
    ForgotPasswordConfirmCanChangePassword_passwordInput: "Continue",
    ForgotPasswordConfirmCanChangePassword_sumbitButton: "Next",
    ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_subject: "Enter a password",
    ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_message: "You'll need to enter a password to continue.",
    ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_okButton: "Try again",
    ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_message: "Create a password at least 6 characters long.",
    ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton: "OK",
    ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_message: "This password is too easy to guess. Please create a new one.",
    ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_okButton: "OK",
    ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_message: "That code isn't valid. You can request a new one.",
    ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_okButton: "OK",
    InitMessages_loadingMessages: `Syncing messages, please wait`,
    ResolveAuth_loadingData: `Bringing data`,
  };