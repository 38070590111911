import * as Font from "expo-font";
import { Platform } from "react-native";
 
const useFonts = async () =>{
  // if(Platform.OS !== "android" && Platform.OS !== "ios"){

  // }
  // else{
    await Font.loadAsync({
      'Inter-Black': require('../../assets/fonts/Inter-Black-900.otf'),
      'Assistant-ExtraLight': require('../../assets/fonts/Assistant-ExtraLight.otf'),
      'Assistant-Light': require('../../assets/fonts/Assistant-Light.otf'),
      'Assistant-Regular': require('../../assets/fonts/Assistant-Regular.otf'),
      'Assistant-SemiBold': require('../../assets/fonts/Assistant-SemiBold.otf'),
      'Assistant-Bold': require('../../assets/fonts/Assistant-Bold.otf'),
      'Assistant-ExtraBold': require('../../assets/fonts/Assistant-ExtraBold.otf'),
      // '': require('../../assets/fonts/.otf'),
    });
  // }
}

export default useFonts;