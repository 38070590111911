import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, TouchableOpacity, FlatList, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { AssignProfileToJewlery, GetJewleryById, GetJewleryByIdAndProfiles, GetOrderById } from '../../api/apiCalls/ProfileCalls';
import OrderItemsListComponent from '../../components/myAccountScreen/OrderItemsListComponent';
import OrderItemsListDetailsComponent from '../../components/myAccountScreen/OrderItemsListDetailsComponent';
import ImageProfile from '../../components/images/ImageProfile';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import { isValidGuid } from '../../functions/utils';
import NotFoundComponent from '../../components/NotFoundComponent';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import MediaEmptyListComponent from '../../components/displayComponent/MediaEmptyListComponent';
import AssignJewleryToProfileComponent from '../../components/displayComponent/ProfileEmptyListComponent';
import { StoreItems } from '../../constants/store/Store_Items';
const AssignJewleryToProfileScreen = ({  }) => {

  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const Id = navigationParams?.Id || IdFromURL;
  const IsIdValid = isValidGuid(Id);

    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const [profiles, setProfiles] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState('');
    const [jewlery, setJewlery] = useState(null);

    const foundStoreItem = StoreItems.find(storeItem => 
      storeItem.Items.find(item1 => item1.Id === jewlery?.JewleryId)
    );
let foundItem = foundStoreItem ? foundStoreItem.Items.find(item1 => item1.Id === jewlery?.JewleryId) : null;

    const [isLoading, setIsLoading] = useState(false);


    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);

    const submitHandler = async () => {
      const dataToSend = {
        UserJewleryId: Id,
        UserProfileId: selectedProfileId === null ? "00000000-0000-0000-0000-000000000000" : selectedProfileId,
      };
      console.log(`dataToSend is ${JSON.stringify(dataToSend)}`);
      const response = await AssignProfileToJewlery(dataToSend);
        if(response.isCompleted === true)
        {
          responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Assign Jewlery To Profile";
      }
      const fetchData = async () => {
        try {
          setLoading(true);
          const req = await GetJewleryByIdAndProfiles(Id);
          if (req.isCompleted) {
            const vm = JSON.parse(req.answer);
            setProfiles(vm.Profiles);
            setJewlery(vm.Jewlery);
            setSelectedProfileId(vm.Jewlery.UserProfileId)
          }
          else{
            setLoading(true);
            setErrorOnRequest(true);
          }
        } catch (err) {
          console.log(err);
          setLoading(true);
          setErrorOnRequest(true);
        }
        finally{
          setLoading(false);
        }
      };
      if(isFirstTime)
        {
          fetchData();
          setIsFirstTime(false);
        }
        else{
          // setLoading(true);
          setErrorOnRequest(false);
          if(errorOnRequest === true)
          {
            fetchData();
          }
        }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[reloadpage]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    {
      !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
    : errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
    <ScrollView style={[styles.container]}>
      <>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{i18n.t('account.AssignJewleryToProfileScreen_Title')}</Text>
    </View>
      <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>

    
    <View style={styles.rowContainer}>
      <Text style={styles.text}>{i18n.t('account.AssignJewleryToProfileScreen_JewleryName')}:</Text>
      <Text style={[styles.text,styles.JewleryName]}>{foundItem ? responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_he : jewlery.JewleryName}</Text>
    </View>

    <View style={[styles.rowContainer, { paddingBottom: !responsiveStyles.isMobile ? 40 : 20 }]}>
      <Text style={styles.text}>{i18n.t('account.AssignJewleryToProfileScreen_SelectedProfile')}:</Text>
      <Text style={[styles.text,styles.JewleryName]}>
        {profiles.find(profile => profile.Id === selectedProfileId)?.FullName || profiles.find(profile => profile.Id === selectedProfileId)?.PetName}
        </Text>
    </View>

    <FlatList
          scrollEnabled={false}
          data={profiles}
          style={styles.flatListContainer}
    key={(item) => item.Id.toString()} 
          renderItem={({ item, index }) =>
          {
            return <View style={[styles.itemContainer, item.Id === selectedProfileId ? styles.selectedContainer : null]}>
              <View style={styles.imageContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={item.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
  isTouchable={false}
    />
    </View>
    <View style={styles.profileNameContainer}>
      <Text style={[styles.text]}>{item.IsPet ? item.PetName : item.FullName}</Text>
    </View>
    <TouchableOpacity
    style={[styles.ProfileContainer, item.Id === selectedProfileId ? styles.selectedProfileContainer : null]}
    onPress={() => {
      if(selectedProfileId === item.Id){
        setSelectedProfileId(null);
      }
      else{
        setSelectedProfileId(item.Id);
      }
    }}
    >
      {/* <Text style={[styles.text]}></Text> */}
    </TouchableOpacity>
  </View>
          }}
          ListEmptyComponent={
            <AssignJewleryToProfileComponent
            userSettingsState={userSettingsState}
            navigation={navigation}
            IsPet={false}
            />
              }
          />

    <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        loading={isLoading}
        onPress={async () => {
          setIsLoading(true);
          await submitHandler();
          setIsLoading(false);
      }}
        >
          {i18n.t('account.AssignJewleryToProfileScreen_AssignToProfile')}
    </ButtonTypeButton>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        // styleButton={{backgroundColor: GlobalStyles.colors.CancelButton}}
         onPress={() => {
          responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
        }}
        >
          {i18n.t('account.AssignJewleryToProfileScreen_Cancel')}
    </ButtonTypeButton>
    </View>
    </View>
    </>
    </ScrollView>
    )}
    </Screen>

}

AssignJewleryToProfileScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    padding: 20,
    // height: fixedScreenHeight,
  },
  subContainer: {
    flex: 1,
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.7
    : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  titleContainer: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
    paddingBottom: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
},
title: {
  fontSize: responsiveStyles.fontSize_H3,
    fontWeight: '700',
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
text: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
flatListContainer: {
  // paddingTop: 10,
},
rowContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
  paddingVertical: 10,
  flex:1,
  // paddingHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : null,
},
imageContainer: {
    // backgroundColor:'blue',
    // marginHorizontal: -20,
    // width: responsiveStyles.screenWidth,
    alignSelf: 'center',
},
userPhoto: {
  width: 40,
  height: 40,
  borderRadius: 50,
  borderWidth: 1,
  borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  itemContainer: {
    flex:1,
    padding: 15,
    borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth: 1,
    // width: responsiveStyles.screenWidth * 0.8,
    marginVertical: 10,
    borderRadius: !responsiveStyles.isMobile ? 30 : 15,

    alignItems: 'flex-end',
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',

  },
  profileNameContainer: {
    flex:1,
    alignSelf:'center',
    paddingHorizontal: 10,
  },
  ProfileContainer: {
    alignSelf:'center',
    borderRadius: 20,
    borderWidth: 1,
    padding: 10,
  },
  selectedProfileContainer: {
    backgroundColor: GlobalStyles.colors.Lavender,
  },
  selectedContainer: {
    // backgroundColor: GlobalStyles.colors.Gold,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.selectedItemBackgroundColor,
  },
  JewleryName: {
    // width: responsiveStyles.screenWidth * 0.55,
    //  textAlign: 'center',
  },
  buttonContainer: {
    marginVertical: 20,
    alignItems:'center',
    flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    justifyContent: !responsiveStyles.isMobile ? 'space-around' : null,
  },
  button: {
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.5,
    minWidth: !responsiveStyles.isMobile ? 115 : null,
    paddingVertical: 10,
  },
});

export default AssignJewleryToProfileScreen;
