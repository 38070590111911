import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  BackHandler,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';  // Import useFocusEffect from @react-navigation/native
import { Context as AuthContext } from '../../context/AuthContext';
import SignUpConfirmEmailCodeForm from '../../components/forms/SignUpConfirmEmailCodeForm';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const SignUpConfirmEmailCodeScreen = ({  }) => {
  
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const email = navigationParams?.email;
  console.log(`email is ${email}`);
  const { state, addErrorMessage, SignUpConfirmEmailCode, ResentEmailCode, setSignUpConfirmEmailCodeModalVisible } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);
	const styles = styling(responsiveStyles);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardDidShowTxt, setKeyboardDidShowTxt] = useState('');

  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  // useEffect to manage showLoader state and keyboard listeners
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Sign Up Confirmation Email Code";
    }
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true);
      setKeyboardDidShowTxt("keyboardDidShow");
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false);
      setKeyboardDidShowTxt("keyboardDidHide");
    });
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
      backHandler.remove();
    };
  }, []);

  // useFocusEffect to handle focus-related effects
  if(responsiveStyles.isWeb){
  }
  else{
    useFocusEffect(
      React.useCallback(() => {
        // Function to run when screen gains focus
        setShowLoader(false); // Example: Resetting loader state
        addErrorMessage("emailConfirmation", ""); // Example: Clearing error message
  
        // Clean-up function when screen loses focus (optional)
        return () => {
          // Perform any clean-up here if necessary
        };
      }, [])
    );
  }

  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
          {/* <BackButtonComponent userSettingsState={userSettingsState} navigation={navigation} onPress={backAction} /> */}
          <SignUpConfirmEmailCodeForm
            errorMessage={state.errorMessage}
            SignUpConfirmEmailCode={SignUpConfirmEmailCode}
            ResentEmailCode={ResentEmailCode}
            resentEmailCodeshowLoader={state.resentEmailCodeshowLoader}
            ShowPassword={false}
            navigation={navigation}
            email={email}
            emailConfirmationErrorMessage={state.emailConfirmationErrorMessage}
            addErrorMessage={addErrorMessage}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            signUpConfirmEmailCodeModalVisible={state.signUpConfirmEmailCodeModalVisible}
            setSignUpConfirmEmailCodeModalVisible={setSignUpConfirmEmailCodeModalVisible}
            isKeyboardVisible={isKeyboardVisible}
            keyboardDidShowTxt={keyboardDidShowTxt}
            userSettingsState={userSettingsState}
          />
        <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </Screen>
  );
};

SignUpConfirmEmailCodeScreen.navigationOptions = {
  header: () => false,
};

const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    inner: {
      padding: 15,
      flex: 1,
    },
  });

export default SignUpConfirmEmailCodeScreen;
