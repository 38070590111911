import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { isNumeric } from "../../functions/utils";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TextInputCustom from "../inputs/TextInputCustom";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
  const ManagePetProfileForm_Vet_PhoneNumberComponent = ({ 
    userSettingsState,
    inputs,
    inputChangedHandler,
    editable,
    containerStyle,
   }) => {

    const [isOtherVisible, setIsOtherVisible] = useState(false);
    const [lastValue, setLastValue] = useState("");
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
<View style={[styles.mainContainer, containerStyle]}>
<TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.Vet_PhoneNumber.value}
                setValue={(value) => {
                    if(editable){   
                      inputChangedHandler("Vet_PhoneNumber", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_Vet_PhoneNumber')}`}
                isValid={inputs.Vet_PhoneNumber.isValid}
                // styleTextInputContainer={styles.textInputPhoneNumber}
                styleTextInputContainer={[styles.inputStyleOneRow]}
                multiline={true}
                // heightTextInput={responsiveStyles.screenHeight * 0.1 }
            />
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  mainContainer: {
    // backgroundColor: 'blue',
    // paddingBottom: 15,
    flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    justifyContent: !responsiveStyles.isMobile ?  'space-between' : null,
  },
  secondaryContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',
  },
  subtitle: {
    fontSize: responsiveStyles.fontSize_H5,
    fontWeight: '500',
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
    paddingBottom: 10,
},
styleButton: {
  paddingHorizontal: 25,
},
chosenButton: {
  backgroundColor: Gold,
},
buttonStyle: {
  paddingRight: responsiveStyles.isRTL ? null : 25,
    paddingLeft: !responsiveStyles.isRTL ? null : 25,
},
inputStyleOneRow: {
  width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 
  (responsiveStyles.screenWidth) * 0.22 * 2 
  : responsiveStyles.isTablet ? (responsiveStyles.screenWidth) * 0.3 * 2 
  : null,
},
});

export default ManagePetProfileForm_Vet_PhoneNumberComponent;