import React, { useEffect } from "react";
import { Appearance, Platform, I18nManager, Text } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createSwitchNavigator } from '@react-navigation/native';
import { Provider as AuthProvider } from "./src/context/AuthContext";
import { Provider as UserSettingsContext } from "./src/context/UserSettingsContext";
import { Provider as ShopContext } from "./src/context/ShopContext";
import useFonts from "./src/hooks/useFonts";
import { setNavigator } from './src/navigationRef';
import ProfileScreen from './src/screens/Discover/ProfileScreen';
import MyAccountScreen from "./src/screens/Account/MyAccountScreen";
import CreateProfileScreen from "./src/screens/Account/CreateProfileScreen";
import EditProfileScreen from "./src/screens/Account/EditProfileScreen";
import ManageMediaPostScreen from "./src/screens/Account/ManageMediaPostScreen";
import ManageTributePostScreen from "./src/screens/Account/ManageTributePostScreen";
import ImageMediaScreen from "./src/screens/Discover/ImageMediaScreen";
import SigninScreen from "./src/screens/auth/SigninScreen";
import SignUpWithEmailScreen from "./src/screens/auth/SignUpWithEmailScreen";
import SignUpConfirmEmailCodeScreen from "./src/screens/auth/SignUpConfirmEmailCodeScreen";
import SignUpConfirmUserNameAndPasswordByEmailScreen from "./src/screens/auth/SignUpConfirmUserNameAndPasswordByEmailScreen";
import SignUpConfirmationScreen from "./src/screens/auth/SignUpConfirmationScreen";
import ForgotPasswordScreen from "./src/screens/auth/ForgotPasswordScreen";
import ForgotPasswordConfirmCanChangePasswordScreen from "./src/screens/auth/ForgotPasswordConfirmCanChangePasswordScreen";
import ForgotPasswordCodeConfirmationScreen from "./src/screens/auth/ForgotPasswordCodeConfirmationScreen";
import LoaderAuthScreen from "./src/screens/auth/LoaderAuthScreen";
import HomeScreen from "./src/screens/Store/HomeScreen";
import ItemDetailsScreen from "./src/screens/Store/ItemDetailsScreen";
import ItemListScreen from "./src/screens/Store/ItemListScreen";
import CartScreen from "./src/screens/Store/CartScreen";
import CartInformationScreen from "./src/screens/Store/CartInformationScreen";
import CartShippingInformationScreen from "./src/screens/Store/CartShippingInformationScreen";
import CartPaymentScreen from "./src/screens/Store/CartPaymentScreen";
import PaymentSuccessfulScreen from "./src/screens/Store/PaymentSuccessfulScreen";
import JewleryDetailsScreen from "./src/screens/Account/JewleryDetailsScreen";
import OrderDetailsScreen from "./src/screens/Account/OrderDetailsScreen";
import AssignJewleryToProfileScreen from "./src/screens/Account/AssignJewleryToProfileScreen";
import AboutUsScreen from "./src/screens/Store/quickLinks/AboutUsScreen";
import AmbassadorScreen from "./src/screens/Store/quickLinks/AmbassadorScreen";
import HelpScreen from "./src/screens/Store/quickLinks/HelpScreen";
import PrivacyScreen from "./src/screens/Store/quickLinks/PrivacyScreen";
import TermsAndConditionsScreen from "./src/screens/Store/quickLinks/TermsAndConditionsScreen";
import ContactUsScreen from "./src/screens/Store/quickLinks/ContactUsScreen";
import TestScreen from "./src/screens/Store/TestScreen";
import { GlobalStyles } from "./src/constants/styles";
import ResolveAuthScreen from "./src/screens/ResolveAuthScreen";
import googlesignin from "./src/screens/auth/googlesignin";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { initializeApp } from "./src/hooks/initializeApp";
import AppContainer from "./src/screens/AppContainer";
import NotFoundScreen from "./src/screens/NotFoundScreen";
import TutorialScreen from "./src/screens/Store/quickLinks/TutorialScreen";
import TutorialExplanationScreen from "./src/screens/Store/quickLinks/TutorialExplanationScreen";
import AdminOrdersListScreen from "./src/screens/Admin/AdminOrdersListScreen";
import AdminOrderDetailsScreen from "./src/screens/Admin/AdminOrderDetailsScreen";
import FinancialAnalysisScreen from "./src/screens/Admin/FinancialAnalysisScreen";
import MyAccountScreenTest from "./src/screens/Account/MyAccountScreenTest";
import PaymentFailedScreen from "./src/screens/Store/PaymentFailedScreen";

// console.log(`Platform.OS is ${Platform.OS}`);
if (Platform.OS !== "android" && Platform.OS !== "ios") {

} else {
  I18nManager.swapLeftAndRightInRTL(false);
  I18nManager.forceRTL(false);
  I18nManager.allowRTL(false);
}
const colorScheme = Appearance.getColorScheme();

const Stack = createStackNavigator();

function StoreFlow() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false, title: "Home" }} />
      <Stack.Screen 
      name="ItemDetails" 
      component={ItemDetailsScreen} 
      options={{ headerShown: false }}
      initialParams={{ Id: '' }} // Set initial params here
      />
      <Stack.Screen 
      name="ItemList" 
      component={ItemListScreen} 
      options={{ headerShown: false }}
      initialParams={{ Id: '' }} // Set initial params here
      />
      <Stack.Screen name="Cart" component={CartScreen} options={{ headerShown: false }} />
      <Stack.Screen name="CartInformation" component={CartInformationScreen} options={{ headerShown: false }} />
      <Stack.Screen name="CartShippingInformation" component={CartShippingInformationScreen} options={{ headerShown: false }} />
      <Stack.Screen name="CartPayment" component={CartPaymentScreen} options={{ headerShown: false }} />
      <Stack.Screen 
      name="PaymentSuccessful" 
      component={PaymentSuccessfulScreen} 
      options={{ headerShown: false }}
      initialParams={{ Id: '' }} // Set initial params here
      />
      <Stack.Screen 
      name="PaymentFailed"
      component={PaymentFailedScreen} 
      options={{ headerShown: false }}
      />
      <Stack.Screen name="ItemDetailsFromCart" component={ItemDetailsScreen} options={{ headerShown: false }} />
      <Stack.Screen name="AboutUs" component={AboutUsScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Ambassador" component={AmbassadorScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Help" component={HelpScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Privacy" component={PrivacyScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ContactUs" component={ContactUsScreen} options={{ headerShown: false }} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Tutorial" component={TutorialScreen} options={{ headerShown: false }} />
      <Stack.Screen 
      name="TutorialExplanation" 
      component={TutorialExplanationScreen} 
      options={{ headerShown: false }}
      initialParams={{ Type: '' }} // Set initial params here
      />
    </Stack.Navigator>
  );
}

function DiscoverFlow() {
  return (
    <Stack.Navigator>
      {/* <Stack.Screen 
      name="ImageMedia" 
      component={ImageMediaScreen} 
      options={{ headerShown: false }}
      initialParams={{ PostId: '' }} // Set initial params here
      /> */}
    </Stack.Navigator>
  );
}

function AccountFlow() {
  return (
    <Stack.Navigator>
      <Stack.Screen path="/MyAccount" name="MyAccount" component={MyAccountScreenTest} options={{ headerShown: false }} />
      {/* <Stack.Screen path="/MyAccount" name="MyAccount" component={MyAccountScreen} options={{ headerShown: false }} /> */}
      <Stack.Screen path="/CreateProfile" 
      name="CreateProfile" 
      component={CreateProfileScreen} 
      options={{ headerShown: false }}
      initialParams={{ IsPet: false }} // Set initial params here
       />
      <Stack.Screen name="EditProfile" 
      component={EditProfileScreen} 
      options={{ headerShown: false }}
      initialParams={{ UserId: '' }} // Set initial params here
      />
      <Stack.Screen 
      name="ManageMediaPost" 
      component={ManageMediaPostScreen} 
      options={{ headerShown: false }}
      initialParams={{ UserId: '', PostId: 0 }} // Set initial params here
      />
      <Stack.Screen 
      name="ManageTributePost" 
      component={ManageTributePostScreen} 
      options={{ headerShown: false }}
      initialParams={{ UserId: '', PostId: 0 }} // Set initial params here
      />
      <Stack.Screen 
      name="Profile" 
      component={ProfileScreen} 
      options={{ headerShown: false }}
      initialParams={{ UserId: '' }} // Set initial params here
      />
      <Stack.Screen 
      name="myjewelry" 
      component={ProfileScreen} 
      options={{ headerShown: false }}
      initialParams={{ UserId: '' }} // Set initial params here
      />
      <Stack.Screen 
      name="ImageMedia" 
      component={ImageMediaScreen} 
      options={{ headerShown: false }}
      initialParams={{ PostId: '', JewelryId: '' }} // Set initial params here
      />
      {/* <Stack.Screen name="ProfileFromAccount" 
      component={ProfileScreen} 
      options={{ headerShown: false }}
      initialParams={{ UserId: '' }} // Set initial params here
      /> */}
      <Stack.Screen 
      name="ImageMediaFromAccount" 
      component={ImageMediaScreen} 
      options={{ headerShown: false }}
      initialParams={{ PostId: '' }} // Set initial params here
       />
      <Stack.Screen 
      name="JewleryDetails" 
      component={JewleryDetailsScreen} 
      options={{ headerShown: false }}
      initialParams={{ Id: 0 }} // Set initial params here
      />
      <Stack.Screen 
      name="OrderDetails" 
      component={OrderDetailsScreen} 
      options={{ headerShown: false }}
      initialParams={{ Id: 0 }} // Set initial params here
      />
      <Stack.Screen 
      name="AssignJewleryToProfile" 
      component={AssignJewleryToProfileScreen} 
      options={{ headerShown: false }}
      initialParams={{ Id: 0 }} // Set initial params here
      />
      <Stack.Screen name="ForgotPasswordCodeConfirmationFromAccountScreen" component={ForgotPasswordCodeConfirmationScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ForgotPasswordConfirmCanChangePasswordFromAccountScreen" component={ForgotPasswordConfirmCanChangePasswordScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}
function AdminFlow() {
  return (
    <Stack.Navigator>
      <Stack.Screen path="/AdminOrdersList" name="AdminOrdersList" component={AdminOrdersListScreen} options={{ headerShown: false }} />
      <Stack.Screen path="/AdminOrderDetails" name="AdminOrderDetails" component={AdminOrderDetailsScreen} options={{ headerShown: false }} />
      <Stack.Screen path="/FinancialAnalysis" name="FinancialAnalysis" component={FinancialAnalysisScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}

function LoginFlow() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Signin" component={SigninScreen} options={{ headerShown: false }} />
      <Stack.Screen name="googlesignin" component={googlesignin} options={{ headerShown: false }} />
      <Stack.Screen name="SignUpWithEmail" component={SignUpWithEmailScreen} options={{ headerShown: false }} />
      <Stack.Screen name="SignUpConfirmEmailCode" component={SignUpConfirmEmailCodeScreen} options={{ headerShown: false }} />
      <Stack.Screen name="SignUpConfirmUserNameAndPasswordByEmail" component={SignUpConfirmUserNameAndPasswordByEmailScreen} options={{ headerShown: false }} />
      <Stack.Screen name="SignUpConfirmation" component={SignUpConfirmationScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ForgotPasswordConfirmCanChangePassword" component={ForgotPasswordConfirmCanChangePasswordScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ForgotPasswordCodeConfirmation" component={ForgotPasswordCodeConfirmationScreen} options={{ headerShown: false }} />
      <Stack.Screen name="LoaderAuth" component={LoaderAuthScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}
const linking = {
  prefixes: ['https://timelessmemories-jewelry.com', 'tmj://'],
  config: {
    screens: {
      ResolveAuth: {
        path: 'ResolveAuth',
      },
      LoginFlow: {
        path: 'LoginFlow',
        screens: {
          Signin: 'Signin',
          googlesignin: 'googlesignin',
          SignUpWithEmail: 'SignUpWithEmail',
          SignUpConfirmEmailCode: 'SignUpConfirmEmailCode',
          SignUpConfirmUserNameAndPasswordByEmail: 'SignUpConfirmUserNameAndPasswordByEmail',
          SignUpConfirmation: 'SignUpConfirmation',
          ForgotPassword: 'ForgotPassword',
          ForgotPasswordConfirmCanChangePassword: 'ForgotPasswordConfirmCanChangePassword',
          ForgotPasswordCodeConfirmation: 'ForgotPasswordCodeConfirmation',
          LoaderAuth: 'LoaderAuth',
        },
      },
      StoreFlow: {
        path: 'StoreFlow',
        screens: {
          Home: 'Home',
          ItemDetails: 'ItemDetails/:Id',
          ItemList: 'ItemList/:Id',
          Cart: 'Cart',
          CartInformation: 'CartInformation',
          CartShippingInformation: 'CartShippingInformation',
          CartPayment: 'CartPayment',
          PaymentSuccessful: 'PaymentSuccessful/:Id',
          ItemDetailsFromCart: 'ItemDetailsFromCart',
          AboutUs: 'AboutUs',
          Ambassador: 'Ambassador',
          Help: 'Help',
          Privacy: 'Privacy',
          ContactUs: 'ContactUs',
          TermsAndConditions: 'TermsAndConditions',
          Tutorial: 'Tutorial',
          TutorialExplanation: 'TutorialExplanation/:Type',
        },
      },
      DiscoverFlow: {
        path: 'DiscoverFlow',
        screens: {
          ImageMedia: 'ImageMedia/:PostId',
        },
      },
      AccountFlow: {
        path: 'AccountFlow',
        screens: {
          MyAccount: 'MyAccount',
          CreateProfile: 'CreateProfile/:IsPet',
          EditProfile: 'EditProfile/:UserId',
          ManageMediaPost: 'ManageMediaPost/:UserId/:PostId',
          ManageTributePost: 'ManageTributePost/:UserId/:PostId',
          Profile: 'Profile/:UserId',
          myjewelry: 'myjewelry/:UserId',
          ImageMedia: 'ImageMedia/:PostId/:JewelryId',
          ImageMediaFromAccount: 'ImageMediaFromAccount/:PostId',
          JewleryDetails: 'JewleryDetails/:Id',
          OrderDetails: 'OrderDetails/:Id',
          AssignJewleryToProfile: 'AssignJewleryToProfile/:Id',
          ForgotPasswordCodeConfirmationFromAccountScreen: 'ForgotPasswordCodeConfirmationFromAccountScreen',
          ForgotPasswordConfirmCanChangePasswordFromAccountScreen: 'ForgotPasswordConfirmCanChangePasswordFromAccountScreen',
        },
      },
      AdminFlow: {
        path: 'AdminFlow',
        screens: {
          AdminOrdersList: 'AdminOrdersList',
          AdminOrderDetails: 'AdminOrderDetails/:OrderId',
          FinancialAnalysis: 'FinancialAnalysis',
        },
      },
      // Fallback for screens not yet covered
      '*': {
        path: '*',
      },
    },
  },
};
function AppNavigator() {
  return (
    <NavigationContainer 
    ref={setNavigator}
    linking={linking}
    fallback={<Text>Loading...</Text>} // Optional: Add a fallback UI
    >
      <Stack.Navigator 
      screenOptions={{ headerShown: false }}
      initialRouteName="ResolveAuth"
      >
        <Stack.Screen name="ResolveAuth" component={ResolveAuthScreen} />
        <Stack.Screen name="LoginFlow" component={LoginFlow} />
        <Stack.Screen name="StoreFlow" component={StoreFlow} />
        <Stack.Screen name="DiscoverFlow" component={DiscoverFlow} />
        <Stack.Screen name="AccountFlow" component={AccountFlow} />
        <Stack.Screen name="AdminFlow" component={AdminFlow} />
        {/* <Stack.Screen name="TestScreen" component={TestScreen} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );
}


export default () => {
  useEffect(() => {
    useFonts();
  }, []);
  return (
        <UserSettingsContext>
      <ShopContext>
        <AuthProvider>
          <AppContainer>
          {Platform.OS === "web" ?
        <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/Home" replace />} />
        <Route path="/MyAccount" element={<MyAccountScreenTest />} />
        {/* <Route path="/MyAccount" element={<MyAccountScreen />} /> */}
        <Route path="/CreateProfile/:IsPet" element={<CreateProfileScreen />} />
        <Route path="/Profile/:UserId" element={<ProfileScreen />} />
        <Route path="/myjewelry/:UserId" element={<ProfileScreen />} />
        <Route path="/EditProfile/:UserId" element={<EditProfileScreen />} />
        {/* <Route path="/ManageMediaPost/:PostId" element={<ManageMediaPostScreen />} />
        <Route path="/ManageTributePost/:PostId" element={<ManageTributePostScreen />} /> */}
        <Route path="/ManageMediaPost/:UserId/:PostId" element={<ManageMediaPostScreen />} />
        <Route path="/ManageTributePost/:UserId/:PostId" element={<ManageTributePostScreen />} />
        <Route path="/ImageMedia/:PostId" element={<ImageMediaScreen />} />
        <Route path="/ImageMedia/:JewelryId/:PostId" element={<ImageMediaScreen />} />
        <Route path="/Signin" element={<SigninScreen />} />
        <Route path="/googlesignin" element={<googlesignin />} />
        <Route path="/SignUpWithEmail" element={<SignUpWithEmailScreen />} />
        <Route path="/SignUpConfirmEmailCode" element={<SignUpConfirmEmailCodeScreen />} />
        <Route path="/SignUpConfirmUserNameAndPasswordByEmail" element={<SignUpConfirmUserNameAndPasswordByEmailScreen />} />
        <Route path="/SignUpConfirmation" element={<SignUpConfirmationScreen />} />
        <Route path="/ForgotPassword" element={<ForgotPasswordScreen />} />
        <Route path="/ForgotPasswordConfirmCanChangePassword" element={<ForgotPasswordConfirmCanChangePasswordScreen />} />
        <Route path="/ForgotPasswordCodeConfirmation" element={<ForgotPasswordCodeConfirmationScreen />} />
        <Route path="/LoaderAuth" element={<LoaderAuthScreen />} />
        <Route path="/Home" element={<HomeScreen />} />
        <Route path="/ItemDetails/:Id" element={<ItemDetailsScreen />} />
        <Route path="/ItemList/:Id" element={<ItemListScreen />} />
        <Route path="/Cart" element={<CartScreen />} />
        <Route path="/CartInformation" element={<CartInformationScreen />} />
        <Route path="/CartShippingInformation" element={<CartShippingInformationScreen />} />
        <Route path="/CartPayment" element={<CartPaymentScreen />} />
        <Route path="/PaymentSuccessful/:Id" element={<PaymentSuccessfulScreen />} />
        <Route path="/PaymentFailed" element={<PaymentFailedScreen />} />
        <Route path="/JewleryDetails/:Id" element={<JewleryDetailsScreen />} />
        <Route path="/OrderDetails/:Id" element={<OrderDetailsScreen />} />
        <Route path="/AssignJewleryToProfile/:Id" element={<AssignJewleryToProfileScreen />} />
        <Route path="/AboutUs" element={<AboutUsScreen />} />
        <Route path="/Ambassador" element={<AmbassadorScreen />} />
        <Route path="/Help" element={<HelpScreen />} />
        <Route path="/Privacy" element={<PrivacyScreen />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditionsScreen />} />
        <Route path="/Tutorial" element={<TutorialScreen />} />
        <Route path="/TutorialExplanation/:Type" element={<TutorialExplanationScreen />} />
        <Route path="/ContactUs" element={<ContactUsScreen />} />



        <Route path="/AdminOrdersList" element={<AdminOrdersListScreen />} />
        <Route path="/FinancialAnalysis" element={<FinancialAnalysisScreen />} />
        <Route path="/AdminOrderDetails/:OrderId" element={<AdminOrderDetailsScreen />} />


        
        <Route path="/ResolveAuth" element={<ResolveAuthScreen />} />
        <Route path="*" element={<NotFoundScreen />} />  {/* Catch-all route */}
      </Routes>
    </Router>
      : 
      <AppNavigator />
      }
      </AppContainer>
        </AuthProvider>
      </ShopContext>
    </UserSettingsContext>
  );
};
