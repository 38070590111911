import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, Text, TouchableOpacity, Image, Platform, Linking, View, Dimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import QRCodeGenerator from "../QRCodeGenerator";
import { getAsset } from "../../functions/getAsset";
import Animated, { useSharedValue, useAnimatedStyle, withTiming, withRepeat, withSequence, cancelAnimation } from 'react-native-reanimated'; // For mobile animation
import { transitionTime } from "../../functions/ScreenDimensionsLogic";

let FastImage = null;
if (Platform.OS === "web") {
  FastImage = Image;
} else {
  FastImage = require("react-native-fast-image");
}

const HeaderImageContainer = ({ 
  userSettingsState, 
  showScannable = true, 
  text, 
  styleContainer, 
  textContainer,
  scrollY // Accept scrollY from parent ScrollView
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Save the Y position of the component
  const windowHeight = Dimensions.get("window").height;
  const [isHovered, setIsHovered] = useState(false);
  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // Pulse effect for QR code
  const qrScale = useSharedValue(1);

  // For web (useState-based animation control)
  const [isVisible, setIsVisible] = useState(false);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Pulse animation for QR code (mobile)
  const qrAnimatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: qrScale.value }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    if(!isHovered){
      return;
    }
    contentOpacity.value = 1;
    contentTranslateY.value = 0;

    // Start the pulse effect for the QR code
    qrScale.value = withRepeat(
      withSequence(withTiming(1.1, { duration: 500 }), withTiming(1, { duration: 500 })),
      -1, // Infinite repetitions
      true // Alternate the animation direction
    );
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== "web") {
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when it's within the viewport
      }
    }
  }, [scrollY, layoutY]);

  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); 
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // First view is already in the viewport on initial load
    }
  };
  useEffect(() => {
    if (Platform.OS === "web") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect();
            }
          });
        },
        { threshold: 0.1 }
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);
  useEffect(() => {
    if (Platform.OS === "web") {
      if (isHovered) {
        // Trigger pulse animation on hover
        qrScale.value = withRepeat(
          withSequence(withTiming(1.1, { duration: 500 }), withTiming(1, { duration: 500 })),
          -1, // Infinite repetitions
          true // Alternate the animation direction
        );
      } else {
        // Stop pulse animation and reset scale
        cancelAnimation(qrScale);
        qrScale.value = withTiming(1, { duration: 200 });
      }
    }
  }, [isHovered]);
  // Intersection Observer for Web

  return (
    <Animated.View
      ref={sectionRef}
      style={[
        styles.container,
        styleContainer,
        Platform.OS === "web" && isVisible
          ? { opacity: 1, transform: "translateY(0px)", transition: "opacity 1s ease-out, transform 1s ease-out" }
          : Platform.OS === "web"
          ? { opacity: 0, transform: "translateY(50px)" } 
          : animatedContentStyle,
      ]}
      onLayout={handleLayout}
    >
      {text ? (
        <Text style={[styles.title, textContainer, { textAlign: "center" }]}>
          {text}
        </Text>
      ) : null}

      <FastImage
        source={{ uri: getAsset(require("../../../assets/images/store/images/main_image.jpg")) }}
        resizeMode={responsiveStyles.isMobile ? null : "contain"}
        style={styles.image}
      />

      {showScannable ? (
        <View style={styles.contentContainer}>
          <Animated.View 
          style={[styles.qrContainer, qrAnimatedStyle ]}
          >
            <TouchableOpacity
              onPress={() =>
                Linking.openURL("https://timelessmemories-jewelry.com/Profile/Mother")
              }
              style={{ flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', justifyContent: 'center', alignItems:'center' }}
              onMouseEnter={() => {
                console.log("onMouseEnter");
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                console.log("onMouseLeave");
                setIsHovered(false);
              }}
            >
              <QRCodeGenerator
                userSettingsState={userSettingsState}
                isFromHomeScreen={true}
                barcodeSize={
                  responsiveStyles.isDesktop || responsiveStyles.isLaptop
                    ? responsiveStyles.screenWidth * 0.06
                    : responsiveStyles.isTablet
                    ? responsiveStyles.screenWidth * 0.08
                    : responsiveStyles.screenWidth * 0.25
                }
                containerStyle={{ padding: 10 }}
              />
            <Text style={styles.scanText}>
              {i18n.t("store.HomeScreen_HeaderImageContainer_ScanText")}
            </Text>
            </TouchableOpacity>
          </Animated.View>
          <Text style={styles.title}>
            {i18n.t("store.HomeScreen_HeaderImageContainer_Title")}
          </Text>
          <Text style={styles.description}>
            {i18n.t("store.HomeScreen_HeaderImageContainer_Description")}
          </Text>
        </View>
      ) : null}
    </Animated.View>
  );
};

const styling = (responsiveStyles) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingBottom: responsiveStyles.isMobile || responsiveStyles.isTablet ? 40 : null,
    },
    image: {
      width: responsiveStyles.screenWidth,
      aspectRatio: responsiveStyles.isMobile ? (5 / 6) : (16 / 9),
      // aspectRatio: 80 / 29,
      borderBottomEndRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
      borderBottomStartRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
    },
    contentContainer: {
      position: responsiveStyles.isDesktop || responsiveStyles.isLaptop || responsiveStyles.isTablet ? "absolute" : null,
      top: responsiveStyles.isDesktop
        // ? responsiveStyles.screenHeight * 0.2
        ? responsiveStyles.screenHeight * 0.4
        : responsiveStyles.isLaptop
        // ? responsiveStyles.screenHeight * 0.1
        ? responsiveStyles.screenHeight * 0.35
        : responsiveStyles.isTablet
        ? responsiveStyles.screenHeight * 0.2
        : responsiveStyles.screenHeight * 0.05,
      left: responsiveStyles.isRTL ? null : responsiveStyles.isDesktop || responsiveStyles.isLaptop || responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.05 : null,
      right: !responsiveStyles.isRTL ? null : responsiveStyles.isDesktop || responsiveStyles.isLaptop || responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.05 : null,
      alignItems: "center",
      justifyContent: "center",
    },
    qrContainer: {
      flexDirection: responsiveStyles.isMobile || responsiveStyles.isTablet ? "column" : "row",
      alignItems: "center",
      marginBottom: 20,
    },
    scanText: {
      marginLeft: 10,
      fontSize: responsiveStyles.fontSize_H4,
      color: responsiveStyles.isMobile 
      // || responsiveStyles.isTablet
       ? GlobalStyles[responsiveStyles.ThemeValue]?.textColor : "white",
      fontFamily: GlobalStyles.fonts.application_font,
    },
    title: {
      fontSize: responsiveStyles.fontSize_H3,
      fontWeight: "bold",
      marginBottom: 10,
      color: responsiveStyles.isMobile 
      // || responsiveStyles.isTablet
       ? GlobalStyles[responsiveStyles.ThemeValue]?.textColor : "white",
      fontFamily: GlobalStyles.fonts.application_font,
    },
    description: {
      fontSize: responsiveStyles.fontSize_H4,
      textAlign: "center",
      color: responsiveStyles.isMobile 
      // || responsiveStyles.isTablet
       ? GlobalStyles[responsiveStyles.ThemeValue]?.textColor : "white",
      fontFamily: GlobalStyles.fonts.application_font,
    },
  });

export default HeaderImageContainer;
