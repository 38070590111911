import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  Alert,
  Text,
  Dimensions,
  Button,
  Modal,
  ScrollView,
  StatusBar,
  BackHandler,
  useWindowDimensions,
} from 'react-native';
import AuthForm from '../../components/forms/AuthForm';
import { Context } from '../../context/AuthContext';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import { navigate } from '../../navigationRef';
import AuthFormHeader from '../../components/AuthFormHeader';
import ButtonTypeText from '../../components/inputs/ButtonTypeText';
import { Feather } from '@expo/vector-icons'; 
import * as Localization from 'expo-localization';
import i18n from '../../hooks/useI18n';
import LanguageModal from '../../components/modals/LanguageModal';

import {SigninAlert} from '../../components/alert/SignInAlert';
import { languages } from '../../modules/StackNavigator/languages';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import Screen from '../../components/Screen';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigation } from '@react-navigation/native';
import { useNavigate } from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { navigateToHomeScreen } from '../../functions/navigateToHomeScreen';
import { token_Storage } from '../../modules/StorageObjects';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const SigninScreen = ( {  } ) => {
  
  
  
  const { state, signin, addErrorMessage, signInGoogle  } = useContext(Context);
  const { state: userSettingsState, setDefaultSettings, setUserSettings, setUserSettingsLanguage } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [email, setEmail] = useState('');
  const [languageModalVisible, setLanguageModalVisible] = useState(false);


  // const [locale, setLocale] = useState(Localization.locale.slice(0, 2));
  const [locale, setLocale] = useState(responsiveStyles.Language.slice(0, 2));
  i18n.locale = locale;

  const [refreshPage, setRefreshPage] = useState(false);

  const [isFirstTime,setIsFirstTime] = useState(true);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardDidShowTxt, setKeyboardDidShowTxt] = useState('');
  const [showThirdView, setShowThirdView] = useState(true);

  const backAction = () => {
    BackHandler.exitApp();
    return true;
  };
  const TextInputOnFocus = () => {
    if(!responsiveStyles.isWeb){
      console.log("handleTextInputFocus");
      setShowThirdView(false);
    }
  };
  const TextInputOnBlue = () => {
    if(!responsiveStyles.isWeb){
      console.log("handleTextInputFocus");
      setShowThirdView(true);
    }
  };
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Sign In";
    }
    const checkIfHasToken = async () => {
      const token = await AsyncStorage.getItem(token_Storage);
      if(token){
        await navigateToHomeScreen(navigation, true);
      }
    }
    checkIfHasToken();
  if(isFirstTime)
  {
    console.log("useeffect isFirstTime");
    setDefaultSettings();
    setIsFirstTime(false);
  }
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        if(!responsiveStyles.isWeb){
          console.log("keyboardDidShow");
          setShowThirdView(false);
          setKeyboardVisible(true); // or some other action
          setKeyboardDidShowTxt("keyboardDidShow");
        }
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        if(!responsiveStyles.isWeb){
          console.log("keyboardDidHide");
          setShowThirdView(true);
          setKeyboardVisible(false); // or some other action
          setKeyboardDidShowTxt("keyboardDidHide");
        }
      }
    );
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
      backHandler.remove();
    };
  }, [locale,state.signinAlertMessage, refreshPage]);

//   const languages = [{
//     name: 'English (US)',
//     language: 'en',
//     userSettingsLanguage: 'en-US',
// }, {
//     name: 'עברית',
//     language: 'he',
//     userSettingsLanguage: 'he-IL',
// }];
  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    // showHeader={false}
    >
    <View style={[[styles.subContainer
    // , showThirdView === true ? null : { paddingTop: screenHeight * 0.1 }
    ]]}>
      <StatusBar
        animated={true}
        backgroundColor={responsiveStyles.ThemeValue === 'dark' ? 'black' : 'white'}
        barStyle={responsiveStyles.ThemeValue === 'dark' ? 'light-content' : 'dark-content'}
      />
      <SigninAlert
      userSettingsState={userSettingsState}
      userName={email}
      navigation={navigation}
      state={state}
      addErrorMessage={addErrorMessage}
      />
    <KeyboardAvoidingView
      // behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
      >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={[styles.inner, languageModalVisible ? { opacity: 0.4 } : null]}>
          
        { !!responsiveStyles.isMobile && showThirdView === false ? null : (
          <View style={{  alignItems:'center',justifyContent:'center', paddingTop: !responsiveStyles.isMobile ? 50 : 50 }}>
          
          <ButtonTypeText
        onPress={() => setLanguageModalVisible(true)}
        // style={{marginTop: 20}}
        userSettingsState={userSettingsState}
        >
          {/* <View > */}
       <Text style={{color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor, fontSize: responsiveStyles.fontSize_H4, fontFamily: GlobalStyles.fonts.application_font,}}>
        {languages.find((language) => language.language === locale)?.name !== undefined ? languages.find((language) => language.language === locale)?.name : 'English (US)'}
        </Text>
        {/* </View> */}
          
         <LanguageModal
        languages={languages}
        setModalVisible={setLanguageModalVisible} 
        modalVisible={languageModalVisible} 
        userSettingsState={userSettingsState}
        // selectedLanguage={locale} 
        selectedLanguage={languages.find((language) => language.language === locale)?.userSettingsLanguage}
        onPress={(language) => {
          console.log(`language.userSettingsLanguage ${language.userSettingsLanguage}`);
          setLocale(language.language);
          setUserSettingsLanguage(language.userSettingsLanguage);
          setRefreshPage(!refreshPage);
        }}
         />
         </ButtonTypeText>
        
        </View>
        )}
        { !!responsiveStyles.isMobile && showThirdView === false ? null :(
         <View 
         style={[
          // {flex:1},
          !!responsiveStyles.isMobile && showThirdView === true ? null : { paddingVertical: responsiveStyles.screenHeight * 0.05 },
          ]}
          >
          <AuthFormHeader userSettingsState={userSettingsState} />
          {/* {
              !responsiveStyles.isWeb ?
              <View style={styles.googleButton}>
            <Button title='googlesignin' onPress={() => {
              navigateToScreen(navigation, 'LoginFlow', `googlesignin`, null, null);
              // navigation.navigate('LoginFlow', { screen: 'googlesignin' });
              // navigation.navigate('googlesignin');
            }}
            
            />
            </View>
             : null } */}
          </View>
        )}
            
          <View  
          style={{flex:1}}
          >

      <AuthForm
      userSettingsState={userSettingsState}
        onSubmit={signin}
        signInGoogle={signInGoogle}
        ShowPassword={true}
        signinAlertMessage={state.signinAlertMessage}
        email={email}
        setEmail={setEmail}
        Keyboard={Keyboard}
        ShowForgotPassword={true}
        isKeyboardVisible={isKeyboardVisible}
        keyboardDidShowTxt={keyboardDidShowTxt}
        refreshPage={refreshPage}
        addErrorMessage={addErrorMessage}
        onFocus={TextInputOnFocus}
        onBlur={TextInputOnBlue}
        navigation={navigation}
        showThirdView={showThirdView}
      />

        </View>
        {/* {responsiveStyles.isMobile && showThirdView === false ? null : ( */}
        <View style={{ marginBottom: 20 }}>
        <CompanyFooter userSettingsState={userSettingsState} />
        </View>
        {/* )} */}
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </View>
    </Screen>
  );
};

SigninScreen.navigationOptions = {
  header: () => false,
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.5 : null,
    // alignSelf: !responsiveStyles.isMobile ? 'center' : null,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,

  },
  inner: {
    padding: 15,
    flex: 1,
    // justifyContent: 'space-around',
  },
  // buttonStyle:{
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor, 
  //   borderWidth:1, 
  //   // borderColor:GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor, 
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
  //   marginBottom: !responsiveStyles.isMobile ? 20 : 10,
    
  //   },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
     googleButton: {
      width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : null,
      alignSelf: !responsiveStyles.isMobile ? 'center' : null,
      paddingBottom: 20,
     },
});

export default SigninScreen;
