import i18n from "../hooks/useI18n";

export const getUserSettingsStateProperties = (userSettingsState) => {
    const ThemeValue = userSettingsState?.userSettings?.ThemeValue === "dark" ? "dark" : "light";
    const isRTL = userSettingsState?.userSettings?.TextAlign === 'right';
    let Language = userSettingsState?.userSettings?.Language;
    if(Language === "en" || Language === "en-US"){
        Language = "en-US";
    }
    else if(Language === "he" || Language === "he-IL"){
        Language = "he-IL";
    }
    else{
        Language = "en-US";
    }
    // const Language = "en-US";
    if(Language?.length >= 2){
        
        i18n.locale = Language.slice(0, 2);;
    }
    return { ThemeValue, isRTL, Language };
};