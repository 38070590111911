import { Platform } from "react-native";



export const getNavigationParams = (navigation) => {
    let routeParams = {
        
    };
    try{
        const routes = navigation.getState ? navigation.getState()?.routes : null;
        if(routes){
        routeParams = routes[routes.length - 1].params;
    }
    else{
        routeParams = navigation.state?.params;
    }
    if(routeParams === null || routeParams === undefined){
      return { };
    }
        return routeParams;
    }
    catch(error)
    {
        console.log(`getNavigationParams failed, error: ${error}`);
      return routeParams;
    }
};

const getPathName = (path) => {
  const trimmedPath = path.startsWith('/') ? path.substring(1) : path;
  const parts = trimmedPath.split('/');
  return  parts[0];
};

export const getNavigationRouteName = (navigation, location) => {
    let routeName = "";
    try{
      // console.log(`func getNavigationRouteName, navigation: ${JSON.stringify(navigation)}, location: ${JSON.stringify(location)}`);
      if(Platform.OS === "web" && location !== null && location !== undefined){
        routeName = getPathName(location.pathname);
        // console.log(`routeName is ${routeName}`);
        return routeName;
      }
      console.log(`location is ${JSON.stringify(location)}`);
        const routes = navigation.getState ? navigation.getState()?.routes : null;
        console.log(`navigation.state`);
        console.log(navigation.getState);
        if(routes){
          routeName = routes[routes.length - 1].name;
        }
        else{
          routeName = navigation.state?.routeName;
        }
        console.log(`getNavigationRouteName routeName: ${routeName}`);
        return routeName;
    }
    catch(error)
    {
        console.log(`getNavigationRouteName failed, error: ${error}`);
      return routeName;
    }
};
