import axios from 'axios';

// Function to convert the image to base64
export const convertImageToBase64 = async (uri) => {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      const base64Image = await blobToBase64(blob);
      return base64Image;
    } catch (error) {
      // console.error('Error converting image to base64:', error);
      return null;
    }
  };
  
  // Function to convert a Blob to base64
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };