import React, { useCallback, useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, TouchableOpacity, Dimensions, LogBox, ScrollView, Platform, FlatList, Alert } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import ImageProfile from '../../components/images/ImageProfile';
import NameComponent from '../../components/profileScreen/NameComponent';
import BirthDateComponent from '../../components/profileScreen/BirthDateComponent';
import DescriptionComponent from '../../components/profileScreen/DescriptionComponent';
import BottomCardComponent from '../../components/profileScreen/BottomCardComponent';
import BackgroundImageComponent from '../../components/profileScreen/BackgroundImageComponent';
import UserImageComponent from '../../components/profileScreen/UserImageComponent';
import ManageMediaComponent from '../../components/displayComponent/ManageMediaComponent';
import ManageTributesComponent from '../../components/displayComponent/ManageTributesComponent';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { AddRequestUserAdmin, CancelRequestUserAdmin, GetUserProfileById, GetUserProfileByIdByJewelryId } from '../../api/apiCalls/ProfileCalls';
import { getNavigationParams, getNavigationRouteName } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { isValidGuid } from '../../functions/utils';
import NotFoundComponent from '../../components/NotFoundComponent';
import JewelryNotAssignedComponent from '../../components/JewelryNotAssignedComponent';
import { getItem, saveAsync } from '../../modules/Storage';
import { ProfileScreen_displayType, ProfileScreen_mediaDisplayTypeTribute } from '../../modules/StorageObjects';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import ButtonsComponent from '../../components/profileScreen/ButtonsComponent';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { ActivityIndicator } from 'react-native';
import PetContainersComponent from '../../components/profileScreen/PetContainersComponent';
import ManageBioComponent from '../../components/displayComponent/ManageBioComponent';
const ProfileScreen = ({  }) => {
  
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const UserIdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;

  const getScreenName = () => {
    try{
      let ScreenName;
      if(responsiveStyles.isWeb){
        ScreenName = location?.pathname?.split('/')[1];
      }
      else{
        ScreenName = getNavigationRouteName(navigation, location);
      }
      ScreenName = ScreenName.toLowerCase();
      console.log(`ScreenName is ${ScreenName}`);
      return ScreenName;
    }
    catch(error){
      return "Profile";
    }
  }
  const IsMyJewelryScreen = getScreenName() === "myjewelry";
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const Id = navigationParams?.UserId || UserIdFromURL;
  const FromScreen = navigationParams?.FromScreen;

  const [UserId, setUserId] = useState(!IsMyJewelryScreen ? Id : null);
  const [isJewelryAssigned, setIsJewelryAssigned] = useState(false);

  const IsDemo = UserId === "Mother" || UserId === "Partner" || UserId === "Pet" ? true : false;
  const IsIdValid = 
  IsMyJewelryScreen ? true :
  isValidGuid(UserId) ? true : IsDemo;

  console.log(`IsIdValid is ${IsIdValid}, UserId: ${UserId}`);
    const styles = styling(responsiveStyles);
    const [displayType, setDisplayType] = useState('Media');
    const [mediaDisplayTypeTribute, setMediaDisplayTypeTribute] = useState(false);


    const setDisplayType_WithLocalStorage = async (newDisplayType) => {
      await saveAsync(ProfileScreen_displayType, newDisplayType);
      setDisplayType(newDisplayType);
    }
    const setMediaDisplayTypeTribute_WithLocalStorage = async (newDisplayType) => {
      await saveAsync(ProfileScreen_mediaDisplayTypeTribute, newDisplayType);
      setMediaDisplayTypeTribute(newDisplayType);
    }
    
    const [user, setUser] = useState({
      UserName: "",
      BirthDate: "",
      DeathDate: "",
      Description: "",
      BackgroundImage: "",
      UserImage: "",
      IsUserHaveRole: false, // show button "request editing permissions"
      IsSendAdminRequest: false, // show button "request editing permissions" or "Cancel request"
      IsPet: false,
    });
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const AddRequestUserAdminHandler = async () => {
      const response = await AddRequestUserAdmin(UserId);
      if(response.isCompleted === true){
        setUser(prevUser => ({
          ...prevUser,
          IsSendAdminRequest: true,
        }));
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
      return response;
    };
    const CancelRequestUserAdminHandler = async () => {
      const response = await CancelRequestUserAdmin(UserId);
      if(response.isCompleted === true){
        setUser(prevUser => ({
          ...prevUser,
          IsSendAdminRequest: false,
        }));
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
      return response;
    };
    const [isAdmin, setIsAdmin] = useState(true);
    const [tributes, setTibutes] = useState([]);
    const [media, setMedia] = useState([]);
    const [reloadpage, setReloadPage] = useState(false);
    // console.log(`tributes is ${JSON.stringify(tributes)}`);
    // console.log(`media is ${JSON.stringify(media)}`);

    const cleanup = () => {
      console.log(`cleanup`);
      
      setTibutes([]);
      setMedia([]);
      // setReloadPage(!reloadpage);
      console.log(`finish cleanup`);
    };


    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      cleanup();
      return true;
    };
    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    


    const setDisplayType_ForAll = async () => {
      const newDisplayType = await getItem(ProfileScreen_displayType);
      if(newDisplayType){
          setDisplayType(newDisplayType);
      }
      const newDisplayType_mediaDisplayTypeTribute = await getItem(ProfileScreen_mediaDisplayTypeTribute);
      if(newDisplayType_mediaDisplayTypeTribute){
        setMediaDisplayTypeTribute(newDisplayType_mediaDisplayTypeTribute);
      }

    }
    const fetchData = async () => {
      try {
        setLoading(true);
        let req;
        if(IsMyJewelryScreen){
          req = await GetUserProfileByIdByJewelryId(Id);
        }
        else{
          req = await GetUserProfileById(UserId);
        }
        if (req.isCompleted) {
          const vm = JSON.parse(req.answer);
          if(vm === "JewelryNotAssigned"){
            setIsJewelryAssigned(true);
          }
          else{
            setUser(vm.User);
            console.log(`user is `);
            console.log(JSON.stringify(vm.User));
            
            
            setUserId(vm.User.Id);
            setTibutes(vm.Tributes);
            setMedia(vm.Media);
            setIsAdmin(vm.IsAdmin);
            const newDisplayType = await getItem(ProfileScreen_displayType);
            if(!vm.User.IsPet && newDisplayType === "Bio"){
              setDisplayType('Media');
            }
            if(vm.User.IsPet && newDisplayType === null){
              setDisplayType('Bio');
            }
          }
        }
        else{
          setLoading(true);
          setErrorOnRequest(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(true);
        setErrorOnRequest(true);
      }
      finally{
        setLoading(false);
      }
    };
    const InitFunction = () => {
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      if(isFirstTime)
        {
          setDisplayType_ForAll();
          fetchData();
          setIsFirstTime(false);
        }
        else{
          // setLoading(true);
          setErrorOnRequest(false);
          if(errorOnRequest === true)
          {
            fetchData();
          }
        }

      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => {
      backHandler.remove();
    }
    }
    if(responsiveStyles.isWeb){
      useEffect(() =>{
        if(responsiveStyles.isWeb){
          document.title = "Profile";
        }
        InitFunction();
    },[reloadpage])
    }
    else{
      useFocusEffect(
        useCallback(() =>{
          InitFunction();
          fetchData();
      },[reloadpage])
    );
    }

    const [childFunction, setChildFunction] = useState(null);
    const onScrollEndChildFunction = (fn) => {
      setChildFunction(() => fn); // Store the child's function in state
    };
    const handleScroll = (event) => {
      const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
        if (layoutMeasurement.height + contentOffset.y >= contentSize.height - responsiveStyles.screenHeight) {
          if (childFunction) {
            childFunction(); // Call the child's function from the parent
          } else {
            // Alert.alert('No function provided by the child yet');
          }
      } else {

      }
    };
      
    console.log(`displayType is ${displayType}`);
  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showFooter={false}
  showBackButton={FromScreen === "FromAccount" || FromScreen === "Home" ? true : false}
  cleanup={cleanup}
  >
    {
    !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
    : isJewelryAssigned ? <JewelryNotAssignedComponent userSettingsState={userSettingsState} navigation={navigation} />
    : errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
    <ScrollView 
    style={[styles.container]}
    contentContainerStyle={styles.scrollViewContent}
    onScroll={handleScroll}
    scrollEventThrottle={16}
    >
      <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
    <BackgroundImageComponent
    userSettingsState={userSettingsState} 
    navigation={navigation}
    user={user}
    AddRequestUserAdmin={AddRequestUserAdminHandler}
    CancelRequestUserAdmin={CancelRequestUserAdminHandler}
    IsMyJewelryScreen={IsMyJewelryScreen}
    isAdmin={isAdmin}
    UserId={UserId}
     />
    <UserImageComponent userSettingsState={userSettingsState} user={user} />
    <NameComponent userSettingsState={userSettingsState} user={user} />
    <BirthDateComponent 
    userSettingsState={userSettingsState}
    user={user}
    AddRequestUserAdmin={AddRequestUserAdminHandler}
    CancelRequestUserAdmin={CancelRequestUserAdminHandler}
    />
    <DescriptionComponent userSettingsState={userSettingsState} user={user} />
    <PetContainersComponent userSettingsState={userSettingsState} user={user} />
    <ButtonsComponent
    userSettingsState={userSettingsState} 
    navigation={navigation}
    user={user} 
    isAdmin={isAdmin}
    IsMyJewelryScreen={IsMyJewelryScreen}
    JewelryId={Id}
    UserId={UserId}
    AddRequestUserAdmin={AddRequestUserAdminHandler}
    CancelRequestUserAdmin={CancelRequestUserAdminHandler}
     />
    <BottomCardComponent
    userSettingsState={userSettingsState} 
    user={user}
    displayType={displayType} 
    setDisplayType={setDisplayType_WithLocalStorage}
    mediaDisplayTypeTribute={mediaDisplayTypeTribute}
    setMediaDisplayTypeTribute={setMediaDisplayTypeTribute_WithLocalStorage}
    />
    {
      user?.IsPet && displayType === 'Bio'?
      <ManageBioComponent 
      userSettingsState={userSettingsState} 
      user={user} 
      UserId={UserId}
      navigation={navigation}
      cleanup={cleanup}
      JewelryId={IsMyJewelryScreen ? Id : null}
      isAdmin={isAdmin}
      />
      : displayType === 'Tributes' ? 
      <ManageTributesComponent 
      data={tributes} 
      userSettingsState={userSettingsState}
      UserId={UserId}
      navigation={navigation}
      cleanup={cleanup}
      IsDemo={IsDemo}
      isAdmin={isAdmin}
      onScrollEndParentFunction={onScrollEndChildFunction}
       />
      : mediaDisplayTypeTribute ? 
      <ManageTributesComponent 
      data={media}
      username={user.UserName} 
      userSettingsState={userSettingsState}
      isMediaPost={true}
      UserId={UserId}
      navigation={navigation}
      cleanup={cleanup}
      IsDemo={IsDemo}
      isAdmin={isAdmin}
      onScrollEndParentFunction={onScrollEndChildFunction}
      />
      : <ManageMediaComponent 
      data={media} 
      username={user.UserName} 
      userSettingsState={userSettingsState} 
      UserId={UserId}
      navigation={navigation}
      cleanup={cleanup}
      JewelryId={IsMyJewelryScreen ? Id : null}
      isAdmin={isAdmin}
      />
    }    
    </ScrollView>
    )}
    </Screen>

}


  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // padding: 20,
    // height: fixedScreenHeight,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
});

export default ProfileScreen;
