import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image, FlatList, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { imageWidth } from "../../constants/imageWidth";
import MediaEmptyListComponent from "./MediaEmptyListComponent";
import ImageProfile from "../images/ImageProfile";
import ImageMedia from "../images/ImageMedia";
import { navigateToScreen } from "../../navigationResponsive";
import { getPaddingScreen,  } from "../../functions/ScreenDimensionsLogic";
import { getImageWidth, getwidthOfImage } from "../../functions/getImageWidth";
import { rearrangeDataForRTL } from "../../functions/rearrangeDataForRTL";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { ScrollView } from "react-native";
import { Feather } from '@expo/vector-icons'; 
import PhoneNumberComponent from "./PhoneNumberComponent";



  const ManageBioComponent = ({ 
    userSettingsState, 
    isEditing = false, 
    user,
    data = [], 
    UserName, 
    UserId, 
    navigation,
    cleanup,
    JewelryId = null,
    isAdmin = false,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
  const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
  
  const widthOfInput = !responsiveStyles.isMobile ? responsiveStyles.screenWidth - (paddingScreen * 2) : responsiveStyles.screenWidth * 0.90;
  
  const styles = styling(responsiveStyles, paddingScreen);
  const dummyDataToAdd = { Id: - 1 , isHidden: true };
  
  const rerenderedData = data.length % 3 === 1 ? [...data, dummyDataToAdd, dummyDataToAdd] : data.length % 3 === 2 ? [...data, dummyDataToAdd] : data;

  return (
    <View style={styles.container}>
      { isEditing && data.length > 0 ? 
      <View style={styles.buttonContainer}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.button}
          styleButton={styles.inviteStyleButton}
           onPress={() => {
            navigateToScreen(navigation, 'AccountFlow', `ManageMediaPost`, { UserId: UserId, }, 
              `${UserId}/Create`
              // 'Create'
            );
            // navigate('AccountFlow', { screen: 'ManageMediaPost', params: { UserId: UserId } });
            // navigate("ManageMediaPost", { UserId: UserId });
            if(cleanup){
              cleanup();
            }
          }}
          >
            {i18n.t('account.MyAccount_EditProfile_ManageMediaComponent_Create')}
      </ButtonTypeButton>
      </View>
      : null }
      {/* <View style={{flexDirection: 'row'}}> */}
      <PhoneNumberComponent 
      userSettingsState={userSettingsState} 
      ContactName={user.ContactName1}
      ContactPhone={user.ContactPhone1}
       />
       <PhoneNumberComponent 
      userSettingsState={userSettingsState} 
      ContactName={user.ContactName2}
      ContactPhone={user.ContactPhone2}
       />
       {/* </View> */}
       <View style={styles.addressContainer}>
       <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Address')}: {user.Address}</Text>
       </View>
       <View style={styles.addressContainer}>
       <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Bio')}: {user.Bio}</Text>
       </View>

        <ScrollView
    contentContainerStyle={styles.scrollViewContent}
    keyboardShouldPersistTaps='handled'
    horizontal={true}
    showsHorizontalScrollIndicator={true}
  >
      <View style={[styles.boxContainer, { backgroundColor: GlobalStyles.colors.Lavender }]}>
        <Text style={styles.title}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior')}</Text>
        <Text style={[styles.text, { paddingBottom: 20 }]}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_GoodWith')}...</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Children')}: {user.Behavior_Children}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Dogs')}: {user.Behavior_Dogs}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Cats')}: {user.Behavior_Cats}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Strangers')}: {user.Behavior_Strangers}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_OtherAnimals')}: {user.Behavior_OtherAnimals}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_EnergyLevel')}: {user.Behavior_EnergyLevel}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_TrainedLevel')}: {user.Behavior_TrainedLevel}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Behavior_Nature')}: {user.Behavior_Nature}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_AdditionalInfo')}:</Text>
        <Text style={styles.text}>{user.Behavior_AdditionalInfo}</Text>
      </View>
      <View style={[styles.boxContainer]}>
        <Text style={styles.title}>{i18n.t('account.MyAccount_ManagePetProfileForm_Health')}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Health_Allergies')}: {user.Health_Allergies}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Health_Medication')}: {user.Health_Medication}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Health_Vaccine')}: {user.Health_Vaccine}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Health_MedicalIndications')}: {user.Health_MedicalIndications}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Health_AdditionalInfo')}:</Text>
        <Text style={styles.text}>{user.Health_AdditionalInfo}</Text>
      </View>
      <View style={[styles.boxContainer, { backgroundColor: GlobalStyles.colors.Lavender }]}>
        <Text style={styles.title}>{i18n.t('account.MyAccount_ManagePetProfileForm_Vet')}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Vet_Name')}: {user.Vet_Name}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Vet_PhoneNumber')}: {user.Vet_PhoneNumber}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Vet_MicrochipNumber')}: {user.Vet_MicrochipNumber}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Vet_RabiesNumber')}: {user.Vet_RabiesNumber}</Text>
        <Text style={styles.text}>{i18n.t('account.MyAccount_ManagePetProfileForm_Vet_AdditionalInfo')}:</Text>
        <Text style={styles.text}>{user.Vet_AdditionalInfo}</Text>
      </View>
      </ScrollView>
  </View>
  );
}
const styling = (responsiveStyles, paddingScreen) => StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    paddingVertical: 10,
    justifyContent:'center',
  },
  container: {
    width:
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 
    : responsiveStyles.Tablet ? responsiveStyles.screenWidth * 0.7
     : responsiveStyles.screenWidth * 0.9,
     alignSelf:'center',
     //  backgroundColor:'blue',
  },
  buttonContainer: {
    alignItems:'center',
    paddingTop: 20,
    paddingBottom: 10,
  },
  button: {
    width: !responsiveStyles.isMobile || responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
    minWidth: !responsiveStyles.isMobile || responsiveStyles.isWeb ? 150 : null,
  },
  inviteStyleButton: {
    // backgroundColor: GlobalStyles.colors.Lavender,
  },
  boxContainer: {
    // flex: 1,
    backgroundColor: GlobalStyles.colors.Gold,
    padding: 15,
    minWidth: responsiveStyles.isMobile || responsiveStyles.isTablet ? 200 : 280,
    marginHorizontal: 15,
    borderRadius: 25,
  },
  addressContainer: {
    paddingHorizontal: 15 + 10,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  title: {
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 10,
  },
  phoneRowContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
    paddingLeft: responsiveStyles.isRTL ? null : 20,
    paddingRight: !responsiveStyles.isRTL ? null : 20,
    paddingBottom: 10,
  },
  rowContainer: {
    flex:1,
    flexDirection: !responsiveStyles.isRTL ? 'row' : 'row-reverse',
  },
  phoneNumberContainer: {
    // flexDirection: !responsiveStyles.isRTL ? 'row' : 'row-reverse',
  },
  smsIconContainer: {
    backgroundColor: GlobalStyles.colors.Lavender,
    padding: 10,
    marginHorizontal: 10,
    alignItems: 'center',
    borderRadius: 15,
  },
  phoneIconContainer: {
    backgroundColor: GlobalStyles.colors.Gold,
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 15,
  },
});

export default ManageBioComponent;