import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import {  GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";
import { navigateToScreen } from "../../navigationResponsive";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const CheckoutButtonComponent = ({ 
    userSettingsState, 
    totalPrice = 0,
    setIsStoreMenuOpen = null,
    navigation,
  }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
    

    
    const [discountCode, setDiscountCode] = useState(false);

  return (
    <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    style={styles.button}
    // styleButton={{ backgroundColor: Gold }}
     onPress={() => {
      // navigateToScreen(navigation, 'StoreFlow', `Cart`, null, null);
      navigateToScreen(navigation, 'StoreFlow', `CartInformation`, null, null);
      // navigate('StoreFlow', { screen: 'Cart' });
      // navigate('StoreFlow', { screen: 'CartInformation' });
      // navigate("Cart");
      //  navigate("CartInformation");
       if(setIsStoreMenuOpen){
         setIsStoreMenuOpen(false);
       }
    }}
    >
      {i18n.t('store.CheckoutButtonComponent_ButtonText')} {totalPrice} ₪
</ButtonTypeButton>
</View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  buttonContainer: {
    alignItems:'center',
    // flex:1
    paddingBottom: 20,
  },
  button: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.5
    : responsiveStyles.screenWidth * 0.6,
  },
});

export default CheckoutButtonComponent;