import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, ActivityIndicator, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";
import { GenericAlert } from "../alert/GenericAlert";
import { Context as ShopContext } from '../../context/ShopContext';
import { CheckForDiscount } from "../../api/apiCalls/StoreCalls";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const DiscountComponent = ({ 
    userSettingsState,
    isFromStoreMenu = false,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles,isFromStoreMenu);

    const { applyDiscount } = useContext(ShopContext);

    const [discountCode, setDiscountCode] = useState('');
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    const Rating = 5;
    const onPressDiscount = async () => {
      if(discountCode.length === 0){
        return;
      }
      setIsLoading(true);
      const response = await CheckForDiscount(discountCode);
      setIsLoading(false);
      console.log(response);
      if(response.isCompleted === true)
      {
        const vm = JSON.parse(response.answer);
        console.log(vm);
        applyDiscount(vm.discountCode, vm.discountPercentage);
        setbodymessge("Discount code was Applied");
        setGenericAlertVisible(true);
        setDiscountCode('');
      }
      else{
        setbodymessge(`Discount code "${discountCode}" does not exist!`);
        setGenericAlertVisible(true);
      }
  };

  
  return (
    <View style={styles.discountContainer}>
      <GenericAlert
      userSettingsState={userSettingsState}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
    <Text style={[styles.text, styles.title]}>{i18n.t('store.DiscountComponent_Title')}</Text>
    <View style={styles.discountTextInput}>
    <TextInputWithErrorComponent
  userSettingsState={userSettingsState}
  value={discountCode}
  setValue={(value) => {
    setDiscountCode(value);
  }}
  label={i18n.t('store.DiscountComponent_Lable')}
  isRequired={false}
  // styleContainer={{ width:
  //   isFromStoreMenu ? 
  //   responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  responsiveStyles.screenWidth * 0.73 : responsiveStyles.screenWidth * 0.65
  //   : responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  responsiveStyles.screenWidth * 0.8 : responsiveStyles.screenWidth * 0.7
  //  }}
  styleTextInputContainer={styles.textInputName}
  />
  <TouchableOpacity style={styles.discountIconContainer}
  onPress={() => onPressDiscount()}
  >
    {isLoading ? 
    <ActivityIndicator style={{padding: 4}} />
    :
  <AntDesign name="checkcircleo" size={!responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 32 : 24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
    }
  </TouchableOpacity>
  </View>
    </View>
  );
}
const styling = (responsiveStyles, isFromStoreMenu) => StyleSheet.create({
  discountContainer: {
    width: !responsiveStyles.isMobile ? null : responsiveStyles.screenWidth * 0.85,
    // paddingRight: 50,
  },
  discountIconContainer: {
    backgroundColor: GlobalStyles.colors.Lavender, 
    height: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 65, 
    justifyContent:'center',
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
    padding: 10,
    width: 50,
  },
  title: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    marginHorizontal: 15,
    marginTop: 10,
    marginBottom: 20,
    textAlign: responsiveStyles.isRTL ? 'right' : 'left',
    marginRight: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? null : null : isFromStoreMenu ? 70 : 50,
  },
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
},
discountTextInput:{
  flexDirection: 'row', 
  justifyContent: responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
  marginRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 15 : null : isFromStoreMenu ? 60 : 40,
  marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? !responsiveStyles.isRTL ? 15 : null : null,
},
textInputName: {
  borderRadius: 15 ,
  borderTopRightRadius: 0, 
  borderBottomRightRadius: 0,
  alignSelf:'flex-end',
  // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 - 50 : responsiveStyles.screenWidth * 0.85 - (isFromStoreMenu ? 110 : 90),
  width: 
  responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 - 50 
  : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.6 - (isFromStoreMenu ? 110 : 90) 
  : responsiveStyles.screenWidth * 0.8 - (isFromStoreMenu ? 110 : 90),
},
});

export default DiscountComponent;