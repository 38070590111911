import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const ErrorComponentMessageError = ({ 
    userSettingsState, 
    errorMessage,
    styleTextInputContainer,
   }) => {
    console.log(`styleTextInputContainer is ${styleTextInputContainer}`);
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={[
      { flexDirection:'row'},
      responsiveStyles.Language === 'he-IL' ? { justifyContent:'flex-end'  } : { justifyContent:'flex-start' },
    ]}>
      <View style={[
      {alignItems:'center'},
     responsiveStyles.Language === 'he-IL' ? { flexDirection:'row-reverse' } : { flexDirection:'row' },
     styleTextInputContainer?.width > 0 ? { maxWidth: styleTextInputContainer.width } : null,
    ]}>
    <Text style={[GlobalStyles.errorMessage,
     responsiveStyles.Language === 'he-IL' ? {  } : null,
    ]}>
          <Text style={
        {
          color:'red',
          fontSize: responsiveStyles.fontSize_H4, 
          fontFamily: GlobalStyles.fonts.application_font,
        }
        }> X </Text>
      {errorMessage}
    </Text>
    </View>
  </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
text: {
  fontFamily: GlobalStyles.fonts.application_font,
}
});

export default ErrorComponentMessageError;