import React from "react";
import { StyleSheet, View, Text, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import BulletText from "../inputs/BulletText";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import RatingAndPurchaseContainer from "./RatingAndPurchaseContainer";
import JewelryTypeLengthSelector from "./JewelryTypeLengthSelector";

const JewelryLengthContainer = ({ 
  userSettingsState, 
  foundItem,
  chainSize,
  setChainSize,
  braceletSize,
  setBraceletSize,
  ringSize,
  setRingSize,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  const isChain = foundItem.IsChain;
  const isBracelet = foundItem.IsBracelet;
  const isRing = foundItem.IsRing;

  if(!isChain && !isBracelet && !isRing){
    return null;
  }

  return (
    <View style={styles.container}>
    <View style={[styles.borderBottomStyle]}>
       <JewelryTypeLengthSelector
       userSettingsState={userSettingsState}
       isChain={isChain}
       isBracelet={isBracelet}
       isRing={isRing}
       chainSize={chainSize}
       setChainSize={setChainSize}
       braceletSize={braceletSize}
       setBraceletSize={setBraceletSize}
       ringSize={ringSize}
       setRingSize={setRingSize}
       />
      </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // marginBottom: 20,
  },
  bulletContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  bullet: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  bulletText: {
    flex: 1,
    lineHeight: responsiveStyles.fontSize_H5,
    paddingLeft: 5, // adjust padding as needed to align with the bullet point
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  itemNameContainer: {
  },
  borderBottomStyle: {
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  descriptionText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingBottom: 10,
    fontWeight: '600',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default JewelryLengthContainer;
