// import i18n from "../hooks/useI18n";
// import { getItem } from "../modules/Storage";
// import { HomeScreenDefault_Storage } from "../modules/StorageObjects";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { token_Storage, IsAdmin_Storage } from "../modules/StorageObjects";
import { navigate } from "../navigationRef";
import { navigateToScreen } from "../navigationResponsive";
// import { HomeScreenSetterListNavigation } from "../objects/HomeScreenSetterList";

export const navigateToHomeScreen = async (navigation, isNavigateToLoginFlow) => {
  let token = await AsyncStorage.getItem(token_Storage);
  let IsAdmin = await AsyncStorage.getItem(IsAdmin_Storage);
  console.log(`IsAdmin_Storage is ${IsAdmin}`);
  
  if(token){
    // navigateToScreen(navigation, 'DiscoverFlow', `ImagePicker`, null, null);
    if(IsAdmin === true || IsAdmin === "true"){
      navigateToScreen(navigation, 'AdminFlow', "AdminOrdersList", null, null);
    }
    else{
      navigateToScreen(navigation, 'AccountFlow', `MyAccount`, null, null);
    }

    // navigate('AccountFlow', { 
    //   screen: 'MyAccount', 
    //   // params: { Id: 2 },
    // });
  }
  else{
    if(isNavigateToLoginFlow === true){
      navigateToScreen(navigation, 'LoginFlow', `Signin`, null, null);
    }
    else{
      navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
    }
    // navigate("StoreFlow");
  }
  };
  