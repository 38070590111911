import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../../context/UserSettingsContext";
import { GlobalStyles } from '../../../constants/styles';
import Screen from "../../../components/Screen";
import i18n from '../../../hooks/useI18n';
import { GenericAlert } from '../../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../../constants/ScreenDimensions';
import ImageProfile from '../../../components/images/ImageProfile';
import BuisnessFooter from '../../../components/store/BuisnessFooter';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import BulletText from '../../../components/inputs/BulletText';
import useResponsiveStyles from '../../../functions/ResponsiveStyles';

const AboutUsScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "About Us";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      if(Platform.OS === "android" || Platform.OS === "ios"){
        const backHandler = BackHandler.addEventListener(
          'hardwareBackPress',
          backAction,
        );
      return () => backHandler.remove();
      }
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <ScrollView style={[styles.container]}>
    <View style={styles.imageContainer}>
      <ImageProfile
      userSettingsState={userSettingsState}
        // uri={jewlery.Image}
        imageStyle={styles.userPhoto}
        editable={false}
        isGroup={false}
        isTouchable={false}
          />
      </View>
    <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>

      <View style={{padding: 10}}>


      
      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('aboutUs.Title')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section1_1')}</Text>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('aboutUs.Section1_2')}</Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section1_3')}</Text>
        </Text>
      </View>


      <View style={[styles.DescriptionContainer, styles.secondPharagraph]}>
      <Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section2_1')}</Text>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('aboutUs.Section2_2')}</Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section2_3')}</Text>
        </Text>
      </View>

      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('aboutUs.OurMission')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section3_1')}</Text>
        </Text>
      </View>


      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('aboutUs.OurStory')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section4_1')}</Text>
        </Text>
      </View>


      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('aboutUs.OurCraftsmanship')}
          </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('aboutUs.Section5_1')}</Text>
        </Text>
      </View>


      <View style={styles.TitleContainer}>
        <Text style={styles.title}>
        {i18n.t('aboutUs.WhyChooseUs')}
          </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('aboutUs.WhyChooseUs_1_Title')}</Text>
        <Text style={[styles.text]}>{i18n.t('aboutUs.WhyChooseUs_1_Description')}</Text>
        </>} />

        <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('aboutUs.WhyChooseUs_2_Title')}</Text>
        <Text style={[styles.text]}>{i18n.t('aboutUs.WhyChooseUs_1_Description')}</Text>
        </>} />

        <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('aboutUs.WhyChooseUs_3_Title')}</Text>
        <Text style={[styles.text]}>{i18n.t('aboutUs.WhyChooseUs_1_Description')}</Text>
        </>} />

        <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('aboutUs.WhyChooseUs_4_Title')}</Text>
        <Text style={[styles.text]}>{i18n.t('aboutUs.WhyChooseUs_1_Description')}</Text>
        </>} />

      </View>
      </View>
      <BuisnessFooter userSettingsState={userSettingsState} navigation={navigation} />
    </ScrollView>
    </Screen>

}


AboutUsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.5 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  imageContainer: {
    // backgroundColor:'blue',
    // marginHorizontal: -20,
    // width: responsiveStyles.screenWidth,
},
userPhoto: {
    // width: responsiveStyles.screenWidth,
    height: 300,
  },
  TitleContainer: {
    // backgroundColor: 'blue',
    paddingTop: 20,
    paddingBottom: 10,
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  DescriptionContainer: {
    flexDirection: 'row',
    paddingBottom: 20,
  },
  secondPharagraph: {
    paddingTop: 20,
  },
  text: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontSize: responsiveStyles.fontSize_H5,
    lineHeight: responsiveStyles.fontSize_H5 * 1.5,
},
  textBold: {
    fontWeight: 'bold',
  },
});

export default AboutUsScreen;
