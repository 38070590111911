import React, { useRef, useState, useEffect, useCallback } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Modal } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import VideoMedia from "./VideoMedia";
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { getThumbnailAsync } from "expo-video-thumbnails";
import Slider from "@react-native-community/slider";
import RenderSlider from "./RenderSlider";
import FullScreenIcon from "./FullScreenIcon";

  const VideoMediaPrimary = ({ 
    videoUri,
    isAudio,
    resizeMode = "cover",
    durationTime = undefined,
    videoStyle,
    videoContainerStyle,
    videoContainerStyleTouchableOpacity,
    isShowBorder = false,
    showFullScreen = false,
    onSliderTouchStart = null,
    onSliderTouchEnd = null,
    isDisplaying = false,
    allowPlayVideo = true,
    onTouchableOpacityPress = null,
    onPlayShowFullScreen = false,
    userSettingsState,
    isThumbnail = false,

    isBackgroundColorBlack = false,
    isSetObjectFit = true,
    RenderSliderWidth,
    isFromTributes = false,
    videoTextStyle,

    isStyleWidthFullWhenFullScreen = false,
    isFromTutorialContainer = null,
    fullScreenZindex = null,

   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const [isFullScreen, setIsFullScreen] = useState(false);
  const styles = styling(responsiveStyles, isAudio, isFullScreen);
  const videoRef = useRef(null);
  const [position, setPosition] = useState(0);
  console.log(`position is ${position}`);
  
  const [refresh, setRefresh] = useState(0);
  // console.log(`isFullScreen is ${isFullScreen}`);
  const [thumbnail, setThumbnail] = useState(null);

  const [videoSliderValue, setVideoSliderValue] = useState(0);
  const [shouldPlay, setShouldPlay] = useState(false);

  const refreshVideoRef = async () => {
    if (videoRef.current) {
      videoRef.current.setPositionAsync(position);
      let newVideoSliderValue = position / durationInMilliSeconds;
      console.log(`refreshVideoRef 
        newVideoSliderValue = ${newVideoSliderValue}
        position: ${position}
        `);
      
      setVideoSliderValue(newVideoSliderValue);
      console.log(`refreshVideoRef position ${position}`);
      }
      else{
        console.log(`refreshVideoRef, !videoRef.current`);
        console.log(videoRef);
      }
  }
  const setVideoObjectFitContain = useCallback(() => {
    if(responsiveStyles.isWeb){
    console.log(`setVideoObjectFitContain`);
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
      video.style.objectFit = 'contain';
    });
  }
}, [responsiveStyles.isWeb, isSetObjectFit]);
  useEffect(() => {
    console.log(`useEffect from VideoMediaPrimary`);
    if(isThumbnail && !thumbnail){
      const getThumbnail = async () => {
        console.log(`getThumbnail started, isThumbnail: ${isThumbnail}, thumbnail: ${thumbnail}
          isThumbnail && !thumbnail: ${isThumbnail && !thumbnail}
          `);
        const { uri } = await getThumbnailAsync(videoUri, {
          time: 500, // time to take the thumbnail from (in milliseconds)
        });
        console.log(`getThumbnail, uri is ${uri}`);
        
        setThumbnail(uri);
        return uri;
      }
      getThumbnail();
    }
    if(responsiveStyles.isWeb){
      if(isSetObjectFit){
        setVideoObjectFitContain();
        setInterval(setVideoObjectFitContain, 1000);
      }
    }
    refreshVideoRef();
  }, [refresh, isFullScreen]);

  const onOpen = async () => {
    console.log(`onOpen`);
    setShouldPlay(false);
    // refreshVideoRef();
    setRefresh(!refresh);
    setIsFullScreen(true);
  }
  const onClose = async () => {
    console.log(`onClose`);
    await refreshVideoRef();
    setRefresh(!refresh);
    setIsFullScreen(false);
  }


  
  

  const [hours, minutes, seconds] = 
  durationTime !== undefined && durationTime !== null 
  ?
  isAudio ?
  durationTime?.split(":").map((part, index) => index === 2 ? Number(part.slice(0, 1))
  : Number(part)) || [0, 0, 0]
  : durationTime?.split(":").map(Number) 
  : "00:00:00.0000000".split(":").map(Number);

  const duration = durationTime !== null && durationTime !== undefined && durationTime.length > 5 ? durationTime?.substr(3, 5) : "00:00";
  const durationInSeconds = minutes === undefined || seconds === undefined ? 0 : (minutes * 60 + seconds);
  const durationInMilliSeconds = durationInSeconds * 1000;

  const onSliderValueChange = async (value) => {
    try{
      
    console.log(`onSliderValueChange value: ${value}`);
    
    if(value === null || value === undefined 
      || value === "Nan"
      || value?.toString()?.toLowerCase() === "nan"
    ){
      return;
    }
    if (videoRef.current) {
      const newPositionValue = (value * durationInSeconds) * 1000;
      console.log(`onSliderValueChange 
        value: ${value} 
        durationInSeconds: ${durationInSeconds}
        newPositionValue: ${newPositionValue}
        position: ${position}
        `);
        const differenceInPosition = (newPositionValue - position) > 0 ? newPositionValue - position : position - newPositionValue;
      if(position === newPositionValue || (differenceInPosition < 0.00001)
      ){
        console.log(`if(position === newPositionValue || (differenceInPosition < 0.00001)`);
        return;
      }
      videoRef.current.setPositionAsync(newPositionValue);
      setPosition(newPositionValue);
      let newVideoSliderValue = 
      // status ? status?.positionMillis / durationInMilliSeconds : 
      newPositionValue / durationInMilliSeconds;
      console.log(`newVideoSliderValue is ${newVideoSliderValue}`);
      
      setVideoSliderValue(newVideoSliderValue);
    }
    else{
      console.log(`onSliderValueChange, !videoRef.current`);
      
    }
  }
  catch(err){
    console.log(`func onSliderValueChange failed: err: ${err}`);
  }
  };
  const onVideoStatusUpdate = useCallback((status) => {
    // console.log(`onVideoStatusUpdate durationInMilliSeconds: ${durationInMilliSeconds}`);
    if(durationInMilliSeconds === 0 || !duration){
      return;
    }
    if (status.isLoaded) {
      if (!shouldPlay) {
        // console.log(`onVideoStatusUpdate isLoaded isPlaying: ${isPlaying} `);
        // setShouldPlay(true);
      }
    }
    if (status.isPlaying) {
      // console.log(`onVideoStatusUpdate isPlaying status.positionMillis: ${status.positionMillis} `);
        setPosition(status.positionMillis);
        setVideoSliderValue(status.positionMillis / durationInMilliSeconds);
    } else {
      // setShouldPlay(false);
    }
    if(status.positionMillis === durationInMilliSeconds){
      // console.log(`onVideoStatusUpdate  ${JSON.stringify(status)} `);
      setPosition(status.positionMillis);
      setVideoSliderValue(status.positionMillis / durationInMilliSeconds);
    }
  }, [durationInMilliSeconds, durationTime]);
  const onVideoReadyForDisplay = async () => {
    console.log(`onVideoReadyForDisplay`);
    if(isFullScreen){
      if(!shouldPlay){
        refreshVideoRef();
        setShouldPlay(true);
      }
    }
    if((!isFullScreen && responsiveStyles.isWeb && !shouldPlay)){
      refreshVideoRef();
    }
    if(isDisplaying){
      console.log('Video is ready in VideoImage for display');
      setShouldPlay(true);
    }
  };
  return (
    <>
    <View style={styles.container}>
    {
      isFullScreen ? (
        <Modal
        visible={isFullScreen}
        transparent={true}
        animationType="slide"
        onRequestClose={onClose}
      >
      <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
    <VideoMedia
    userSettingsState={userSettingsState}
    resizeMode={resizeMode}
    videoRef={videoRef}
    uri={videoUri} 
    isAudio={isAudio}
    videoStyle={[
      videoStyle, 
      isStyleWidthFullWhenFullScreen ? 
      { width: responsiveStyles.screenWidth, maxWidth: null, height: '100%', maxHeight: null } 
      : null
    ]}
    // videoContainerStyle={videoContainerStyle}
    // videoContainerStyleTouchableOpacity={videoContainerStyleTouchableOpacity}
    // isShowBorder={isShowBorder}
    refresh={refresh}
    isDisplaying={isDisplaying}
    isFullScreen={isFullScreen} // true
    // allowPlayVideo={allowPlayVideo}
    onTouchableOpacityPress={onTouchableOpacityPress}
    onPlayShowFullScreen={onPlayShowFullScreen}
    setVisible={setIsFullScreen}
    visible={isFullScreen}
    // isThumbnail={isThumbnail}
    // thumbnail={thumbnail}
    isBackgroundColorBlack={isBackgroundColorBlack}
    refreshVideoRef={refreshVideoRef}
    onVideoStatusUpdate={onVideoStatusUpdate}
    shouldPlay={shouldPlay && isFullScreen}
    setShouldPlay={setShouldPlay}
    onVideoReadyForDisplay={onVideoReadyForDisplay}
    />
            <TouchableOpacity
          style={{
            position: 'absolute',
            top: 30,
            right: 20,
          }}
          onPress={onClose}
        >
          <AntDesign name="closecircle" size={24} color="white" />
        </TouchableOpacity>
        <RenderSlider
        userSettingsState={userSettingsState}
    allowPlayVideo={allowPlayVideo} 
    durationTime={durationTime} 
    videoSliderValue={videoSliderValue} 
    onSliderValueChange={onSliderValueChange} 
    onSliderTouchStart={onSliderTouchStart} 
    onSliderTouchEnd={onSliderTouchEnd} 
    position={position} 
    duration={duration} 
    isFullScreen={isFullScreen}
  />
        </View>
        </Modal>
      ) : (
 null
      )
    }
       {
        // i did that because on web, when i click on "onOpen" function
        // this VideoMedia keeps playing for some reason, so i have disappeared this component
        isFullScreen && responsiveStyles.isWeb ? null :
        <VideoMedia
       userSettingsState={userSettingsState}
       resizeMode={resizeMode}
        videoRef={isFullScreen ? null : videoRef}
        uri={videoUri} 
        isAudio={isAudio}
        videoStyle={videoStyle}
        videoContainerStyle={videoContainerStyle}
        videoContainerStyleTouchableOpacity={videoContainerStyleTouchableOpacity}
        isShowBorder={isShowBorder}
        refresh={refresh}
        isDisplaying={isDisplaying}
        isFullScreen={isFullScreen} // false
        allowPlayVideo={allowPlayVideo}
        onTouchableOpacityPress={onTouchableOpacityPress}
        onPlayShowFullScreen={onPlayShowFullScreen}
        setVisible={setIsFullScreen}
        visible={isFullScreen}
        isThumbnail={isThumbnail}
        thumbnail={thumbnail}
        isBackgroundColorBlack={isBackgroundColorBlack}
        refreshVideoRef={refreshVideoRef}
        onVideoStatusUpdate={onVideoStatusUpdate}
        shouldPlay={shouldPlay && !isFullScreen}
        setShouldPlay={setShouldPlay}
        onVideoReadyForDisplay={onVideoReadyForDisplay}
        />
        }
          <FullScreenIcon
  userSettingsState={userSettingsState}
  showFullScreen={showFullScreen}
  isAudio={isAudio}
  onOpen={onOpen}
  isFromTutorialContainer={isFromTutorialContainer}
  fullScreenZindex={fullScreenZindex}
  />
        <RenderSlider
        userSettingsState={userSettingsState}
    allowPlayVideo={allowPlayVideo} 
    durationTime={durationTime} 
    videoSliderValue={videoSliderValue} 
    onSliderValueChange={onSliderValueChange} 
    onSliderTouchStart={onSliderTouchStart} 
    onSliderTouchEnd={onSliderTouchEnd} 
    position={position} 
    duration={duration} 
    isFullScreen={isFullScreen}
    RenderSliderWidth={RenderSliderWidth}
    isFromTributes={isFromTributes}
    videoTextStyle={videoTextStyle}
    isFromTutorialContainer={isFromTutorialContainer}
  />



    </View>
    </>
  );
}
const styling = (responsiveStyles, isAudio, isFullScreen) => StyleSheet.create({
  container: { 
    flex: 1, 
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
  },
  progressBarContainer: {
    // flexDirection:'row',
    position: 'absolute',
    bottom: 0,
    // bottom: isAudio ? 10 : 10,
    width: isFullScreen ? '100%' : '90%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 5,
    // flex:1,
    backgroundColor:'blue',
    // justifyContent: 'space-between',
    justifyContent:'center',
    // paddingHorizontal: 20,
    // zIndex: 1000,
  },
  videoText: {
    fontSize: responsiveStyles.fontSize_H6,
    color:  GlobalStyles[responsiveStyles.ThemeValue]?.iconColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  slider: {
    width: '65%',
  },
  isFullScreenColor: {
    color: "white"
  },
  videoNativeStyle: 
  { 
    objectFit:'contain', 
    width: '100%', 
    height: '100%',
  }
});

export default VideoMediaPrimary;