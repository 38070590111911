export default {
  Title: "עזרה",
  Section1_1: "ברוך הבא למרכז העזרה של TMJ! אנחנו כאן כדי לסייע לך עם כל שאלה או בעיה שיש לך. למטה תמצא תשובות לשאלות נפוצות וטיפים לפתרון בעיות כדי להבטיח לך את החוויה הטובה ביותר עם תכשיטי TMJ שלך.",
  FrequentlyAskedQuestions: "שאלות נפוצות",
  Section2_Title: "איך לסרוק את קוד ה-QR על תכשיטי TMJ שלי?",
  Section2_1: "לסרוק את קוד ה-QR, פשוט השתמש במצלמת הסמארטפון שלך או באפליקציית סריקת קודי QR. כוון את המצלמה לעבר קוד ה-QR, וקישור יופיע על המסך שלך. הקש על הקישור כדי לגשת לגלריה הדיגיטלית האישית שלך.",
  Section3_Title: "איך להקים פרופיל ולפרסם את הגלריה הדיגיטלית שלי?",
  Section3_1: "לאחר רכישת תכשיטי TMJ שלך, תקבל אימייל עם הוראות כיצד להקים את הגלריה שלך. עקוב אחרי הקישור שנמצא באימייל כדי ליצור חשבון.",
  Section3_2: "לאחר שתתחבר, תוכל להקים פרופיל לאדם שאתה רוצה ליצור לו פרופיל. זה כולל העלאת תמונות, סרטונים וכל קובץ אחר שתרצה לשתף.",
  Section3_3: "התאם אישית את הפרופיל כדי להפוך אותו לייחודי ואישי, כך שישקף את הזיכרונות והרגעים שברצונך להציג.",
  Section4_Title: "איך להקצות פרופיל לתכשיט שלי?",
  Section4_1: "בחשבון TMJ שלך, נווט אל הסעיף 'תכשיטים'. כאן תראה את כל התכשיטים שהזמנת. לחץ על התכשיט שברצונך להקצות. ואז, לחץ על כפתור 'הקצה תכשיט' ובחר את הפרופיל שברצונך לקשר לקוד ה-QR הזה. תוכל ליצור ולנהל מספר פרופילים לפי הצורך.",
  Section5_Title: "מה אם קוד ה-QR לא נסרק?",
  Section5_1: "ודא שהעדשה של המצלמה שלך נקייה ויש מספיק אור כדי לראות את קוד ה-QR בבירור. אם הבעיה נמשכת, נסה להשתמש באפליקציית סריקת קודים אחרת. אם אתה עדיין נתקל בקשיים, פנה לתמיכת הלקוחות שלנו לעזרה נוספת.",
  Section6_Title: "האם אני יכול לעדכן את התוכן בגלריה הדיגיטלית שלי?",
  Section6_1: "כן, תוכל לעדכן את הגלריה שלך בכל עת על ידי התחברות לחשבון שלך באתר שלנו. פשוט העלה תמונות או סרטונים חדשים, והם יהיו זמינים מיידית דרך קוד ה-QR על התכשיט שלך.",
  Section7_Title: "האם המידע האישי שלי מאובטח?",
  Section7_1: "ב-TMJ, פרטיותך וביטחונך הם בראש סדר העדיפויות שלנו. אנו משתמשים בטכנולוגיות הצפנה מתקדמות כדי להגן על הנתונים שלך ולוודא שהמידע האישי שלך בטוח.",
  Troubleshooting: "פתרון בעיות",
  Problem: "בעיה:",
  Solution: "פתרון:",
  Troubleshooting_Problem1: "קוד ה-QR לא נסרק.",
  Troubleshooting_Solution1_1: "וודא שמצלמת הטלפון שלך ממוקדת על קוד ה-QR.",
  Troubleshooting_Solution1_2: "נסה לנקות את פני השטח של קוד ה-QR אם הוא מלוכלך או שרוט.",
  Troubleshooting_Solution1_3: "השתמש באפליקציית סריקת קודים אחרת.",
  Troubleshooting_Problem2: "לא קיבלתי את האימייל להקמה.",
  Troubleshooting_Solution2_1: "בדוק את תיק האימיילים הזבל או הדואר המפוקפק.",
  Troubleshooting_Solution2_2: "ודא שהזנת את כתובת האימייל הנכונה במהלך הרכישה.",
  Troubleshooting_Solution2_3: "פנה לתמיכת הלקוחות שלנו לעזרה.",
  Troubleshooting_Problem3: "שכחתי את סיסמת החשבון שלי.",
  Troubleshooting_Solution3_1: "לחץ על הקישור 'שכחתי סיסמה' בעמוד הכניסה כדי לאפס את הסיסמה שלך. עקוב אחרי ההוראות שנשלחו לאימייל שלך כדי לשחזר את הגישה לחשבון שלך.",
  ManageJewelryAndProfiles: "ניהול התכשיטים והפרופילים שלך",
  ManageJewelryAndProfiles1_Title: "איך לצפות בתכשיטים שלך:",
  ManageJewelryAndProfiles1_1: "היכנס לחשבון TMJ שלך.",
  ManageJewelryAndProfiles1_2: "נווט אל הסעיף 'תכשיטים' כדי לראות את כל הפריטים שהזמנת.",
  ManageJewelryAndProfiles2_Title: "איך להקצות פרופיל לתכשיט שלך:",
  ManageJewelryAndProfiles2_1: "בסעיף 'תכשיטים', לחץ על התכשיט שברצונך לנהל.",
  ManageJewelryAndProfiles2_2: "לחץ על כפתור 'הקצה תכשיט'.",
  ManageJewelryAndProfiles2_3: "בחר את הפרופיל שברצונך לקשר לקוד ה-QR הזה. תוכל ליצור ולנהל מספר פרופילים בתוך החשבון שלך.",
  ContactUs: "צור קשר",
  ContactUs_1: "אם אתה זקוק לעזרה נוספת, אל תהסס לפנות לצוות תמיכת הלקוחות שלנו. אנחנו כאן כדי לעזור!",
  ContactUs_Email: "אימייל:",
  ContactUs_Phone: "טלפון:",
  FinalWords: "תודה שבחרת ב-TMJ. אנו מחויבים לספק לך את החוויה הטובה ביותר האפשרית!",
};
