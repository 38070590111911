import React from "react";
import { StyleSheet, View, Text, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";


const BulletText = ({ 
  userSettingsState, 
  text, 
  withBullet = true,
  isBold = false,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  return (
    <View style={styles.bulletContainer}>
    {withBullet ? <Text style={[styles.bullet, isBold ? styles.bold : null]}>{'\u2022 '}</Text> : null}
    <Text style={[styles.bulletText, isBold ? styles.bold : null]}>
      {text}
    </Text>
  </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  bulletContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems: 'flex-start',
    marginVertical: !responsiveStyles.isMobile ? 10 : 5,
    // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? '85%' : null,
    // alignSelf:'center',
  },
  bullet: {
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  bold: {
    fontWeight: 'bold',
  },
  bulletText: {
    flex: 1,
    fontSize: responsiveStyles.fontSize_H5,
    paddingLeft: !responsiveStyles.isRTL ? 5 : null,
    paddingRight: responsiveStyles.isRTL ? 5 : null,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
});

export default BulletText;
