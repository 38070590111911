import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import { AntDesign } from '@expo/vector-icons';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const QuantityStoreItemComponent = ({ 
    userSettingsState, 
    quantity,
    setQuantity,
    canDelete = false,
    styleContainer = null,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  return (
    <View style={[
      styles.priceContainer,
      {  justifyContent: !responsiveStyles.isMobile ?  'flex-end'  : 'center' },
      styleContainer,

    ]}>
      <TouchableOpacity
      style={styles.priceIcon}
      onPress={() => setQuantity(quantity + 1)}
      >
    <AntDesign name="plus" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      </TouchableOpacity>

      <Text style={[styles.text, styles.itemDetails]}>{quantity}</Text>

      <TouchableOpacity
      style={styles.priceIcon}
      onPress={() => {
        if(quantity <= 1){
            if(canDelete){
                setQuantity(0);
            }
        }
        else{
          setQuantity(quantity - 1)
        }
        
      }}
      >
    <AntDesign name="minus" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
    </TouchableOpacity>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    itemDetailsContainer: {
        flexDirection: responsiveStyles.isRTL ? "row-reverse" : 'row',
        // justifyContent: 'space-around',
        
        marginTop: 10,
        marginBottom: 30,
      },
      priceContainer: {
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
        
      },
      priceIcon: {
        marginHorizontal: 10,
      },
      itemNameContainer: {
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
      
      },
      text: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
        },
      itemDetails: {
        fontSize: responsiveStyles.fontSize_H4,
        fontFamily: GlobalStyles.fonts.application_font,
        fontWeight: 'bold',
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
    rating: {
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      padding: 2,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
});

export default QuantityStoreItemComponent;