import React from 'react';
import { TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native';
import { GlobalStyles } from '../constants/styles';
import { Feather } from '@expo/vector-icons';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const HamburgerMenu = ({ onPress, userSettingsState }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  console.log(`HamburgerMenu responsiveStyles is ${JSON.stringify(responsiveStyles)}`);

  console.log(`responsiveStyles.ThemeValue is ${responsiveStyles.ThemeValue}`);
  
    const styles = styling(responsiveStyles);
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <Feather name="menu" size={responsiveStyles.isWeb ? 32 : 24} color={GlobalStyles[responsiveStyles.ThemeValue].iconColor} />
      </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    paddingHorizontal: 20,
  },
});

export default HamburgerMenu;
