export default {
  Title: "מדיניות פרטיות",
  description: "החברה TMJ מחויבת להגן על פרטיותך. מדיניות פרטיות זו מתארת כיצד אנו אוספים, משתמשים ומגנים על המידע האישי שלך. באמצעות השימוש באתר שלנו ובשירותים שלנו, אתה מסכים לתנאים של מדיניות זו.",
  Section1_Title: "1. מידע שאנו אוספים",
  Section1_1_1: "1.1 מידע אישי:",
  Section1_1_2: "כאשר אתה יוצר חשבון, מבצע הזמנה או פונה אלינו, אנו עשויים לאסוף מידע אישי כגון שמך, כתובת הדוא\"ל שלך, כתובת למשלוח, מספר טלפון ומידע תשלום.",
  Section1_2_1: "1.2 נתוני שימוש:",
  Section1_2_2: "אנו עשויים גם לאסוף מידע על האופן שבו האתר והשירותים שלנו ניגשים ומשתמשים בהם. זה עשוי לכלול את כתובת ה-IP שלך, סוג הדפדפן, דפים שביקרנו בהם, זמן שהות בדפים ונתונים אבחוניים נוספים.",
  Section2_Title: "2. כיצד אנו משתמשים במידע שלך",
  Section2_1_1: "2.1 כדי לספק שירותים:",
  Section2_1_2: "אנו משתמשים במידע האישי שלך כדי לעבד הזמנות, לנהל את החשבון שלך ולספק תמיכה ללקוח.",
  Section2_2_1: "2.2 לשיפור השירותים שלנו:",
  Section2_2_2: "אנו מנתחים נתוני שימוש כדי להבין כיצד השירותים שלנו בשימוש ולשפר את חווית המשתמש.",
  Section2_3_1: "2.3 שיווק:",
  Section2_3_2: "בהסכמתך, אנו עשויים להשתמש במידע שלך כדי לשלוח לך חומרי פרסום ועדכונים על המוצרים והשירותים שלנו. תוכל לבטל את ההסכמה לתקשורת זו",
};