import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image, Platform, ActivityIndicator, Modal, useWindowDimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { convertImageToBase64 } from "../../functions/imageLogic";
import ImageModal from "./ImageModal";
import { AntDesign } from '@expo/vector-icons';
import {  GlobalStyles } from "../../constants/styles";
import { defaultBackgroundImage, defaultImage } from "../../constants/images";
import { Feather } from '@expo/vector-icons';
import { askForPermissions } from "../../functions/pickImage";
import { Asset } from "expo-asset";
import ImagePickerPopupWebComponent from "./ImagePickerPopupWebComponent";
import useLazyLoad from "../../functions/useLazyLoad";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { getAsset } from "../../functions/getAsset";
import FullScreenIcon from "./FullScreenIcon";
// import { pickImage } from "../../functions/pickImageWithCrop";
// import LazyImage from 'react-native-image-lazy-loading';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const ImageProfile = ({
  userSettingsState,
    uri = null,
    resizeMode = null,
    isUriWithSource = false,
    imageStyle,
    editable = false,
    onImagePick = null,
    isTouchable = true,
    showOptionButton = false,
    showCloseButton = false,
    onPress = null,
    createdTime,
    downloadable = false,
    userName = null,
    showEditButton = false,
    editButtonContainerStyle = null,
    isAllFiles = false,
    allowsMultipleSelection = false,
    isShowReloading = false,
    accessibilityLabel,
    key,


    isWithCrop = false,
    isCircle = false,
    isBackgroundImage = false,
    isBackgroundColorBlack = false,
    showFullScreen = false,
}) => {

  
const responsiveStyles = useResponsiveStyles(userSettingsState);
  const [isVisible, elementRef] = useLazyLoad(); // Use the lazy load hook

  const imagePic = uri === null || uri.length === 0 ?
   isBackgroundImage ? { uri: defaultBackgroundImage } : { uri: defaultImage }
  :isUriWithSource ? uri : { uri: uri };

  const errorURI = getAsset(require('../../../assets/images/error/image_url_not_valid.png'));
  // const errorURI = "";
  const styles = styling(responsiveStyles);
    const [modalVisible, setModalVisible] = useState(false);


    const [isReloading, setIsReloading] = useState(false);
    const [isErrorImage, setIsErrorImage] = useState(false);
    const [errorCounter, setErrorCounter] = useState(0);
  //   useEffect(() => {
      
  // },[isErrorImage])

  const onLoadStart = useCallback(() => {
    if (errorCounter > 3) return;
    console.log(`onLoadStart in ImageProfile`);
    setIsReloading(true);
  }, [errorCounter]);

  const onLoadEnd = useCallback(() => {
    if (errorCounter > 3) return;
    console.log(`onLoadEnd in ImageProfile`);
    setIsReloading(false);
    setIsErrorImage(false);
  }, [errorCounter]);

  const onLoadError = useCallback(() => {
    console.log(`error on image, imagePic: ${JSON.stringify(imagePic)}`);
    if (!isErrorImage) {
      setErrorCounter(errorCounter + 1);
      setIsErrorImage(true);
    }
  }, [errorCounter, isErrorImage, imagePic]);


  const showImageModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onButtonPress = useCallback(() => {
    if (isTouchable) {
      if (editable === true) {
        pickImageHandler();
      } else {
        showImageModal();
      }
    } else {
      if (onPress !== null) {
        onPress();
      }
    }
  }, [isTouchable, editable, onPress, pickImageHandler, showImageModal]);
    const [modalPopupSrc, setModalPopupSrc] = useState(null);
    const [isModalPopupVisible, setIsModalPopupVisible] = useState(false);


    const imagePickerAfterSelection = async (result) => {
      const isWithCropWeb = responsiveStyles.isWeb;

      if(isWithCropWeb){
        if (!result.cancelled) {
          if(allowsMultipleSelection){
            let URIs = [];
            result.assets.forEach(asset => {
              URIs.push(asset.uri);
            });
            onImagePick(URIs);
          }
          else{
            // 
            if(isWithCrop){
              setModalPopupSrc(result.assets[0].uri);
              setIsModalPopupVisible(true);
            } 
            else{
              onImagePick(result.assets[0].uri);
            }
          }
        }
      }
      else{
        if (result) { // crop
          if(allowsMultipleSelection){
            let URIs = [];
            result.assets.forEach(asset => {
              URIs.push(asset.uri);
            });
            onImagePick(URIs);
          }
          else{
            onImagePick(result.path); // crop
          }
    }
      }
    }
      const pickImageHandler = async () => {
        const hasPermission = await askForPermissions();

        const {pickImage} = Platform.OS === 'web' ? 
        require('../../functions/pickImage.js')
        : require('../../functions/pickImageWithCrop.js');
        if (hasPermission) {
          let result = await pickImage(isAllFiles, allowsMultipleSelection, isCircle);
          await imagePickerAfterSelection(result);
        }
      };
      // console.log(`imageStyle is ${JSON.stringify(imageStyle)}`);
      const slashFeatherSize = (imageStyle?.width && imageStyle?.width > 0) ? imageStyle.width / 3 : 35;


      const onOpen = async () => {
          setModalVisible(true);
        }
        const onClose = async () => {
          setModalVisible(false);
        }


  return (
    <>
      {responsiveStyles.isWeb && modalPopupSrc && (
        <Modal
          visible={isModalPopupVisible}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setIsModalPopupVisible(false)}
        >
          <View style={styles.modalContainer}>
            <ImagePickerPopupWebComponent
            userSettingsState={userSettingsState}
            isCircle={isCircle}
            src={modalPopupSrc}
            setIsModalPopupVisible={setIsModalPopupVisible}
            onClose={async (ImageWithCrop) => {
            // handle and setUri
            onImagePick(ImageWithCrop);
            setIsModalPopupVisible(false);
            }}
            />
          </View>
        </Modal>
      )}
    <TouchableOpacity 
    onPress={onButtonPress}
        activeOpacity={isTouchable ? 0.25 : 1}
        ref={elementRef}
        >
     {
      isErrorImage ?
      <FastImage
      accessibilityLabel={accessibilityLabel}
      key={key}
      style={[
        imageStyle,
        { 
          borderWidth:1, 
          borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor
         }]}
      source={{ uri: errorURI }}
      resizeMode={resizeMode}
      />
      // <View style={[imageStyle, styles.activityIndicator]}>
      //     <Feather name="slash" size={slashFeatherSize} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      // </View>
      :
      isShowReloading && isReloading ?
        <View style={[imageStyle, styles.activityIndicator]}>
          <ActivityIndicator
            size={35}
            color={GlobalStyles[responsiveStyles.ThemeValue]?.ActivityIndicatorColor}
        />
        </View>
        :
         null
      }
      {isVisible && (
        // responsiveStyles.isWeb ? 
        <FastImage
        accessibilityLabel={accessibilityLabel}
        key={key}
          style={[
            imageStyle,
            (isShowReloading && isReloading) || isErrorImage ? styles.isReloadingImageStyle :  null,
            isBackgroundColorBlack ? { backgroundColor:'black' } : null,
            { borderWidth: isBackgroundImage ? 0 : 1, }
          ]}
          source={imagePic}
          onLoadStart={onLoadStart}
          onLoadEnd={onLoadEnd}
          // onError={onLoadError}
          resizeMode={resizeMode}
          />
        )}
        <ImageModal
        userSettingsState={userSettingsState}
        imageUri={isErrorImage ? errorURI : imagePic}
        visible={modalVisible}
        onClose={closeModal}
        downloadable={downloadable}
        chatType="user"
        userName={userName}
      />
      {editable && showEditButton ? (
          <TouchableOpacity style={[styles.editButtonContainer, editButtonContainerStyle]}  onPress={onButtonPress}>
          <Feather iconName="edit-2" name="edit-2" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
      ) : null
      }
      {showOptionButton ? (
      <View style={styles.optionButton}>
        {showCloseButton ?
        <AntDesign name="closecircle" size={24} color={"#b8b8b8"} />
        : <AntDesign name="checkcircle" size={24} color={"#00a984"} />
      }
      </View>
      )
      : null
    }
    <View style={styles.progressBarContainer}>
            <View style={styles.timestampContainer}>
            <View style={{ flexDirection:'row', alignItems:'center' }}>
              <Text style={[styles.groupText]}>{createdTime}</Text>
              </View>
            </View>
          </View>
    
          { showFullScreen ? <FullScreenIcon
          userSettingsState={userSettingsState}
          showFullScreen={true}
          style={{ paddingLeft: 8, backgroundColor: null }}
          onOpen={onOpen}
          />
        : null}
  </TouchableOpacity>
  </>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  optionButton: {
    position: 'absolute',
    left: -15,
    bottom: -5,
    borderWidth:2,
    borderRadius: 50,
  },
  progressBarContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 5,
  },
  timestampContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  editButtonContainer: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    borderWidth:2,
    borderRadius: 50,
    padding: 5,
    backgroundColor: GlobalStyles.colors.Lavender,
  },
  activityIndicator: {
    justifyContent: 'center',
     alignItems: 'center',
    backgroundColor: GlobalStyles.colors.Lavender,
    borderWidth:2,
    borderColor: 'black',
  },
  isReloadingImageStyle: {
    width: 0,
    height: 0,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
export default ImageProfile;
