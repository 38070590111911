import React, { useContext } from 'react';
import { TouchableOpacity, StyleSheet, View, Text, useWindowDimensions } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { GlobalStyles } from '../constants/styles';
import { Context as ShopContext } from '../context/ShopContext';
import useResponsiveStyles from '../functions/ResponsiveStyles';


const StoreMenu = ({ onPress, userSettingsState }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  const {state: shopState } = useContext(ShopContext);
const data = shopState.cart;
const totalQuantity = data.reduce((accumulator, currentItem) => {
  return accumulator + currentItem.quantity;
}, 0);

  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <Feather name="shopping-bag" size={!responsiveStyles.isMobile ? 32 : 24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
      <View style={styles.editButtonContainer}>
      <Text style={[styles.text]}>{totalQuantity}</Text>
        </View>
    </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    alignItems:'flex-end'
  },
  editButtonContainer: {
    position: 'absolute',
    right: 10,
    bottom: -8,
    borderWidth:2, 
    borderRadius: 50,
    backgroundColor: GlobalStyles.colors.Lavender,
    width: !responsiveStyles.isMobile ? 25 : 20,
    height: !responsiveStyles.isMobile ? 25 : 20,
  },
  text: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: !responsiveStyles.isMobile ? responsiveStyles.fontSize_H6 : 10,
    fontFamily: GlobalStyles.fonts.application_font,
},
});

export default StoreMenu;
