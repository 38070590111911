import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import { MaterialIcons } from '@expo/vector-icons';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const RatingAndPurchaseContainer = ({ 
    userSettingsState, 
    foundItem, 
    openDialog,
    quantity,
    setQuantity,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <View style={styles.itemDetailsContainer}>
    <TouchableOpacity 
    activeOpacity={1}
    style={[styles.itemNameContainer,styles.noPointer]} 
    // onPress={openDialog}
    >
    {[...Array(5)].map((_, index) => (
   <MaterialIcons name="star" size={26} color={GlobalStyles.store.iconColor} key={index} />
          ))}
    {/* <Text style={[styles.text, styles.rating]}>{foundItem?.Rating}</Text> */}
    </TouchableOpacity>
    {/* <QuantityStoreItemComponent userSettingsState={userSettingsState} quantity={quantity} setQuantity={setQuantity} /> */}
    </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    itemDetailsContainer: {
        flexDirection: responsiveStyles.isRTL ? "row-reverse" : 'row',
        // justifyContent: 'space-around',
        
        marginBottom: 30,
        // alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'flex-start' : 'flex-end' : null,
      },
      priceContainer: {
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse',
      },
      priceIcon: {
        marginHorizontal: 10,
      },
      itemNameContainer: {
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
      },
      noPointer: {
        pointerEvents: 'none', // Disables mouse interactions
      },
      text: {
        color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
        },
      itemDetails: {
        fontSize: responsiveStyles.fontSize_H4,
        fontWeight: 'bold',
        fontFamily: GlobalStyles.fonts.application_font,
    },
    rating: {
      fontSize: responsiveStyles.fontSize_H5,
      padding: 2,
      fontFamily: GlobalStyles.fonts.application_font,
    },
});

export default RatingAndPurchaseContainer;