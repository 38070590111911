export const RelationshipList = 
[
    {
        Id: 1,
        Value: "Mother",
    },
    {
        Id: 2,
        Value: "Father",
    },
    {
        Id: 3,
        Value: "Wife",
    },
    {
        Id: 4,
        Value: "Husband",
    },
    {
        Id: 5,
        Value: "Sister",
    },
    {
        Id: 6,
        Value: "Brother",
    },
    {
        Id: 7,
        Value: "Aunt",
    },
    {
        Id: 8,
        Value: "Uncle",
    },
    {
        Id: 9,
        Value: "Cousin",
    },
    {
        Id: 10,
        Value: "Daughter",
    },
    {
        Id: 11,
        Value: "Son",
    },
    {
        Id: 12,
        Value: "Nephew",
    },
    {
        Id: 13,
        Value: "Boyfriend",
    },
    {
        Id: 14,
        Value: "Girlfriend",
    },
    {
        Id: 15,
        Value: "Friend",
    },
    {
        Id: 16,
        Value: "Granddaughter",
    },
    {
        Id: 17,
        Value: "Grandson",
    },
    {
        Id: 18,
        Value: "Grandmother",
    },
    {
        Id: 19,
        Value: "Grandfather",
    },
    {
        Id: 20,
        Value: "great grandfather",
    },
    {
        Id: 21,
        Value: "great grandmother",
    },
    {
        Id: 22,
        Value: "Other",
    },
];