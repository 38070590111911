import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, Switch } from "react-native";
import { GlobalStyles, Gold, Lavender } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { MaterialIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { SetUserSettings } from "../../api/apiCalls/AccountCalls";


  const DarkModeComponent = ({ 
    userSettingsState, 
    setUserSettingsDarkMode,
    showText = true,
    showIcon = false,
    containerStyle,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles, showText);

  return <View style={[styles.settingsRow,
    responsiveStyles.isRTL ? { flexDirection:'row-reverse'} : { flexDirection:'row'},
    containerStyle
  ]}>
    {showText ?
  <View style={[styles.settingsRowTextAndIcon,
    responsiveStyles.isRTL ? { flexDirection:'row-reverse'} : { flexDirection:'row'}
  ]}>
  <View style={[styles.settingsRowIcon, { backgroundColor:Lavender }]}>
<Ionicons name="moon-sharp" size={20} color={GlobalStyles[responsiveStyles.ThemeValue].iconColorOpposite} style={{ padding:3 }} />
</View>
   <Text style={[styles.settingsRowText,
  responsiveStyles.isRTL ? { paddingRight:7} : { paddingLeft:7}
  ]}>
    {i18n.t('account.MyAccount_DarkModeText')}
  </Text>
  </View>
   : null}
   { showIcon ? 
   <TouchableOpacity
   onPress={async () => {
    await setUserSettingsDarkMode(!userSettingsState.userSettings.DarkMode);
    await SetUserSettings({ 
        DarkMode: !userSettingsState.userSettings.DarkMode ,
        ThemeValue: !userSettingsState.userSettings.DarkMode === true ? 'dark' : 'light'
    });
  }}
   >
   <MaterialIcons 
   name={userSettingsState.userSettings.DarkMode ? "light-mode" : "dark-mode"} 
   size={24} 
   color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
    />
    </TouchableOpacity>
  :
  <Switch
    trackColor={{false: Lavender, true: Gold}}
    thumbColor={userSettingsState.userSettings.DarkMode ? '#f5dd4b' : '#f4f3f4'}
    ios_backgroundColor="#3e3e3e"
    onValueChange={async () => {
      await setUserSettingsDarkMode(!userSettingsState.userSettings.DarkMode);
    }}
    value={userSettingsState.userSettings.DarkMode}
    style={{ transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }] }}
  />
 }
</View>
}
const styling = (responsiveStyles, showText) => StyleSheet.create({
    settingsRow: {
        alignItems: 'center',
        marginHorizontal:10,
        marginTop: 10,
        justifyContent:'space-between',
        // backgroundColor:'blue',
        paddingHorizontal: !showText ? null : 20,
        
      },
      settingsRowTextAndIcon:{
      },
      settingsRowIcon:{
        borderRadius: 50,
        borderWidth: 2, 
        borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        
      },
      settingsRowText: {
        fontSize: responsiveStyles.fontSize_H4,
        fontWeight: '400',
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontFamily: GlobalStyles.fonts.application_font,
        
      },

});

export default DarkModeComponent;