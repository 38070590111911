import { useState, useEffect, useRef } from 'react';
import { Platform } from 'react-native';

const useLazyLoad = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    // Check if the platform is 'web'
    const isWeb = Platform.OS === 'web';

    if (isWeb) {
      // Web-specific logic
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              console.log(`useLazyLoad (Web), setIsVisible(true);`);
              setIsVisible(true);
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    } else {
      // Non-web-specific logic (e.g., React Native)
      // You may want to handle this differently if needed
      console.log('Lazy loading is not supported on non-web platforms.');
      // elementRef?.current?.measure((fx, fy, width, height, px, py) => {
      //   console.log(`Position on screen: X: ${px}, Y: ${py}, Width: ${width}, Height: ${height}`);
      //   console.log(`Position, Y: ${py}`);
      //   // setTopOfDropDown(py);

      // });
      setIsVisible(true);

      return () => {
        // Clean up if needed
      };
    }
  }, [elementRef]);

  return [isVisible, elementRef];
};

export default useLazyLoad;
