import React, { useRef, useEffect, useState, useContext } from 'react';
import { TouchableOpacity, StyleSheet, Dimensions, View, Text, Animated, Platform, useWindowDimensions } from 'react-native';
import { navigate } from '../navigationRef';
import i18n from '../hooks/useI18n';
import { GlobalStyles } from '../constants/styles';
import { Context as AuthContext } from "../context/AuthContext";
import { Context as UserSettingsContext } from "../context/UserSettingsContext";
import { getNavigationParams, getNavigationRouteName } from '../functions/navigationLogic';
import { navigateToScreen } from '../navigationResponsive';
import { useLocation } from 'react-router-dom';
import { AntDesign } from '@expo/vector-icons';
import DarkModeComponent from './myAccountScreen/DarkModeComponent';
import LanguageComponent from './myAccountScreen/LanguageComponent';
import HamburgerNavigationComponent from './store/HamburgerNavigationComponent';
import useResponsiveStyles from '../functions/ResponsiveStyles';

const HamburgerMenuOpen = ({ 
  onPress, 
  navigation,
  cleanup,
  userSettingsState,
  setIsMenuOpen,
  isMenuOpen,
}) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

    const location = responsiveStyles.isWeb ? useLocation() : null;
    let routeName = getNavigationRouteName(navigation, location);
    const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
    const IsProfileFromStore = (navigationParams?.UserId === "Mother" || navigationParams?.UserId === "Partner");
    

  const slideAnimation = useRef(new Animated.Value(0)).current;
  const [shouldRender, setShouldRender] = useState(false);
  const {signout } = useContext(AuthContext);
  const {setDefaultSettings, setUserSettingsDarkMode, setUserSettingsLanguage } = useContext(UserSettingsContext);

  useEffect(() => {
    if (isMenuOpen) {
      // Slide in animation
      setShouldRender(true);
      Animated.timing(slideAnimation, {
        toValue: 1,
        duration: 500, // Adjust animation duration as needed
        useNativeDriver: true,
      }).start(() => {
        // Animation completed, now render the component
      });
    } else {
      // Slide out animation
      Animated.timing(slideAnimation, {
        toValue: 0,
        duration: 500, // Adjust animation duration as needed
        useNativeDriver: true,
      }).start(() => {
        // Animation completed, now hide the component
        setShouldRender(false);
      });
    }
  }, [isMenuOpen]);

  const outputRangeDimensions = -(responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8);
  // console.log(`outputRangeDimensions is ${outputRangeDimensions}`);
  const menuTranslateX = slideAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [ responsiveStyles.isRTL ? -outputRangeDimensions : outputRangeDimensions, 0],
  });

  if (!shouldRender) {
    return null;
  }

  
  const accountFlow = [
    "MyAccount",
    "CreateProfile",
    "EditProfile",
    "ManageMediaPost",
    "ManageTributePost",
    IsProfileFromStore ? null : "Profile",
    IsProfileFromStore ? null : "ProfileFromAccount",
    IsProfileFromStore ? null : "ImageMediaFromAccount",
    IsProfileFromStore ? null : "ImageMedia",
    "JewleryDetails",
    "JewleryDetailsFromOrder",
    "OrderDetails",
    "AssignJewleryToProfile",
    "ForgotPasswordCodeConfirmationFromAccountScreen",
    "ForgotPasswordConfirmCanChangePasswordFromAccountScreen",
  ];
  const loginFlow = [
    "Signin",
    "googlesignin",
    "SignUpWithEmail",
    "SignUpConfirmEmailCode",
    "SignUpConfirmUserNameAndPasswordByEmail",
    "SignUpConfirmation",
    "ForgotPassword",
    "ForgotPasswordConfirmCanChangePassword",
    "ForgotPasswordCodeConfirmation",
    "LoaderAuth",
  ];
  const discoverFlow = [
    // "ImageMedia",

  ];

  const storeFlow = [
    "Home",
    "ItemDetails",
    "ItemList",
    IsProfileFromStore ? "Profile" : null,
    IsProfileFromStore ? "ImageMedia" : null,
    "Cart",
    "CartInformation",
    "CartShippingInformation",
    "CartPayment",
    "PaymentSuccessful",
    "ItemDetailsFromCart",
    // "AboutUs",
    // "Ambassador",
    // "Help", // not using it cause i already have "Help" in hamburger
    // "Privacy",
    // "ContactUs",
    // "TermsAndConditions",
    // "Tutorial",
    "TutorialExplanation",
  ];


  return (
    <TouchableOpacity
      style={styles.backdrop}
      activeOpacity={1} // Prevents event from passing through
      onPress={(event) => {
        const { locationX, locationY } = event.nativeEvent;
        const { pageX, pageY } = event.nativeEvent;
        
        const TouchX = responsiveStyles.isWeb ? pageX : locationX;
        const TouchY = responsiveStyles.isWeb ? pageY : locationY;
        console.log(`TouchX ${TouchX}, TouchY: ${TouchY}, responsiveStyles.screenWidth: ${responsiveStyles.screenWidth * 0.1} responsiveStyles.isRTL: ${responsiveStyles.isRTL}`);
        if(responsiveStyles.isRTL){
          if (
            TouchX < (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth - responsiveStyles.screenWidth * 0.3) : (responsiveStyles.screenWidth - responsiveStyles.screenWidth * 0.9)) || // Touch outside right edge
            TouchY < 0 || // Touch above the menu
            TouchY > responsiveStyles.screenHeight // Touch below the menu
          ) {
            setIsMenuOpen(false);
          }
        }
        else if (
          TouchX < 0 || // Touch outside left edge
          TouchX > (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8) || // Touch outside right edge
          TouchY < 0 || // Touch above the menu
          TouchY > responsiveStyles.screenHeight // Touch below the menu
        ) {
          setIsMenuOpen(false);
        }

      }}
    >
      <Animated.View
        style={[
          styles.menuContainer,
          {
            transform: [{ translateX: menuTranslateX }],
          },
        ]}
      >
        <TouchableOpacity 
        onPress={() => {
          setIsMenuOpen(false);
        }}
        style={{width: 24}}
        >
          <AntDesign name="close" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
        </TouchableOpacity>
      
        <HamburgerNavigationComponent
      isHeader={false}
      userSettingsState={userSettingsState}
      navigation={navigation}
      cleanup={cleanup}
      setIsMenuOpen={setIsMenuOpen}
      />  
        {/* <>
        {userSettingsState?.userSettings?.UserId ? 
        <TouchableOpacity 
        onPress={() => {
          setIsMenuOpen(false);
          navigateToScreen(navigation, 'AccountFlow', `MyAccount`, null, null);
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, accountFlow.includes(routeName) ? styles.onFocusText : null]}>
            {i18n.t('menu.MyAccount')}
            </Text>
        </TouchableOpacity>
        : null }
        <TouchableOpacity 
        onPress={() => {
          setIsMenuOpen(false);
          navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, storeFlow.includes(routeName) ? styles.onFocusText : null]}>
            {i18n.t('menu.Store')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity 
        onPress={() => {
          setIsMenuOpen(false);
          navigateToScreen(navigation, 'StoreFlow', `Help`, null, null);
        }}
        style={styles.itemContainer}
        >
          <Text style={[styles.text, routeName === "Help" ? styles.onFocusText : null]}>
            {i18n.t('menu.Help')}
          </Text>
        </TouchableOpacity>
        {userSettingsState?.userSettings?.UserId ? 
          <TouchableOpacity 
          onPress={async () => {
            await signout();
            console.log("setDefaultSettings");
            await setDefaultSettings();
            setIsMenuOpen(false);
            navigateToScreen(navigation, 'LoginFlow', "Signin", null, null);
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, routeName === "SignOut" ? styles.onFocusText : null]}>
              {i18n.t('menu.SignOut')}
            </Text>
          </TouchableOpacity>
          :
          <TouchableOpacity 
          onPress={() => {
            setIsMenuOpen(false);
            navigateToScreen(navigation, 'LoginFlow', "Signin", null, null);
          }}
          style={styles.itemContainer}
          >
            <Text style={[styles.text, loginFlow.includes(routeName) ? styles.onFocusText : null]}>
              {i18n.t('menu.SignIn')}
            </Text>
          </TouchableOpacity>
        }
</> */}

        {/* <View style={styles.settingsContainer}>
        <LanguageComponent
    userSettingsState={userSettingsState} 
    languageContainerStyle={{ paddingLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 20 }}
    setUserSettingsLanguage={(newLocale) => {
      i18n.locale = newLocale.slice(0, 2);
      setUserSettingsLanguage(newLocale);
    }}
    />
<DarkModeComponent
    userSettingsState={userSettingsState} 
    setUserSettingsDarkMode={setUserSettingsDarkMode}
    />
    </View> */}
      </Animated.View>
    </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  backdrop: {
    // position: 'fixed',
    position: responsiveStyles.isWeb ? 'fixed' : 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 999, // Ensure it's below the menu
    height: responsiveStyles.screenHeight,
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    // top: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 83 : 60, // Adjust this value according to your header's height
    left: !responsiveStyles.isRTL ? 0 : null,
    right: responsiveStyles.isRTL ? 0 : null,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8, // Adjust width as needed
    backgroundColor: 'white',
    elevation: 5, // For Android shadow
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: 1000, // Ensure it's above other components
    height: '100%', // Fill the screen height
    padding: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  itemContainer: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  onFocusText: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles.colors.Gold,
  },
  text: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
},
settingsContainer:{ 
  flex:1, 
  justifyContent:'flex-end', 
  width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8,  
  marginLeft: -30,
 }
});

export default HamburgerMenuOpen;
