import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { AntDesign } from '@expo/vector-icons';
import { getImageWidth } from "../../functions/getImageWidth";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const BottomCardComponent = ({ 
    userSettingsState, 
    user,
    displayType, 
    setDisplayType,
    mediaDisplayTypeTribute,
    setMediaDisplayTypeTribute,
    }) => {
  
      const responsiveStyles = useResponsiveStyles(userSettingsState);


      
	const styles = styling(responsiveStyles);

  return (
    <View>
    <View style={styles.bottomCardContainer}>

    {user?.IsPet ?
    <TouchableOpacity 
    style={[displayType === 'Bio' ? styles.onfocus : null,styles.boxContainer,
      // { 
      //   paddingLeft: !responsiveStyles.isRTL ? (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? 140 : responsiveStyles.screenWidth * 0.15 : null,
      //   paddingRight: responsiveStyles.isRTL ? (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? 140 : responsiveStyles.screenWidth * 0.15 : null,
      //  },
    ]}
    onPress={() => {
      console.log();
      setDisplayType('Bio')
    }}
    >
    <Text style={[styles.text, displayType === 'Bio' ? styles.onfocusText : null ]}>{i18n.t(`account.ProfileScreen_BottomCardComponent_Bio`)}</Text>
    </TouchableOpacity>
    : null}
    
    <TouchableOpacity 
    style={[displayType === 'Media' ? styles.onfocus : null,styles.boxContainer,
      // user?.IsPet ? null :
      // { 
      //   paddingLeft: !responsiveStyles.isRTL ? (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? 140 : responsiveStyles.screenWidth * 0.15 : null,
      //   paddingRight: responsiveStyles.isRTL ? (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? 140 : responsiveStyles.screenWidth * 0.15 : null,
      //  },
    ]}
    onPress={() => {
      console.log();
      setDisplayType('Media')
    }}
    >
    <Text style={[styles.text, displayType === 'Media' ? styles.onfocusText : null ]}>{i18n.t(`account.ProfileScreen_BottomCardComponent_Media`)}</Text>
    </TouchableOpacity>

    <TouchableOpacity 
    style={[displayType === 'Tributes' ? styles.onfocus : null,styles.boxContainer,
      // { paddingRight: (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? responsiveStyles.screenWidth * 0.2 : null, },
      // { 
      //   paddingRight: !responsiveStyles.isRTL ? (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? 140 : responsiveStyles.screenWidth * 0.15 : null,
      //   paddingLeft: responsiveStyles.isRTL ? (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? 140 : responsiveStyles.screenWidth * 0.15 : null,
      //  },
    ]}
    onPress={() => setDisplayType('Tributes')}
    >
    <Text style={[styles.text, displayType === 'Tributes' ? styles.onfocusText : null]}>{i18n.t(`account.ProfileScreen_BottomCardComponent_Tributes`)}</Text>
    </TouchableOpacity>
  </View>
      <View 
    style={styles.orderBoxContainer}
    >
      <TouchableOpacity 
      style={[styles.orderContainer]}
      onPress={() => {
        if(displayType === "Media"){
          setMediaDisplayTypeTribute(false);
        }
      }}
      >
      <AntDesign name="appstore-o" size={24} 
      color={
        displayType === "Media" ? 
        !mediaDisplayTypeTribute ? GlobalStyles.colors.selectedItemColor
        : GlobalStyles[responsiveStyles.ThemeValue]?.iconColor 
        : GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor
      } 
      />
      </TouchableOpacity>
      <TouchableOpacity 
      style={[styles.orderContainer]}
      onPress={() => {
        if(displayType === "Media"){
          setMediaDisplayTypeTribute(true);
        }
      }}
      >
      <AntDesign name="bars" size={24} 
      color={
        displayType === "Media" ? 
        mediaDisplayTypeTribute ? GlobalStyles.colors.selectedItemColor
        : GlobalStyles[responsiveStyles.ThemeValue]?.iconColor
        : GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor
      }
      />
      </TouchableOpacity>
      </View>
  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({

  orderContainer: {
    paddingHorizontal: 10
  },
  orderBoxContainer: {
    flex:1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent:'flex-start',
    alignItems:'flex-end', 
    width: getImageWidth(responsiveStyles),
    alignSelf: 'center',
  },
  boxContainer: {
    flex:1,
    alignItems:'center',
  },
  bottomCardContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    marginTop: 20,
    flex:1,
    alignSelf: 'center',
    width: getImageWidth(responsiveStyles),
    // backgroundColor:'blue',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  onfocus:{
    
  },
  onfocusText: {
    color: GlobalStyles.colors.selectedItemColor,
    borderBottomColor: GlobalStyles.colors.selectedItemColor,
    borderBottomWidth: 2,
    fontWeight: '700',
  },
  
});

export default BottomCardComponent;