import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Platform, useWindowDimensions, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import ShoppingCartFullInfoComponent from '../../components/store/ShoppingCartFullInfoComponent';
import BuisnessFooter from '../../components/store/BuisnessFooter';
import GuaranteesGrid from '../../components/store/GuaranteesGrid';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
const CartScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Cart";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

    const [scrollY, setScrollY] = useState(0);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <KeyboardAvoidingView
      behavior={'padding'}
      style={styles.container}
      >
  <ScrollView 
      contentContainerStyle={styles.scrollViewContent}
      keyboardShouldPersistTaps='handled'
      onScroll={(event) => setScrollY(event.nativeEvent.contentOffset.y)} // Track scroll position
    scrollEventThrottle={16} // Ensure smooth tracking of scroll position
  >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View>
      <View style={styles.subContainer}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      <View style={{padding: 20}}>
    <View style={styles.cartContainer}>
    <Text style={[styles.text, styles.title]}>{i18n.t('store.CartScreen_Title')}</Text>
    </View>
    <View>
     <ShoppingCartFullInfoComponent
     userSettingsState={userSettingsState} 
     navigation={navigation}
     isFromCartScreen={true}
     />
     </View>

</View>

      </View>
<GuaranteesGrid 
userSettingsState={userSettingsState} 
scrollY={scrollY}
 />
<BuisnessFooter
userSettingsState={userSettingsState}
navigation={navigation}
/>
      </View>
      </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    </Screen>

}

CartScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // padding: 20,
    height: fixedScreenHeight,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  subContainer: {
    flex: 1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : responsiveStyles.screenWidth * 0.8,
    alignSelf:'center',
  },
  cartContainer: {
    flexDirection: 'row',
    justifyContent:'center',
    paddingBottom: 20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H1,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
},
});

export default CartScreen;
