import { Platform } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import { getVideoDurationInTimeSpan } from "./getVideoDurationInTimeSpan";

export const askForPermissions = async () => {
    if (Platform.OS !== 'web') {
      // const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY, Permissions.CAMERA);
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        alert('Permission to access media and camera is required!');
        return false;
      }
    }
  
    return true;
  };
  export const pickImage = async (isAllFiles, allowsMultipleSelection) => {
    console.log(`pickImage`);
    const hasPermission = await askForPermissions();
    
    if (hasPermission) {
      console.log(`before let result = await ImagePicker.launchImageLibraryAsync({`);
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: isAllFiles ? ImagePicker.MediaTypeOptions.All : ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
        allowsMultipleSelection: allowsMultipleSelection
      });
  
      console.log(result);
      return result;
    }
    else{
      console.log(`!hasPermission`);
    }
  };
  export const calculateBase64FileSize = (asset) => {
    try{
      if(Platform.OS === "android" || Platform.OS === "ios"){
        let fileSizeInBytes = asset.filesize ? asset.filesize : asset.fileSize;
        let fileSizeInMB = fileSizeInBytes / (1024 * 1024);
        return fileSizeInMB;
      }
      else{
        const base64String = asset.uri.split(',')[1]; // Extract Base64 data
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const fileSizeInBytes = rawData.length;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
    
        return fileSizeInMB;
      }
    }
    catch(error){
      console.log(error);
      return 0;
    }
};
  export const pickImageHandler = async (isAllFiles, allowsMultipleSelection) => {
    try{
      console.log(`pickImageHandler`);
      const hasPermission = await askForPermissions();
      console.log(`hasPermission ${hasPermission}`);
      if (hasPermission) {
        let result = await pickImage(isAllFiles, allowsMultipleSelection);
        if (!result.cancelled) {
          if(allowsMultipleSelection){
            let URIs = [];
            let Assets = [];
            result.assets.forEach(asset => {
              console.log(`asset is ${JSON.stringify(asset)}`);
              const fileSizeInMB = calculateBase64FileSize(asset);

              console.log(`fileSizeInMB is ${fileSizeInMB}`);
              console.log(`asset.fileSize ${asset.fileSize}`);
              // const durationOfWeb = Platform.OS === 'web' ?  getVideoDurationInTimeSpan(asset) : null
              // console.log(`durationOfWeb ${JSON.stringify(durationOfWeb)}`);

              URIs.push(asset.uri);
              Assets.push(asset);
            });
            return Assets;
            // return URIs;
          }
          else{
              return result.assets[0];
              // return result.assets[0].uri;
          }
        }
      }
      else{
        console.log(`!hasPermission`);
      }
    }
    catch(error){
      console.log(`error in pickImageHandler, error: ${error}`);
    }
  };