export default {
    header: "Payment Successful!",
    thankYouNote1: `Thank you,`,
    thankYouNote2: `, for your purchase! Your payment has been processed successfully.`,
    orderTitle: `Order Summary`,
    orderNumber: `Order Number:`,
    orderDate: `Order Date:`,
    amountPaid: `Amount Paid:`,
    ItemsPurchased: `Items Purchased:`,
    total: `Total:`,
    nextSteps: `Your order is being prepared and will be shipped to you soon. You will receive an email with the tracking information once your order has been dispatched.`,
    thankYouNoteFinal: `We appreciate your business and hope you enjoy your purchase!`,
    continueShoppingButton: `Continue Shopping`,
    orderDetailsButton: `View Order Details`,
    contactInfo: `If you have any questions or need further assistance, please contact our customer support at`,
  };