import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Assistant_Light, GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import moment from "moment";
import { formatDate } from "../../functions/formatDate";
import { Menu, MenuItem } from 'react-native-material-menu';
import { Feather } from '@expo/vector-icons'; 
import useResponsiveStyles from "../../functions/ResponsiveStyles";


  const BirthDateComponent = ({ 
    userSettingsState, 
    user,
    AddRequestUserAdmin,
    CancelRequestUserAdmin,
  }) => {
    
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);

    const formattedBirthDate = formatDate(user.BirthDate);
    const formattedDeathDate = formatDate(user.DeathDate);
    
const [visible, setVisible] = useState(null);
const hideMenu = () => {
  setVisible(false)
};

const showMenu = (Id) => {
  setVisible(true)
};

  const styles = styling(responsiveStyles);
  return (
    <View style={styles.birthDateContainer}>
            <Text style={styles.birthDate}>
              {formattedBirthDate == null || formattedBirthDate === "NaN/NaN/NaN" ? user.BirthDate : formattedBirthDate}
              </Text>
            {user.DeathDate?.length > 0 ? 
              <>
            <Text style={styles.birthDate}> - </Text>
            <Text style={styles.birthDate}>
            {formattedDeathDate == null || formattedDeathDate === "NaN/NaN/NaN" ? user.DeathDate : formattedDeathDate}
              </Text>
              </>
              : null
            }
            
            {/* { !user.IsUserHaveRole ? <Menu
          visible={visible}
          anchor={
        <TouchableOpacity 
         onPress={() => showMenu()}
         style={styles.menu}
        >
        <Feather name="more-vertical"  size={18} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
        </TouchableOpacity>
          
        }
        style={{borderRadius:10}}
          onRequestClose={hideMenu}
        >
      <View style={styles.menuContainer}>
        {
          !user.IsSendAdminRequest ? 
        
      <MenuItem textStyle={styles.menuItemText} onPress={() => {
        hideMenu();
        AddRequestUserAdmin();
      }}
      >
        {i18n.t(`account.BirthDateComponent_RequestAdmin`)}
      </MenuItem>
      :
      <MenuItem textStyle={styles.menuItemText} onPress={() => {
        hideMenu();
        CancelRequestUserAdmin();
      }}
      >
        {i18n.t(`account.BirthDateComponent_CancelRequestAdmin`)}
      </MenuItem>
  }
      
      </View>
      </Menu> : null} */}
          </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  birthDateContainer: {
    flexDirection:'row',
    marginBottom: 5,
    justifyContent:'center',
    paddingBottom: 10,
  },
  birthDate: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: Assistant_Light,
  },
  menu: {
    // backgroundColor: 'blue',
    paddingTop: 3,
    // marginLeft: 5,
  },
  menuContainer:{
    
    backgroundColor:GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor, 
    borderWidth:1,
     borderColor:GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor, 
     borderRadius: 10,

    
},
menuItemText:{
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
});

export default BirthDateComponent;