export default {
    MyAccount: "My account",
    Discover: "Discover",
    Store: "Store",
    Help: "Help",
    SignIn: "Sign in",
    SignOut: "Sign out",
    Orders: "Orders",
    FinancialAnalysis: "Financial Analysis",
    Tutorial: "Tutorials",
  };