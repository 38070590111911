import AsyncStorage from "@react-native-async-storage/async-storage";
import { AllUnreadMessages_Storage, notes_Storage } from "./StorageObjects";

export const saveString = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
    return true;
  } catch (error) {
    return false;
  }
};

export const saveAsync = async (key, value) =>
  saveString(key, JSON.stringify(value));

export const getItemAsync = async key => {
  try {
    const itemString = await AsyncStorage.getItem(key);
    if (itemString) {
      return JSON.parse(itemString);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
export const getItem = async key => {
  try {
    const itemString = await AsyncStorage.getItem(key);
    if (itemString) {
      return JSON.parse(itemString);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
export const getNotesFromStorage = async () => {
  try {
    console.log("func getNotesFromStorage");
    const notes = await getItem(notes_Storage);
    console.log(`notes from getNotesFromStorage is ${JSON.stringify(notes)}`);
    return notes ? notes : [];
  } catch (error) {
    console.error('Error retrieving notes:', error);
    return [];
  }
};
export const saveNotesToStorage = async (notes) => {
  try {
    console.log(`saveNotesToStorage, notes: ${JSON.stringify(notes)}`);
    await saveAsync(notes_Storage,notes);
  } catch (error) {
    console.error('Error saving notes:', error);
  }
};


export const getUnreadMessagesFromStorageAsync = async () => {
  try {
    const AllUnreadMessages = await getItem(AllUnreadMessages_Storage);
    // console.log(`notes from getUnreadMessagesFromStorage is ${JSON.stringify(AllUnreadMessages)}`);
    return AllUnreadMessages ? AllUnreadMessages : [];
  } catch (error) {
    console.error('Error retrieving AllUnreadMessages:', error);
    return [];
  }
};

export const CleanUnreadMessagesToStorage = async (NewUnreadMessages) => {
  try {
    await saveAsync(AllUnreadMessages_Storage, []);
  } catch (error) {
    console.error('Error saving unread messages:', error);
  }
};
export const AddUnreadMessagesToStorage = async (NewUnreadMessages) => {
  try {
    let AllUnreadMessages = await getUnreadMessagesFromStorageAsync();
    NewUnreadMessages.forEach(newMessage => {
      // Check if the message.Id already exists in AllUnreadMessages
      const exists = AllUnreadMessages.some(message => message.Id === newMessage.Id);
      if (!exists) {
        // Add the new unread message to AllUnreadMessages
        AllUnreadMessages.push(newMessage);
      }
    });
    await saveAsync(AllUnreadMessages_Storage, AllUnreadMessages);
  } catch (error) {
    console.error('Error saving unread messages:', error);
  }
};

export const RemoveUnreadMessagesToStorage = async (MessagesToRemove) => {
  try {
    console.log(`func RemoveUnreadMessagesToStorage, MessagesToRemove is ${JSON.stringify(MessagesToRemove)}`);
    let AllUnreadMessages = await getUnreadMessagesFromStorageAsync();
    const updatedUnreadMessages = AllUnreadMessages.filter(message => {
      // Check if the message.Id is not included in MessagesToRemove
      return !MessagesToRemove.some(removeMessage => removeMessage.Id === message.Id);
    });
    await saveAsync(AllUnreadMessages_Storage, updatedUnreadMessages);
  } catch (error) {
    console.error('Error saving notes:', error);
  }
};



export default {
  saveString,
  save: saveAsync,
  get: getItemAsync,
};