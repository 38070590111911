import React from "react";
import { StyleSheet, View, TouchableOpacity, Dimensions, ImageBackground } from "react-native";
import ImageProfile from "../images/ImageProfile";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const BackgroundImageComponent = ({ 
  userSettingsState,
  uri = null,
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  return (
    <View style={styles.container}>
      {/* <ImageBackground
        source={{ uri: uri }}
        style={styles.backgroundBlur}
        blurRadius={15} // This adds the blur effect (React Native)
      > */}
        {/* Centered Main Image */}
        <TouchableOpacity style={styles.backgroundImageContainer}>
          <ImageProfile
            userSettingsState={userSettingsState}
            uri={uri}
            imageStyle={styles.backgroundImage}
            editable={false}
            isGroup={false}
            isTouchable={false}
            resizeMode={"contain"}
            isBackgroundImage={true}
          />
        </TouchableOpacity>
      {/* </ImageBackground> */}
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    // position: 'relative',
  },
  backgroundBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: responsiveStyles.screenWidth,
    // aspectRatio: 80 / 29 * 2,
      // aspectRatio: 1512 / 991,
      height: responsiveStyles.screenHeight * 0.5,
  },
  backgroundImageContainer: {
    position:'absolute',
    top: 0,
    // flex: 1,
    // paddingBottom: responsiveStyles.isMobile || responsiveStyles.isTablet ? 40 : null,
  },
  backgroundImage: {
    width: responsiveStyles.screenWidth,
    // aspectRatio: 80 / 29 * 2,
      // aspectRatio: 1512 / 991,
      height: responsiveStyles.screenHeight * 0.5,
      borderBottomEndRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
      borderBottomStartRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,

      
    // width: responsiveStyles.screenWidth,
    // height: 150,
    // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // backgroundColor:'blue'
  },
  // backgroundImageContainer: {
  //   position: 'absolute',
  //   top: 0,
  //   width: responsiveStyles.screenWidth,
  //   height: responsiveStyles.screenHeight * 0.5,
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
  // backgroundImage: {
  //   width: responsiveStyles.screenWidth * 0.5, // Centered image takes 50% of the screen width
  //   height: responsiveStyles.screenHeight * 0.5,
  //   borderBottomEndRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
  //   borderBottomStartRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
  // },
});

export default BackgroundImageComponent;
