import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../../constants/styles";
import i18n from "../../../hooks/useI18n";
import ImageProfile from "../../images/ImageProfile";
import useResponsiveStyles from "../../../functions/ResponsiveStyles";
  const TopRow = ({ 
    userSettingsState,
    user,
 }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  return (
    <View style={styles.topRowContainer}>
    <View style={styles.userNameContainer}>
    <ImageProfile 
    userSettingsState={userSettingsState}
uri={user?.UserImage}
imageStyle={styles.userPhoto}
editable={false}
isGroup={false}
/>
        <Text style={styles.userNameText}>{user?.UserName}</Text>
        </View>
      </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    topRowContainer: {
        // position: 'absolute',
        // alignSelf: 'center',
        width:'100%',
        padding: 5,
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
        alignItems:'center',
    
      },
      userNameContainer:{
        flex:1,
        alignItems:responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
        alignItems:'center',
      },
      userNameText: {
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        marginHorizontal: 20,
        fontFamily: GlobalStyles.fonts.application_font,
        fontWeight: '700',
    
      },
      userPhoto: {
        width: 50,
        height: 50,
        borderRadius: 100,
        borderWidth: 4,
        // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        marginRight: 10,
      },
});

export default TopRow;