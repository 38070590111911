import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Linking } from "react-native";
import { Assistant_Light, GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { shareProfileLink, shareTributesLink } from "../../functions/shareApp";
import { navigateToScreen } from "../../navigationResponsive";
import ShareLocation from "../displayComponent/ShareLocation";

  const ButtonsComponent = ({ 
    navigation,
    userSettingsState, 
    user,
    isAdmin,
    IsMyJewelryScreen,
    UserId,
    JewelryId,
    CancelRequestUserAdmin,
    AddRequestUserAdmin,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  const [loading, setLoading] = useState(false);
  if(isAdmin === true && IsMyJewelryScreen === true){
    return (
      <View style={styles.buttonContainer}>
      <ButtonTypeButton
      userSettingsState={userSettingsState}
      style={styles.button}
      // styleButton={{ backgroundColor: Gold }}
      onPress={() => {
        shareProfileLink(user.UserName,JewelryId);
        // Linking.openURL(`https://timelessmemories-jewelry.com/myjewelry/${JewelryId}`); // jewelryId
      }}
      >
        {i18n.t(`media.ShareProfile`)}
  </ButtonTypeButton>
  <ButtonTypeButton
  userSettingsState={userSettingsState}
      style={styles.button}
      onPress={() => {
        shareTributesLink(user.UserName,UserId);
      }}
      >
        {i18n.t(`media.TributesLink`)}
  </ButtonTypeButton>
  </View>
        );
  }
  else if(isAdmin && !IsMyJewelryScreen){
    return(
    <View style={styles.buttonContainer}>
      <ButtonTypeButton
      userSettingsState={userSettingsState}
      style={styles.button}
      loading={loading}
      // styleButton={{ backgroundColor: Gold }}
      onPress={() => {
        navigateToScreen(navigation, 'AccountFlow', `EditProfile`, { UserId: UserId }, UserId);
      }}
      >
    {i18n.t(`account.BirthDateComponent_EditProfile`)}
  </ButtonTypeButton>
  </View>
    )
  }
  else if(!user.IsUserHaveRole && userSettingsState?.userSettings?.UserId && IsMyJewelryScreen){
    return(
    <View style={styles.buttonContainer}>
      <ButtonTypeButton
      userSettingsState={userSettingsState}
      style={styles.button}
      loading={loading}
      // styleButton={{ backgroundColor: Gold }}
      onPress={async () => {
        let response;
        setLoading(true);
        if(!user.IsSendAdminRequest){
          response = await AddRequestUserAdmin();
        }
        else{
          response = await CancelRequestUserAdmin();
        }
        setLoading(false);
        if(!response.isCompleted){

        }
      }}
      >
    {!user.IsSendAdminRequest ? i18n.t(`account.BirthDateComponent_RequestAdmin`) : i18n.t(`account.BirthDateComponent_CancelRequestAdmin`)}
  </ButtonTypeButton>
  <ShareLocation userSettingsState={userSettingsState} />
  </View>
    )
  }
  else if (user.IsPet){
    return(
      <View style={styles.buttonContainer}>
    <ShareLocation userSettingsState={userSettingsState} />
    </View>
      )
  }
  else {
    return null;
  }
  
}
const styling = (responsiveStyles) => StyleSheet.create({

  buttonContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-around',
    alignItems:'center',
    marginVertical: 10,
    marginHorizontal: (!responsiveStyles.isMobile && !responsiveStyles.isTablet) ? responsiveStyles.screenWidth * 0.2 : null,
  },
  button: {
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.85,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.4,
    width: responsiveStyles.screenWidth * 0.1,
    minWidth: responsiveStyles.isMobile ? 150 : responsiveStyles.isTablet ? 150 : 250,
    // minWidth: responsiveStyles.isMobile ? 100 : responsiveStyles.isTablet ? 150 : 150,
  },
});

export default ButtonsComponent;