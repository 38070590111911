import { Alert, View } from "react-native";
import { useEffect, useState } from "react";
import i18n from "../../hooks/useI18n";
import CustomAlert from "../modals/CustomAlert";

  // function PasswordRequired() {
  //   Alert.alert(i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_subject'),
  //   i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_message'), [
  //       {
  //         text: i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_okButton'),
  //       },
  //     ],
  //     {cancelable:true}
  //     );
  // }
  // function PasswordLengthRequired() {
  //   Alert.alert(null,i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_message'), [
  //       {
  //         text: i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton'),
  //       },
  //     ],
  //     {cancelable:true}
  //     );
  // }
  // function PasswordTooEasy() {
  //   Alert.alert(null,i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_message'), [
  //       {
  //         text: i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_okButton'),
  //       },
  //     ],
  //     {cancelable:true}
  //     );
  // }
  // function CodeNotValid() {
  //   Alert.alert(null,i18n.t('auth.ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_message'), [
  //       {
  //         text: i18n.t('auth.ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_okButton'),
  //       },
  //     ],
  //     {cancelable:true}
  //     );
  // }
// export {PasswordRequired,PasswordLengthRequired,PasswordTooEasy,CodeNotValid};

export const ForgotPasswordConfirmCanChangePasswordAlert = ({ 
  navigation, 
  state, 
  addErrorMessage,
  userSettingsState,
 }) => {
  const [PasswordRequiredVisible, setPasswordRequiredVisible] = useState(false);
    const [PasswordLengthRequiredVisible, setPasswordLengthRequiredVisible] = useState(false);
    const [PasswordTooEasyVisible, setPasswordTooEasyVisible] = useState(false);
    const [CodeNotValidVisible, setCodeNotValidVisible] = useState(false);

    const [ServerMessageVisible, setServerMessageVisible] = useState(false);
    const [MessageFromServer, setMessageFromServer] = useState("");

  useEffect(() => {

      console.log(`useEffect in alertsssss state.forgotPasswordConfirmCanChangePasswordAlertMessage ${state.signinAlertMessage}`);
      if(state.forgotPasswordConfirmCanChangePasswordAlertMessage === "PasswordLengthRequired")
      {
        console.log(`if(state.forgotPasswordConfirmCanChangePasswordAlertMessage === "PasswordLengthRequired")`);
        setPasswordLengthRequiredVisible(true);
      }
      else if(state.forgotPasswordConfirmCanChangePasswordAlertMessage === "PasswordTooEasy")
      {
        setPasswordTooEasyVisible(true)
      }
      else if(state.forgotPasswordConfirmCanChangePasswordAlertMessage === "CodeNotValid")
      {
        setCodeNotValidVisible(true)
      }
      else if(state.forgotPasswordConfirmCanChangePasswordAlertMessage === "PasswordRequired")
      {
        setPasswordRequiredVisible(true)
      }
      else if (state.forgotPasswordConfirmCanChangePasswordAlertMessage?.length > 0)
      {
        console.log("else if (state.forgotPasswordConfirmCanChangePasswordAlertMessage.length > 0)");
        const message = state.forgotPasswordConfirmCanChangePasswordAlertMessage;
        setServerMessageVisible(true);
        setMessageFromServer(message);
      }
      addErrorMessage("forgotPasswordConfirmCanChangePasswordAlertMessage","");
    }, [state.forgotPasswordConfirmCanChangePasswordAlertMessage]);
  
    const hidePasswordLengthRequiredAlert = () => {
      setPasswordLengthRequiredVisible(false);
    };
    const hidePasswordTooEasyAlert = () => {
      setPasswordTooEasyVisible(false);
    };
    const hideCodeNotValidAlert = () => {
      setCodeNotValidVisible(false);
    };
    const hidePasswordRequiredAlert = () => {
      setPasswordRequiredVisible(false);
    };
    const hideServerMessageVisibleAlert = () => {
      setServerMessageVisible(false);
  };
    return (
      <View>
      <CustomAlert
      visible={PasswordRequiredVisible}
      onClosePress={hidePasswordRequiredAlert}
      userSettingsState={userSettingsState}
      headermessage={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_subject')}
      bodymessge={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_message')}
      buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordRequiredAlert_okButton')}
    />
        <CustomAlert
      visible={PasswordLengthRequiredVisible}
      onClosePress={hidePasswordLengthRequiredAlert}
      userSettingsState={userSettingsState}
      bodymessge={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_message')}
      buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton')}
    />
          <CustomAlert
      visible={PasswordTooEasyVisible}
      onClosePress={hidePasswordTooEasyAlert}
      userSettingsState={userSettingsState}
      bodymessge={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_message')}
      buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordTooEasyAlert_okButton')}
    />
      <CustomAlert
        visible={CodeNotValidVisible}
        onClosePress={hideCodeNotValidAlert}
        userSettingsState={userSettingsState}
        bodymessge={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_message')}
        buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_CodeNotValidAlert_okButton')}
      />
          <CustomAlert
        visible={ServerMessageVisible}
        onClosePress={hideServerMessageVisibleAlert}
        userSettingsState={userSettingsState}
        bodymessge={MessageFromServer}
        buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton')}
      />

    </View>
    );
  };
