import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../../context/UserSettingsContext";
import { GlobalStyles } from '../../../constants/styles';
import Screen from "../../../components/Screen";
import i18n from '../../../hooks/useI18n';
import { GenericAlert } from '../../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../../constants/ScreenDimensions';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import useResponsiveStyles from '../../../functions/ResponsiveStyles';

const AmbassadorScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Ambassador";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  >
    <View style={[styles.container]}>
    <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      </View>
    </View>
    </Screen>

}


AmbassadorScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    padding: 20,
    height: fixedScreenHeight,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.8 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
});

export default AmbassadorScreen;
