import { Dimensions, Platform, useWindowDimensions } from 'react-native';

// Get screen dimensions
// const { width, height } = useWindowDimensions();
// const { width } = Dimensions.get('window');
const isWeb = Platform.OS === 'web';

export const transitionTime = 1500;
// Functions to determine responsive design breakpoints
export const isDesktopResponsive = (width) => {
// const width = Dimensions.get('window').width;
  return width >= 1440;
};

export const isLaptopResponsive = () => {
const width = Dimensions.get('window').width;
  return width >= 1024 && width < 1440;
};

export const isTabletResponsive = () => {
const width = Dimensions.get('window').width;
  return width >= 768 && width < 1024;
};

export const isMobileResponsive = () => {
const width = Dimensions.get('window').width;
  return width < 768;
};

export const isWebResponsive = () => {
  return isWeb;
};

// Function to get responsive padding based on screen width
export const getPaddingScreen = (screenWidth) => {
  return isTabletResponsive() ? screenWidth * 0.1 : isWebResponsive() ? screenWidth * 0.2 : screenWidth * 0.05;
};

// Font size adjustment functions
export const getFontSize_H1 = (screenWidth) => {
  if (isDesktopResponsive(screenWidth)) return 48;
  if (isLaptopResponsive(screenWidth)) return 48;
  if (isTabletResponsive(screenWidth)) return 36;
  return 32; // Default for mobile
};

export const getFontSize_H2 = (screenWidth) => {
  if (isDesktopResponsive(screenWidth)) return 40;
  if (isLaptopResponsive(screenWidth)) return 40;
  if (isTabletResponsive(screenWidth)) return 32;
  return 32; // Default for mobile
};

export const getFontSize_H3 = (screenWidth) => {
  if (isDesktopResponsive(screenWidth)) return 32;
  if (isLaptopResponsive(screenWidth)) return 32;
  if (isTabletResponsive(screenWidth)) return 24;
  return 24; // Default for mobile
};

export const getFontSize_H4 = (screenWidth) => {
  if (isDesktopResponsive(screenWidth)) return 24;
  if (isLaptopResponsive(screenWidth)) return 24;
  if (isTabletResponsive(screenWidth)) return 20;
  return 20; // Default for mobile
};

export const getFontSize_H5 = (screenWidth) => {
  if (isDesktopResponsive(screenWidth)) return 20;
  if (isLaptopResponsive(screenWidth)) return 20;
  if (isTabletResponsive(screenWidth)) return 16;
  return 16; // Default for mobile
};

export const getFontSize_H6 = (screenWidth) => {
  if (isDesktopResponsive(screenWidth)) return 16;
  if (isLaptopResponsive(screenWidth)) return 16;
  if (isTabletResponsive(screenWidth)) return 14;
  return 14; // Default for mobile
};