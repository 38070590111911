export default {
    Necklaces: "Necklaces",
    Bracelets: "Bracelets",
    CoupleJewelry: "Jewelry for couples",
    PetsTag: "Pets",
    HomeScreen_HeaderImageContainer_ScanText: `Click me / scan to try`,
    HomeScreen_HeaderImageContainer_Title: `World's first NFC technology jewelry!`,
    HomeScreen_HeaderImageContainer_Description: `Share their memories, photos, videos and more with them.`,
    HomeScreen_OurStoryContainer_SectionTitle: `What is TMJ?`,
    HomeScreen_OurStoryContainer_Section1_1: `At `,
    HomeScreen_OurStoryContainer_Section1_2: `Timeless Memories Jewelry (TMJ)`,
    HomeScreen_OurStoryContainer_Section1_3: `, we believe that the most precious moments in life should be cherished forever. We’re not just crafting jewelry, we’re creating a gateway to your most meaningful memories. Each piece of our sterling silver 925 jewelry comes with a unique, engraved QR code that unlocks a world of memories – photos, videos, and heartfelt messages – captured by the people who mean the most to you.`,
    HomeScreen_OurStoryContainer_Section2: `Imagine gifting your loved one not just a beautiful necklace, but a treasure trove of memories that they can revisit anytime. With a simple scan, they are transported back to the moments that made your bond special. Whether it’s a husband surprising his wife, a child showing love to their mother, or a best friend celebrating your shared journey, TMJ is about more than just jewelry – it’s about keeping those connections alive.`,
    HomeScreen_OurStoryContainer_Section3: `What makes us different? It’s our commitment to combining timeless elegance with modern technology. We understand that a gift should be more than just an object; it should carry a piece of your heart. Our jewelry not only adorns but also tells a story – a story that’s personal, emotional, and, above all, unforgettable.`,
    HomeScreen_OurStoryContainer_Section4: `With TMJ, you’re not just wearing jewelry; you’re wearing memories. We invite you to explore our collection and discover how you can make your moments timeless.`,
    HomeScreen_OurStoryContainer_Slogan: `"TMJ – Where the physical meets the digital, turning memories into treasures that last forever."`,
    HomeScreen_StepContainer_SectionTitle: `Only 3 simple steps`,
    HomeScreen_StepContainer_Section1_Title: `Step`,
    HomeScreen_StepContainer_Section1_Description: `Buy a gift`,
    HomeScreen_StepContainer_Section2_Title: `Step`,
    HomeScreen_StepContainer_Section2_Description: `Create your acount`,
    HomeScreen_StepContainer_Section3_Title: `Step`,
    HomeScreen_StepContainer_Section3_Description: `Set-up a profile for your loved one`,
    HomeScreen_StepMemoriesContainer_Title: `Check us out`,
    HomeScreen_StepMemoriesContainer_Section1_Title: `Parent`,
    HomeScreen_StepMemoriesContainer_Section2_Title: `Human`,
    // HomeScreen_StepMemoriesContainer_Section2_Title: `Partner`,
    HomeScreen_StepMemoriesContainer_Section3_Title: `Best Friend`,
    HomeScreen_StepMemoriesContainer_Section4_Title: `Pet`,
    HomeScreen_linkExistingUserText: `If you want to purchase another jewlery to the same person you can link to an existing person`,
    HomeScreen_StoreContainers_Title: `Products`,
    HomeScreen_StoreContainers_SubTitle: `Join us today and begin celebrating your relationships`,
    HomeScreen_Review1_Name: `David E.`,
    HomeScreen_Review1_Subtitle: `Christmas Gift That Will Last Forever`,
    HomeScreen_Review1_Description: `I gave this to my siblings as a Christmas gift to remember our parents. It is literally a gift to be able to have this to memorialize them every time we visit their final resting place. This product is perfect and we are thankful for it. Thank you.`,
    HomeScreen_Review2_Name: `Nicole D.`,
    HomeScreen_Review2_Subtitle: `A Wonderful Present For My Girlfriend`,
    HomeScreen_Review2_Description: `I gave this to my girlfriend and she LOVED IT!!`,
    HomeScreen_Review3_Name: `Maya S.`,
    HomeScreen_Review3_Subtitle: `A Special Thing For My Mom`,
    HomeScreen_Review3_Description: `For the 50th birthday of my mom, me and my 2 brothers decided to buy this to our mother. we uploaded ALL of our memories, photos, videos and even old audio recording and she fell in love.`,
    HomeScreen_GuaranteesGrid_ThumbsUp: `Hassle-Free Returns`,
    HomeScreen_GuaranteesGrid_Calendar: `Free Lifetime Warranty`,
    HomeScreen_GuaranteesGrid_DoneOutline: `100% Satisfaction Guaranteed`,
    HomeScreen_GuaranteesGrid_StarBorder: `Worldwide Shipping`,
    "" : "",
    StoreBreadcrumbs_Home: `Home`,
    CartEmptyListComponent_Title: "Your cart is currently empty.",
    CartBreadcrumbs_Cart: `Cart`,
    CartBreadcrumbs_Information: `Information`,
    CartBreadcrumbs_Shipping: `Shipping`,
    CartBreadcrumbs_Payment: `Payment`,
    OrderSummeryComponent_Show: `Show`,
    OrderSummeryComponent_Hide: `Hide`,
    OrderSummeryComponent_SummeryText: `order summery`,
    AddToCart: `Add to cart`,
    ChooseOptions: `Choose options`,
    ItemDetailsScreen_Description_text1: `Sterling silver 925 with NFC technology`,
    ItemDetailsScreen_Description_text2: `Approximate dimensions of the jewelry: Length: 45 cm`,
    ItemDetailsScreen_DescriptionContainer_text1: `Share unlimited photos, videos, memories, tributes and more`,
    ItemDetailsScreen_DescriptionContainer_text2: `Share with family and friends to gather even more memories`,
    ItemDetailsScreen_DescriptionContainer_text3: `You can decide which profile user you want to assign`,
    JewelryLengthContainer_Chain_text1: `Chain length in cm`,
    JewelryLengthContainer_Bracelet_text1: `Bracelet length in cm`,
    JewelryLengthContainer_Ring_text1: `Ring length in cm`,

    ItemDetailsScreen_DetailsContainer_sizeGuide_header: `Size Guide`,
    ItemDetailsScreen_DetailsContainer_details_header: `Details`,
    ItemDetailsScreen_DetailsContainer_description_header: `Description`,
    ItemDetailsScreen_DetailsContainer_materials: `Materials`,
    ItemDetailsScreen_DetailsContainer_materials_text1: `Sterling silver 925.`,
    ItemDetailsScreen_DetailsContainer_dimensions: `Dimensions`,
    ItemDetailsScreen_DetailsContainer_dimensions_chain: `Chain length`,
    ItemDetailsScreen_DetailsContainer_dimensions_chain_text1: "centimeter.",
    ItemDetailsScreen_DetailsContainer_dimensions_pendant: "pendant size",
    ItemDetailsScreen_DetailsContainer_dimensions_pendant_text1: "millimeter.",
    ItemDetailsScreen_DetailsContainer_weight: "Weight",
    ItemDetailsScreen_DetailsContainer_weight_text1: "milligram.",

    BraceletSizeGuide_mm: `mm`,
    BraceletSizeGuide_in : `in.`,
    BraceletSizeGuide_cm : `cm`,
    BraceletSizeGuide_sizeGuide_rings : `Rings`,
    BraceletSizeGuide_sizeGuide_bracelets  : `Bracelets`,
    BraceletSizeGuide_sizeGuide_necklacesAndPendants : `Necklaces & Pendants`,
    BraceletSizeGuide_sizeGuide_usSize : `US Size`,
    BraceletSizeGuide_sizeGuide_insideCircumference: `Inside Circumference`,
    BraceletSizeGuide_sizeGuide_braceletsHeader: `Bracelets`,
    BraceletSizeGuide_sizeGuide_wristMeasurements: `Wrist Measurements`,
    BraceletSizeGuide_sizeGuide_lengthIn: `'Length (in)`,
    BraceletSizeGuide_sizeGuide_lengthCm: `Length (cm)`,

    
        faqs_header: "FAQs",
        faq_nfc_devices: "Which devices support the use of NFC?",
        faq_nfc_devices_answer: "NFC functionality is supported on most modern smartphones. Android devices have supported NFC in earlier versions, while iPhone devices have supported it starting from iPhone 7 with iOS 11 and above.",
        faq_nfc_usage: "What can be done with a piece of jewelry containing NFC?",
        faq_nfc_usage_answer: "The jewelry offers access to innovative technology that allows quick scanning of the NFC chip to view the user's most cherished memories.",
        faq_nfc_scan: "Can anyone scan the jewelry?",
        faq_nfc_scan_answer: "Yes, anyone with an NFC-enabled smartphone can scan the jewelry.",
        faq_custom_design: "Can special custom designs be ordered?",
        faq_custom_design_answer: "Currently, we do not offer custom designs as a standard service. However, you can contact us for details on custom design options for an additional fee.",
        faq_shipping_time: "How long does shipping take?",
        faq_shipping_time_answer: "Currently, shipping may take up to 30 days from the date of the order.",
        faq_warranty: "Is there a warranty on the jewelry?",
        faq_warranty_answer: "Yes, there is a six-month warranty on the jewelry from the moment it is received by the customer.",
      
      
  

    ItemDetailsScreen_EngravingTextContainer_frontSideText_label: `Text in the front`,
    ItemDetailsScreen_EngravingTextContainer_backSideText_label: `Text in the back`,
    ItemDetailsScreen_EngravingTextContainer_chainSize_label: `Chain size`,
    ItemDetailsScreen_EngravingTextContainer_braceletSize_label: `Bracelet size`,
    ItemDetailsScreen_EngravingTextContainer_ringSize_label: `Ring size`,
    ItemDetailsScreen_EngravingTextContainer_note: `Max letters are 20`,


    ItemDetailsScreen_DevileryDetailsContainer_Location: `Tel Aviv`,
    ItemDetailsScreen_DevileryDetailsContainer_FreeDeliveryShipping: `Free Devilery`,
    MaterialColorPickerContainer_title: `Material color`,
    MaterialColorPickerContainer_Silver: `Silver`,
    MaterialColorPickerContainer_Gold: 'Gold',
    PendentColorPickerContainer_title: `Cover color`,
    PendentColorPickerContainer_MotherOfPearl: `Mother of pearl`,
    PendentColorPickerContainer_Malachite: `Malachite`,
    PendentColorPickerContainer_BlackAgate: `Black Agate`,
    PendentColorPickerContainer_RedAgate: `Red Agate`,
    PendentColorPickerContainer_LapisLazuli: `Lapis Lazuli`,
    PendentColorPickerContainer_TigersEye: `Tiger's Eye`,

    CartScreen_Title: `Your cart`,
    FloatingCartHeaderComponent_Title: `Your cart`,
    DiscountComponent_Title: `Discount code`,
    DiscountComponent_SubmitButton: `Apply code`,
    DiscountComponent_Lable: `discount code`,
    TotalItemsComponent_Subtotal: `Subtotal`,
    TotalItemsComponent_Discount: `Discount`,
    TotalItemsComponent_DiscountCode: `Discount code`,
    TotalItemsComponent_Shipping: `Shipping`,
    TotalItemsComponent_Total: `Total`,
    TotalItemsComponent_Items: `items`,
    CheckoutButtonComponent_ButtonText: `check out -`,
    CartInformationScreen_Title: `Shipping address`,
    CartInformationScreen_Inputs_Country: `Country`,
    CartInformationScreen_Inputs_Country_ErrorMessage: `Enter a country`,
    CartInformationScreen_Inputs_FirstName: `FirstName`,
    CartInformationScreen_Inputs_FirstName_ErrorMessage: `Enter a first name`,
    CartInformationScreen_Inputs_LastName: `LastName`,
    CartInformationScreen_Inputs_LastName_ErrorMessage: `Enter a last name`,
    CartInformationScreen_Inputs_Email: "Email",
    CartInformationScreen_Inputs_Email_ErrorMessage: "Enter an email",
    CartInformationScreen_Inputs_Address: `Address`,
    CartInformationScreen_Inputs_Address_ErrorMessage: `Enter an address`,
    CartInformationScreen_Inputs_Apartment: `Apartment (optional)`,
    CartInformationScreen_Inputs_PostalCode: `PostalCode`,
    CartInformationScreen_Inputs_City: `City`,
    CartInformationScreen_Inputs_City_ErrorMessage: `Enter a city`,
    CartInformationScreen_Inputs_Phone: `Phone (optional)`,
    CartInformationScreen_SubmitButton: `Continue to shipping`,
    CartInformationScreen_NextButton: "Continue",
    CartInformationScreen_CancelButton: "Cancel",
    CartInformationScreen_LoginRequired_bodymessge: `In order to complete the order you need to login`,
    CartInformationScreen_LoginRequired_buttonmessage1: `Login`,
    CartInformationScreen_LoginRequired_buttonmessage2: `Cancel`,
    CartShippingInformationScreen_Title: `Shipping Method`,
    CartShippingInformationScreen_SubmitButton: `Continue to payment`,
    shippingInternational: `International Flat Rate`,
    OrderShippingContainer_ShipTo: `Ship to`,
    OrderShippingContainer_ChangeButton: `Change`,
    OrderShippingContainer_ShippingMeathod_Title: `Shipping meathod`,
    CartPaymentScreen_Title: `Payment`,
    CartPaymentScreen_Inputs_CardNumber : `CardNumber`,
    CartPaymentScreen_Inputs_CardNumber_ErrorMessage : `Enter a CardNumber`,
    CartPaymentScreen_Inputs_NameOfCard : `Name on card`,
    CartPaymentScreen_Inputs_NameOfCardr_ErrorMessage : `Enter a name on card`,
    CartPaymentScreen_Inputs_ExpirationDate : `Expiration date(MM/YY)`,
    CartPaymentScreen_Inputs_ExpirationDate_ErrorMessage  : `Enter an expiration date`,
    CartPaymentScreen_Inputs_SecurityCode : `Security code`,
    CartPaymentScreen_Inputs_SecurityCode_ErrorMessage : `Enter a security code`,
    CartPaymentScreen_SubmitButton: `Place order`,
    ItemListEmptyListComponent_Title: `we currently have no items in this category`,
    ItemListEmptyListComponent_SubTitle1: `Click`,
    ItemListEmptyListComponent_SubTitle2: `here`,
    ItemListEmptyListComponent_SubTitle3: `to check our new and updated items!`,
    HomeScreen_: ``,
   };