import { isAfter, parseISO } from 'date-fns';

  export function isFirstCharHebrew(str) {
    try{
      if(str)
      {
        let letter = str.charAt(0);
        if(letter === "*" && str.length > 1){
          letter = str.charAt(1);
          if(letter === "" || letter === " " && str.length > 2){
            letter = str.charAt(2);
          }
        }
        const hebrewRegEx = /^[\u0590-\u05FF]/; // Regular expression to match Hebrew characters
        const res = hebrewRegEx.test(letter); // Test if the first character of the string matches the Hebrew regular expression
        // console.log(`func isFirstCharHebrew, str: ${str}, res is ${res}`);
        return res;
      }
      else{
        return false;
      }
    }
    catch(err){
      console.log(`func isFirstCharHebrew failed, err: ${err}`);
    }
  }
  export function isDateGreaterThanCurrentDate(date = undefined) {
    try{
      return true;
      if(date === undefined)
      {
        return false;
      }
      const currentDate = new Date();
      const parsedDate = parseISO(date);
      let isafter = false;
      if(parsedDate === undefined || parsedDate === null || parsedDate.toString() === "Invalid Date"){
        isafter = isAfter(date, currentDate);
      }
      else{
        isafter = isAfter(parsedDate, currentDate);
      }
      console.log(`
      isDateGreaterThanCurrentDate
      date: ${date}
      parsedDate: ${parsedDate}
      currentDate: ${currentDate}
      isafter: ${isafter}
      `);
      return isafter;
    }
    catch(err){
      console.log(`func isDateGreaterThanCurrentDate failed, err: ${err}`);
      return false;
    }
  };
  export const hasLineBreakOrExceedsLength = (str) => {
    if(str === null || str === undefined){
      return false;
    }
    // Check if the string contains a line break (\n)
    const hasLineBreak = str.includes('\n');
  
    // Check if the string length exceeds 30 letters
    const exceedsLength = str.length > 30;
  
    // Return true if the string contains a line break or exceeds 30 letters, otherwise false
    return hasLineBreak || exceedsLength;
  };
  export const isNumeric = (str, exclude = null) => {
    if (str === null || str === undefined) {
      return false;
    }
  
    let strTest = str;
    if (exclude !== null && exclude !== undefined) {
      // Remove excluded characters from the string
      const excludeRegex = new RegExp(`[${exclude}]`, 'g');
      strTest = str.replace(excludeRegex, '');
    }
  
    // Use a regular expression to check if the string consists of only digits
    const res = (/^\d+$/.test(strTest));
    console.log(`isNumeric, res: ${res}`);
    return res;
  };

  export const isValidGuid = (guid) => {
    // Define the regex pattern for a GUID
    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(guid);
  };
  
  export const calculateAge = (birthDate) => {
    try{
        const today = new Date();
        const birth = new Date(birthDate);
      
        let ageInYears = today.getFullYear() - birth.getFullYear();
        let ageInDays = Math.floor((today - birth) / (1000 * 60 * 60 * 24)); // Total age in days
      
        // Adjust if the birthday hasn't occurred yet this year
        const monthDiff = today.getMonth() - birth.getMonth();
        const dayDiff = today.getDate() - birth.getDate();
      
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          ageInYears--;
        }
      
        // Calculate days in the current year
        const thisYearBirthday = new Date(today.getFullYear(), birth.getMonth(), birth.getDate());
        const daysSinceLastBirthday = Math.floor((today - thisYearBirthday) / (1000 * 60 * 60 * 24));
      
        if (daysSinceLastBirthday < 0) {
          const previousYearBirthday = new Date(today.getFullYear() - 1, birth.getMonth(), birth.getDate());
          ageInDays = Math.floor((today - previousYearBirthday) / (1000 * 60 * 60 * 24));
        }
      
        return { years: ageInYears, days: ageInDays };
    }
    catch(err){
      return { years: 0, days: 0 };
    }
  };