
import { Platform } from "react-native";


export const getwidthOfImage = (responsiveStyles) => {
    const widthOfImage = 
    !responsiveStyles.isMobile ? (responsiveStyles.screenWidth / 3) - 5 
    : (responsiveStyles.screenWidth / 3) - 5;
    return widthOfImage;
    };

export const getImageWidth = (responsiveStyles) => {
    const widthOfImage = getwidthOfImage(responsiveStyles);
    const width = 
    // responsiveStyles.isWeb ? 
    !responsiveStyles.isMobile ? 
    ((widthOfImage < responsiveStyles.screenWidth * 0.2 ? widthOfImage : responsiveStyles.screenWidth * 0.2) + 5) * 3 
    : ((widthOfImage < 300 ? widthOfImage : 300) + 5) * 3;
    return width;
    };