import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { GetOrderById } from '../../api/apiCalls/ProfileCalls';
import OrderItemsListComponent from '../../components/myAccountScreen/OrderItemsListComponent';
import OrderItemsListDetailsComponent from '../../components/myAccountScreen/OrderItemsListDetailsComponent';
import { formatDate } from '../../functions/formatDate';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import NotFoundComponent from '../../components/NotFoundComponent';
import { isValidGuid } from '../../functions/utils';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
const OrderDetailsScreen = ({  }) => {

  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);


  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const Id = navigationParams?.Id || Number(IdFromURL);
  const IsIdValid = Number(Id);

    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const [order, setOrder] = useState(null);


    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);


    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Order Details";
      }
      const fetchData = async () => {
        try {
          setLoading(true);
          const req = await GetOrderById(Id);
          if (req.isCompleted) {
            const vm = JSON.parse(req.answer);
            setOrder(vm);
          }
          else{
            setLoading(true);
            setErrorOnRequest(true);
          }
        } catch (err) {
          console.log(err);
          setLoading(true);
          setErrorOnRequest(true);
        }
        finally{
          setLoading(false);
        }
      };
      if(isFirstTime)
        {
          fetchData();
          setIsFirstTime(false);
        }
        else{
          // setLoading(true);
          setErrorOnRequest(false);
          if(errorOnRequest === true)
          {
            fetchData();
          }
        }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[reloadpage]);
    
    const formattedDate = formatDate(order?.OrderDate);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    {
    !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
    : errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
    <ScrollView style={[styles.container]}>
      <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{i18n.t('account.OrderDetailsScreen_Title')}</Text>
    </View>

    <View style={styles.rowContainer}>
      <Text style={styles.text}>{i18n.t('account.OrderDetailsScreen_OrderNumber')}:</Text>
      <Text style={styles.text}>#{order.OrderNumber}</Text>
    </View>

    <View style={styles.rowContainer}>
      <Text style={styles.text}>{i18n.t('account.OrderDetailsScreen_OrderDate')}:</Text>
      <Text style={styles.text}>{formattedDate == null || formattedDate === "NaN/NaN/NaN" ? order.OrderDate : formattedDate}</Text>
    </View>

    <OrderItemsListDetailsComponent 
    userSettingsState={userSettingsState} 
    data={order.Jewlery}
    navigation={navigation}
    isOrderDetails={true}
    />
    <View style={styles.rowContainer}>
    <Text style={[styles.text, { fontSize: responsiveStyles.fontSize_H4, fontFamily: GlobalStyles.fonts.application_font, }]}>{i18n.t('account.OrderDetailsScreen_Total')}:</Text>
      <Text style={[styles.text, { fontSize: responsiveStyles.fontSize_H4, fontFamily: GlobalStyles.fonts.application_font, }]}>{order.Total} ₪</Text>
    </View>
    </View>
    </ScrollView>
    )}
    </Screen>

}

OrderDetailsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    padding: 20,
    height: fixedScreenHeight,
  },
  subContainer: {
    flex: 1,
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.7
     : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  titleContainer: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
},
title: {
  fontSize: responsiveStyles.fontSize_H3,
    fontWeight: '700',
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
JewleryName: {
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  textAlign: 'center',
  fontSize: responsiveStyles.fontSize_H4,
  fontFamily: GlobalStyles.fonts.application_font,
  // paddingTop: 10,
  // fontWeight: '700',
},
text: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
rowContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
  flex:1,
  paddingHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : null,
  paddingTop: 10,
  paddingBottom: 10,
},
});

export default OrderDetailsScreen;
