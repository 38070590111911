import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Assistant_Light, GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { calculateAge } from "../../functions/utils";

  const PetContainersComponent = ({ userSettingsState, user }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  const age = calculateAge(user.BirthDate);
  if(user.IsPet){
    return (
      <View style={styles.conatainer}>
      <View style={styles.boxContainer}>
          <Text style={styles.description}>{user.Weight} {i18n.t(`account.PetContainersComponent_WeightType`)}</Text>
          <Text style={styles.description}>{i18n.t(`account.PetContainersComponent_Weight`)}</Text>
      </View>
      <View style={styles.boxContainer}>
          <Text style={styles.description}>{age.years} {i18n.t(`account.PetContainersComponent_Years`)}</Text>
          <Text style={styles.description}>{i18n.t(`account.PetContainersComponent_Age`)}</Text>
      </View>
      <View style={styles.boxContainer}>
          <Text style={styles.description}>{
          user.Gender === "Girl" ? 
          i18n.t('account.MyAccount_ManagePetProfileForm_Gender_Girl')
          : i18n.t('account.MyAccount_ManagePetProfileForm_Gender_Boy')
          }</Text>
          {/* <Text style={styles.description}>{i18n.t(`account.PetContainersComponent_Gender`)}</Text> */}
      </View>
      </View>
        );
  }
  else{
    return null;
  }
}
const styling = (responsiveStyles) => StyleSheet.create({
  conatainer: {
    flexDirection: !responsiveStyles.isRTL ? 'row' : 'row-reverse',
    // backgroundColor:'blue',
    justifyContent:'center',
    marginBottom: 15,
    paddingBottom: 10,
  },
  boxContainer: {
    borderWidth: 1,
    borderColor: GlobalStyles.colors.Gold,
    padding: 20,
    marginHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 40 : 10,
    borderRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 25 : 15,
    justifyContent:'center',
    // borderColor: GlobalStyles.colors.Lavender,
    minWidth: responsiveStyles.screenWidth < 420 ? responsiveStyles.screenWidth * 0.2 : 110,
    backgroundColor: GlobalStyles.colors.Lavender,
  },
  description: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: Assistant_Light,
    fontWeight: '700'
  },
});

export default PetContainersComponent;