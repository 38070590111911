import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../constants/styles";
import { navigateToScreen } from "../navigationResponsive";
import { SecondaryLogo } from "../constants/images";
import useResponsiveStyles from "../functions/ResponsiveStyles";
import { Image } from "react-native";
import { headerHeight } from "../constants/ScreenDimensions";

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

  const ImageHeaderComponent = ({ 
    userSettingsState,
    navigation,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
    const isMainLogo = responsiveStyles.isMobile || responsiveStyles.isTablet;
    const isMobile = responsiveStyles.isMobile || responsiveStyles.isTablet;
    return (
      <TouchableOpacity 
      onPress={() => {
        navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
        // navigateToScreen(navigation, 'TestScreen', `TestScreen`, null, null);
      }}
      style={{ 
        flex: 1, 
        // backgroundColor:'blue', 
        justifyContent:'center', 
        marginLeft:
        responsiveStyles.isMobile || responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.07 :
         isMainLogo ? null : responsiveStyles.isRTL ? null : responsiveStyles.screenWidth * 0.05,
        marginRight: 
        responsiveStyles.isMobile || responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.07 :
        isMainLogo ? null : !responsiveStyles.isRTL ? null : responsiveStyles.screenWidth * 0.05,
        marginBottom: responsiveStyles.isMobile || responsiveStyles.isTablet ? 10 : null,
       }}
      >
          <FastImage
          // source={SecondaryLogo}
          // source={{ uri: isMainLogo ? 
          //   getAsset(require('../../assets/images/store/buisness/logo_main.png'))
          //   : getAsset(require('../../assets/images/store/buisness/logo_secondary.png'))
          // }}
          source={{ uri: SecondaryLogo }}
          // source={{ uri: isMainLogo ? MainLogo : SecondaryLogo}}
          // resizeMode="contain"
          style={{ 
            flex:1,
            aspectRatio: responsiveStyles.isMobile ? 24 / 7 : 20 / 5,
            height: responsiveStyles.isMobile || responsiveStyles.isTablet ? headerHeight : isMainLogo ? headerHeight * 3 : headerHeight - 10,
            minHeight: 
            isMobile && responsiveStyles.isWeb ? 190 > headerHeight ? headerHeight : 190
            : null,
            maxHeight: headerHeight,
            // backgroundColor:'blue',
            transform: 
            responsiveStyles.isMobile  ? [ { scale: 1 } ] 
            : responsiveStyles.isTablet ? [ { scale: 0.7 } ] 
            :  isMainLogo ? [ { scale: 0.4 } ] : [ { scale: 1 } ],
            // backgroundColor:'blue'

           }}
        />
        </TouchableOpacity>
    )
}
const styling = (responsiveStyles) => StyleSheet.create({
text: {
  fontFamily: GlobalStyles.fonts.application_font,
}
});

export default ImageHeaderComponent;