import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Modal, TouchableWithoutFeedback, Dimensions, Platform } from 'react-native';
import { color } from 'react-native-reanimated';
import { GlobalStyles } from '../../constants/styles';
import { isFirstCharHebrew } from '../../functions/utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const CustomAlert = ({
  userSettingsState,
    visible = false,
    onClosePress, 
    backgroundColor, 
    color,
    headermessage, 
    bodymessge,
    buttonmessage1,
    buttonmessage1onPress = undefined,
    buttonmessage2,
    buttonmessage2onPress = undefined,
    children = null,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const windowWidth = responsiveStyles.screenWidth;
  const ButtonContainerWidth = 
  responsiveStyles.isDesktop ? windowWidth * 0.3
  : responsiveStyles.isLaptop ? windowWidth * 0.4
  : responsiveStyles.isTablet ? windowWidth * 0.5
  : windowWidth * 0.85;
    const styles = styling(responsiveStyles);
    console.log(visible);
  const closeModal = () => {
    console.log(`closeModal onClosePress: ${onClosePress}`);
    onClosePress();
  };
  const [buttonmessage1Width,setButtonmessage1Width] = useState(0);
  const [buttonmessage2Width,setButtonmessage2Width] = useState(0);
  // const isSetWidthButton = buttonmessage1Width + buttonmessage2Width > ButtonContainerWidth;
  const isSetWidthButton = buttonmessage1?.length + buttonmessage2?.length > 35;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onDismiss={() => {console.log()}}      
    >
      <TouchableOpacity 
      style={[styles.modalContainer]} 
      onPress={closeModal}
      activeOpacity={1}
      >
        <TouchableOpacity 
        style={[
          styles.alertContainer, 
          { backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor }
        ,{ width: ButtonContainerWidth }
      ]} 
        activeOpacity={1}
        >
          {headermessage !== undefined ? (
          <Text style={[styles.headermessage, { color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor }]}>{headermessage}</Text>
          ) : null}
          <Text style={[styles.bodymessge, { color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor }]}>{bodymessge}</Text>
          {children}
          <View style={[styles.buttonContainer, 
          isSetWidthButton === true ?( 
            isFirstCharHebrew(buttonmessage1) === false ?  { flexDirection:'column' } : { flexDirection:'column-reverse' }
            )
          : isFirstCharHebrew(buttonmessage1) === false ? { flexDirection:'row' } : { flexDirection:'row-reverse' }
        ]}
        >
          <TouchableOpacity onPress={() => {
            closeModal();
            if(buttonmessage1onPress !== undefined)
            {
                buttonmessage1onPress();
            }
        }}
             style={{ }}
             onLayout={(event) => {
              let {width} = event.nativeEvent.layout;
              setButtonmessage1Width(width);
            }}
             >
            <Text style={styles.alertButton}>{buttonmessage1}</Text>
          </TouchableOpacity>
          {buttonmessage2 === undefined ? null :
            <TouchableOpacity onPress={() => {
            closeModal();
            if(buttonmessage2onPress !== undefined)
            {
                buttonmessage2onPress();
            }
        }}
             style={{ }}
             onLayout={(event) => {
              let {width} = event.nativeEvent.layout;
              setButtonmessage2Width(width);
            }}
             >
            <Text style={[
              styles.alertButton,
              isSetWidthButton === true ? null : { marginHorizontal: 15 }
              ]}>{buttonmessage2}</Text>
          </TouchableOpacity>}
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    
  },
  buttonContainer:{
    //   flex:1,
    // flexDirection:'column-reverse',
    // flexDirection:'row-reverse',
    // backgroundColor:'brown',
  },
  alertContainer: {
    borderRadius: 10,
    padding: 20,
    // width:'85%',
    // height:'25%',
  },
  alertButton: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.alertButtonColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  headermessage:{
    color: GlobalStyles[responsiveStyles.ThemeValue]?.alertButtonColor,
    fontSize: responsiveStyles.fontSize_H4,
    paddingBottom: 15,
    fontFamily: GlobalStyles.fonts.application_font,
    // flex:1,
  },
  bodymessge:{
    color: GlobalStyles[responsiveStyles.ThemeValue]?.alertButtonColor,
    fontSize: responsiveStyles.fontSize_H5,
    paddingBottom: 10,
    fontFamily: GlobalStyles.fonts.application_font,
    // flex:1,
  }
});

export default CustomAlert;
