
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getResponseByErr } from "../../functions/getResponseByErr";
import { getAsync, postAsync } from "../categoryApiCalls";
import { FCMToken_Storage } from "../../modules/StorageObjects";

const SendMediaPostMessage = async (PostId, Message) => {
    try {
      const vm = {
        postId: PostId,
        message: Message
      }
        const req = await postAsync(
          "/Comment/SendMediaPostMessage",
          vm
        );
        const response = req.data;
          return response;
    } catch (err) {
      console.log(`err from SendMediaPostMessage ${err}`);
      return getResponseByErr(err);
    }
  };

  const SendTributePostMessage = async (PostId, Message) => {
    try {
      const vm = {
        postId: PostId,
        message: Message
      }
        const req = await postAsync(
          "/Comment/SendTributePostMessage",
          vm
        );
        const response = req.data;
          return response;
    } catch (err) {
      console.log(`err from SendTributePostMessage ${err}`);
      return getResponseByErr(err);
    }
  };
  const DeleteComment = async (Id, isAdmin) => {
    try {
      let req;
      if(isAdmin === true){
        req = await getAsync(
          "/Comment/DeleteCommentByAdmin/" + Id
        );
      }
      else{
        req = await getAsync(
          "/Comment/Delete/" + Id
        );
      }
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from DeleteComment ${err}`);
      return getResponseByErr(err);
    }
  };

  const LikeMediaPost = async (Id) => {
    try {
        const req = await getAsync(
          "/Like/LikeMediaPost/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from LikeMediaPost ${err}`);
      return getResponseByErr(err);
    }
  };

  const LikeTributePost = async (Id) => {
    try {
        const req = await getAsync(
          "/Like/LikeTributePost/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from LikeTributePost ${err}`);
      return getResponseByErr(err);
    }
  };

  const UnlikeMediaPost = async (Id) => {
    try {
        const req = await getAsync(
          "/Like/UnlikeMediaPost/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from UnlikeMediaPost ${err}`);
      return getResponseByErr(err);
    }
  };

  const UnlikeTributePost = async (Id) => {
    try {
        const req = await getAsync(
          "/Like/UnlikeTributePost/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from UnlikeTributePost ${err}`);
      return getResponseByErr(err);
    }
  };

  export { 
    SendMediaPostMessage, 
    SendTributePostMessage, 
    DeleteComment,
    LikeMediaPost,
    LikeTributePost,
    UnlikeMediaPost,
    UnlikeTributePost
  };