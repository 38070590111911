export default {
  Title: "תנאים והגבלות",
  description: "ברוך הבא ל-TMJ! תנאים והגבלות אלו מתארים את הכללים והתקנות לשימוש באתר שלנו ולשירותים שלנו. גישה או שימוש באתר שלנו ושירותינו מהווים הסכמה לתנאים ולהגבלות אלו. נא קרא אותם בקפידה.",
  Section1_Title: "1. מבוא",
  Section1_1: `תנאים והגבלות אלו חלים על אתר TMJ וכל השירותים הקשורים המסופקים על ידי TMJ. על ידי שימוש באתר ובשירותים שלנו, אתה מסכים לציית ולכבד את התנאים הללו.`,
  Section2_Title: "2. חשבונות משתמש",
  Section2_1_1: `2.1 יצירת חשבון:`,
  Section2_1_2: `לגשת לתכונות מסוימות באתר שלנו, ייתכן שתצטרך ליצור חשבון. אתה מסכים לספק מידע מדויק ומלא במהלך תהליך ההרשמה ולעדכן את המידע הזה לפי הצורך.`,
  Section2_2_1: `2.2 אבטחת חשבון:`,
  Section2_2_2: `אתה אחראי לשמור על סודיות המידע שלך בחשבון, כולל הסיסמה שלך, ולכל הפעילויות שמתרחשות תחת החשבון שלך. הודע לנו מיד על כל שימוש לא מורשה בחשבון שלך.`,
  Section3_Title: "3. שימוש בשירותים",
  Section3_1_1: `3.1 זכאות:`,
  Section3_1_2: `עליך להיות בן 18 לפחות כדי להשתמש בשירותים שלנו. על ידי שימוש בשירותים שלנו, אתה מצהיר ומתחייב שאתה עומד בדרישת זכאות זו.`,
  Section3_2_1: `3.2 רישיון:`,
  Section3_2_2: `TMJ מעניקה לך רישיון מוגבל, שאינו בלעדי, שאינו ניתן להעברה וניתן לביטול, להשתמש בשירותים שלנו לשימוש אישי ולא מסחרי בלבד.`,
  Section3_3_1: `3.3 פעילויות אסורות:`,
  Section3_3_2: `אתה מסכים לא לבצע את הפעולות הבאות:`,
  Section3_3_reason_1: `שימוש בשירותים שלנו לכל מטרה בלתי חוקית.`,
  Section3_3_reason_2: `העלאת תוכן פוגע, מזיק או המפר את זכויות צדדים שלישיים.`,
  Section3_3_reason_3: `ניסיון להתערב בתפקוד התקין של האתר שלנו או השירותים שלנו.`,
  Section4_Title: "4. הזמנות ותשלומים",
  Section4_1_1: `4.1 הזמנות:`,
  Section4_1_2: `על ידי ביצוע הזמנה, אתה מסכים לרכוש את התכשיטים בהתאם לתנאים אלו. כל ההזמנות כפופות לזמינות ולקבלה על ידי TMJ.`,
  Section4_2_1: `4.2 מחירים:`,
  Section4_2_2: `כל המחירים מוצגים ב[מטבע] ועשויים להשתנות ללא הודעה מראש. המחירים אינם כוללים מסים חלים או עלויות משלוח, שיתווספו בעת התשלום.`,
  Section4_3_1: `4.3 תשלום:`,
  Section4_3_2: `התשלומים חייבים להתבצע באמצעות שיטות התשלום המסופקות באתר שלנו. על ידי מתן מידע על אמצעי התשלום, אתה מצהיר ומתחייב שיש לך את הזכות החוקית להשתמש באמצעי התשלום שנבחר.`,
  Section5_Title: "5. משלוח וקליטה",
  Section5_1_1: `5.1 משלוח:`,
  Section5_1_2: `נשלח את התכשיטים לכתובת שניתנה במהלך התשלום. זמני המשלוח עשויים להשתנות בהתאם למיקומך ולגורמים אחרים.`,
  Section5_2_1: `5.2 קליטה:`,
  Section5_2_2: `אנו לא אחראים לכל עיכוב בקליטה שנגרם מאירועים מחוץ לשליטתנו. במקרה של עיכובים משמעותיים, נודיע לך ונספק תאריך אספקה משוער.`,
  Section6_Title: "6. החזרות והחזרים",
  Section6_1_1: `6.1 החזרות:`,
  Section6_1_2: `אם אינך מרוצה מהרכישה שלך, תוכל להחזיר את התכשיטים תוך 30 יום מקבלת המוצר להחזר או החלפה, בהתאם למדיניות ההחזרים שלנו.`,
  Section6_2_1: `6.2 החזרים:`,
  Section6_2_2: `ההחזרים יועברו תוך [מספר] ימים מקבלת הפריט המוחזר. ההחזר יינתן לאמצעי התשלום המקורי שבו השתמשת בעת הרכישה.`,
  Section7_Title: "7. קניין רוחני",
  Section7_1_1: `7.1 בעלות:`,
  Section7_1_2: `כל התוכן באתר שלנו, כולל טקסט, גרפיקה, לוגו ודימויים, הוא רכוש של TMJ או של מורשיה ומוגן על פי חוקי הקניין הרוחני.`,
  Section7_2_1: `7.2 רישיון לתוכן:`,
  Section7_2_2: `על ידי העלאת תוכן לאתר שלנו, אתה מעניק ל-TMJ רישיון עולמי, לא בלעדי, ללא תמלוגים, להשתמש, לשכפל ולהציג את התוכן בקשר לשירותים שלנו.`,
  Section8_Title: "8. פרטיות",
  Section8_1: `השימוש שלך בשירותים שלנו כפוף גם למדיניות הפרטיות שלנו, שהיא חלק מתנאים אלו. נא עיין במדיניות הפרטיות שלנו כדי להבין את התנהלותנו.`,
  Section9_Title: "9. הגבלת אחריות",
  Section9_1: `במידה המרבית המותרת על פי החוק, TMJ לא תהיה אחראית לכל נזק עקיף, מקרי, מיוחד, תוצאתי או עונשי, או לכל אובדן רווחים או הכנסות, אם נגרם ישירות או בעקיפין, או לכל אובדן נתונים, שימוש, מוניטין או אובדן בלתי מוחשי אחר, שנגרם כתוצאה מ:`,
  Section9_1_reason_1: `השימוש שלך או חוסר היכולת להשתמש בשירותים שלנו.`,
  Section9_1_reason_2: `גישה או שימוש בלתי מורשה לשרתים שלנו ולמידע אישי המאוחסן בהם.`,
  Section9_1_reason_3: `הפסקה או הפסקת שידור לשירותים שלנו או מהשירותים שלנו.`,
  Section10_Title: "10. חוק חל",
  Section10_1: `תנאים אלו יהיו כפופים ומפורשים בהתאם לחוקי [המדינה/המדינה שלך], מבלי להתחשב בקווי החוק המתנגדים.`,
  Section11_Title: "11. שינויים בתנאים",
  Section11_1: `החברה TMJ שומרת לעצמה את הזכות לשנות תנאים אלו בכל עת. אנו נודיע לך על כל שינוי על ידי פרסום התנאים החדשים באתר שלנו. מומלץ לעיין בתנאים אלה מעת לעת על מנת לבדוק שינויים. השימוש המתמשך שלך בשירותים לאחר שינויים בתנאים מהווה הסכמה לשינויים אלה.`,
  Section12_Title: "12. צור קשר",
  Section12_1: `אם יש לך שאלות בנוגע לתנאים אלו, אנא פנה אלינו ב:`,
  ContactUs_Email: "אימייל:",
  ContactUs_Phone: "טלפון:",
  FinalWords: `תודה על שבחרת ב-TMJ. אנו מעריכים את העסק שלך ומצפים לעזור לך לשתף את הזיכרונות הגדולים ביותר שלך עם יקיריך.`,
};
