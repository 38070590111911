import React, { useState } from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native';
import { GlobalStyles } from '../../constants/styles';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import i18n from '../../hooks/useI18n';
const LanguageModal = ({
  modalVisible,
  setModalVisible, 
  selectedLanguage, 
  onPress, 
  languages,
  userSettingsState,
 }) => {
//   const [modalVisible, setModalVisible] = useState(false);


const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  return (
    <View style={styles.container}>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {languages.map((language) => (
              <TouchableOpacity
                key={language.language}
                onPress={() => {
                  setModalVisible(false);
                  onPress(language);
                  // Add your code here to set the selected language in your state or perform other actions
                }}
              >
                <Text style={[styles.languageText, language.language === selectedLanguage ? { fontWeight:'bold' } : null]}>{language.name}</Text>
              </TouchableOpacity>
            ))}

            <TouchableOpacity onPress={() => setModalVisible(false)}>
              <Text style={styles.cancelText}>{i18n.t('textInput.LanguageModal_Cancel')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    color: 'blue',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',

  },
  modalContent: {
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    borderRadius: 10,
    padding: 20,
  },
  languageText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    paddingVertical: 10,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
  },
  cancelText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    textAlign: 'center',
    paddingVertical: 10,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold'
  },
});

export default LanguageModal;
