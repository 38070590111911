import React, { useState } from 'react';
import { StyleSheet,View,Text, Alert, Keyboard, Dimensions, useWindowDimensions } from 'react-native';
import ButtonTypeButton from "../inputs/ButtonTypeButton"
import { GlobalStyles } from '../../constants/styles';
import i18n from '../../hooks/useI18n';
import { CodeNotValid } from '../../components/alert/ForgotPasswordConfirmCanChangePasswordAlert';
import { navigateToScreen } from '../../navigationResponsive';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import TextInputCustom from '../inputs/TextInputCustom';
const ForgotPasswordConfirmCanChangePasswordForm = ({ 
  errorMessage, 
  onSubmit, 
  email, 
  code, 
  ThemeValue = 'light',
  forgotPasswordConfirmCanChangePasswordModalVisible,
  forgotPasswordConfirmCanChangePasswordAlertMessage,
  setForgotPasswordConfirmCanChangePasswordModalVisible,
  addErrorMessage,
  navigation,
  userSettingsState,

}) => {
  
const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  const [showLoader, setShowLoader] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    function hasSameLetter (str){
      const firstChar = str.charAt(0).toLowerCase();
      for (let i = 1; i < str.length; i++) {
        if (str.charAt(i).toLowerCase() !== firstChar) {
          return false;
        }
      }
      return true;
    }
    
    function ValidatePassword (text) {
      let reg = /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/;
      if (reg.test(text) === false || text.length < 6) {
        // atleast one number and one charecter
        return false;
      }
      else {
        // Email is Correct
        return true;
      }
    }
    if(forgotPasswordConfirmCanChangePasswordModalVisible === true && showLoader === true)
    {
      console.log("forgotPasswordConfirmCanChangePasswordModalVisible === true && showLoader === true");
      setShowLoader(false);
      // setForgotPasswordConfirmCanChangePasswordModalVisible(false);
    }

    if(forgotPasswordConfirmCanChangePasswordAlertMessage == "CodeNotValid")
    {
      // CodeNotValid();
      addErrorMessage("forgotPasswordConfirmCanChangePasswordAlertMessage","");
    }

  return (
    <View style={styles.container}>
        <Text style={styles.header}>{i18n.t('auth.ForgotPasswordConfirmCanChangePassword_header')}</Text>
        <View style={{ paddingBottom: 20 }}>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.ForgotPasswordConfirmCanChangePassword_headerDescription_1')}
        </Text>
        <Text style={styles.headerDescription}>
        {i18n.t('auth.ForgotPasswordConfirmCanChangePassword_headerDescription_2')}
        </Text>
        </View>
        <View style={styles.textInputContainer}>
      <TextInputCustom
      userSettingsState={userSettingsState}
      secureTextEntry={true}
      editable={true}
        label={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_passwordInput')}
        value={password}
        onChangeText={setPassword}
        style={styles.textInput}
        iconType="password"
      />
            {errorMessage ? (
        <Text style={GlobalStyles.errorMessage}>{errorMessage}</Text>
      ) : null}
      </View>
      <View style={styles.btnContainer}>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
        // TextOpposite={true}
        onPress={async () => {
          if(password.length === 0)
          {
            // PasswordRequired();
            addErrorMessage("forgotPasswordConfirmCanChangePasswordAlertMessage","PasswordRequired");
          }
          else if(password.length < 6)
          {
            // PasswordLengthRequired();
            addErrorMessage("forgotPasswordConfirmCanChangePasswordAlertMessage","PasswordLengthRequired");
          }
          else if(!hasSameLetter(password) === false)
          {
            // PasswordTooEasy();
            addErrorMessage("forgotPasswordConfirmCanChangePasswordAlertMessage","PasswordTooEasy");
          }
          else{
            setShowLoader(true);
            Keyboard.dismiss();
            const response = await onSubmit({ email, code, password, navigation });
            setShowLoader(false);
            if(response.isCompleted === true)
            {
              navigateToScreen(navigation, 'ResolveAuth', `ResolveAuth`, null, null);
              // navigation.navigate("ResolveAuth");
            }
          }
        }}
        styleButton={styles.buttonStyle}
        loading={showLoader}
        >
        {i18n.t('auth.ForgotPasswordConfirmCanChangePassword_passwordInput')}
          </ButtonTypeButton>
        </View>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.4 : null,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  header: {
    fontSize: responsiveStyles.fontSize_H2,
    fontWeight:'bold',
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    
  },
  headerDescriptionView:{
    marginBottom:15,
  },
  headerDescription:{
    fontSize: responsiveStyles.fontSize_H5,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    marginBottom: 5,
  },
  textInputContainer: {
    // flex: !responsiveStyles.isMobile ? null : 1,
    marginBottom: responsiveStyles.isWeb ? 15 : 50

  },
  btnContainer: {
    marginHorizontal: !responsiveStyles.isMobile ? null : 15,    
    alignSelf:'center',
    
  },
  textInput:{
    height: 40,
    borderWidth: 1,
    padding: 10,
    color:GlobalStyles[responsiveStyles.ThemeValue].textColor,
    marginBottom:15,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  // buttonStyle: {
  //   backgroundColor: GlobalStyles[responsiveStyles.ThemeValue].secondaryButtonBackgroundColor, 
  //   width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.8,
  //   alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  // },
  buttonStyle: {
    backgroundColor: GlobalStyles.colors.secondaryButtonBackgroundColor, 
    width: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.4 
    : responsiveStyles.screenWidth * 0.5,
    minWidth: 200,
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
});

export default ForgotPasswordConfirmCanChangePasswordForm;
