import { Platform } from 'react-native';

export const getVideoDurationInTimeSpan = async (uri) => {
  try {
    console.log(`getVideoDurationInTimeSpan started`);
    if(Platform.OS === "web") {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = uri;
        video.addEventListener('loadedmetadata', () => {
          console.log(`getVideoDurationInTimeSpan, video is ${video}`);
          const durationInMillis = video?.duration ? video.duration * 1000 : 0;
          const durationInTimeSpan = formatMillisToTimeSpan(durationInMillis);
          console.log(`getVideoDurationInTimeSpan durationInMillis is ${durationInMillis}`);
          console.log(`getVideoDurationInTimeSpan durationInTimeSpan is ${durationInTimeSpan}`);

        //   return durationInTimeSpan;
          resolve(durationInTimeSpan);
        });
        // video.addEventListener('error', (e) => reject(e));
      });
    }
  } catch (error) {
    console.error(`func getVideoDurationInTimeSpan failed, error: ${error}`);
    resolve('00:00:00.0000000');
  }
};  

const formatMillisToTimeSpan = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const millis = milliseconds % 1000;
  const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
  const seconds = (totalSeconds % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}.${millis.toString().padStart(3, '0')}0000`;
};
