import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  BackHandler,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Context as AuthContext } from '../../context/AuthContext';
import ForgotPasswordCodeConfirmationForm from '../../components/forms/ForgotPasswordCodeConfirmationForm';
import { GlobalStyles } from '../../constants/styles';
import { Context as UserSettingsContext } from '../../context/UserSettingsContext';
import BackButtonComponent from '../../components/BackButtonComponent';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyFooter from '../../components/CompanyFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Screen from '../../components/Screen';

const ForgotPasswordCodeConfirmationScreen = ({  }) => {
  
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
  const email = navigationParams?.email;
  const FromScreen = navigationParams?.FromScreen;
  
  const { state, addErrorMessage, ConfirmResetPasswordCode, ForgotPasswordCodeConfirmationResetPassword, setForgotPasswordCodeConfirmationModalVisible } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);
  
  const styles = styling(responsiveStyles);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardDidShowTxt, setKeyboardDidShowTxt] = useState('');

  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };
  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Forgot Password Code Confirmation";
    }
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true);
      setKeyboardDidShowTxt("keyboardDidShow");
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false);
      setKeyboardDidShowTxt("keyboardDidHide");
    });
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
      backHandler.remove();
    };
  }, []);

  if(responsiveStyles.isWeb){
  }
  else{
    useFocusEffect(
      useCallback(() => {
        addErrorMessage("forgotPasswordCodeConfirmationAlertMessage", "");
        setShowLoader(false);
        return () => {
          // Clean up if needed
        };
      }, [])
    );
  }

  return (
    <Screen
    userSettingsState={userSettingsState}
    navigation={navigation}
    showFooter={false}
    showBackButton={true}
    >
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} style={styles.container}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.inner}>
          {/* <BackButtonComponent userSettingsState={userSettingsState} navigation={navigation} onPress={backAction}
          /> */}
          <ForgotPasswordCodeConfirmationForm
            errorMessage={state.errorMessage}
            ConfirmResetPasswordCode={ConfirmResetPasswordCode}
            ResetPassword={ForgotPasswordCodeConfirmationResetPassword}
            ShowPassword={false}
            email={email}
            navigation={navigation}
            forgotPasswordCodeConfirmationAlertMessage={state.forgotPasswordCodeConfirmationAlertMessage}
            Keyboard={Keyboard}
            addErrorMessage={addErrorMessage}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            forgotPasswordCodeConfirmationModalVisible={state.forgotPasswordCodeConfirmationModalVisible}
            setForgotPasswordCodeConfirmationModalVisible={setForgotPasswordCodeConfirmationModalVisible}
            resetPasswordshowLoader={state.resetPasswordshowLoader}
            isKeyboardVisible={isKeyboardVisible}
            keyboardDidShowTxt={keyboardDidShowTxt}
            userSettingsState={userSettingsState}
            FromScreen={FromScreen}
          />
          <CompanyFooter userSettingsState={userSettingsState} containerStyle={{ paddingBottom: 20 }} />
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
    </Screen>
  );
};

ForgotPasswordCodeConfirmationScreen.navigationOptions = {
  header: () => false,
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  inner: {
    padding: 15,
    flex: 1,
  },
});

export default ForgotPasswordCodeConfirmationScreen;
