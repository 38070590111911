import React from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import { GlobalStyles } from "../constants/styles";
import i18n from "../hooks/useI18n";
import useResponsiveStyles from "../functions/ResponsiveStyles";

  const LoadingScreen = ({ 
    size="large", 
    message = null,
    userSettingsState,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  return (
    <View style={styles.container}>
                <ActivityIndicator
            size={size}
            color={GlobalStyles[responsiveStyles.ThemeValue]?.ActivityIndicatorColor}
          />
    {message ? 
    <Text style={styles.loaderText}>{message}</Text>
    : null
  }
        </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderText: {
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.inputColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default LoadingScreen;