export default {
  ManageTributesComponent_Create: "Post a tribute",
  ManageTributesComponent_Accept: "Accept",
  ManageTributesComponent_Decline: "Decline",
  ManageTributesComponent_Likes: "Likes",
  ManageTributesComponent_Comments: "Comments",
  CommendComponent_Edit: "Edit",
  ManageTributesComponent_DeleteButton: "Delete post",
  CommentSectionPopup_Title: "Comments",
  DescriptionExtention: `Continue reading`,
  CommentSectionPopup_DeleteButton: "Delete comment",
  CommentSectionPopup_TextInput: "Add a comment for",
  Comments: "Comments",
  Likes: "Likes",

  ShareProfile: "Share Profile",
  ShareProfile_message1: "View all the memories of",
  ShareProfile_message2: "with Timeless Memories Jewelry.",
  TributesLink: "Tributes Link",
  TributesLink_message1: "Share your memories with",
  TributesLink_message2: "and create a lasting tribute on Timeless Memories Jewelry.",
  };