import React, { useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Image, TouchableOpacity, Dimensions, LogBox, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";

import CommentSectionPopup from '../../components/CommentSectionPopup';
import ImageSlider from '../../components/media/ImageSlider';
import { DeleteMediaPost, GetUserProfilePostMedia, GetUserProfilePostMediaByJewelry } from '../../api/apiCalls/ProfileCalls';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import LoginModalAlert from '../../components/alert/LoginModalAlert';
import { DeleteComment, LikeMediaPost, SendMediaPostMessage, UnlikeMediaPost } from '../../api/apiCalls/MediaApiCalls';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import TopRow from '../../components/adminsComponent/ImageMediaScreen/TopRow';
import BottomRow from '../../components/adminsComponent/ImageMediaScreen/BottomRow';
import NotFoundComponent from '../../components/NotFoundComponent';
import { isValidGuid } from '../../functions/utils';
import JewelryNotAssignedComponent from '../../components/JewelryNotAssignedComponent';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
const ImageMediaScreen = ({  }) => {
  
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const PostIdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);

  const getJewelryId = () => {
    try{
      const ScreenName = location?.pathname?.split('/')[2].toLowerCase();
      return isValidGuid(ScreenName) ? ScreenName : null;
    }
    catch(error){
      return null;
    }
  }

  const PostId = navigationParams?.PostId || Number(PostIdFromURL);
  const IsDemo = PostId < 0;
  const JewelryId = navigationParams?.JewelryId || getJewelryId();
  console.log(`navigationParams ${JSON.stringify(navigationParams)}`);
  console.log(`JewelryId ${JewelryId}`);

  const [isJewelryAssigned, setIsJewelryAssigned] = useState(false);

  const IsIdValid = Number(PostId);
  const isEditing = !!PostId;


  const styles = styling(responsiveStyles);
    
    const [showMoreRead, setShowMoreRead] = useState(false);
    const [images, setImages] = useState([]);
    const [comments, setComments] = useState([]);
    const [isAdmin, setIsAdmin] = useState(true);
    const [user, setUser] = useState({
      Id: "",
      UserImage: "",
      UserName: "",
      Like: 0,
      Title: "",
      Description: "",
      CreatedDate: "",
      IsUserLiked: false,
    });
console.log(`user ${JSON.stringify(user)}`);
    
  const scrollViewRef = useRef(null);

    const handleLayout = (event) => {
      console.log("handleLayout");
      const { height } = event.nativeEvent.layout;
      const fontSize = 15;
      const lineHeight = fontSize + fontSize * 0.2; // Calculating line height based on font size
      const maxAllowedHeight = lineHeight * 3;
      console.log(`height ${height} maxAllowedHeight ${maxAllowedHeight}`);

      if(height >= maxAllowedHeight)
        {
        console.log(`height > maxAllowedHeight ${height} > ${maxAllowedHeight}`);
        setShowMoreRead(true);
      }
    };
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [loginModalVisible, setLoginModalVisible] = useState(false);


    const openDialog = (item) => {
      if(!userSettingsState?.userSettings?.UserId){
        setLoginModalVisible(true);
      }
      else{
        setDialogVisible(true);
      }
    };
  
    const closeDialog = () => {
      setDialogVisible(false);
    };
    

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };

    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const sendMessageHandler = async (message) => {
      const response = await SendMediaPostMessage(PostId, message);
      if(response.isCompleted === true)
      {
        const commentFromServer = JSON.parse(response.answer);
        setComments(prevComments => [...prevComments, commentFromServer]);
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
      return response;
    }
    const LikePostHandler = async () => {
      if(IsDemo){
        return;
      }
      if(user?.IsUserLiked === true){
        UnlikeMediaPost(PostId);
        setUser(prevUser => ({
          ...prevUser,
          IsUserLiked: false,
          Like: prevUser.Like === 0 ? 0 : prevUser.Like - 1,
        }));
      }
      else{
        LikeMediaPost(PostId);
        setUser(prevUser => ({
          ...prevUser,
          IsUserLiked: true,
          Like: prevUser.Like === 0 ? 1 : prevUser.Like + 1,
        }));
      }
    }
      const DeleteCommentHandler = async (Id) => {
      const response = await DeleteComment(Id, isAdmin);
      if(response.isCompleted === true)
      {
        setComments(prevComments => prevComments.filter(comment => comment.Id !== Id));
      }
      else{
        // setbodymessge(response.message);
        // setGenericAlertVisible(true);
      }
      return response;
    }

    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Image Media";
      }
      const fetchData = async () => {
        try {
          setLoading(true);
          let req;
          if(JewelryId){
            req = await GetUserProfilePostMediaByJewelry(JewelryId, PostId);
          }
          else{
            req = await GetUserProfilePostMedia(PostId);
          }
          if (req.isCompleted) {
            const vm = JSON.parse(req.answer);
            if(vm === "JewelryNotAssigned"){
              setIsJewelryAssigned(true);
            }
            else{
              setUser(vm.User);
              setImages(vm.Images);
              setComments(vm.Comments);
              setIsAdmin(vm.IsAdmin);
            }
          }
          else{
            setLoading(true);
            setErrorOnRequest(true);
          }
        } catch (err) {
          console.log(err);
          setLoading(true);
          setErrorOnRequest(true);
        }
        finally{
          setLoading(false);
        }
      };
      if(isFirstTime)
        {
          fetchData();
          setIsFirstTime(false);
        }
        else{
          // setLoading(true);
          setErrorOnRequest(false);
          if(errorOnRequest === true)
          {
            fetchData();
          }
        }

      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[reloadpage]);


    


  return <Screen
  userSettingsState={userSettingsState}
  navigation={navigation}
  showFooter={false}
  showBackButton={true}
  >
    {
      !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
      : isJewelryAssigned ? <JewelryNotAssignedComponent userSettingsState={userSettingsState} navigation={navigation} />
    : errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
  <ScrollView 
  style={styles.container}
  ref={scrollViewRef}
  >
    <View style={styles.subContainer}>
    <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
    <LoginModalAlert
      visible={loginModalVisible}
      setVisible={setLoginModalVisible}
      userSettingsState={userSettingsState}
      navigation={navigation}
      />
      {
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 
        <View style={{flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse'}}>
      <View style={{width:responsiveStyles.screenWidth * 0.80}}>
        <ImageSlider userSettingsState={userSettingsState} images={images} isFromImageMedia={true} />
      </View>
      <View style={{width:responsiveStyles.screenWidth * 0.20}}>
      <TopRow userSettingsState={userSettingsState} user={user} />
      <BottomRow 
      userSettingsState={userSettingsState} 
      user={user}
      LikePostHandler={LikePostHandler}
      openDialog={openDialog}
      handleLayout={handleLayout}
      showMoreRead={showMoreRead}
      comments={comments}
      IsDemo={IsDemo}
      />
      </View>
      </View>
      :
      <>
      <TopRow userSettingsState={userSettingsState} user={user} />
      <View>
        <ImageSlider userSettingsState={userSettingsState} images={images} isFromImageMedia={true} />
      </View>
      <BottomRow 
      userSettingsState={userSettingsState} 
      user={user}
      LikePostHandler={LikePostHandler}
      openDialog={openDialog}
      handleLayout={handleLayout}
      showMoreRead={showMoreRead}
      comments={comments}
      IsDemo={IsDemo}
      />
      </>
      }

        <CommentSectionPopup
        isVisible={dialogVisible}
        onClose={closeDialog}
        // onJoinPress={joinGroup}
        isLoading={isLoading}
        // onCancelJoinPress={onCancelJoinPress}
        navigation={navigation}
        userSettingsState={userSettingsState}
        data={comments}
        UserName={user?.UserName}
        IsDemo={IsDemo}
        sendComment={(message) => sendMessageHandler(message)}
        DeleteComment={(Id) => DeleteCommentHandler(Id)}
        isAdmin={isAdmin}
      />
      </View>
    </ScrollView>
    )}
    </Screen>

}

ImageMediaScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    subContainer: {
      flex: 1,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth : null,
      // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 : null,
      alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
    },
    
    imageContainer: {
      flex:1,
    },
    closeContainer: {
      marginHorizontal: 10,
    },
    shareContainer: {
      marginHorizontal: 10,
    },
    bottomContainer: {
      // flex:1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
      // alignSelf: 'center',
      // bottom: 90,
      // position: 'absolute',
      width:'100%',
    },
    likes: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H6,
      fontFamily: GlobalStyles.fonts.application_font,
      paddingTop: 5,
      paddingHorizontal: 20,
      fontWeight: '700',
    },
    title: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H4,
      fontFamily: GlobalStyles.fonts.application_font,
      paddingTop: 5,
      paddingHorizontal: 20,
      fontWeight: '700',
    },
    description: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      paddingTop: 5,
      paddingHorizontal: 20,
    },
    commentContainer: {
      flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
      paddingHorizontal: 20,
    },
    uploadDateContainer: {
      paddingBottom: 20,
    },
    comment: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H4,
      fontFamily: GlobalStyles.fonts.application_font,
      paddingTop: 5,
      paddingHorizontal: 5,
      },
      commentsNumberContainer: {
        backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
        // padding: 10,
        borderRadius: 100,
        width: 30,
        height: 30,
        justifyContent:'center',
        alignItems:'center'
      },
      commentsNumber: {
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H4,
        fontFamily: GlobalStyles.fonts.application_font,
      },
      showcaseNextPreviousButtonContainer: {
        position: 'absolute',
        top: '50%',
        transform: [{ translateY: -12 }], // Adjust based on icon size
        backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
        borderRadius: 100,
        padding: 5,
        justifyContent:'center',
        alignItems:'center',
      },
      PreviousContainer: {
        right: 10,
      },
      NextContainer: {
        left: 10,
      },

      swipeableView: {
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    },
      image: {
        flex: 1,
        width: '100%',
        height: responsiveStyles.screenWidth,
    },
});

export default ImageMediaScreen;
