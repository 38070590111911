export default {
  MyAccount_Title: "החשבון שלי",
  MyAccount_Name: "שם:",
  MyAccount_Email: "דוא\"ל:",
  MyAccount_Joined: "הצטרף:",
  MyAccountUserInfoComponent_Hello: 'היי',
  MyAccount_BottomCard_Posts: "פוסטים",
  MyAccount_BottomCard_Jewlery: "תכשיטים",
  MyAccount_BottomCard_Profiles: "פרופילים",
  MyAccount_BottomCard_Account: "החשבון שלי",
  MyAccount_AccountForm_Title: "הגדרות",
  MyAccount_AccountForm_ChangePassword: "שנה סיסמה",
  MyAccount_AccountForm_PersonalDetails: "פרטים אישיים",
  MyAccount_AccountForm_FirstName: "שם פרטי",
  MyAccount_AccountForm_FirstName_ErrorMessage: "יש להזין שם פרטי",
  MyAccount_AccountForm_LastName: "שם משפחה",
  MyAccount_AccountForm_LastName_ErrorMessage: "יש להזין שם משפחה",
  MyAccount_AccountForm_LocationDetails: "פרטי מיקום",
  MyAccount_AccountForm_State: "מדינה",
  MyAccount_AccountForm_State_ErrorMessage: "יש להזין מדינה",
  MyAccount_AccountForm_City: "עיר",
  MyAccount_AccountForm_City_ErrorMessage: "יש להזין עיר",
  MyAccount_AccountForm_Save: "שמור שינויים",
  MyAccount_TributeDisplayTypeComponent_Tributes: "מחוות",
  MyAccount_TributeDisplayTypeComponent_Invitations: "הזמנות",
  MyAccount_ProfileListDisplayTypeComponent_Human: "בני אדם",
  MyAccount_ProfileListDisplayTypeComponent_Pets: "חיות מחמד",
  MyAccount_ProfileDisplayTypeComponent_Profiles: "פרופילים",
  MyAccount_ProfileDisplayTypeComponent_AuthorizedProfiles: "פרופילים מאושרים",
  MyAccount_ProfileDisplayTypeComponent_Archived: "ארכיון",
  MyAccount_JewleryDisplayTypeComponent_Jewlery: "תכשיטים",
  MyAccount_JewleryDisplayTypeComponent_Orders: "הזמנות",
  MyAccount_JewleryListComponent_Profile: "פרופיל:",
  MyAccount_JewleryListComponent_NotePets: "כאן תוכל ליצור פרופיל עבור חיית המחמד שלכם, זה המקום להעלות תמונות, מחוות, סרטונים וקבצי אודיו.",
  MyAccount_JewleryListComponent_NoteHuman: "כאן תוכל ליצור פרופיל עבור האדם שיקבל את המתנה הנפלאה שרכשת, זה המקום להעלות תמונות, מחוות, סרטונים וקבצי אודיו.",
  MyAccount_JewleryListComponent_QRCODE: "קוד QR",
  MyAccount_JewleryListComponent_View: "הצג תכשיטים",
  MyAccount_ProfileListComponent_Create: "צור פרופיל חדש",
  MyAccount_ProfileListComponent_Bonding: "קשר:",
  MyAccount_ProfileListComponent_Archived: "ארכיון",
  MyAccount_ProfileListComponent_ArchivedDate: "ארכיון",
  MyAccount_ProfileListComponent_Restore: "שחזר",
  MyAccount_ProfileListComponent_View: "הצג",
  MyAccount_ProfileListComponent_Edit: "ערוך",
  MyAccount_JewleryComponent_Created: "נוצר ב",
  UNVERIFIED_UserProfile: "הפרופיל הזה עדיין לא שויך לתכשיט",
  MyAccount_JewleryComponent_JewleryAssigned1: "לפרופיל הזה יש",
  MyAccount_JewleryComponent_JewleryAssigned2: "תכשיטים משויכים",
  MyAccount_OrderListComponent_OrderNumber: "מספר הזמנה:",
  MyAccount_OrderListComponent_Items: "פריטים",
  MyAccount_OrderListComponent_OrderDate: "תאריך הזמנה:",
  MyAccount_OrderListComponent_Total: "סה\"כ:",
  MyAccount_OrderListComponent_View: "הצג הזמנה",
  MyAccount_ManageProfileForm_Create: "צור",
  MyAccount_ManageProfileForm_Edit: "ערוך",
  buttonmessage1DeleteAlert: `האם אתה בטוח שברצונך למחוק את הפרופיל הזה?
שים לב שאם תלחץ על 'כן', כל השיוך של הפרופיל הנ"ל לתכשיטים יימחקו.`,
  buttonmessage1Yes: "כן",
  buttonmessage2No: "לא",

  MyAccount_ManagePetProfileForm_BasicInformation: "מידע בסיסי",
    MyAccount_ManagePetProfileForm_Behavior: "התנהגות",
    MyAccount_ManagePetProfileForm_Health: "בריאות",
    MyAccount_ManagePetProfileForm_Vet: "וטרינר",
    MyAccount_ManagePetProfileForm_PetName: "שם החיה",
    MyAccount_ManagePetProfileForm_PetName_ErrorMessage: "יש להכניס את שם החיה",
    MyAccount_ManagePetProfileForm_PetType: "סוג החיה",
    MyAccount_ManagePetProfileForm_PetType_ErrorMessage: "יש לבחור את סוג החיה",
    MyAccount_ManagePetProfileForm_PetType_Dog: "כלב",
    MyAccount_ManagePetProfileForm_PetType_Cat: "חתול",
    MyAccount_ManagePetProfileForm_PetType_Other: "אחר",
    MyAccount_ManagePetProfileForm_Gender: "מין",
    MyAccount_ManagePetProfileForm_Gender_ErrorMessage: "יש לבחור מין",
    MyAccount_ManagePetProfileForm_Gender_Boy: "בן",
    MyAccount_ManagePetProfileForm_Gender_Girl: "בת",
    MyAccount_ManagePetProfileForm_Breed: "גזע",
    MyAccount_ManagePetProfileForm_BirthDate: "תאריך לידה",
    MyAccount_ManagePetProfileForm_BirthDate_ErrorMessage: "יש להכניס תאריך לידה",
    MyAccount_ManagePetProfileForm_DeathDate: "תאריך פטירה",
    MyAccount_ManagePetProfileForm_Weight: "משקל",
    MyAccount_ManagePetProfileForm_Weight_ErrorMessage: "יש להכניס משקל",
    MyAccount_ManagePetProfileForm_Bio: "ביו",
    MyAccount_ManagePetProfileForm_ContactName: "איש קשר",
    MyAccount_ManagePetProfileForm_ContactName_ErrorMessage: "יש להכניס איש קשר",
    MyAccount_ManagePetProfileForm_ContactPhone: "טלפון איש קשר",
    MyAccount_ManagePetProfileForm_ContactPhone_ErrorMessage: "יש להכניס טלפון איש קשר",
    MyAccount_ManagePetProfileForm_Address: "כתובת",
    MyAccount_ManagePetProfileForm_AdditionalInfo: "מידע נוסף",
    MyAccount_ManagePetProfileForm_Behavior_GoodWith: "טוב/ה עם",
    MyAccount_ManagePetProfileForm_Behavior_Dogs: "כלבים",
    MyAccount_ManagePetProfileForm_Yes: "כן",
    MyAccount_ManagePetProfileForm_No: "לא",
    MyAccount_ManagePetProfileForm_DependsOnSomeCases: "תלוי במקרים מסוימים",
    MyAccount_ManagePetProfileForm_Behavior_Cats: "חתולים",
    MyAccount_ManagePetProfileForm_Behavior_Children: "ילדים",
    MyAccount_ManagePetProfileForm_Behavior_Strangers: "זרים",
    MyAccount_ManagePetProfileForm_Behavior_OtherAnimals: "חיות אחרות",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel: "רמת אנרגיה",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel_Low: "נמוכה",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel_Medium: "בינונית",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel_High: "גבוהה",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel: "רמת אילוף",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Untrained: "לא מאולף",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Basic: "אילוף בסיסי",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Trained: "אילוף מלא",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_InTraining: "כרגע באילוף",
    MyAccount_ManagePetProfileForm_Behavior_Nature: "טבע",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Shy: "ביישן",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Social: "חברותי",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Aggressive: "אגרסיבי",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Curious: "סקרן",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Familiar: "מכיר מקומות ידועים",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Protective: "הגנתי",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Energetic: "אנרגטי",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Dominant: "דומיננטי",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Sensitive: "רגיש",
    MyAccount_ManagePetProfileForm_Health_Allergies: "אלרגיות",
    MyAccount_ManagePetProfileForm_Health_Medication: "תרופות",
    MyAccount_ManagePetProfileForm_Health_Vaccine: "חיסונים",
    MyAccount_ManagePetProfileForm_Health_MedicalIndications: "התוויות רפואיות",
    MyAccount_ManagePetProfileForm_Health_Spayed: "מעוקר/מסורס",
    MyAccount_ManagePetProfileForm_Health_AdditionalInfo: "מידע נוסף",
    MyAccount_ManagePetProfileForm_Vet_Name: "שם וטרינר",
    MyAccount_ManagePetProfileForm_Vet_PhoneNumber: "מספר טלפון",
    MyAccount_ManagePetProfileForm_Vet_MicrochipNumber: "מספר מיקרוצ'יפ",
    MyAccount_ManagePetProfileForm_Vet_RabiesNumber: "מספר כלבת",
    MyAccount_ManagePetProfileForm_Vet_AdditionalInfo: "מידע נוסף",
    MyAccount_ManagePetProfileForm_Save: `שמור שינויים`,
    

  MyAccount_ManageProfileForm_NewProfile: "פרופיל",
  MyAccount_ManageProfileForm_FirstName: "שם פרטי",
  MyAccount_ManageProfileForm_FirstName_ErrorMessage: "יש להזין שם פרטי",
  MyAccount_ManageProfileForm_MiddleName: "שם אמצעי",
  MyAccount_ManageProfileForm_MiddleName_ErrorMessage: "יש להזין שם אמצעי",
  MyAccount_ManageProfileForm_LastName: "שם משפחה",
  MyAccount_ManageProfileForm_LastName_ErrorMessage: "יש להזין שם משפחה",
  MyAccount_ManageProfileForm_NoteText: "במסך זה תוכל למלא את פרטי האדם שלמענו רכשת את המתנה",
  MyAccount_ManageProfileForm_NoteForNonAdmin: "אינכם יכולים לערוך את הביוגריפה של הפרופיל, רק המנהל יכול.",
  MyAccount_ManageProfileForm_Relationship: "קשר:",
  MyAccount_ManageProfileForm_Relationship_ErrorMessage: "יש לבחור קשר:",
  MyAccount_ManageProfileForm_RelationshipOptions: "בחר אפשרות",
  MyAccount_ManageProfileForm_Lifetime: "תוכלת חיים",
  MyAccount_ManageProfileForm_BirthDate: "תאריך לידה",
  MyAccount_ManageProfileForm_BirthDate_ErrorMessage: "יש להזין תאריך לידה",
  MyAccount_ManageProfileForm_DeathDate: "תאריך פטירה",
  MyAccount_ManageProfileForm_LocationDetails: "פרטי מיקום",
  MyAccount_ManageProfileForm_State: "מדינה",
  MyAccount_ManageProfileForm_City: "עיר",
  MyAccount_ManageProfileForm_Quote: "קטע ציטוט",
  MyAccount_ManageProfileForm_QuoteText: "טקסט או משפט",
  MyAccount_ManageProfileForm_Example1: "לדוגמה:",
  MyAccount_ManageProfileForm_Example2: `"אל תיתן לפחד מהפסד למנוע ממך לשחק את המשחק." - באב רות`,
  MyAccount_ManageProfileForm_Example3: `* כותרת זו מופיעה מעל לשם האדם.`,
  MyAccount_ManageProfileForm_Save: "שמור שינויים",
  MyAccount_EditProfile_DisplayTypeComponent_Bio: "ביוגרפיה",
  MyAccount_EditProfile_DisplayTypeComponent_Media: "מדיה",
  MyAccount_EditProfile_DisplayTypeComponent_Tributes: "מחוות",
  MyAccount_EditProfile_DisplayTypeComponent_Admins: "מנהלים",
  MyAccount_EditProfile_ManageMediaComponent_Create: "הוסף קובץ חדש",
  MyAccount_AdminsComponent_InviteUser: "הזמן משתמש",
  MyAccount_AdminsComponent_DisplayTypeComponent_ProfileAdmins: "מנהלי פרופילים",
  MyAccount_AdminsComponent_DisplayTypeComponent_PendingInvitations: "הזמנות ממתינות",
  MyAccount_AdminsComponent_Joined: "הצטרף:",
  MyAccount_AdminsComponent_Admin: "מנהל",
  MyAccount_AdminsComponent_Deactivate: "השבת",
  MyAccount_InviteUserAdminAlert_Title: "הזמן משתמש רשום לניהול",
  MyAccount_InviteUserAdminAlert_Username: "שם משתמש",
  MyAccount_InviteUserAdminAlert_Username_ErrorMessage: "יש להזין שם משתמש בקטגוריה",
  MyAccount_InviteUserAdminAlert_SubmitButton: "שלח הזמנה",
  MyAccount_TributeEmptyListComponent_Title: "עדיין לא פורסמו מחוות",
  MyAccount_TributeEmptyListComponent_SubTitle: "טרם פורסמו מחוות, שתף את הראשונה כאן והתחל לשתף.",
  MyAccount_TributeEmptyListComponent_PostButton: "פרסם מחווה",
  MyAccount_MediaEmptyListComponent_Title: "לא נמצאה מדיה",
  MyAccount_MediaEmptyListComponent_SubTitle1: "טרם נוספה מדיה ל",
  MyAccount_MediaEmptyListComponent_SubTitle2: "אוספים.",
  AssignJewleryToProfileComponent_Title: "לא יצרת פרופיל עדיין",
  AssignJewleryToProfileComponent_SubTitle1: "לחצו",
  AssignJewleryToProfileComponent_SubTitle2: "כאן",
  AssignJewleryToProfileComponent_SubTitle3: "על מנת ליצור פרופיל",
  MyAccount_MediaEmptyListComponent_PostButton: "פרסם מדיה",
  MyAccount_JewelryEmptyListComponent_Title: "לא רכשת אף מוצר",
  MyAccount_JewelryEmptyListComponent_SubTitle1: "לחץ",
  MyAccount_JewelryEmptyListComponent_SubTitle2: "כאן",
  MyAccount_JewelryEmptyListComponent_SubTitle3: "כדי לרכוש",
  MyAccount_OrderEmptyListComponent_Title: "לא רכשת אף מוצר",
  MyAccount_OrderEmptyListComponent_SubTitle1: "לחץ",
  MyAccount_OrderEmptyListComponent_SubTitle2: "כאן",
  MyAccount_OrderEmptyListComponent_SubTitle3: "כדי לרכוש",
  MyAccount_AdminEmptyListComponent_Title: "אין הזמנות ממתינות",
  MyAccount_AdminEmptyListComponent_SubTitle1: "אין הזמנות ממתינות ל",
  MyAccount_AdminEmptyListComponent_SubTitle2: "מנהל. הזמן משתמשים לניהול הפרופיל הזה ושתף הקלטות משמעותיות לתרום למורשת הוויזואלית הזו.",
  MyAccount_CommentSectionListComponent_Title: "לפוסט הזה אין תגובות עדיין!",
  MyAccount_CommentSectionListComponent_SubTitle: "היה הראשון לשתף את דעתך ולהשאיר תגובה למטה.",
  MyAccount_ManageMediaPost_Edit: "ערוך",
  MyAccount_ManageMediaPost_New: "חדש",
  MyAccount_ManageMediaPost_Post: "פרסם",
  MyAccount_ManageMediaPost_Title: "כותרת",
  MyAccount_ManageMediaPost_Title_ErrorMessage: "יש להזין כותרת",
  MyAccount_ManageMediaPost_Files_ErrorMessage: "יש להעלות לפחות קובץ אחד",
  MyAccount_ManageMediaPost_Description: "תיאור",
  MyAccount_ManageMediaPost_TypeOfContent: "סוג תוכן:",
  MyAccount_ManageMediaPost_Photos: "תמונות/סרטונים",
  MyAccount_ManageMediaPost_Audio: "אודיו",
  MyAccount_ManageMediaPost_UploadTitle: "העלה תמונות/סרטונים",
  MyAccount_ManageMediaPost_UploadAudio: "העלה קבצי אודיו",
  MyAccount_ManageMediaPost_Save: "שמור שינויים",
  MyAccount_ManageMediaPost_Cancel: "ביטול",
  MyAccount_ManageMediaPost_MaxMB: "לא ניתן להעלות קבצים בגודל של מעל 25 MB",
  MyAccount_ManageTributePost_Edit: "ערוך",
  MyAccount_ManageTributePost_New: "חדש",
  MyAccount_ManageTributePost_Post: "מחווה",
  MyAccount_ManageTributePost_UserName: "שם משתמש",
  MyAccount_ManageTributePost_UserName_ErrorMessage: "יש להזין שם משתמש",
  MyAccount_ManageTributePost_Title: "כותרת",
  MyAccount_ManageTributePost_Title_ErrorMessage: "יש להזין כותרת",
  MyAccount_ManageTributePost_Description: "תיאור",
  MyAccount_ManageTributePost_Description_ErrorMessage: "יש להזין תיאור",
  MyAccount_ManageTributePost_TypeOfContent: "סוג תוכן:",
  MyAccount_ManageTributePost_Photos: "תמונות/סרטונים",
  MyAccount_ManageTributePost_Audio: "אודיו",
  MyAccount_ManageTributePost_UploadTitle: "העלה תמונות/סרטונים",
  MyAccount_ManageTributePost_UploadAudio: "העלה קבצי אודיו",
  MyAccount_ManageTributePost_Save: "שמור שינויים",
  MyAccount_ManageTributePost_Cancel: "ביטול",
  MyAccount_ManagePost_note1: "שים לב שכל פוסט מחווה מוגבל ל-25 MB בלבד. תוכל להעלות קבצים בפורמטים JPEG, JPG, PNG, MP4 או MP3. ודא שההגשה שלך לא חורגת מהמגבלה הזו.",
  MyAccount_ManagePost_note2: "לתשומת לבך: תהליך ההעלאה עשוי להימשך עד דקה בזמן שאנו שומרים את הקבצים שלך. תודה על סבלנותך.",
  MyAccount_LanguageText: 'שפה',
  MyAccount_DarkModeText: 'מצב כהה',
  OrderDetailsScreen_Title: 'פרטי הזמנה',
  OrderDetailsScreen_OrderNumber: 'מספר הזמנה',
  OrderDetailsScreen_OrderDate: 'תאריך הזמנה',
  OrderDetailsScreen_Total: 'סה\"כ',
  OrderDetailsScreen_OrderItemsListDetailsComponent_View: 'הצג תכשיטים',
  JewleryDetailsScreen_Title: 'פרטי תכשיט',
  JewleryDetailsScreen_QRCode: 'קוד QR',
  JewleryDetailsScreen_ShowProfileButton: 'הצג פרופיל',
  JewleryDetailsScreen_Profile: 'פרופיל',
  JewleryDetailsScreen_AssignProfileButton: 'שייך פרופיל',
  AssignJewleryToProfileScreen_Title: 'שייך תכשיט',
  AssignJewleryToProfileScreen_JewleryName: 'שם תכשיט',
  AssignJewleryToProfileScreen_SelectedProfile: 'פרופיל נבחר',
  AssignJewleryToProfileScreen_AssignToProfile: 'שייך לפרופיל',
  AssignJewleryToProfileScreen_Cancel: 'ביטול',
  LoginModalAlert_headerMessage: 'כדי להוסיף תגובה, נא להתחבר או ליצור חשבון',
  LoginModalAlert_SubmitButton: 'הוסף תגובה!',
  BirthDateComponent_RequestAdmin: 'בקש הרשאות עריכה',
  BirthDateComponent_CancelRequestAdmin: 'בטל בקשת הרשאות עריכה',
  BirthDateComponent_EditProfile: `ערוך פרופיל`,
  BirthDateComponent_ShareLocation: `שתף מיקום`,
  PetContainersComponent_WeightType: `ק"ג`,
  PetContainersComponent_Weight: `משקל`,
  PetContainersComponent_Years: "שנים",
  PetContainersComponent_Age: "גיל",
  PetContainersComponent_Gender: "מין",
  ProfileScreen_BottomCardComponent_Bio: "ביו",
  ProfileScreen_BottomCardComponent_Media: "מדיה",
  ProfileScreen_BottomCardComponent_Tributes: "מחוות",
  MyAccount_: "",
  JewelryNotAssignedComponent_title: 'תכשיט לא שייך למשתמש',
  JewelryNotAssignedComponent_subtitle1: 'תכשיט זה אינו משויך כרגע לפרופיל.',
  JewelryNotAssignedComponent_subtitle2: 'רק המשתמש שרכש את התכשיט יכול לשייך אותו לפרופיל.',
  JewelryNotAssignedComponent_subtitle3: 'כדי לשייך פרופיל משתמש, אנא עבור למסך "החשבון שלי".',
};