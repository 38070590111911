import React, { useCallback } from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import i18n from "../../hooks/useI18n";
import Slider from "@react-native-community/slider";


const RenderSlider = ({
    userSettingsState,
    allowPlayVideo,
    durationTime,
    videoSliderValue,
    onSliderValueChange,
    onSliderTouchStart,
    onSliderTouchEnd,
    position,
    duration,
    isFullScreen,
    RenderSliderWidth = '100%',
    isFromTributes = false,
    videoTextStyle,
    isFromTutorialContainer = null,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles, isFullScreen, RenderSliderWidth, isFromTributes, isFromTutorialContainer);

    const formatTime = useCallback((milliseconds) => {
        const totalSeconds = milliseconds / 1000;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        const value = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        return value;
      }, []);
    return (
      <>
        {allowPlayVideo && durationTime ? (
          <View style={styles.progressBarContainer}>
            <Text style={[styles.videoText, isFullScreen ? styles.isFullScreenColor : null, videoTextStyle]}>
              {formatTime(position)}
            </Text>
            <Slider
              style={{ width: '100%', flex: 1 }}
              minimumValue={0}
              maximumValue={1}
              value={videoSliderValue}
              onValueChange={onSliderValueChange}
              style={styles.slider}
              minimumTrackTintColor={GlobalStyles.videoSlider.minimumTrackTintColor}
              maximumTrackTintColor={GlobalStyles.videoSlider.maximumTrackTintColor}
              onTouchStart={() => {
                console.log(`onTouchStart`);
                if (onSliderTouchStart) {
                  onSliderTouchStart();
                }
              }}
              onTouchEnd={() => {
                console.log(`onTouchEnd`);
                if (onSliderTouchEnd) {
                  onSliderTouchEnd();
                }
              }}
            />
            <View style={{ paddingHorizontal: 2 }}>
              <Text style={[styles.videoText, isFullScreen ? styles.isFullScreenColor : null, videoTextStyle]}>
                {duration}
              </Text>
            </View>
          </View>
        ) : null}
      </>
    );
  };
const styling = (responsiveStyles, isFullScreen, RenderSliderWidth, isFromTributes, isFromTutorialContainer) => StyleSheet.create({
    progressBarContainer: {
        flexDirection:'row',
        // bottom: isAudio ? 10 : 10,
        position: 'absolute',
        bottom: 0,
        // width: isFullScreen ? '100%' : RenderSliderWidth,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 10,
        // flex:1,
        // backgroundColor:'blue',
        // justifyContent: 'space-between',
        justifyContent:'center',
        // paddingHorizontal: 20,
        // zIndex: 1000,
        
        transform: isFromTutorialContainer ? [{ scale: 1.125 }] : isFromTributes ? [{ scale: 1.25 }] : [{ scale: 1 }],
        width: 
        isFromTributes ? responsiveStyles.isDesktop || responsiveStyles.isLaptop ? '76.5%'
        : responsiveStyles.isTablet ? '77%' : '72%'
        // regular
        : isFullScreen ? '100%' : RenderSliderWidth,
        marginLeft: 
        isFromTributes ? 
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  responsiveStyles.screenWidth * 0.07
        : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.09
        : responsiveStyles.screenWidth * 0.10
        // regular
        : null,
      },
      videoText: {
        fontSize: responsiveStyles.fontSize_H6,
        // color:  GlobalStyles[responsiveStyles.ThemeValue]?.iconColor,
        color: "white",
        fontFamily: GlobalStyles.fonts.application_font,
        zIndex: 999,
      },
      slider: {
        width: '65%',
      },
      isFullScreenColor: {
        color: "white",
      },
      videoNativeStyle: 
      { 
        objectFit:'contain', 
        width: '100%', 
        height: '100%',
      }
});

export default RenderSlider;