import AsyncStorage from "@react-native-async-storage/async-storage";
import { jewlery, orders, profiles, user_PersonalDetails } from "../../dummyData/AccountData";
import { tributes } from "../../dummyData/Profile_TributesData";
import { getResponseByErr, getResponseSucceed } from "../../functions/getResponseByErr";
import { getAsync, post, postAsync } from "../categoryApiCalls";
import { token_Storage } from "../../modules/StorageObjects";

const GetAllOrdersByPagination = async (data) => {
  try {
      const req = await postAsync(
        "/Statistics/GetAllOrdersByPagination",
        data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from GetAllOrdersByPagination ${err}`);
    return getResponseByErr(err);
  }
};
const GetOrderById = async (Id) => {
    try {
        const req = await getAsync(
          "/Statistics/GetOrderById/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetOrderById ${err}`);
      return getResponseByErr(err);
    }
  };
  const SaveOrderStatus = async (data) => {
    try {
      const req = await postAsync(
        "/Statistics/SaveOrderStatus",
        data
      );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from SaveOrderStatus ${err}`);
      return getResponseByErr(err);
    }
  };
  
  const GetFinancialData = async () => {
    try {
        const req = await getAsync(
          "/Statistics/GetFinancialData/"
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetFinancialData ${err}`);
      return getResponseByErr(err);
    }
  };
  export { GetAllOrdersByPagination, GetOrderById, SaveOrderStatus, GetFinancialData };