export default {
    Title: "Privacy Policy",
    description: "TMJ is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information. By using our website and services, you agree to the terms of this policy.",
    Section1_Title: "1. Information We Collect",
    Section1_1_1: `1.1 Personal Information:`,
    Section1_1_2: ` When you create an account, place an order, or contact us, we may collect personal information such as your name, email address, mailing address, phone number, and payment information.`,
    Section1_2_1: `1.2 Usage Data:`,
    Section1_2_2: ` We may also collect information on how our website and services are accessed and used. This may include your IP address, browser type, pages visited, time spent on pages, and other diagnostic data.`,
    Section2_Title: "2. How We Use Your Information",
    Section2_1_1: `2.1 To Provide Services:`,
    Section2_1_2: ` We use your personal information to process orders, manage your account, and provide customer support.`,
    Section2_2_1: `2.2 To Improve Our Services:`,
    Section2_2_2: ` We analyze usage data to understand how our services are used and to enhance user experience.`,
    Section2_3_1: `2.3 Marketing:`,
    Section2_3_2: ` With your consent, we may use your information to send you promotional materials and updates about our products and services. You can opt out of these communications`,
  };