import i18n from "../hooks/useI18n";

export const getResponseByErr = (err) => {
    console.log(`getResponseByErr err.message ${err.message}`);
    const response = {
      isCompleted: false,
      answer: "",
      message: 
        err.message === "Request failed with status code 400" || err.message === "BadRequest" ?  i18n.t('error.BadRequest')
      : err.message === "Timeout" ? i18n.t('error.Timeout') 
      : i18n.t('error.InternalError'),
    };
    return response;
  };
  export const getResponseSucceed = (answer = null) => {
    const response = {
      isCompleted: true,
      answer: JSON.stringify(answer),
      message: "",
    };
    return response;
  };