import React from 'react';
import { View, Image, StyleSheet, Dimensions } from 'react-native';
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';

const TestScreen = () => {
  return (
    <View style={styles.container}>
      <ReactNativeZoomableView
        maxZoom={3}
        minZoom={1}
        zoomStep={0.5}
        initialZoom={1}
        bindToBorders={true}
        style={styles.zoomableView}
      >
        <Image
          source={{ uri: 'https://picsum.photos/800/1200' }}
          style={styles.fullImage}
          resizeMode="contain"
        />
      </ReactNativeZoomableView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  zoomableView: {
    flex: 1,
  },
  fullImage: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
});

export default TestScreen;
