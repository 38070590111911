import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, Text, Platform, Dimensions, Image } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import HoverableTouchableOpacity from "../HoverableTouchableOpacity";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from "react-native-reanimated"; // For mobile animation
import { navigateToScreen } from "../../navigationResponsive";
import { Partner_User } from "../../constants/Profiles/Wife/User";
import { Pet_User } from "../../constants/Profiles/Pet/User";
import { transitionTime } from "../../functions/ScreenDimensionsLogic";

let FastImage = null;
if (Platform.OS === "web") {
  FastImage = Image;
} else {
  FastImage = require("react-native-fast-image");
}

const StepMemoriesContainer = ({ userSettingsState, navigation, scrollY }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Save the Y position of the component
  const windowHeight = Dimensions.get("window").height;

  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // For web (useState-based animation control)
  const [isVisible, setIsVisible] = useState(false);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    contentOpacity.value = 1;
    contentTranslateY.value = 0;
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== "web") {
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when it's within the viewport
      }
    }
  }, [scrollY, layoutY]);

  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); // Save the Y position
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // Trigger animation if visible on initial load
    }
  };

  // Intersection Observer for Web
  useEffect(() => {
    if (Platform.OS === "web") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect(); // Disconnect observer after triggering
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the section is visible
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  return (
    <Animated.View // Use Animated.View for animations
      ref={sectionRef}
      style={[
        styles.section,
        Platform.OS === "web" && isVisible
          ? { opacity: 1, transform: "translateY(0px)", transition: "opacity 1s ease-out, transform 1s ease-out" }
          : { opacity: 0, transform: "translateY(50px)" }, // Hidden until visible
        Platform.OS !== "web" ? animatedContentStyle : null,
      ]}
      onLayout={handleLayout} // Capture layout for mobile
    >
      <Text style={styles.sectionTitle}>
        {i18n.t("store.HomeScreen_StepMemoriesContainer_Title")}
      </Text>
      <View style={styles.stepsContainer}>
        <HoverableTouchableOpacity
          userSettingsState={userSettingsState}
          style={[styles.step, responsiveStyles.isRTL ? styles.stepLeft : styles.stepRight]}
          onPress={() => {
            navigateToScreen(navigation, "AccountFlow", `Profile`, { UserId: "Partner", FromScreen: "Home" }, "Partner");
          }}
          activeOpacity={0.6}
        >
          <Text style={[styles.stepTitle]}>
            {i18n.t("store.HomeScreen_StepMemoriesContainer_Section2_Title")}
          </Text>
          <FastImage source={{ uri: Partner_User.UserImage }} style={styles.stepImage} />
        </HoverableTouchableOpacity>

        <HoverableTouchableOpacity
          userSettingsState={userSettingsState}
          style={styles.step}
          onPress={() => {
            navigateToScreen(navigation, "AccountFlow", `Profile`, { UserId: "Pet", FromScreen: "Home" }, "Pet");
          }}
          activeOpacity={0.6}
        >
          <Text style={[styles.stepTitle]}>
            {i18n.t("store.HomeScreen_StepMemoriesContainer_Section4_Title")}
          </Text>
          <FastImage source={{ uri: Pet_User.UserImage }} style={styles.stepImage} />
        </HoverableTouchableOpacity>
      </View>
    </Animated.View>
  );
};

const styling = (responsiveStyles) =>
  StyleSheet.create({
    section: {
      paddingVertical: 20,
      paddingHorizontal: 10,
    },
    sectionTitle: {
      fontSize: responsiveStyles.fontSize_H1,
      fontFamily: GlobalStyles.fonts.application_font,
      fontWeight: "bold",
      marginBottom: 10,
      textAlign: "center",
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
    stepsContainer: {
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop
        ? responsiveStyles.isRTL
          ? "row-reverse"
          : "row"
        : "column", // Make it responsive for mobile and desktop
      justifyContent: "center",
      alignItems: "center",
      transform: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? [{ scale: 0.9 }] : [{ scale: 1 }],
    },
    stepLeft: {
      marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop
        ? responsiveStyles.screenWidth * 0.15
        : null,
    },
    stepRight: {
      marginRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop
        ? responsiveStyles.screenWidth * 0.15
        : null,
    },
    step: {
      alignItems: "center",
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop
        ? responsiveStyles.screenWidth * 0.15
        : null,
      borderRadius: 10,
      marginBottom: 20,
      paddingVertical: 10,
    },
    stepTitle: {
      fontSize: responsiveStyles.fontSize_H4,
      fontWeight: "bold",
      marginBottom: 5,
      fontFamily: GlobalStyles.fonts.application_font,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      position: "absolute",
      bottom: 30,
      zIndex: 999,
      padding: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 15,
      borderRadius: 15,
      backgroundColor: Gold,
      minWidth: responsiveStyles.screenWidth * 0.15,
      textAlign: "center",
    },
    stepImage: {
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop
        ? responsiveStyles.screenWidth * 0.23
        : responsiveStyles.isTablet
        ? responsiveStyles.screenWidth * 0.4
        : responsiveStyles.screenWidth * 0.7,
      aspectRatio: 23 / 30,
      marginBottom: 5,
      borderRadius: 25,
      marginVertical: 20,
    },
  });

export default StepMemoriesContainer;
