import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image, FlatList, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { imageWidth } from "../../constants/imageWidth";
import MediaEmptyListComponent from "./MediaEmptyListComponent";
import ImageProfile from "../images/ImageProfile";
import ImageMedia from "../images/ImageMedia";
import { navigateToScreen } from "../../navigationResponsive";
import { getPaddingScreen,  } from "../../functions/ScreenDimensionsLogic";
import { getImageWidth, getwidthOfImage } from "../../functions/getImageWidth";
import { rearrangeDataForRTL } from "../../functions/rearrangeDataForRTL";
import useResponsiveStyles from "../../functions/ResponsiveStyles";




  const ManageMediaComponent = ({ 
    userSettingsState, 
    isEditing = false, 
    data = [], 
    UserName, 
    UserId, 
    navigation,
    cleanup,
    JewelryId = null,
    isAdmin = false,
   }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
  const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
  
  const widthOfInput = !responsiveStyles.isMobile ? responsiveStyles.screenWidth - (paddingScreen * 2) : responsiveStyles.screenWidth * 0.90;
  
  const styles = styling(responsiveStyles, paddingScreen);
  const dummyDataToAdd = { Id: - 1 , isHidden: true };
  
  const rerenderedData = data.length % 3 === 1 ? [...data, dummyDataToAdd, dummyDataToAdd] : data.length % 3 === 2 ? [...data, dummyDataToAdd] : data;

  return (
    <View style={styles.container}>
      { isEditing && data.length > 0 ? 
      <View style={styles.buttonContainer}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.button}
          styleButton={styles.inviteStyleButton}
           onPress={() => {
            navigateToScreen(navigation, 'AccountFlow', `ManageMediaPost`, { UserId: UserId, }, 
              `${UserId}/Create`
              // 'Create'
            );
            // navigate('AccountFlow', { screen: 'ManageMediaPost', params: { UserId: UserId } });
            // navigate("ManageMediaPost", { UserId: UserId });
            if(cleanup){
              cleanup();
            }
          }}
          >
            {i18n.t('account.MyAccount_EditProfile_ManageMediaComponent_Create')}
      </ButtonTypeButton>
      </View>
      : null }
      <FlatList
      // scrollEnabled={false}
      style={styles.flatListContainer}
      key={(item) => item.Id.toString()} 
      data={responsiveStyles.isRTL ? rearrangeDataForRTL(rerenderedData, 3) : rerenderedData}
      renderItem={({ item, index }) =>
      {
      
      console.log(`item.FirstFileURI ${item.FirstFileURI}`);
     return <View style={[
      styles.ImageContainer
     ]}>
      {item.isHidden === true ? 
      <View style={{ width: imageWidth }}>
      </View> 
      : 
      <>
     <ImageMedia
     userSettingsState={userSettingsState}
     resizeMode={"cover"}
     fileURI={{uri: item.FirstFileURI}}
   isUriWithSource={true}
  imageStyle={styles.image}
  editable={isEditing}
  isGroup={false}
  showVideoIcon={item.FirstFileType === 'video' || item.FirstFileURI?.includes("mp4")}
  showAudioIcon={item.FirstFileType === 'audio' || item.FirstFileURI?.includes("mp3")}
  showMultipleFiles={item.FileCount > 1}
  PostId={item.Id}
  JewelryId={JewelryId}
  isEditing={isEditing}
  UserId={UserId}
  navigation={navigation}
  cleanup={cleanup}
    />
      </>
    }
</View>
      }
      }
      numColumns={3}
      ListEmptyComponent={
        <MediaEmptyListComponent 
        UserName={UserName} 
        isEditing={isEditing} 
        UserId={UserId} 
        userSettingsState={userSettingsState}
        navigation={navigation}
        />
          }
      />
  </View>
  );
}
const styling = (responsiveStyles, paddingScreen) => StyleSheet.create({

  container: {
  },
  flatListContainer: {
    padding: 5,
    maxWidth: responsiveStyles.isMobile ? null : getImageWidth(responsiveStyles),
    alignSelf: !responsiveStyles.isMobile ? 'center' : null,
  },
  ImageContainer: {
    flexDirection:'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    flex:1,
    padding:1,
  },
  image: {
    width: getwidthOfImage(responsiveStyles),
    height: getwidthOfImage(responsiveStyles),
    maxWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : 
    // (responsiveStyles.screenWidth / 3) - 5,
    300,
    maxHeight: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : 
    // (responsiveStyles.screenWidth / 3) - 5 ,
    300,
    // borderTopLeftRadius: 50,
    // borderTopRightRadius: 50,
    // borderBottomLeftRadius: 50,
    // borderBottomRightRadius: 50,
    borderWidth: 1,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  buttonContainer: {
    alignItems:'center',
    paddingTop: 20,
    paddingBottom: 10,
  },
  button: {
    width: !responsiveStyles.isMobile || responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
    minWidth: !responsiveStyles.isMobile || responsiveStyles.isWeb ? 150 : null,
  },
  inviteStyleButton: {
    // backgroundColor: GlobalStyles.colors.Lavender,
  },
});

export default ManageMediaComponent;