import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, FlatList, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";
import { GenericAlert } from "../alert/GenericAlert";
import CartEmptyListComponent from "../displayComponent/CartEmptyListComponent";
import QuantityStoreItemComponent from "./QuantityStoreItemComponent";
import { Feather } from '@expo/vector-icons';
import { Context as ShopContext } from '../../context/ShopContext';
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const FloatingCartHeaderComponent = ({ 
    userSettingsState, 
    totalQuantity = 0,
    setIsStoreMenuOpen = null,
  }) => {
    const {state: shopState} = useContext(ShopContext);

    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

    const [discountCode, setDiscountCode] = useState(false);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const Rating = 5;
    const onPressDiscount = () => {
      setbodymessge(`Discount code "${discountCode}" does not exist!`);
      setGenericAlertVisible(true);
    }
  return (
    <View style={styles.cartHeaderContainer}>
    <View style={styles.cartContainer}>
  <AntDesign name="shoppingcart" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} style={styles.shoppingCartIcon} />
    <Text style={[styles.text, styles.title]}>{i18n.t('store.FloatingCartHeaderComponent_Title')} ({totalQuantity})</Text>
    </View>
    <TouchableOpacity 
    style={styles.cartCloseContainer}
    onPress={() => {
      if(setIsStoreMenuOpen){
        setIsStoreMenuOpen(false);
      }
    }}
    >
    <AntDesign name="close" size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
    </TouchableOpacity>
  </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
cartCloseContainer: {
},
cartHeaderContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
},
cartContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  alignItems:'flex-start',
},
shoppingCartIcon: {
  paddingTop: !responsiveStyles.isMobile ? 10 : null,
  paddingHorizontal: 10,
},

  
  title: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H3,
    fontFamily: GlobalStyles.fonts.application_font,
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
},
itemTitle: {
  fontWeight: '900',
  textAlign: responsiveStyles.isRTL ?  'right' : 'left'
},

});

export default FloatingCartHeaderComponent;