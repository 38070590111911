import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { Assistant_Light, GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const DescriptionComponent = ({ userSettingsState, user }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  console.log(`user.IsPet: ${user.IsPet} user.Bio: ${user.Bio}`);
  
  if(
    // user.IsPet ? user.Bio?.length > 0 : 
    user.Description?.length > 0
  ){
    return (
      <View style={styles.descriptionContainer}>
                  <Text style={styles.description}>
                    {
                    // user.IsPet ? user.Bio : 
                    user.Description
                    }
                    </Text>
                </View>
        );
  }
  else{
    return null;
  }
}
const styling = (responsiveStyles) => StyleSheet.create({

  descriptionContainer: {
    marginBottom: 15,
    paddingBottom: 10,
  },
  description: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H3,
    fontFamily: Assistant_Light,
    fontWeight: '700'
  },
});

export default DescriptionComponent;