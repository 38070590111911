import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const NameComponent = ({ userSettingsState, user }) => {
    
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  return (
    <View style={styles.nameContainer}>
            <Text style={styles.name}>{user.IsPet ? user.PetName : user.UserName}</Text>
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
    nameContainer: {
        marginTop: 5,
        marginBottom: 5,
        paddingBottom: 10,
      },
      name: {
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        textAlign: 'center',
        fontSize: responsiveStyles.fontSize_H1,
        fontFamily: GlobalStyles.fonts.application_font,
        fontWeight: 'bold',
      },
});

export default NameComponent;