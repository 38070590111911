export const formatTimeSpan = (milliseconds) => {
  try{
    // console.log(`formatTimeSpan milliseconds is ${milliseconds}`);
    if(milliseconds === null || milliseconds === undefined || milliseconds?.toString()?.toLowerCase() === "nan"){
      return null;
    }
    if (typeof milliseconds === 'string') {
      return milliseconds;
    }
      const totalSeconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
    
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  }
  catch(error){
    console.log(error);
    return null;
  }
  };