import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../constants/styles";
import i18n from "../hooks/useI18n";
import EdgeFrame from "./store/EdgeFrame";
import useResponsiveStyles from "../functions/ResponsiveStyles";
  const HoverableTouchableOpacity = ({ 
    userSettingsState,
    onPress,
    style,
    children,
    isShowEdge = false,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

    const [isHovered, setIsHovered] = useState(false);

    return (
      <TouchableOpacity
        onPress={onPress}
        style={[style, !responsiveStyles.isMobile && isHovered && styles.hovered]}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        activeOpacity={0.6}
      >
        {children}
        {isHovered && isShowEdge ? <EdgeFrame isFocus={isHovered} /> : null}
      </TouchableOpacity>
    );
}
const styling = (responsiveStyles) => StyleSheet.create({
  hovered: {
    opacity: 0.6, // or any other style for hover effect
  },
});

export default HoverableTouchableOpacity;