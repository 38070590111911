import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Animated } from 'react-native';

const EdgeFrame = ({ isFocus = false }) => {
  // Define animated values
  const edgeWidthAnim = useRef(new Animated.Value(0)).current;
  const edgeHeightAnim = useRef(new Animated.Value(0)).current;
  const cornerWidthAnim = useRef(new Animated.Value(0)).current;

  const duration = 200;
  useEffect(() => {
    // Trigger animation when isFocus becomes true
    if (isFocus) {
      Animated.timing(edgeWidthAnim, {
        toValue: 40,
        duration: duration,
        useNativeDriver: false,
      }).start();

      Animated.timing(edgeHeightAnim, {
        toValue: 5,
        duration: duration,
        useNativeDriver: false,
      }).start();

      Animated.timing(cornerWidthAnim, {
        toValue: 7,
        duration: duration,
        useNativeDriver: false,
      }).start();
    }
  }, [isFocus]);

  const edgeWidth = edgeWidthAnim.interpolate({
    inputRange: [0, 40],
    outputRange: [0, 40],
  });

  const edgeHeight = edgeHeightAnim.interpolate({
    inputRange: [0, 5],
    outputRange: [0, 5],
  });

  const cornerWidth = cornerWidthAnim.interpolate({
    inputRange: [0, 7],
    outputRange: [0, 7],
  });

  return (
    <View style={styles.overlay}>
      {/* Top edge */}
      <Animated.View style={[styles.edge, { width: edgeWidth, height: edgeHeight }, styles.topLeft]} />
      <Animated.View style={[styles.edge, { width: edgeWidth, height: edgeHeight }, styles.topRight]} />

      {/* Bottom edge */}
      <Animated.View style={[styles.edge, { width: edgeWidth, height: edgeHeight }, styles.bottomLeft]} />
      <Animated.View style={[styles.edge, { width: edgeWidth, height: edgeHeight }, styles.bottomRight]} />

      {/* Left edge */}
      <Animated.View style={[styles.edgeVertical, { width: edgeHeight, height: edgeWidth }, styles.leftTop]} />
      <Animated.View style={[styles.edgeVertical, { width: edgeHeight, height: edgeWidth }, styles.leftBottom]} />

      {/* Right edge */}
      <Animated.View style={[styles.edgeVertical, { width: edgeHeight, height: edgeWidth }, styles.rightTop]} />
      <Animated.View style={[styles.edgeVertical, { width: edgeHeight, height: edgeWidth }, styles.rightBottom]} />

      {/* Corner connecting elements */}
      <Animated.View style={[styles.cornerTopLeft, { width: cornerWidth, height: cornerWidth }]} />
      <Animated.View style={[styles.cornerTopRight, { width: cornerWidth, height: cornerWidth }]} />
      <Animated.View style={[styles.cornerBottomLeft, { width: cornerWidth, height: cornerWidth }]} />
      <Animated.View style={[styles.cornerBottomRight, { width: cornerWidth, height: cornerWidth }]} />
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 35,
    left: 5,
    right: 5,
    bottom: 20,
    borderWidth: 2,
    borderColor: 'transparent',
  },
  edge: {
    position: 'absolute',
    backgroundColor: 'black',
    borderRadius: 10,
  },
  edgeVertical: {
    position: 'absolute',
    backgroundColor: 'black',
    borderRadius: 10,
  },
  topLeft: {
    top: 0,
    left: 0,
    marginLeft: 5,
    borderTopLeftRadius: 20,
  },
  topRight: {
    top: 0,
    right: 0,
    marginRight: 5,
    borderTopRightRadius: 20,
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
    marginLeft: 5,
    borderBottomLeftRadius: 20,
  },
  bottomRight: {
    bottom: 0,
    right: 0,
    marginRight: 5,
    borderBottomRightRadius: 20,
  },
  leftTop: {
    top: 0,
    left: 0,
    marginTop: 5,
    borderTopLeftRadius: 20,
  },
  leftBottom: {
    bottom: 0,
    left: 0,
    marginBottom: 5,
    borderBottomLeftRadius: 20,
  },
  rightTop: {
    top: 0,
    right: 0,
    marginTop: 5,
    borderTopRightRadius: 20,
  },
  rightBottom: {
    bottom: 0,
    right: 0,
    marginBottom: 5,
    borderBottomRightRadius: 20,
  },
  cornerTopLeft: {
    position: 'absolute',
    top: -0,
    left: -0,
    backgroundColor: 'black',
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  cornerTopRight: {
    position: 'absolute',
    top: -0,
    right: -0,
    backgroundColor: 'black',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  cornerBottomLeft: {
    position: 'absolute',
    bottom: -0,
    left: -0,
    backgroundColor: 'black',
    borderBottomLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  cornerBottomRight: {
    position: 'absolute',
    bottom: -0,
    right: -0,
    backgroundColor: 'black',
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
  },
});

export default EdgeFrame;
