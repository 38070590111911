import React, { useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, FlatList, TouchableOpacity, Dimensions, Platform, useWindowDimensions, TouchableWithoutFeedback, Keyboard, KeyboardAvoidingView } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import CheckboxButton from '../../components/inputs/CheckboxButton';
import TextInputWithErrorComponent from '../../components/forms/TextInputWithErrorComponent';
import ImageProfile from '../../components/images/ImageProfile';
import { Feather } from '@expo/vector-icons';
import { imageWidth } from '../../constants/imageWidth';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { calculateBase64FileSize, pickImageHandler } from '../../functions/pickImage';
import { AddTributePost, AddTributePostInvitation, DeleteTributePost, GetUserProfilePostTribute, UpdateTributePost } from '../../api/apiCalls/ProfileCalls';
import LoadingScreen from '../../components/LoadingScreen';
import ErrorComponent from '../../components/ErrorComponent';
import VideoMedia from '../../components/images/VideoMedia';
import ImageSlider from '../../components/media/ImageSlider';
import * as DocumentPicker from 'expo-document-picker';
import { Audio } from 'expo-av';
import VideoMediaPrimary from '../../components/images/VideoMediaPrimary';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import { formatTimeSpan } from '../../functions/convertDurationToTimeSpan';
import { getVideoDurationInTimeSpan } from '../../functions/getVideoDurationInTimeSpan';
import NotFoundComponent from '../../components/NotFoundComponent';
import { isValidGuid } from '../../functions/utils';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { getImageWidth, getwidthOfImage } from '../../functions/getImageWidth';
import { rearrangeDataForRTL } from '../../functions/rearrangeDataForRTL';
import DraggableFlatListRow from '../../components/DraggableFlatListRow';


const paddingScreen = 30;


const ManageTributePostScreen = ({  }) => {
  
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const PostIdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);

  const getUserId = () => {
    try{
      const ScreenName = location?.pathname?.split('/')[2].toLowerCase();
      return isValidGuid(ScreenName) ? ScreenName : null;
    }
    catch(error){
      return null;
    }
  }

  const UserId = navigationParams?.UserId || getUserId();

  const PostId = navigationParams?.PostId || Number(PostIdFromURL);
  const isEditing = !!PostId;
  const IsIdValid = responsiveStyles.isWeb ? 
  (Number(PostId) || PostIdFromURL === "Create" || PostIdFromURL === "CreatePostTributeInvitation") 
  : (Number(PostId) ||  !isEditing);
  const isPostTributeInvitation = PostIdFromURL === "CreatePostTributeInvitation";
  // console.log(`PostId is ${PostId}`);
    const styles = styling(responsiveStyles, isEditing, paddingScreen);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const [displayType, setDisplayType] = useState('Bio');

    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const DeleteTributeHandler = async () => {
        const response = await DeleteTributePost(PostId);
        if(response.isCompleted === true)
          {
            responsiveStyles.isWeb ? navigation(-1) : navigation.pop();navigation.pop();
          }
          else{
            setbodymessge(response.message);
            setGenericAlertVisible(true);
          }
        setDeleteAlertVisible(false);
      
    }

    const scrollViewRef = useRef(null);
    const [inputs, setInputs] = useState({
      UserName: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Title: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Description: {
        value: "",
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
    });

    const onLayoutChange = (inputName, y) => {

      setInputs(curInputs => ({
        ...curInputs,
        [inputName]: { ...curInputs[inputName], layoutY: y },
      }));
    };


    const setInputsByUser = (user) => {
      setInputs({
        UserName: {
          value: "",
          isValid: true,
          isChanged: false,
        },
        Title: {
          value: user?.Title ? user.Title : "",
          isValid: true,
          isChanged: false,
        },
        Description: {
          value: user?.Description ? user.Description : "",
          isValid: true,
          isChanged: false,
        },
      });
    }
    const [files, setFiles] = useState([]);
    const [audioFiles, setAudioFiles] = useState([]);
    const [durationOfFiles, setDurationOfFiles] = useState([]);
    

    const [isImageChecked, setIsImageChecked] = useState(true);

    const handleFilePick = async () => {
      if (isImageChecked) {
        await onImagePick();
      } else {
        await onAudioPick();
      }
    };
 // Function to handle picking audio files
 const onAudioPick = async () => {
  try {
    console.log(`onAudioPick`);
    const result = await DocumentPicker.getDocumentAsync({
      type: 'audio/*',
      multiple: true, // allow multiple file selection if required
    });
    console.log(`result`);
    console.log(result);
    if (result.type === 'success' || result.assets !== null) {
      const selectedAudios = [];
      let fileCount = files.length;
      for (let file of result.assets) {
        const { uri } = file;
        const sound = new Audio.Sound();
        await sound.loadAsync({ uri });
        const status = await sound.getStatusAsync();
        const duration = status.durationMillis;
        const sizeInMB = calculateBase64FileSize(file);
        console.log(`sizeInMB after calculateBase64FileSize is ${sizeInMB}`);  


        if (sizeInMB > 8) {
          alert(`Selected file ${file.name} exceeds 8 MB limit. Please select a smaller file.`);
      } else {
        if (fileCount < 10) {
          console.log(`file.uri is ${file.uri}`);

          selectedAudios.push({
            uri,
            type: 'audio/mpeg',
            // type: file.mimeType || 'audio/mpeg', // Ensure the correct MIME type is set
            name: file.name,
            width: 0,
            height: 0,
            duration,
            sizeInMB: sizeInMB,
          });
          fileCount++;
        }
          else {
            alert(`You can upload unlimited images, but can only upload 10 at a time`);
            break;
        }

      }    
        // Unload the sound to free up resources
        await sound.unloadAsync();
      }
      console.log(`selectedAudios`);
      console.log(selectedAudios);
      setAudioFiles(selectedAudios);
    }
  } catch (error) {
    console.error('Error picking audio files:', error);
  }
};

const isTotalFileSizeOver25MB = () => {
  let fileSizeTotal = 0;
  const mergedFiles = [...files, ...audioFiles];

  // Iterate through 'files' and sum up 'sizeInMB' values
  mergedFiles.forEach(file => {
      if (file.sizeInMB) {
          fileSizeTotal += file.sizeInMB;
      }
  });

  console.log(`fileSizeTotal is ${fileSizeTotal}`);
  return fileSizeTotal >= 25;
};
const onImagePick = async () => {
  console.log(`onImagePick`);
  const selectedImages = await pickImageHandler(true,true);
  console.log(selectedImages);
  if(!selectedImages){
    return;
  }

  const imageURIs = [];
  let fileCount = audioFiles.length;
  for (let asset of selectedImages) {
      const sizeInMB = calculateBase64FileSize(asset);
      console.log(`sizeInMB after calculateBase64FileSize is ${sizeInMB}`);  
      if (sizeInMB > 8) {
          alert(`Selected file ${asset.fileName} exceeds 8 MB limit. Please select a smaller file.`);
      } else {
        if (fileCount < 10) {
          console.log(`asset.uri is ${asset.uri}`);
          imageURIs.push({
              uri: asset.uri,
              type: responsiveStyles.isWeb ? asset.uri.includes("data:video") ? "mp4" : asset.mimeType : asset.mimeType,
              name: asset.fileName,
              width: asset.width,
              height: asset.height,
              duration: asset.duration,
              sizeInMB: sizeInMB,
          });
          fileCount++;
        }
          else {
            alert(`You can upload unlimited images, but can only upload 10 at a time`);
            break;
        }

      }
  }

  // const imageURIs = selectedImages.map(asset => ({
  //   uri: asset.uri,
  //   type: asset.mimeType,
  //   name: asset.name,
  //   width: asset.width,
  //   height: asset.height,
  //   duration: asset.duration,
  // }));
  console.log(`selectedImages is`);
  console.log(selectedImages);

  // setFiles((curFiles) => {
  //   const newInputs = [
  //     ...curFiles,
  //     ...imageURIs,
  //   ];
  //   return newInputs;
  // });

  setFiles(imageURIs);
}
    const removeFileByUri = (uri) => {
      setFiles(files.filter(file => file.uri !== uri));
      setAudioFiles(audioFiles.filter(file => file.uri !== uri));
    };
    function inputChangedHandler(inputIdentifier, enteredValue,isChanged) {
      setInputs((curInputs) => {
        const newInputs = {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        };
        return newInputs;
      });
      return;
    }
    const [isLoading, setIsLoading] = useState(false);

    const SavePersonalDetails = async () => {
      const UserNameIsValid = isPostTributeInvitation && !userSettingsState?.userSettings?.UserId ? 
      (inputs.UserName.value.toString().trim().length > 0 && inputs.UserName.value.toString().trim().length <= 50)
       : true;
      const TitleIsValid = inputs.Title.value.toString().trim().length > 0;
      const DescriptionIsValid = inputs.Description.value.toString().trim().length > 0;
      if (!TitleIsValid || !DescriptionIsValid || !UserNameIsValid) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.UserName.isValid = UserNameIsValid;
          updatedInputs.Title.isValid = TitleIsValid;
          updatedInputs.Description.isValid = DescriptionIsValid;
          return updatedInputs;
        });

        if (!UserNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.UserName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.UserName.layoutY, x: inputs.UserName.layoutY, animated: true });
          }
        } 
        else if (!TitleIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.Title.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.Title.layoutY, x: inputs.Title.layoutY, animated: true });
          }
        } 
        else if (!DescriptionIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.Description.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.Description.layoutY, x: inputs.Description.layoutY, animated: true });
          }
        } 

        return;
      }

      if(isTotalFileSizeOver25MB()){
        alert(i18n.t('account.MyAccount_ManageMediaPost_MaxMB'));
        return;
      }

      console.log(`SavePersonalDetails passed`);
      const formData = new FormData();
      let userData = {
        UserName: isPostTributeInvitation && !userSettingsState?.userSettings?.UserId ? inputs.UserName.value : null,
        UserProfileId: UserId,
        Id: PostId ? PostId : 0,
        Title: inputs.Title.value,
        Description: inputs.Description.value,
        IsActive: true,
    }

    let sendMessageFileVMList = [];
    const createSendMessageFileVM = (file, fileName) => {
      return new Promise((resolve, reject) => {
        try {
          if (responsiveStyles.isWeb && (file.uri.includes("data:video") || file.uri.includes("data:audio"))) {
            const videoElement = document.createElement('video');
            videoElement.src = file.uri;
    
            videoElement.onloadedmetadata = () => {
              const width = videoElement.videoWidth;
              const height = videoElement.videoHeight;
              const duration = videoElement.duration * 1000;
              resolve({
                FileName: fileName,
                Width: width,
                Height: height,
                Duration: duration,
              });
            };
    
            videoElement.onerror = () => {
              reject(new Error(`Error loading video: ${file.uri}`));
            };
          } else {
            resolve({
              FileName: fileName,
              Width: file.width || 0,
              Height: file.height || 0,
              Duration: file.duration || 0,
            });
          }
        } catch (error) {
          console.log(error);
          resolve({
            FileName: fileName,
            Width: file.width || 0,
            Height: file.height || 0,
            Duration: file.duration || 0,
          });
        }
      });
    };
  
    if(!isEditing){

    
    // Handling files for Android
    if (Platform.OS === 'android') {
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          const FileName = `image_${index}.${file.type.includes("mp4") ? "mp4" : "jpg"}`;
          formData.append('files', {
            uri: file.uri,
            name: FileName, // You can dynamically name the files
            type: file.type || 'image/jpeg', // Ensure the correct MIME type is set
          });
          const sendMessageFileVM = await createSendMessageFileVM(file, FileName);
          console.log(`const sendMessageFileVM = createSendMessageFileVM(file, fileName);
            sendMessageFileVM: ${JSON.stringify(sendMessageFileVM)}
            `);
          sendMessageFileVMList.push(sendMessageFileVM);
        };
      }
      if (audioFiles.length > 0) {
        for (let index = 0; index < audioFiles.length; index++) {
          const file = audioFiles[index];
          const FileName = `audio_${index}.mp3`;
          formData.append('files', {
            uri: file.uri,
            name: FileName, // You can dynamically name the files
            type: 'audio/mpeg', // Ensure the correct MIME type is set
          });
          const sendMessageFileVM = await createSendMessageFileVM(file, FileName);
          sendMessageFileVMList.push(sendMessageFileVM);
        };
      }
      // if (files.length > 0) {
      //   for (let index = 0; index < files.length; index++) {
      //     const file = files[index];
      //     const fileName = `image_${index}.${file.type.includes("mp4") ? "mp4" : "jpg"}`;
      //     const blob = await fetch(file.uri).then((res) => res.blob());
      //     formData.append('files', blob, fileName);
      //     const sendMessageFileVM = createSendMessageFileVM(file, fileName);
      //     sendMessageFileVMList.push(sendMessageFileVM);
      //   }
      // }
      // if (audioFiles.length > 0) {
      //   for (let index = 0; index < audioFiles.length; index++) {
      //     const file = audioFiles[index];
      //     const fileName = `audio_${index}.mp3`;
      //     const blob = await fetch(file.uri).then((res) => res.blob());
      //     formData.append('files', blob, fileName);
      //     const sendMessageFileVM = createSendMessageFileVM(file, fileName);
      //     sendMessageFileVMList.push(sendMessageFileVM);
      //   }
      // }
    } else { // Handling files for web (Data URL)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          const fileName = `image_${index}.${file.type.includes("mp4") ? "mp4" : "jpg"}`;
          const blob = await fetch(file.uri).then((res) => res.blob());
          formData.append('files', blob, fileName);
          const sendMessageFileVM = await createSendMessageFileVM(file, fileName);
          console.log(`const sendMessageFileVM = createSendMessageFileVM(file, fileName);
            sendMessageFileVM: ${JSON.stringify(sendMessageFileVM)}
            `);
          sendMessageFileVMList.push(sendMessageFileVM);
        }
      }
      if (audioFiles.length > 0) {
        for (let index = 0; index < audioFiles.length; index++) {
          const file = audioFiles[index];
          const fileName = `audio_${index}.mp3`;
          const blob = await fetch(file.uri).then((res) => res.blob());
          formData.append('files', blob, fileName);
          const sendMessageFileVM = await createSendMessageFileVM(file, fileName);
          sendMessageFileVMList.push(sendMessageFileVM);
        }
      }
    }
    }
    console.log("after adding files");
    const dataToServer = {
      tributePost: userData,
      sendMessageFileVMs: sendMessageFileVMList,
    }
    formData.append('json', JSON.stringify(dataToServer));
    let response;
    if(isPostTributeInvitation){
      response = await AddTributePostInvitation(formData);
      if(response.isCompleted){
        setbodymessge(i18n.t('responseMessages.ManageTributePostScreen_PostTributeInvitation'));
        setGenericAlertVisible(true);
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    else{
    if(isEditing){
      response = await UpdateTributePost(userData);
    }
    else{
      response = await AddTributePost(formData);
    }
    if(response.isCompleted === true)
    {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    }
    else{
      setbodymessge(response.message);
      setGenericAlertVisible(true);
    }
  }
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };

    const [isFirstTime,setIsFirstTime] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errorOnRequest, setErrorOnRequest] = useState(false);
    const [reloadpage, setReloadPage] = useState(false);

    const [numColumns, setNumColumns] = useState(responsiveStyles.screenWidth > 1024 ? 3 : 1);
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Manage Tribute Post";
      }
      const fetchVideoDuration = async () => {
        try {
          console.log(`fetchVideoDuration started`);
          if(responsiveStyles.isWeb){
          const newDurationOfFiles = [];
          const mergedFiles = [...files, ...audioFiles];
          console.log(`fetchVideoDuration mergedFiles: ${mergedFiles.length}`);

          mergedFiles.forEach(async(file) => {
            console.log(`mergedFiles.forEach(async(file) => {, file: ${JSON.stringify(file)}`);
            const duration = await getVideoDurationInTimeSpan(file.uri);
            console.log(`mergedFiles.forEach(async(file) => {, duration: ${JSON.stringify(duration)}`);
            const newDurationFile = {
              uri: file.uri,
              duration: duration
            };
            newDurationOfFiles.push(newDurationFile);
        });
          setDurationOfFiles(newDurationOfFiles);
      }
        } catch (error) {
          console.error('Error fetching video duration:', error);
          // Handle error or set a default duration if needed
          setDurationOfFiles([]); // Example default duration
        }
      };
      const fetchData = async () => {
        try {
          setLoading(true);
          const req = await GetUserProfilePostTribute(PostId);
          if (req.isCompleted) {
            const vm = JSON.parse(req.answer);
            setFiles(vm.Images);
            setInputsByUser(vm.User);
          }
          else{
            setLoading(true);
            setErrorOnRequest(true);
          }
        } catch (err) {
          console.log(err);
          setLoading(true);
          setErrorOnRequest(true);
        }
        finally{
          setLoading(false);
        }
      };
      fetchVideoDuration();
      if(isEditing){
        if(isFirstTime)
          {
            fetchData();
            setIsFirstTime(false);
          }
          else{
            // setLoading(true);
            setErrorOnRequest(false);
            if(errorOnRequest === true)
            {
              fetchData();
            }
          }
      }
      else{
        if(isFirstTime)
          {
        setInputsByUser(null);
        setIsFirstTime(false);
          }
      }
      const onChange = ({ window }) => {
        const newWidth = window.width;
      setNumColumns(newWidth > 1024 ? 3 : 1);
      };
      if(responsiveStyles.isWeb){
        Dimensions.addEventListener('change', onChange);
        }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => {
      backHandler.remove();
      if(responsiveStyles.isWeb){
        Dimensions.removeEventListener('change', onChange);
      }
    };
    },[reloadpage, files.length, audioFiles.length]);

  return <Screen
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
        { 
         !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
        : isEditing && errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : isEditing && loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState} />

    </View> 
    : (
      <KeyboardAvoidingView
      behavior={'padding'}
      style={styles.container}
      >
  <ScrollView 
  ref={scrollViewRef}
      contentContainerStyle={styles.scrollViewContent}
      keyboardShouldPersistTaps='handled'
  >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View style={styles.containerSub}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      onClosePress={() => {
        if(isPostTributeInvitation){
          setInputsByUser(null);
          setFiles([]);
          setAudioFiles([]);
          setDurationOfFiles([]);
          setReloadPage(!reloadpage);
        }
      }}
      />
      <GenericAlert
      userSettingsState={userSettingsState}
      bodymessge={i18n.t('account.buttonmessage1DeleteAlert')}
      buttonmessage1={i18n.t('account.buttonmessage1Yes')}
      buttonmessage2={i18n.t('account.buttonmessage2No')}
      visible={deleteAlertVisible}
      setVisible={setDeleteAlertVisible}
      buttonmessage1onPress={DeleteTributeHandler}
      
      />

      <View style={styles.titleContainer}>
        <Text style={styles.title}>{isEditing ? i18n.t('account.MyAccount_ManageTributePost_Edit') : i18n.t('account.MyAccount_ManageTributePost_New')} {i18n.t('account.MyAccount_ManageTributePost_Post')}</Text>
    </View>
    <View 
    style={{ flexDirection: !isEditing ? 'column' : responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : 'column',
      // alignItems: 'center',
     }}
    >
    <View 
        style={[
          //   responsiveStyles.isDesktop || responsiveStyles.isLaptop 
          // ? { flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', justifyContent: 'space-evenly' } 
          // : null
          responsiveStyles.isDesktop || responsiveStyles.isLaptop 
          ? { alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start' } 
          : null,
          { 
            width: !isEditing ? null :  responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.30 : null, 
            zIndex: 999,
            alignItems: isEditing ? null : 'center',
          }
        ]}
    >
         { isPostTributeInvitation && !userSettingsState?.userSettings?.UserId ?  
    <View
    style={[
      // responsiveStyles.isDesktop || responsiveStyles.isLaptop 
      // ? { alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start' } 
      // : null
    ]}
    onLayout={(event) => {
        console.log(event.nativeEvent);
        onLayoutChange('UserName', event.nativeEvent.layout.y);
    }}
    >
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.UserName.value}
    maxLetters={50}
    setValue={(value) => {
        inputChangedHandler("UserName", value, true);
    }}
    label={`*${i18n.t('account.MyAccount_ManageTributePost_UserName')}`}
    errorMessage={`${i18n.t('account.MyAccount_ManageTributePost_UserName_ErrorMessage')}`}
    isRequired={true}
    isValid={inputs.UserName.isValid}
    styleTextInputContainer={styles.textInputName}
    />
    </View>
    : null }
    <View
    style={{flexDirection: 'column'}}
    onLayout={(event) => {
        console.log(event.nativeEvent);
        onLayoutChange('Title', event.nativeEvent.layout.y);
    }}
    >
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Title.value}
    setValue={(value) => {
        inputChangedHandler("Title", value, true);
    }}
    label={`*${i18n.t('account.MyAccount_ManageTributePost_Title')}`}
    errorMessage={`${i18n.t('account.MyAccount_ManageTributePost_Title_ErrorMessage')}`}
    isRequired={true}
    isValid={inputs.Title.isValid}
    styleTextInputContainer={styles.textInputName}
    />

    </View>
    <View
    style={{flexDirection:'column'}}
    onLayout={(event) => {
        console.log(event.nativeEvent);
        onLayoutChange('Description', event.nativeEvent.layout.y);
    }}
    >
<TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.Description.value}
    setValue={(value) => {
        inputChangedHandler("Description", value, true);
    }}
    label={`*${i18n.t('account.MyAccount_ManageTributePost_Description')}`}
    errorMessage={i18n.t('account.MyAccount_ManageTributePost_Description_ErrorMessage')}
    isRequired={true}
    isValid={inputs.Description.isValid}
    // styleTextInputContainer={styles.inputStyleOneRow}
    styleTextInputContainer={styles.textInputName}
    multiline={true}
    heightTextInput={responsiveStyles.screenHeight * 0.15 }
    />
    </View>
    </View>
    <>
    {isEditing ? <>
    <View style={{
            width: responsiveStyles.screenWidth, 
            marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : -paddingScreen, 
            // backgroundColor:'blue'
            width:responsiveStyles.screenWidth * 0.7,
      }}>
      <ImageSlider 
      userSettingsState={userSettingsState} 
      images={files}
      durationOfFiles={durationOfFiles}
      isFromManagePost={true}
      paddingScreenFromManagePost={paddingScreen}
      />
    </View>
    </> :
    <>
    <View style={styles.contentTypeContainer}>
    <Text style={[styles.text, { paddingBottom: 20 }]}>{i18n.t('account.MyAccount_ManageTributePost_TypeOfContent')}</Text>
    <View style={styles.checkboxButtonContainer}>
    <CheckboxButton 
    userSettingsState={userSettingsState} 
    text={i18n.t('account.MyAccount_ManageTributePost_Photos')} 
    isChecked={isImageChecked}
    toggleCheckbox={() => setIsImageChecked(true)}
     />
    <CheckboxButton 
    userSettingsState={userSettingsState} 
    text={i18n.t('account.MyAccount_ManageTributePost_Audio')}
    isChecked={!isImageChecked}
    toggleCheckbox={() => setIsImageChecked(false)}
     />
    </View>
    </View>
    <TouchableOpacity style={styles.uploadFileContainer} onPress={handleFilePick}>
    <Feather name="upload" size={36} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} style={styles.uploadIcon} />
      <Text style={styles.text}>
        {
          isImageChecked ? 
          i18n.t('account.MyAccount_ManageTributePost_UploadTitle')
          : i18n.t('account.MyAccount_ManageTributePost_UploadAudio')
        }
        </Text>
    </TouchableOpacity>
    <Text style={[styles.text, { paddingBottom: 20 }]}>{i18n.t('account.MyAccount_ManagePost_note1')}</Text>
    {/* <DraggableFlatListRow
    numColumns={numColumns}
      // saveAllDraggableListsFunc={saveAllDraggableListsFunc}
      data={responsiveStyles.isRTL ? rearrangeDataForRTL([...files,...audioFiles], numColumns) : [...files,...audioFiles]}
      userSettingsState={userSettingsState}
      // UpdateDraggableListIndex={UpdateDraggableListIndex}
      IsActive={true}
      // OptionsDeleteCallBack={async (Id,IsMainCategory, MainCategoryId) => {
      //   setIsDeleteLoaderVisible(true);
      //   let response;
      //   if(IsMainCategory)
      //   {
      //     response = await DeleteMainCategories(Id);
      //   }
      //   else{
      //     response = await DeleteCategory(Id);
      //   }
      //   setIsDeleteLoaderVisible(false);
      //   if(response.isCompleted === true)
      //   {
      //     setRefreshPage(!refreshPage);
      //     if(!IsMainCategory)
      //     {
      //       await UpdateCategoryLength(MainCategoryId, false);
      //     }
      //   }
      //   else{
      //     setbodymessge(response.message);
      //     setGenericAlertVisible(true);
      //   }
      // }}
      // OptionsActivateCallBack={async (Id,IsMainCategory, MainCategoryId) => {
      //   setIsDeleteLoaderVisible(true);
      //   let response;
      //   if(IsMainCategory)
      //   {
      //     response = await ActivateMainCategories(Id, undefined);
      //   }
      //   else{
      //     response = await ActivateCategory(Id);
      //   }
      //   setIsDeleteLoaderVisible(false);
      //   if(response.isCompleted === true)
      //   {
      //     setRefreshPage(!refreshPage);
      //     if(!IsMainCategory)
      //     {
      //       await UpdateCategoryLength(MainCategoryId, true);
      //     }
      //   }
      //   else{
      //     setbodymessge(response.message);
      //     setGenericAlertVisible(true);
      //     // Alert.alert(null,response.message, [
      //     //   {
      //     //     text: i18n.t('error.ok'),
      //     //   },
      //     // ],
      //     // {cancelable:true}
      //     // );
      //   }
      // }}
      /> */}
    <FlatList
          scrollEnabled={false}
          style={styles.flatListContainer}
          key={numColumns}
          numColumns={numColumns}
          data={responsiveStyles.isRTL ? rearrangeDataForRTL([...files,...audioFiles], numColumns) : [...files,...audioFiles]}
          renderItem={({ item, index }) =>
          {
            console.log(`item`);
            console.log(item);
            const IsAudio = (item.type?.toString()?.includes("audio") || item.type === 3);
            const IsVideo = (item.type?.toString()?.includes("video") 
            || (IsAudio ? false : item.type?.toString()?.includes("mp4"))
            || item.type === 2);


            const durationOfItem = IsVideo || IsAudio ? 
            formatTimeSpan(item.duration) ? formatTimeSpan(item.duration) :
            responsiveStyles.isWeb ? (durationOfFiles.find(s => s.uri === item.uri)?.duration || null) : 
            formatTimeSpan(item.duration) : null;

            console.log(`durationOfItem is ${durationOfItem}`);

            return <View style={styles.itemContainer}>
                            {
                IsAudio || IsVideo ? <>
                <VideoMediaPrimary
                videoUri={item.uri} // should be a URI of an audio image
                isAudio={IsAudio}
                durationTime={durationOfItem}
                userSettingsState={userSettingsState}
                isDisplaying={false}
                videoStyle={[styles.userPhoto, 
                  IsAudio ? { borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor } : null
                ]}
                videoContainerStyle={{flex:null, backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor }}
                onPlayShowFullScreen={true}
                resizeMode="contain"
                />
                </>
              : <>
            <ImageProfile
            userSettingsState={userSettingsState}
            uri={item.uri}
          imageStyle={styles.userPhoto}
          editable={false}
          isGroup={false}
          isShowReloading={true}
          resizeMode="contain"
            />
              </>
              }

          <TouchableOpacity 
          style={styles.deleteContainer}
          onPress={() => removeFileByUri(item.uri)}
          >
          <Feather name="trash-2" size={20} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
            </TouchableOpacity>
        </View>
          }}
          />
</>
}
</>
</View>

{
    (isTotalFileSizeOver25MB()) ?
    <Text style={styles.text}>{i18n.t('account.MyAccount_ManageMediaPost_MaxMB')}</Text>
    : null
   }

{
  files.length > 0 || audioFiles.length > 0 ? 
<Text style={[styles.text, { paddingVertical: 20 }]}>{i18n.t('account.MyAccount_ManagePost_note2')}</Text>
: null
}
   
<View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        // styleButton={{backgroundColor: isEditing ? GlobalStyles.colors.UpdateButton : GlobalStyles.colors.AddButton}}
        loading={isLoading}
         onPress={async () => {
            setIsLoading(true);
            await SavePersonalDetails();
            setIsLoading(false);
        }}
        >
          {i18n.t('account.MyAccount_ManageTributePost_Save')}
    </ButtonTypeButton>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
        style={styles.button}
        // styleButton={{backgroundColor: GlobalStyles.colors.CancelButton}}
         onPress={() => {
          responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
        }}
        >
          {i18n.t('account.MyAccount_ManageTributePost_Cancel')}
    </ButtonTypeButton>
    </View>
    {
      PostId ? <TouchableOpacity 
          style={styles.deleteContainer}
          onPress={() => setDeleteAlertVisible(true)}
          >
          <Feather name="trash-2" size={36} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
            </TouchableOpacity>
            : null }
    </View>
    </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    )}
    </Screen>

}

ManageTributePostScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles, isEditing, paddingScreen) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  containerSub: {
    flex: 1,
    padding: paddingScreen,
    width: isEditing ? null : responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 : null,
    alignSelf: isEditing ? null : responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  titleContainer: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
},
title: {
  fontSize: responsiveStyles.fontSize_H3,
    fontWeight: '700',
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
contentTypeContainer: {

},
checkboxButtonContainer: {
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent:'space-around',
},
deleteContainer: {
  // marginTop: 16,
  // paddingTop: 8,
  // // borderTopWidth: 2,
  // borderTopColor: GlobalStyles.colors.Red,
  alignItems: 'center',
  paddingTop: 20,
  paddingBottom: 20,
},
itemContainer: {
  // flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent:'space-between',
  alignItems: 'center',
  paddingBottom: 20,
},
flatListContainer: {
  // padding: 5,
  maxWidth: getImageWidth(responsiveStyles),
  alignSelf: responsiveStyles.isWeb ? 'center' : null,
},
userPhoto: {
  // width: imageWidth,
  // height: imageWidth,
  // maxWidth: 300,
  //   maxHeight: 300,
  width: responsiveStyles.isWeb ? getwidthOfImage(responsiveStyles) : getImageWidth(responsiveStyles),
    height: responsiveStyles.isWeb ? getwidthOfImage(responsiveStyles) : getImageWidth(responsiveStyles),
    maxWidth: responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : 300,
    maxHeight: responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : 300,
  marginBottom: 5,
  marginHorizontal: 5,
},
uploadFileContainer: {
  alignItems:'center',
  paddingVertical: 20,
  marginVertical: 20,
  borderWidth: 1,
  borderStyle: 'dashed',
  borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
uploadFile: {
  width: imageWidth,
  height: imageWidth,
},

buttonContainer: {
  marginVertical: 20,
  alignItems:'center',
  flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  justifyContent:'space-around'
},
button: {
  width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.3,
  minWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 125 : null,
},
text: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
uploadIcon: {
  paddingBottom: 10,
},
inputStyleOneRow: {
  width: 
  responsiveStyles.isDesktop ? (responsiveStyles.screenWidth) * 0.57
  : responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.55
  // : responsiveStyles.isTablet ? (responsiveStyles.screenWidth) * 0.52
  : null,
  
},
textInputName: {
  width: isEditing ? null : 
  responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.29 
  : responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.4 
  : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.5
  : responsiveStyles.screenWidth * 0.7,
  // backgroundColor: isAdmin ? null : 'gray',
},
});

export default ManageTributePostScreen;
