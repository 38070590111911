import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const PendentColorPickerContainer = ({ 
  userSettingsState, 
  foundItem,
  stoneColor,
  setStoneColor,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [hoveredColor, setHoveredColor] = useState(null);

  
  const isHasMaterial = foundItem.IsMotherOfPearl || foundItem.IsMalachite || foundItem.IsBlackAgate
  || foundItem.IsRedAgate || foundItem.IsLapisLazuli || foundItem.IsTigersEye;

  useEffect(() =>{
    if(stoneColor === null){
      if(foundItem.IsMotherOfPearl){
        setStoneColor('Mother_Of_Pearl');
      }
      else if(foundItem.IsMalachite){
        setStoneColor('Malachite');
      }
      else if(foundItem.IsBlackAgate){
        setStoneColor('Black_Agate');
      }
      else if(foundItem.IsRedAgate){
        setStoneColor('Red_Agate');
      }
      else if(foundItem.IsLapisLazuli){
        setStoneColor('Lapis_Lazuli');
      }
      else if(foundItem.IsTigersEye){
        setStoneColor(`Tiger's_Eye`);
      }
    }
    },[]);

  if(!isHasMaterial)
  {
    return null;
  }


  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.text}>{i18n.t('store.PendentColorPickerContainer_title')}:</Text>
      </View>

      {
        foundItem.IsMotherOfPearl ?
        <View style={[styles.colorMainContainer, stoneColor === 'Mother_Of_Pearl' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Mother_Of_Pearl')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: '#f0e8f4' }, 
        ]}
        activeOpacity={1}
        onPress={() => setStoneColor('Mother_Of_Pearl')}
      >
        {hoveredColor === 'Mother_Of_Pearl' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_MotherOfPearl')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }


{
        foundItem.IsMalachite ?
        <View style={[styles.colorMainContainer, stoneColor === 'Malachite' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Malachite')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: '#093e34' }, 
        ]}
        activeOpacity={1}
        onPress={() => setStoneColor('Malachite')}
      >
        {hoveredColor === 'Malachite' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_Malachite')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }


{
        foundItem.IsBlackAgate ?
        <View style={[styles.colorMainContainer, stoneColor === 'Black_Agate' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Black_Agate')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: '#000000' }, 
        ]}
        activeOpacity={1}
        onPress={() => setStoneColor('Black_Agate')}
      >
        {hoveredColor === 'Black_Agate' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_BlackAgate')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }


{
        foundItem.IsRedAgate ?
        <View style={[styles.colorMainContainer, stoneColor === 'Red_Agate' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Red_Agate')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: '#961112' }, 
        ]}
        activeOpacity={1}
        onPress={() => setStoneColor('Red_Agate')}
      >
        {hoveredColor === 'Red_Agate' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_RedAgate')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }


{
        foundItem.IsLapisLazuli ?
        <View style={[styles.colorMainContainer, stoneColor === 'Lapis_Lazuli' ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor('Lapis_Lazuli')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: '#0002bf' }, 
        ]}
        activeOpacity={1}
        onPress={() => setStoneColor('Lapis_Lazuli')}
      >
        {hoveredColor === 'Lapis_Lazuli' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_LapisLazuli')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }


{
        foundItem.IsTigersEye ?
        <View style={[styles.colorMainContainer, stoneColor === `Tiger's_Eye` ? styles.colorWrapper : null]}>
        <TouchableOpacity
        onMouseEnter={() => setHoveredColor(`Tiger's_Eye`)}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, 
          { backgroundColor: '#cc8e05' }, 
        ]}
        activeOpacity={1}
        onPress={() => setStoneColor(`Tiger's_Eye`)}
      >
        {hoveredColor === `Tiger's_Eye` && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_TigersEye')}</Text>
          </View>
        )}
      </TouchableOpacity>
      </View>
      : null
      }

      
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginBottom: 20,
  },
  colorWrapper: {
    padding: 2, // Add small padding between border and color
    borderRadius: 50, // Ensure borderRadius matches the border
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.iconColor,
    borderWidth: 1,
    marginTop: -2,
  },
  colorMainContainer: {
    marginLeft: !responsiveStyles.isRTL ? 20 : null,
    marginRight: responsiveStyles.isRTL ? 20 : null,
  },
  colorContainer: {
    width: 25,
    height: 25,
    borderRadius: 50, // Matches the wrapper's borderRadius
    backgroundColor: 'transparent', // Will be overridden dynamically
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  tooltip: {
    position: 'absolute',
    top: -40, // Position above the color container
    left: '50%',
    transform: [{ translateX: '-50%' }], // Corrected syntax
    padding: 5,
    backgroundColor: 'black',
    borderRadius: 5,
    zIndex: 1, // Ensure tooltip is above other content
  },
  tooltipText: {
    color: 'white',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    minWidth: 150,
    textAlign:'center'
  },
});

export default PendentColorPickerContainer;
