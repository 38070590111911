import React, { useCallback, useContext, useEffect, useState  } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, BackHandler, ScrollView, useWindowDimensions, Platform, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Alert } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import UserImageComponent from '../../components/myAccountScreen/UserImageComponent';
import NameComponent from '../../components/myAccountScreen/NameComponent';
import EmailComponent from '../../components/myAccountScreen/EmailComponent';
import JoinedComponent from '../../components/myAccountScreen/JoinedComponent';
import BottomCardWebComponent from '../../components/myAccountScreen/BottomCardWebComponent';
import ManageTributesComponent from '../../components/displayComponent/ManageTributesComponent';
import { defaultImage } from '../../constants/images';
import ManageMediaComponent from '../../components/displayComponent/ManageMediaComponent';
import AccountForm from '../../components/forms/AccountForm';
import JewleryComponent from '../../components/displayComponent/JewleryComponent';
import { GetUserPersonalDetails, RestoreUserProfile, UpdateUserPersonalDetails } from '../../api/apiCalls/AccountCalls';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import ProfilesComponent from '../../components/displayComponent/ProfilesComponent';
import { getPaddingScreen } from '../../functions/ScreenDimensionsLogic';
import { useNavigate } from 'react-router-dom';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MyAccountScreen_displayType, MyAccountScreen_Jewelry_displayType, MyAccountScreen_Profiles_displayType, MyAccountScreen_ProfilesList_displayType, token_Storage } from '../../modules/StorageObjects';
import { navigateToHomeScreen } from '../../functions/navigateToHomeScreen';
import { getItem, saveAsync } from '../../modules/Storage';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import MyAccountUserInfoComponent from '../../components/myAccountScreen/MyAccountUserInfoComponent';
import JewleryDisplayTypeComponent from '../../components/myAccountScreen/JewleryDisplayTypeComponent';
import BottomCardMobileComponent from '../../components/myAccountScreen/BottomCardMobileComponent';

const MyAccountScreenTest = ({  }) => {
  const {state: userSettingsState, setUserSettingsLanguage, setUserSettingsDarkMode } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const { ResetPassword } = useContext(AuthContext);
    const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
    const styles = styling(responsiveStyles, paddingScreen);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const [reloadpage, setReloadPage] = useState(false);

    const [displayType, setDisplayType] = useState( 'Profiles');
    const [displayType_Jewlery, setDisplayType_Jewlery] = useState('Jewlery');
    const [displayType_Profile, setDisplayType_Profile] = useState('Profiles');
    const [displayType_ProfileList, setDisplayType_ProfileList] = useState('Human');

    const setDisplayTypeWithLocalStorage = async (newDisplayType) => {
      await saveAsync(MyAccountScreen_displayType, newDisplayType);
      setDisplayType(newDisplayType);
    }

    const setDisplayType_Profile_WithLocalStorage = async (newDisplayType) => {
      await saveAsync(MyAccountScreen_Profiles_displayType, newDisplayType);
      setDisplayType_Profile(newDisplayType);
    }
    const setDisplayType_ProfileList_WithLocalStorage = async (newDisplayType) => {
      await saveAsync(MyAccountScreen_ProfilesList_displayType, newDisplayType);
      setDisplayType_ProfileList(newDisplayType);
    }
    const setDisplayType_Jewlery_WithLocalStorage = async (newDisplayType) => {
      await saveAsync(MyAccountScreen_Jewelry_displayType, newDisplayType);
      setDisplayType_Jewlery(newDisplayType);
    }

    
    const [user, setUser] = useState();
    const [profiles, setProfiles] = useState();

    
    const [tributes, setTributes] = useState();
    const [jewlery, setJewlery] = useState();
    const [orders, setOrders] = useState();
    console.log(`tributes is ${JSON.stringify(tributes)}`);
    const [inputs, setInputs] = useState();

    const cleanup = () => {
      console.log(`cleanup`);
      
      setTributes([]);
      setJewlery([]);
      setOrders([]);
      console.log(`finish cleanup`);
    };


    const setInputsByUser = (AccountInformation) => {
      setInputs({
        FirstName: {
          value: AccountInformation?.FirstName ? AccountInformation.FirstName : user?.FirstName ? user.FirstName : "",
          isValid: true,
          isChanged: false,
        },
        LastName: {
          value: AccountInformation?.LastName ? AccountInformation.LastName : user?.LastName  ? user.LastName : "",
          isValid: true,
          isChanged: false,
        },
        State: {
          value: AccountInformation?.State ? AccountInformation.State : user?.State  ? user.State : "",
          isValid: true,
          isChanged: false,
        },
        City: {
          value: AccountInformation?.City ? AccountInformation.City : user?.City  ? user.City : "",
          isValid: true,
          isChanged: false,
        },
        Image:{
            value: AccountInformation && AccountInformation?.UserImage?.length > 0 ? AccountInformation?.UserImage : user?.UserImage?.length > 0 ? user?.UserImage : defaultImage.uri,
            isValid: true,
            isChanged: false,
        }
      });
    }
    console.log(`inputs.Image ${inputs?.Image}`);
    console.log(`user ${JSON.stringify(user)}`);
    const RestoreUserProfileHandler = async (userId) => {
      const response = await RestoreUserProfile(userId);
      if(response.isCompleted === true)
      {
        // setbodymessge(i18n.t('responseMessages.success'));
        // setGenericAlertVisible(true);
        fetchData();
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    const SavePersonalDetails = async () => {
      const FirstNameIsValid = inputs.FirstName.value.toString().trim().length > 0;
      const LastNameIsValid = inputs.LastName.value.toString().trim().length > 0;
      if (!FirstNameIsValid || !LastNameIsValid) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.FirstName.isValid = FirstNameIsValid;
          updatedInputs.LastName.isValid = LastNameIsValid;
          return updatedInputs;
        });
        return;
      }
      console.log(`SavePersonalDetails passed`);
      const formData = new FormData();
      let userData = {
        FirstName: inputs.FirstName.value,
        LastName: inputs.LastName.value,
        State: inputs.State.value,
        City: inputs.City.value,
    }
    if(inputs.Image.isChanged)
      {
        const ImageFileName = 'image.jpg';
        if(responsiveStyles.isWeb){
          console.log(`
            if(inputs.Image.isChanged) 
            if(responsiveStyles.isWeb){
            `);
          const blob = await fetch(inputs.Image.value).then((res) => res.blob());
          formData.append('image', blob, ImageFileName);
        }
        else{
          formData.append('image', {
            uri: inputs.Image.value,
            name: ImageFileName,
            type: 'image/jpeg', // Modify the content type based on your image type
          });
        }
      }

  formData.append('json', JSON.stringify(userData));
  console.log(`formData is selected: ${JSON.stringify(formData)}`);
  const response = await UpdateUserPersonalDetails(formData);
        if(response.isCompleted === true)
        {
          setReloadPage(!reloadpage);
          // setbodymessge(i18n.t('responseMessages.success'));
          // setGenericAlertVisible(true);
        }
        else{
          setbodymessge(response.message);
          setGenericAlertVisible(true);
        }
    };
    const backAction = () => {
      BackHandler.exitApp();
      return true;
    };
  const [isFirstTime,setIsFirstTime] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorOnRequest, setErrorOnRequest] = useState(false);

  const fetchData = async () => {
    try {
      const token = await AsyncStorage.getItem(token_Storage);
      if(!token){
        await navigateToHomeScreen(navigation, true);
      }
      setLoading(true);
      console.log(`calling GetUserPersonalDetails`);
      const req = await GetUserPersonalDetails();
      if (req.isCompleted) {
        const vm = JSON.parse(req.answer);
        setUser(vm.AccountInformation);
        setProfiles(vm.Profiles);
        if(vm.Profiles.filter(s => !s.IsActive).length === 0 && displayType_Profile === "Archived"){
          setDisplayType_Profile('Profiles');
        }
        setTributes(vm.Tributes);
        setJewlery(vm.Jewlery);
        setOrders(vm.Orders);
        setInputsByUser(vm.AccountInformation);
      }
      else{
        setLoading(true);
        setErrorOnRequest(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(true);
      setErrorOnRequest(true);
    }
    finally{
      setLoading(false);
    }
  };

  const setDisplayType_ForAll = async () => {
    const newDisplayType = await getItem(MyAccountScreen_displayType);
    if(newDisplayType){
      setDisplayType(newDisplayType);
    }
    const newDisplayType_ProfilesList = await getItem(MyAccountScreen_ProfilesList_displayType);
    if(newDisplayType_ProfilesList){
      setDisplayType_ProfileList(newDisplayType_ProfilesList);
    }
    const newDisplayType_Profiles = await getItem(MyAccountScreen_Profiles_displayType);
    if(newDisplayType_Profiles){
      setDisplayType_Profile(newDisplayType_Profiles);
    }
    const newDisplayType_Jewelry = await getItem(MyAccountScreen_Jewelry_displayType);
    if(newDisplayType_Jewelry){
      setDisplayType_Jewlery(newDisplayType_Jewelry);
    }
  }
  const InitFunction = () => {
    if (isFirstTime) {
      setDisplayType_ForAll();
      fetchData();
      setIsFirstTime(false);
    } else {
        // setLoading(true);
      setErrorOnRequest(false);
      if (errorOnRequest === true) {
        fetchData();
      }
      else{
        fetchData();
      }
    }
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
    return () => {
      backHandler.remove();
    };
  }

  if(responsiveStyles.isWeb){
    useEffect(() =>{
      
      if(responsiveStyles.isWeb){
        document.title = "My Account";
      }

      InitFunction();
      
      if(responsiveStyles.isWeb){

        const interval = setInterval(() => {
          const scrollView = document.getElementById('MainScrollView');
          if (scrollView) {
            // Get all classes on the element
            const classList = Array.from(scrollView.classList);
    
            // Remove classes that start with "r-overflow"
            classList.forEach((className) => {
              if (className.startsWith('r-overflow')) {
                scrollView.classList.remove(className);
              }
            });
          }
        }, 1000); // Execute every 1 second

      return () => clearInterval(interval);
      }
      
  },[reloadpage])
  }
  else{
    useFocusEffect(
      useCallback(() =>{
        InitFunction();
    },[reloadpage])
  );
  }

    // useEffect(() =>{
    //   if(isFirstTime)
    //     {
    //       fetchData();
    //       setIsFirstTime(false);
    //     }
    //     else{
    //       // setLoading(true);
    //       setErrorOnRequest(false);
    //       if(errorOnRequest === true)
    //       {
    //         fetchData();
    //       }
    //     }
    // //   const backHandler = BackHandler.addEventListener(
    // //     'hardwareBackPress',
    // //     backAction,
    // //   );
    // // return () => backHandler.remove();
    // },[reloadpage]);

    const [childFunction, setChildFunction] = useState(null);
    const onScrollEndChildFunction = (fn) => {
      setChildFunction(() => fn); // Store the child's function in state
    };
    const handleScroll = (event) => {
      const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
      if (layoutMeasurement.height + contentOffset.y >= contentSize.height - responsiveStyles.screenHeight) {
          if (childFunction) {
            childFunction(); // Call the child's function from the parent
          } else {
            // Alert.alert('No function provided by the child yet');
          }
      } else {

      }
    };

  return <Screen
  userSettingsState={userSettingsState}
  navigation={navigation}
  cleanup={cleanup}
  >
        {errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
      <KeyboardAvoidingView
      behavior={'padding'}
      style={[styles.container]}
      >
  <ScrollView 
    contentContainerStyle={[styles.scrollViewContent]}
    id='MainScrollView'
    keyboardShouldPersistTaps='handled'
    onScroll={handleScroll}
    scrollEventThrottle={16}
  >
  <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View style={styles.container}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />

<View style={styles.TitleContainer}>
        <Text style={styles.title}>{i18n.t('account.MyAccount_Title')}</Text>
        </View>

      <View style={{flex:1, 
        flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
         responsiveStyles.isRTL ? 'row-reverse' : 'row'
         : 'column',
        }}>

          <View style={{ 
            width: '100%', 
            flex:1, 
            alignSelf: 'flex-start', 
            paddingHorizontal: 20, 
            position: responsiveStyles.isWeb ? 'sticky' : 'relative',
            top: responsiveStyles.isWeb ? 100 - 20 : undefined,
            zIndex: 1,
             }}>

          <MyAccountUserInfoComponent userSettingsState={userSettingsState} user={user} navigation={navigation}  />
          {responsiveStyles.isWeb ?
            <BottomCardWebComponent
      userSettingsState={userSettingsState}
       displayType={displayType} 
       setDisplayType={setDisplayTypeWithLocalStorage} 
       setInputsByUser={setInputsByUser}
        />
        : 
        <BottomCardMobileComponent
      userSettingsState={userSettingsState}
       displayType={displayType} 
       setDisplayType={setDisplayTypeWithLocalStorage} 
       setInputsByUser={setInputsByUser}
        />
          }


          </View>

        <View style={{flex:2}}>
        {/* <ScrollView 
     contentContainerStyle={styles.scrollViewContent}
     keyboardShouldPersistTaps='handled'
     onScroll={handleScroll}
     scrollEventThrottle={16}
   > */}
        {
      displayType === 'Profiles' ? 
      <ProfilesComponent 
      userSettingsState={userSettingsState}
      navigation={navigation} 
      cleanup={cleanup}
      profiles={profiles} 
      jewlery={jewlery} 
      RestoreUserProfile={RestoreUserProfileHandler}
      setDisplayType={setDisplayType_Profile_WithLocalStorage}
      displayType={displayType_Profile}
      profileListDisplayType={displayType_ProfileList}
      setProfileListDisplayType={setDisplayType_ProfileList_WithLocalStorage}
      />
      : displayType === 'Jewlery' ? 
      <JewleryComponent 
      userSettingsState={userSettingsState}
      navigation={navigation} 
      cleanup={cleanup}
      profiles={profiles} 
      jewlery={jewlery} 
      orders={orders}
      setDisplayType={setDisplayType_Jewlery_WithLocalStorage}
      displayType={displayType_Jewlery}
      />
      : displayType === 'Account' ? 
      <AccountForm 
      userSettingsState={userSettingsState}
      inputs={inputs} 
      setInputs={setInputs} 
      submitHandler={SavePersonalDetails}
      setUserSettingsLanguage={setUserSettingsLanguage}
      setUserSettingsDarkMode={setUserSettingsDarkMode}
      navigation={navigation}
      user={user}
      ResetPassword={ResetPassword}
       />
      :
      <View style={{ paddingTop: 10, }}>
      <ManageTributesComponent 
      data={tributes} 
      userSettingsState={userSettingsState}
      isShowUserProfileFullName={true}
      navigation={navigation}
      // UserId={}
      onScrollEndParentFunction={onScrollEndChildFunction}
      isFromMyAccountScreen={true}
      />
      </View>
    }
        {/* </ScrollView> */}
        </View>

      </View>

      {/* <UserImageComponent userSettingsState={userSettingsState} user={user}  />
      <NameComponent userSettingsState={userSettingsState} user={user}  />
      <EmailComponent userSettingsState={userSettingsState} user={user}  />
      <JoinedComponent userSettingsState={userSettingsState} user={user}  />

      */}


    
    </View>
     </TouchableWithoutFeedback>
     </ScrollView>
     </KeyboardAvoidingView>
    )}
    </Screen>

}

MyAccountScreenTest.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles, paddingScreen) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // padding: 20,
    // height: fixedScreenHeight,
    
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  TitleContainer: {
    // backgroundColor: 'blue',
    paddingVertical: 20,
    // paddingHorizontal: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.15 : 20,
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 
    : responsiveStyles.screenWidth * 0.8,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },

});

export default MyAccountScreenTest;
