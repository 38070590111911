import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { GlobalStyles } from '../../constants/styles';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const CheckboxButton = ({ 
  text, 
  userSettingsState,
  isChecked,
  toggleCheckbox,
}) => {
  
  const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);
  // const [isChecked, setIsChecked] = useState(initialState);

  // const toggleCheckbox = () => {
  //   setIsChecked(!isChecked);
  // };

  return (
    <TouchableOpacity
      style={styles.checkboxContainer}
      onPress={toggleCheckbox}
      activeOpacity={0.8}
    >
      <View style={[styles.checkbox, isChecked && styles.checked]}>
        {isChecked && <Text style={styles.checkmark}>✓</Text>}
      </View>
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 3,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  checked: {
    backgroundColor: 'blue', // Change the background color when checked
  },
  checkmark: {
    color: 'white',
  },
  text: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
});

export default CheckboxButton;
