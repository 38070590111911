export default {
  header: "תשלום הצליח!",
  thankYouNote1: "תודה לך,",
  thankYouNote2: " , על רכישתך! התשלום שלך הושלם בהצלחה.",
  orderTitle: "סיכום הזמנה",
  orderNumber: "מספר הזמנה:",
  orderDate: "תאריך הזמנה:",
  amountPaid: "סכום ששולם:",
  ItemsPurchased: "פריטים שנרכשו:",
  total: "סך הכל:",
  nextSteps: "ההזמנה שלך מתבצעת ותישלח אליך בקרוב. תקבל דוא\"ל עם פרטי המעקב לאחר שההזמנה שלך נשלחה.",
  thankYouNoteFinal: "אנו מעריכים את העסק שלך ומקווים שתיהנה מהקנייה שלך!",
  continueShoppingButton: "המשך לקניות",
  orderDetailsButton: "צפה בפרטי ההזמנה",
  contactInfo: "אם יש לך שאלות או זקוק לעזרה נוספת, אנא פנה לתמיכה שלנו ב",
};