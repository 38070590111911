import { Platform } from 'react-native';
import { saveAsync } from './modules/Storage';
import { EditProfileScreen_displayType, MyAccountScreen_displayType, MyAccountScreen_Jewelry_displayType, MyAccountScreen_Profiles_displayType, ProfileScreen_displayType, ProfileScreen_mediaDisplayTypeTribute } from './modules/StorageObjects';


export const navigateToScreen = async (navigation, mainStackScreen, secondaryStackScreen, params, itemId) => {
  console.log(`navigateToScreen, 
    mainStackScreen: ${mainStackScreen}, 
    secondaryStackScreen: ${secondaryStackScreen},
    params: ${JSON.stringify(params)}
    itemId: ${itemId}
     `);


     await RemoveAsyncStorageStateByStackScreen(secondaryStackScreen);

  if(Platform.OS === "web"){
       const screenTo = itemId ? `/${secondaryStackScreen}/${itemId}` : `/${secondaryStackScreen}`;
       navigation(screenTo, {state: params});
    // navigation(`/${secondaryStackScreen}`, { userId: 123, username: 'john_doe' });
  }
  else{
    
    // navigation.replace(mainStackScreen, { screen: secondaryStackScreen, params: params});
    navigation.navigate(mainStackScreen, { screen: secondaryStackScreen, params: params});
  }
};

const RemoveAsyncStorageStateByStackScreen = async (StackScreenTmp) => {
  const StackScreen = StackScreenTmp.toLowerCase();
  console.log(`RemoveAsyncStorageStateByStackScreen StackScreen: ${StackScreen}`);
  if(StackScreen === "EditProfile".toLowerCase())
  {
    await saveAsync(EditProfileScreen_displayType, null);
  }
  if(StackScreen === "MyAccount".toLowerCase())
  {
    console.log(`if(StackScreen === "MyAccount")`);
    await saveAsync(MyAccountScreen_displayType, null);
    await saveAsync(MyAccountScreen_Profiles_displayType, null);
    await saveAsync(MyAccountScreen_Jewelry_displayType, null);
  }
  if(StackScreen === "myjewelry".toLowerCase() || StackScreen === "Profile".toLowerCase())
    {
      await saveAsync(ProfileScreen_displayType, null);
      await saveAsync(ProfileScreen_mediaDisplayTypeTribute, null);
    }
}
