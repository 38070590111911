import React, { useState, useEffect } from 'react';
import { Modal, View, Image, TouchableOpacity, StatusBar, StyleSheet, Dimensions, Text, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { GlobalStyles } from '../../constants/styles';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const ImageModal = ({ 
  userSettingsState,
  imageUri,
  visible,
  onClose, 
  downloadable = false, 
  userName = null,
  chatType = "user",
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  if(visible){
    console.log(imageUri);
  }

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <TouchableOpacity 
        style={styles.imageContainer}
         activeOpacity={1}
         >
            <ReactNativeZoomableView
            maxZoom={3}
            minZoom={1}
            zoomStep={0.5}
            initialZoom={1}
            bindToBorders={true}
            zoomEnabled={true}
            panEnabled={true}              
            >
          <FastImage
            source={imageUri}
            resizeMode="contain"
            style={{ flex: 1, width: '100%' }}
            onError={() => console.log("error on image")}
          />
          </ReactNativeZoomableView>
          {userName !== null ? 
          <View style={styles.topRowContainer}>
          <TouchableOpacity
          style={styles.closeContainer}
          onPress={onClose}
        >
          <AntDesign name="closecircle" size={24} color="white" />
        </TouchableOpacity>
        <View style={styles.userNameContainer}>
            <Text style={styles.userNameText}>{userName}</Text>
            </View>
            {chatType === "group" ? 
            <TouchableOpacity
          style={styles.shareContainer}
          // onPress={onClose}
        >
          <AntDesign name="sharealt" size={24} color="white" />
        </TouchableOpacity>
        : null }
          </View>
          : null }
        </TouchableOpacity>
        {userName === null ?
        <TouchableOpacity
          style={{
            position: 'absolute',
            top: 30,
            right: 20,
          }}
          onPress={onClose}
        >
          <AntDesign name="closecircle" size={24} color="white" />
        </TouchableOpacity>
        : null }
      </View>
    </Modal>
  );
};
const styling = (responsiveStyles) => StyleSheet.create({
  topRowContainer: {
    position: 'absolute',
    alignSelf: 'center',
    width:'100%',
    paddingVertical: 15,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundColor: GlobalStyles.colors.Gold,
    alignItems:'center'

  },
  userNameContainer:{
    flex:1,
    alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start',
  },
  userNameText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    marginHorizontal: 20,
    fontFamily: GlobalStyles.fonts.application_font,
    

  },
  imageContainer: {
    backgroundColor:'black',
    flex:1,
  },
  closeContainer: {
    marginHorizontal: 10,
  },
  shareContainer: {
    marginHorizontal: 10,
  },
});
export default ImageModal;
