import { Alert, View } from "react-native";
import { useEffect, useState } from "react";
import i18n from "../../hooks/useI18n";
import CustomAlert from "../modals/CustomAlert";
import { navigateToScreen } from "../../navigationResponsive";

// function CantFindAccount(userName, navigation) {
//   Alert.alert(i18n.t('auth.SignIn_CantFindAccountAlert_subject'),
//   i18n.t('auth.SignIn_CantFindAccountAlert_message1') + userName + i18n.t('auth.SignIn_CantFindAccountAlert_message2'), [
//       {
//         text: i18n.t('auth.SignIn_CantFindAccountAlert_signUpText'),
//         onPress: () => {
//           navigation.navigate('SignUpWithEmail',{ isEmail: true });
//       },
//         style: 'cancel',
//       },
//       {text: i18n.t('auth.SignIn_CantFindAccountAlert_tryAgainText')},
//     ],
//     {cancelable:true}
//     );
//   }
  
//   function IncorrectPassword() {
//     Alert.alert(i18n.t('auth.SignIn_IncorrectPasswordAlert_subject'),i18n.t('auth.SignIn_IncorrectPasswordAlert_message'), [
//         {text: i18n.t('auth.SignIn_IncorrectPasswordAlert_signUpText')},
//       ],
//       {cancelable:true}
//       );
//   }
//   function EmailRequired() {
//     Alert.alert(i18n.t('auth.SignIn_EmailRequired_subject'),i18n.t('auth.SignIn_EmailRequired_message'), [
//         {text: i18n.t('auth.SignIn_EmailRequired_signUpText')},
//       ],
//       {cancelable:true}
//       );
//   }
//   function PasswordRequired() {
//     Alert.alert(i18n.t('auth.SignIn_PasswordRequired_subject'),i18n.t('auth.SignIn_PasswordRequired_message'), [
//         {text: i18n.t('auth.SignIn_PasswordRequired_signUpText')},
//       ],
//       {cancelable:true}
//       );
//   }
// export {CantFindAccount,IncorrectPassword,EmailRequired,PasswordRequired};

  export const SigninAlert = ({ 
    userName, 
    navigation, 
    state, 
    addErrorMessage,
    userSettingsState,
   }) => {
    const [CantFindAccountVisible, setCantFindAccountVisible] = useState(false);
    const [IncorrectPasswordVisible, setIncorrectPasswordVisible] = useState(false);
    const [EmailRequiredVisible, setEmailRequiredVisible] = useState(false);
    const [PasswordRequiredVisible, setPasswordRequiredVisible] = useState(false);
    const [ServerMessageVisible, setServerMessageVisible] = useState(false);
    const [MessageFromServer, setMessageFromServer] = useState("");

  useEffect(() => {
      // state.signinAlertMessage === "CantFindAccount" ? CantFindAccount(email,navigation) :
      // state.signinAlertMessage === "IncorrectPassword" ? IncorrectPassword() : null;
      console.log(`useEffect in alertsssss state.signinAlertMessage ${state.signinAlertMessage}`);
      if(state.signinAlertMessage === "CantFindAccount")
      {
        console.log(`if(state.signinAlertMessage === "CantFindAccount")`);
        setCantFindAccountVisible(true);
      }
      else if(state.signinAlertMessage === "IncorrectPassword")
      {
        setIncorrectPasswordVisible(true)
      }
      else if(state.signinAlertMessage === "EmailRequired")
      {
        setEmailRequiredVisible(true)
      }
      else if(state.signinAlertMessage === "PasswordRequired")
      {
        setPasswordRequiredVisible(true)
      }
      else if(state.signinAlertMessage?.length > 0)
      {
        const message = state.signinAlertMessage;
        setServerMessageVisible(true);
        setMessageFromServer(message);
      }
      addErrorMessage("signinAlertMessage","");
    }, [state.signinAlertMessage]);
  
    const hideCantFindAccountAlert = () => {
      setCantFindAccountVisible(false);
    };
    const hideIncorrectPasswordAlert = () => {
      setIncorrectPasswordVisible(false);
    };
    const hideEmailRequiredAlert = () => {
      setEmailRequiredVisible(false);
    };
    const hidePasswordRequiredAlert = () => {
      setPasswordRequiredVisible(false);
    };
    const hideServerMessageVisibleAlert = () => {
      setServerMessageVisible(false);
  };
    return (
      <View>
      <CustomAlert
        visible={CantFindAccountVisible}
        onClosePress={hideCantFindAccountAlert}
        userSettingsState={userSettingsState}
        headermessage={i18n.t('auth.SignIn_CantFindAccountAlert_subject')}
        bodymessge={i18n.t('auth.SignIn_CantFindAccountAlert_message1') + userName + i18n.t('auth.SignIn_CantFindAccountAlert_message2')}
        buttonmessage1={i18n.t('auth.SignIn_CantFindAccountAlert_signUpText')}
        buttonmessage1onPress={() => {
          navigateToScreen(navigation, 'LoginFlow', `SignUpWithEmail`, { isEmail: true }, null);
          // navigation.navigate('LoginFlow', { screen: 'SignUpWithEmail', params: { isEmail: true } });
                    // navigation.navigate('SignUpWithEmail',{ isEmail: true });
                }}
        buttonmessage2={i18n.t('auth.SignIn_CantFindAccountAlert_tryAgainText')}
      />
      <CustomAlert
      visible={IncorrectPasswordVisible}
      onClosePress={hideIncorrectPasswordAlert}
      userSettingsState={userSettingsState}
      headermessage={i18n.t('auth.SignIn_IncorrectPasswordAlert_subject')}
      bodymessge={i18n.t('auth.SignIn_IncorrectPasswordAlert_message')}
      buttonmessage1={i18n.t('auth.SignIn_IncorrectPasswordAlert_signUpText')}
    />
    <CustomAlert
      visible={EmailRequiredVisible}
      onClosePress={hideEmailRequiredAlert}
      userSettingsState={userSettingsState}
      headermessage={i18n.t('auth.SignIn_EmailRequired_subject')}
      bodymessge={i18n.t('auth.SignIn_EmailRequired_message')}
      buttonmessage1={i18n.t('auth.SignIn_EmailRequired_signUpText')}
    />
        <CustomAlert
      visible={PasswordRequiredVisible}
      onClosePress={hidePasswordRequiredAlert}
      userSettingsState={userSettingsState}
      headermessage={i18n.t('auth.SignIn_PasswordRequired_subject')}
      bodymessge={i18n.t('auth.SignIn_PasswordRequired_message')}
      buttonmessage1={i18n.t('auth.SignIn_PasswordRequired_signUpText')}
    />
    <CustomAlert
        visible={ServerMessageVisible}
        onClosePress={hideServerMessageVisibleAlert}
        userSettingsState={userSettingsState}
        bodymessge={MessageFromServer}
        buttonmessage1={i18n.t('auth.ForgotPasswordConfirmCanChangePassword_PasswordLengthAlert_okButton')}
      />    
    </View>
    );
  };
