import React, { useContext, useEffect, useRef, useState } from "react";
import { View, StyleSheet, Text, useWindowDimensions, Alert } from "react-native";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { getTotalPrice } from "../../functions/getShopInfo";
import { Context as ShopContext } from "../../context/ShopContext";
import base64 from 'react-native-base64';
import { getTranzilaURL } from "../../functions/getTranzilaURL";


const TranzilaIframe = ({ 
  userSettingsState,
  JsonToSend,
  OrderId,
  // amount,
 }) => {


  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const {state: shopState } = useContext(ShopContext);

  const [amount, setAmount] = useState(getTotalPrice(shopState, true, true));

  useEffect(() =>{
    // const interval = setInterval(() => {
      const newAmount = getTotalPrice(shopState, true, true);
      console.log(`newAmount is ${newAmount}`);
      
      setAmount(newAmount);

      const iframe = document.getElementById("tranzila-iframe");
      const checkUrl = () => {
        const iframeUrl = iframe.contentWindow.location.href;
        if (iframeUrl.includes("PaymentSuccessful")) {
          // תשלום הצליח
          console.log("Payment successful!");
          // בצע לוגיקה
        } else if (iframeUrl.includes("PaymentFailed")) {
          // תשלום נכשל
          console.log("Payment failed!");
          // בצע לוגיקה
        }
      };
    if(iframe)
      iframe.onload = checkUrl; // בדוק בכל פעם שה-iframe נטען מחדש
    // }, 1000); // Execute every 1 second

  // return () => clearInterval(interval);
  },[shopState]);


  const iframeRef = useRef(null);
  

  
  

  const iframeUrl = getTranzilaURL(amount, OrderId, userSettingsState, JsonToSend);
  
  

console.log(`iframeUrl is`);
console.log(iframeUrl);


  useEffect(() => {
    const interval = setInterval(() => {
      const iframe = iframeRef.current;

      try {
        // console.log(`iframe is`);
        // console.log(iframe);
        
        
        return;
        const currentUrl = iframe.contentWindow.location.href;
        console.log(`Current IFRAME URL: ${currentUrl}`);

        if (currentUrl.includes("PaymentSuccessful")) {
          console.log("Payment was successful");
          clearInterval(interval);
          // Navigate to success page or handle success logic
        } else if (currentUrl.includes("PaymentFailed")) {
          console.log("Payment failed");
          clearInterval(interval);
          // Navigate to failure page or handle failure logic
        }
      } catch (error) {
        // Same-origin policy prevents access to iframe URL from different domains
        console.error("Unable to access iframe URL:", error);
      }
    }, 1000); // Check every 1 second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <View style={styles.container}>
      {/* <Text style={styles.title}>Secure Payment</Text> */}
      <View
        style={[
          styles.iframeContainer,
          // { height: dimensions.height * 0.6, width: dimensions.width * 0.9 },
        {
          // height: dimensions.height * 0.6,
          // minHeight: 400,
          // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.35 : responsiveStyles.screenWidth * 0.85,
          // backgroundColor: 'blue'

          height: responsiveStyles.screenHeight,
          width: responsiveStyles.screenWidth,
        }
        ]}
      >
        <iframe
        ref={iframeRef}
          src={iframeUrl}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            borderRadius: 8,
          }}
          title="Tranzila Payment"
          allowpaymentrequest="true"
        ></iframe>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    // backgroundColor: "#f9f9f9",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  iframeContainer: {
    backgroundColor: "#fff",
    borderRadius: 8,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
  },
});

export default TranzilaIframe;
