import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const ProfileListDisplayTypeComponent = ({ 
    userSettingsState, 
    displayType, 
    setDisplayType, 
   }) => {

    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  return (
    <View style={styles.bottomCardContainer}>

<TouchableOpacity 
    style={[styles.cardContainer, displayType === 'Human' ? styles.onfocus : null,]}
    onPress={() => setDisplayType('Human')}
    >
    <Text style={[styles.text, displayType === 'Human' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_ProfileListDisplayTypeComponent_Human')}</Text>
    </TouchableOpacity>

  
    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer , displayType === 'Pets' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Pets')}
    >
    <Text style={[styles.text, displayType === 'Pets' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_ProfileListDisplayTypeComponent_Pets')}</Text>
    </TouchableOpacity>

  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  bottomCardContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    marginTop: !responsiveStyles.isMobile ? 20 : 10,
    marginBottom: !responsiveStyles.isMobile ? 20 : 10,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: GlobalStyles.fonts.application_font,
  },
  onfocus:{
    // borderBottomColor: GlobalStyles.colors.selectedItemColor,
    // borderBottomWidth: 1,
  },
  onfocusText: {
    color: GlobalStyles.colors.selectedItemColor,
    fontWeight: '700',
    // minWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 12 : responsiveStyles.screenWidth / 6,
    // maxWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 12 : responsiveStyles.screenWidth / 6,
    borderBottomColor: GlobalStyles.colors.selectedItemColor,
    borderBottomWidth: 2,
    paddingBottom: 10,
  },
  cardContainer: {
    flex:1,
  },
  cardBorderContainer: {
    borderRightWidth: responsiveStyles.isRTL ? 3 : null,
    borderLeftWidth: !responsiveStyles.isRTL ? 3 : null,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.selectedItemBackgroundColor,
  },
});

export default ProfileListDisplayTypeComponent;