import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const LengthSelector = ({ 
    userSettingsState,
    lengths = [],
    onSelect,
    value,
    setValue,
 }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  const handleSelect = (length) => {
    setValue(length);
    if (onSelect) {
      onSelect(length);
    }
  };

  return (
    <View style={styles.container}>
      {lengths.map((length) => (
        <TouchableOpacity
          key={length}
          style={[
            styles.option,
            value === length && styles.selectedOption
          ]}
          onPress={() => handleSelect(length)}
        >
          <Text style={styles.text}>{length}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' ,
    marginVertical: 10,
  },
  option: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginHorizontal: 5,
    backgroundColor: '#f9f9f9',
  },
  selectedOption: {
    borderColor: '#000',
    backgroundColor: '#e0e0e0',
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
});

export default LengthSelector;
