import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const TotalItemsComponent = ({ 
    userSettingsState, 
    data = [],
    totalQuantity = 0,
    totalPrice = 0,
    showShipping = false,
    shippingFee = 0,
    discountFee = 0,
    discountCode = null,
    totalPriceAfterShippingFee = 0,
    totalPriceAfterDiscount = 0,
  }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);

  return (
    <View>
    <View style={styles.container}>
    <Text style={[styles.text]}>{i18n.t('store.TotalItemsComponent_Subtotal')} ({totalQuantity} {i18n.t('store.TotalItemsComponent_Items')}): </Text>
    <Text style={[styles.text]}>{totalPrice} ₪</Text>
    </View>
    {discountCode ? 
    <>
        <View style={styles.container}>
    <Text style={[styles.text]}>{i18n.t('store.TotalItemsComponent_DiscountCode')}: </Text>
    <Text style={[styles.text]}>'{discountCode}'</Text>
    </View>
    </>  
    : null }
    {discountFee > 0 ? 
    <>
    <View style={styles.container}>
    <Text style={[styles.text]}>{i18n.t('store.TotalItemsComponent_Discount')}: </Text>
    <Text style={[styles.text]}>-{discountFee} ₪</Text>
    </View>
    </>
    : null }
    {showShipping ? 
    <>
    <View style={styles.container}>
    <Text style={[styles.text]}>{i18n.t('store.TotalItemsComponent_Shipping')}: </Text>
    <Text style={[styles.text]}>{shippingFee} ₪</Text>
    </View>
    <View style={styles.container}>
    <Text style={[[styles.text,styles.totalPrice]]}>{i18n.t('store.TotalItemsComponent_Total')}: </Text>
    <Text style={[[styles.text,styles.totalPrice]]}>{totalPriceAfterShippingFee} ₪</Text>
    </View>
    </>
    : null }
    {discountFee > 0 && !showShipping ?
    <View style={styles.container}>
    <Text style={[[styles.text,styles.totalPrice]]}>{i18n.t('store.TotalItemsComponent_Total')}: </Text>
    <Text style={[[styles.text,styles.totalPrice]]}>{totalPriceAfterDiscount} ₪</Text>
    </View>
    : null }
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // backgroundColor:'blue',
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    marginHorizontal: 15,
    marginBottom: 20,
  },
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
},
totalPrice: {
  fontSize: responsiveStyles.fontSize_H4,
  fontFamily: GlobalStyles.fonts.application_font,
  fontWeight: 'bold',
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
},
});

export default TotalItemsComponent;