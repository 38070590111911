import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, FlatList, Dimensions, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { formatDate } from "../../functions/formatDate";
import { navigateToScreen } from "../../navigationResponsive";
import OrderEmptyListComponent from "../displayComponent/OrderEmptyListComponent";
import OrderItemsListDetailsComponent from "./OrderItemsListDetailsComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const OrderListComponent = ({ 
    userSettingsState, 
    data = [],
    navigation,
    cleanup,
   }) => {
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  return (
    <View style={styles.bottomCardContainer}>
      <FlatList
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          data={data}
    key={(item) => item.Id.toString()} 
          renderItem={({ item, index }) =>
          {
        const formattedDate = formatDate(item.OrderDate);

            return <View style={styles.itemContainer}>
              <View style={styles.orderContainer}>
    <View style={[
      styles.orderRowContainer, 
      !responsiveStyles.isMobile ? { alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start' } : null,
      ]}>
              <Text style={styles.orderText}>{i18n.t('account.MyAccount_OrderListComponent_OrderNumber')} #{item.OrderNumber}</Text>
              <Text style={styles.orderText}>{i18n.t('account.MyAccount_OrderListComponent_OrderDate')} {formattedDate == null || formattedDate === "NaN/NaN/NaN" ? item.OrderDate : formattedDate}</Text>
              <Text style={styles.text}>{item.Jewlery.length} {i18n.t('account.MyAccount_OrderListComponent_Items')}</Text>
    </View>
    <View style={[
      styles.orderRowContainer, 
      !responsiveStyles.isMobile ? { alignItems: !responsiveStyles.isRTL ? 'flex-end' : 'flex-start' } : null,
    ]}>
              <Text style={styles.text}>{i18n.t('account.MyAccount_OrderListComponent_Total')} {item.Total} ₪</Text>
    <View style={styles.buttonContainer}>
  <ButtonTypeButton
  userSettingsState={userSettingsState}
        style={styles.button}
        onPress={() => {
          navigateToScreen(navigation, 'AccountFlow', `OrderDetails`, { Id: item.Id }, item.Id);
          // navigate('AccountFlow', { screen: 'OrderDetails', params:  { Id: item.Id } });
          // navigate("OrderDetails", { Id: item.Id });
          if(cleanup){
            cleanup();
          }
        }}
        >
          {i18n.t('account.MyAccount_OrderListComponent_View')}
    </ButtonTypeButton>
  </View>
    </View>
    </View>
    {/* <OrderItemsListComponent userSettingsState={userSettingsState} data={item.Jewlery} /> */}
    {/* <OrderItemsListDetailsComponent 
    userSettingsState={userSettingsState} 
    data={item.Jewlery}
    navigation={navigation}
    isFromOrderDetails={true}
     /> */}

  </View>
          }}
          ListEmptyComponent={
            <OrderEmptyListComponent
            userSettingsState={userSettingsState}
            navigation={navigation}
            />
              }
          />
  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({

  orderContainer: {
    flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : 'column', 
  },
  orderRowContainer: {
    flex:1,
    // flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', 
    justifyContent: 'space-between',
    // paddingBottom: 10,
    paddingHorizontal: !responsiveStyles.isMobile ? 25 : null,    
  },
  bottomCardContainer: {
    flex:1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', 
  },
  itemContainer: {
    flex:1,
    padding: 15,
    justifyContent:'flex-start',
    borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth: 1,

    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.6 : responsiveStyles.screenWidth * 0.8,

    marginVertical: 10,
    borderRadius: 10,
    alignSelf:'center',
  },
  orderText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    // paddingTop: 10,
    // fontWeight: '700',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  button: {
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.06 : responsiveStyles.screenWidth * 0.4,
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 - (10 * 3)
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.screenWidth * 0.4,
    minWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 80 : null,
    paddingTop:10,
},

buttonContainer: {
  alignItems: 'center',
},
});

export default OrderListComponent;