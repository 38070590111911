
export const getTotalPrice = (shopState, addShippingFee, addDiscount) => {
  const data = shopState.cart;
    let totalPrice = data.reduce((accumulator, currentItem) => {
      const isWithDiscount = currentItem.item.Discount > 0;
    const realPrice = currentItem.item.Price;
      const priceAfterDiscount = isWithDiscount ? (currentItem.item.Price * (100 - currentItem.item.Discount) / 100).toFixed(2) : realPrice;
        return accumulator + (priceAfterDiscount * currentItem.quantity);
      }, 0);
      if(addDiscount){
        const discount = shopState.discount?.discountPercentage > 0 ? shopState.discount.discountPercentage : 0;
        totalPrice = totalPrice * ((100 - discount) / 100);
      }
      if(addShippingFee && shopState.shippingMethod?.price > 0){
        totalPrice = totalPrice + shopState.shippingMethod.price;
      }
      totalPrice = totalPrice.toFixed(2);
      return totalPrice;
};
export const getTotalQuantity = (data) => {
    const totalQuantity = data.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.quantity;
      }, 0);
      return totalQuantity;
};

export const getShippingFee = (shopState) => {
  if(shopState.shippingMethod?.price > 0){
    return shopState.shippingMethod.price;
  }
  else{
    return 0;
  }
};

export const getDiscountPrice = (shopState) => {
  let totalPrice = getTotalPrice(shopState, false, false);
  const discount = shopState.discount?.discountPercentage > 0 ? shopState.discount.discountPercentage : 0;
  console.log(`getDiscountPrice, discount is ${discount} `);
  totalPrice = (totalPrice * (discount) / 100).toFixed(2);
  return totalPrice;
};